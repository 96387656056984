import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import useAuthorization from "../authentication/useauthorization";
import AuthorizedButton from "../authentication/authorizedbutton";
import { Link, Paper, Typography } from "@material-ui/core";
import { ChevronRight, Search as SearchIcon } from "@material-ui/icons";
import useStyles from "./useStyles";

const Widget = (props) => {
    const {
        requiredOperation,
        headerTitle,
        headerLink,
        onHeaderClick,
        headerLinkTitle,
        noContentSpacing,
        renderContent,
        loading,
        hasContent,
        icon,
        noDataText,
        addButtonText,
        addButtonUrl,
        onAddButtonClick,
        addButtonRequiredOperation,
        addButtonRequiredOperationAtLeastOneOf,
        fullwidth,
    } = props;
    const classes = useStyles();
    const { isLoading: authorizationIsLoading, isAuthorized } = useAuthorization(requiredOperation);

    if (authorizationIsLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return (
        <Paper
            className={clsx(
                classes.widget,
                loading === true && "widget--is-loading",
                fullwidth && "widget--fullwidth"
            )}
        >
            {headerLinkTitle && (
                <Link
                    className={classes.header}
                    component={headerLink && RouterLink}
                    to={headerLink ? headerLink : undefined}
                    href={!headerLink ? "#" : undefined}
                    title={headerLinkTitle}
                    onClick={
                        onHeaderClick
                            ? (e) => {
                                  e.preventDefault();
                                  onHeaderClick();
                              }
                            : undefined
                    }
                >
                    <div className={classes.widgetIcon}>{icon ? icon : <SearchIcon />}</div>
                    <Typography className={classes.title} variant="h4">
                        {headerTitle}
                    </Typography>
                    <ChevronRight className={classes.viewIcon} />
                </Link>
            )}
            {!headerLinkTitle && (
                <div className={classes.header}>
                    <div className={classes.widgetIcon}>{icon ? icon : <SearchIcon />}</div>
                    <Typography className={classes.title} variant="h4">
                        {headerTitle}
                    </Typography>
                </div>
            )}
            <div
                className={clsx(
                    classes.content,
                    noContentSpacing && "--without-spacing",
                    !hasContent && "--no-data"
                )}
            >
                {hasContent ? renderContent() : <p className={classes.noDataText}>{noDataText}</p>}

                <div className={classes.footer}>
                    {(addButtonUrl || onAddButtonClick) && (
                        <AuthorizedButton
                            color="secondary"
                            variant="contained"
                            size="small"
                            to={addButtonUrl}
                            onClick={onAddButtonClick}
                            requiredOperation={addButtonRequiredOperation}
                            requiredOperationAtLeastOneOf={addButtonRequiredOperationAtLeastOneOf}
                            buttonText={addButtonText ? addButtonText : "Add"}
                        />
                    )}
                </div>
            </div>
        </Paper>
    );
};

Widget.propTypes = {
    requiredOperation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    headerTitle: PropTypes.string,
    headerLink: PropTypes.string,
    onHeaderClick: PropTypes.func,
    headerLinkTitle: PropTypes.string,
    noContentSpacing: PropTypes.bool,
    renderContent: PropTypes.func,
    loading: PropTypes.bool,
    hasContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    icon: PropTypes.object,
    noDataText: PropTypes.string,
    addButtonText: PropTypes.string,
    addButtonUrl: PropTypes.string,
    onAddButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    addButtonRequiredOperation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    addButtonRequiredOperationAtLeastOneOf: PropTypes.array,
    fullwidth: PropTypes.bool,
};

export default Widget;
