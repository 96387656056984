import React, { useState } from "react";
import PropTypes from "prop-types";
import ResourcedTextField from "./textresources/resourcedtextfield";
import PageListSearch from "./pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = ({ onSearch, searchParams, labelResourceKey, labelDefaultValue }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    size="small"
                    labelResourceKey={labelResourceKey || "Shared.search.searchField"}
                    labelDefaultValue={labelDefaultValue || "Search"}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText}
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
            ]}
        />
    );
};

Search.propTypes = {
    onSearch: PropTypes.func,
    searchParams: PropTypes.object,
    labelResourceKey: PropTypes.string,
    labelDefaultValue: PropTypes.string,
};

export default Search;
