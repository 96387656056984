import AttributeTypes from "./attributetypes";

export function getAttributeValue(assetAttributeDefinition, attributes) {
    if (!attributes) {
        return null;
    }

    const attribute = attributes.find(
        (x) => x.assetAttributeDefinitionId === assetAttributeDefinition.id
    );
    if (!attribute) {
        switch (assetAttributeDefinition.assetAttributeTypeId) {
            case AttributeTypes.DATE:
            case AttributeTypes.TIME:
            case AttributeTypes.DATETIME:
                return null;
            default:
                return "";
        }
    }

    switch (assetAttributeDefinition.assetAttributeTypeId) {
        case AttributeTypes.SINGLE_LINE_TEXT:
        case AttributeTypes.MULTI_LINE_TEXT:
        case AttributeTypes.LINK:
            return attribute.textValue;
        case AttributeTypes.NUMBER:
            return attribute.numberValue;
        case AttributeTypes.MONEY:
            return attribute.decimalValue;
        case AttributeTypes.BOOLEAN:
            return attribute.boolValue;
        case AttributeTypes.OPTION_LIST:
            return attribute.optionValue;
        case AttributeTypes.DATE:
        case AttributeTypes.TIME:
        case AttributeTypes.DATETIME:
            return attribute.dateTimeValue;
        default:
            throw new Error(
                `Unknown assetAttributeTypeId in getAttributeValue '${assetAttributeDefinition.assetAttributeTypeId}'`
            );
    }
}

export function setAttributeValue(
    assetAttributeDefinition,
    attributes,
    value,
    setAttributes,
    handleChange
) {
    var attribute = attributes.find(
        (x) => x.assetAttributeDefinitionId === assetAttributeDefinition.id
    );
    if (!attribute) {
        attribute = {
            assetAttributeDefinitionId: assetAttributeDefinition.id,
            textValue: null,
            numberValue: null,
            decimalValue: null,
            boolValue: null,
            optionValue: null,
            dateTimeValue: null,
        };
    }

    switch (assetAttributeDefinition.assetAttributeTypeId) {
        case AttributeTypes.SINGLE_LINE_TEXT:
        case AttributeTypes.MULTI_LINE_TEXT:
        case AttributeTypes.LINK:
            attribute.textValue = value.target.value;
            break;
        case AttributeTypes.NUMBER:
            attribute.numberValue = value.target.value;
            break;
        case AttributeTypes.MONEY:
            attribute.decimalValue = value.target.value;
            break;
        case AttributeTypes.BOOLEAN:
            attribute.boolValue = value.target.checked;
            break;
        case AttributeTypes.OPTION_LIST:
            attribute.optionValue = value.target.value;
            break;
        case AttributeTypes.DATE:
        case AttributeTypes.TIME:
        case AttributeTypes.DATETIME:
            attribute.dateTimeValue = value;
            break;
        default:
            throw new Error(
                `Unknown assetAttributeTypeId in setAttributeValue '${assetAttributeDefinition.assetAttributeTypeId}'`
            );
    }

    var copy = attributes.filter(
        (x) => x.assetAttributeDefinitionId !== assetAttributeDefinition.id
    );
    copy = [...copy, attribute];

    setAttributes(copy);
    handleChange({ target: { name: "attributes", value: copy } });
}

export function validateAttributes(attributeDefinitions, attributes, errors) {

    if (attributeDefinitions) {
        attributeDefinitions.forEach((assetAttributeDefinition) => {
            const value = getAttributeValue(assetAttributeDefinition, attributes);
            if (
                assetAttributeDefinition.isRequired &&
                assetAttributeDefinition.assetAttributeTypeId !== AttributeTypes.BOOLEAN
            ) {

                if (!value) {
                    errors[
                        assetAttributeDefinition.id
                    ] = `Please enter a value for the attribute '${assetAttributeDefinition.label}'`;
                }
            }
            if (value && assetAttributeDefinition.assetAttributeTypeId === AttributeTypes.LINK) {
                if (!value.match("^(http:\\/\\/|https:\\/\\/){1}.*$")) {
                    errors[
                        assetAttributeDefinition.id
                    ] = `Provided URL for the attribute '${assetAttributeDefinition.label}' is invalid, it should start with http:// or https://`;
                }
            }
        });
    }

    return errors;
}
