import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getReferenceStatuses() {
    const url = `/api/maintenance/scheduledEvent/referenceStatuses`;

    return await interceptedFetch(url);
}

async function getAvailability(
    searchText,
    assetModelId,
    manufacturerId,
    startDate,
    endDate,
    page,
    pageSize
) {
    const params = {
        page,
        pageSize,
        searchText,
        assetModelId,
        manufacturerId,
        startDate: new Date(startDate).toISOString().split("T")[0],
        endDate: new Date(endDate).toISOString().split("T")[0],
    };

    const url = `/api/maintenance/scheduledEvent/availability?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/maintenance/scheduledevent/${id}`;

    return await interceptedFetch(url);
}

async function getAll(referenceId, referenceType, start, end) {
    const params = {
        referenceId,
        referenceType,
        start,
        end,
    };

    const url = `/api/maintenance/scheduledevent?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/maintenance/scheduledevent", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/maintenance/scheduledevent/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function move(id, start, end, allDay) {
    return await interceptedFetch(`/api/maintenance/scheduledevent/${id}/move`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({ start, end, allDay })),
    });
}

async function del(id) {
    return await interceptedFetch(`/api/maintenance/scheduledevent/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { get, getAll, add, update, move, getReferenceStatuses, getAvailability, del };
