import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    tableSection: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 3),
        borderBottom: `1px solid ${theme.palette.dividerGray}`,

        "&:first-child": {
            paddingTop: theme.spacing(4),
        },

        "& h3": {
            marginBottom: theme.spacing(2),
        },
    },

    tableRow: {
        "& th": {
            width: 125,
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            textTransform: "uppercase",
            fontWeight: 500,
            color: theme.palette.panelGray,

            "&:first-child": {
                width: "auto",
            },
        },

        "&.--totals": {
            "& td, & th": {
                paddingBottom: 0,
                borderBottom: 0,
                verticalAlign: "top",
            },

            "& .totalLabel, & .totalHours, & .totalAmount": {
                fontSize: 16,
                fontWeight: 500,
            },

            "& .totalEstimated": {
                fontSize: 13,
                marginTop: 2,
                color: theme.palette.text.darkGray,
            },
        },
    },
}));

export default useStyles;
