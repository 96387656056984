import React, { useContext } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import withResources from "../../../../shared/textresources/withresources";
import AuthenticationContext from "../../../../shared/authentication/authenticationcontext";
import WorkingScopeContext from "../../../shell/components/workingScope/workingscopecontext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    checkboxAll: {
        marginLeft: -5,
    },

    checkboxLabel: {
        alignSelf: "center",
    },
}));

export default withResources(
    ({ getResource, values, partTitle, partName, partOperation, onChange, checkboxes }) => {
        const { authorizationService } = useContext(AuthenticationContext);
        const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
        const allAreManuallyChecked = checkboxes.every((checkbox) =>
            Boolean(values[checkbox.variable])
        );
        const allAreChecked = Boolean(values[partName]) || allAreManuallyChecked;
        const allAreUnchecked = checkboxes.every((box) => !values[box.variable]);
        const someButNotAllAreChecked = !allAreChecked && !allAreUnchecked;

        const classes = useStyles();
        const isAuthorized = (operation) => {
            return authorizationService.isAuthorized({
                name: operation,
                companyId: companyScope.id,
                assetCollectionId: assetCollectionScope.id,
            });
        };

        if (partOperation && !isAuthorized(partOperation)) {
            return null;
        }

        const getUncheckAllEvents = () =>
            checkboxes.map((checkbox) => {
                return {
                    target: {
                        type: "checkbox",
                        name: checkbox.variable,
                        checked: false,
                    },
                };
            });

        return (
            <Accordion key={partName}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Checkbox
                        checked={Boolean(values[partName]) || allAreManuallyChecked}
                        indeterminate={someButNotAllAreChecked}
                        onChange={(e) => {
                            if (!Boolean(values[partName]) && !allAreUnchecked) {
                                onChange(getUncheckAllEvents());
                                return;
                            }
                            onChange(e);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className={classes.checkboxAll}
                        name={partName}
                        aria-labelledby={partName}
                    />
                    {/** No actual label element is used here, since a click on the label shouldn't toggle the checkbox. Instead it should only toggle the accordion. **/}
                    <span className={classes.checkboxLabel} id={partName}>
                        {getResource(`Assets.asset.export.${partName}.header`, partTitle)}
                    </span>
                </AccordionSummary>

                <AccordionDetails style={{ display: "inherit" }}>
                    {checkboxes.map((checkbox) => {
                        if (checkbox.operation && !isAuthorized(checkbox.operation)) {
                            return null;
                        }

                        return (
                            <div key={checkbox.variable}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                Boolean(values[checkbox.variable]) ||
                                                Boolean(values[partName])
                                            }
                                            onChange={(e) => onChange(e)}
                                            name={checkbox.variable}
                                            disabled={Boolean(values[partName])}
                                        />
                                    }
                                    label={getResource(
                                        `Assets.asset.export.${partName}.${checkbox.variable}`,
                                        checkbox.title
                                    )}
                                    variant="outlined"
                                />
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        );
    }
);
