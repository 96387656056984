import React from "react";
import { useLocation } from "react-router-dom";
import useAPI from "../../../shared/useapi";
import { get } from "./dataservice";
import Widget from "./financialDetailsWidget";
import useAuthorization from "../../../shared/authentication/useauthorization";

const FinancialDetailsWidget = ({ asset }) => {
    const location = useLocation();

    const refreshParam = new URLSearchParams(location.search).get("refresh");
    const refreshData = refreshParam === "financials";

    const { data, loading, invoke } = useAPI(() => get(asset.id), true, [asset.id, refreshData]);

    return (
        <Widget asset={asset} loading={loading} data={data} assetId={asset.id} onRefresh={invoke} />
    );
};

export default ({ asset }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: "assets-view-financial",
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <FinancialDetailsWidget asset={asset} />;
};
