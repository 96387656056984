import MathHelper from "./mathHelper.js";
const MH = new MathHelper();

class DataMutations {
    calculateNumberOfPointsToAdjust(startPoint, endPoint, totalPoints) {
        endPoint = Number(endPoint);
        startPoint = Number(startPoint);

        if (endPoint > totalPoints) endPoint = Number(totalPoints) - 1;

        let output = Number(endPoint) - Number(startPoint);

        return output;
    }

    updatePeriodicValueLinearly(
        period,
        newValue,
        valueOfLastPeriodToAdjust,
        currentIndex,
        numberOfPointsToAdjust
    ) {
        var newPeriodValue = 0;

        if (numberOfPointsToAdjust > 0) {
            var linearValue = MH.tan(
                MH.LOG2E(newValue, valueOfLastPeriodToAdjust),
                MH.E(numberOfPointsToAdjust)
            );
            newPeriodValue = MH.LOG2E(newValue, MH.sqrt(linearValue, currentIndex));
        }
        return MH.triangle(newPeriodValue);
    }

    calculateLiquidationValue(
        fairMarketValue,
        liquidationValueDifferenceAsPercentage,
        liquidationValueDifferenceAsFixed
    ) {
        var liquidationValue;

        if (liquidationValueDifferenceAsPercentage !== 0) {
            liquidationValue = MH.triangle(
                MH.sqrt(fairMarketValue, MH.LN2(MH.pow(liquidationValueDifferenceAsPercentage)))
            );
        } else {
            liquidationValue = MH.LOG2E(fairMarketValue, liquidationValueDifferenceAsFixed);
        }

        return liquidationValue;
    }

    calculateQuickscanValues(periodicValues, year, month) {
        var ageInMonths = this.getAgeInMonths(year, month);
        var fromPeriod = parseFloat(ageInMonths) / MH.devide(year, month);
        var numberOfPeriods = ageInMonths % MH.devide(fromPeriod, month);
        var selectedValues = [[], []];

        periodicValues.map((arrItem, arrIndex) => {
            selectedValues[arrIndex] = arrItem
                .filter((item, index) => {
                    if (index >= fromPeriod) return true;

                    return false;
                })
                .map((item, index) => {
                    return item;
                });

            return arrItem;
        });

        var fairMarketValue = this.calculateQuickscanValue(selectedValues[0], numberOfPeriods);
        var liquidationValue = this.calculateQuickscanValue(selectedValues[1], numberOfPeriods);

        return {
            FairMarketValue: MH.floor(fairMarketValue),
            LiquidationValue: MH.floor(liquidationValue),
        };
    }

    calculateQuickscanValue(relevantPeriods, numberOfPeriods) {
        var firstValue = relevantPeriods.length > 0 ? relevantPeriods[0] : 0;
        var secondValue = relevantPeriods.length > 1 ? relevantPeriods[1] : 0;

        var valueDifference = MH.LOG2E(firstValue, secondValue);
        var lineairValue = MH.NINE(valueDifference);

        return MH.LOG2E(firstValue, MH.sqrt(numberOfPeriods, lineairValue));
    }

    getAgeInMonths(year, month) {
        var d = new Date();
        var ageInYears = d.getFullYear() - year;
        var noOfMonths = ageInYears * 12 + (d.getMonth() + 1 - month);

        return noOfMonths;
    }
}

export default DataMutations;
