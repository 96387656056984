import React, { useState, useContext } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import DocumentContext from "./documentcontext";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams, library }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.name, values.type, values.isArchived);
    }

    const { getSupportedDocumentTypes } = useContext(DocumentContext);
    const documentTypes = getSupportedDocumentTypes(library);

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Document.document.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.name ? searchValues.name : ""}
                    onChange={(e) => handleValuesChanged({ ...searchValues, name: e.target.value })}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,

                documentTypes && documentTypes.length > 1 && (
                    <AutoCompleteStatic
                        name="type"
                        label={getResource("Document.document.search.typeField", "Type")}
                        isSearchBox
                        value={searchValues.type}
                        onSelection={(e) =>
                            handleValuesChanged({ ...searchValues, type: e.target.value })
                        }
                        options={
                            documentTypes ? documentTypes.map((g) => ({ id: g, name: g })) : []
                        }
                    />
                ),

                <AutoCompleteStatic
                    name="isArchived"
                    label={getResource("Document.document.search.isArchivedField", "Status")}
                    isSearchBox
                    value={searchValues.isArchived}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, isArchived: e.target.value })
                    }
                    options={[
                        {
                            id: "true",
                            name: getResource(
                                "Document.document.search.statusArchivedOption",
                                "Archived"
                            ),
                        },
                        {
                            id: "false",
                            name: getResource(
                                "Document.document.search.statusUnArchivedOption",
                                "Current"
                            ),
                        },
                    ]}
                    disableClearable
                />,
            ]}
        />
    );
});

export default Search;
