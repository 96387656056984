import { useContext } from "react";
import LocalizationContext from "./localization/localizationcontext";
import { utcToZonedTime } from "date-fns-tz";

export default ({ date, long, includeTime, includeWeekDay, onlyTime }) => {
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    if (!date) {
        return null;
    }

    return formatDate(
        date,
        selectedUiCulture,
        selectedTimezone,
        long,
        includeTime,
        includeWeekDay,
        onlyTime
    );
};

export const formatDate = (
    date,
    selectedUiCulture,
    selectedTimezone,
    long,
    includeTime,
    includeWeekDay,
    onlyTime
) => {
    const time = selectedTimezone
        ? utcToZonedTime(new Date(date), selectedTimezone)
        : new Date(date);

    var options = {
        month: long ? "long" : "numeric",
        year: "numeric",
        day: "numeric",
    };

    if (includeWeekDay) {
        options.weekday = "long";
    }

    if (onlyTime) {
        var timeOptions = {
            hour: "2-digit",
            minute: "2-digit",
        };
        return time.toLocaleTimeString(selectedUiCulture, timeOptions);
    } else if (includeTime) {
        options.hour = "2-digit";
        options.minute = "2-digit";

        return time.toLocaleString(selectedUiCulture, options);
    } else {
        return new Date(date).toLocaleDateString(selectedUiCulture, options);
    }
};
