import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import _ from "underscore";
import useStyles from "./useStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import withResources from "../../textresources/withresources";
import Path from "../../path";
import clsx from "clsx";

const Search = withResources(
    ({
        getResource,
        itemName,
        autoCompleteResults,
        handleSearchKeyPress,
        handleSearchOptionChange,
        isWhite,
    }) => {
        const classes = useStyles();
        const [inputValue, setInputValue] = useState("");
        const debouncedHandleSearchKeyPress = useRef(
            _.debounce((value) => handleSearchKeyPress(value), 200)
        ).current;
        const autoCompleteWrapper = useRef();

        React.useEffect(() => {
            debouncedHandleSearchKeyPress(inputValue);
        }, [inputValue, debouncedHandleSearchKeyPress]);

        return (
            <Autocomplete
                id="drilldown-search"
                className={clsx(classes.search, isWhite && "--is-white")}
                classes={{
                    popper: classes.autocompletePopper,
                    option: classes.autocompleteOption,
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                options={autoCompleteResults}
                includeInputInList
                freeSolo
                filterOptions={(x) => x}
                PopperComponent={autoCompleteWrapper.current}
                disableOpenOnFocus
                onChange={(e, value) => handleSearchOptionChange(value ? value.id : null)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        label={getResource(
                            "Shell.drillDown.search.searchLabel",
                            "Search {itemName}",
                            { itemName }
                        )}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        onChange={(e) => setInputValue(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment className={classes.startAdornment} position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={({ name, path }) => {
                    const parts = parse(name, match(name, inputValue));

                    return (
                        <div className={classes.searchOption}>
                            <Path
                                path={path ? path.map((item) => item.name) : null}
                                removeLastItem
                                keepOnOneLine
                            />
                            <Typography variant="body1" color="textPrimary">
                                {parts.map((part, i) => (
                                    <span
                                        key={i}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </Typography>
                        </div>
                    );
                }}
            />
        );
    }
);

Search.propTypes = {
    itemName: PropTypes.string,
    autoCompleteResults: PropTypes.array.isRequired,
    handleSearchKeyPress: PropTypes.func.isRequired,
    handleSearchOptionChange: PropTypes.func.isRequired,
    isWhite: PropTypes.bool,
};

export default Search;
