import React, { useState } from "react";
import withResources from "../../../../shared/textresources/withresources";
import { LinearProgress } from "@material-ui/core";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import useAPI from "../../../../shared/useapi";
import { calculate } from "../../assetquickscan/dataservice";
import useCurrencies from "../../../library/currencies/usecurrencies";
import MoneyInput from "../../../../shared/moneyInput";
import { PromptCategory } from "../../../system/prompttemplates/models/promptTemplates";
import { ChatButton } from "shared/aiChat/chatButton";

const CustomizeValues = withResources(
    ({ getResource, quickScan, filteredQuestions, parameters, onChange }) => {
        const { data, loading } = useAPI(() => calculate(quickScan), true);
        const { currencies, loading: currenciesLoading, getCode } = useCurrencies();
        const [isInitialized, setIsInitialized] = useState(false);

        if (data && !isInitialized) {
            var copy = { ...data };
            onChange(copy);
            setIsInitialized(true);
        }

        return loading || currenciesLoading ? (
            <LinearProgress />
        ) : (
            <>
                {data.currencyCode === null && (
                    <AutoCompleteStatic
                        name="currencyId"
                        label={getResource(
                            "Valuation.quickScan.customizeValues.currency",
                            "Currency"
                        )}
                        fullWidth
                        isMedium
                        value={parameters.currencyId}
                        onSelection={(e) =>
                            onChange({
                                ...parameters,
                                currencyId: e.target.value,
                                currencyCode: getCode(e.target.value),
                            })
                        }
                        options={currencies}
                        required
                    />
                )}

                {parameters.currencyCode !== null && (
                    <>
                        <MoneyInput
                            name="fairMarketValue"
                            labelResourceKey="Valuation.quickScan.customizeValues.fairMarketValue"
                            labelDefaultValue="Fair market value"
                            fullWidth
                            value={parameters.fairMarketValue}
                            currencyCode={parameters.currencyCode}
                            onChange={(e) =>
                                onChange({ ...parameters, fairMarketValue: e.target.value })
                            }
                            required
                            minimumValue="-10000000000000"
                        />

                        <MoneyInput
                            name="liquidationValue"
                            labelResourceKey="Valuation.quickScan.customizeValues.liquidationValue"
                            labelDefaultValue="Liquidation value"
                            fullWidth
                            value={parameters.liquidationValue}
                            currencyCode={parameters.currencyCode}
                            onChange={(e) =>
                                onChange({ ...parameters, liquidationValue: e.target.value })
                            }
                            required
                            minimumValue="-10000000000000"
                        />

                        {parameters.purchaseFutureValues && (
                            <>
                                <MoneyInput
                                    name="futureFairMarketValue"
                                    labelResourceKey="Valuation.quickScan.customizeValues.futureFairMarketValue"
                                    labelDefaultValue="Future fair market value"
                                    fullWidth
                                    value={parameters.futureFairMarketValue}
                                    currencyCode={parameters.currencyCode}
                                    onChange={(e) =>
                                        onChange({
                                            ...parameters,
                                            futureFairMarketValue: e.target.value,
                                        })
                                    }
                                    required
                                    minimumValue="-10000000000000"
                                />

                                <MoneyInput
                                    name="futureLiquidationValue"
                                    labelResourceKey="Valuation.quickScan.customizeValues.futureLiquidationValue"
                                    labelDefaultValue="Future liquidation value"
                                    fullWidth
                                    value={parameters.futureLiquidationValue}
                                    currencyCode={parameters.currencyCode}
                                    onChange={(e) =>
                                        onChange({
                                            ...parameters,
                                            futureLiquidationValue: e.target.value,
                                        })
                                    }
                                    required
                                    minimumValue="-10000000000000"
                                />
                            </>
                        )}
                        <ChatButton
                            assetId={quickScan.assetId}
                            category={PromptCategory.QuickScan}
                            context={{
                                quickScan,
                                filteredQuestions,
                            }}
                        />
                    </>
                )}
            </>
        );
    }
);

export default CustomizeValues;
