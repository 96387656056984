import React, { Fragment } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import withResources from "../textresources/withresources";

/**
 * This Memoized component makes sure the name and icon is not rendered too often
 * (Before this was used, also all ancestor items that were not visible were re-rendered which had
 * a negative performance impact.)
 */

const NameAndIcon = React.memo(
    withResources(({ getResource, name }) => (
        <Fragment>
            <Typography variant="h6" component="h3" title={name} className="name">
                {name}
            </Typography>
            <IconButton
                size="small"
                color="primary"
                aria-label={getResource("Shell.drillDownInput.openItemLabel", "Open this item")}
            >
                <ChevronRightIcon />
            </IconButton>
        </Fragment>
    ))
);

export default NameAndIcon;
