import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Titled } from "react-titled";
import Status from "./status";

const useStyles = makeStyles((theme) => ({
    header: {
        minHeight: 93,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${theme.spacing(1.5)}px 0`,

        "& > *": {
            margin: `${theme.spacing(0.5)}px 0`,
        },
    },

    headerLeft: {
        flex: "1 0",
        marginRight: 15,
    },

    headerRight: {
        flex: "0 0",
        whiteSpace: "nowrap",

        "& .MuiButton-root": {
            marginRight: theme.spacing(1),

            "&:last-child": {
                marginRight: 0,
            },
        },
    },

    breadcrumb: {
        margin: `${theme.spacing(1)}px 0 0 0`,
        padding: 0,
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        fontSize: 11,

        "& li": {
            display: "flex",
            alignItems: "center",

            "& svg": {
                margin: "0 4px",
            },

            "&:last-child svg": {
                display: "none",
            },
        },
    },
}));

const Header = ({ title, breadcrumb, renderActions, statusText, statusColor, secondary }) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <div className={classes.headerLeft}>
                <Titled title={(pageTitle) => `${pageTitle} - ${title}`} />
                {statusText && <Status text={statusText} color={statusColor} />}
                <Typography component={!secondary ? "h1" : "h2"} variant={!secondary ? "h3" : "h4"}>
                    {title}
                </Typography>
                {breadcrumb && (
                    <ol className={classes.breadcrumb}>
                        {breadcrumb
                            .filter((item) => item) /** Filter out null values */
                            .map((item) => (
                                <li key={item}>
                                    {item} <ChevronRightIcon fontSize="inherit" />
                                </li>
                            ))}
                    </ol>
                )}
            </div>

            {renderActions && <div className={classes.headerRight}>{renderActions()}</div>}
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string.isRequired,
    breadcrumb: PropTypes.array,
    renderActions: PropTypes.func,
    statusText: PropTypes.string,
    statusColor: PropTypes.string,
};

export default Header;
