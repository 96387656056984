import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
    Delete as DeleteIcon,
    Visibility as ViewIcon,
    Receipt as InvoiceIcon,
} from "@material-ui/icons";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

import WorkingContext from "../../security/workingContext";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import ActionMenu from "../../../shared/actionmenu";
import AssetCellContent from "../../../shared/assetCellContent";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
    },
}));

export default withResources(
    ({
        getResource,
        data,
        page,
        pageSize,
        availablePageSizes,
        loading,
        history,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onPurchase,
        onPurchaseCustom,
        onDelete,
        onInvoice,
    }) => {
        const classes = useStyles();

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="asset-quick-scans-view" />
                <ContentHolder>
                    <Header
                        title={getResource(
                            "Valuation.allAssetQuickScanList.header.subtitle",
                            "Asset quick scans"
                        )}
                        renderActions={() => {
                            return [
                                <AuthorizedButton
                                    key="purchaseButton"
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    onClick={onPurchase}
                                    requiredOperation={getRequiredOperation(
                                        "asset-quick-scans-purchase"
                                    )}
                                    buttonText={getResource(
                                        "Valuation.allAssetQuickScanList.header.purchaseButton",
                                        "Purchase"
                                    )}
                                />,
                                <AuthorizedButton
                                    key="createCustomButton"
                                    className={classes.button}
                                    color="secondary"
                                    variant="contained"
                                    onClick={onPurchaseCustom}
                                    requiredOperation={getRequiredOperation(
                                        "custom-asset-quick-scans-purchase"
                                    )}
                                    buttonText={getResource(
                                        "Valuation.allAssetQuickScanList.header.createCustomButton",
                                        "Create custom"
                                    )}
                                />,
                            ];
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data && data.total}
                        noResultsText={getResource(
                            "Valuation.allAssetQuickScanList.noResults",
                            "No asset quick scans available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={page}
                        pageSize={pageSize}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.allAssetQuickScanList.assetHeader"
                                            defaultValue="Asset"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.allAssetQuickScanList.referenceHeader"
                                            defaultValue="Reference"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.allAssetQuickScanList.createdOnHeader"
                                            defaultValue="Created on"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.allAssetQuickScanList.createdByHeader"
                                            defaultValue="Created by"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Valuation.allAssetQuickScanList.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.items &&
                                    data.items.map((row) => (
                                        <TableRow
                                            id={row.id}
                                            key={row.id}
                                            hover
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push(`/quick-scan/report/${row.id}`)
                                            }
                                        >
                                            <TableCell component="td">
                                                <AssetCellContent
                                                    assetName={row.assetName}
                                                    manufacturerName={row.manufacturerName}
                                                    assetModelName={row.assetModelName}
                                                    constructionYear={row.assetConstructionYear}
                                                    serial={row.assetSerial}
                                                    internalId={row.assetInternalId}
                                                    assetId={row.id}
                                                />
                                            </TableCell>
                                            <TableCell component="td">{row.reference}</TableCell>
                                            <TableCell component="td">
                                                <FormattedDate
                                                    date={row.purchasedDate}
                                                    includeTime
                                                />
                                            </TableCell>
                                            <TableCell component="td">
                                                {row.createdById === row.purchasedById
                                                    ? getResource(
                                                          "Valuation.allAssetQuickScanList.createdFor",
                                                          "{purchasedBy}",
                                                          { ...row }
                                                      )
                                                    : getResource(
                                                          "Valuation.allAssetQuickScanList.createdFor",
                                                          "{createdBy} (for {purchasedBy})",
                                                          { ...row }
                                                      )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Valuation.allAssetQuickScanList.viewButton",
                                                                "View"
                                                            ),
                                                            icon: <ViewIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "asset-quick-scans-view"
                                                            ),
                                                            onClick: () =>
                                                                history.push(
                                                                    `/quick-scan/report/${row.id}`
                                                                ),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.allAssetQuickScanList.deleteButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "asset-quick-scans-delete"
                                                            ),
                                                            onClick: () =>
                                                                onDelete({
                                                                    id: row.id,
                                                                    reference: row.reference,
                                                                }),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.allAssetQuickScanList.invoiceButton",
                                                                "Create invoice"
                                                            ),
                                                            icon: <InvoiceIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "invoice-create"
                                                            ),
                                                            onClick: () => onInvoice(row.id),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);
