import React from "react";
import { Section } from "../shell";
import Invoice from "./invoices";
import QRCodes from "./qrcodes";
import withResources from "../../shared/textresources/withresources";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Administration.invoicesTitle", "Invoices"),
            href: "/administration/invoices",
            render: Invoice,
            requiredOperation: "invoice-view",
        },
        {
            title: getResource("Administration.qrCodesTitle", "QR Codes"),
            href: "/administration/qrcodes/link/:id",
            render: props => QRCodes({ id: props.match.params.id }),
            requiredOperation: "qrcodes-link",
            hideInMenu: true
        },
    ];

    return (
        <Section
            key="administration"
            title={getResource("Administration.title", "Administration")}
            requiresAuthentication
            icon={InsertDriveFileIcon}
            href="/administration"
            children={children}
        />
    );
});
