import React, { useContext } from "react";
import { LinearProgress, Switch, FormControlLabel } from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import { getAll as getCurrencies } from "../../library/currencies/dataservice";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import DatePicker from "../../../shared/datepicker";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import LabelledOutline from "../../../shared/labelledoutline";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

import { getWhereUserHasOperation as getCompanies } from "../../security/companies/dataservice";
import {
    getDictionaryItems
} from "../../system/dictionaries/dataservice";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { authorizationService } = useContext(AuthenticationContext);

    const { data } = useAPI(() => get(id), true, [id]);

    const { data: currencies } = useAPI(() => getCurrencies(), true);
    const { data: formsOfFinance } = useAPI(() => getDictionaryItems("FormOfFinance"), true);
    const { data: companies } = useAPI(() => getCompanies(["use-as-financier"]), true);

    const depreciationPeriods = Array.from(Array(40).keys()).map((key) => ({
        id: (key + 1) * 6,
        name:
            (key + 1) * 6 +
            " " +
            getResource("Assets.asset.financialDetails.depreciationPeriodMonths", "months"),
    }));
    const loanPeriods = Array.from(Array(300).keys()).map((key) => ({
        id: key + 1,
        name:
            key + 1 + " " + getResource("Assets.asset.financialDetails.loanPeriodMonths", "months"),
    }));
    const loanPaymentMethods = [
        {
            id: "Pre",
            name: getResource(
                "Assets.asset.financialDetails.loanPaymentMethod.pre",
                "Beginning of each period"
            ),
        },
        {
            id: "Post",
            name: getResource(
                "Assets.asset.financialDetails.loanPaymentMethod.post",
                "End of each period"
            ),
        },
    ];
    const loanTypes = [
        {
            id: "Annuity",
            name: getResource("Assets.asset.financialDetails.loanPaymentMethod.annuity", "Annuity"),
        },
        {
            id: "Linear",
            name: getResource("Assets.asset.financialDetails.loanPaymentMethod.linear", "Linear"),
        },
    ];

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const getCompaniesToShow = () => {
        if (!companies || !data) {
            return [];
        }
        if (data.financierCompany && !companies.some(x => x.id == data.financierCompanyId))
        {
            return companies.concat([data.financierCompany]);
        }

        return companies;
    };

    const onValidate = (values, cultures, defaultCulture) => {
        var errors = {};

        if (!values.currencyId) {
            errors.currencyId = getResource(
                "Assets.asset.financialDetails.validation.currencyId",
                "Please select a currency"
            );
        }

        if (!values.purchaseDate) {
            errors.purchaseDate = getResource(
                "Assets.asset.financialDetails.validation.purchaseDate",
                "Please provide a purchase date"
            );
        }

        if (!values.depreciationPeriod) {
            errors.depreciationPeriod = getResource(
                "Assets.asset.financialDetails.validation.depreciationPeriod",
                "Please select a depreciation period"
            );
        }

        if (!values.loanOptional) {
            if (!values.loanStartDate) {
                errors.loanStartDate = getResource(
                    "Assets.asset.financialDetails.validation.loanStartDate",
                    "Please provide a start date"
                );
            }

            if (!values.loanPeriods) {
                errors.loanPeriods = getResource(
                    "Assets.asset.financialDetails.validation.loanPeriods",
                    "Please select a period"
                );
            }

            if (!values.loanPaymentMethod) {
                errors.loanPaymentMethod = getResource(
                    "Assets.asset.financialDetails.validation.loanPaymentMethod",
                    "Please select a payment method"
                );
            }

            if (!values.loanType) {
                errors.loanType = getResource(
                    "Assets.asset.financialDetails.validation.loanType",
                    "Please select a type for the loan"
                );
            }

            if (values.loanInterestRate < 0 || values.loanInterestRate > 100) {
                errors.loanInterestRate = getResource(
                    "Assets.asset.financialDetails.validation.loanInterestRate",
                    "Please provide a valid interest rate (between 0 and 100)"
                );
            }
        }

        return errors;
    };

    var isLoaded = data && currencies && companies && formsOfFinance;

    if (isLoaded && !data.currencyId) {
        data.currencyId = currencies.find((currency) => currency.name === "Euro").id;
    }

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Assets.asset.financialDetails.title", "Financial details")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.asset.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors) => {
                        var currencySign = currencies.find(
                            (currency) => currency.id === values.currencyId
                        ).sign;
                        var hasSealOperation = authorizationService.isAuthorized(
                            "assets-seal-financial"
                        );

                        return (
                            <React.Fragment>
                                {hasSealOperation && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={values.isSealed}
                                                onChange={(e) => handleChange(e)}
                                                name="isSealed"
                                            />
                                        }
                                        label={getResource(
                                            "Assets.asset.financialDetails.isSealed",
                                            "Is sealed"
                                        )}
                                        variant="outlined"
                                    />
                                )}

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="currencyId"
                                    label={getResource(
                                        "Assets.asset.financialDetails.currencyId",
                                        "Currency"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.currencyId}
                                    onSelection={(e) => handleChange(e)}
                                    options={currencies}
                                    disableClearable
                                    disabled={values.isSealed}
                                    required
                                />

                                <DatePicker
                                    autoFocus
                                    errors={errors}
                                    name="purchaseDate"
                                    label={getResource(
                                        "Assets.asset.financialDetails.purchaseDate",
                                        "Purchase date"
                                    )}
                                    value={values.purchaseDate}
                                    onChange={(e) => handleChange(e)}
                                    disabled={values.isSealed}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    name="purchasePrice"
                                    labelResourceKey="Assets.asset.financialDetails.purchasePrice"
                                    labelDefaultValue="Purchase price"
                                    margin="normal"
                                    type="number"
                                    AdornmentIcon={currencySign}
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.purchasePrice || ""}
                                    onChange={(e) => handleChange(e)}
                                    disabled={values.isSealed}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    name="remainingValue"
                                    labelResourceKey="Assets.asset.financialDetails.remainingValue"
                                    labelDefaultValue="Remaining value"
                                    margin="normal"
                                    type="number"
                                    AdornmentIcon={currencySign}
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.remainingValue || ""}
                                    onChange={(e) => handleChange(e)}
                                    disabled={values.isSealed}
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="depreciationPeriod"
                                    label={getResource(
                                        "Assets.asset.financialDetails.depreciationPeriod",
                                        "Depreciation period"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.depreciationPeriod}
                                    onSelection={(e) => handleChange(e)}
                                    options={depreciationPeriods}
                                    disableClearable
                                    disabled={values.isSealed}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    name="liquidationCost"
                                    labelResourceKey="Assets.asset.financialDetails.liquidationCost"
                                    labelDefaultValue="Liquidation cost"
                                    margin="normal"
                                    type="number"
                                    AdornmentIcon={currencySign}
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.liquidationCost || ""}
                                    onChange={(e) => handleChange(e)}
                                    disabled={values.isSealed}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    name="note"
                                    labelResourceKey="Assets.asset.financialDetails.note"
                                    labelDefaultValue="Note"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    InputLabelProps={{ shrink: true }}
                                    value={values.note || ""}
                                    onChange={(e) => handleChange(e)}
                                    disabled={values.isSealed}
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="financierCompanyId"
                                    label={getResource(
                                        "Administration.invoice.edit.financierCompanyField",
                                        "Financier"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.financierCompanyId}
                                    onSelection={handleChange}
                                    options={getCompaniesToShow()}
                                    disabled={values.isSealed}
                                />                                

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="formOfFinance"
                                    label={getResource(
                                        "Assets.asset.financialDetails.formOfFinance",
                                        "Form of finance"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.formOfFinance}
                                    onSelection={(e) => handleChange(e)}
                                    options={formsOfFinance}
                                    disabled={values.isSealed}
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.loanOptional}
                                            onChange={(e) => handleChange(e)}
                                            name="loanOptional"
                                        />
                                    }
                                    label={getResource(
                                        "Assets.asset.financialDetails.loanOptional",
                                        "Loan information not applicable/available"
                                    )}
                                    variant="outlined"
                                />

                                {!values.loanOptional && (
                                    <LabelledOutline
                                        label={getResource(
                                            "Assets.asset.financialDetails.loan",
                                            "Loan"
                                        )}
                                    >
                                        <DatePicker
                                            errors={errors}
                                            name="loanStartDate"
                                            label={getResource(
                                                "Assets.asset.financialDetails.loanStartDate",
                                                "Start date"
                                            )}
                                            value={values.loanStartDate}
                                            onChange={(e) => handleChange(e)}
                                            disabled={values.isSealed}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            name="loanInitialAmount"
                                            labelResourceKey="Assets.asset.financialDetails.loanInitialAmount"
                                            labelDefaultValue="Initial amount"
                                            margin="normal"
                                            type="number"
                                            AdornmentIcon={currencySign}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={values.loanInitialAmount || ""}
                                            onChange={(e) => handleChange(e)}
                                            disabled={values.isSealed}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            name="loanRemainingAmount"
                                            labelResourceKey="Assets.asset.financialDetails.loanRemainingAmount"
                                            labelDefaultValue="Remaining amount"
                                            margin="normal"
                                            type="number"
                                            AdornmentIcon={currencySign}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={values.loanRemainingAmount || ""}
                                            onChange={(e) => handleChange(e)}
                                            disabled={values.isSealed}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            name="loanInterestRate"
                                            labelResourceKey="Assets.asset.financialDetails.loanInterestRate"
                                            labelDefaultValue="Interest rate"
                                            margin="normal"
                                            type="number"
                                            AdornmentIcon="%"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={values.loanInterestRate || ""}
                                            onChange={(e) => handleChange(e)}
                                            disabled={values.isSealed}
                                        />

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="loanPeriods"
                                            label={getResource(
                                                "Assets.asset.financialDetails.loanPeriods",
                                                "Loan period"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.loanPeriods}
                                            onSelection={(e) => handleChange(e)}
                                            options={loanPeriods}
                                            disableClearable
                                            disabled={values.isSealed}
                                            required
                                        />

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="loanPaymentMethod"
                                            label={getResource(
                                                "Assets.asset.financialDetails.loanPaymentMethod",
                                                "Payment period"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.loanPaymentMethod}
                                            onSelection={(e) => handleChange(e)}
                                            options={loanPaymentMethods}
                                            disableClearable
                                            disabled={values.isSealed}
                                            required
                                        />

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="loanType"
                                            label={getResource(
                                                "Assets.asset.financialDetails.loanType",
                                                "Type"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.loanType}
                                            onSelection={(e) => handleChange(e)}
                                            options={loanTypes}
                                            disableClearable
                                            disabled={values.isSealed}
                                            required
                                        />
                                    </LabelledOutline>
                                )}
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
