import React from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import Configuration from "./configuration";

export default () => {
    return (
        <PrivateRoute
            requiredOperation="marketplace-configure"
            path="/marketplace/configuration"
            render={(props) => <Configuration {...props} />}
        />
    );
};
