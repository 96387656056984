import React from "react";
import { LinearProgress, FormControlLabel, Checkbox } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";

import useAPI from "../../../shared/useapi";
import { get, getPath, update } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";

import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { config } from "config";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            correspondenceCountry: values.values.correspondenceVisible
                ? values.values.correspondenceCountry
                : null,
            correspondenceAddress: values.values.correspondenceVisible
                ? values.values.correspondenceAddress
                : null,
            correspondenceState: values.values.correspondenceVisible
                ? values.values.correspondenceState
                : null,
            correspondencePostalCode: values.values.correspondenceVisible
                ? values.values.correspondencePostalCode
                : null,
            correspondenceCity: values.values.correspondenceVisible
                ? values.values.correspondenceCity
                : null,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = getResource("Security.companies.edit.enterName", "Please enter a name");
        }

        if (!values.country) {
            errors.country = getResource(
                "Security.companies.edit.selectCountry",
                "Please select a country"
            );
        }

        if (!values.address) {
            errors.address = getResource(
                "Security.companies.edit.enterAddress",
                "Please enter an address"
            );
        }

        if (!values.postalCode) {
            errors.postalCode = getResource(
                "Security.companies.edit.enterPostalCode",
                "Please enter a postalCode"
            );
        }

        if (!values.city) {
            errors.city = getResource("Security.companies.edit.enterCity", "Please enter a city");
        }

        if (values.correspondenceVisible && !values.correspondenceCountry) {
            errors.correspondenceCountry = getResource(
                "Security.companies.edit.correspondence.selectCountry",
                "Please select a correspondence country"
            );
        }

        if (values.correspondenceVisible && !values.correspondenceAddress) {
            errors.correspondenceAddress = getResource(
                "Security.companies.edit.correspondence.enterAddress",
                "Please enter a correspondence address"
            );
        }

        if (values.correspondenceVisible && !values.correspondencePostalCode) {
            errors.correspondencePostalCode = getResource(
                "Security.companies.edit.correspondence.enterPostalCode",
                "Please enter a correspondence postal code"
            );
        }

        if (values.correspondenceVisible && !values.correspondenceCity) {
            errors.correspondenceCity = getResource(
                "Security.companies.edit.correspondence.enterCity",
                "Please enter a correspondence city"
            );
        }

        if (!values.email) {
            errors.email = getResource(
                "Security.companies.edit.enterEmail",
                "Please enter a e-mail"
            );
        }

        if (values.email && !values.email.match(config.emailRegex)) {
            errors.email = getResource(
                "Security.companies.edit.enterValidEmail",
                "The provided e-mail isn't a valid e-mail address"
            );
        }

        return errors;
    };

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    const { data: path } = useAPI(() => getPath(id), true, [id]);

    var isLoaded = data && allCountries && path;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.companies.edit.title", "Edit company")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.companies.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    name="parent"
                                    labelResourceKey="Security.companies.edit.parentField"
                                    labelDefaultValue="Parent company"
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={path.parentNames.join(" > ")}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Security.companies.edit.nameField"
                                    labelDefaultValue="Name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 75 }}
                                    value={values.name}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="email"
                                    labelResourceKey="Security.companies.edit.emailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.email}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="chamberOfCommerce"
                                    labelResourceKey="Security.companies.edit.chamberOfCommerceField"
                                    labelDefaultValue="Chamber of Commerce number"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.chamberOfCommerce || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="vatNumber"
                                    labelResourceKey="Security.companies.edit.vatNumberField"
                                    labelDefaultValue="VAT Number"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.vatNumber || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber1"
                                    labelResourceKey="Security.companies.edit.phoneNumber1Field"
                                    labelDefaultValue="Phone number 1"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber1 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber1;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber2"
                                    labelResourceKey="Security.companies.edit.phoneNumber2Field"
                                    labelDefaultValue="Phone number 2"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber2 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber2;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="whatsAppNumber"
                                    labelResourceKey="Security.companies.edit.whatsAppNumberField"
                                    labelDefaultValue="WhatsApp number"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.whatsAppNumber || ""}
                                    helperText={getResource(
                                        "Security.companies.edit.whatsAppNumberHelperText",
                                        "in international format like 316123456789"
                                    )}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.whatsAppNumber;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Security.companies.edit.mainAddressTitle",
                                        "Main address"
                                    )}
                                >
                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="country"
                                        label={getResource(
                                            "Security.companies.edit.countryField",
                                            "Country"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.country}
                                        onSelection={(e) => handleChange(e)}
                                        options={allCountries.map((c) => ({
                                            id: c.code,
                                            name: c.name,
                                        }))}
                                        disableClearable
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="address"
                                        labelResourceKey="Security.companies.edit.addressField"
                                        labelDefaultValue="Address"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 100 }}
                                        value={values.address}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="postalCode"
                                        labelResourceKey="Security.companies.edit.postalCodeField"
                                        labelDefaultValue="Postal code"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                        value={values.postalCode}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="city"
                                        labelResourceKey="Security.companies.edit.cityField"
                                        labelDefaultValue="City"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 100 }}
                                        value={values.city}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="state"
                                        labelResourceKey="Security.companies.edit.stateField"
                                        labelDefaultValue="State"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 100 }}
                                        value={values.state}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </LabelledOutline>

                                <LabelledOutline
                                    label={getResource(
                                        "Security.companies.edit.correspondenceAddressTitle",
                                        "Correspondence address"
                                    )}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(values.correspondenceVisible)}
                                                onChange={(e) => handleChange(e)}
                                                name="correspondenceVisible"
                                            />
                                        }
                                        label={getResource(
                                            "Security.companies.edit.differentCorrespondenceAddressField",
                                            "Different correspondence address"
                                        )}
                                        variant="outlined"
                                    />

                                    {values.correspondenceVisible && (
                                        <React.Fragment>
                                            <AutoCompleteStatic
                                                errors={errors}
                                                name="correspondenceCountry"
                                                label={getResource(
                                                    "Security.companies.edit.correspondence.countryField",
                                                    "Correspondence country"
                                                )}
                                                fullWidth
                                                isMedium
                                                value={values.correspondenceCountry}
                                                onSelection={(e) => handleChange(e)}
                                                options={allCountries.map((c) => ({
                                                    id: c.code,
                                                    name: c.name,
                                                }))}
                                                disableClearable
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="correspondenceAddress"
                                                labelResourceKey="Security.companies.edit.correspondence.addressField"
                                                labelDefaultValue="Correspondence address"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.correspondenceAddress || ""}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="correspondencePostalCode"
                                                labelResourceKey="Security.companies.edit.correspondence.postalCodeField"
                                                labelDefaultValue="Correspondence postal code"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                value={values.correspondencePostalCode || ""}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="correspondenceCity"
                                                labelResourceKey="Security.companies.edit.correspondence.cityField"
                                                labelDefaultValue="Correspondence city"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.correspondenceCity || ""}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="correspondenceState"
                                                labelResourceKey="Security.companies.edit.correspondence.stateField"
                                                labelDefaultValue="Correspondence state"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.correspondenceState || ""}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </React.Fragment>
                                    )}
                                </LabelledOutline>
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
