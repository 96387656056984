import React, { useEffect, useState } from "react";
import QuestionsEditor from "../questionnaire/questionsEditor";
import CreditScore from "../questionnaire/creditScore";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Confirmation from "../../../shared/confirmation";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    intro: {
        margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },

    inheritedQuestions: {
        "& .questionsEditorAccordion": {
            marginBottom: "0 !important",
        },
    },
}));

export default withResources(
    ({ values, assetModelQuestionnaire, handleChange, errors, getResource, assetModelName }) => {
        const [copyDialogVisible, showCopyDialog] = useState(false);
        const classes = useStyles();

        // If the user chooses Replaces and no questions have filled in and there is a category
        // questionnaire available...
        useEffect(() => {
            if (
                values.action === "Replace" &&
                assetModelQuestionnaire != null &&
                assetModelQuestionnaire.length > 0 &&
                (!values.questions || values.questions.length === 0)
            ) {
                showCopyDialog(true);
            }
        }, [values.action, values.questions, assetModelQuestionnaire]);

        const handleCopy = async () => {
            handleChange({
                target: {
                    name: "questions",
                    value: assetModelQuestionnaire.map((q) => ({ ...q, id: null })),
                },
            });

            showCopyDialog(false);
        };

        var allQuestions = [];
        if (values.action === "Extend" || values.action === "Replace") {
            allQuestions = allQuestions.concat(values.questions);
        }
        if (
            assetModelQuestionnaire != null &&
            assetModelQuestionnaire.length > 0 &&
            (values.action === "Inherit" || values.action === "Extend")
        ) {
            allQuestions = allQuestions.concat(assetModelQuestionnaire);
        }
        return (
            <React.Fragment>
                <div className={classes.intro}>
                    <ResourcedText
                        resourceKey="Valuation.assetQuestionnaire.edit.description"
                        defaultValue="You can choose to inherit or extend the questions defined for the <strong>{assetModelName}</strong> model or replace them with a custom set of questions."
                        tokens={{ assetModelName }}
                    />
                </div>

                <ToggleButtonGroup
                    value={values.action || "Inherit"}
                    exclusive
                    size="small"
                    onChange={(e, value) => {
                        if (value !== null) {
                            handleChange({
                                target: {
                                    name: "action",
                                    value: value,
                                },
                            });
                        }
                    }}
                >
                    <ToggleButton value="Inherit" className="e2e_inheritButton">
                        {getResource("Valuation.assetQuestionnaire.edit.action.inherit", "Inherit")}
                    </ToggleButton>
                    <ToggleButton value="Extend" className="e2e_extendButton">
                        {getResource("Valuation.assetQuestionnaire.edit.action.extend", "Extend")}
                    </ToggleButton>
                    <ToggleButton value="Replace" className="e2e_replaceButton">
                        {getResource("Valuation.assetQuestionnaire.edit.action.replace", "Replace")}
                    </ToggleButton>
                </ToggleButtonGroup>

                {assetModelQuestionnaire != null && assetModelQuestionnaire.length > 0 && (
                    <FormHelperText>
                        {values.action === "Inherit" && (
                            <ResourcedText
                                resourceKey="Valuation.assetQuestionnaire.edit.inheritDescription"
                                defaultValue="Only the questions defined for this model will be used"
                                tokens={{ assetModelName }}
                            />
                        )}

                        {values.action === "Replace" && (
                            <ResourcedText
                                resourceKey="Valuation.assetQuestionnaire.edit.replaceDescription"
                                defaultValue="The questions below will replace the questions defined for this model."
                                tokens={{ assetModelName }}
                            />
                        )}

                        {values.action === "Extend" && (
                            <ResourcedText
                                resourceKey="Valuation.assetQuestionnaire.edit.extendDescription"
                                defaultValue="The questions below will be in addition to the questions already defined for this model."
                                tokens={{ assetModelName }}
                            />
                        )}
                    </FormHelperText>
                )}

                {(!assetModelQuestionnaire || assetModelQuestionnaire.length === 0) && (
                    <FormHelperText>
                        {values.action === "Inherit" && (
                            <ResourcedText
                                resourceKey="Valuation.assetQuestionnaire.edit.inheritNothingDescription"
                                defaultValue="This model currently does not have any questions defined. Any questions added to the model will be automatically inherited"
                            />
                        )}

                        {values.action === "Extend" && (
                            <ResourcedText
                                resourceKey="Valuation.assetQuestionnaire.edit.extendNothingDescription"
                                defaultValue="This model currently does not have any questions defined. Any questions added to the model will be automatically inherited."
                            />
                        )}
                    </FormHelperText>
                )}

                <CreditScore questions={allQuestions} />

                {assetModelQuestionnaire != null &&
                    assetModelQuestionnaire.length > 0 &&
                    (values.action === "Inherit" || values.action === "Extend") && (
                        <div className={classes.inheritedQuestions}>
                            <QuestionsEditor
                                disabled
                                label={getResource(
                                    "Valuation.assetQuestionnaire.inheritedQuestions.label",
                                    "Inherited questions from model"
                                )}
                                questions={assetModelQuestionnaire}
                                isExpandable
                            />
                        </div>
                    )}

                {values.action !== "Inherit" && (
                    <QuestionsEditor
                        error={"questions" in errors}
                        helperText={errors["questions"]}
                        questions={values.questions}
                        onQuestionsChanged={(newQuestions) => {
                            handleChange({ target: { name: "questions", value: newQuestions } });
                        }}
                    />
                )}

                {copyDialogVisible && (
                    <Confirmation
                        open={copyDialogVisible}
                        handleAction={handleCopy}
                        handleClose={() => showCopyDialog(false)}
                        assetModelName={assetModelName}
                        headerText={getResource(
                            "Valuation.assetQuestionnaire.copyValuesDialog.headerText",
                            "Copy model questions?"
                        )}
                        buttonText={getResource(
                            "Valuation.assetQuestionnaire.copyValuesDialog.copyButton",
                            "Copy"
                        )}
                        cancelButtonText={getResource(
                            "Valuation.assetQuestionnaire.copyValuesDialog.dontCopyButton",
                            "Don't copy"
                        )}
                    >
                        <ResourcedText
                            resourceKey="Valuation.assetQuestionnaire.copyValuesDialog.Label"
                            defaultValue="Would you like to copy the questions defined for the {assetModelName} model? (after copying you can modify the questions without affecting the model questions)"
                            tokens={{ assetModelName }}
                        />
                    </Confirmation>
                )}
            </React.Fragment>
        );
    }
);

export function validate(values, getResource) {
    const errors = {};

    if (values.action !== "Inherit" && (!values.questions || values.questions.length === 0)) {
        errors.questions = getResource(
            "Valuation.assetQuestionnaire.edit.enterQuestion",
            "Please fill in at least 1 question"
        );
    }

    return errors;
}
