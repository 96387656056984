import React, { useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import useStyles from "./useStyles";
import {
    Delete as DeleteIcon,
    ExpandLess as CollapseIcon,
    ExpandMore as ExpandIcon,
} from "@material-ui/icons";
import ConfigurationContext from "../../configuration/configurationcontext";
import Upload from "../../upload";
import ResourcedTextField from "../../textresources/resourcedtextfield";
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslatableTextField from "../../translatableTextField";
import withResources from "../../textresources/withresources";

const DocumentItem = withResources(
    ({
        getResource,
        document,
        multiLingual,
        culture,
        disabled,
        isExpanded,
        index,
        errors,
        uploadUrl,
        handleDelete,
        handleAccordionToggle,
        handleFileUpload,
        handleNameChange,
        handleFileNameChange,
        handleFileDelete,
    }) => {
        const { allowedDocumentExtensions } = useContext(ConfigurationContext);
        const classes = useStyles();
        const summary = multiLingual ? document.name[culture] : document.name;

        return (
            <Accordion
                id={`documentPanel${index}`}
                square
                expanded={isExpanded}
                onChange={() => handleAccordionToggle(isExpanded, index)}
            >
                <AccordionSummary
                    aria-controls={`documentPanel${index}-content`}
                    id={`documentPanel${index}-header`}
                    classes={{ content: classes.summaryContent }}
                >
                    {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    <div className={classes.summaryDescription}>
                        {summary ||
                            getResource(
                                "Documents.documentEditor.summaryLabelWhenNoName",
                                "Document {number}",
                                { number: index + 1 }
                            )}
                    </div>
                    <div className={classes.summaryDeleteButtonWrapper}>
                        {isExpanded && (
                            <IconButton
                                aria-label="delete"
                                disabled={disabled}
                                onClick={() => handleDelete(index)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                </AccordionSummary>

                <AccordionDetails id={`documentPanel${index}-content`}>
                    <div className={classes.details}>
                        <div className={classes.detailsUploadContainer}>
                            <Upload
                                fileId={document.fileId}
                                fileName={document.fileName + document.extension}
                                errors={errors && errors[index]}
                                url={uploadUrl}
                                disabled={disabled}
                                allowedExtensions={allowedDocumentExtensions}
                                showDelete={true}
                                onFileUpload={(file) => handleFileUpload(file, index)}
                                onFileDelete={() => handleFileDelete(index)}
                            />
                        </div>

                        <ResourcedTextField
                            errors={errors && errors[index]}
                            margin="normal"
                            name="fileName"
                            labelResourceKey="Documents.documentEditor.fileName"
                            labelDefaultValue="File name"
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            value={document.fileName || ""}
                            disabled={disabled}
                            onChange={(e) => handleFileNameChange(e, index)}
                            InputProps={{
                                endAdornment: document.extension && (
                                    <InputAdornment position="end">
                                        {document.extension}
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />

                        {multiLingual ? (
                            <TranslatableTextField
                                errors={errors && errors[index]}
                                margin="normal"
                                name="name"
                                labelResourceKey="Documents.documentEditor.name"
                                labelDefaultValue="Name"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={document.name}
                                culture={culture}
                                handleChange={(e) => handleNameChange(e, index)}
                                required
                            />
                        ) : (
                            <ResourcedTextField
                                errors={errors && errors[index]}
                                margin="normal"
                                name="name"
                                labelResourceKey="Documents.documentEditor.name"
                                labelDefaultValue="Name"
                                type="text"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={document.name || ""}
                                disabled={disabled}
                                onChange={(e) => handleNameChange(e, index)}
                                required
                            />
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
);

export default DocumentItem;
