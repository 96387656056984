import React, { Fragment, useContext, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import {
    Edit as UpdateStatusIcon,
    Edit as EditIcon,
    GetApp as DownloadIcon,
    Delete as DeleteIcon,
    Close,
} from "@material-ui/icons";

import Search from "./search";
import ActionMenu from "../../../shared/actionmenu";
import PagedList from "../../../shared/pagedList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import _ from "underscore";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import { formatCurrency } from "../../../shared/formattedCurrency";
import WorkingContext from "../../security/workingContext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import FormattedDate from "../../../shared/formattedDate";
import { useStyles } from "./useStyles";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onCreate,
        onEdit,
        onDownload,
        onUpdateStatus,
        onDelete,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        const { selectedUiCulture } = useContext(LocalizationContext);
        const { authorizationService } = useContext(AuthenticationContext);

        const [showModal, setShowModal] = useState(false);
        const [fullDescription, setFullDescription] = useState("");

        const classes = useStyles();

        function truncateText(text) {
            const tokens = text.split(/\s+/);
            return [...tokens.slice(0, 25), "..."].join(" ");
        }

        return (
            <Fragment>
                <WorkingContext requiredOperation={"invoice-view"} hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource("Administration.invoices.header.title", "Invoices")}
                        renderActions={() => {
                            const globalOperation = {
                                name: "invoice-create",
                                companyId: null,
                                assetCollectionId: null,
                            };

                            if (
                                authorizationService.isAuthorized(globalOperation) ||
                                authorizationService.hasAuthorizationOnCompanyLevel(
                                    "invoice-create"
                                )
                            ) {
                                return (
                                    <AuthorizedButton
                                        color="primary"
                                        variant="contained"
                                        onClick={onCreate}
                                        buttonText={getResource(
                                            "Administration.appraisals.newButton",
                                            "Create"
                                        )}
                                    />
                                );
                            }
                            return null;
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Administration.invoices.noResults",
                            "No invoices available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.numberHeader"
                                            defaultValue="Number"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.dateHeader"
                                            defaultValue="Date"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.amountHeader"
                                            defaultValue="Amount"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.descriptionHeader"
                                            defaultValue="Description"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.companyUserHeader"
                                            defaultValue="Company & User"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.statusHeader"
                                            defaultValue="Status"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Administration.invoices.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => onDownload(row.id)}
                                            requiredOperation="invoice-view"
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <FormattedDate long date={row.date} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatCurrency(
                                                    row.amount,
                                                    row.currencyShortName,
                                                    2,
                                                    selectedUiCulture
                                                )}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.description.length > 250 ? (
                                                    <>
                                                        {truncateText(row.description)}{" "}
                                                        <button
                                                            className={
                                                                classes.descriptionDialogToggle
                                                            }
                                                            onClick={(e) => {
                                                                setFullDescription(row.description);
                                                                setShowModal(true);
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            {getResource(
                                                                "Administration.invoices.description.showMore",
                                                                "Show More"
                                                            )}
                                                        </button>
                                                    </>
                                                ) : (
                                                    row.description
                                                )}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>{row.company.name}</div>
                                                <div>{row.userFullName}</div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getResource(
                                                    `Administration.invoices.invoiceStatus.${row.status.toLowerCase()}`,
                                                    row.status
                                                )}
                                            </TableCell>

                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Administration.invoices.downloadButton",
                                                                "Download"
                                                            ),
                                                            icon: <DownloadIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "invoice-view",
                                                            onClick: () => onDownload(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Administration.invoices.editButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "invoice-update",
                                                            onClick: () => onEdit(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Administration.invoices.updateStatusButton",
                                                                "Update status"
                                                            ),
                                                            icon: <UpdateStatusIcon />,
                                                            itemId: row.id,
                                                            requiredOperation:
                                                                "invoice-update-status",
                                                            onClick: () =>
                                                                onUpdateStatus({ ...row }),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Administration.invoices.deleteButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "invoice-delete",
                                                            onClick: () => onDelete({ ...row }),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>

                <Dialog
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    onExited={() => setFullDescription("")}
                >
                    <DialogTitle disableTypography className={classes.descriptionDialogTitle}>
                        <h3>
                            {getResource(
                                "Administration.invoices.descriptionDialogTitle",
                                "Description"
                            )}
                        </h3>
                        <IconButton onClick={() => setShowModal(false)}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.descriptionDialogContent}>
                        {fullDescription}
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
);

export default Overview;
