import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    Image as ShowThumbnailsIcon,
} from "@material-ui/icons";
import _ from "underscore";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import PagedListContent from "./pagedListContent";
import clsx from "clsx";
import { Checkbox, TableCell, FormControlLabel, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    checkboxCell: {
        width: 52,
        cursor: "auto",

        "& + td, & + th": {
            paddingLeft: 8,
        },
    },
}));

const Overview = withResources(
    ({
        getResource,
        data,
        history,
        availablePageSizes,
        loading,
        searchParams,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onLinkReadings,
        onDelete,
        onDeleteSelected,
        defaultDebounceInterval,
        allStatuses,
        showThumbnails,
        setShowThumbnails,
        selectedComponents,
        selectedComponentsInfo,
        onSelectComponent,
        onSelectAllComponents,
        onDeselectAllComponents,
    }) => {
        data = data || {};
        const classes = useStyles();
        const items = data.items;
        const sortedColumnHeader = data.sortedColumnHeader;
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);
        const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="components-view" hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource("Asset.components.overview.header.title", "Components")}
                        renderActions={() => {
                            if (selectedComponentsInfo && selectedComponentsInfo.length > 0) {
                                return (
                                    <ActionMenu
                                        buttonLabel={getResource(
                                            "Asset.components.bulkMenuLabel",
                                            "Actions"
                                        )}
                                        actions={[
                                            {
                                                text: getResource(
                                                    "Asset.components.overview.deleteButton",
                                                    "Delete"
                                                ),
                                                icon: <DeleteIcon />,
                                                requiredOperation: {
                                                    name: "components-delete",
                                                    companyId: companyScope.id,
                                                },
                                                onClick: () => onDeleteSelected(selectedComponents),
                                            },
                                        ]}
                                    />
                                );
                            }
                            return <Fragment>
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="components-create"
                                    buttonText={getResource("Asset.components.overview.new", "Add")}
                                />
                                <ActionMenu
                                    actions={[                                        
                                        {
                                            preventClose: true,
                                            text: (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={showThumbnails}
                                                            onChange={(e) =>
                                                                setShowThumbnails(e.target.checked)
                                                            }
                                                            name="showThumbnails"
                                                        />
                                                    }
                                                    label={getResource(
                                                        "Asset.components.search.showThumbnails",
                                                        "Show thumbnails"
                                                    )}
                                                    labelPlacement="start"
                                                    variant="outlined"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginLeft: 0,
                                                    }}
                                                />
                                            ),
                                            icon: <ShowThumbnailsIcon />,
                                        }
                                    ]}
                                />
                            </Fragment>;
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Asset.components.overview.noResults",
                            "No components available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search
                                onSearch={onSearchDebounced}
                                searchParams={searchParams}
                                allStatuses={allStatuses}
                            />
                        )}
                    >
                        <PagedListContent
                            items={items}
                            sortedColumnHeader={sortedColumnHeader}
                            handleRowClick={(row) => history.push(`/assets/components/${row.id}`)}
                            getRequiredOperationForRowClick={(row) => {
                                return {
                                    name: "components-view",
                                    companyId: row.companyId,
                                    assetCollectionId: row.assetCollectionIds,
                                };
                            }}
                            renderSelectAllComponents={() => (
                                <TableCell
                                    className={clsx(classes.checkboxCell, "checkboxCell")}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Checkbox
                                        checked={
                                            items && selectedComponents
                                                ? items.every((row) => selectedComponents.includes(row.id))
                                                : false
                                        }
                                        onChange={(e) =>
                                            e.target.checked
                                                ? onSelectAllComponents()
                                                : onDeselectAllComponents()
                                        }
                                        name="SelectAll"
                                        size="small"
                                    />
                                </TableCell>
                            )}
                            renderSelectComponent={(row) => (
                                <TableCell
                                    className={classes.checkboxCell}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Checkbox
                                        checked={selectedComponents.filter((x) => x === row.id).length > 0}
                                        onChange={(e) => onSelectComponent(e)}
                                        name={row.id.toString()}
                                        size="small"
                                    />
                                </TableCell>
                            )}
                            showThumbnails={showThumbnails}
                            renderActionMenu={(row) => (
                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Asset.components.overview.viewButton",
                                                "View"
                                            ),
                                            icon: <ViewIcon />,
                                            itemId: row.id,
                                            requiredOperation: {
                                                name: "components-view",
                                                companyId: row.companyId,
                                            },
                                            onClick: () =>
                                                history.push(`/assets/components/${row.id}`),
                                        },
                                        {
                                            showOnlyWhen: !row.isSealed,
                                            text: getResource(
                                                "Asset.components.overview.editButton",
                                                "Edit"
                                            ),
                                            icon: <EditIcon />,
                                            itemId: row.id,
                                            requiredOperation: {
                                                name: "components-update",
                                                companyId: row.companyId,
                                                assetCollectionId: row.assetCollectionIds,
                                            },
                                            onClick: () => onEdit(row.id),
                                        },
                                        {
                                            text: getResource(
                                                "Asset.components.overview.deleteButton",
                                                "Delete"
                                            ),
                                            icon: <DeleteIcon />,
                                            itemId: row.id,
                                            requiredOperation: {
                                                name: "components-delete",
                                                companyId: row.companyId,
                                                assetCollectionId: row.assetCollectionIds,
                                            },
                                            onClick: () => onDelete({ id: row.id, isInstalled: row.status == "Installed", isSealed: row.isSealed }),
                                        },
                                    ]}
                                />
                            )}
                        />
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
