import React, { Fragment, useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { update, get } from "./dataservice";
import ScheduledEventEditor, {
    validate as validateScheduledEvent,
    correctTimesOnSubmit,
} from "../components/scheduledEventEditor";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import useDialog from "../../../shared/usedialog";
import Delete from "./delete";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";

export default withResources((props) => {
    const { getResource, open, onClose, id } = props;

    if (!open) {
        return null;
    }

    const { selectedTimezone } = useContext(LocalizationContext);

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        var copyValues = {
            scheduledEvent: correctTimesOnSubmit(values.values.scheduledEvent, selectedTimezone),
        };

        if (await invoke(copyValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        let errors = {};
        validateScheduledEvent(values.scheduledEvent, "scheduledEvent", errors, getResource, false);

        return errors;
    };

    let isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    const { open: handleDelete, close: handleCloseDelete, visible: deleteToggled } = useDialog(
        onClose
    );

    return (
        <Fragment>
            <FormDrawer
                title={getResource("Planning.scheduledEvent.edit.title", "Edit event")}
                onClose={() => onClose(false)}
                isDirty={isDirty}
            >
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={{ scheduledEvent: data }}
                        onIsDirty={setIsDirty}
                        loading={loading}
                        okButtonText={getResource(
                            "Planning.scheduledEvent.edit.okButton",
                            "Update"
                        )}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        renderFooter={() => (
                            <AuthorizedButton
                                onClick={handleDelete}
                                requiredOperation="scheduled-events-delete"
                                buttonText={getResource(
                                    "Planning.scheduledEvent.deleteButton",
                                    "Delete"
                                )}
                                startIcon={<DeleteIcon />}
                            />
                        )}
                        renderForm={(
                            culture,
                            values,
                            handleChange,
                            errors,
                            updateValues,
                            isDefaultCulture,
                            resetValues
                        ) => (
                            <ScheduledEventEditor
                                onScheduledEventChanged={(event) => {
                                    handleChange({
                                        target: { name: "scheduledEvent", value: event },
                                    });
                                }}
                                scheduledEvent={values.scheduledEvent}
                                errors={errors["scheduledEvent"]}
                                disableLinkedEdit
                            />
                        )}
                    />
                )}
            </FormDrawer>
            {data && (
                <Delete
                    id={id}
                    open={deleteToggled}
                    onClose={handleCloseDelete}
                    summary={data.summary}
                />
            )}
        </Fragment>
    );
});
