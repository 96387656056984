import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, returnSecondary, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        returnSecondary,
    };

    const url = `/api/security/operation?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/security/operation/${id}`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/security/operation/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

export { getAll, get, update };
