import React, { useContext, useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import DrillDownInput from "../../../shared/drilldowninput";
import AuthorizationContext from "../../../shared/authentication/authenticationcontext";
import {
    getChildren,
    getParents,
    getPath,
    filter,
} from "../../library/assetcategories/dataservice";
import { getAll as getAllManufacturers } from "../../library/manufacturers/dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import useAPI from "../../../shared/useapi";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);
    const { authorizationService } = useContext(AuthorizationContext);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const { data: allManufacturers } = useAPI(() => getAllManufacturers(null, null, null), true, [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    const statusOperationsMapping = {
        Draft: "model-valuations-create",
        Released: "model-valuations-view-released",
        Published: "model-valuations-view-published",
    };

    const filterStatuses = Object.keys(statusOperationsMapping)
        .filter((status) => {
            var operation = statusOperationsMapping[status];
            return authorizationService.isAuthorized(operation);
        })
        .map((x) => ({ id: x, name: getResource(`Valuation.status.${x}`, x) }));

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.manufacturerId, values.assetCategory, values.statuses);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Valuation.modelValuation.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Valuation.modelValuation.search.manufacturerField",
                        "Manufacturer"
                    )}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, manufacturerId: e.target.value })
                    }
                />,
                <DrillDownInput
                    name="assetCategory"
                    itemName={getResource(
                        "Valuation.modelValuation.search.assetCategoryItemName",
                        "category"
                    )}
                    label={getResource(
                        "Valuation.modelValuation.search.assetCategoryField",
                        "Category"
                    )}
                    getParents={getParents}
                    getChildren={getChildren}
                    getPath={getPath}
                    filter={filter}
                    value={searchValues.assetCategory}
                    setValue={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            assetCategory: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Valuation.modelValuation.search.statusesField",
                        "Valuation status"
                    )}
                    multiple
                    value={searchValues.statuses}
                    options={filterStatuses}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            statuses: e.target.value,
                        })
                    }
                />,
            ]}
        />
    );
});

export default Search;
