import React, { useContext, Fragment, useEffect } from "react";
import { Chip, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PlayCircleOutline as OpenIcon } from "@material-ui/icons";
import useSessionStorage from "../../../shared/useSessionStorage";
import Search from "./search";
import ActionMenu from "../../../shared/actionmenu";
import useAPI from "../../../shared/useapi";
import _ from "underscore";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import { getAssetsWithValuations } from "../valuation/dataservice";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import PagedList from "../../../shared/pagedList";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import PagedListContent from "../../assets/asset/pagedListContent";
import { formatDate } from "../../../shared/formattedDate";

const useOverviewStyles = makeStyles((theme) => ({
    root: {},
    filterButton: {
        marginRight: theme.spacing(2),
    },
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 1150,
    },
    actions: {
        padding: theme.spacing(0, 1),
        justifyContent: "flex-end",
    },
    noContent: {
        backgroundColor: theme.palette.white,
        padding: "20px",
        textAlign: "center",
        fontWeight: 500,
    },
    searchIcon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: "10px",
        borderRadius: ".8em",
        fontSize: "3em",
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));

const ChooseAsset = withResources(({ getResource, history }) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-valuation", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        manufacturerId: null,
        assetModelId: null,
        statuses: [],
    });

    const { loading, data } = useAPI(
        () =>
            getAssetsWithValuations(
                searchParams.searchText,
                searchParams.manufacturerId,
                searchParams.assetModelId,
                searchParams.statuses,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.manufacturerId,
            searchParams.assetModelId,
            searchParams.statuses,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id,
            assetCollectionScope.id
        ]);

    function onSearch(searchText, manufacturerId, assetModelId, statuses) {
        setSearchParams({
            ...searchParams,
            searchText,
            manufacturerId,
            assetModelId,
            statuses,
            page: 0,
        });
    }

    function onPageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function onPageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    const classes = useOverviewStyles();

    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <Fragment>
            <WorkingContext requiredOperation="assets-view" />
            <ContentHolder>
                <Header title={getResource("Valuation.chooseAsset.title", "Choose asset")} />
                <PagedList
                    loading={loading}
                    itemCount={data && data.total}
                    noResultsText={getResource(
                        "Valuation.chooseAsset.noResults",
                        "No assets available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <PagedListContent
                        items={data && data.items}
                        handleRowClick={(row) =>
                            history.push(`/valuation/asset-valuation/${row.id}`)
                        }
                        showFinancials={false}
                        getRequiredOperationForRowClick={() => [
                            "asset-valuations-create",
                            "asset-valuations-prepare",
                        ]}
                        renderExtraHeaders={() => (
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Valuation.chooseAsset.valuationStatusHeader"
                                    defaultValue="Valuation status"
                                />
                            </TableCell>
                        )}
                        renderExtraColumns={(row) => (
                            <TableCell component="td">
                                {row.statuses.map(function (status) {
                                    var suffix = "";
                                    if (status === "Published") {
                                        suffix = ` (${formatDate(
                                            row.lastPublishedDate,
                                            selectedUiCulture,
                                            selectedTimezone
                                        )})`;
                                    }
                                    return (
                                        <Chip
                                            key={status}
                                            className={classes.chip}
                                            label={
                                                getResource(`Valuation.status.${status}`, status) +
                                                suffix
                                            }
                                        />
                                    );
                                })}
                            </TableCell>
                        )}
                        renderActionMenu={(row) => (
                            <ActionMenu
                                actions={[
                                    {
                                        text: "Open",
                                        icon: <OpenIcon />,
                                        itemId: row.id,
                                        requiredOperationAtLeastOneOf: [
                                            "asset-valuations-create",
                                            "asset-valuations-prepare",
                                        ],
                                        onClick: () =>
                                            history.push(`/valuation/asset-valuation/${row.id}`),
                                    },
                                ]}
                            />
                        )}
                    />
                </PagedList>
            </ContentHolder>
        </Fragment>
    );
});

export default ChooseAsset;
