import React, { useContext, useState, Fragment } from "react";
import { Route, Switch } from "react-router";

import PrivateRoute from "../../../shared/authentication/privateroute";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import { getQuickScan } from "../quickscan/dataservice";
import Overview from "./overview";
import Details from "./details";
import Purchase from "./purchase";
import Delete from "./delete";
import useDialog from "../../../shared/usedialog";
import NotFound from "../../../shared/notFound";

const FinancialReportRoutes = (props) => {
    var quickScanId = props.match.params["quickScanId"];

    const { data: quickScan, loading } = useAPI(() => getQuickScan(quickScanId), true, [
        quickScanId,
    ]);

    function getRequiredOperation(operationName) {
        if (!quickScan) {
            return operationName;
        }

        return {
            name: operationName,
            companyId: quickScan.authentication.companyId,
            assetCollectionId: quickScan.authentication.assetCollectionId,
        };
    }

    // Prevent overview from rendering behind the detail page
    return (
        <Fragment>
            {!loading && !quickScan && <NotFound element="quick scan" />}
            {quickScan && (
                <Switch>
                    <PrivateRoute
                        exact
                        requiredOperation={getRequiredOperation("financial-reports-view")}
                        path="/valuation/financial-report/:quickScanId(\d+)/:financialReportId(\d+)"
                        render={(props) => <Details {...props} />}
                    />

                    <PrivateRoute
                        requiredOperation={getRequiredOperation("financial-reports-view")}
                        path="/valuation/financial-report/:quickScanId(\d+)"
                        render={(props) => (
                            <FinancialReport
                                getRequiredOperation={getRequiredOperation}
                                quickScan={quickScan}
                                {...props}
                            />
                        )}
                    />
                </Switch>
            )}
        </Fragment>
    );
};

const FinancialReport = ({ getRequiredOperation, quickScan, ...props }) => {
    var quickScanId = props.match.params["quickScanId"];

    const { defaultPageSize, availablePageSizes } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
    });

    const { data, invoke, loading } = useAPI(
        () => getAll(quickScanId, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams, quickScanId]
    );

    const {
        open: handlePurchase,
        close: handleClosePurchase,
        visible: purchaseToggled,
        args: purchaseId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    return (
        <Fragment>
            <Overview
                data={data}
                searchParams={searchParams}
                availablePageSizes={availablePageSizes}
                loading={loading}
                quickScan={quickScan}
                getRequiredOperation={getRequiredOperation}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onDelete={handleDelete}
                onPurchase={handlePurchase}
                {...props}
            />

            <Purchase
                open={purchaseToggled}
                id={purchaseId}
                onClose={handleClosePurchase}
                history={props.history}
                quickScanId={quickScanId}
            />

            <Delete
                open={deleteToggled}
                onClose={handleCloseDelete}
                id={deleteArgs && deleteArgs.id}
                reportDate={deleteArgs && deleteArgs.purchasedDate}
            />
        </Fragment>
    );
};

export default () => {
    return (
        <Route
            path="/valuation/financial-report/:quickScanId(\d+)"
            render={(props) => <FinancialReportRoutes {...props} />}
        />
    );
};
