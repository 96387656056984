import interceptedFetch from "../interceptedFetch";

async function get() {
    return await interceptedFetch("/api/security/user/me");
}

async function logOut() {
    return await interceptedFetch("/api/security/user/logout", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { get, logOut };
