import makeStyles from "@material-ui/core/styles/makeStyles";

const ulPadding = 10;

const useStyles = makeStyles((theme) => ({
    header: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "10px 0 10px 10px",
        marginBottom: ulPadding,

        "&::after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            right: -ulPadding,
            left: -ulPadding,
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },

    titleAndPreTitle: {
        minWidth: 0,
        padding: "12px 0",

        "&.--inactive": {
            opacity: 0.7,
        },
    },

    preTitleText: {
        fontSize: "0.7em",
    },

    titleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
    },

    titleText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "capitalize",
    },

    archivedText: {
        flexGrow: 1,
        fontSize: "0.6em",
    },
}));

export default useStyles;
