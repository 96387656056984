import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    grid: {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -5px",
        padding: 0,
    },

    item: {
        position: "relative",
        margin: 5,
        height: "180px",
        listStyle: "none",
        transition: "box-shadow 0.2s ease-out",

        "&.--is-moving": {
            boxShadow: "0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.6)",
        },
    },

    dragHandle: {
        position: "absolute",
        top: 5,
        left: 5,
        backgroundColor: "rgba(255, 255, 255, 0.5)",

        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
        },

        "&:focus, .sortableItem.--is-moving &": {
            color: "black",
            backgroundColor: "white",
        },

        "&.--disabled": {
            color: "#444",
            opacity: 0.4,

            "&:hover, &:focus": {
                backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
        },
    },

    image: {
        height: "100%",
        maxWidth: "100%",
        objectFit: "cover",
    },

    itemBar: {
        position: "absolute",
        right: 0,
        bottom: 0,
        left: 0,
        padding: theme.spacing(0, 0, 0, 1.5),
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    titleAndDate: {
        minWidth: 0,
    },

    title: {
        fontWeight: "bold",
        color: "white",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    date: {
        color: "white",
    },

    actionMenu: {
        "& .MuiIconButton-root": {
            color: theme.palette.white,
        },
    },
}));

export default useStyles;
