import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    header: {
        position: "relative",
        borderBottom: `1px solid ${theme.palette.divider}`,

        "&:not(:empty)": {
            padding: "6px 10px 6px 20px",
        },

        "& .path": {
            marginLeft: 25,
        },
    },

    backButton: {
        marginLeft: "-10px",
        marginRight: 5,
    },

    titleAndButtonsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },

    titleWrapper: {
        marginRight: "auto",
        minWidth: 0,
    },

    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    selectButton: {
        color: theme.palette.primary.main,
        padding: "3px 10px",
        flexShrink: 0,
    },
}));

export default useStyles;
