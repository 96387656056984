import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(assetId, library, searchText) {
    const params = {
        searchText,
    };

    const url = `/api/assets/asset/${assetId}/media/${library}?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(assetId, library, id) {
    const url = `/api/assets/asset/${assetId}/media/${library}/${id}`;

    return await interceptedFetch(url);
}

async function move(assetId, library, id, currentPosition, newPosition) {
    var data = {
        currentPosition,
        newPosition,
    };

    return await interceptedFetch(`/api/assets/asset/${assetId}/media/${library}/${id}/move`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(data)),
    });
}

async function add(assetId, library, values) {
    return await interceptedFetch(`/api/assets/asset/${assetId}/media/${library}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(assetId, library, id, values) {
    return await interceptedFetch(`/api/assets/asset/${assetId}/media/${library}/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(assetId, library, id) {
    return await interceptedFetch(`/api/assets/asset/${assetId}/media/${library}/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, add, update, move, del };
