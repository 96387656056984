import React, { useEffect, useState, useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import { Lock as SealedIcon } from "@material-ui/icons";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Summary from "../../../shared/summary";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import QrCode from "../../../shared/qrCode"

export default ({ component }) => {
    const [summaryRows, setSummaryRows] = useState([]);
    const isLoaded = Boolean(component.id);
    const { selectedCulture } = useContext(LocalizationContext);

    useEffect(() => {
        if (!component) {
            setSummaryRows([]);
            return;
        }

        let newSummaryRows = [];

        function addToSummaryRows(resourceKey, defaultResourceValue, value) {
            if (!value) {
                return;
            }

            newSummaryRows.push({
                title: (
                    <ResourcedText resourceKey={resourceKey} defaultValue={defaultResourceValue} />
                ),
                data: value,
            });
        }

        addToSummaryRows(
            "Asset.component.summary.modelTypeField",
            "Model/Type",
            component.modelType
        );
        addToSummaryRows(
            "Asset.component.summary.identificationField",
            "Identification",
            component.identification
        );
        addToSummaryRows("Asset.component.summary.statusField", "Status", component.status);
        addToSummaryRows("Asset.component.summary.positionField", "Position", component.position);
        addToSummaryRows("Asset.component.summary.idField", "Component id", component.id);
        addToSummaryRows(
            "Asset.component.summary.consumableField",
            "Consumable",
            component.isConsumable ? (
                <ResourcedText
                    resourceKey="Asset.component.summary.consumableYes"
                    defaultValue="Yes"
                />
            ) : (
                <ResourcedText
                    resourceKey="Asset.component.summary.consumableNo"
                    defaultValue="No"
                />
            )
        );

        if (component.firstQrCode) {
            const qrUrl = window.location.origin + '/qr/' + component.firstQrCode;

            addToSummaryRows(
                "Asset.component.summary.firstQrCode",
                "QR Code",
                <QrCode qrCodeValue={qrUrl} />);
        }

        setSummaryRows(newSummaryRows);
    }, [component, setSummaryRows]);

    return !isLoaded ? (
        <LinearProgress />
    ) : (
        <Summary
            title={
                <ResourcedText
                    resourceKey="Asset.component.summary.header"
                    defaultValue="Component information"
                />
            }
            icon={component.isSealed ? <SealedIcon /> : null}
            description={component.description && component.description[selectedCulture]}
            rows={summaryRows}
        />
    );
};
