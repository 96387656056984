import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { CallSplit as ProcessIcon } from "@material-ui/icons";
import _ from "underscore";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import FormattedDate from "../../../shared/formattedDate";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onProcess,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <ContentHolder>
                <Header title={getResource("Security.applications.header.title", "Applications")} />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Security.applications.noResults",
                        "No applications available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.firstNameHeader"
                                        defaultValue="Firstname"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.surNameHeader"
                                        defaultValue="Surname"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.userEmailHeader"
                                        defaultValue="User e-mail"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.companyNameHeader"
                                        defaultValue="Company name"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.applicationDateHeader"
                                        defaultValue="Application date"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Security.applications.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() => onProcess(row.id)}
                                        requiredOperation="applications-view"
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.firstName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.userEmail}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.companyName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <FormattedDate date={row.createDate} includeTime />
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.isApproved === null && (
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Security.applications.processApplicationButton",
                                                                "Process"
                                                            ),
                                                            icon: <ProcessIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "applications-view",
                                                            onClick: () => onProcess(row.id),
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
