import React, { useState, useContext, Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Visibility as OpenIcon, Archive as ArchiveIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import ActionMenu from "../../../../shared/actionmenu";
import PagedList from "../../../../shared/pagedList";
import ConfigurationContext from "../../../../shared/configuration/configurationcontext";
import { get } from "../dataservice";
import useAPI from "../../../../shared/useapi";
import Search from "./valuationListSearch";
import FormattedDate from "../../../../shared/formattedDate";
import useDialog from "../../../../shared/usedialog";
import Archive from "../archive";

import _ from "underscore";
import withResources from "../../../../shared/textresources/withresources";
import ResourcedText from "../../../../shared/textresources/resourcedtext";

const useStyles = makeStyles((theme) => ({
    row: {
        cursor: "pointer",
    },
}));

const ValuationList = withResources(
    ({
        getResource,
        assetModelId,
        assetId,
        onEditValuation,
        requiredEditOperation,
        requiredArchiveOperation,
        name,
    }) => {
        const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
            ConfigurationContext
        );

        const classes = useStyles();

        const [searchParams, setSearchParams] = useState({
            page: 0,
            pageSize: defaultPageSize,
            statuses: [],
            createdBy: null,
        });

        const { loading, invoke, data } = useAPI(
            () =>
                get(
                    assetModelId,
                    assetId,
                    searchParams.createdBy,
                    searchParams.statuses,
                    searchParams.page + 1,
                    searchParams.pageSize
                ),
            true,
            [searchParams, assetModelId, assetId]
        );

        const {
            open: handleArchive,
            close: handleCloseArchive,
            visible: archiveToggled,
            args: archiveId,
        } = useDialog(invoke);

        function handlePageChange(page) {
            setSearchParams({ ...searchParams, page });
        }

        function handlePageSizeChange(pageSize) {
            setSearchParams({ ...searchParams, pageSize, page: 0 });
        }

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        function onSearch(createdBy, statuses) {
            setSearchParams({ ...searchParams, createdBy, statuses, page: 0 });
        }

        return (
            <Fragment>
                <PagedList
                    loading={loading}
                    itemCount={data && data.total}
                    noResultsText={getResource(
                        "Valuation.overview.noResults",
                        "No valuations available"
                    )}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => <Search onSearch={onSearchDebounced} />}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.overview.creationDateHeader"
                                        defaultValue="Creation date"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.overview.createdByHeader"
                                        defaultValue="Created by"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.overview.statusHeader"
                                        defaultValue="Status"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Valuation.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.items &&
                                data.items.map((row) => (
                                    <TableRow
                                        id={row.id}
                                        hover
                                        className={classes.row}
                                        key={row.id}
                                        onClick={() => onEditValuation(row.id)}
                                    >
                                        <TableCell component="td">
                                            <FormattedDate date={row.creationDate} includeTime />
                                        </TableCell>
                                        <TableCell component="td">{row.createdBy}</TableCell>
                                        <TableCell component="td">
                                            {getResource(
                                                `Valuation.status.${row.status}`,
                                                row.status
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Valuation.overview.openButton",
                                                            "Open"
                                                        ),
                                                        icon: <OpenIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: requiredEditOperation,
                                                        onClick: () => onEditValuation(row.id),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Valuation.overview.archiveButton",
                                                            "Archive"
                                                        ),
                                                        icon: <ArchiveIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: requiredArchiveOperation,
                                                        onClick: () => handleArchive(row.id),
                                                        hide: row.status !== "Published",
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>

                <Archive
                    id={archiveId}
                    name={name}
                    open={archiveToggled}
                    onClose={handleCloseArchive}
                />
            </Fragment>
        );
    }
);

export default ValuationList;
