import React, { useContext, useEffect } from "react";

import { startOfWeek, endOfWeek, addWeeks } from "date-fns";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";

import { getAvailability } from "../scheduledEvent/dataservice";

import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-availability", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        assetModelId: null,
        manufacturerId: null,
        dateRange: {
            startDate: startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
            endDate: endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }),
        },
    });

    const { loading, data } = useAPI(
        () =>
            getAvailability(
                searchParams.searchText,
                searchParams.assetModelId,
                searchParams.manufacturerId,
                searchParams.dateRange.startDate,
                searchParams.dateRange.endDate,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.assetModelId,
            searchParams.manufacturerId,
            searchParams.dateRange.startDate,
            searchParams.dateRange.endDate,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id,
            assetCollectionScope.id,
        ]
    );

    const handleSearch = (searchText, assetModelId, manufacturerId, dateRange) => {
        setSearchParams({
            ...searchParams,
            searchText,
            assetModelId,
            manufacturerId,
            dateRange,
            page: 0,
        });
    };

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <PrivateRoute
            requiredOperation={"assets-availability-view"}
            path="/planning/asset-availability"
            exact
            render={(props) => (
                <Overview
                    data={data}
                    searchParams={searchParams}
                    availablePageSizes={availablePageSizes}
                    loading={loading}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSearch={handleSearch}
                    defaultDebounceInterval={defaultDebounceInterval}
                    {...props}
                />
            )}
        />
    );
};
