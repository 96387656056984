import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import ResourcedTextField from "./textresources/resourcedtextfield";
import TranslateIcon from "@material-ui/icons/Translate";
import { makeStyles } from "@material-ui/styles";
import LocalizationContext from "./localization/localizationcontext";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 16,
        color: theme.palette.text.secondary,
    },

    popper: {
        marginTop: (props) => (props.margin === "normal" ? -15 : -7),
    },

    textFieldWrapper: {
        width: "100%",
    },
}));

const TranslatableTextField = (props) => {
    const {
        errors,
        margin,
        name,
        labelResourceKey,
        labelDefaultValue,
        fullWidth,
        multiline,
        rows,
        rowsMax,
        value,
        culture,
        inputProps,
        inputRef,
        handleChange,
        clearable,
        autoFocus,
        required,
    } = props;

    const { defaultCulture } = useContext(LocalizationContext);
    const classes = useStyles(props);
    const isDefaultCulture = defaultCulture === culture;
    const tooltipText = isDefaultCulture ? "" : value[defaultCulture] ? value[defaultCulture] : "";

    return (
        <Fragment>
            <Tooltip
                title={tooltipText}
                placement="bottom-end"
                classes={{ popper: classes.popper }}
            >
                <div className={clsx(classes.textFieldWrapper, fullWidth && "--is-fullwidth")}>
                    <ResourcedTextField
                        errors={errors}
                        margin={margin}
                        variant="outlined"
                        name={name}
                        labelResourceKey={labelResourceKey}
                        labelDefaultValue={labelDefaultValue}
                        type="text"
                        fullWidth={fullWidth}
                        multiline={multiline}
                        rowsMax={rowsMax}
                        rows={rows}
                        clearable={clearable}
                        inputRef={inputRef}
                        value={value[culture] || ""}
                        inputProps={inputProps}
                        onChange={(e) => handleChange(e, true)}
                        autoFocus={autoFocus}
                        AdornmentIcon={<TranslateIcon className={classes.icon} />}
                        adornmentPosition="end"
                        required={required}
                    />
                </div>
            </Tooltip>
        </Fragment>
    );
};

TranslatableTextField.propTypes = {
    errors: PropTypes.object,
    margin: PropTypes.string,
    name: PropTypes.string,
    labelResourceKey: PropTypes.string,
    labelDefaultValue: PropTypes.string,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.array,
    rowsMax: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    culture: PropTypes.string,
    inputProps: PropTypes.object,
    inputRef: PropTypes.object,
    handleChange: PropTypes.func,
    clearable: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

export default TranslatableTextField;
