import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 20px 20px",
    },
}));

const ContentHolder = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.root}>{children}</div>;
};

export default ContentHolder;
