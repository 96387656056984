import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    cultureSelectorGroup: {
        width: "calc(100% + 66px)",
        margin: theme.spacing(0, -4),
        height: 84,
        paddingBottom: theme.spacing(3),

        "&.--is-sticky": {
            position: "fixed",
            top: 0,
            zIndex: 100,
        },
    },

    cultureSelectorButton: {
        width: (props) => `calc(100% / ${props.count})`,
        border: 0,
        background: "#f4f6f8",
        color: "#90a4ae",
        borderTop: "2px solid #90a4ae",
        borderBottom: "2px solid transparent",
        borderRadius: 0,

        "&:hover": {
            backgroundColor: "#f0f0f1",
        },

        "&.--selected": {
            color: "#607d8B",
            borderBottomColor: "#3b41a1",
        },
    },

    alert: {
        marginBottom: theme.spacing(1),
    },

    footerWrapper: {
        position: "fixed",
        bottom: 0,
        width: "calc(100% - 64px)",
        padding: theme.spacing(6, 0, 2),
        zIndex: 1,
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",

        [theme.breakpoints.up("md")]: {
            width: "calc(700px - 64px)",
        },
    },

    footer: {
        display: "flex",
        justifyContent: "space-between",

        ".labelledOutline + &": {
            marginTop: 0,
        },

        "& .MuiButton-root": {
            marginTop: "0 !important",
        },
    },

    loader: {
        marginLeft: theme.spacing(1),
    },
}));

export default useStyles;
