import React from "react";
import { Fill } from "../../shared/slotmachine/index";
import withResources from "../../shared/textresources/withresources";
import Documents from "../document/documents/widget";
import WorkOrderMedia from "./workOrderMedia/widget";

const WorkOrderDocuments = withResources(({ getResource, workOrder }) => {
    return (
        <Documents
            reference={workOrder}
            referenceId={workOrder.id}
            library="workorder-documents-generic"
            title={getResource("Maintenance.workOrder.documents.widget.header", "Documents")}
            onRowClick={(id) =>
                window.open(
                    `/api/documents/libraries/workorder-documents-generic/documents/${id}/download`,
                    "_blank"
                )
            }
            hideAddButton
            hideHeaderLink
            pageSize={null} //all documents
        />
    );
});

export default () => {
    return [
        <Fill key="workOrderMedia" name="work-order-media">
            <WorkOrderMedia />
        </Fill>,
        <Fill key="workOrderDocuments" name="work-order-documents">
            <WorkOrderDocuments />
        </Fill>,
    ];
};
