import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Security.roles.delete.header", "Delete role?")}
            buttonText={getResource("Security.roles.delete.okButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Security.roles.delete.message"
                defaultValue="This will remove the role with all its assigned operations.<br /> Also all the role assignments for this role will be deleted. <br /><br />Are you sure?"
            />
        </Confirmation>
    );
});
