import React, { Fragment, useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { configure, get } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import Header from "../../../shared/header";
import LabelledOutline from "../../../shared/labelledoutline";
import LinksEditor, { validate as validateLinks } from "./components/linksEditor";
import ClusterEditor, { validate as validateClusters } from "./components/clusterEditor";
import UspBlockEditor, { validate as validateUspBlocks } from "./components/uspBlockEditor";
import Confirmation from "../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import TranslatableTextField from "../../../shared/translatableTextField";

const useStyles = makeStyles((theme) => ({
    configurationsPaper: {
        padding: `0 ${theme.spacing(4)}px ${theme.spacing(12)}px ${theme.spacing(4)}px`,
    },
}));

export default withResources(({ getResource }) => {
    const { data } = useAPI(() => get(), true);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const classes = useStyles();

    const { loading, invoke } = useAPI(configure);

    const onSubmit = async (values) => {
        const saveValues = {
            ...values.values,
            topAdvertisements: values.values.topAdvertisements.map((topAdvertisement) => {
                return {
                    ...topAdvertisement,
                    advertisements: topAdvertisement.advertisements.map((ad) => ad.id),
                };
            }),
            highlightedAdvertisements: values.values.highlightedAdvertisements.map(
                (highlightedAdvertisement) => {
                    return {
                        ...highlightedAdvertisement,
                        advertisements: highlightedAdvertisement.advertisements.map((ad) => ad.id),
                    };
                }
            ),
        };
        if (await invoke(saveValues)) {
            setConfirmationOpen(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        validateLinks(values, "headerLinks", errors, getResource, defaultCulture, true);
        validateLinks(values, "usps", errors, getResource, defaultCulture, false);
        validateUspBlocks(values, "uspBlocks", errors, getResource, defaultCulture);
        validateClusters(values, "topAdvertisements", errors, getResource, defaultCulture);
        validateClusters(values, "highlightedAdvertisements", errors, getResource, defaultCulture);
        validateLinks(values, "footerLinks", errors, getResource, defaultCulture, true);

        if (!values.copyright[defaultCulture]) {
            errors.copyright = getResource(
                "Marketplace.advertisement.edit.copyrightRequired",
                "Please enter a copyright for the default language"
            );
        }

        if (!values.organizationName[defaultCulture]) {
            errors.organizationName = getResource(
                "Marketplace.advertisement.edit.organizationNameRequired",
                "Please enter an organization name for the default language"
            );
        }

        if (!values.organizationDescription[defaultCulture]) {
            errors.organizationDescription = getResource(
                "Marketplace.advertisement.edit.organizationDescriptionRequired",
                "Please enter an organization description for the default language"
            );
        }

        if (!values.organizationPhone[defaultCulture]) {
            errors.organizationPhone = getResource(
                "Marketplace.advertisement.edit.organizationPhoneRequired",
                "Please enter an organization phone for the default language"
            );
        }

        return errors;
    };

    const isLoaded = data;

    return !isLoaded ? (
        <LinearProgress />
    ) : (
        <Paper className={classes.configurationsPaper}>
            <LocalizedForm
                initialValues={data}
                onIsDirty={() => null}
                loading={loading}
                okButtonText={getResource("Marketplace.configuration.okButton", "Update")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                showCultureSelector
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture,
                    resetValues
                ) => {
                    useEffect(() => {
                        async function reloadConfiguration() {
                            const response = await get();

                            if (!response.ok) {
                                return;
                            }

                            const newConfiguration = await response.json();
                            resetValues(newConfiguration);
                        }

                        if (confirmationOpen) {
                            reloadConfiguration();
                        }
                        // eslint-disable-next-line
                    }, [confirmationOpen]);

                    return (
                        <Fragment>
                            <Header
                                title={getResource(
                                    "Marketplace.configuration.header.title",
                                    "Marketplace configuration"
                                )}
                            />

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.headerLabel",
                                    "Header quick links"
                                )}
                            >
                                <LinksEditor
                                    name="headerLinks"
                                    errors={errors["headerLinks"]}
                                    values={values.headerLinks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={true}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource("Marketplace.configuration.uspLabel", "USP's")}
                            >
                                <LinksEditor
                                    name="usps"
                                    errors={errors["usps"]}
                                    values={values.usps}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={false}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.uspBlocksLabel",
                                    "USP blocks"
                                )}
                            >
                                <UspBlockEditor
                                    name="uspBlocks"
                                    errors={errors["uspBlocks"]}
                                    values={values.uspBlocks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    maxItems={3}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.topAdvertisementsLabel",
                                    "Top advertisements"
                                )}
                            >
                                <ClusterEditor
                                    name="topAdvertisements"
                                    errors={errors["topAdvertisements"]}
                                    values={values.topAdvertisements}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    allowAdd={false}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.highlightedAdvertisementsLabel",
                                    "Highlighted advertisements"
                                )}
                            >
                                <ClusterEditor
                                    name="highlightedAdvertisements"
                                    errors={errors["highlightedAdvertisements"]}
                                    values={values.highlightedAdvertisements}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    allowAdd={true}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.footerLabel",
                                    "Footer quick links"
                                )}
                            >
                                <LinksEditor
                                    name="footerLinks"
                                    errors={errors["footerLinks"]}
                                    values={values.footerLinks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={true}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.configuration.organizationLabel",
                                    "Organization meta data"
                                )}
                            >
                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="organizationName"
                                    labelResourceKey="Marketplace.configuration.organizationNameField"
                                    labelDefaultValue="Organization name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.organizationName}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="organizationDescription"
                                    labelResourceKey="Marketplace.configuration.organizationDescriptionField"
                                    labelDefaultValue="Description"
                                    fullWidth
                                    multiline
                                    value={values.organizationDescription}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="organizationPhone"
                                    labelResourceKey="Marketplace.configuration.organizationPhoneField"
                                    labelDefaultValue="Contact phone"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.organizationPhone}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />
                            </LabelledOutline>

                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="copyright"
                                labelResourceKey="Marketplace.configuration.copyrightField"
                                labelDefaultValue="Copyright"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.copyright}
                                culture={culture}
                                handleChange={handleChange}
                                required
                            />
                        </Fragment>
                    );
                }}
            />
            <Confirmation
                open={confirmationOpen}
                cancelHidden
                handleAction={() => setConfirmationOpen(false)}
                headerText={getResource(
                    "Marketplace.configuration.confirmation.header",
                    "Changes saved"
                )}
                buttonText={getResource("Marketplace.configuration.confirmation.buttonText", "Ok")}
                loading={loading}
            >
                {getResource(
                    "Marketplace.configuration.confirmation.content",
                    "All changes made to the marketplace configuration are saved successful"
                )}
            </Confirmation>
        </Paper>
    );
});
