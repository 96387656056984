import React, { useContext, useState } from "react";
import { Route } from "react-router";
import PrivateRoute from "../../../shared/authentication/privateroute";

import useAPI from "../../../shared/useapi";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import DocumentContext from "./documentcontext";

import Overview from "./overview";
import Delete from "./delete";
import Add from "./add";
import Edit from "./edit";
import Archive from "./archive";
import UnArchive from "./unArchive";
import { getAll } from "./dataservice";

import useDialog from "../../../shared/usedialog";

const Documents = (props) => {
    var referenceId = props.referenceId;
    var reference = props.reference;
    var library = props.match.params["library"];

    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
        name: null,
        type: null,
        isArchived: "false",
    });

    const { data, invoke, loading } = useAPI(
        () =>
            getAll(
                referenceId,
                library,
                searchParams.name,
                searchParams.type,
                searchParams.isArchived,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [searchParams, referenceId]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);
    const {
        open: handleArchive,
        close: handleCloseArchive,
        visible: archiveToggled,
        args: archiveArgs,
    } = useDialog(invoke);
    const {
        open: handleUnArchive,
        close: handleCloseUnArchive,
        visible: unArchiveToggled,
        args: unArchiveArgs,
    } = useDialog(invoke);

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function handleSearch(name, type, isArchived) {
        setSearchParams({ ...searchParams, name, type, isArchived, page: 0 });
    }

    return (
        <React.Fragment>
            <Overview
                data={data}
                searchParams={searchParams}
                availablePageSizes={availablePageSizes}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onSearch={handleSearch}
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onArchive={handleArchive}
                onUnArchive={handleUnArchive}
                loading={loading}
                reference={reference}
                defaultDebounceInterval={defaultDebounceInterval}
                {...props}
            />
            <Add
                library={library}
                referenceId={referenceId}
                open={addToggled}
                onClose={handleCloseAdd}
            />
            <Edit
                library={library}
                referenceId={referenceId}
                id={editId}
                open={editToggled}
                onClose={handleCloseEdit}
            />
            <Delete
                library={library}
                referenceId={referenceId}
                id={deleteId}
                open={deleteToggled}
                onClose={handleCloseDelete}
            />

            <Archive
                open={archiveToggled}
                onClose={handleCloseArchive}
                id={archiveArgs && archiveArgs.id}
                name={archiveArgs && archiveArgs.name}
                library={library}
            />

            <UnArchive
                open={unArchiveToggled}
                onClose={handleCloseUnArchive}
                id={unArchiveArgs && unArchiveArgs.id}
                name={unArchiveArgs && unArchiveArgs.name}
                library={library}
            />
        </React.Fragment>
    );
};

const DocumentsWrapper = (props) => {
    const { getRequiredOperation, getReferenceMethod, isSystemLibrary } = useContext(
        DocumentContext
    );

    var referenceId = new URLSearchParams(props.location.search).get("referenceId");
    var library = props.match.params.library;
    var apiCall = getReferenceMethod(library);

    const { data: reference } = useAPI(
        () => apiCall(referenceId),
        Boolean(apiCall) && Boolean(referenceId),
        [referenceId, apiCall]
    );

    return (
        (isSystemLibrary(library) || reference) && (
            <PrivateRoute
                requiredOperation={getRequiredOperation(library, "download", reference)}
                path="/documents/documents/:library([a-z, -]+)"
                render={(props) => (
                    <Documents reference={reference} referenceId={referenceId} {...props} />
                )}
            />
        )
    );
};

export default (props) => {
    return (
        <Route
            path="/documents/documents/:library([a-z, -]+)"
            render={(props) => <DocumentsWrapper {...props} />}
        />
    );
};
