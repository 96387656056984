class AuthorizationService {
    constructor(assignedOperations, isAdmin) {
        this.assignedOperations = assignedOperations;
        this.isAdmin = isAdmin;
    }

    isAuthorized(operation) {
        let authorized = false;
        if (this.isAdmin) {
            return true;
        }

        if (!operation) {
            return false;
        }

        if (typeof operation === "string") {
            return this.assignedOperations.some((o) => o.operation === operation);
        }

        if (!authorized && operation.companyId) {
            authorized = this.assignedOperations.some(
                (o) =>
                    o.operation === operation.name && //operation name is the same
                    (o.companies.some((x) => x === operation.companyId) || //companyId is te same
                        ((!o.companies || o.companies.length === 0) && !o.assetCollectionId)) //user has global permission
            );
        }

        if (!authorized && operation.assetCollectionId) {
            if (typeof operation.assetCollectionId === "number") {
                authorized = this.assignedOperations.some(
                    (o) =>
                        o.operation === operation.name &&
                        (operation.assetCollectionId === o.assetCollectionId || //assetCollectionId is the same
                            ((!o.companies || o.companies.length === 0) && !o.assetCollectionId)) //user has global permission
                );
            } else {
                authorized = this.assignedOperations.some(
                    (o) =>
                        o.operation === operation.name && //operation name is the same
                        (operation.assetCollectionId.some(
                            (assetCollectionId) => assetCollectionId === o.assetCollectionId
                        ) || //assetCollectionId is the same
                            ((!o.companies || o.companies.length === 0) && !o.assetCollectionId)) //user has global permission
                );
            }
        }

        if (!authorized && !operation.companyId && !operation.assetCollectionId) {
            authorized = this.assignedOperations.some(
                (o) =>
                    o.operation === operation.name && //operation name is the same
                    (!o.companies || o.companies.length === 0) &&
                    !o.assetCollectionId //user has global permission
            );
        }

        return authorized;
    }

    hasAuthorizationOnCompanyLevel(operation) {
        if (this.isAdmin) {
            return true;
        }

        if (!operation) {
            return false;
        }

        return this.assignedOperations.some(
            (o) => o.operation === operation && o.companies.length > 0
        );
    }

    hasAuthorizationOnAssetCollectionLevel(operation) {
        if (this.isAdmin) {
            return true;
        }

        if (!operation) {
            return false;
        }

        return this.assignedOperations.some(
            (o) => o.operation === operation && o.assetCollectionId !== null
        );
    }
}

export default AuthorizationService;
