import React, { Fragment } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";

import useAPI from "../../../shared/useapi";
import { get } from "./../asset/dataservice";
import { getAssetAttributeDefinitions } from "../../library/assetmodels/dataservice";
import Overview from "./overview";
import NotFound from "../../../shared/notFound";

export default (props) => {
    const id = props.match.params.id;

    const { invoke: loadAsset, data: asset, loading } = useAPI(() => get(id), true, [id]);
    const { data: attributeDefinitions } = useAPI(
        () => getAssetAttributeDefinitions(asset.assetModel.id),
        asset && Boolean(asset.assetModel.id),
        [asset]
    );

    const refresh = () => {
        loadAsset(id);
    };

    return (
        <Fragment>
            {asset && attributeDefinitions && (
                <PrivateRoute
                    requiredOperation={{
                        name: "assets-view",
                        companyId: asset.companyId,
                        assetCollectionId: asset.assetCollectionIds,
                    }}
                    path="/assets/assets/:id(\d+)"
                    render={(props) => (
                        <Overview
                            asset={asset}
                            attributeDefinitions={attributeDefinitions}
                            onRefresh={refresh}
                            {...props}
                        />
                    )}
                />
            )}
            {!loading && !asset && <NotFound element="asset" />}
        </Fragment>
    );
};
