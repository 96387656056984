import React from "react";

import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import Icon from "@material-ui/icons/LockOpen";

import ResourcedText from "../../../shared/textresources/resourcedtext";
import ResetForm from "./resetForm";

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: "100%",
    },
    content: {
        padding: theme.spacing(8, 4, 3, 4),
        margin: "auto",
    },
    root: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(6, 2),
    },
    card: {
        width: theme.breakpoints.values.md,
        maxWidth: "100%",
        overflow: "unset",
        display: "flex",
        position: "relative",
        "& > *": {
            flexGrow: 1,
            flexBasis: "50%",
            width: "50%",
        },
    },
    media: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        padding: theme.spacing(3),
        color: theme.palette.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    icon: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: "absolute",
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32,
    },
    fields: {
        margin: theme.spacing(-1),
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
}));

export default () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <Icon className={classes.icon} />
                        <Typography gutterBottom variant="h3">
                            <ResourcedText
                                resourceKey="Security.resetPassword.title"
                                defaultValue="Reset your password"
                            />
                        </Typography>
                        <ResourcedText
                            resourceKey="Security.resetPassword.text"
                            defaultValue="You can reset your password by entering and confirming your new password and selecting reset"
                        />

                        <ResetForm />
                    </CardContent>
                    <CardMedia
                        className={classes.media}
                        image={`/${Math.ceil(Math.random() * 6)}.jpg`}
                        title="Cover"
                    ></CardMedia>
                </Card>
            </div>
        </main>
    );
};
