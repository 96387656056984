import React from "react";
import { Redirect, Route } from "react-router";
import Unauthorized from "../../features/security/unauthorized";
import useAuthorization from "./useauthorization";

// The passed in operation can be string, object or a function used to resolve the operation.
// If its an function we pass the current route parameters to the function.
function addMatchToFactory(operation, match) {
    if (!operation || !(operation instanceof Function)) {
        return operation;
    }

    if (operation.constructor.name === "AsyncFunction") {
        return async () => await operation(match);
    }

    return () => operation(match);
}

// This needs to be a component instead of using the Route's render method, because we need to
// use the useAuthorization hook. Hooks only work in components.
const Inner = ({
    match,
    location,
    children,
    component,
    requiredOperation,
    requiredOperationAtLeastOneOf,
    ...rest
}) => {
    const { isAuthenticated, isAuthorized, isLoading } = useAuthorization(
        addMatchToFactory(requiredOperation, match),
        requiredOperationAtLeastOneOf
            ? requiredOperationAtLeastOneOf.map(addMatchToFactory, match)
            : null,
        match
    );

    if (isLoading) {
        return null;
    }

    if (isAuthenticated && isAuthorized) {
        return (
            <Route {...rest} component={component}>
                {children}
            </Route>
        );
    }

    if (isAuthenticated) {
        return <Unauthorized state={{ from: location }} />;
    }

    return (
        <Redirect
            to={{
                pathname: "/login",
                state: { from: location },
            }}
        />
    );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Based on: https://reacttraining.com/react-router/web/example/auth-workflow
const PrivateRoute = (props) => {
    const { children, component, render, ...propsWithoutRenderOptions } = props;
    return (
        <Route
            {...propsWithoutRenderOptions}
            render={(nestedProps) => {
                return <Inner {...props} {...nestedProps} />;
            }}
        />
    );
};

export default PrivateRoute;
