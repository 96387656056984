import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(isApproved, page, pageSize) {
    const params = {
        page: page,
        pageSize: pageSize,
        isApproved: isApproved,
    };

    const url = `/api/security/application?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/security/application/${id}`;

    return await interceptedFetch(url);
}

async function approve(id, values) {
    const url = `/api/security/application/${id}/approve`;

    return await interceptedFetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function reject(id) {
    const url = `/api/security/application/${id}/reject`;

    return await interceptedFetch(url, {
        method: "POST",
    });
}

async function add(values) {
    return await interceptedFetch("/api/security/user/apply", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

export { getAll, get, approve, reject, add };
