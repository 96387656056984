import React, { useState } from "react";
import DrillDownInput from "../../../shared/drilldowninput";
import {
    getChildren,
    getParents,
    getPath,
    filter,
} from "./../../library/assetcategories/dataservice";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.assetCategory);
    }

    return (
        <PageListSearch
            searchFields={[
                <DrillDownInput
                    name="assetCategory"
                    itemName={getResource(
                        "Valuation.questionnaire.search.assetCategoryItemName",
                        "category"
                    )}
                    label={getResource(
                        "Valuation.questionnaire.search.assetCategoryField",
                        "Category"
                    )}
                    getParents={getParents}
                    getChildren={getChildren}
                    getPath={getPath}
                    filter={filter}
                    value={searchValues.assetCategory}
                    setValue={(e) =>
                        handleValuesChanged({ ...searchValues, assetCategory: e.target.value })
                    }
                />,
            ]}
        />
    );
});

export default Search;
