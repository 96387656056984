import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
}));

export default useStyles;
