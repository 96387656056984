import React, { useState, useEffect, useContext, Fragment } from "react";
import { Button, LinearProgress, CircularProgress } from "@material-ui/core";
import {
    ShoppingCart as PurchaseButton,
    NavigateBefore as PreviousButton,
    NavigateNext as NextButton,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import withResources from "../../../shared/textresources/withresources";
import useAPI from "../../../shared/useapi";
import { getBatchInformation, purchaseBatch } from "./dataservice";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

import FormDrawer from "../../../shared/formDrawer/formDrawer";
import InvalidAssets from "./components/invalidAssets";
import FutureValues from "../quickscan/components/futureValues";
import Confirmation from "../../../shared/confirmation";
import ChooseUser from "./components/chooseUser";

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: theme.spacing(2),
    },
    error: {
        backgroundColor: "red",
        padding: theme.spacing(2),
    },
    infoText: {
        marginBottom: theme.spacing(2),
    },
}));

const AssetBatchPurchase = withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { authorizationService } = useContext(AuthenticationContext);
    const { companyScope } = useContext(WorkingScopeContext);

    const classes = useStyles();

    const {
        loading: batchInformationLoading,
        data: batchInformation,
        invoke: loadBatchInformation,
    } = useAPI(
        () => getBatchInformation(quickScanBatchPurchase && quickScanBatchPurchase.futureMonths),
        false
    );

    const [quickScanBatchPurchase, setQuickScanBatchPurchase] = useState({
        userId: null,
        purchaseFutureValues: true,
        acknowledge: false,
        validAssets: null,
        purchased: false,
    });

    const [currentStep, setCurrentStep] = useState(0);

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const goToNextStep = async () => {
        if (!getCurrentStep().beforeNextAction || (await getCurrentStep().beforeNextAction())) {
            setCurrentStep(currentStep + 1);
        }
    };

    const getCurrentStep = () => {
        return getSteps()[currentStep];
    };

    const inLastStep = () => currentStep === getSteps().length - 1;

    const getCurrentStepControl = () => {
        return getCurrentStep().render();
    };

    const isNextButtonDisabled = () => {
        return getCurrentStep().isNextButtonDisabled && getCurrentStep().isNextButtonDisabled();
    };

    const getSteps = () => {
        var chooseUser = {
            infoText: getResource(
                "Valuation.batchQuickScan.chooseUser.infoText",
                "Select the user on whose behalf you are making this batch quick scan."
            ),
            render: () => (
                <ChooseUser
                    quickScan={quickScanBatchPurchase}
                    companyId={companyScope.id}
                    onChange={(userId) => {
                        var copy = {
                            ...quickScanBatchPurchase,
                            userId,
                        };

                        setQuickScanBatchPurchase(copy);
                    }}
                />
            ),
            isStepComplete: () => quickScanBatchPurchase.userId != null,
        };

        var futureValues = {
            infoText: getResource(
                "Valuation.quickScan.futureValues.purchase.infoText",
                "In addition to purchasing a current value you can also purchase a future value. This future value will be included in the quick scan report."
            ),
            render: () => (
                <React.Fragment>
                    <FutureValues quickScan={quickScanBatchPurchase} onChange={handleChange} />
                </React.Fragment>
            ),
            beforeNextAction: async () => {
                await loadBatchInformation();
                return true;
            },
        };

        var invalidAssets = {
            infoText: "",
            render: () => (
                <InvalidAssets
                    invalidAssets={batchInformation.invalidAssets}
                    validAssets={batchInformation.validAssets}
                />
            ),
            isNextButtonDisabled: () => batchInformation.validAssets.length === 0,
        };

        var purchase = {
            infoText: getResource(
                "Valuation.assetQuickScanBatchPurchase.confirmationText",
                "By confirming the purchase you will be billed for {validAssetCount} quick scans.",
                { validAssetCount: batchInformation ? batchInformation.validAssets.length : 0 }
            ),
            render: () => <Fragment></Fragment>, // This step doesn't need to render anything, infoText is enough
        };

        var steps = [futureValues, purchase];

        if (batchInformation && batchInformation.invalidAssets.length !== 0) {
            steps.splice(1, 0, invalidAssets);
        }

        if (authorizationService.isAuthorized("asset-quick-scans-purchase-batch")) {
            steps = [chooseUser, ...steps];
        }

        return steps;
    };

    const { loading: saving, invoke } = useAPI(() => purchaseBatch(quickScanBatchPurchase));

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        var copy = {
            ...quickScanBatchPurchase,
            [name]: value,
        };

        setQuickScanBatchPurchase(copy);
    };

    const handleSubmit = async () => {
        if (await invoke()) {
            handleChange({ target: { name: "purchased", value: true } });
        }
    };

    var loading = batchInformationLoading;

    useEffect(() => {
        if (batchInformation && !quickScanBatchPurchase.validAssets) {
            setQuickScanBatchPurchase({
                ...quickScanBatchPurchase,
                validAssets: batchInformation.validAssets.map((asset) => asset.id),
            });
        }
    }, [batchInformation, quickScanBatchPurchase]);

    return (
        <FormDrawer
            title={getResource(
                "Valuation.assetQuickScanBatchPurchase.header.title",
                "Purchase quick scan batch"
            )}
            onClose={onClose}
            size="medium"
        >
            {loading ? (
                <LinearProgress />
            ) : (
                <Fragment>
                    <div className={classes.infoText}>{getCurrentStep().infoText}</div>

                    {getCurrentStepControl()}

                    <div className={classes.content}>
                        {currentStep !== 0 && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={goToPreviousStep}
                                startIcon={<PreviousButton />}
                            >
                                {getResource("Valuation.quickScan.previousButton", "Previous")}
                            </Button>
                        )}
                        {!inLastStep() && (
                            <Button
                                disabled={isNextButtonDisabled()}
                                color="primary"
                                variant="contained"
                                onClick={goToNextStep}
                                startIcon={<NextButton />}
                            >
                                {getResource("Valuation.quickScan.nextButton", "Next")}
                            </Button>
                        )}

                        {inLastStep() && (
                            <Fragment>
                                <Button
                                    disabled={saving || quickScanBatchPurchase.purchased}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    endIcon={<PurchaseButton />}
                                >
                                    {saving && <CircularProgress size={20} />}
                                    {getResource(
                                        "Valuation.assetQuickScanBatchPurchase.confirmPurchaseButton",
                                        "Confirm purchase"
                                    )}
                                </Button>
                                <Confirmation
                                    open={quickScanBatchPurchase.purchased}
                                    cancelHidden={true}
                                    handleAction={onClose}
                                    headerText={getResource(
                                        "Valuation.assetQuickScanBatchPurchase.succeededHeader",
                                        "Purchase succeeded"
                                    )}
                                    buttonText={getResource(
                                        "Valuation.assetQuickScanBatchPurchase.succeededButton",
                                        "Close"
                                    )}
                                >
                                    {getResource(
                                        "Valuation.assetQuickScanBatchPurchase.succeededText",
                                        "{validAssetCount} quick scans are being created in the background and should be available shortly.",
                                        { validAssetCount: batchInformation.validAssets.length }
                                    )}
                                </Confirmation>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            )}
        </FormDrawer>
    );
});

export default AssetBatchPurchase;
