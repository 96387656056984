import React, { Fragment, useContext } from "react";

import useAPI from "../../../../shared/useapi";
import { getAll as getAssetMedia } from "../../../assets/assetMedia/dataservice";
import { getAllContainingLibrary as getDocuments } from "../../../document/documents/dataservice";

import withResources from "../../../../shared/textresources/withresources";
import ConfigurationContext from "../../../../shared/configuration/configurationcontext";
import LabelledOutline from "../../../../shared/labelledoutline";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import AdditionalInformationEditor, {
    validate as validateAdditionalInformation,
} from "../../advertisements/components/additionalInformationEditor";
import ContactInformationEditor, {
    validate as validateContactInformation,
} from "../../advertisements/components/contactInformation";
import ImageSelect from "../../../../shared/imageSelect";
import TranslatableTextField from "../../../../shared/translatableTextField";

export default withResources(
    ({ getResource, culture, values, handleChange, errors, isDefaultCulture }) => {
        const {
            marketplace: { advertisementDocumentLibrary, advertisementMediaLibrary },
        } = useContext(ConfigurationContext);

        const assetId = (values.asset && values.asset.id) || values.assetId; // Asset that is selected or from edit to fetch asset media and documents

        const { data: assetMedia } = useAPI(
            () => getAssetMedia(assetId, advertisementMediaLibrary),
            assetId,
            [assetId]
        );

        const { data: documents } = useAPI(
            () => getDocuments(assetId, advertisementDocumentLibrary),
            assetId,
            [assetId]
        );

        return (
            <Fragment>
                <TranslatableTextField
                    errors={errors}
                    margin="normal"
                    name="title"
                    labelResourceKey="Marketplace.auction.advertisementForm.titleField"
                    labelDefaultValue="Title"
                    fullWidth
                    value={values.title}
                    culture={culture}
                    inputProps={{ maxLength: 255 }}
                    handleChange={handleChange}
                    required
                />

                <TranslatableTextField
                    errors={errors}
                    margin="normal"
                    name="description"
                    labelResourceKey="Marketplace.auction.advertisementForm.descriptionField"
                    labelDefaultValue="Description"
                    fullWidth
                    value={values.description}
                    culture={culture}
                    inputProps={{ maxLength: 255 }}
                    handleChange={handleChange}
                    multiline
                />

                <ImageSelect
                    errors={errors}
                    name="media"
                    label={getResource(
                        "Marketplace.auction.advertisementForm.assetMediaField",
                        "Asset media"
                    )}
                    value={values.media}
                    images={assetMedia}
                    getImageSource={(image) =>
                        `/api/assets/asset/${assetId}/media/Generic/${image.id}/view?imageSize=MediaPicker&v=${image.fileId}`
                    }
                    noImagesText={getResource(
                        "Marketplace.auction.advertisementForm.noAssetMediaAvailable",
                        "No image available for this asset"
                    )}
                    onSelection={handleChange}
                    required
                />

                <AutoCompleteStatic
                    errors={errors}
                    name="documents"
                    label={getResource(
                        "Marketplace.auction.advertisementForm.documentField",
                        "Documents"
                    )}
                    fullWidth
                    isMedium
                    multiple
                    keepOnOneLine={false}
                    value={values.documents}
                    onSelection={handleChange}
                    options={documents && documents.items}
                    disableClearable
                    getOptionLabel={(option) => {
                        return `${option.name} - ${option.type.toLowerCase()}`;
                    }}
                />

                <LabelledOutline
                    label={getResource(
                        "Marketplace.auction.advertisementForm.additionalInformationLabel",
                        "Additional information"
                    )}
                >
                    <AdditionalInformationEditor
                        name="additionalInformation"
                        errors={errors["additionalInformation"]}
                        values={values.additionalInformation}
                        onChange={(e) => handleChange(e)}
                        culture={culture}
                        disabled={!isDefaultCulture}
                    />
                </LabelledOutline>

                <LabelledOutline
                    label={getResource(
                        "Marketplace.auction.advertisementForm.sellerInformationLabel",
                        "Seller information"
                    )}
                >
                    <ContactInformationEditor
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        isDefaultCulture={isDefaultCulture}
                    />
                </LabelledOutline>
            </Fragment>
        );
    }
);

export function validate(values, defaultCulture, errors, getResource) {
    if (!values.title[defaultCulture]) {
        errors.title = getResource(
            "Marketplace.auction.advertisementForm.titleRequired",
            "Please enter a title for the default language"
        );
    }

    if (!values.media || values.media.length === 0) {
        errors.media = getResource(
            "Marketplace.auction.advertisementForm.mediaRequired",
            "Please select at least one media image"
        );
    }

    validateAdditionalInformation(
        values,
        "additionalInformation",
        errors,
        getResource,
        defaultCulture
    );

    validateContactInformation(values, getResource, errors);

    return errors;
}
