const getStatusColor = (status) => {
    if (!status) {
        return null;
    }

    switch (status) {
        case "Cancelled":
        case "OnHold":
            return "red";
        case "Draft":
        case "Open":
        case "QuoteRequested":
        case "Proposed":
        case "InProgress":
            return "orange";
        default:
            return "green";
    }
};

export default getStatusColor;
