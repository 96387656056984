import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    buttonFilter: {
        display: "flex",
        alignItems: "center",

        "& .MuiChip-root": {
            marginRight: theme.spacing(1),
            backgroundColor: theme.palette.white,

            "&.active": {
                backgroundColor: theme.palette.black,
                color: theme.palette.white,
            },
        },
    },
}));

export default useStyles;
