import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { revoke } from "./dataservice";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, onClose, id, userId, userName, roleName }) => {
    if (!open) {
        return null;
    }

    const { loggedInUser } = useContext(AuthenticationContext);

    const { loading, invoke } = useAPI(revoke);

    function handleClose() {
        onClose(false);
    }

    const handleRevoke = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleRevoke}
            handleClose={handleClose}
            headerText={getResource("Security.permissions.revoke.header", "Revoke permission?")}
            buttonText={getResource("Security.permissions.revoke.okButton", "Revoke")}
            buttonHidden={loggedInUser.id === userId}
        >
            {loggedInUser.id === userId ? (
                <ResourcedText
                    resourceKey="Security.permissions.revoke.cannotRevokeOwnPermissions"
                    defaultValue="You cannot revoke your own permissions"
                />
            ) : (
                <ResourcedText
                    resourceKey="Security.permissions.revoke.message"
                    defaultValue="Are you sure you want to revoke the <u>{roleName}</u> permission for <u>{userName}</u>?"
                    tokens={{ userName, roleName }}
                />
            )}
        </Confirmation>
    );
});
