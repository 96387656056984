import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    imageGallery: {
        backgroundColor: "white",

        "& .image-gallery-content": {
            backgroundColor: "white",
        },

        "& .image-gallery-description": {
            bottom: 0,
        },

        "& .image-gallery-thumbnail": {
            borderRadius: 6,
            marginRight: theme.spacing(0.5),
            border: "2px solid transparent",
            width: 60,
            height: 45,
            transition: "border 0.1s linear",
            cursor: "pointer",

            "&.active, &:hover": {
                borderColor: theme.palette.primary.main,
            },
        },

        "& .image-gallery-thumbnail-inner": {
            backgroundSize: "cover !important",
            height: "100%",
            width: "100%",
            borderRadius: 4,
            border: "1px solid white",
        },

        "& .image-gallery-thumbnails": {
            padding: 0,
        },

        "& .image-gallery-slides": {
            paddingBottom: theme.spacing(1),
        },

        "& .image-gallery-slide": {
            outline: "none",
        },

        "& .image-gallery-image": {
            height: 250,
            objectFit: "cover",
            outline: "none",
            cursor: "pointer",
            userDrag: "none",
        },

        "& .image-gallery-icon": {
            filter: "none",
        },

        "& .image-gallery-svg": {
            color: "white",
            width: 15,
        },

        "& .image-gallery-left-nav, & .image-gallery-right-nav": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            top: 0,
            transform: "none",
            height: "calc(100% - 8px)",
            transition: "background 0.2s",

            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.15)",
            },

            "& .image-gallery-svg": {
                height: "100%",
                strokeWidth: 2,
            },
        },

        "& .image-gallery-fullscreen-button": {
            top: 0,
            bottom: "auto",
            padding: "15px 10px",

            "& .image-gallery-svg": {
                height: "100%",
                strokeWidth: 4,
            },
        },

        /**
         * Fullscreen:
         **/
        "& .image-gallery-content.fullscreen": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        },

        "& .fullscreen .image-gallery-image": {
            height: "calc(100vh - 61px)",
            maxHeight: "none",
            objectFit: "contain",
            backgroundColor: "#000",
        },
    },
}));

export default useStyles;
