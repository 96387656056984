import React, { useRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";

import { forgotPassword } from "./dataservice";
import useAPI from "../../../shared/useapi";
import useForm from "../../../shared/forms/useform";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import { config } from "config";

export default withResources(({ getResource, handleClose, email }) => {
    const { loading, invoke } = useAPI(forgotPassword);
    const formId = useRef("id_" + Math.random().toString(36).substr(2, 9));

    const onSubmit = async ({ values }) => {
        if (await invoke(values.email)) {
            handleClose();
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = getResource(
                "Security.forgotPassword.enterEmail",
                "Please enter a e-mail"
            );
        }

        if (values.email && !values.email.match(config.emailRegex)) {
            errors.email = getResource(
                "Security.forgotPassword.enterValidEmail",
                "The provided e-mail isn't a valid e-mail address"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { email: email },
        onSubmit: onSubmit,
        formId: formId.current,
        validate: onValidate,
    });

    return (
        <Dialog
            open
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                <ResourcedText
                    resourceKey="Security.forgotPassword.title"
                    defaultValue="Reset password"
                />
            </DialogTitle>
            <DialogContent id={formId.current}>
                <DialogContentText id="form-dialog-description">
                    <ResourcedText
                        resourceKey="Security.forgotPassword.text"
                        defaultValue="Enter your email address below and click send reset password link below and we will send you an email with a link to reset your password."
                    />
                </DialogContentText>
                <ResourcedTextField
                    fullWidth
                    errors={errors}
                    autoFocus
                    label="Email address"
                    labelResourceKey="Security.forgotPassword.emailField"
                    labelDefaultValue="E-mail"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    <ResourcedText
                        resourceKey="Security.forgotPassword.cancelButton"
                        defaultValue="Cancel"
                    />
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    color="primary"
                    variant="contained"
                >
                    <ResourcedText
                        resourceKey="Security.forgotPassword.resetPasswordButton"
                        defaultValue="Send reset password link"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
});
