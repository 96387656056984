import { makeStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";

const noOptionsText = colors.grey[600];

const useStyles = makeStyles((theme) => ({
    selectContainer: {
        display: "inline-flex",
        verticalAlign: "top",
        flexWrap: "wrap",
        maxWidth: "100%",
        width: 300,

        "&.--medium:not(.--no-margin)": {
            margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        },

        "&.--fullwidth": {
            width: "100%",
        },
    },

    pageBlocker: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
    },

    selectElement: {
        position: "relative",
        padding: "5px 3px 3px 11px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        border: "1px solid #dadbdb",
        boxShadow: "none",
        transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "var(--background-color)",

        "&:hover": {
            borderColor: theme.palette.black,
        },

        "&:hover:not(.--no-options,--disabled)": {
            cursor: "pointer",
        },

        "&.--medium": {
            minHeight: 56,
            paddingTop: 12,
            paddingBottom: 10,
        },

        "&.--disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            borderColor: "rgba(0, 0, 0, 0.28)",
        },

        "&.--error": {
            borderColor: theme.palette.error.main,
        },

        "&.--no-options": {
            borderColor: "rgba(0,0,0,0.10)",

            "& .autoCompleteLabel": {
                color: noOptionsText,

                "& .MuiInputLabel-asterisk": {
                    marginLeft: theme.spacing(1),
                },
            },

            "& .autoCompleteNoOptionsText": {
                fontSize: "0.95em",
                fontStyle: "italic",
                color: noOptionsText,
            },
        },
    },

    label: {
        position: "absolute",
        top: 0,
        left: 9,
        padding: "0 4px",
        fontSize: 12,
        lineHeight: "11px",
        transform: "translateY(-6px)",

        "&.--error": {
            color: theme.palette.error.main,
        },

        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 1px)",
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "var(--background-color)",
            zIndex: -1,
        },
    },

    chipsWrapper: {
        position: "relative",
        flexGrow: 1,

        "&.--keep-on-one-line": {
            whiteSpace: "nowrap",
            overflow: "hidden",
        },

        "&.--with-fading-gradient::after": {
            content: "''",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            background:
                "linear-gradient(to right, rgba(255, 255, 255, 0.001), var(--background-color))",
            width: 50,
        },

        "&.--disabled&::before": {
            content: "''",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
        },
    },

    chip: {
        margin: "3px 6px 3px 0",

        "&:hover": {
            cursor: "pointer",
        },

        ".--keep-on-one-line &": {
            marginTop: 0,
            marginBottom: 0,
        },

        "&.--show-as-text": {
            backgroundColor: "transparent",

            "& .MuiChip-label": {
                paddingLeft: 2,
                paddingRight: 8,
                fontSize: 16,
            },
        },
    },

    clearIconButton: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: 4,
        marginRight: -2,
        opacity: 0,

        ".autoCompleteSelect:hover &, &.Mui-focusVisible": {
            opacity: 1,
        },

        "& svg": {
            fontSize: 13,
        },
    },

    autoCompleteToggle: {
        /** To be able to set focus on the button with JS from outside of the React component **/
        "&:focus:not(.Mui-focusVisible)": {
            backgroundColor: colors.grey[300],
        },
    },

    popper: {
        marginTop: 5,
        border: "1px solid #263238",
        boxShadow: "0 3px 12px rgba(27,31,35,.15)",
        borderRadius: 3,
        zIndex: 10001,
        fontSize: 13,
        color: "#586069",
        overflow: "hidden",
        backgroundColor: theme.palette.white,
    },

    autoCompleteHelperText: {
        marginBottom: 0,
        paddingBottom: 8,
    },

    autocompleteRoot: {
        backgroundColor: "white",
        borderRadius: 3,
    },

    autocompletePaper: {
        boxShadow: "none",
        margin: 0,
        color: "#586069",
        fontSize: 13,
        borderRadius: 0,
    },

    listbox: {
        maxHeight: "var(--listbox-max-height) !important",
        padding: 0,
    },

    autocompleteAdornment: {
        marginRight: 4,
        marginLeft: 5,
    },

    autocompleteTextField: {
        "& .MuiInputBase-root": {
            padding: "3px 9px !important",
        },

        "& input": {
            margin: "5px 0",
        },

        "& fieldset": {
            borderColor: `rgba(0,0,0,0.15) !important`,
            borderWidth: "0 0 1px 0 !important",
            borderRadius: 0,
        },
    },

    option: {
        display: "flex",
        alignItems: "center",
        padding: "5px 13px",

        '&[aria-selected="true"]:not([data-focus="true"])': {
            backgroundColor: "transparent",
        },
    },

    optionCheckboxRoot: {
        padding: "0 10px 0 0",
        background: "none !important",
    },

    popperDisablePortal: {
        position: "relative",
    },

    applyButton: {
        margin: "8px 13px",
    },
}));

export default useStyles;
