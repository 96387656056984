import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useAPI from "../../../shared/useapi";
import Record from "./record";
import Edit from "./edit";
import Delete from "./delete";
import Overview from "./overview";
import { getAll } from "../usages/dataservice";
import { get as getAsset } from "../asset/dataservice";
import useSessionStorage from "../../../shared/useSessionStorage";
import useDialog from "../../../shared/usedialog";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";

const AssetUsage = (props) => {
    var assetId = props.match.params["assetId"];

    const { data: asset } = useAPI(() => getAsset(assetId), true, [assetId]);

    const { defaultPageSize, availablePageSizes } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-usage", {
        page: 0,
        pageSize: defaultPageSize,
    });

    const { loading, invoke, data } = useAPI(
        () => getAll("assets", assetId, searchParams.page + 1, searchParams.pageSize),
        true,
        [assetId, searchParams]
    );

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const { open: handleRecord, close: handleCloseRecord, visible: recordToggled } = useDialog(
        invoke
    );
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);

    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);

    return (
        asset && (
            <React.Fragment>
                <Overview
                    data={data}
                    searchParams={searchParams}
                    asset={asset}
                    availablePageSizes={availablePageSizes}
                    loading={loading}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onRecord={handleRecord}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    {...props}
                />

                <Edit asset={asset} id={editId} open={editToggled} onClose={handleCloseEdit} />

                <Delete
                    assetId={asset.id}
                    id={deleteId}
                    open={deleteToggled}
                    onClose={handleCloseDelete}
                />

                <Record asset={asset} open={recordToggled} onClose={handleCloseRecord} />
            </React.Fragment>
        )
    );
};

export default () => {
    return (
        <PrivateRoute
            requiredOperation={"assets-usage-view"}
            path="/assets/asset-usage/:assetId(\d+)"
            render={(props) => <AssetUsage {...props} />}
        />
    );
};
