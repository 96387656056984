import React, { useContext, useEffect, useState } from "react";
import LocalizationContext from "../localization/localizationcontext";
import PropTypes from "prop-types";
import CurrencyTextField from "./currencyTextField";
import withResources from "../textresources/withresources";
import useStyles from "./useStyles";
import ResourcedTextField from "../textresources/resourcedtextfield";

/**
 * This MoneyInput is a wrapper around CurrencyTextField
 * (copied from https://github.com/unicef/material-ui-currency-textfield)
 * Value has to be of type 'number' (or null). Mind that, when useDecimals is true, the value will
 * be 123 when 123.00 is shown, 123.3 when 123.30 is shown and 123.33 when 123.33 is shown.
 */
const MoneyInput = withResources((props) => {
    const {
        getResource,
        name,
        errors,
        size = "medium",
        labelResourceKey,
        labelDefaultValue,
        fullWidth = false,
        margin = "normal",
        value,
        minimumValue = "0",
        disabled = false,
        useDecimals = false,
        textAlign = "left",
        currencyCode,
        onChange: handleChange,
        placeholder,
        required,
    } = props;

    const { selectedCulture } = useContext(LocalizationContext);
    const [digitGroupSeparator, setDigitGroupSeparator] = useState("");
    const [decimalCharacter, setDecimalCharacter] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const error = errors && name in errors;
    const helperText = errors && errors[name];
    const classes = useStyles();

    useEffect(() => {
        const setMoneyCharactersForSelectedCulture = () => {
            var parts = new Intl.NumberFormat(selectedCulture, {
                style: "currency",
                currency: currencyCode,
            }).formatToParts(1000.1);

            var currencyValue = parts.find((part) => part.type === "currency").value;
            setCurrencySymbol(currencyValue);

            var groupSeparatorValue = parts.find((part) => part.type === "group").value;
            setDigitGroupSeparator(groupSeparatorValue);

            var decimalIndex = parts.findIndex((part) => part.type === "decimal");
            if (decimalIndex === -1) {
                setDecimalCharacter(groupSeparatorValue === "." ? "," : ".");
            } else {
                setDecimalCharacter(parts[decimalIndex].value);
            }
        };

        if (currencyCode) {
            setMoneyCharactersForSelectedCulture();
        }
    }, [selectedCulture, currencyCode]);

    let classNames = classes.root + " ";
    classNames = classNames + (size === "small" ? "--size-small " : "");
    classNames = classNames + (fullWidth ? "MuiFormControl-fullWidth " : "");
    classNames = classNames + (margin === "normal" ? "MuiFormControl-marginNormal " : "");
    classNames = classNames + (margin === "dense" ? "MuiFormControl-marginDense " : "");

    if (!currencyCode) {
        /**
         * Show a disabled text field when there is no currency code (yet). This can not be done
         * with the CurrencyTextField component, since it crashes without a currency code)
         */
        return (
            <ResourcedTextField
                labelResourceKey={labelResourceKey}
                labelDefaultValue={labelDefaultValue}
                value=""
                helperText={getResource(
                    "MoneyInput.disabledNoCurrencyText",
                    "Please select a currency first"
                )}
                variant="outlined"
                disabled
                className={classNames + " --disabled-no-currency"}
            />
        );
    }

    /**
     * For some reason, the props digitGroupSeparator, decimalCharacter and currencySymbol have to
     * be correct, the moment the component mounts, so this check makes sure the component only
     * mounts once these states are set.
     **/
    if (digitGroupSeparator && decimalCharacter && currencySymbol) {
        return (
            <CurrencyTextField
                label={getResource(labelResourceKey, labelDefaultValue)}
                value={value}
                error={error}
                helperText={helperText}
                variant="outlined"
                disabled={disabled}
                name={name}
                textAlign={textAlign}
                minimumValue={minimumValue}
                outputFormat="number"
                emptyInputBehavior="null"
                className={classNames}
                currencySymbol={currencySymbol}
                decimalCharacter={decimalCharacter}
                digitGroupSeparator={digitGroupSeparator}
                decimalPlaces={useDecimals ? 2 : 0}
                onChange={(event, value) => handleChange({ target: { name, value: value } })}
                placeholder={placeholder}
                required={required}
            />
        );
    }

    return null;
});

MoneyInput.propTypes = {
    name: PropTypes.string.isRequired,
    errors: PropTypes.object,
    labelResourceKey: PropTypes.string.isRequired,
    labelDefaultValue: PropTypes.string.isRequired,
    fullwidth: PropTypes.bool,
    margin: PropTypes.string,
    value: PropTypes.number,
    minimumValue: PropTypes.string,
    disabled: PropTypes.bool,
    useDecimals: PropTypes.bool,
    textAlign: PropTypes.string,
    currencyCode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default MoneyInput;
