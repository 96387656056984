import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "inline-block" /** prevent margins collapsing **/,
        width: "100%",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),

        "& + .labelledOutline": {
            marginTop: theme.spacing(2),
        },
    },

    root2: {
        position: "relative",
    },

    content: {
        padding: "18.5px 14px",

        "& fieldset": {
            borderColor: "rgba(0,0,0,0.15)",
        },
    },

    inputLabel: {
        position: "absolute",
        left: 0,
        top: 0,
        fontSize: 18,
        fontWeight: 500,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        // slight alteration to spec spacing to match visual spec result
        transform: "translate(0, 24px) scale(1)",
    },
}));

const LabelledOutline = (props) => {
    const { id, label, children, className, onClick } = props;

    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        const labelNode = ReactDOM.findDOMNode(labelRef.current);
        setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
    }, [label]);

    return (
        <div className={clsx(classes.root, "labelledOutline", className)} onClick={onClick}>
            <InputLabel
                ref={labelRef}
                htmlFor={id}
                variant="outlined"
                className={classes.inputLabel}
                shrink
                title={label}
            >
                {label}
            </InputLabel>
            <div className={classes.root2}>
                <div id={id} className={classes.content}>
                    {children}
                    <NotchedOutline notched labelWidth={labelWidth} />
                </div>
            </div>
        </div>
    );
};

LabelledOutline.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default LabelledOutline;
