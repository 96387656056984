import React, { useState } from "react";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourceTextField from "../../../shared/textresources/resourcedtextfield";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import slugify from "../../../shared/slugify";
import Wysiwyg from "./components/wysiwyg";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, _, defaultCulture) => {
        const errors = {};

        if (!values.title[defaultCulture]) {
            errors.title = getResource(
                "Marketplace.marketplacePages.add.titleRequired",
                "Please enter a title for the default language"
            );
        }

        if (!values.urlSlug) {
            errors.urlSlug = getResource(
                "Marketplace.marketplacePages.add.urlSlugRequired",
                "Please enter a URL slug"
            );
        }

        return errors;
    };

    const [slugChangedManually, setSlugChangedManually] = useState(false);

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            disableAutoFocus={true}
            disableEnforceFocus={true}
            disablePortal={true}
            title={getResource("Marketplace.marketplacePages.add.title", "New marketplace page")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
            size={"medium"}
        >
            <LocalizedForm
                initialValues={{
                    title: {},
                    body: {},
                    metadataTitle: {},
                    metadataDescription: {},
                    metadataKeywords: {},
                }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource(
                    "Marketplace.marketplacePages.add.okButtonText",
                    "Create"
                )}
                onSubmit={onSubmit}
                onValidate={onValidate}
                useUILanguages={true}
                showCultureSelector
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture
                ) => {
                    return (
                        <React.Fragment>
                            <TranslatableTextField
                                errors={errors}
                                autoFocus
                                margin="normal"
                                name="title"
                                labelResourceKey="Marketplace.marketplacePages.add.titleField"
                                labelDefaultValue="Title"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.title}
                                culture={culture}
                                handleChange={(e) => {
                                    const changes = [e];
                                    if (!slugChangedManually && isDefaultCulture) {
                                        changes.push({
                                            target: {
                                                name: "urlSlug",
                                                value: slugify(e.target.value),
                                            },
                                            isLocalizable: false,
                                        });
                                    }
                                    handleChange(changes, true);
                                }}
                                required
                            />

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.marketplacePages.add.bodyLabel",
                                    "Body"
                                )}
                            >
                                <Wysiwyg
                                    value={values.body[culture] || ""}
                                    onEditorChange={(content) => {
                                        handleChange(
                                            {
                                                target: {
                                                    name: "body",
                                                    value: content,
                                                },
                                            },
                                            true
                                        );
                                    }}
                                />
                            </LabelledOutline>

                            <ResourceTextField
                                errors={errors}
                                margin="normal"
                                name="urlSlug"
                                labelResourceKey="Marketplace.marketplacePages.add.urlSlugField"
                                labelDefaultValue="URL slug"
                                type="text"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={values.urlSlug}
                                onChange={(e) => {
                                    setSlugChangedManually(true);
                                    handleChange(e);
                                }}
                                disabled={!isDefaultCulture}
                                required
                            />

                            <LabelledOutline
                                label={getResource(
                                    "Marketplace.marketplacePages.add.metadataLabel",
                                    "Metadata"
                                )}
                            >
                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="metadataTitle"
                                    labelResourceKey="Marketplace.marketplacePages.add.metadataTitleField"
                                    labelDefaultValue="Title"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.metadataTitle}
                                    culture={culture}
                                    handleChange={handleChange}
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="metadataDescription"
                                    labelResourceKey="Marketplace.marketplacePages.add.metadataDescriptionField"
                                    labelDefaultValue="Description"
                                    multiline
                                    fullWidth
                                    value={values.metadataDescription}
                                    culture={culture}
                                    handleChange={handleChange}
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="metadataKeywords"
                                    labelResourceKey="Marketplace.marketplacePages.add.metadataKeywordsField"
                                    labelDefaultValue="Keywords"
                                    multiline
                                    fullWidth
                                    value={values.metadataKeywords}
                                    culture={culture}
                                    handleChange={handleChange}
                                />
                            </LabelledOutline>
                        </React.Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
