import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),

        "& h2": {
            marginBottom: 20,
        },

        "& dl": {
            display: "block",
            width: "100%",
            marginRight: -58,

            "& div": {
                display: "inline-block",
                verticalAlign: "top",
                width: 200,
                maxWidth: "100%",
                marginRight: 58,

                "&:first-child": {
                    width: 458,

                    [theme.breakpoints.down(800)]: {
                        width: "100%",
                    },
                },

                [theme.breakpoints.down(800)]: {
                    width: "100%",
                },

                "&.task-description": {
                    "& dt": {
                        textTransform: "none",
                        fontSize: 14,
                        fontWeight: 500,
                        color: theme.palette.black,
                    },

                    "& dd": {
                        fontWeight: "normal",
                        color: theme.palette.grey,
                    },
                },
            },

            "& dt": {
                marginBottom: 0,
            },

            "& dt, & dd": {
                width: "auto",
                maxWidth: "none",
            },
        },
    },
}));

export default useStyles;
