import React from "react";
import {
    LinearProgress,
    Button,
    CircularProgress,
} from "@material-ui/core";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LocalizedForm from "../../../shared/localization/localizedform";
import { FormHelperText } from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useAPI from "../../../shared/useapi";
import { getAll, mergeAndDelete } from "./dataservice";

import withResources from "../../../shared/textresources/withresources";
import AutoComplete from "../../../shared/autocomplete";
import MergeInfo from "./components/mergeInfo";

export default withResources(({ getResource, id, version, name, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(mergeAndDelete);

    const handleMerge = async (input) => {
        var values = {
            sourceVersion: version,
            targetId: input.values.targetModel.id,
        };
        if (await invoke(id, values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.targetModel || !values.targetModel.id) {
            errors.targetModel = getResource(
                "Library.assetModel.merge.targetModel",
                "You have to select the model to merge to"
            );
        } else if (values.targetModel.id === id) {
            errors.targetModel = getResource(
                "Library.assetModel.merge.cannotMergeToSelf",
                "You cannot merge this model to itself"
            );
        }

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.assetModel.merge.header", "Merge model {name}", { name })}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            <LocalizedForm
                initialValues={{ sourceId: id }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource("Library.assetModel.merge.okButton", "Merge")}
                onSubmit={handleMerge}
                onValidate={onValidate}
                renderForm={(_, values, handleChange, errors) => (
                    <React.Fragment>
                        <FormHelperText>
                            <ResourcedText
                                resourceKey="Library.assetModel.merge.body"
                                defaultValue="The model will be deleted, and all related objects are moved to the selected model. Valuations of this model are also moved to the selected model, but will be archived. The model questionnaire will not be moved to the selected model."
                            />
                        </FormHelperText>

                        <AutoComplete
                            errors={errors}
                            autoFocus
                            name="targetModel"
                            label={getResource("Assets.asset.add.targetModelField", "Model")}
                            value={values.targetModel}
                            onGetData={(name, page, pageSize) =>
                                getAll(name, null, null, page, pageSize)
                            }
                            onSelection={(e) => handleChange(e)}
                            getTextField={(field) =>
                                field.manufacturerName
                                    ? `${field.manufacturerName} ${field.name}`
                                    : field.name
                            }
                            renderOption={(option) => (
                                <div>
                                    {option.manufacturerName} {option.name}
                                </div>
                            )}
                            required
                        />

                        <MergeInfo id={id} name={name} />
                    </React.Fragment>
                )}
            />
        </FormDrawer>
    );
});
