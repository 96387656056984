import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    path: {
        "&.--keep-on-one-line": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            direction: "rtl",
            textOverflow: "ellipsis",
            maxWidth: "100%",
        },

        "& *": {
            display: "inline-block",
            verticalAlign: "middle",
        },
    },

    chevronIcon: {
        margin: "0 3px",
        fontSize: 10,

        "&.--regular-size": {
            fontSize: 12,
        },
    },
}));

export default useStyles;
