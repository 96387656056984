import React from "react";
import {
    Checkbox,
    LinearProgress,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    Radio,
    Typography,
} from "@material-ui/core";
import useAPI from "../../../shared/useapi";

import { set } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import { getAll as getAllCompanies } from "./../../security/companies/dataservice";

import LocalizedForm from "../../../shared/localization/localizedform";
import AutoComplete from "../../../shared/autocomplete";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import { config } from "config";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        marginBottom: theme.spacing(1),
    },
}));

export default withResources(({ getResource, id, onDirtyChanged, onClose }) => {
    const classes = useStyles();

    const { loading, invoke } = useAPI((values) => set(id, values));

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,

            companyId:
                values.values.companyMethod === "search" ? values.values.searchedCompany.id : null,

            correspondenceCountry: values.values.correspondenceVisible
                ? values.values.correspondenceCountry
                : null,
            correspondenceAddress: values.values.correspondenceVisible
                ? values.values.correspondenceAddress
                : null,
            correspondenceState: values.values.correspondenceVisible
                ? values.values.correspondenceState
                : null,
            correspondencePostalCode: values.values.correspondenceVisible
                ? values.values.correspondencePostalCode
                : null,
            correspondenceCity: values.values.correspondenceVisible
                ? values.values.correspondenceCity
                : null,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.companyMethod) {
            errors.companyMethod = getResource(
                "Assets.asset.ownership.selectCompanyMethod",
                "Please select a method to select a company"
            );
        }

        if (values.companyMethod === "suggestion" && !values.suggestedCompany) {
            errors.suggestedCompany = getResource(
                "Assets.asset.ownership.selectSuggestedCompany",
                "Please select a suggested company"
            );
        }

        if (values.companyMethod === "search" && !values.searchedCompany.id) {
            errors.searchedCompany = getResource(
                "Assets.asset.ownership.selectSearchedCompany",
                "Please search a company to use"
            );
        }

        if (values.companyMethod === "new") {
            if (!values.companyName) {
                errors.companyName = getResource(
                    "Assets.asset.ownership.newCompany.enterName",
                    "Please enter a company name"
                );
            }

            if (!values.companyEmail) {
                errors.companyEmail = getResource(
                    "Assets.asset.ownership.newCompany.enterEmail",
                    "Please enter a company e-mail"
                );
            }

            if (!!values.suggestedCompany && !values.searchedCompany.id) {
                errors.company = getResource(
                    "Assets.asset.ownership.newCompany.selectCompanyOrCreateNew",
                    "Please select a company or create a new one"
                );
            }

            if (values.companyEmail && !values.companyEmail.match(config.emailRegex)) {
                errors.companyEmail = getResource(
                    "Assets.asset.ownership.newCompany.enterCompanyEmail",
                    "The provided company e-mail isn't a valid e-mail address"
                );
            }

            if (!values.companyCountry) {
                errors.companyCountry = getResource(
                    "Assets.asset.ownership.newCompany.selectCountry",
                    "Please select a country"
                );
            }

            if (!values.companyAddress) {
                errors.companyAddress = getResource(
                    "Assets.asset.ownership.newCompany.enterAddress",
                    "Please enter a address"
                );
            }

            if (!values.companyState) {
                errors.companyState = getResource(
                    "Assets.asset.ownership.newCompany.enterState",
                    "Please enter a state"
                );
            }

            if (!values.companyPostalCode) {
                errors.companyPostalCode = getResource(
                    "Assets.asset.ownership.newCompany.enterPostalCode",
                    "Please enter a postal code"
                );
            }

            if (!values.companyCity) {
                errors.companyCity = getResource(
                    "Assets.asset.ownership.newCompany.enterCity",
                    "Please enter a city"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceCountry) {
                errors.correspondenceCountry = getResource(
                    "Assets.asset.ownership.newCompany.correspondence.selectCountry",
                    "Please select a country"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceAddress) {
                errors.correspondenceAddress = getResource(
                    "Assets.asset.ownership.newCompany.correspondence.enterAddress",
                    "Please enter a address"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceState) {
                errors.correspondenceState = getResource(
                    "Assets.asset.ownership.newCompany.correspondence.enterState",
                    "Please enter a state"
                );
            }

            if (values.correspondenceVisible && !values.correspondencePostalCode) {
                errors.correspondencePostalCode = getResource(
                    "Assets.asset.ownership.newCompany.correspondence.enterPostalCode",
                    "Please enter a postal code"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceCity) {
                errors.correspondenceCity = getResource(
                    "Assets.asset.ownership.newCompany.correspondence.enterCity",
                    "Please enter a city"
                );
            }
        }

        if (!values.agreeRegistrationCondition) {
            errors.agreeRegistrationCondition = getResource(
                "Assets.asset.ownership.newCompany.mustAgreeRegistrationCondition",
                "You must accept the registration conditions"
            );
        }

        return errors;
    };

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    var isLoaded = allCountries;

    return (
        <React.Fragment>
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        searchedCompany: { id: null, name: null },
                        companyMethod: "",
                    }}
                    onIsDirty={onDirtyChanged}
                    loading={loading}
                    okButtonText={getResource(
                        "Assets.asset.changeOwnership.okButton",
                        "Change owner"
                    )}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <Typography variant="h4">
                                    <ResourcedText
                                        resourceKey="Assets.asset.ownership.changeOwnerHeader"
                                        defaultValue="Change owner"
                                    />
                                </Typography>
                                <p>
                                    <ResourcedText
                                        resourceKey="Assets.asset.ownership.changeOwnerText"
                                        defaultValue="You can transfer ownership to an existing company or to a newly created company."
                                    />
                                </p>
                                <RadioGroup
                                    value={values.companyMethod}
                                    onChange={(e) => handleChange(e)}
                                    row
                                >
                                    <FormControlLabel
                                        name="companyMethod"
                                        value="search"
                                        control={<Radio color="primary" />}
                                        label={getResource(
                                            "Assets.asset.ownership.company.searchForCompany",
                                            "Select an existing company"
                                        )}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        name="companyMethod"
                                        value="new"
                                        control={<Radio color="primary" />}
                                        label={getResource(
                                            "Assets.asset.ownership.company.createNewCompany",
                                            "Create a new company"
                                        )}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                                {"companyMethod" in errors && (
                                    <FormHelperText error component="legend">
                                        {errors["companyMethod"]}
                                    </FormHelperText>
                                )}

                                {values.companyMethod === "search" && (
                                    <AutoComplete
                                        name="searchedCompany"
                                        errors={errors}
                                        label={getResource(
                                            "Assets.asset.ownership.searchedCompanyField",
                                            "Company"
                                        )}
                                        value={values.searchedCompany}
                                        onGetData={(searchText, page, pageSize) =>
                                            getAllCompanies(searchText, null, true, page, pageSize)
                                        }
                                        onSelection={(e) => handleChange(e)}
                                        required
                                    />
                                )}

                                {values.companyMethod === "new" && (
                                    <React.Fragment>
                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyName"
                                            labelResourceKey="Assets.asset.ownership.newCompany.nameField"
                                            labelDefaultValue="Name"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 75 }}
                                            value={values.companyName}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyEmail"
                                            label="E-mail"
                                            labelResourceKey="Assets.asset.ownership.newCompany.emailField"
                                            labelDefaultValue="E-mail"
                                            type="email"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            value={values.companyEmail}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyPhoneNumber1"
                                            labelResourceKey="Assets.asset.ownership.newCompany.phoneNumber1Field"
                                            labelDefaultValue="Phone number 1"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                maxLength: 20,
                                                pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                            }}
                                            value={values.companyPhoneNumber1 || ""}
                                            onChange={(e) => {
                                                if (!e.target.validity.valid) {
                                                    e.target.value = values.companyPhoneNumber1;
                                                }
                                                handleChange(e);
                                            }}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyPhoneNumber2"
                                            labelResourceKey="Assets.asset.ownership.newCompany.phoneNumber2Field"
                                            labelDefaultValue="Phone number 2"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                maxLength: 20,
                                                pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                            }}
                                            value={values.companyPhoneNumber2 || ""}
                                            onChange={(e) => {
                                                if (!e.target.validity.valid) {
                                                    e.target.value = values.companyPhoneNumber2;
                                                }
                                                handleChange(e);
                                            }}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyChamberOfCommerce"
                                            labelResourceKey="Assets.asset.ownership.newCompany.chamberOfCommerceField"
                                            labelDefaultValue="Chamber of commerce"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            value={values.companyChamberOfCommerce || ""}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyVatNumber"
                                            labelResourceKey="Assets.asset.ownership.newCompany.companyVatNumberField"
                                            labelDefaultValue="VAT Number"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            value={values.companyVatNumber || ""}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <strong>Company address</strong>

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="companyCountry"
                                            label={getResource(
                                                "Assets.asset.ownership.newCompany.countryField",
                                                "Country"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.companyCountry}
                                            onSelection={(e) => handleChange(e)}
                                            options={allCountries.map((c) => ({
                                                id: c.code,
                                                name: c.name,
                                            }))}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyAddress"
                                            labelResourceKey="Assets.asset.ownership.newCompany.addressField"
                                            labelDefaultValue="Address"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.companyAddress}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyPostalCode"
                                            labelResourceKey="Assets.asset.ownership.newCompany.postalCodeField"
                                            labelDefaultValue="Postal code"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            value={values.companyPostalCode}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyState"
                                            labelResourceKey="Assets.asset.ownership.newCompany.stateField"
                                            labelDefaultValue="State"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.companyState}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="companyCity"
                                            labelResourceKey="Assets.asset.ownership.newCompany.cityField"
                                            labelDefaultValue="City"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.companyCity}
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(values.correspondenceVisible)}
                                                    onChange={(e) => handleChange(e)}
                                                    name="correspondenceVisible"
                                                />
                                            }
                                            label={getResource(
                                                "Assets.asset.ownership.newCompany.differentCorrespondenceAddressField",
                                                "Different correspondence address"
                                            )}
                                            variant="outlined"
                                        />

                                        {values.correspondenceVisible && (
                                            <React.Fragment>
                                                <AutoCompleteStatic
                                                    errors={errors}
                                                    name="correspondenceCountry"
                                                    label={getResource(
                                                        "Assets.asset.ownership.correspondence.countryField",
                                                        "Country"
                                                    )}
                                                    fullWidth
                                                    isMedium
                                                    value={values.correspondenceCountry}
                                                    onSelection={(e) => handleChange(e)}
                                                    options={allCountries.map((c) => ({
                                                        id: c.code,
                                                        name: c.name,
                                                    }))}
                                                    required
                                                />

                                                <ResourcedTextField
                                                    errors={errors}
                                                    margin="normal"
                                                    name="correspondenceAddress"
                                                    labelResourceKey="Assets.asset.ownership.correspondence.addressField"
                                                    labelDefaultValue="Address"
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 100 }}
                                                    value={values.correspondenceAddress || ""}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                />

                                                <ResourcedTextField
                                                    errors={errors}
                                                    margin="normal"
                                                    name="correspondencePostalCode"
                                                    labelResourceKey="Assets.asset.ownership.correspondence.postalCodeField"
                                                    labelDefaultValue="Postal code"
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 50 }}
                                                    value={values.correspondencePostalCode || ""}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                />

                                                <ResourcedTextField
                                                    errors={errors}
                                                    margin="normal"
                                                    name="correspondenceState"
                                                    labelResourceKey="Assets.asset.ownership.correspondence.stateField"
                                                    labelDefaultValue="State"
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 100 }}
                                                    value={values.correspondenceState}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                />

                                                <ResourcedTextField
                                                    errors={errors}
                                                    margin="normal"
                                                    name="correspondenceCity"
                                                    labelResourceKey="Assets.asset.ownership.correspondence.cityField"
                                                    labelDefaultValue="City"
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 100 }}
                                                    value={values.correspondenceCity}
                                                    onChange={(e) => handleChange(e)}
                                                    required
                                                />
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                <div className={classes.checkboxContainer}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                autoFocus={"agreeRegistrationCondition" in errors}
                                                checked={Boolean(values.agreeRegistrationCondition)}
                                                onChange={(e) => handleChange(e)}
                                                name="agreeRegistrationCondition"
                                            />
                                        }
                                        label={getResource(
                                            "Assets.asset.ownership.agreeRegistrationCondition",
                                            "I agree with the registration conditions"
                                        )}
                                        variant="outlined"
                                    />
                                    {"agreeRegistrationCondition" in errors ? (
                                        <FormHelperText className="Mui-error">
                                            {errors["agreeRegistrationCondition"]}
                                        </FormHelperText>
                                    ) : null}
                                </div>
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </React.Fragment>
    );
});
