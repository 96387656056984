import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    MergeType as MergeIcon,
    Pageview as ReviewIcon,
} from "@material-ui/icons";
import _ from "underscore";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Search from "../../../shared/search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import useStyles from "./useStyles";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        onMerge,
        onReview,
        defaultDebounceInterval,
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        data = data || {};
        const items = data.items;

        const classes = useStyles();

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <ContentHolder>
                <Header
                    title={getResource("Library.manufacturers.header.title", "Manufacturers")}
                    renderActions={() => {
                        return (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                requiredOperation="manufacturers-create"
                                buttonText={getResource(
                                    "Library.manufacturers.newManufacturerButton",
                                    "Create"
                                )}
                                id="e2e_createButtonManufacturer"
                            />
                        );
                    }}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Library.manufacturers.noResults",
                        "No manufacturers available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Library.manufacturers.overview.nameHeader"
                                        defaultValue="Name"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Library.manufacturers.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() =>
                                            row.isSuggestion ? onReview(row.id) : onEdit(row.id)
                                        }
                                        requiredOperation={
                                            row.isSuggestion
                                                ? "manufacturers-approve-suggestion"
                                                : "manufacturers-update"
                                        }
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                            {row.isSuggestion ? (
                                                <div className={classes.suggestion}>
                                                    {getResource(
                                                        "Library.manufacturers.proposedText",
                                                        "Proposed by {suggestedBy} on {suggestedOn}",
                                                        {
                                                            ...row,
                                                            suggestedOn: formatDate(
                                                                row.suggestedOn,
                                                                selectedUiCulture,
                                                                selectedTimezone,
                                                                false,
                                                                true,
                                                                false,
                                                                false
                                                            ),
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Library.manufacturers.editButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "manufacturers-update",
                                                        onClick: () => onEdit(row.id),
                                                        showOnlyWhen: !row.isSuggestion,
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.manufacturers.mergeButton",
                                                            "Merge"
                                                        ),
                                                        icon: <MergeIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "manufacturers-merge",
                                                        onClick: () => onMerge(row),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.manufacturers.deleteButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "manufacturers-delete",
                                                        onClick: () => onDelete(row.id),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.manufacturers.reviewSuggestionButton",
                                                            "Review proposal"
                                                        ),
                                                        icon: <ReviewIcon />,
                                                        itemId: row.id,
                                                        requiredOperation:
                                                            "manufacturers-approve-suggestion",
                                                        onClick: () => onReview(row.id),
                                                        showOnlyWhen: row.isSuggestion,
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
