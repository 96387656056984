import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    noContent: {
        backgroundColor: theme.palette.white,
        padding: 20,
        textAlign: "center",
        fontWeight: 500,
        marginTop: 20,
    },

    icon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: 10,
        borderRadius: ".8em",
        fontSize: "3em",
    },
    searchIcon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: "10px",
        borderRadius: ".8em",
        fontSize: "3em",
    },
}));

export default useStyles;
