import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import ValuationService from "../helpers/valuationService.js";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";
import ResourcedTextField from "../../../../../shared/textresources/resourcedtextfield";
import withResources from "../../../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../../../shared/autocompleteStatic";
import FormattedCurrency from "../../../../../shared/formattedCurrency";

class ValuationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startBuildYearOptions: undefined,
            endBuildYearOptions: undefined,
            valutaOptions: undefined,
            isPopulated: false,
            valutaId: undefined,
        };
    }

    componentWillReceiveProps(nextProps) {
        let stateData = this.state;

        // check for the valutaId
        if (nextProps.valuationData.valutaId !== null) {
            stateData.valutaId = nextProps.valuationData.valutaId;
            this.setState(stateData);
        }

        if (!stateData.isPopulated && typeof stateData.valutaId !== undefined) {
            stateData.isPopulated = true;
            this.setState(stateData);
            this.loadData();
        }

        return true;
    }

    loadData() {
        const VS = new ValuationService();
        VS.getAvailableCurrencies(
            (data) => {
                // build valuta options
                var valutaOptions = data.map((item, index) => {
                    return {
                        key: item.id,
                        value: item.id,
                        text: item.name,
                        selected: item.id === this.state.valutaId,
                        currencyCode: item.currencyCode,
                    };
                });

                // build year options
                VS.getAvailableBuildYears(
                    (data) => {
                        let yearOptions = data.map((item, index) => {
                            return { key: item, value: item, text: item };
                        });

                        let currState = this.state;
                        currState.valutaOptions = valutaOptions;
                        currState.startBuildYearOptions = yearOptions;
                        currState.endBuildYearOptions = yearOptions;
                        currState.isFetching = false;
                        currState.fetchCompleted = true;

                        this.setState(currState);
                    },
                    (err) => {
                        let notify = this.props.notificationRef;
                        notify.showError(
                            this.props.getResource(
                                "Valuation.info.getAvailableBuildYearsErrorTitle",
                                "Failed to get available build years"
                            ),
                            this.props.getResource(
                                "Valuation.info.getAvailableBuildYearsErrorMessage",
                                "An error occurred while fetching the data. Please try again later."
                            )
                        );
                    }
                );
            },
            (err) => {
                let notify = this.props.notificationRef;
                notify.showError(
                    this.props.getResource(
                        "Valuation.info.getAvailableCurrenciesErrorTitle",
                        "Failed to get available currencies"
                    ),
                    this.props.getResource(
                        "Valuation.info.getAvailableCurrenciesErrorMessage",
                        "An error occurred while fetching the data. Please try again later."
                    )
                );
            }
        );
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <div>
                        <strong>
                            <ResourcedText
                                resourceKey="Valuation.info.fairMarketValueLabel"
                                defaultValue="Fair Market Value"
                            />
                        </strong>
                    </div>
                    <FormattedCurrency
                        value={this.props.valuationData.fairMarketValue}
                        decimals={0}
                        currency={
                            this.state.valutaOptions &&
                            this.state.valutaOptions.find(
                                (x) => x.value === this.props.valuationData.valutaId
                            ).currencyCode
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <div>
                        <strong>
                            <ResourcedText
                                resourceKey="Valuation.info.remainingValueAfterTechLifeLabel"
                                defaultValue="Remaining value after tech life"
                            />
                        </strong>
                    </div>
                    <FormattedCurrency
                        value={this.props.valuationData.remainingValueAfterTechLife}
                        decimals={0}
                        currency={
                            this.state.valutaOptions &&
                            this.state.valutaOptions.find(
                                (x) => x.value === this.props.valuationData.valutaId
                            ).currencyCode
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <div>
                        <strong>
                            <ResourcedText
                                resourceKey="Valuation.info.technicalLifeLabel"
                                defaultValue="Technical life"
                            />
                        </strong>
                    </div>
                    {this.props.valuationData.technicalLife}
                </Grid>
                <Grid item xs={4}>
                    {this.state.startBuildYearOptions &&
                        this.props.valuationData.startBuildYear && (
                            <AutoCompleteStatic
                                name="StartBuildYear"
                                fullWidth
                                label={
                                    <ResourcedText
                                        resourceKey="Valuation.info.startBuildYearField"
                                        defaultValue="Start build year"
                                    />
                                }
                                onSelection={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "valuationInfo/startBuildYear"
                                    )
                                }
                                value={this.props.valuationData.startBuildYear}
                                options={this.state.startBuildYearOptions.map((year) => ({
                                    id: year.value,
                                    name: year.text,
                                }))}
                                disableClearable
                            />
                        )}
                </Grid>
                <Grid item xs={4}>
                    {this.state.endBuildYearOptions && this.props.valuationData.endBuildYear && (
                        <AutoCompleteStatic
                            name="EndBuildYear"
                            fullWidth
                            label={
                                <ResourcedText
                                    resourceKey="Valuation.info.endBuildYearField"
                                    defaultValue="End build year"
                                />
                            }
                            onSelection={(e) =>
                                this.props.onHandleChange(e.target, "valuationInfo/endBuildYear")
                            }
                            value={this.props.valuationData.endBuildYear}
                            options={this.state.endBuildYearOptions.map((year) => ({
                                id: year.value,
                                name: year.text,
                            }))}
                            disableClearable
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    {this.state.valutaOptions && (
                        <AutoCompleteStatic
                            name="valutaId"
                            fullWidth
                            label={
                                <ResourcedText
                                    resourceKey="Valuation.info.valutaField"
                                    defaultValue="Valuta"
                                />
                            }
                            onSelection={(e) =>
                                this.props.onHandleChange(e.target, "valuationInfo/valutaId")
                            }
                            value={this.props.valuationData.valutaId}
                            options={this.state.valutaOptions.map((valuta) => ({
                                id: valuta.value,
                                name: valuta.text,
                            }))}
                            disableClearable
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <ResourcedTextField
                        labelResourceKey="Valuation.info.commentField"
                        labelDefaultValue="Comment"
                        name="Comment"
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            this.props.onHandleChange(e.target, "valuationInfo/comment")
                        }
                        value={this.props.valuationData.comment}
                        margin="dense"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withResources(ValuationInfo);
