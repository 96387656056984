import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Paper } from "@material-ui/core";
import withResources from "../../../../shared/textresources/withresources";
import FormattedDate from "../../../../shared/formattedDate";
import useStyles from "./useStyles";

const SignOff = withResources((props) => {
    const { getResource, task, handleChange, disabled } = props;
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 30 * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            {!disabled && (
                <dl>
                    <dt>
                        {getResource(
                            "Maintenance.workOrder.signOff.currentDateAndTime",
                            "Current date and time"
                        )}
                    </dt>
                    <dd>
                        <FormattedDate date={currentDate} long includeTime />
                    </dd>
                </dl>
            )}

            <FormControlLabel
                control={
                    <Checkbox
                        checked={disabled ? true : Boolean(task.completed)}
                        onChange={(e) => handleChange(e)}
                        name="completed"
                        disabled={disabled}
                    />
                }
                label={getResource(
                    "Maintenance.workOrder.signOff.completed",
                    "Work has been completed"
                )}
            />

            {disabled && (
                <dl>
                    <dt>
                        {getResource("Maintenance.workOrder.signOff.completedOn", "Completed on")}
                    </dt>
                    <dd>
                        <FormattedDate date={task.jobSheetCompletedOn} long includeTime />
                    </dd>
                    <dt>
                        {getResource("Maintenance.workOrder.signOff.completedBy", "Completed by")}
                    </dt>
                    <dd>{task.jobSheetCompletedBy}</dd>
                </dl>
            )}

            <Button type="submit" color="primary" variant="contained" disabled={disabled}>
                {getResource("Maintenance.workOrder.signOff.update", "Update")}
            </Button>
        </Paper>
    );
});

export default SignOff;
