import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 10,
    },

    sliderItem: {
        padding: "0 10px",
    },

    inputItemLeft: {
        textAlign: "left",
    },

    inputItemRight: {
        textAlign: "right",
    },
}));

export default useStyles;
