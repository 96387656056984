import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: "nowrap",
        minWidth: "auto",
    },

    icon: {
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
