import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    summaryContent: {
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: theme.spacing(1.5, 0),

        "&, &.Mui-expanded": {
            margin: 0,
        },

        "& > *": {
            marginBottom: 0,
        },
    },

    summaryImageWrapper: {
        margin: theme.spacing(0, 1.5, 0, 0.5),
        position: "relative",
        width: 70,
        height: 48,
        backgroundColor: theme.palette.white,
        border: "1px solid rgba(0, 0, 0, 0.125)",

        "& img, & svg": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },

    summaryDescription: {
        marginRight: "auto",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: 0,
    },

    summaryDeleteButtonWrapper: {
        width: 48,
    },

    singleMediaItem: {
        marginBottom: theme.spacing(1),
    },

    details: {
        width: "100%",
    },

    detailsImageOrUploadContainer: {
        position: "relative",
        margin: theme.spacing(2, 0, 4),
        minHeight: 200 /** minHeight so can grow when an error is displayed in Upload **/,

        "& .imageAndDelete": {
            opacity: 0,
            transition: "opacity 0.2s linear",

            "&.--image-is-loaded": {
                opacity: 1,
            },
        },

        "& img": {
            height: 200,
            minWidth: 50,
            maxWidth: "100%",
            objectFit: "cover",
            verticalAlign: "top",
            border: "1px solid rgba(0, 0, 0, 0.125)",
        },

        "& .deleteButton": {
            position: "absolute",
            top: theme.spacing(1),
            marginLeft: theme.spacing(-1),
            transform: "translateX(-100%)",
            backgroundColor: theme.palette.white,

            "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
        },
    },

    detailsUploadContainer: {
        "&.--hidden": {
            display: "none",
        },

        "& .fileUploaded": {
            display: "none",
        },

        "& .uploadComponent": {
            margin: 0,
            padding: 0,
        },

        "& .dropZone": {
            margin: 0,
        },
    },
}));

export default useStyles;
