import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    heading: {
        padding: theme.spacing(2),
    },

    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },

    container: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },

    mainArea: {
        width: "calc(100% - 300px)",
        paddingRight: theme.spacing(3),

        [theme.breakpoints.down(1400)]: {
            width: "100%",
            paddingRight: 0,
        },
    },

    sideBar: {
        width: 300,

        [theme.breakpoints.down(1400)]: {
            width: "100%",
        },
    },
}));

export default useStyles;
