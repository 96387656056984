import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& span + span": {
            paddingLeft: theme.spacing(1),

            "&::before": {
                content: "'|'",
                paddingRight: theme.spacing(1),
            },
        },
    },

    suggestion: {
        color: "red",
    },

    secondRow: {
        fontSize: ".9em",
        color: theme.typography.body2.color,
    },
}));

export default useStyles;
