export default {
    gutters: {
        "@media (min-width: 600px)": {
            padding: "0 16px 0 20px",
        },

        "@media (min-width: 1280px)": {
            paddingLeft: "24px",
        },
    },
};
