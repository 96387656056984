import React, { useContext } from "react";

import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import SelectLanguageDialog from "../../../shared/selectLanguageDialog";

export default ({ appraisalId, open, onClose }) => {
    if (!open) {
        return null;
    }

    const config = useContext(ConfigurationContext);

    const openDraft = (appraisalId, language) => {
        window.open(
            `/api/valuation/appraisal/${appraisalId}/draft?culture=${language.code}`,
            "_blank"
        );
        onClose();
    };

    return (
        <SelectLanguageDialog
            languages={config.supportedAppraisalLanguages}
            onSelectLanguage={(language) => openDraft(appraisalId, language, onClose)}
            quickScanId={appraisalId}
            onClose={onClose}
        />
    );
};
