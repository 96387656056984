import React from "react";
import { Dialog, DialogTitle, List, ListItem, ListItemText } from "@material-ui/core";
import ResourcedText from "./textresources/resourcedtext";

const SelectLanguageDialog = ({ languages, onSelectLanguage, onClose }) => {
    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onSelectLanguage(value);
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="download-report-dialog-title" open={true}>
            <DialogTitle id="download-report-dialog-title">
                <ResourcedText
                    resourceKey="Shell.selectLanguageDialog.title"
                    defaultValue="Select a language"
                />
            </DialogTitle>
            <List>
                {languages.map((language) => (
                    <ListItem
                        button
                        onClick={() => handleListItemClick(language)}
                        key={language.code}
                    >
                        <ListItemText primary={language.description} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default SelectLanguageDialog;
