import React, { Fragment, useEffect, useRef } from "react";
import { FormHelperText, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import AddButton from "../../../../shared/addButton";
import TranslatableTextField from "../../../../shared/translatableTextField";

const AnswersEditor = withResources(
    ({ getResource, onAnswersChanged, answers, error, culture, disabled }) => {
        const isBrowserAvailable = typeof window !== "undefined";
        const addButtonRef = useRef(null);
        const lastAnswer = useRef(null);

        useEffect(() => {
            if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
                lastAnswer.current.focus();
            }
        }, [answers, isBrowserAvailable]);

        return (
            <Fragment>
                <SortableList
                    items={answers}
                    culture={culture}
                    disabled={disabled}
                    sortingChanged={(sortedAnswers) => {
                        const copy = sortedAnswers.map((item, index) => ({
                            ...item,
                            order: index,
                        }));
                        onAnswersChanged(copy);
                    }}
                    renderItemContent={(answer, index) => (
                        <Fragment>
                            <TranslatableTextField
                                fullWidth
                                name="answers"
                                inputRef={lastAnswer}
                                value={answer.answers}
                                culture={culture}
                                handleChange={(e) => {
                                    const copy = [...answers];
                                    copy[index]["answers"][culture] = e.target.value;
                                    onAnswersChanged(copy, true);
                                }}
                            />

                            <IconButton
                                aria-label="delete"
                                disabled={disabled}
                                onClick={() => {
                                    const copy = [
                                        ...answers.slice(0, index),
                                        ...answers.slice(index + 1),
                                    ];
                                    onAnswersChanged(copy);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Fragment>
                    )}
                />

                {error && (
                    <FormHelperText style={{ margin: "0 0 16px" }} error>
                        {error}
                    </FormHelperText>
                )}

                <AddButton
                    buttonRef={addButtonRef}
                    buttonText={getResource("Valuation.questions.addAnswer", "Add answer")}
                    handleClick={() => {
                        const copy = answers.concat([{ answers: { [culture]: "" } }]);
                        onAnswersChanged(copy);
                    }}
                />
            </Fragment>
        );
    }
);

export default AnswersEditor;
