import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    FormHelperText,
    LinearProgress,
} from "@material-ui/core";
import { ExpandLess as CollapseIcon, ExpandMore as ExpandIcon, FileCopy } from "@material-ui/icons";
import useAPI from "../../../../shared/useapi";
import LabelledOutline from "../../../../shared/labelledoutline";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import { getQuestions, getAnswers } from "../../questions/dataservice";
import useStyles from "./useStyles";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import AddRowWithAutocompleteStatic from "../../../../shared/addRowWithAutocompleteStatic";
import Question from "./question";
import useDialog from "../../../../shared/usedialog";
import Copy from "./copy";

const Wrapper = (props) => {
    const { isExpandable, label, children } = props;
    const [isExpanded, setIsExpanded] = React.useState(false);

    if (!isExpandable) {
        return <LabelledOutline label={label}>{children}</LabelledOutline>;
    }

    return (
        <div>
            <Accordion
                square
                expanded={isExpanded}
                onChange={() => setIsExpanded(!isExpanded)}
                className="questionsEditorAccordion"
            >
                <AccordionSummary>
                    {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    {label}
                </AccordionSummary>

                <AccordionDetails>{isExpanded && children}</AccordionDetails>
            </Accordion>
        </div>
    );
};

const QuestionsEditor = withResources((props) => {
    const {
        getResource,
        onQuestionsChanged,
        questions,
        error,
        helperText,
        label,
        disabled,
        isExpandable = false,
    } = props;
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();

    const setQuestions = (_, copyInfo) => {
        var copyQuestions = copyInfo.questions.map((question) => {
            return {
                ...question,
                id: null,
            };
        });
        if (copyInfo.replace) {
            onQuestionsChanged(copyQuestions);
        } else {
            const uniqueCopyQuestions = copyQuestions.filter(
                (q1) => !questions.some((q2) => q1.questionId === q2.questionId)
            );
            const copy = [...questions, ...uniqueCopyQuestions];
            onQuestionsChanged(copy);
        }
    };

    const { open: handleCopy, close: handleCloseCopy, visible: copyToggled } = useDialog(
        setQuestions
    );

    const calculateAnswerCorrectionForAnswer = (questions, questionIndex, answerIndex) => {
        var minimalCorrection = questions[questionIndex].minimalCorrection;
        var maximumCorrection = questions[questionIndex].maximumCorrection;
        var value = questions[questionIndex].answers[answerIndex]["score"];

        const distance = maximumCorrection - minimalCorrection;
        const onePercent = distance / 100;
        const newAnswerCorrection = minimalCorrection + value * onePercent;

        questions[questionIndex].answers[answerIndex]["answerCorrection"] = newAnswerCorrection;
    };

    const calculateAnswerCorrectionsForQuestion = (questions, questionIndex) => {
        for (var answerIndex in questions[questionIndex].answers) {
            calculateAnswerCorrectionForAnswer(questions, questionIndex, answerIndex);
        }
    };

    const handleAnswerSliderChange = (questionIndex, answerIndex, newValue) => {
        const questionsCopy = [...questions];
        questionsCopy[questionIndex].answers[answerIndex]["score"] = newValue;

        calculateAnswerCorrectionForAnswer(questionsCopy, questionIndex, answerIndex);

        onQuestionsChanged(questionsCopy);
    };

    const handleAnswerVisibleChange = (questionIndex, answerIndex, visible) => {
        const questionsCopy = [...questions];
        questionsCopy[questionIndex].answers[answerIndex]["visible"] = visible;

        if (!questionsCopy[questionIndex].answers[answerIndex]["score"]) {
            questionsCopy[questionIndex].answers[answerIndex]["score"] = 0;
            calculateAnswerCorrectionForAnswer(questionsCopy, questionIndex, answerIndex);
        }

        onQuestionsChanged(questionsCopy);
    };

    const handleCorrectionSliderChange = (questionIndex, newMin, newMax) => {
        const copy = [...questions];
        copy[questionIndex].minimalCorrection = newMin;
        copy[questionIndex].maximumCorrection = newMax;

        calculateAnswerCorrectionsForQuestion(copy, questionIndex);

        onQuestionsChanged(copy);
    };

    const handleDependentOnAgeSliderChange = (questionIndex, newMin, newMax) => {
        const copy = [...questions];
        copy[questionIndex].minimalAge = newMin;
        copy[questionIndex].maximumAge = newMax;
        onQuestionsChanged(copy);
    };

    const addQuestion = async (questionId) => {
        if (questionId) {
            var answers = await getAnswers(questionId);
            const answersData = await answers.json();

            const copy = [...questions];

            copy.push({
                questionId: questionId,
                question: allQuestions.items.find((x) => x.id === questionId).question,
                order: questions.length,
                dependentOnAge: false,
                minimalAge: 0,
                maximumAge: 100,
                minimalCorrection: 0,
                maximumCorrection: 0,
                answers: answersData.map((answer) => ({
                    answerId: answer.id,
                    answer: answer.answer,
                    score: 0,
                    answerCorrection: 0,
                })),
            });

            onQuestionsChanged(copy);
            setTimeout(() => setExpanded(`questionPanel${questions.length}`), 100);
        }
    };

    const handleToggle = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { data: allQuestions } = useAPI(() => getQuestions(null, null, null, null), !disabled);

    const isLoaded = allQuestions || disabled;

    return !isLoaded ? (
        <LinearProgress />
    ) : (
        <Wrapper
            isExpandable={isExpandable}
            label={label || getResource("Valuation.questionnaire.questions.title", "Questions")}
        >
            <div className={classes.questionsContainer}>
                {error && <FormHelperText error>{helperText}</FormHelperText>}
                <SortableList
                    disabled={disabled}
                    items={questions}
                    sortingChanged={(sortedAnswers) => {
                        const copy = sortedAnswers.map((item, index) => ({
                            ...item,
                            order: index,
                        }));
                        onQuestionsChanged(copy);
                    }}
                    renderItemContent={(question, questionIndex) => {
                        const isExpanded = expanded === `questionPanel${questionIndex}`;
                        return (
                            <Question
                                disabled={disabled}
                                questionIndex={questionIndex}
                                question={question}
                                isExpanded={isExpanded}
                                onToggle={handleToggle(`questionPanel${questionIndex}`)}
                                onDelete={() => {
                                    const copy = [
                                        ...questions.slice(0, questionIndex),
                                        ...questions.slice(questionIndex + 1),
                                    ];
                                    onQuestionsChanged(copy);
                                    setExpanded(false);
                                }}
                                onDependentOnAgeChanged={(e) => {
                                    const copy = [...questions];
                                    copy[questionIndex].dependentOnAge = e.target.checked;
                                    onQuestionsChanged(copy);
                                }}
                                onAnswerSliderChange={handleAnswerSliderChange}
                                onAnswerVisibleChange={handleAnswerVisibleChange}
                                onCorrectionSliderChange={handleCorrectionSliderChange}
                                onDependentOnAgeSliderChange={handleDependentOnAgeSliderChange}
                            />
                        );
                    }}
                />
            </div>

            {!disabled && (
                <div className={classes.addButtonBar}>
                    <div className={classes.addButtonWrapper}>
                        <AddRowWithAutocompleteStatic
                            buttonText={getResource(
                                "Valuation.questionnaire.questions.addQuestionField",
                                "Add a question"
                            )}
                            renderAutocompleteStatic={() => (
                                <AutoCompleteStatic
                                    // Add the questions.length as the key to make sure the control
                                    // gets re-rendered with an empty value when a questions is added.
                                    key={questions.length}
                                    options={allQuestions.items}
                                    fullWidth
                                    isMedium
                                    value=""
                                    onSelection={(e) => addQuestion(e.target.value)}
                                    getOptionLabel={(option) => option.question}
                                />
                            )}
                        />
                    </div>
                    <Button size="small" className={classes.copyButton} onClick={handleCopy}>
                        <FileCopy fontSize="small" className={classes.copyIcon} />
                        {getResource(
                            "Valuation.questionnaire.questions.copyQuestionsButton",
                            "Copy questions"
                        )}
                    </Button>
                </div>
            )}
            <Copy open={copyToggled} onClose={handleCloseCopy} />
        </Wrapper>
    );
});

export default QuestionsEditor;
