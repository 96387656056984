import React, { useRef, useEffect, Fragment } from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import AddButton from "../../../../shared/addButton";
import TranslatableTextField from "../../../../shared/translatableTextField";

export default withResources(({ getResource, name, onChange, values, errors, culture }) => {
    const isBrowserAvailable = typeof window !== "undefined";
    let addButtonRef = useRef(null);
    let lastSection = useRef(null);

    useEffect(() => {
        if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
            lastSection.current.focus();
            addButtonRef.current.value = "";
        }
    }, [values, isBrowserAvailable]);

    const handleChange = (values) => {
        onChange({
            target: {
                name: name,
                value: values,
            },
        });
    };

    return (
        <Fragment>
            <SortableList
                items={values}
                sortingChanged={(sortedSections) => {
                    var copy = sortedSections.map((item, index) => ({ ...item, order: index }));
                    handleChange(copy);
                }}
                culture={culture}
                renderItemContent={(section, index) => (
                    <Fragment>
                        <TranslatableTextField
                            errors={errors && errors[index]}
                            labelResourceKey="Assets.asset.specifications.nameField"
                            labelDefaultValue="Name"
                            fullWidth
                            name="name"
                            inputRef={lastSection}
                            value={section.name}
                            culture={culture}
                            handleChange={(e) => {
                                const copy = [...values];
                                copy[index]["name"][culture] = e.target.value;
                                handleChange(copy, true);
                            }}
                            required
                        />

                        <TranslatableTextField
                            errors={errors && errors[index]}
                            labelResourceKey="Assets.asset.specifications.valueField"
                            labelDefaultValue="Value"
                            fullWidth
                            name="value"
                            value={section.value}
                            culture={culture}
                            multiline
                            rows={1}
                            handleChange={(e) => {
                                const copy = [...values];
                                copy[index]["value"][culture] = e.target.value;
                                handleChange(copy, true);
                            }}
                            required
                        />

                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                const copy = [
                                    ...values.slice(0, index),
                                    ...values.slice(index + 1),
                                ];
                                handleChange(copy);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Fragment>
                )}
            />

            <AddButton
                buttonRef={addButtonRef}
                buttonText={getResource(
                    "Assets.asset.specifications.addButton",
                    "Add specification"
                )}
                handleClick={() => {
                    const copy = values.concat([
                        {
                            name: { [culture]: "" },
                            value: { [culture]: "" },
                        },
                    ]);
                    handleChange(copy);
                }}
            />
        </Fragment>
    );
});

export function validate(values, name, errors, getResource, defaultCulture) {
    const setError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((row, i) => {
        if (!row.name[defaultCulture]) {
            setError(
                i,
                "name",
                getResource(
                    "Assets.asset.specifications.nameRequired",
                    "Please provide a name for the default language"
                )
            );
        }

        if (!row.value[defaultCulture]) {
            setError(
                i,
                "value",
                getResource(
                    "Assets.asset.specifications.valueRequired",
                    "Please provide a value for the default language"
                )
            );
        }
    });
}
