import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Popover, CardHeader, Divider, colors } from "@material-ui/core";
import withResources from "../../../../../shared/textresources/withresources";

import NotificationList from "./notificationList";
import EmptyNotificationList from "./emptyNotificationList";

const useStyles = makeStyles(() => ({
    popover: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    root: {
        width: 350,
        maxWidth: "100%",
    },
    actions: {
        backgroundColor: colors.grey[50],
        justifyContent: "center",
    },
}));

const NotificationsPopover = withResources(
    ({ getResource, notifications, icons, anchorEl, onClose, ...rest }) => {
        const classes = useStyles();

        return (
            <Popover
                {...rest}
                className={classes.popover}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <div className={classes.root}>
                    <CardHeader
                        title={getResource("Shell.notifications.header", "Notifications")}
                    />
                    <Divider />
                    {notifications.length > 0 ? (
                        <NotificationList
                            notifications={notifications}
                            icons={icons}
                            onClick={onClose}
                        />
                    ) : (
                        <EmptyNotificationList />
                    )}
                </div>
            </Popover>
        );
    }
);

NotificationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    icons: PropTypes.any,
};

export default NotificationsPopover;
