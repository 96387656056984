import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { reopen } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, taskId, summary, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(reopen);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(taskId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Maintenance.workOrderTasks.reopen.confirmationHeader",
                "Reopen work order task?"
            )}
            buttonText={getResource(
                "Maintenance.workOrderTasks.reopen.confirmationButton",
                "Reopen"
            )}
        >
            <ResourcedText
                resourceKey="Maintenance.workOrderTasks.reopen.reopenMessage"
                defaultValue="Are you sure you want to reopen '{summary}'?"
                tokens={{ summary }}
            />
        </Confirmation>
    );
});
