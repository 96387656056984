import React, { useState, useEffect, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import useAPI from "../../../shared/useapi";
import { updateRoles, getRoles as getAssetCollectionRoles } from "./dataservice";
import { getAll as getAllRoles } from "../../security/roles/dataservice";
import { getAll as getAllUsers } from "../../security/users/dataservice";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import FormTable from "../../../shared/formTable";
import AddRowWithAutocompleteStatic from "../../../shared/addRowWithAutocompleteStatic";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(updateRoles);

    const { data } = useAPI(() => getAssetCollectionRoles(), true);
    const { data: allRoles } = useAPI(() => getAllRoles(null, null, 3, null, null), true);
    const { data: allUsers } = useAPI(() => getAllUsers(null, null, true, null, null), true);

    const [roleAssignments, setRoleAssignments] = useState();

    useEffect(() => {
        data && setRoleAssignments(data);
    }, [data]);

    const onSubmit = async () => {
        var data = { roleAssignments };
        if (await invoke(data)) {
            onClose(true);
        }
    };

    const onValidate = () => {
        return {};
    };

    const handleRolesUpdate = (i, roles) => {
        var copy = [
            ...roleAssignments.slice(0, i),
            {
                ...roleAssignments[i],
                roles: roles,
            },
            ...roleAssignments.slice(i + 1),
        ];
        setRoleAssignments(copy);
    };

    const addRoleAssignment = (userId) => {
        if (userId) {
            var user = activeUsers.find((x) => x.id === userId);
            var copy = roleAssignments.concat({
                userId: userId,
                userFirstName: user.firstName,
                userLastName: user.lastName,
                userIsActive: user.isActive,
                roles: [],
            });
            setRoleAssignments(copy);
        }
    };

    const activeUsers =
        allUsers && allUsers.items && allUsers.items.filter((user) => user.isActive);
    const isLoaded = roleAssignments && allRoles && allUsers;
    const [isDirty, setIsDirty] = React.useState(false);

    return (
        <FormDrawer
            title={getResource(
                "Assets.assetCollections.roles.title",
                "Collection role assignments"
            )}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ roleAssignments }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.assetCollections.roles.okButton", "Save")}
                    hideOkButton={activeUsers.length === 0}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={() => {
                        return (
                            <Fragment>
                                {activeUsers.length === 0 && (
                                    <Alert severity="warning">
                                        {getResource(
                                            "Assets.assetCollections.roles.noUsersUnderCompany",
                                            "There are no active users attached to this company or its parent companies."
                                        )}
                                    </Alert>
                                )}
                                {roleAssignments && (
                                    <FormTable>
                                        {roleAssignments.map((roleAssignment, i) => (
                                            <tr
                                                key={roleAssignment.userId}
                                                style={{
                                                    opacity: roleAssignment.userIsActive
                                                        ? "1"
                                                        : "0.7",
                                                }}
                                            >
                                                <th style={{ width: 200 }}>
                                                    {roleAssignment.userFirstName}{" "}
                                                    {roleAssignment.userLastName}
                                                    {!roleAssignment.userIsActive &&
                                                        " (" +
                                                            getResource(
                                                                "Assets.assetCollections.roles.inactiveText",
                                                                "inactive"
                                                            ) +
                                                            ")"}
                                                </th>
                                                <td>
                                                    <AutoCompleteStatic
                                                        options={allRoles && allRoles.items}
                                                        value={roleAssignment.roles}
                                                        isMedium
                                                        noMargin
                                                        multiple
                                                        fullWidth
                                                        noOptionsText={getResource(
                                                            "Assets.assetCollections.roles.noRolesAvailable",
                                                            "No roles available"
                                                        )}
                                                        onSelection={(e) =>
                                                            handleRolesUpdate(i, e.target.value)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </FormTable>
                                )}

                                <AddRowWithAutocompleteStatic
                                    disabled={roleAssignments.length === activeUsers.length}
                                    buttonText={getResource(
                                        "Assets.assetCollections.roles.addRoleAssignmentField",
                                        "Add role assignment"
                                    )}
                                    renderAutocompleteStatic={() => (
                                        <AutoCompleteStatic
                                            fullWidth
                                            isMedium
                                            label={getResource(
                                                "Assets.assetCollections.roles.addRoleAssignmentField",
                                                "Add role assignment"
                                            )}
                                            value=""
                                            onSelection={(e) => addRoleAssignment(e.target.value)}
                                            options={activeUsers.filter(
                                                (user) =>
                                                    !roleAssignments.some(
                                                        (x) => x.userId === user.id
                                                    )
                                            )}
                                        />
                                    )}
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
