import React, { useState } from "react";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.isApproved);
    }

    return (
        <PageListSearch
            searchFields={[
                <AutoCompleteStatic
                    label={getResource("Security.applications.search.statusField", "Status")}
                    autoFocus
                    value={searchValues.isApproved}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, isApproved: e.target.value })
                    }
                    options={[
                        {
                            id: "",
                            name: getResource("Security.applications.search.status.new", "New"),
                        },
                        {
                            id: "true",
                            name: getResource(
                                "Security.applications.search.status.approved",
                                "Approved"
                            ),
                        },
                        {
                            id: "false",
                            name: getResource(
                                "Security.applications.search.status.rejected",
                                "Rejected"
                            ),
                        },
                    ]}
                    disableClearable
                />,
            ]}
        />
    );
});

export default Search;
