import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "../usages/dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, assetId, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke("assets", assetId, id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={getResource("Asset.assetUsage.delete.header", "Delete asset readings?")}
            buttonText={getResource("Asset.assetUsage.delete.okButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Asset.assetUsage.delete.message"
                defaultValue="Are you sure you want to delete these readings?"
            />
        </Confirmation>
    );
});
