import React, { useContext } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    MergeType as MergeIcon,
    QuestionAnswer as EditQuestionnaireIcon,
    Pageview as ReviewIcon,
} from "@material-ui/icons";

import _ from "underscore";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import Path from "../../../shared/path";
import useStyles from "./useStyles";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        onMerge,
        onEditQuestionnaire,
        onReview,
        defaultDebounceInterval,
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        data = data || {};
        const items = data.items;

        const classes = useStyles();

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <ContentHolder>
                <Header
                    title={getResource("Library.assetModels.header.title", "Models")}
                    renderActions={() => {
                        return (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                requiredOperation="asset-models-create"
                                buttonText={getResource(
                                    "Library.assetModels.newAssetModelButton",
                                    "Create"
                                )}
                                id="e2e_createButtonModel"
                            />
                        );
                    }}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Library.assetModels.noResults",
                        "No models available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Library.assetModels.overview.nameHeader"
                                        defaultValue="Name"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Library.assetModels.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() =>
                                            row.isSuggestion ? onReview(row.id) : onEdit(row.id)
                                        }
                                        requiredOperation={
                                            row.isSuggestion
                                                ? "asset-models-approve-suggestion"
                                                : "asset-models-update"
                                        }
                                    >
                                        <TableCell component="th" scope="row">
                                            <div className={classes.root}>
                                                <span>{row.manufacturerName}</span>
                                                <span>{row.name}</span>
                                            </div>
                                            <div className={classes.secondRow}>
                                                <Path
                                                    path={row.assetCategoryNames}
                                                    useRegularText
                                                />
                                            </div>
                                            {row.isSuggestion ? (
                                                <div className={classes.suggestion}>
                                                    {getResource(
                                                        "Library.manufacturers.proposedText",
                                                        "Proposed by {suggestedBy} on {suggestedOn}",
                                                        {
                                                            ...row,
                                                            suggestedOn: formatDate(
                                                                row.suggestedOn,
                                                                selectedUiCulture,
                                                                selectedTimezone,
                                                                false,
                                                                true,
                                                                false,
                                                                false
                                                            ),
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Library.assetModels.editAssetModelButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "asset-models-update",
                                                        onClick: () => onEdit(row.id),
                                                        showOnlyWhen: !row.isSuggestion,
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.assetModels.deleteAssetModelButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "asset-models-delete",
                                                        onClick: () => onDelete(row.id),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.assetModels.mergeAssetModelButton",
                                                            "Merge"
                                                        ),
                                                        icon: <MergeIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "asset-models-merge",
                                                        onClick: () =>
                                                            onMerge({
                                                                id: row.id,
                                                                version: row.version,
                                                                name:
                                                                    row.manufacturerName +
                                                                    " " +
                                                                    row.name,
                                                            }),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.assetModels.editQuestionnaireButton",
                                                            "Edit questionnaire"
                                                        ),
                                                        icon: <EditQuestionnaireIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "questionnaires-update",
                                                        onClick: () =>
                                                            onEditQuestionnaire({
                                                                manufacturerName:
                                                                    row.manufacturerName,
                                                                assetModelName: row.name,
                                                                assetModelId: row.id,
                                                                assetCategoryNames:
                                                                    row.assetCategoryNames,
                                                                assetCategoryId:
                                                                    row.assetCategoryId,
                                                            }),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Library.assetModels.reviewSuggestionButton",
                                                            "Review proposal"
                                                        ),
                                                        icon: <ReviewIcon />,
                                                        itemId: row.id,
                                                        requiredOperation:
                                                            "asset-models-approve-suggestion",
                                                        onClick: () => onReview(row.id),
                                                        showOnlyWhen: row.isSuggestion,
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
