import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
    ExpandLess as CollapseIcon,
    Delete as DeleteIcon,
    ExpandMore as ExpandIcon,
} from "@material-ui/icons";
import useStyles from "./useStyles";
import Answer from "./answer";
import RangeSlider from "./rangeSlider";
import clsx from "clsx";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import variables from "./variables";
import withResources from "../../../../shared/textresources/withresources";

const { marksPositiveAge, marksPositivePercentage, marksPositiveNegativePercentage } = variables;

const Question = withResources((props) => {
    const {
        getResource,
        question,
        questionIndex,
        isExpanded,
        onToggle,
        onDelete,
        onDependentOnAgeChanged,
        onDependentOnAgeSliderChange,
        onCorrectionSliderChange,
        onAnswerSliderChange,
        onAnswerVisibleChange,
        disabled,
    } = props;
    const classes = useStyles();

    const addPlusToPositiveNumber = (value) => {
        let strValue = value.toString();
        if (strValue.charAt(0) !== "-" && strValue.charAt(0) !== "0") {
            strValue = "+" + value;
        }

        return strValue;
    };

    return (
        <Accordion
            id={questionIndex}
            key={question.id}
            square
            expanded={isExpanded}
            onChange={onToggle}
            className={classes.accordion}
        >
            <AccordionSummary
                aria-controls={`questionPanel${questionIndex}-content`}
                id={`questionPanel${questionIndex}-header`}
            >
                {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                <div className={classes.accordionInformation}>
                    <div className={clsx("e2e_question", classes.accordionQuestion)}>
                        {question.question}
                    </div>
                    <div className={classes.accordionDetails}>
                        <div className={classes.accordionAge}>
                            {getResource("Valuation.questionnaire.questions.accordionAge", "Age")}:{" "}
                            {question.dependentOnAge
                                ? question.minimalAge + " - " + question.maximumAge
                                : getResource(
                                      "Valuation.questionnaire.questions.accordionAnyAge",
                                      "any"
                                  )}
                        </div>
                        <div className={classes.accordionCorrection}>
                            {getResource(
                                "Valuation.questionnaire.questions.accordionCorrection",
                                "Correction"
                            )}
                            :{" "}
                            {addPlusToPositiveNumber(question.minimalCorrection) +
                                " / " +
                                addPlusToPositiveNumber(question.maximumCorrection)}
                        </div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails id={`questionPanel${questionIndex}-content`}>
                {isExpanded && (
                    <Grid container>
                        <Grid item xs={11} className={classes.questionNameItem}>
                            <Typography component="h2" variant="h4">
                                {question.question}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.deleteItem}>
                            {!disabled && (
                                <IconButton
                                    className={classes.deleteIcon}
                                    aria-label="delete"
                                    onClick={onDelete}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={clsx(classes.dependentOnAgeItem, classes.labelItem)}
                        >
                            <FormControlLabel
                                label={getResource(
                                    "Valuation.questionnaire.questions.dependentOnAgeField",
                                    "Dependent on age"
                                )}
                                classes={{
                                    label: classes.label,
                                }}
                                control={
                                    <Checkbox
                                        checked={Boolean(question.dependentOnAge)}
                                        className={classes.dependentOnAgeCheckbox}
                                        onChange={onDependentOnAgeChanged}
                                        disabled={disabled}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {question.dependentOnAge && (
                                <RangeSlider
                                    absoluteMin={marksPositiveAge[0].value}
                                    absoluteMax={
                                        marksPositiveAge[marksPositiveAge.length - 1].value
                                    }
                                    defaultMin={question.minimalAge}
                                    defaultMax={question.maximumAge}
                                    marks={marksPositiveAge}
                                    questionIndex={questionIndex}
                                    handleRangeSliderChange={onDependentOnAgeSliderChange}
                                    ariaLabel="Dependent on age"
                                    disabled={disabled}
                                />
                            )}
                        </Grid>
                        <Grid item xs={4} className={classes.labelItem}>
                            <Typography
                                id={`correction_${questionIndex}`}
                                className={classes.correctionLabel}
                            >
                                <ResourcedText
                                    resourceKey="Valuation.questionnaire.questions.correctionField"
                                    defaultValue="Correction"
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <RangeSlider
                                absoluteMin={marksPositiveNegativePercentage[0].value}
                                absoluteMax={
                                    marksPositiveNegativePercentage[
                                        marksPositiveNegativePercentage.length - 1
                                    ].value
                                }
                                defaultMin={question.minimalCorrection}
                                defaultMax={question.maximumCorrection}
                                marks={marksPositiveNegativePercentage}
                                questionIndex={questionIndex}
                                handleRangeSliderChange={onCorrectionSliderChange}
                                ariaLabel={getResource(
                                    "Valuation.questionnaire.questions.correctionField",
                                    "Correction"
                                )}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.answersHeading}
                                component="h3"
                                variant="body1"
                            >
                                <ResourcedText
                                    resourceKey="Valuation.questionnaire.questions.answersField"
                                    defaultValue="Answers"
                                />
                            </Typography>
                            {question.answers.length === 0 && (
                                <Alert severity="info">
                                    <ResourcedText
                                        resourceKey="Valuation.questionnaire.questions.noAnswers"
                                        defaultValue="This question has no answers"
                                    />
                                </Alert>
                            )}
                            {question.answers.map((answer, answerIndex) => (
                                <Answer
                                    answer={answer}
                                    answerIndex={answerIndex}
                                    questionIndex={questionIndex}
                                    onAnswerSliderChange={onAnswerSliderChange}
                                    onAnswerVisibleChange={onAnswerVisibleChange}
                                    marksPositive={marksPositivePercentage}
                                    key={answerIndex}
                                    disabled={disabled}
                                />
                            ))}
                        </Grid>
                    </Grid>
                )}
            </AccordionDetails>
        </Accordion>
    );
});

export default Question;
