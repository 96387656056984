import React from "react";
import useAPI from "../../../shared/useapi";
import { unArchive } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, onClose, open, id, name, library }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(unArchive);

    const handleDeactivate = async () => {
        if (await invoke(library, id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleDeactivate}
            handleClose={onClose}
            headerText={getResource("Document.documents.unArchive.header", "Un-archive document?")}
            buttonText={getResource("Document.documents.unArchive.okButton", "Un-archive")}
        >
            {getResource(
                "Document.documents.unArchive.confirmationMessage",
                "Are you sure you want to un-archive '{name}'?",
                { name }
            )}
        </Confirmation>
    );
});
