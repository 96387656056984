import React from "react";
import { LinearProgress } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

import useAPI from "../../../shared/useapi";
import { getScopes, add } from "./dataservice";
import { getAll as getOperations } from "./../operations/dataservice";
import withResources from "../../../shared/textresources/withresources";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(add);

    const { data: scopes } = useAPI(() => getScopes(), true);
    const { data: operations } = useAPI(() => getOperations(null, false, null, null), true);

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Security.roles.add.enterDefaultName",
                "Please enter a name for the default language"
            );
        }

        if (!errors.name) {
            cultures.forEach((culture) => {
                if (values.description[culture.code] && !values.name[culture.code]) {
                    errors.name = getResource(
                        "Security.roles.add.enterLanguageName",
                        "Please enter a name for the language '{language}'",
                        { language: culture.description }
                    );
                }
            });
        }

        if (!values.operations || values.operations.length === 0) {
            errors.operations = getResource(
                "Security.roles.add.selectOperation",
                "Please select one or more operations"
            );
        }

        return errors;
    };

    var isLoaded = scopes && operations;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.roles.add.title", "Create role")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ name: "", description: "" }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.roles.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        return (
                            <React.Fragment>
                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Security.roles.add.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="description"
                                    labelResourceKey="Security.roles.add.descriptionField"
                                    labelDefaultValue="Description"
                                    fullWidth
                                    multiline
                                    value={values.description}
                                    culture={culture}
                                    handleChange={handleChange}
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="scope"
                                    label={getResource("Security.roles.add.scopeField", "Scope")}
                                    value={values.scope}
                                    multiple={true}
                                    options={scopes}
                                    onSelection={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture}
                                    fullWidth
                                    isMedium
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="operations"
                                    label={getResource(
                                        "Security.roles.add.operationsField",
                                        "Permissions"
                                    )}
                                    value={values.operations}
                                    multiple={true}
                                    options={operations.items}
                                    onSelection={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture}
                                    fullWidth
                                    isMedium
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
