import React from "react";
import TextField from "@material-ui/core/TextField";
import useStyles from "./useStyles";

const NrInput = (props) => {
    const { value, onChange, onBlur, inputAttributes, onKeyDown, required, ...rest } = props;
    const classes = useStyles();

    return (
        <TextField
            value={value != null ? value : ""}
            margin="none"
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
                classes: { input: classes.input },
            }} /** Note that Inputprops is for the props and inputProps (without capital) is for the attributes */
            inputProps={inputAttributes}
            onKeyDown={onKeyDown}
            required={required}
            {...rest}
        />
    );
};

export default NrInput;
