import React from "react";
import { Slot } from "../../../shared/slotmachine/index";
import ContentHolder from "../../../shared/contentHolder";
import WidgetsOverview from "../../../shared/widgetsOverview";

export default (props) => {
    return (
        <ContentHolder>
            <Slot name="asset-header" list={true} {...props} />
            <WidgetsOverview
                mainWidgetSlots={[
                    <Slot name="asset-media" list={true} {...props} />,
                    <Slot name="asset-facts" list={true} {...props} />,
                    <Slot name="asset-components" list={true} {...props} />,
                    <Slot name="asset-usage" list={true} {...props} />,
                    <Slot name="asset-documents-generic" list={true} {...props} />,
                    <Slot name="asset-advertisements" list={true} {...props} />,
                    <Slot name="asset-quick-scan" list={true} {...props} />,
                    <Slot name="asset-financial-detail" list={true} {...props} />,
                    <Slot name="asset-position" list={true} {...props} />,
                    <Slot name="asset-documents-financial" list={true} {...props} />,
                    <Slot name="asset-ownership" list={true} {...props} />,
                    <Slot name="asset-documents-ownership" list={true} {...props} />,
                ]}
                sidebarWidgetSlots={[<Slot name="asset-summary" list={true} {...props} />]}
            />
        </ContentHolder>
    );
};
