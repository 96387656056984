import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update, getAssetModelValues } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./editform";
import PagedListInputForAssets from "../../../shared/pagedListInput/pagedListInputForAssets";

export default withResources(
    ({
        getResource,
        open,
        assetId,
        assetName,
        assetModelId,
        assetModelName,
        manufacturerName,
        onClose,
    }) => {
        if (!open) {
            return null;
        }

        const [selectedAsset, setSelectedAsset] = useState({
            id: assetId,
            name: assetName
                ? assetName
                : assetModelName && manufacturerName
                ? assetModelName + " / " + manufacturerName
                : "",
            assetModelName,
            assetModelId,
        });

        const isCreate = assetId == null;

        const { data, loading: dataLoading } = useAPI(
            () => get(selectedAsset.id),
            Boolean(selectedAsset.id),
            [selectedAsset]
        );

        const { loading, invoke } = useAPI((values) => update(selectedAsset.id, values));

        const {
            data: assetModelQuestionnaire,
            loading: assetModelQuestionnaireLoading,
        } = useAPI(
            () => getAssetModelValues(selectedAsset.assetModelId, true),
            Boolean(selectedAsset.assetModelId),
            [selectedAsset]
        );

        const onSubmit = async (values) => {
            if (await invoke(values.values)) {
                onClose(true);
            }
        };

        const [isDirty, setIsDirty] = useState(false);
        return (
            <React.Fragment>
                <FormDrawer
                    title={
                        isCreate
                            ? getResource(
                                  "Valuation.assetQuestionnaire.add.title",
                                  "Create asset questionnaire"
                              )
                            : getResource(
                                  "Valuation.assetQuestionnaire.edit.title",
                                  "Edit asset questionnaire"
                              )
                    }
                    size="medium"
                    onClose={() => onClose(false)}
                    isDirty={isDirty}
                >
                    <PagedListInputForAssets
                        autoFocus
                        label={getResource(
                            "Valuation.assetQuestionnaire.create.assetField",
                            "Asset"
                        )}
                        name="asset"
                        value={selectedAsset}
                        onSelection={(e, asset) => {
                            var newAsset = {
                                id: asset.id,
                                name: asset.name
                                    ? asset.name
                                    : asset.assetModelName + " / " + asset.manufacturerName,
                                assetModelName: asset.assetModelName,
                                assetModelId: asset.assetModelId,
                            };
                            setSelectedAsset(newAsset);
                        }}
                        fullWidth
                        isMedium
                        dialogTitle={getResource(
                            "Valuation.assetQuestionnaire.create.asset.dialogTitle",
                            "Select an asset"
                        )}
                        helperText={getResource(
                            "Valuation.assetQuestionnaire.create.asset.selectAssetText",
                            "Select the asset for which you want to create an questionnaire"
                        )}
                        disabled={!isCreate}
                        disableClearable
                        required
                        className="e2e_asset"
                    />
                    {dataLoading || assetModelQuestionnaireLoading || selectedAsset.id == null ? (
                        (dataLoading || assetModelQuestionnaireLoading) && <LinearProgress />
                    ) : (
                        <LocalizedForm
                            initialValues={data || { action: "Inherit", questions: [] }}
                            onIsDirty={setIsDirty}
                            loading={loading}
                            okButtonText={
                                isCreate
                                    ? getResource(
                                          "Valuation.assetQuestionnaire.create.okButton",
                                          "Create"
                                      )
                                    : getResource(
                                          "Valuation.assetQuestionnaire.edit.okButton",
                                          "Update"
                                      )
                            }
                            onSubmit={onSubmit}
                            onValidate={(values) => validate(values, getResource)}
                            renderForm={(culture, values, handleChange, errors) => (
                                <EditForm
                                    assetModelQuestionnaire={assetModelQuestionnaire}
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    assetModelName={selectedAsset.assetModelName}
                                />
                            )}
                        />
                    )}
                </FormDrawer>
            </React.Fragment>
        );
    }
);
