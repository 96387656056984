import React from "react";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const InfoBlock = ({ title, rows }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Typography variant="h4" component="h2">
                {title}
            </Typography>
            {rows && rows.length > 0 && (
                <dl>
                    {rows.map((row) => {
                        if (!row.title || !row.value) return null;

                        return (
                            <div
                                key={row.title}
                                className={row.isDescription ? "task-description" : ""}
                            >
                                <dt>{row.title}</dt>
                                <dd>{row.value}</dd>
                            </div>
                        );
                    })}
                </dl>
            )}
        </Paper>
    );
};

export default InfoBlock;
