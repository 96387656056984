import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, id, library, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(library, id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Document.documents.delete.header", "Delete document?")}
            buttonText={getResource("Document.documents.delete.okButton", "Delete")}
        >
            {getResource(
                "Document.documents.delete.deleteMessage",
                "The document will be deleted."
            )}
        </Confirmation>
    );
});
