import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function del(selectedId) {
    return await interceptedFetch(`/api/valuation/quickScan/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function getQuickScan(quickScanId) {
    return await interceptedFetch(`/api/valuation/quickScan/${quickScanId}`);
}

async function requestValuation(companyId, assetId, assetModelId, constructionDate, futureMonths) {
    return await interceptedFetch("/api/valuation/valuationrequest", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ companyId, assetId, assetModelId, constructionDate, futureMonths }),
    });
}

async function isValuationOutdated(valuationId, constructionDate, futureMonths) {
    const params = {
        valuationId,
        constructionDate,
        futureMonths,
    };
    const url = `/api/valuation/valuation/isValuationOutdated?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function reportAvailable(quickScanId) {
    return await interceptedFetch(`/api/valuation/quickScan/${quickScanId}/reportavailable`);
}

export { del, getQuickScan, reportAvailable, requestValuation, isValuationOutdated };
