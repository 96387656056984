import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { get, del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, id, open, onClose, isInstalled, isSealed }) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    if (!open) {
        return false;
    }

    const { data: component } = useAPI(() => get(id), true);

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    var componentName =
        component &&
        component.name &&
        (component.name[selectedCulture] || component.name[defaultCulture]);

    const headerText = isInstalled
        ? getResource("Asset.component.delete.installedHeader", "Cannot delete installed component")
        : isSealed 
        ? getResource("Asset.component.delete.sealedHeader", "Cannot delete sealed component")
        : getResource("Asset.component.delete.header", "Delete component {componentName}?", {
              componentName,
          });

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={headerText}
            buttonText={getResource("Asset.component.delete.okButton", "Delete")}
            buttonHidden={isInstalled || isSealed}
        >
            {!isInstalled && !isSealed && (
                <ResourcedText
                    resourceKey="Asset.component.delete.deleteMessage"
                    defaultValue="Are you sure you want to delete this component? "
                />
            )}

            {isInstalled && (
                <ResourcedText
                    resourceKey="Asset.component.delete.installedMessage"
                    defaultValue="This component is currently installed on an asset and cannot be deleted. "
                />
            )}

            {isSealed && (
                <ResourcedText
                    resourceKey="Asset.component.delete.sealedMessage"
                    defaultValue="This component is currently sealed and cannot be deleted. "
                />
            )}
        </Confirmation>
    );
});
