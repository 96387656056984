import interceptedFetch from "../../../shared/interceptedFetch";

async function clearAllCacheItems(endpoint, key) {
    const url = endpoint + `clear`;
    return await interceptedFetch(url, {
        method: "GET",
        headers: {
            CacheApiKey: key,
        },
    });
}

export { clearAllCacheItems };
