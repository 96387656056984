import interceptedFetch from "../../../shared/interceptedFetch";

async function get(assetId) {
    const url = `/api/assets/assetFinancialDetail/${assetId}`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/assets/assetFinancialDetail/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

export { get, update };
