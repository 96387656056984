import interceptedFetch from "../../../shared/interceptedFetch";

async function get() {
    const url = `/api/security/user/profile`;

    return await interceptedFetch(url);
}

async function update(values) {
    return await interceptedFetch(`/api/security/user/profile`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export { get, update };
