import React, { useRef, useEffect, Fragment } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import AddButton from "../../../../shared/addButton";
import PagedListInputForAdvertisements from "../../../../shared/pagedListInput/pagedListInputForAdvertisements";
import clsx from "clsx";
import TranslatableTextField from "../../../../shared/translatableTextField";

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        minWidth: 150,
        width: 150,

        [theme.breakpoints.up("md")]: {
            minWidth: 300,
            width: 300,
        },

        "&.--line-has-error": {
            alignSelf: "flex-start",
        },
    },

    advertisementsContainer: {
        minWidth: 1,
        flexGrow: 1,

        "&.--line-has-error": {
            alignSelf: "flex-start",
        },
    },
}));

export default withResources(
    ({ getResource, name, onChange, values, errors, culture, disabled, allowAdd }) => {
        const isBrowserAvailable = typeof window !== "undefined";
        const addButtonRef = useRef(null);
        const lastSection = useRef(null);
        const classes = useStyles();

        useEffect(() => {
            if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
                lastSection.current.focus();
            }
        }, [values, isBrowserAvailable]);

        const handleChange = (values) => {
            onChange({
                target: {
                    name: name,
                    value: values,
                },
            });
        };

        return (
            <Fragment>
                <SortableList
                    items={values}
                    sortingChanged={(sortedSections) => {
                        const copy = sortedSections.map((item, index) => ({
                            ...item,
                            order: index,
                        }));
                        handleChange(copy);
                    }}
                    culture={culture}
                    disabled={disabled || !allowAdd}
                    renderItemContent={(item, index) => {
                        const lineErrors = errors && errors[index];
                        const lineHasError =
                            lineErrors && (lineErrors["title"] || lineErrors["advertisements"]);

                        return (
                            <Fragment>
                                <div
                                    className={clsx(
                                        classes.titleContainer,
                                        lineHasError && "--line-has-error"
                                    )}
                                >
                                    <TranslatableTextField
                                        errors={errors && errors[index]}
                                        labelResourceKey="Marketplace.configuration.clusterEditor.titleField"
                                        labelDefaultValue="Title"
                                        fullWidth
                                        name="title"
                                        inputRef={lastSection}
                                        value={item.title}
                                        culture={culture}
                                        handleChange={(e) => {
                                            const copy = [...values];
                                            copy[index]["title"][culture] = e.target.value;
                                            handleChange(copy, true);
                                        }}
                                        required
                                    />
                                </div>

                                <div
                                    className={clsx(
                                        classes.advertisementsContainer,
                                        lineHasError && "--has-error"
                                    )}
                                >
                                    <PagedListInputForAdvertisements
                                        label={getResource(
                                            "Marketplace.configuration.clusterEditor.advertisementsField",
                                            "Advertisements"
                                        )}
                                        errors={errors && errors[index]}
                                        name="advertisements"
                                        value={item.advertisements}
                                        fullWidth
                                        isMedium
                                        disabled={disabled}
                                        noMargin
                                        multiple
                                        dialogTitle={getResource(
                                            "Marketplace.configuration.clusterEditor.dialogTitle",
                                            "Select one or more advertisements"
                                        )}
                                        disableClearable
                                        onSelection={(e) => {
                                            const copy = [...values];
                                            copy[index]["advertisements"] = e.target.value;
                                            handleChange(copy);
                                        }}
                                        required
                                    />
                                </div>

                                {allowAdd && (
                                    <IconButton
                                        aria-label="delete"
                                        disabled={disabled}
                                        onClick={(e) => {
                                            const copy = [
                                                ...values.slice(0, index),
                                                ...values.slice(index + 1),
                                            ];
                                            handleChange(copy);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Fragment>
                        );
                    }}
                />

                {allowAdd && (
                    <AddButton
                        disabled={disabled}
                        buttonRef={addButtonRef}
                        buttonText={getResource(
                            "Marketplace.configuration.clusterEditor.addLinkText",
                            "Add link"
                        )}
                        handleClick={() => {
                            const copy = values.concat([
                                {
                                    order: Math.max(...values.map((value) => value.order)) + 1,
                                    title: { [culture]: "" },
                                    advertisements: [],
                                },
                            ]);
                            handleChange(copy);
                        }}
                    />
                )}
            </Fragment>
        );
    }
);

export function validate(values, name, errors, getResource, defaultCulture) {
    const setError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((row, i) => {
        if (!row.title[defaultCulture]) {
            setError(
                i,
                "title",
                getResource(
                    "Marketplace.configuration.clusterEditor.titleRequired",
                    "Please provide a title for the default language"
                )
            );
        }

        if (row.advertisements.length === 0) {
            setError(
                i,
                "advertisements",
                getResource(
                    "Marketplace.configuration.clusterEditor.advertisementsRequired",
                    "Please select one or more advertisements"
                )
            );
        }
    });
}
