import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import { getByCompany } from "../../../security/users/dataservice";
import useAPI from "../../../../shared/useapi";
import { LinearProgress } from "@material-ui/core";

const ChooseUser = withResources(({ getResource, quickScan, companyId, onChange }) => {
    const { loading, data } = useAPI(() => getByCompany(companyId), true);

    return loading ? (
        <LinearProgress />
    ) : (
        <AutoCompleteStatic
            name="userId"
            value={quickScan.userId}
            label={getResource("Valuation.quickScan.chooseUser.header", "Choose user")}
            options={data}
            onSelection={(e) => {
                onChange(e.target.value);
            }}
            required
        />
    );
});

export default ChooseUser;
