export default {
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        paddingRight: 10,
        paddingLeft: 10,

        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },

        "& > svg": {
            padding: "0 5px 3px 0",
        },
    },
};
