import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import { Check as PublishedIcon, Clear as UnpublishedIcon } from "@material-ui/icons";
import React from "react";
import withresources from "../../../shared/textresources/withresources";
import useStyles from "./useStyles";

const PagedListContent = withresources((props) => {
    const {
        getResource,
        items,
        handleRowClick,
        getRequiredOperationForRowClick,
        renderActionMenu,
        renderSelectButton,
    } = props;

    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <ResourcedText
                            resourceKey="Marketplace.advertisements.overview.publishedHeader"
                            defaultValue="Published"
                        />
                    </TableCell>
                    <TableCell>
                        <ResourcedText
                            resourceKey="Marketplace.advertisements.overview.typeHeader"
                            defaultValue="Type"
                        />
                    </TableCell>
                    <TableCell>
                        <ResourcedText
                            resourceKey="Marketplace.advertisements.overview.titleHeader"
                            defaultValue="Title"
                        />
                    </TableCell>
                    <TableCell>
                        <ResourcedText
                            resourceKey="Marketplace.advertisements.overview.assetHeader"
                            defaultValue="Asset"
                        />
                    </TableCell>
                    <TableCell align="right">
                        <ResourcedText
                            resourceKey="Marketplace.advertisements.overview.actionsHeader"
                            defaultValue="Actions"
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items &&
                    items.map((row) => {
                        const assetName =
                            row.assetName || `${row.manufacturerName} ${row.assetModelName}`;

                        return (
                            <AuthorizedTableRow
                                key={row.id}
                                id={row.id}
                                hover
                                handleClick={() => handleRowClick(row)}
                                requiredOperation={getRequiredOperationForRowClick()}
                            >
                                <TableCell component="th" scope="row" align="center">
                                    {row.isPublished ? (
                                        <PublishedIcon className={classes.publishedIcon} />
                                    ) : (
                                        <UnpublishedIcon className={classes.unPublishedIcon} />
                                    )}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    {getResource(
                                        `Marketplace.advertisement.type.${row.type.toLowerCase()}`,
                                        row.type
                                    )}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {assetName}
                                </TableCell>

                                <TableCell
                                    align="right"
                                    style={{ width: renderSelectButton ? 136 : "auto" }}
                                >
                                    {renderActionMenu && renderActionMenu(row, assetName)}
                                    {renderSelectButton && renderSelectButton(row.id)}
                                </TableCell>
                            </AuthorizedTableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
});

export default PagedListContent;
