import React, { Component } from "react";
import { LinearProgress, Grid, Button } from "@material-ui/core";

import DataMutations from "../helpers/datamutations.js";
import Data from "../data.json";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";
import AutoCompleteStatic from "../../../../../shared/autocompleteStatic";

class Execute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            month: null,
            year: null,
            fairMarketValue: null,
            liquidationValue: null,
            monthOptions: undefined,
            yearOptions: undefined,
            isFetching: true,
        };

        this.DM = new DataMutations();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.isFetching || nextProps.valuationData.technicalLife === null) return false;

        let currentState = this.state;
        let monthOptions = Data.FullMonthNames.map((item, index) => {
            return { key: index + 1, value: index + 1, text: item };
        });

        let yearOptions = [];
        let year = new Date().getFullYear();
        let endYear = year - nextProps.valuationData.technicalLife;
        for (let y = year; y >= endYear; y--) {
            yearOptions.push({ key: y, value: y, text: y });
        }
        currentState.monthOptions = monthOptions;
        currentState.yearOptions = yearOptions;
        currentState.isFetching = false;

        this.setState(currentState);

        return true;
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    executeCallback() {
        let month = this.state.month;
        let year = this.state.year;

        let values = [[], []];
        const series = this.props.highchartsRef.refs.highcharts.chart.series;

        values.map((arrItem, arrIndex) => {
            return series[arrIndex].data.map((item, index) => {
                return values[arrIndex].push(Number(item.y));
            });
        });

        let output = this.DM.calculateQuickscanValues(values, Number(year), Number(month));

        this.setState({
            fairMarketValue: output.FairMarketValue,
            liquidationValue: output.LiquidationValue,
        });
    }

    render() {
        return this.state.isFetching ? (
            <LinearProgress />
        ) : (
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <AutoCompleteStatic
                        name="month"
                        value={this.state.month}
                        fullWidth
                        label={
                            <ResourcedText
                                resourceKey="Valuation.execute.monthField"
                                defaultValue="Month"
                            />
                        }
                        onSelection={(e) => this.handleChange(e)}
                        options={this.state.monthOptions.map((month) => ({
                            id: month.value,
                            name: month.text,
                        }))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutoCompleteStatic
                        name="year"
                        value={this.state.year}
                        fullWidth
                        label={
                            <ResourcedText
                                resourceKey="Valuation.execute.yearField"
                                defaultValue="Year"
                            />
                        }
                        onSelection={(e) => this.handleChange(e)}
                        options={this.state.yearOptions.map((year) => ({
                            id: year.value,
                            name: year.text,
                        }))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => this.executeCallback()}
                    >
                        <ResourcedText
                            resourceKey="Valuation.execute.goButton"
                            defaultValue="Go!"
                        />
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <label>
                        <strong>
                            <ResourcedText
                                resourceKey="Valuation.execute.fairMarketValueLabel"
                                defaultValue="Fair market value"
                            />
                        </strong>
                        : {this.state.fairMarketValue}
                    </label>
                </Grid>
                <Grid item xs={4}>
                    <label>
                        <strong>
                            <ResourcedText
                                resourceKey="Valuation.execute.liquidationLabel"
                                defaultValue="Liquidation value"
                            />
                        </strong>
                        : {this.state.liquidationValue}
                    </label>
                </Grid>
            </Grid>
        );
    }
}

export default Execute;
