import React, { useContext, useState, useEffect } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import List from "./list";
import Purchase from "./purchase";
import DownloadReport from "../quickscan/downloadreport";
import Delete from "../quickscan/delete";
import useDialog from "../../../shared/usedialog";
import Invoice from "../../administration/invoices/forReference";

const QuickScan = ({ history }) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
    });

    const { data, invoke, loading } = useAPI(
        () => getAll(searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams.page, searchParams.pageSize, companyScope.id]
    );

    const {
        open: handlePurchase,
        close: handleClosePurchase,
        visible: purchaseToggled,
        args: purchaseId,
    } = useDialog(invoke);
    const {
        open: handleDownloadReport,
        close: handleCloseDownloadReport,
        visible: downloadReportToggled,
        args: downloadReportId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleInvoice,
        close: handleCloseInvoice,
        visible: invoiceToggled,
        args: invoiceId,
    } = useDialog();

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operationName) {
        if (!companyScope.id) {
            return operationName;
        }

        return {
            name: operationName,
            companyId: companyScope.id,
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <React.Fragment>
            <PrivateRoute
                key="asset-model-quick-scans-view"
                requiredOperation="asset-model-quick-scans-view"
                path="/quick-scan/asset-model"
                render={(props) => {
                    return (
                        <List
                            data={data}
                            page={searchParams.page}
                            pageSize={searchParams.pageSize}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            getRequiredOperation={getRequiredOperation}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onPurchase={handlePurchase}
                            onDownloadReport={handleDownloadReport}
                            onDelete={handleDelete}
                            onInvoice={handleInvoice}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />

            <Purchase
                open={purchaseToggled}
                id={purchaseId}
                onClose={handleClosePurchase}
                history={history}
            />

            <DownloadReport
                open={downloadReportToggled}
                quickScanId={downloadReportId}
                onClose={handleCloseDownloadReport}
            />

            <Delete
                open={deleteToggled}
                onClose={handleCloseDelete}
                quickScanId={deleteArgs && deleteArgs.id}
                reference={deleteArgs && deleteArgs.reference}
            />

            <Invoice
                open={invoiceToggled}
                onClose={handleCloseInvoice}
                referenceType={"QuickScan"}
                referenceId={invoiceId}
            />
        </React.Fragment>
    );
};

export default QuickScan;
