import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",

        ".subForm &": {
            position: "static",
        },

        "&.--show-overlay": {
            /** Show an overlay over the whole drawer, except the subForm itself: **/
            "&::before": {
                content: "''",
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                zIndex: 999,
                backgroundColor: "rgba(0, 0, 0, 0.5)",

                /** If the form is in a drawer, let the overlay only overlap the drawer: **/
                ".MuiDrawer-root &": {
                    width: "var(--width)",
                    minWidth: "var(--minWidth)",
                    maxWidth: "var(--maxWidth)",
                },

                /** A sub-subForm overlay should only overlap the parent subForm: **/
                ".subForm &": {
                    position: "absolute",
                    width: "auto",
                    minWidth: 0,
                    maxWidth: "none",
                    left: 0,
                    borderRadius: 4,
                    top: -20,
                },
            },

            "& .labelledOutline": {
                zIndex: 1000,
                borderRadius: "0 0 4px 4px",

                "&::before": {
                    content: "''",
                    position: "absolute",
                    top: -20,
                    right: 0,
                    left: 0,
                    zIndex: 1,
                    bottom: "calc(100% - 1px)",
                    borderRadius: "4px 4px 0 0",
                    border: "1px solid rgba(0,0,0,0.15)",
                    borderBottomWidth: 0,
                    backgroundColor: theme.palette.background.lightestGray,
                },
            },
        },

        "& .autoCompleteSelect, & .drillDownInput": {
            "--background-color": theme.palette.background.lightestGray + " !important",
        },
    },

    labelledOutline: {
        backgroundColor: theme.palette.background.lightestGray,
    },

    formContent: {
        zIndex: 1000,
    },

    buttons: {
        margin: theme.spacing(2, 0, 1),
    },

    submitButton: {
        marginRight: theme.spacing(2),
    },

    summaryButton: {
        width: "100%",
        margin: theme.spacing(2, 0),

        "&:focus": {
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            backgroundColor: "#d5d5d5",
        }
    },

    summaryContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        textTransform: "none",
        textAlign: "left",
    },

    summaryContentWrapper: {
        marginRight: theme.spacing(1),
    },

    summaryLabel: {
        color: "inherit",
        fontWeight: 500,
    },

    summaryText: {
        color: "inherit",
    },

    summaryEditIcon: {
        marginLeft: "auto",
        padding: 4,

        "& svg": {
            fontSize: 20,
        },
    },
}));

export default useStyles;
