import palette from "../palette";

export default {
    root: {
        ".MuiTableBody-root &": {
            height: 61,
        },

        "&$selected": {
            backgroundColor: palette.background.default,
        },
        "&$hover": {
            "&:hover": {
                backgroundColor: palette.background.default,
            },
        },
    },
};
