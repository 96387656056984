import React from "react";
import useAPI from "../../../shared/useapi";
import { activate } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, onClose, open, companyId, companyName }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(activate);

    const handleDeactivate = async () => {
        if (await invoke(companyId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleDeactivate}
            handleClose={onClose}
            headerText={getResource("Security.companies.unArchive.header", "Un-archive company?")}
            buttonText={getResource("Security.companies.unArchive.okButton", "Un-archive")}
        >
            {getResource(
                "Security.companies.unArchive.message",
                "Company '{companyName}' will be un-archived. Are you sure?",
                { companyName: companyName }
            )}
        </Confirmation>
    );
});
