import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useStyles from "./useStyles";
import Item from "./item";
import Header from "./header";
import DrillDownInputSearch from "./drilldowninputsearch";
import Loader from "../drilldown/loader";
import Path from "../path";
import {
    ArrowDropDown as ArrowDropDownIcon,
    Clear as ClearIcon,
    Close as CloseIcon,
} from "@material-ui/icons";
import handleToggle from "../drilldown/utilities/handleToggle";
import handleLoad from "../drilldown/utilities/handleLoad";
import getTreeDepth from "../drilldown/utilities/getTreeDepth";
import getNewTreeFromItemId from "../drilldown/utilities/getNewTreeFromItemId";
import withResources from "../textresources/withresources";
import { FormHelperText, IconButton, Paper, Popper } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const DrillDownInput = withResources(
    ({
        getResource,
        name,
        itemName,
        label,
        errors,
        multiple,
        isMedium,
        fullWidth,
        backgroundColor,
        getChildren,
        getParents,
        getPath,
        filter,
        value,
        setValue,
        required,
        disabled,
        disableClearable = false,
        autoFocus = false,
        ...props
    }) => {
        const classes = useStyles();
        const isBrowserEnvironment = typeof window !== "undefined";
        const searchContainerHeight = 80;
        const error = errors && name in errors;
        const helperText = errors && errors[name];

        const drillDownInputEl = useRef(null);
        const clearButton = useRef(null);
        const chipsWrapper = useRef(null);
        const labelId = useRef("id_" + Math.random().toString(36).substr(2, 9));
        const popperToggleRef = useRef(null);

        const [isOpen, setIsOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [itemTree, setItemTree] = useState({});
        const [popperWidth, setPopperWidth] = useState(300);
        const [treeDepth, setTreeDepth] = useState(1);
        const [currentlyVisibleItemId, setCurrentlyVisibleItemId] = useState(0);
        const [drillDownHeight, setDrillDownHeight] = useState(200);
        const [valueArray, setValueArray] = useState([]);
        const [valuePaths, setValuePaths] = useState([]);

        useEffect(() => {
            /** Always make an array of the value, even if it's just an object (this makes the other logic simpler): **/
            setValueArray(multiple ? value : [value]);
        }, [value, multiple]);

        useEffect(() => {
            const createNewValuePaths = async () => {
                let newValuePaths = [];

                for (let i = 0; i < valueArray.length; i++) {
                    let value = valueArray[i];
                    if (!value || !value.id || !value.name) {
                        continue;
                    }

                    let path = await getPath(value.id);

                    let jsonPath = null;
                    if (path.status == 403) {
                        jsonPath = { parentNames: [value.name] };
                    } else {
                        jsonPath = await path.json()
                    }

                    jsonPath.id = value.id;
                    jsonPath.name = value.name;
                    newValuePaths.push(jsonPath);
                }

                setValuePaths(newValuePaths);
            };

            createNewValuePaths();
        }, [valueArray, getPath]);

        useEffect(() => {
            if (isBrowserEnvironment) {
                const closeOnEscape = (e) => (e.key === "Escape" ? setIsOpen(false) : null);
                document.addEventListener("keydown", closeOnEscape, {capture: true});
                return () => document.removeEventListener("keydown", closeOnEscape, {capture: true});
            }
        }, [isBrowserEnvironment]);

        const handleClose = () => {
            setIsOpen(false);
            if (popperToggleRef.current) {
                popperToggleRef.current.focus();
            }
        };

        const getDrillDownHeight = () => {
            const minHeight = 140;
            const maxHeight = 500;
            const availableSpace =
                window.innerHeight - drillDownInputEl.current.getBoundingClientRect().top;
            const availableSpaceForDrillDown = availableSpace - searchContainerHeight - 100;
            return availableSpaceForDrillDown < minHeight
                ? minHeight
                : availableSpaceForDrillDown > maxHeight
                ? maxHeight
                : availableSpaceForDrillDown;
        };

        const setPopperDimensions = () => {
            if (isBrowserEnvironment) {
                setDrillDownHeight(getDrillDownHeight());
                const drillDownInputWidth = drillDownInputEl.current.offsetWidth;
                setPopperWidth(drillDownInputWidth > 300 ? drillDownInputWidth : 300);
            }
        };

        const setTheValue = (newValue) => {
            setValue({
                target: {
                    name,
                    value: newValue,
                },
            });
        };

        const handleSelectItem = (item) => {
            let newValue;
            if (multiple) {
                newValue = [...value];
                newValue.push(item);
            } else {
                newValue = item;
            }

            setTheValue(newValue);

            if (multiple) {
                setPopperDimensions();
            } else {
                handleClose();
            }
        };

        const handleDeselectItem = (item) => {
            const newValue = multiple
                ? value.filter((valueItem) => JSON.stringify(valueItem) !== JSON.stringify(item))
                : { id: null, name: null };

            setTheValue(newValue);

            if (multiple) {
                setPopperDimensions();
            } else {
                handleClose();
            }
        };

        const handlePopperOpen = async (e) => {
            if (disabled) { return;}

            const isClearButtonClicked = clearButton.current
                ? e.target.classList.contains("clearButton") ||
                  clearButton.current.contains(e.target)
                : false;

            if (isClearButtonClicked) {
                return;
            }

            setPopperDimensions();

            if (!multiple && value && value.id) {
                await showNewTreeFromItemId(value.id);
            } else {
                await showNewTreeFromRoot();
            }

            setIsOpen(true);
        };

        const showNewTreeFromRoot = async () => {
            await handleLoad(setItemTree, setIsLoading, getChildren);
            setCurrentlyVisibleItemId(0);
            setTreeDepth(1);
        };

        const showNewTreeFromItemId = async (itemId) => {
            setIsLoading(true);
            const newItemTree = await getNewTreeFromItemId(itemId, getParents, getChildren);
            setCurrentlyVisibleItemId(itemId);
            setTreeDepth(getTreeDepth(newItemTree));
            setItemTree(newItemTree);
            setIsLoading(false);
        };

        const openItem = async (e, clickedItem) => {
            await setIsLoading(true);
            await handleToggle(clickedItem, itemTree, setItemTree, getChildren);
            await setIsLoading(false);
            setTreeDepth(treeDepth + 1);
            setCurrentlyVisibleItemId(clickedItem.id);
        };

        const closeItem = async (e, itemToClose, parentOfItemToClose) => {
            await handleToggle(itemToClose, itemTree, setItemTree, getChildren);
            setTreeDepth(treeDepth - 1);
            setCurrentlyVisibleItemId(parentOfItemToClose.id);
        };

        const handleSearchOptionChange = async (itemId) => {
            if (!itemId) {
                await showNewTreeFromRoot();
            } else {
                await showNewTreeFromItemId(itemId);
            }
        };

        return (
            <React.Fragment>
                <div
                    className={clsx(
                        props.className,
                        classes.drillDownInputContainer,
                        isMedium && "--medium",
                        fullWidth && "--fullwidth"
                    )}
                >
                    <Paper
                        className={clsx(
                            classes.drillDownInput,
                            "drillDownInput",
                            isMedium && "--medium",
                            error && "--error",
                            disabled && "--disabled"
                        )}
                        ref={drillDownInputEl}
                        onClick={handlePopperOpen}
                        style={{
                            "--background-color": backgroundColor ? backgroundColor : "white",
                        }}
                    >
                        {label && (
                            <label
                                className={clsx(classes.label, error && "--error")}
                                id={labelId.current}
                            >
                                {label}
                                {required && (
                                    <span aria-hidden="true" className="MuiInputLabel-asterisk">
                                        {" "}
                                        *
                                    </span>
                                )}
                            </label>
                        )}

                        <div
                            aria-labelledby={labelId.current}
                            ref={chipsWrapper}
                            className={classes.chipsWrapper}
                        >
                            {valuePaths.map((path) => {
                                const dontShowChipDelete =
                                    !multiple || (disableClearable && valueArray.length === 1);
                                return path.parentNames && path.parentNames.length ? (
                                    <Chip
                                        key={path.parentNames}
                                        label={<Path path={path.parentNames} keepOnOneLine />}
                                        size={isMedium ? "medium" : "small"}
                                        disabled={disabled}
                                        className={clsx(
                                            classes.chip,
                                            !multiple && "--show-as-text"
                                        )}
                                        onDelete={
                                            dontShowChipDelete
                                                ? null
                                                : () =>
                                                      handleDeselectItem({
                                                          id: path.id,
                                                          name: path.name,
                                                      })
                                        }
                                        style={{
                                            maxWidth: chipsWrapper.current
                                                ? chipsWrapper.current.offsetWidth + "px"
                                                : "none",
                                        }}
                                    />
                                ) : null;
                            })}
                        </div>

                        {!multiple && !disableClearable && value && value.id && (
                            <IconButton
                                aria-label={getResource(
                                    "DrillDownInput.clearSelectionLabel",
                                    "Clear selection"
                                )}
                                disabled={disabled}
                                className={classes.clearIconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeselectItem();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        )}

                        <IconButton
                            autoFocus={autoFocus}
                            aria-label={getResource(
                                "DrillDownInput.openPopperLabel",
                                "Open the Select popup"
                            )}
                            size="small"
                            name={name}
                            disabled={disabled}
                            className={classes.popperToggle}
                            ref={popperToggleRef}
                        >
                            <ArrowDropDownIcon />
                        </IconButton>
                    </Paper>
                </div>

                {helperText && error && (
                    <FormHelperText className={classes.error} variant="outlined" error={error}>
                        {helperText}
                    </FormHelperText>
                )}

                {isOpen && (
                    <div className={classes.pageBlocker} onClick={handleClose}>
                        &nbsp;
                    </div>
                )}

                <Popper
                    open={isOpen}
                    anchorEl={drillDownInputEl.current}
                    className={classes.popper}
                    placement="bottom-end"
                    disablePortal
                    modifiers={{ flip: { enabled: false } }}
                    style={{
                        width: popperWidth + "px",
                    }}
                >
                    <div className={classes.searchContainer}>
                        <DrillDownInputSearch
                            handleSearchOptionChange={handleSearchOptionChange}
                            itemName={itemName}
                            filter={filter}
                        />

                        <IconButton
                            className={clsx(classes.closeButton, "e2e_closeDrilldownButton")}
                            size="small"
                            aria-label="Cancel"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>

                    {itemTree.children ? (
                        <div
                            className={clsx(classes.ulWrapper, "drilldown", "--root")}
                            style={{ height: drillDownHeight + "px" }}
                        >
                            <ul
                                className="childItems"
                                style={{ transform: `translateX(-${(treeDepth - 1) * 100}%)` }}
                            >
                                <React.Fragment>
                                    <Header getPath={getPath} />
                                    {itemTree.children.map((item) => (
                                        <Item
                                            {...props}
                                            item={item}
                                            itemName={itemName}
                                            valueArray={valueArray}
                                            parentOfItem={itemTree}
                                            openItem={openItem}
                                            closeItem={closeItem}
                                            handleSelectItem={handleSelectItem}
                                            handleDeselectItem={handleDeselectItem}
                                            getPath={getPath}
                                            currentlyVisibleItemId={currentlyVisibleItemId}
                                            disableClearable={disableClearable}
                                            key={item.id}
                                        />
                                    ))}
                                </React.Fragment>
                            </ul>
                            <Loader isLoading={isLoading} positionAbsolute />
                        </div>
                    ) : null}
                </Popper>
            </React.Fragment>
        );
    }
);

DrillDownInput.propTypes = {
    name: PropTypes.string.isRequired,
    itemName: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    errors: PropTypes.object,
    isMedium: PropTypes.bool,
    fullWidth: PropTypes.bool,
    backgroundColor: PropTypes.string,
    getChildren: PropTypes.func.isRequired,
    getParents: PropTypes.func.isRequired,
    getPath: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    setValue: PropTypes.func.isRequired,
    disableClearable: PropTypes.bool,
};

export default DrillDownInput;
