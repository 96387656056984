import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    scheduledEventSwitchWrapper: {
        margin: `${theme.spacing(2)}px 0`,

        "&.--more-margin-bottom": {
            marginBottom: theme.spacing(4),
        },
    },

    scheduleHelperText: {
        margin: 0,
    },

    firstRow: {
        display: "block",
    },

    secondRow: {
        fontSize: ".9em",
        color: theme.typography.body2.color,

        "& span": {
            "& + span": {
                paddingLeft: theme.spacing(1),

                "&:before": {
                    content: "'|'",
                    paddingRight: theme.spacing(1),
                },
            },
        },
    },
}));

export default useStyles;
