import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    summaryContent: {
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",

        "&, &.Mui-expanded": {
            margin: 0,
        },

        "& > *": {
            marginBottom: 0,
        },
    },

    summaryDescription: {
        marginRight: "auto",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: 0,
    },

    summaryDeleteButtonWrapper: {
        width: 48,
    },

    details: {
        width: "100%",
    },

    detailsUploadContainer: {
        position: "relative",
        margin: theme.spacing(2, 0),

        "& .uploadComponent": {
            margin: 0,
            padding: 0,
        },

        "& .dropZone": {
            margin: 0,
        },
    },
}));

export default useStyles;
