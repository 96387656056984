import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: theme.typography.body2.fontSize,
        margin: 0,
        padding: 5,
        width: 30,
        textAlign: "center",
        height: "auto",
        lineHeight: 1.4,
        "-moz-appearance": "textfield",

        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
        },
    },
}));

export default useStyles;
