import React from "react";
import { Slot } from "../../../shared/slotmachine/index";
import ContentHolder from "../../../shared/contentHolder";
import WidgetsOverview from "../../../shared/widgetsOverview";

export default (props) => {
    return (
        <ContentHolder>
            <Slot name="component-header" list={true} {...props} />
            <WidgetsOverview
                mainWidgetSlots={[
                    <Slot name="component-media" list={true} {...props} />,
                    <Slot name="component-documents" list={true} {...props} />,
                    <Slot name="component-parties" list={true} {...props} />,
                    <Slot name="component-usage" list={true} {...props} />,
                    <Slot name="component-facts" list={true} {...props} />,
                ]}
                sidebarWidgetSlots={[
                    <Slot name="component-summary" list={true} {...props} />,
                    <Slot name="component-asset-summary" list={true} {...props} />,
                ]}
            />
        </ContentHolder>
    );
};
