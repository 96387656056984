import React, { useEffect, useState } from "react";
import { filter, getChildren, getParents } from "./dataservice";
import Add from "./add";
import Edit from "./edit";
import Move from "./move";
import Merge from "./merge";
import Delete from "./delete";
import DrillDown from "../../../shared/drilldown";
import PrivateRoute from "../../../shared/authentication/privateroute";
import handleToggle from "../../../shared/drilldown/utilities/handleToggle";
import handleUpdateChildren from "../../../shared/drilldown/utilities/handleUpdateChildren";
import handleLoad from "../../../shared/drilldown/utilities/handleLoad";
import handleSearchPress from "../../../shared/drilldown/utilities/search/handleSearchPress";
import handleSearchOptionClick from "../../../shared/drilldown/utilities/search/handleSearchOptionClick";
import Search from "../../../shared/drilldown/search";
import Loader from "../../../shared/drilldown/loader";
import getNewTreeFromItemId from "../../../shared/drilldown/utilities/getNewTreeFromItemId";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
    OpenWith as MoveIcon,
    MergeType as MergeIcon,
} from "@material-ui/icons";
import withResources from "../../../shared/textresources/withresources";

import useDialog from "../../../shared/usedialog";

export default withResources(({ getResource }) => {
    const [itemTree, setItemTree] = useState({});
    const [autoCompleteResults, setAutoCompleteResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        await handleLoad(setItemTree, setIsLoading, getChildren);
    };

    const {
        open: handleAdd,
        close: handleCloseAdd,
        visible: addToggled,
        args: addParentId,
    } = useDialog((parentId) => handleUpdateChildItems(parentId));
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editArgs,
    } = useDialog((args) => handleUpdateChildItems(args.parentId, args.id));
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog((args) => handleUpdateChildItems(args.parentId));
    const {
        open: handleMove,
        close: handleCloseMove,
        visible: moveToggled,
        args: moveArgs,
    } = useDialog();
    const {
        open: handleMerge,
        close: handleCloseMerge,
        visible: mergeToggled,
        args: mergeArgs,
    } = useDialog();

    const handleUpdateChildItems = async (parentId, itemId = null) => {
        setIsLoading(true);
        await handleUpdateChildren(parentId, itemTree, setItemTree, getChildren, itemId);
        setIsLoading(false);
    };

    const handleToggleItem = async (clickedItem) => {
        setIsLoading(true);
        await handleToggle(clickedItem, itemTree, setItemTree, getChildren);
        setIsLoading(false);
    };

    const handleSearchKeyPress = async (searchText) => {
        await handleSearchPress(searchText, setAutoCompleteResults, filter);
    };

    const handleSearchOptionChange = async (itemId) => {
        if (!itemId) {
            await loadData();
            return;
        }

        setIsLoading(true);
        await handleSearchOptionClick(itemId, setItemTree, getParents, getChildren);
        setIsLoading(false);
    };

    const getActions = (availableWidth, item, itemId, parentId, version) => {
        return [
            {
                text: getResource("Library.assetCategories.overview.addButton", "Create"),
                icon: <AddIcon />,
                itemId: itemId,
                requiredOperation: "asset-categories-create",
                onClick: () => handleAdd(itemId),
            },
            {
                text: getResource("Library.assetCategories.overview.editButton", "Edit"),
                icon: <EditIcon />,
                itemId: itemId,
                requiredOperation: "asset-categories-update",
                onClick: () =>
                    handleEdit({
                        id: itemId,
                        parentId: parentId,
                    }),
            },
            {
                text: getResource("Library.assetCategories.overview.moveButton", "Move"),
                icon: <MoveIcon />,
                itemId: itemId,
                requiredOperation: "asset-categories-update",
                onClick: () =>
                    handleMove({
                        id: itemId,
                        parentId: parentId,
                    }),
            },
            {
                text: getResource("Library.assetCategories.overview.mergeButton", "Merge"),
                icon: <MergeIcon />,
                itemId: itemId,
                requiredOperation: "asset-categories-merge",
                onClick: () =>
                    handleMerge({
                        id: itemId,
                        parentId: parentId,
                        version: version,
                        name: item.name,
                    }),
            },
            {
                text: getResource("Library.assetCategories.overview.deleteButton", "Delete"),
                icon: <DeleteIcon />,
                itemId: itemId,
                requiredOperation: "asset-categories-delete",
                onClick: () =>
                    handleDelete({
                        id: itemId,
                        parentId: parentId,
                        version: version,
                    }),
            },
        ];
    };

    return (
        <ContentHolder>
            <PrivateRoute
                requiredOperation="asset-categories-view"
                path="/library/asset-categories"
                render={() => {
                    return (
                        <React.Fragment>
                            <Header
                                title={getResource(
                                    "Library.assetCategories.header.title",
                                    "Categories"
                                )}
                            />

                            <Search
                                itemName="category"
                                autoCompleteResults={autoCompleteResults}
                                handleSearchKeyPress={handleSearchKeyPress}
                                handleSearchOptionChange={handleSearchOptionChange}
                            />

                            <DrillDown
                                getActions={getActions}
                                itemTree={itemTree}
                                handleToggleItem={handleToggleItem}
                                handleClickAddButton={handleAdd}
                                addButtonRequiredOperation="asset-categories-create"
                                addLabel={getResource(
                                    "Library.assetCategories.overview.addRootButton",
                                    "Create"
                                )}
                            />

                            <Loader isLoading={isLoading} />
                        </React.Fragment>
                    );
                }}
            />

            <Add open={addToggled} parentId={addParentId} onClose={handleCloseAdd} />

            <Edit open={editToggled} onClose={handleCloseEdit} id={editArgs && editArgs.id} />

            <Delete
                open={deleteToggled}
                onClose={handleCloseDelete}
                onDelete={() => handleUpdateChildItems(deleteArgs.parentId)}
                itemId={deleteArgs && deleteArgs.id}
                version={deleteArgs && deleteArgs.version}
            />

            <Move
                open={moveToggled}
                id={moveArgs && moveArgs.id}
                onClose={handleCloseMove}
                onMove={async (newParentId) => {
                    const newItemTree = await getNewTreeFromItemId(
                        newParentId,
                        getParents,
                        getChildren
                    );
                    setItemTree(newItemTree);
                }}
            />

            <Merge
                open={mergeToggled}
                id={mergeArgs && mergeArgs.id}
                version={mergeArgs && mergeArgs.version}
                name={mergeArgs && mergeArgs.name}
                onClose={handleCloseMerge}
                onMerge={() => handleUpdateChildItems(mergeArgs.parentId)}
            />
        </ContentHolder>
    );
});
