import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Confirmation from "./confirmation";
import { Prompt } from "react-router-dom";
import withResources from "./textresources/withresources";

export const PreventNavigationDialog = withResources(
    ({ key, when, getResource, header, message, discardButtonText }) => {
        const trigger = Symbol.for(key);
        const [allowTransitionCallback, setAllowTransitionCallback] = useState(null);
        const [open, setOpen] = useState(false);

        useEffect(() => {
            window[trigger] = show;
            return () => {
                delete window[trigger];
            };
        }, [trigger]);

        useEffect(() => {
            if (allowTransitionCallback) {
                setOpen(true);
            }
        }, [allowTransitionCallback]);

        const handleTransition = (answer) => {
            allowTransitionCallback(answer);
            setOpen(false);
        };

        const show = (callback) => {
            setOpen(true);
            setAllowTransitionCallback(() => (answer) => callback(answer));
        };

        return (
            <React.Fragment>
                <Prompt when={when} message={Symbol.keyFor(trigger)} />
                <Confirmation
                    open={open}
                    handleAction={() => handleTransition(true)}
                    handleClose={() => handleTransition(false)}
                    headerText={header}
                    buttonText={discardButtonText}
                    getResource={getResource}
                >
                    {message}
                </Confirmation>
            </React.Fragment>
        );
    }
);

PreventNavigationDialog.propTypes = {
    key: PropTypes.string,
    when: PropTypes.bool,
    getResource: PropTypes.string,
    header: PropTypes.string,
    message: PropTypes.string,
    discardButtonText: PropTypes.string,
};

export default (dialogKey, callback) => {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        // delegate to dialog and pass callback through
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};
