/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Link } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import { login } from "./dataservice";
import useAPI from "../../../shared/useapi";
import useForm from "../../../shared/forms/useform";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";

import Forgot from "../passwordReset/forgot";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    fields: {
        margin: theme.spacing(-1),
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    forgotLink: {
        cursor: "pointer",
    },

    noCookiesOverlay: {
        position: "absolute",
        width: "80%",
        top: "50%",
        left: "10%",
        transform: "translateY(-50%)",
        zIndex: 10,

        "& .MuiAlert-standardError": {
            border: "2px solid #e53935",
            borderRadius: theme.spacing(1),
        },
    },
}));

const LoginForm = withResources(
    ({
        getResource,
        className,
        initialize, // to prevent error when passing props to Form
        ...rest
    }) => {
        const classes = useStyles();
        const history = useHistory();
        const location = useLocation();
        const { refresh } = useContext(AuthenticationContext);
        const { setCompanyScope, setAssetCollectionScope } = useContext(WorkingScopeContext);
        const [forgotOpen, setForgotOpen] = useState(false);
        const formId = useRef("id_" + Math.random().toString(36).substr(2, 9));

        const { loading, invoke } = useAPI(login);

        const onSubmit = async ({ values }) => {
            // Login the user
            if (await invoke(values.email, values.password)) {
                // Clear working scope
                setCompanyScope({ id: null, name: null });
                setAssetCollectionScope({ id: null, name: null });

                // Refresh authentication context
                await refresh();

                // Redirect back to requested page
                let { from } = location.state || { from: { pathname: "/assets/assets" } };
                const fromQueryString = new URLSearchParams(location.search).get("from");
                if (fromQueryString) {
                    from = fromQueryString;
                }
                history.replace(from);
            }
        };

        const onValidate = (values) => {
            const errors = {};

            if (!values.email) {
                errors.email = getResource("Security.login.enterEmail", "Please enter a e-mail");
            }

            if (!values.password) {
                errors.password = getResource(
                    "Security.login.enterPassword",
                    "Please enter a password"
                );
            }

            return errors;
        };

        const { values, handleChange, handleSubmit, errors } = useForm({
            initialValues: {},
            onSubmit: onSubmit,
            formId: formId.current,
            validate: onValidate,
        });

        //https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
        var cookiesEnabled = (function () {
            try {
                // Create cookie
                document.cookie = "cookietest=1; SameSite=Strict";
                var ret = document.cookie.indexOf("cookietest=") !== -1;
                // Delete cookie
                document.cookie = "cookietest=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT";
                return ret;
            } catch (e) {
                return false;
            }
        })();

        return (
            <React.Fragment>
                <form
                    {...rest}
                    id={formId.current}
                    className={clsx(classes.root, className)}
                    onSubmit={handleSubmit}
                >
                    <div className={classes.fields}>
                        <ResourcedTextField
                            fullWidth
                            errors={errors}
                            autoFocus
                            label="Email address"
                            labelResourceKey="Security.login.emailField"
                            labelDefaultValue="E-mail"
                            name="email"
                            type="email"
                            onChange={handleChange}
                            value={values.email || ""}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            disabled={!cookiesEnabled}
                        />
                        <ResourcedTextField
                            fullWidth
                            errors={errors}
                            labelResourceKey="Security.login.passwordField"
                            labelDefaultValue="Password"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={values.password || ""}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            disabled={!cookiesEnabled}
                        />
                        {cookiesEnabled && (
                            <Link
                                className={classes.forgotLink}
                                onClick={() => setForgotOpen(true)}
                                color="secondary"
                                underline="always"
                                variant="subtitle2"
                            >
                                <ResourcedText
                                    resourceKey="Security.login.forgotPassword"
                                    defaultValue="Forgot password?"
                                />
                            </Link>
                        )}
                    </div>
                    <Button
                        className={classes.submitButton}
                        disabled={loading || !cookiesEnabled}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        <ResourcedText
                            resourceKey="Security.login.signInButton"
                            defaultValue="Sign in"
                        />
                    </Button>

                    {!cookiesEnabled && (
                        <div className={classes.noCookiesOverlay}>
                            <Alert severity="error">
                                <AlertTitle>
                                    <ResourcedText
                                        resourceKey="Security.login.noCookiesHeader"
                                        defaultValue="No cookies allowed"
                                    />
                                </AlertTitle>
                                <ResourcedText
                                    resourceKey="Security.login.noCookiesText"
                                    defaultValue="This website needs cookies to work, but your browser does not support this. <br />Please adjust your settings to allow cookies."
                                />
                            </Alert>
                        </div>
                    )}
                </form>

                {forgotOpen && (
                    <Forgot handleClose={() => setForgotOpen(false)} email={values.email} />
                )}
            </React.Fragment>
        );
    }
);

LoginForm.propTypes = {
    className: PropTypes.string,
};

export default LoginForm;
