import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "&.--size-small input": {
            paddingTop: 10.5,
            paddingBottom: 10.5,
        },

        "&.--disabled-no-currency": {
            "& .MuiFormHelperText-root": {
                color: theme.palette.text.secondary,
            },
        },
    },
}));

export default useStyles;
