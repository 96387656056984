import React, { Fragment, useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import { MergeType as MergeIcon } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";
import useAPI from "../../../shared/useapi";
import { get, approve } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./components/editForm";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";

export default withResources(({ getResource, open, id, onClose, onMerge }) => {
    if (!open) {
        return null;
    }

    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => approve(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        validate(values, errors, getResource);

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.manufacturers.edit.title", "Proposed manufacturer")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource(
                        "Library.manufacturers.edit.approveButton",
                        "Approve"
                    )}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => (
                        <Fragment>
                            <Alert severity="info">
                                <AlertTitle>
                                    {getResource(
                                        "Library.manufacturers.proposedText",
                                        "Proposed by {suggestedBy} on {suggestedOn}",
                                        {
                                            ...data,
                                            suggestedOn: formatDate(
                                                data.suggestedOn,
                                                selectedUiCulture,
                                                selectedTimezone,
                                                false,
                                                true,
                                                false,
                                                false
                                            ),
                                        }
                                    )}
                                </AlertTitle>
                                {getResource(
                                    "Library.manufacturers.reviewText",
                                    "Approving this manufacturer will make the proposed manufacturer available to all users. Alternatively, you can choose to merge the proposed manufacturer into an existing manufacturer."
                                )}
                            </Alert>
                            <EditForm errors={errors} values={values} handleChange={handleChange} />
                        </Fragment>
                    )}
                    renderFooter={() => (
                        <AuthorizedButton
                            onClick={() => {
                                onClose(false);
                                onMerge({
                                    id: data.id,
                                    version: data.version,
                                    name: data.manufacturerName + " " + data.name,
                                });
                            }}
                            requiredOperation="dependency-manufacturers-merge"
                            buttonText={getResource(
                                "Library.manufacturers.review.mergeButton",
                                "Merge"
                            )}
                            startIcon={<MergeIcon />}
                        />
                    )}
                />
            )}
        </FormDrawer>
    );
});
