import React, { useContext, useState } from "react";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.missingLanguages);
    }

    const { cultures } = useContext(LocalizationContext);

    const filterCultures =
        cultures &&
        cultures
            .filter((culture) => culture.default !== true)
            .map((culture) => ({ id: culture.code, name: culture.description }));

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Library.assetTypes.search.searchLabel"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Library.assetTypes.search.missingTranslationsLabel",
                        "Missing translation(s)"
                    )}
                    multiple
                    value={searchValues.missingLanguages}
                    options={filterCultures}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, missingLanguages: e.target.value })
                    }
                />,
            ]}
        />
    );
});

export default Search;
