import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Valuation.questionnaire.delete.confirmationHeader",
                "Delete category questionnaire?"
            )}
            buttonText={getResource("Valuation.questionnaire.delete.confirmationButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Valuation.questionnaire.delete.confirmationText"
                defaultValue="The category questionnaire will be deleted"
            />
        </Confirmation>
    );
});
