import interceptedFetch from "../../../../../shared/interceptedFetch";

async function handleErrors(response) {
    if (!response.ok) {
        throw Error(await response.text());
    }
    return response;
}

class CalculationService {
    generatePeriods(args, success, error) {
        interceptedFetch("/api/valuation/calculation/generateperiods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(args.data)),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
}

export default CalculationService;
