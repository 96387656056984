import React, { useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import useAPI from "../../../shared/useapi";
import { getAll as getOperations } from "./../operations/dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.name, values.operation);
    }

    const { data: operations } = useAPI(() => getOperations(null, false, null, null), true);

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Security.roles.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.name || ""}
                    onChange={(e) => handleValuesChanged({ ...searchValues, name: e.target.value })}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource("Security.roles.search.operationField", "Permissions")}
                    isSearchBox
                    options={operations ? operations.items : []}
                    value={searchValues.operation}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, operation: e.target.value })
                    }
                />,
            ]}
        />
    );
});

export default Search;
