import React, { useContext } from "react";
import withResources from "../../../shared/textresources/withresources";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import DocumentContext from "../../document/documents/documentcontext";
import { makeStyles } from "@material-ui/styles";
import Header from "../../../shared/header";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
    },
}));

export default withResources(({ getResource, library, reference, onAdd }) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const { getRequiredOperation } = useContext(DocumentContext);
    const classes = useStyles();

    var componentName =
        reference &&
        reference.name &&
        (reference.name[selectedCulture] || reference.name[defaultCulture]);

    return (
        <Header
            className={classes.header}
            title={getResource(
                "Assets.components.documents.header.title",
                "Documents for {componentName}",
                { componentName }
            )}
            renderActions={() => {
                return (
                    <AuthorizedButton
                        color="primary"
                        variant="contained"
                        onClick={onAdd}
                        requiredOperation={getRequiredOperation(library, `create`, reference)}
                        buttonText={getResource("Document.documents.header.addButton", "Add")}
                    />
                );
            }}
        />
    );
});
