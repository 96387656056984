const setChildrenOnItem = async (item, getChildren) => {
    const children = await getChildren(item.id === 0 ? null : item.id);
    const jsonChildren = await children.json();

    if (jsonChildren.length > 0) {
        item.children = jsonChildren;
    } else {
        delete item.children;
    }
};

export default setChildrenOnItem;
