import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LoadingSkelleton from "./loadingSkelleton";
import useStyles from "./useStyles";

const WidgetsOverview = ({ mainWidgetSlots = [], sidebarWidgetSlots = [] }) => {
    const classes = useStyles();

    return (
        <div className={classes.overview}>
            <div className={classes.sidebarContainer}>
                {sidebarWidgetSlots.map((slot) => (
                    <Fragment key={slot.props.name}>{slot}</Fragment>
                ))}
            </div>
            <div className={classes.widgetsContainer}>
                {mainWidgetSlots.map((slot) => (
                    <Fragment key={slot.props.name}>{slot}</Fragment>
                ))}
                <LoadingSkelleton nrOfWidgets={mainWidgetSlots.length} />
            </div>
        </div>
    );
};

WidgetsOverview.propTypes = {
    mainWidgetSlots: PropTypes.array,
    sidebarWidgetSlots: PropTypes.array,
};

export default WidgetsOverview;
