import React from "react";
import { Mail as MessagesIcon, ShoppingCart as MarketplaceIcon } from "@material-ui/icons";
import { Section } from "../shell";
import withResources from "../../shared/textresources/withresources";
import Configuration from "./configuration";
import MarketplacePages from "./marketplacepages";
import Advertisements from "./advertisements";
import CacheReset from "./cachereset";
import Messages from "./messages";
import Auctions from "./auctions";
// import Monitor from "./auctions/monitor/monitor";
import { registerIcon } from "../shell/components/topbar/components/notifications";

registerIcon("unreadMessages", MessagesIcon);

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Marketplace.configurationTitle", "Configuration"),
            href: "/marketplace/configuration",
            render: Configuration,
            requiredOperation: "marketplace-configure",
        },
        {
            title: getResource("Marketplace.marketplacePagesTitle", "Marketplace pages"),
            href: "/marketplace/marketplacepages",
            render: MarketplacePages,
            requiredOperation: "marketplace-pages-view",
        },
        {
            title: getResource("Marketplace.advertisementsTitle", "Advertisements"),
            href: "/marketplace/advertisements",
            render: Advertisements,
            requiredOperation: "advertisements-view",
        },
        {
            title: getResource("Marketplace.auctionTitle", "Auctions"),
            href: "/marketplace/auctions",
            render: Auctions,
            requiredOperation: "auctions-view",
        },
        // Temporary menu item for easier front-end development, can be removed after the monitor is implemented according to WAMP-887
        // {
        //     title: getResource("Marketplace.auctionMonitorTitle", "Auction Monitor"),
        //     href: "/marketplace/auctionmonitor",
        //     render: Monitor,
        //     requiredOperation: "auctions-monitor",
        // },
        {
            title: getResource("Marketplace.messagesTitle", "Message inbox"),
            href: "/marketplace/messages",
            render: Messages,
            requiredOperation: "messages-view",
        },
        {
            title: getResource("Marketplace.cacheResetTitle", "Cache reset"),
            href: "/marketplace/cache-reset",
            render: CacheReset,
            requiredOperation: "marketplace-cache-reset",
        },
    ];

    return (
        <Section
            key="Marketplace"
            title={getResource("Marketplace.title", "Marketplace")}
            icon={MarketplaceIcon}
            href="/marketplace"
            children={children}
        />
    );
});
