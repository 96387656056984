import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, roleId, scope, page, pageSize) {
    const params = {
        searchText,
        roleId,
        scope,
        page,
        pageSize,
    };

    const url = `/api/security/permissions?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getPossiblePermissions(scope) {
    const url = `/api/security/permissions/possible/${scope}`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/security/permissions", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function revoke(selectedId) {
    return await interceptedFetch(`/api/security/permissions/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, getPossiblePermissions, add, revoke };
