import React, { useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import { filter, getChildren, getParents, getPath } from "./../assetcategories/dataservice";
import PageListSearch from "../../../shared/pagelistsearch";
import DrillDownInput from "../../../shared/drilldowninput";
import SearchIcon from "@material-ui/icons/Search";

import useAPI from "../../../shared/useapi";
import { getAll as getAllManufacturers } from "./../manufacturers/dataservice";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    const { data: allManufacturers } = useAPI(() => getAllManufacturers(null, null, null), true);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.name, values.manufacturerId, values.assetCategory);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Library.assetModels.search.searchLabel"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.name || ""}
                    onChange={(e) => handleValuesChanged({ ...searchValues, name: e.target.value })}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Library.assetModels.search.manufacturerLabel",
                        "Manufacturer"
                    )}
                    isSearchBox
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, manufacturerId: e.target.value })
                    }
                />,
                <DrillDownInput
                    name="assetCategorySearch"
                    itemName={getResource(
                        "Library.assetModels.search.assetCategoryItemName",
                        "category"
                    )}
                    label={getResource("Library.assetModels.search.assetCategoryLabel", "Category")}
                    getParents={getParents}
                    getChildren={getChildren}
                    getPath={getPath}
                    filter={filter}
                    value={searchValues.assetCategory}
                    setValue={(e) => {
                        handleValuesChanged({
                            ...searchValues,
                            assetCategory: e.target.value,
                        });
                    }}
                />,
            ]}
        />
    );
});

export default Search;
