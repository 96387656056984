import React, { useContext, useEffect } from "react";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Overview from "./overview";
import Delete from "./delete";
import ValuationCorrection from "./valuationCorrection";
import Roles from "./roles";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-collection", {
        page: 0,
        pageSize: defaultPageSize,
        name: null,
        missingLanguages: [],
        hasValuationCorrection: null,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.name,
                searchParams.missingLanguages,
                searchParams.hasValuationCorrection,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.name,
            searchParams.missingLanguages,
            searchParams.hasValuationCorrection,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);
    const {
        open: handleEditRoleAssignments,
        close: handleCloseEditRoleAssignments,
        visible: editRoleAssignmentsToggled,
        args: editRoleAssignmentId,
    } = useDialog(invoke);
    const {
        open: handleEditValuationCorrection,
        close: handleCloseEditValuationCorrection,
        visible: editValuationCorrectionToggled,
        args: editValuationCorrectionId,
    } = useDialog(invoke);

    const handleSearch = (name, missingLanguages, hasValuationCorrection) => {
        setSearchParams({
            ...searchParams,
            name,
            missingLanguages,
            hasValuationCorrection,
            page: 0,
        });
    };

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    return (
        <PrivateRoute
            requiredOperation={"asset-collections-view"}
            path="/assets/asset-collections"
            render={(props) => {
                return (
                    <React.Fragment>
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            showValuationCorrection={searchParams.hasValuationCorrection === "true"}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onSearch={handleSearch}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onEditRoleAssignments={handleEditRoleAssignments}
                            onEditValuationCorrection={handleEditValuationCorrection}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                        <Add open={addToggled} onClose={handleCloseAdd} />
                        <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
                        <Delete id={deleteId} open={deleteToggled} onClose={handleCloseDelete} />
                        <Roles
                            id={editRoleAssignmentId}
                            open={editRoleAssignmentsToggled}
                            onClose={handleCloseEditRoleAssignments}
                        />
                        <ValuationCorrection
                            id={editValuationCorrectionId}
                            open={editValuationCorrectionToggled}
                            onClose={handleCloseEditValuationCorrection}
                        />
                    </React.Fragment>
                );
            }}
        />
    );
};
