import React from "react";
import useAuthorization from "./useauthorization";

import { Button } from "@material-ui/core";

const AuthorizedButton = ({
    requiredOperation,
    requiredOperationAtLeastOneOf,
    buttonText,
    children,
    ...other
}) => {
    const { isAuthorized, isLoading } = useAuthorization(
        requiredOperation,
        requiredOperationAtLeastOneOf
    );

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return (
        <Button {...other}>
            {buttonText}
            {children}
        </Button>
    );
};

export default AuthorizedButton;
