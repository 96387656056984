import React, { useState } from "react";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import ButtonFilter from "../../../shared/buttonFilter";

const Search = withResources(({ getResource, factTypes, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    const handleValuesChanged = (values) => {
        setSearchValues(values);
        onSearch(values.searchText, values.factType);
    };

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Asset.facts.search.searchField"
                    labelDefaultValue="Keyword search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    AdornmentIcon={<SearchIcon />}
                    clearable
                    className="e2e_pagedListGenericSearchField"
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                />,
                <ButtonFilter
                    options={factTypes}
                    value={searchValues.factType}
                    name="factType"
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            factType: e.target.value,
                        })
                    }
                />,
            ]}
        />
    );
});

export default Search;
