import React, { useState } from "react";
import { Button, Drawer, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Titled } from "react-titled";
import clsx from "clsx";
import Confirmation from "../confirmation";
import withResources from "../textresources/withresources";
import ResourcedText from "../textresources/resourcedtext";
import useStyles from "./useStyles";
import useRefCallback from "./useRefCallback";

export default withResources(
    ({
        getResource,
        title,
        onClose,
        children,
        isDirty,
        onCloseWhenDirty,
        initialize, // Prevent initialize to be passed down, since it causes warnings
        size = "small",
        ...rest
    }) => {
        const [confirmationVisible, setConfirmationVisible] = useState(false);
        const [drawerRef] = useRefCallback();
        const classes = useStyles();

        function handleClose() {
            if (isDirty) {
                if (onCloseWhenDirty) {
                    onCloseWhenDirty();
                } else {
                    setConfirmationVisible(true);
                }
            } else {
                onClose();
            }
        }

        return (
            <Drawer
                {...rest}
                disableEscapeKeyDown
                anchor="right"
                open
                ref={drawerRef}
                onClose={handleClose}
                className={clsx(
                    classes.drawer,
                    size === "large" && "--large",
                    size === "medium" && "--medium"
                )}
            >
                <Titled title={(pageTitle) => `${pageTitle} - ${title}`} />
                <div className={classes.header}>
                    <Typography component="h1" variant="h4">
                        {title}
                    </Typography>
                    <Button className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon className={classes.closeButtonIcon} />
                        {getResource("Shell.form.close.buttonText", "Close")}
                    </Button>
                </div>
                <div className={classes.content}>{children}</div>
                <Confirmation
                    open={confirmationVisible}
                    handleAction={onClose}
                    handleClose={() => setConfirmationVisible(false)}
                    headerText={getResource("Shell.form.confirmation.title", "Are you sure?")}
                    buttonText={getResource("Shell.form.confirmation.buttonText", "Discard")}
                >
                    <ResourcedText
                        resourceKey="Shell.form.confirmation.text"
                        defaultValue="Any information entered will be discarded"
                    />
                </Confirmation>
            </Drawer>
        );
    }
);
