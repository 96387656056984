import React, { useState, useContext } from "react";
import useAPI from "../../../shared/useapi";
import { clearAllCacheItems } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Confirmation from "../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";

export default withResources(({ getResource }) => {
    const {
        cacheApiKey,
        cacheApiPath,
        marketplace: { homeUrl },
    } = useContext(ConfigurationContext);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const { loading, invoke } = useAPI(() =>
        clearAllCacheItems(homeUrl + cacheApiPath, cacheApiKey)
    );

    const onSubmit = async (values) => {
        if (await invoke()) {
            setConfirmationOpen(true);
        }
    };

    return (
        <ContentHolder>
            <Header
                title={getResource(
                    "Marketplace.resetCache.header.title",
                    "Reset marketplace cache"
                )}
            />
            <LocalizedForm
                onIsDirty={() => null}
                loading={loading}
                okButtonText={getResource("Marketplace.resetCache.okButton", "Send reset request")}
                onSubmit={onSubmit}
                onValidate={() => null}
                renderForm={() => null}
                buttonNotSticky
            />

            <Confirmation
                open={confirmationOpen}
                cancelHidden
                handleAction={() => setConfirmationOpen(false)}
                headerText={getResource(
                    "Marketplace.resetCache.confirmation.header",
                    "Request sent"
                )}
                buttonText={getResource("Marketplace.resetCache.confirmation.buttonText", "Ok")}
                loading={loading}
            >
                {getResource(
                    "Marketplace.configuration.resetCache.content",
                    "The request to reset the marketplace cache has been sent."
                )}
            </Confirmation>
        </ContentHolder>
    );
});
