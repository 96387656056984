import React, { Fragment, useContext } from "react";
import { LibraryBooksOutlined as FactIcon } from "@material-ui/icons";
import WidgetItem from "../widgetItem";
import Widget from "../../../../shared/widget";
import withResources from "../../../../shared/textresources/withresources";
import { formatDate } from "../../../../shared/formattedDate";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import { config as factConfig } from "../config";

const FactsWidget = withResources((props) => {
    const { getResource, headerLink, loading, data, handleAdd, entity } = props;

    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    return (
        <Widget
            headerTitle={getResource("Asset.fact.widget.title", "Facts")}
            headerLink={headerLink}
            headerLinkTitle={getResource("Asset.fact.widget.linkTitle", "See all facts")}
            loading={loading}
            hasContent={data && data.items.length > 0}
            icon={<FactIcon />}
            noDataText={getResource("Asset.fact.widget.noDataText", "No facts available")}
            addButtonText={getResource("Asset.fact.widget.addFact", "Add")}
            noContentSpacing
            onAddButtonClick={handleAdd}
            addButtonRequiredOperationAtLeastOneOf={
                factConfig.operationTypes.map((type) => ({
                    name: `facts-create-${type}`,
                    companyId: entity.companyId,
                    assetCollectionId: entity.assetCollectionIds,
                }))}
            renderContent={() => (
                <Fragment>
                    {data &&
                        data.items.map((row, i) => {
                            return (
                                <WidgetItem
                                    key={i}
                                    factType={getResource(
                                        `Assets.fact.factType.${row.factType.toLowerCase()}`,
                                        row.factType
                                    )}
                                    title={row.summary}
                                    actions={row.actions}
                                    authorAndDate={getResource(
                                        "Assets.fact.overview.createdValue",
                                        "by {createdBy} on {created} ",
                                        {
                                            ...row,
                                            created: formatDate(
                                                row.created,
                                                selectedUiCulture,
                                                selectedTimezone,
                                                true
                                            ),
                                        }
                                    )}
                                    requiredOperation={{
                                        name: `facts-view-${row.factType.toLowerCase()}`,
                                        companyId: entity.companyId,
                                        assetCollectionId: entity.assetCollectionIds,
                                    }}
                                />
                            );
                        })}
                </Fragment>
            )}
        />
    );
});

export default FactsWidget;
