import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import useAPI from "../../shared/useapi";
import { getWhereUserHasOperation as getCompanies } from "./companies/dataservice";
import { getWhereUserHasOperation as getAssetCollections } from "../assets/assetCollection/dataservice";
import AutoCompleteStatic from "../../shared/autocompleteStatic";
import WorkingScopeContext from "../shell/components/workingScope/workingscopecontext";
import withResources from "../../shared/textresources/withresources";
import clsx from "clsx";

const backgroundColor = "#3b41a1";
const borderColor = "#7d81bf";
const noOptionsTextColor = "rgba(255, 255, 255, 0.65)";
const noOptionsBorderColor = "#686ca9";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 94,
        boxShadow: "none",
        backgroundColor: backgroundColor,
        padding: "10px 20px",

        "& h1": {
            color: "#fff",
        },
        "& h2": {
            color: "#ddd",
        },
    },
    filterPart: {
        display: "inline-block",
        backgroundColor: backgroundColor,
        marginRight: theme.spacing(1),
        marginTop: 15,

        "& .autoCompleteSelect": {
            borderColor: borderColor,

            "&.--no-options": {
                borderColor: noOptionsBorderColor,

                "& .autoCompleteLabel": {
                    color: noOptionsTextColor,
                },

                "& .autoCompleteToggle": {
                    color: noOptionsBorderColor,
                },

                "& .autoCompleteNoOptionsText": {
                    color: noOptionsTextColor,
                },
            },
        },

        "& .autoCompleteLabel, & .autoCompleteToggle": {
            color: "white",
        },

        "& .autoCompleteChip": {
            color: "white",
        },

        "& .autoCompleteClearButton": {
            color: "#b6b9db",

            "&:hover, &:focus": {
                color: "white",
            },
        },
    },
}));

const WorkingContext = withResources(
    ({ getResource, requiredOperation, requiredOperations, hideAssetCollections }) => {
        const operations = requiredOperation ? [requiredOperation] : requiredOperations;
        const classes = useStyles();
        const {
            companyScope,
            setCompanyScope,
            assetCollectionScope,
            setAssetCollectionScope,
        } = useContext(WorkingScopeContext);

        const { data: companies } = useAPI(() => getCompanies(operations), true);
        const { data: assetCollections, invoke: loadAssetCollections } = useAPI(
            () => getAssetCollections(operations),
            false
        );

        useEffect(() => {
            if (companyScope.id) {
                loadAssetCollections();
            }
        }, [companyScope]); // eslint-disable-line

        var activeCompany = companyScope;

        //Reset the company to a company you have permissions on
        if (
            companies &&
            activeCompany &&
            activeCompany.id &&
            !companies.find((x) => x.id === activeCompany.id)
        ) {
            activeCompany = { id: null, name: null };
            setCompanyScope(activeCompany);
        }

        return (
            <div className={clsx(classes.root, "workingContext-root")}>
                {companies && (
                    <div className={classes.filterPart}>
                        <AutoCompleteStatic
                            backgroundColor="#3b41a1"
                            label={getResource("Security.workingContext.companyField", "Company")}
                            name="companyScope"
                            value={activeCompany.id}
                            options={companies}
                            noOptionsText={getResource(
                                "Security.workingContext.companyFieldEmpty",
                                "No company"
                            )}
                            onSelection={(e) => {
                                if (!e.target.value) {
                                    setCompanyScope({ id: null, name: null });
                                } else {
                                    var company = companies.find((x) => x.id === e.target.value);
                                    setCompanyScope({ id: company.id, name: company.name });
                                }
                            }}
                        />
                    </div>
                )}
                {!hideAssetCollections && companyScope.id && assetCollections && (
                    <div className={classes.filterPart}>
                        <AutoCompleteStatic
                            backgroundColor="#3b41a1"
                            label={getResource(
                                "Security.workingContext.assetCollectionField",
                                "Collection"
                            )}
                            value={assetCollectionScope.id}
                            options={assetCollections}
                            noOptionsText={getResource(
                                "Security.workingContext.assetCollectionFieldEmpty",
                                "No collections"
                            )}
                            noOptionSelectedText={getResource(
                                "Security.workingContext.assetCollectionFieldNoOptionSelected",
                                "all"
                            )}
                            onSelection={(e) => {
                                if (!e.target.value) {
                                    setAssetCollectionScope({ id: null, name: null });
                                } else {
                                    var assetCollection = assetCollections.find(
                                        (x) => x.id === e.target.value
                                    );
                                    setAssetCollectionScope({
                                        id: assetCollection.id,
                                        name: assetCollection.name,
                                    });
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
);

WorkingContext.propTypes = {
    requiredOperation: PropTypes.string,
    requiredOperations: PropTypes.array,
    hideAssetCollections: PropTypes.bool,
};

export default WorkingContext;
