import React from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import RoutedIndex from "./routedIndex";

export default () => {
    return (
        <PrivateRoute
            requiredOperation={"work-orders-view"}
            path="/maintenance/work-orders"
            exact
            render={(props) => <RoutedIndex assetId={null} componentId={null} {...props} />}
        />
    );
};
