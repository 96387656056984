import React, { Component } from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { TableChart as TableIcon, Print as PrintIcon } from "@material-ui/icons";

import ReactDataGrid from "react-data-grid";
import DateHelper from "../helpers/datehelper.js";
import $ from "jquery";
import withResources from "../../../../../shared/textresources/withresources";

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTouchDevice: false,
            activeIndex: -1,
            columns: [
                {
                    key: "by",
                    name: props.getResource("Valuation.dataTable.buildYearHeader", "Build year"),
                    sortable: true,
                },
                {
                    key: "fairMarketValue",
                    name: props.getResource("Valuation.dataTable.fairMarketValueHeader", "FMV"),
                    sortable: true,
                    editable: true,
                },
                {
                    key: "liquidationValue",
                    name: props.getResource("Valuation.dataTable.liquidationValueHeader", "LV"),
                    sortable: true,
                    editable: true,
                },
                {
                    key: "combined",
                    name: props.getResource("Valuation.dataTable.combinedHeader", "FMV - LV"),
                    sortable: true,
                },
            ],
            originalRows: this.createRows(),
            rows: this.createRows().slice(0),
        };
    }

    componentWillReceiveProps() {
        if (this.props.data.isTouchDevice && !this.state.isTouchDevice) {
            this.setState({ isTouchDevice: true });
        }

        this.setState({ rows: this.createRows() });
    }

    getRandomDate(start, end) {
        return new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime())
        ).toLocaleDateString();
    }

    createRows() {
        const data = this.props.data;
        let rows = [];
        let dh = new DateHelper();

        for (let i = 0; i < data.series[0].data.length; i++) {
            let fairMarketValue = data.series[0].data[i];
            let liquidationValue = data.series[1].data[i];

            if (typeof fairMarketValue === "object")
                // could be an object, should be fixed somewhere... not sure where
                fairMarketValue = fairMarketValue.y;

            if (typeof liquidationValue === "object")
                // could be an object, should be fixed somewhere... not sure where
                liquidationValue = liquidationValue.y;

            rows.push({
                by: dh.getBuildYear(i),
                fairMarketValue: fairMarketValue,
                liquidationValue: liquidationValue,
                combined: Number(fairMarketValue) - Number(liquidationValue),
            });
        }

        return rows;
    }

    handleGridSort(sortColumn, sortDirection) {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };

        const rows =
            sortDirection === "NONE"
                ? this.state.originalRows.slice(0)
                : this.state.rows.sort(comparer);

        this.setState({ rows });
    }

    handleGridRowsUpdated(e, d) {
        let seriesIndex = e.cellKey === "fairMarketValue" ? 0 : 1;
        let value = seriesIndex === 0 ? e.updated.fairMarketValue : e.updated.liquidationValue;

        if (isNaN(Number(value))) return;

        let fromRow = e.fromRow;
        let toRow = e.toRow;
        let updated = e.updated;
        let rows = d.state.rows.slice();
        let chart = this.props.highchartsRef.refs.highcharts.getChart();

        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = rows[i];

            rowToUpdate.fairMarketValue = this.updateValue(
                rowToUpdate.fairMarketValue,
                updated.fairMarketValue
            );
            rowToUpdate.liquidationValue = this.updateValue(
                rowToUpdate.liquidationValue,
                updated.liquidationValue
            );
            rowToUpdate.combined = rowToUpdate.fairMarketValue - rowToUpdate.liquidationValue;

            rows[i] = rowToUpdate;

            // update the point in the chart serie
            chart.series[seriesIndex].data[i].update(
                {
                    x: i,
                    y: Number(value),
                    high: undefined,
                    low: undefined,
                },
                false
            );
        }

        // now redraw the whole serie
        this.updateChartSeries(chart.series[seriesIndex]);

        d.setState({ rows });
    }

    updateChartSeries(series) {
        let kdTree;
        kdTree = series.kdTree;
        series.redraw();
        series.kdTree = kdTree;
    }

    updateValue(currentVal, updatedVal) {
        let val = Number(updatedVal);

        return !isNaN(val) ? val : Number(currentVal);
    }

    rowGetter(i) {
        return this.state.rows[i];
    }

    handleAccordionClick = (e) => {
        e.preventDefault();
        const state = this.state;
        state.activeIndex = state.activeIndex === 0 ? -1 : 0;

        this.setState(state);
    };

    handleChartContextClick = (e) => {
        e.preventDefault();

        // close the datatable if open
        const state = this.state;
        if (state.activeIndex === 0) {
            state.activeIndex = -1;
            this.setState(state);
        }

        $(".highcharts-button").click();
    };

    onCellSelected = ({ rowIdx, idx }) => {
        if (this.state.isTouchDevice) this.refs.table.openCellEditor(rowIdx, idx);
    };

    render() {
        return (
            <div id="DataGrid" style={{ width: 335 + "px" }}>
                <ButtonGroup variant="contained" fullWidth color="secondary">
                    <Button onClick={this.handleAccordionClick}>
                        <TableIcon fontSize="small" />
                    </Button>
                    <Button onClick={this.handleChartContextClick}>
                        <PrintIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
                {this.state.activeIndex === 0 && (
                    <ReactDataGrid
                        ref="table"
                        onGridSort={(a, b) => this.handleGridSort(a, b)}
                        columns={this.state.columns}
                        rowGetter={(i) => this.rowGetter(i)}
                        rowsCount={this.state.rows.length}
                        minHeight={205}
                        enableCellSelect={true}
                        onCellSelected={this.onCellSelected}
                        onGridRowsUpdated={(a) => this.handleGridRowsUpdated(a, this)}
                    />
                )}
            </div>
        );
    }
}

export default withResources(DataTable);
