import React, { useContext } from "react";
import {
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
} from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { removeAssetCollection } from "./dataservice";

import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useForm from "../../../shared/forms/useform";

export default withResources(({ getResource, selectedAssets, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { assetCollectionScope } = useContext(WorkingScopeContext);

    const { loading, invoke } = useAPI(removeAssetCollection);

    const handleRemove = async (input) => {
        if (await invoke(input.values)) {
            onClose(true);
        }
    };

    const { values, handleChange, handleSubmit } = useForm({
        initialValues: { assets: selectedAssets, trackMovement: true },
        onSubmit: handleRemove,
    });

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {getResource("Asset.asset.removeAssetCollection.header", "Remove from collection?")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getResource(
                        "Asset.asset.removeAssetCollection.body",
                        "Are you sure you want to remove these assets from the {currentAssetCollectionName} collection?",
                        { currentAssetCollectionName: assetCollectionScope.name }
                    )}
                </DialogContentText>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(values.trackMovement)}
                            onChange={(e) => handleChange(e)}
                            name="trackMovement"
                        />
                    }
                    label={getResource(
                        "Asset.asset.removeAssetCollection.trackMovementField",
                        "Track movement"
                    )}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)} autoFocus>
                    {getResource("Asset.asset.removeAssetCollection.cancelButtonText", "Cancel")}
                </Button>
                <Button disabled={loading} onClick={handleSubmit} color="primary">
                    {getResource("Asset.asset.removeAssetCollection.okButton", "Remove")}
                    {loading && <CircularProgress />}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
