import { makeStyles } from "@material-ui/styles";
import variables from "./variables";

const {
    ulPadding,
    itemWidth,
    itemHeight,
    itemMargin,
    ulWrapperAnimationMs,
    paperBoxShadow,
} = variables;

const useStyles = makeStyles((theme) => ({
    ulWrapper: {
        display: "inline-block",

        "&.--root": {
            position: "relative",
        },

        "& .childItems": {
            margin: 0,
            padding: `0 ${ulPadding}px ${ulPadding}px`,
            listStyle: "none",
            width: itemWidth,
            height: "100%",
        },

        "& .ulWrapper": {
            position: "absolute",
            top: 0,
            bottom: 0,
            marginLeft: `calc(100% - ${2 * ulPadding + 1}px)`,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            cursor: "auto",
            width: 0,
            overflow: "hidden",
            boxShadow: "none",
            animation: `$show-ul-wrapper ${theme.transitions.easing.easeOut} forwards ${ulWrapperAnimationMs}ms`,

            "& .childItems": {
                transform: "translateX(-100%)",
                animation: `$show-ul ${theme.transitions.easing.easeOut} forwards ${ulWrapperAnimationMs}ms`,
            },
        },
    },

    item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: itemHeight,
        margin: `${itemMargin}px 0`,
        padding: "5px 10px",
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",

        "&:hover": {
            transition: `0.1s background-color ${theme.transitions.easing.easeOut}`,
            backgroundColor: theme.palette.background.default,
        },

        "& > .name": {
            flexGrow: 1,
            lineHeight: 1.2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
        },

        "&.--inactive": {
            "& > .name": {
                opacity: 0.7,
            },

            "& > button": {
                opacity: 0.5,
            },
        },

        "& > .ulWrapper": {
            display: "none",
        },

        "&.--is-open": {
            transition: "none !important",
            backgroundColor: `${theme.palette.primary.main} !important`,

            /** To directly reserve space, so horizontal scrolling works well: **/
            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                width: itemWidth,
                marginLeft: `calc(100% - ${2 * ulPadding + 1}px)`,
            },

            "& > .name": {
                color: theme.palette.white,
            },

            "& > button": {
                color: theme.palette.white,
            },

            "& > .ulWrapper": {
                display: "block",
            },
        },

        "&.--is-highlighted": {
            transition: "none !important",
            backgroundColor: `${theme.palette.highlighted} !important`,
        },
    },

    "@keyframes show-ul-wrapper": {
        "0%": {
            width: 0,
            overflow: "hidden",
        },
        "10%": {
            boxShadow: paperBoxShadow,
        },
        "99%": {
            overflow: "hidden",
        },
        "100%": {
            width: itemWidth,
            overflow: "visible",
            boxShadow: paperBoxShadow,
        },
    },

    "@keyframes show-ul": {
        "0%": {
            transform: "translateX(-100%)",
        },
        "100%": {
            transform: "translateX(0)",
        },
    },
}));

export default useStyles;
