import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: "100%",
    },

    content: {
        padding: theme.spacing(8, 4, 3, 4),
        margin: "auto",
    },

    root: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(6, 2),
    },

    card: {
        width: "100%",
        overflow: "unset",
        display: "flex",
        position: "relative",

        [theme.breakpoints.up("lg")]: {
            width: theme.breakpoints.values.md,
        },

        "& > *": {
            flexGrow: 1,

            [theme.breakpoints.up("lg")]: {
                width: "50%",
            },
        },
    },

    media: {
        display: "none",

        [theme.breakpoints.up("lg")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            padding: theme.spacing(3),
            color: theme.palette.white,
        },
    },

    icon: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        position: "absolute",
        top: -32,
        left: theme.spacing(3),
        height: 64,
        width: 64,
        fontSize: 32,
    },

    loginForm: {
        marginTop: theme.spacing(3),
    },

    divider: {
        margin: theme.spacing(2, 0),
    },
}));

export default useStyles;
