import React from "react";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
}));

export default ({ message, onClose }) => {
    const classes = useStyles();
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            open={message !== null}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <SnackbarContent
                aria-describedby="client-snackbar"
                className={classes.error}
                message={
                    <span id="client-snackbar">
                        <ErrorIcon />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};
