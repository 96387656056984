import palette from "../palette";

export default {
    contained: {
        boxShadow:
            "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
        backgroundColor: palette.white,
    },

    containedPrimary: {
        border: "1px solid transparent",

        "&:hover": {
            backgroundColor: palette.primary.darkest,
        },
    },

    containedSecondary: {
        color: palette.text.primary,
        backgroundColor: "#e0e0e0",

        "&, &:hover": {
            boxShadow:
                "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },

        "&:hover": {
            backgroundColor: "#d5d5d5",
        },
    },
};
