import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import TableWidget from "../../../../shared/tableWidget";
import FormattedCurrency from "../../../../shared/formattedCurrency";

export default withResources(({ getResource, loading, values, currencyShortName }) => {
    const tableHeaders = [
        getResource("Valuation.financialReports.report.netOutstandingTable.header.year", "Year"),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.bookValue",
            "Book value"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.liquidationValue",
            "Liquidation value"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.liquidationCost",
            "Liquidation cost"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.reserve",
            "Reserve"
        ),
    ];

    const tableData =
        values &&
        values.map((document) => ({
            year: document.year,
            bookValue: (
                <FormattedCurrency
                    value={document.bookValue}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            liquidationValue: (
                <FormattedCurrency
                    value={document.liquidationValue}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            liquidationCost: (
                <FormattedCurrency
                    value={document.liquidationCost}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            reserve: (
                <FormattedCurrency
                    value={document.reserve}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
        }));

    return (
        <TableWidget
            headerTitle={getResource(
                "Valuation.financialReports.report.bookValueTable.title",
                "Book value"
            )}
            headers={tableHeaders}
            data={tableData}
            loading={loading}
            hasContent={tableData !== null && tableData.length > 0}
            caption={getResource(
                "Valuation.financialReports.report.bookValueTable.caption",
                "The above table starts on the purchase date of the asset"
            )}
        />
    );
});
