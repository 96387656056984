import React from "react";
import FormattedDate from "../../../../../shared/formattedDate";
import withResources from "../../../../../shared/textresources/withresources";
import useStyles from "./useStyles";
import { Typography } from "@material-ui/core";

const TaskFooter = withResources((props) => {
    const { getResource, data } = props;

    const classes = useStyles();

    return (
        <div className={classes.taskFooter}>
            <div className={classes.taskFooterComments}>
                <Typography variant="h4" component="h3" className={classes.heading}>
                    {getResource("Maintenance.workOrderReport.tableFooter.Comments", "Comments")}
                </Typography>
                {data.jobSheetComments ? data.jobSheetComments : "-"}
            </div>

            {data.status === "Completed" && (
                <div className={classes.taskFooterSignOff}>
                    <Typography variant="h4" component="h3" className={classes.heading}>
                        {getResource("Maintenance.workOrderReport.tableFooter.signOff", "Sign off")}
                    </Typography>
                    <dl>
                        <div>
                            <dt>
                                {getResource(
                                    "Maintenance.workOrderReport.tableFooter.completedBy",
                                    "Completed by"
                                )}
                            </dt>
                            <dd>{data.jobSheetCompletedBy}</dd>
                        </div>
                        <div>
                            <dt>
                                {getResource(
                                    "Maintenance.workOrderReport.tableFooter.completedOn",
                                    "Completed on"
                                )}
                            </dt>
                            <dd>
                                <FormattedDate date={data.lastUpdateDate} long includeTime />
                            </dd>
                        </div>
                    </dl>
                </div>
            )}
        </div>
    );
});

export default TaskFooter;
