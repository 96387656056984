import React, { useState, useEffect, useContext } from "react";
import { Button, LinearProgress } from "@material-ui/core";
import {
    ShoppingCart as PurchaseButton,
    NavigateBefore as PreviousButton,
    NavigateNext as NextButton,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import { get as getAssetModel } from "../../library/assetmodels/dataservice";
import { getQuestionsForAssetModel as getQuestions } from "../questionnaire/dataservice";
import { getPublishedForAssetModel as getValuation } from "../valuation/dataservice";

import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

import { purchase } from "./dataservice";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import Init from "./components/init";
import AnswerQuestionnaire from "../quickscan/components/questionnaire";
import FutureValues from "../quickscan/components/futureValues";
import calculateAge from "../quickscan/calculateAge";
import { isValuationOutdated } from "../quickscan/dataservice";
import RequestValuationDialog from "../quickscan/components/requestValuationDialog";
import {
    CloseConfirmation,
    ContinueConfirmation,
    revalidateQuestions,
} from "../assetquickscan/components/continueAbandoned";

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    mobileStepper: {
        backgroundColor: "inherit",
        "& .MuiMobileStepper-progress": {
            width: "100%",
        },
    },
    infoText: {
        marginBottom: theme.spacing(2),
    },
}));

export default withResources(({ getResource, open, onClose, history }) => {
    if (!open) {
        return null;
    }

    const { companyScope } = useContext(WorkingScopeContext);

    const classes = useStyles();

    const [quickScan, setQuickScan] = useState({
        purchaseForCompany: companyScope,
        assetModel: { id: null, name: null },
        constructionDate: null,
        purchaseFutureValues: false,
        futureMonths: null,
        answers: {},
    });
    const [isDirty, setIsDirty] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [showRequestValuationDialog, setShowRequestValuationDialog] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [showContinue, setShowContinue] = useState(true);
    const [validateQuestions, setValidateQuestions] = useState(false);

    const [previousQuickScan, setPreviousQuickScan] = useSessionStorage(
        "previous-model-quick-scan",
        null,
        true
    );

    const { loading: assetModelLoading, data: assetModel } = useAPI(
        () => getAssetModel(quickScan.assetModel.id),
        quickScan.assetModel.id != null,
        [quickScan.assetModel]
    );
    const { loading: valuationLoading, data: valuation } = useAPI(
        () => getValuation(quickScan.assetModel.id),
        quickScan.assetModel.id != null,
        [quickScan.assetModel]
    );
    const { loading: questionsLoading, data: questions } = useAPI(
        () => getQuestions(quickScan.assetModel.id),
        quickScan.assetModel.id != null,
        [quickScan.assetModel]
    );
    const { saving, invoke, data: quickScanId } = useAPI((values) => purchase(values));

    var loading = assetModelLoading || valuationLoading || questionsLoading;
    var assetName =
        (assetModel && `${assetModel.manufacturerName} / ${assetModel.name}`) ||
        getResource("Valuation.assetModelQuickScanList.header.model", "model");

    const goToNextStep = async () => {
        if (!getCurrentStep().beforeNextAction || (await getCurrentStep().beforeNextAction())) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        var copy = {
            ...quickScan,
            [name]: value,
        };

        setIsDirty(true);
        setQuickScan(copy);
    };

    const getSteps = () => {
        var init = {
            render: () => (
                <React.Fragment>
                    <Init quickScan={quickScan} onChange={handleChange} />
                    <div className={classes.infoText}>
                        {getResource(
                            "Valuation.quickScan.futureValues.infoText",
                            "In addition to purchasing a current value you can also purchase a future value. This future value will be included in the quick scan report."
                        )}
                    </div>
                    <FutureValues quickScan={quickScan} onChange={handleChange} />
                    {quickScan && (
                        <RequestValuationDialog
                            open={showRequestValuationDialog}
                            companyId={quickScan.purchaseForCompany.id}
                            assetModelId={quickScan.assetModel.id}
                            constructionDate={quickScan.constructionDate}
                            futureMonths={quickScan.futureMonths}
                            onValuationRequested={handleCancel}
                            onCancel={() => setShowRequestValuationDialog(false)}
                        >
                            <ResourcedText
                                resourceKey="Valuation.quickScan.requestValuationDialogAssetModel.text"
                                defaultValue="The valuation information for this model is missing or outdated. Would you like to submit a request to update this information?<br><br>NB please allow us 24 hours before you try again."
                            />
                        </RequestValuationDialog>
                    )}
                </React.Fragment>
            ),
            beforeNextAction: async () => {
                if (!valuation) {
                    setShowRequestValuationDialog(true);
                    return false;
                }
                var response = await isValuationOutdated(
                    valuation.id,
                    new Date(quickScan.constructionDate).toISOString(),
                    quickScan.futureMonths
                );
                var result = await response.json();
                if (result) {
                    setShowRequestValuationDialog(true);
                    return false;
                }
                return true;
            },
            infoText: getResource(
                "Valuation.quickScan.assetModelPurchase.header",
                "A model quick scan allows you to create a quick scan for an asset that is not registered in the system. To purchase the quick scan, select a model and enter the construction date of the asset"
            ),
            isNextButtonDisabled: () =>
                !quickScan.assetModel.id ||
                !quickScan.constructionDate ||
                (quickScan.purchaseFutureValues && !quickScan.futureMonths) ||
                !quickScan.purchaseForCompany.id,
        };

        const age = calculateAge(quickScan.constructionDate);
        const filteredQuestions = questions
            ? questions.filter(
                  (question) =>
                      !question.dependentOnAge ||
                      (question.minimalAge <= age && question.maximumAge >= age)
              )
            : [];

        var questionSteps = filteredQuestions.map((question, index) => ({
            infoText: getResource(
                "Valuation.quickScan.questions.infoText",
                "Answer all questions as accurately as possible. The answers supplied will affect the quickscan values."
            ),
            render: () => (
                <AnswerQuestionnaire
                    quickScan={quickScan}
                    question={question}
                    onChange={(answerId) => {
                        var answersCopy = {
                            ...quickScan.answers,
                            [question.id]: answerId,
                        };
                        handleChange({ target: { name: "answers", value: answersCopy } });
                    }}
                    onComplete={goToNextStep}
                />
            ),
            isNextButtonDisabled: () => {
                if (question.answers.length === 0) {
                    return false;
                }
                return !quickScan.answers[question.id];
            },
        }));

        var confirmPurchase = {
            infoText: getResource(
                "Valuation.quickScan.futureValues.infoText",
                "All questions have been answered. Would you like to include a future value as part of this quick scan?"
            ),
            render: () => <React.Fragment></React.Fragment>,
            handleSubmit: async () => {
                var copy = {
                    ...quickScan,
                    assetModelId: quickScan.assetModel.id,
                    purchaseForCompanyId: quickScan.purchaseForCompany.id,
                };

                await invoke(copy);
            },
        };

        return [init, ...questionSteps, confirmPurchase];
    };

    const getCurrentStep = () => {
        return getSteps()[currentStep];
    };

    const inLastStep = () => currentStep === getSteps().length - 1;

    const getCurrentStepControl = () => {
        return getCurrentStep().render();
    };

    const handleCancel = () => {
        onClose(false);
    };

    const handleSubmit = async () => {
        var handleSubmitCurrentStep = getCurrentStep().handleSubmit;
        handleSubmitCurrentStep && (await handleSubmitCurrentStep());
    };

    const isNextButtonDisabled = () => {
        return getCurrentStep().isNextButtonDisabled && getCurrentStep().isNextButtonDisabled();
    };

    if (showContinue && !previousQuickScan) {
        setShowContinue(false);
    }

    useEffect(() => {
        if (quickScanId == null) return;

        history.push(`/quick-scan/report/${quickScanId}`);
    }, [history, quickScanId]);

    useEffect(() => {
        if (validateQuestions) {
            var copy = revalidateQuestions(quickScan, questions);
            setQuickScan(copy);
            setValidateQuestions(false);
        }
    }, [quickScan, questions, validateQuestions]);

    return (
        <FormDrawer
            title={getResource(
                "Valuation.assetModelQuickScanList.header.subtitle",
                "Purchase quick scan for {assetName}",
                { assetName }
            )}
            onClose={handleCancel}
            isDirty={isDirty}
            onCloseWhenDirty={() => setConfirmationVisible(true)}
        >
            {loading ? (
                <LinearProgress />
            ) : (
                <div>
                    <div className={classes.infoText}>{getCurrentStep().infoText}</div>

                    {getCurrentStepControl()}

                    <div className={classes.content}>
                        {currentStep !== 0 && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={goToPreviousStep}
                                startIcon={<PreviousButton />}
                            >
                                {getResource("Valuation.quickScan.previousButton", "Previous")}
                            </Button>
                        )}
                        &nbsp;
                        {!inLastStep() && (
                            <Button
                                disabled={isNextButtonDisabled()}
                                color="primary"
                                variant="contained"
                                onClick={goToNextStep}
                                startIcon={<NextButton />}
                            >
                                {getResource("Valuation.quickScan.nextButton", "Next")}
                            </Button>
                        )}
                        &nbsp;
                        {inLastStep() && (
                            <Button
                                disabled={saving}
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                endIcon={<PurchaseButton />}
                            >
                                {getResource(
                                    "Valuation.assetModelQuickScanPurchase.confirmPurchaseButton",
                                    "Confirm purchase"
                                )}
                            </Button>
                        )}
                    </div>
                </div>
            )}

            <CloseConfirmation
                open={confirmationVisible}
                onCancel={() => setConfirmationVisible(false)}
                onDiscard={handleCancel}
                onContinueLater={() => {
                    setPreviousQuickScan(quickScan);
                    handleCancel();
                }}
            />

            <ContinueConfirmation
                open={showContinue}
                onNew={() => setShowContinue(false)}
                onContinue={() => {
                    setQuickScan(previousQuickScan);
                    setValidateQuestions(true);
                    setShowContinue(false);
                }}
            />
        </FormDrawer>
    );
});
