import React from "react";
import useAPI from "../../../shared/useapi";
import { get, getIsReferenced, del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { data } = useAPI(() => get(id), true);

    const { data: isReferenced } = useAPI(() => getIsReferenced(id), true);

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(id, data.version)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={getResource("Asset.assetCollection.delete.header", "Delete collection?")}
            buttonText={getResource("Asset.assetCollection.delete.okButton", "Delete")}
            buttonHidden={isReferenced}
        >
            {isReferenced && (
                <ResourcedText
                    resourceKey="Asset.assetCollection.delete.inUseMessage"
                    defaultValue="Collection already in use; first delete all items referencing this collection."
                />
            )}
            {!isReferenced && (
                <ResourcedText
                    resourceKey="Asset.assetCollection.delete.deleteMessage"
                    defaultValue="The collection and all its child collections will be deleted."
                />
            )}
        </Confirmation>
    );
});
