import React from "react";
import { LinearProgress, Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoComplete from "../../../shared/autocomplete";

import useAPI from "../../../shared/useapi";
import { getAll, getPath, move } from "./dataservice";

import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";

const useSearchStyles = makeStyles((theme) => ({
    optionHolder: {
        flexWrap: "wrap",
        width: "100%",
        borderBottom: "1px solid #eee",
    },
    small: {
        width: "100%",
        fontSize: ".7em",
        color: "#666",
    },
}));

export default withResources(({ getResource, onMove, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const classes = useSearchStyles();

    const { loading, invoke } = useAPI((values) =>
        move(id, values.values.moveToRoot ? 0 : values.values.company.id)
    );

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose();
            onMove(values.values.moveToRoot ? 0 : values.values.company.id);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.moveToRoot && !values.company) {
            errors.company = getResource(
                "Security.companies.move.selectDestinationCompany",
                "Please select a parent company to move to"
            );
        }

        return errors;
    };

    const { data: path } = useAPI(() => getPath(id), true, [id]);

    var isLoaded = path;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.companies.move.title", "Move company")}
            onClose={() => onClose()}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ moveToRoot: false }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.companies.move.okButton", "Move")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    name="parent"
                                    labelResourceKey="Security.companies.move.parentField"
                                    labelDefaultValue="Item"
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{ readOnly: true }}
                                    value={path.parentNames.join(" > ")}
                                />

                                <FormControlLabel
                                    label={getResource(
                                        "Security.companies.move.moveToRootField",
                                        "Move to root"
                                    )}
                                    variant="outlined"
                                    control={
                                        <Switch
                                            name="moveToRoot"
                                            checked={values.moveToRoot}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    }
                                />

                                {!values.moveToRoot && (
                                    <AutoComplete
                                        errors={errors}
                                        autoFocus
                                        name="company"
                                        label={getResource(
                                            "Security.companies.move.destinationCompanyField",
                                            "New parent company"
                                        )}
                                        value={values.company}
                                        onGetData={(searchText, page, pageSize) =>
                                            getAll(searchText, id, true, page, pageSize)
                                        }
                                        onSelection={(e) => handleChange(e)}
                                        renderOption={(option) => (
                                            <div className={classes.optionHolder}>
                                                <div>{option.name}</div>
                                                <i className={classes.small}>
                                                    {option.path
                                                        .map((item) => item.name)
                                                        .join(" > ")}
                                                </i>
                                            </div>
                                        )}
                                        required
                                    />
                                )}
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
