import React, { useCallback, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { Backup as BackupIcon } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import withResources from "./textresources/withresources";

const useStyles = makeStyles((theme) => ({
    dropZone: {
        borderStyle: "dashed",
        borderWidth: "2px",
        height: "200px",
        textAlign: "center",
        padding: `60px ${theme.spacing(1)}px`,
        marginBottom: theme.spacing(1),

        "&.--disabled": {
            color: "rgba(0, 0, 0, 0.38)",
        },

        "& .drop-file": {
            marginBottom: theme.spacing(1),
        },
    },
    error: {
        borderColor: "#ff1744",
    },
}));

const DropZone = withResources(
    ({ getResource, error, onFileAccepted, onFileRejected, acceptedFileExtensions, disabled }) => {
        const onDropAccepted = useCallback(
            (acceptedFiles) => {
                onFileAccepted(acceptedFiles[0]);
            },
            [onFileAccepted]
        );

        const onDropRejected = useCallback(
            (rejectedFiles) => {
                onFileRejected(rejectedFiles);
            },
            [onFileRejected]
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            accept: acceptedFileExtensions.join(", "),
            onDropAccepted,
            onDropRejected,
            multiple: false,
            disabled,
        });

        const classes = useStyles();

        const className = clsx(
            classes.dropZone,
            error && classes.error,
            disabled && "--disabled",
            "dropZone"
        );

        return (
            <div className={className} {...getRootProps()}>
                <input {...getInputProps()} />
                <BackupIcon fontSize="large" />
                {disabled ? (
                    <p>{getResource("Shell.dropZone.disabled", "File upload is disabled")}</p>
                ) : isDragActive ? (
                    <p>{getResource("Shell.dropZone.dropFile", "Drop the file here ...")}</p>
                ) : (
                    <Fragment>
                        <p className="drop-file">
                            {getResource("Shell.dropZone.dropOrSelectFile", "Drop a file here")}
                        </p>
                        <Button variant="contained" color="secondary" size="small">
                            {getResource("Shell.dropZone.selectFile", "Browse for a file")}
                        </Button>
                    </Fragment>
                )}
            </div>
        );
    }
);

export default DropZone;
