import React from "react";
import PropTypes from "prop-types";
import withResources from "../textresources/withresources";
import useStyles from "./useStyles";
import { Chip } from "@material-ui/core";

const ButtonFilter = withResources((props) => {
    const { getResource, options, onSelection, value, name, hideAllButton } = props;

    const classes = useStyles();

    const handleClick = (newValue) => {
        onSelection({ target: { name: name, value: newValue } });
    };

    return (
        <div className={classes.buttonFilter}>
            {!hideAllButton && <Chip
                label={getResource("ButtonFilter.allItems", "All types")}
                variant="outlined"
                onClick={() => handleClick(null)}
                className={!value && "active"}
                aria-label={getResource("ButtonFilter.label", "Type")}
            />}

            {options &&
                options.map((option) => {
                    var optionValue = typeof (option.value) === "undefined" ? option.name : option.value;
                    return (
                        <Chip
                            label={option.name}
                            key={option.name}
                            variant="outlined"
                            onClick={() => handleClick(optionValue)}
                            className={optionValue === value ? "active" : ""}
                            aria-label={getResource("ButtonFilter.label", "Type")}
                        />
                    );
                })}
        </div>
    );
});

ButtonFilter.propTypes = {
    getResource: PropTypes.string,
    options: PropTypes.array,
    onSelection: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
};

export default ButtonFilter;
