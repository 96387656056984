import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    publishedIcon: {
        color: theme.palette.completed.main,
    },

    unPublishedIcon: {
        color: theme.palette.error.main,
    },
}));

export default useStyles;
