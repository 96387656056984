import React from "react";
import { Fill } from "../../shared/slotmachine/index";

import AssetQuickScan from "./assetquickscan/widget";

export default () => {
    return [
        <Fill key="assetQuickScan" name="asset-quick-scan">
            <AssetQuickScan />
        </Fill>,
    ];
};
