import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, CardMedia, Typography, Divider, Link } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LoginForm from "./loginform";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useStyles from "./useStyles";

const Login = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.main}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <LockIcon className={classes.icon} />
                        <Typography gutterBottom variant="h3">
                            <ResourcedText
                                resourceKey="Security.login.title"
                                defaultValue="Sign in"
                            />
                        </Typography>
                        <Typography variant="subtitle2">
                            <ResourcedText
                                resourceKey="Security.login.subtitle"
                                defaultValue="Sign in on the internal platform"
                            />
                        </Typography>
                        <LoginForm className={classes.loginForm} />
                        <Divider className={classes.divider} />
                        <ResourcedText
                            resourceKey="Security.login.noAccountText"
                            defaultValue="Don't have an account? "
                        />
                        <Link
                            align="center"
                            color="secondary"
                            component={RouterLink}
                            to="/apply"
                            underline="always"
                        >
                            <ResourcedText
                                resourceKey="Security.login.noAccountButton"
                                defaultValue="Register"
                            />
                        </Link>
                    </CardContent>
                    <CardMedia
                        className={classes.media}
                        image={`/images/${Math.ceil(Math.random() * 6)}.jpg`}
                        title="Cover"
                    ></CardMedia>
                </Card>
            </div>
        </main>
    );
};

export default Login;
