import React, { useContext } from "react";
import _ from "underscore";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Visibility as ViewIcon,
    Edit as EditIcon,
    GetApp as DownloadIcon,
    Delete as DeleteIcon,
    Archive,
    Unarchive,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Slot, Fill, withFillInfo } from "../../../shared/slotmachine/index";
import Header from "../../../shared/header";
import ContentHolder from "../../../shared/contentHolder";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import ActionMenu from "../../../shared/actionmenu";
import Search from "./search";
import DocumentContext from "./documentcontext";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
    },
}));

export const DocumentHeader = ({ library, children, onAdd }) => (
    <Fill name={`document-header-${library}`} onAdd={onAdd}>
        {children}
    </Fill>
);

export const DocumentSummary = ({ library, children }) => (
    <Fill name={`document-summary-${library}`}>{children}</Fill>
);

const Documents = withFillInfo(
    withResources(
        ({
            getResource,
            hasFills,
            data,
            searchParams,
            availablePageSizes,
            onPageChange,
            onPageSizeChange,
            onSearch,
            onAdd,
            onEdit,
            onDelete,
            onArchive,
            onUnArchive,
            loading,
            location,
            reference,
            defaultDebounceInterval,
            match: {
                params: { library },
            },
        }) => {
            const referenceId = new URLSearchParams(location.search).get("referenceId");
            const classes = useStyles();
            const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

            const hasSummaryFills = hasFills(`document-summary-${library}`);
            const { getRequiredOperation, isSystemLibrary } = useContext(DocumentContext);

            const systemLibrary = isSystemLibrary(library);

            return (
                <ContentHolder>
                    <Slot
                        key={`document-header-${library}`}
                        name={`document-header-${library}`}
                        reference={reference}
                        referenceId={referenceId}
                        library={library}
                        onAdd={onAdd}
                    >
                        <Header
                            className={classes.header}
                            title={getResource("Document.documents.header.title", "PDF templates")}
                            renderActions={() => {
                                return (
                                    !systemLibrary && (
                                        <AuthorizedButton
                                            color="primary"
                                            variant="contained"
                                            onClick={onAdd}
                                            requiredOperation={getRequiredOperation(
                                                library,
                                                "create",
                                                reference
                                            )}
                                            buttonText={getResource(
                                                "Document.documents.header.addButton",
                                                "Add document"
                                            )}
                                        />
                                    )
                                );
                            }}
                        />
                    </Slot>
                    <Grid container spacing={3}>
                        <Grid item xs={hasSummaryFills ? 9 : 12}>
                            <PagedList
                                loading={loading}
                                itemCount={data && data.total}
                                noResultsText={getResource(
                                    "Document.documents.noResults",
                                    "No documents available"
                                )}
                                onPageChange={onPageChange}
                                onPageSizeChange={onPageSizeChange}
                                availablePageSizes={availablePageSizes}
                                page={searchParams.page}
                                pageSize={searchParams.pageSize}
                                renderSearch={() => (
                                    <Search
                                        key={library}
                                        onSearch={onSearchDebounced}
                                        searchParams={searchParams}
                                        library={library}
                                    />
                                )}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <ResourcedText
                                                    resourceKey="Document.documents.overview.nameHeader"
                                                    defaultValue="Name"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ResourcedText
                                                    resourceKey="Document.documents.overview.dateHeader"
                                                    defaultValue="Date"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ResourcedText
                                                    resourceKey="Document.documents.overview.typeHeader"
                                                    defaultValue="Type"
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <ResourcedText
                                                    resourceKey="Document.documents.overview.actionsHeader"
                                                    defaultValue="Actions"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data &&
                                            data.items &&
                                            data.items.map((row) => {
                                                let actions = [
                                                    {
                                                        text: getResource(
                                                            "Document.documents.viewDocument",
                                                            "View"
                                                        ),
                                                        icon: <ViewIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "download",
                                                            reference
                                                        ),
                                                        onClick: () =>
                                                            window.open(
                                                                `/api/documents/libraries/${library}/documents/${row.id}/view`,
                                                                "_blank"
                                                            ),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Document.documents.editDocument",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "update",
                                                            reference
                                                        ),
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Document.documents.downloadDocument",
                                                            "Download"
                                                        ),
                                                        icon: <DownloadIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "download",
                                                            reference
                                                        ),
                                                        onClick: () =>
                                                            window.open(
                                                                `/api/documents/libraries/${library}/documents/${row.id}/download`,
                                                                "_blank"
                                                            ),
                                                    },
                                                ];

                                                if (!systemLibrary) {
                                                    actions.push({
                                                        text: getResource(
                                                            "Document.documents.deleteButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "delete",
                                                            reference
                                                        ),
                                                        onClick: () => onDelete(row.id),
                                                    });

                                                    actions.push({
                                                        text: getResource(
                                                            "Document.documents.archiveButton",
                                                            "Archive"
                                                        ),
                                                        icon: <Archive />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "update",
                                                            reference
                                                        ),
                                                        onClick: () => onArchive({ ...row }),
                                                        hide: row.isArchived,
                                                    });

                                                    actions.push({
                                                        text: getResource(
                                                            "Document.documents.unArchiveButton",
                                                            "Un-archive"
                                                        ),
                                                        icon: <Unarchive />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            library,
                                                            "update",
                                                            reference
                                                        ),
                                                        onClick: () => onUnArchive({ ...row }),
                                                        hide: !row.isArchived,
                                                    });
                                                }

                                                return (
                                                    <AuthorizedTableRow
                                                        id={row.id}
                                                        hover
                                                        key={row.id}
                                                        handleClick={() =>
                                                            window.open(
                                                                `/api/documents/libraries/${library}/documents/${row.id}/view`,
                                                                "_blank"
                                                            )
                                                        }
                                                        requiredOperation={getRequiredOperation(
                                                            library,
                                                            "download",
                                                            reference
                                                        )}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormattedDate date={row.createDate} />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.type}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <ActionMenu actions={actions} />
                                                        </TableCell>
                                                    </AuthorizedTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </PagedList>
                        </Grid>
                        {hasSummaryFills && (
                            <Grid item xs={3} className={classes.header}>
                                <Slot
                                    key={`document-summary-${library}`}
                                    name={`document-summary-${library}`}
                                    reference={reference}
                                    referenceId={referenceId}
                                    library={library}
                                />
                            </Grid>
                        )}
                    </Grid>
                </ContentHolder>
            );
        }
    )
);

export default Documents;
