import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    drawer: {
        "& .MuiDrawer-paper": {
            /** Used CSS variables, so they can also be used inside a drawer. E.g. for an overlay. **/
            "--width": "100%",
            "--minWidth": 0,
            "--maxWidth": "none",
            width: "var(--width)",
            minWidth: "var(--minWidth)",
            maxWidth: "var(--maxWidth)",

            [theme.breakpoints.up("md")]: {
                "--width": "700px",
                overflowY: "scroll",
                overflowX: "hidden",
            },
        },

        "&.--medium": {
            "& .MuiDrawer-paper": {
                [theme.breakpoints.up("md")]: {
                    "--minWidth": "900px",
                    "--width": "50%",
                },
            },
        },

        "&.--large": {
            "& .MuiDrawer-paper": {
                [theme.breakpoints.up("md")]: {
                    "--width": "100%",
                    "--maxWidth": "1500px",
                },
            },
        },
    },

    header: {
        padding: theme.spacing(4, 4, 2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    closeButton: {
        flexShrink: 0,
    },

    closeButtonIcon: {
        marginRight: theme.spacing(1),
    },

    content: {
        padding: `0 ${theme.spacing(4)}px 94px ${theme.spacing(4)}px`,
        flexGrow: 1,

        '& button[type="submit"]': {
            minWidth: 150,
            marginTop: theme.spacing(1),
        },
    },
}));

export default useStyles;
