import React, { useRef, useEffect, Fragment } from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import AddButton from "../../../../shared/addButton";
import TranslatableTextField from "../../../../shared/translatableTextField";

export default withResources(
    ({ getResource, name, onChange, values, errors, culture, disabled, urlRequired }) => {
        const isBrowserAvailable = typeof window !== "undefined";
        const addButtonRef = useRef(null);
        const lastSection = useRef(null);

        useEffect(() => {
            if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
                lastSection.current.focus();
            }
        }, [values, isBrowserAvailable]);

        const handleChange = (values) => {
            onChange({
                target: {
                    name: name,
                    value: values,
                },
            });
        };

        const allLinkTargets = [
            { id: "_self", name: "Same window" },
            { id: "_blank", name: "New window" },
        ];

        return (
            <Fragment>
                <SortableList
                    items={values}
                    sortingChanged={(sortedSections) => {
                        const copy = sortedSections.map((item, index) => ({
                            ...item,
                            order: index,
                        }));
                        handleChange(copy);
                    }}
                    culture={culture}
                    disabled={disabled}
                    renderItemContent={(item, index) => (
                        <Fragment>
                            <TranslatableTextField
                                errors={errors && errors[index]}
                                labelResourceKey="Marketplace.configuration.linksEditor.labelField"
                                labelDefaultValue="Label"
                                fullWidth
                                name="label"
                                inputRef={lastSection}
                                value={item.label}
                                culture={culture}
                                handleChange={(e) => {
                                    const copy = [...values];
                                    copy[index]["label"][culture] = e.target.value;
                                    handleChange(copy, true);
                                }}
                                required
                            />

                            <ResourcedTextField
                                errors={errors && errors[index]}
                                labelResourceKey="Marketplace.configuration.linksEditor.urlField"
                                labelDefaultValue="Url"
                                fullWidth
                                variant="outlined"
                                name="url"
                                InputLabelProps={{ shrink: true }}
                                value={item.url || ""}
                                disabled={disabled}
                                onChange={(e) => {
                                    const copy = [...values];
                                    copy[index]["url"] = e.target.value;
                                    handleChange(copy);
                                }}
                                required
                            />

                            <div style={{ width: 160, flexShrink: 0 }}>
                                <AutoCompleteStatic
                                    errors={errors && errors[index]}
                                    name="target"
                                    fullWidth
                                    isMedium
                                    noMargin
                                    label={getResource(
                                        "Marketplace.configuration.linksEditor.targetField",
                                        "Open in"
                                    )}
                                    value={item.target}
                                    options={allLinkTargets}
                                    disabled={disabled}
                                    disableClearable={urlRequired}
                                    onSelection={(e) => {
                                        const copy = [...values];
                                        copy[index].target = e.target.value ? e.target.value : null;
                                        handleChange(copy);
                                    }}
                                    required
                                />
                            </div>

                            <IconButton
                                aria-label="delete"
                                disabled={disabled}
                                style={{ width: 48 }}
                                onClick={() => {
                                    const copy = [
                                        ...values.slice(0, index),
                                        ...values.slice(index + 1),
                                    ];
                                    handleChange(copy);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Fragment>
                    )}
                />

                <AddButton
                    buttonRef={addButtonRef}
                    buttonText={getResource(
                        "Marketplace.configuration.linksEditor.addLinkText",
                        "Add link"
                    )}
                    handleClick={() => {
                        const copy = values.concat([
                            {
                                order:
                                    values.length !== 0
                                        ? Math.max(...values.map((value) => value.order)) + 1
                                        : 1,
                                label: { [culture]: "" },
                                url: null,
                                target: urlRequired ? "_blank" : null,
                            },
                        ]);
                        handleChange(copy);
                    }}
                />
            </Fragment>
        );
    }
);

export function validate(values, name, errors, getResource, defaultCulture, urlRequired) {
    const setError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((row, i) => {
        if (!row.label[defaultCulture]) {
            setError(
                i,
                "label",
                getResource(
                    "Marketplace.configuration.linksEditor.labelRequired",
                    "Please provide a title for the default language"
                )
            );
        }

        if (!row.url && urlRequired) {
            setError(
                i,
                "url",
                getResource(
                    "Marketplace.configuration.linksEditor.urlRequired",
                    "Please provide a url"
                )
            );
        }

        if (!row.target && (urlRequired || row.url)) {
            setError(
                i,
                "target",
                getResource(
                    "Marketplace.configuration.linksEditor.targetRequired",
                    "Please select a target"
                )
            );
        }
    });
}
