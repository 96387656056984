import React, { useContext } from "react";
import PropTypes from "prop-types";
import PagedListInput from "./index";
import Search from "../../features/marketplace/advertisements/search";
import withResources from "../textresources/withresources";
import PagedListContent from "../../features/marketplace/advertisements/pagedListContent";
import _ from "underscore";
import useSessionStorage from "../useSessionStorage";
import useAPI from "../useapi";
import { getAll } from "../../features/marketplace/advertisements/dataservice";
import ConfigurationContext from "../configuration/configurationcontext";
import WorkingScopeContext from "../../features/shell/components/workingScope/workingscopecontext";

const PagedListInputForAdvertisements = withResources((props) => {
    const { getResource } = props;

    const { companyScope } = useContext(WorkingScopeContext);
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage(
        "search-advertisements-paged-list-input",
        {
            page: 0,
            pageSize: defaultPageSize,
            searchText: "",
            type: "",
            published: "",
        }
    );

    const { loading, invoke, data: filteredAdvertisements } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.type,
                searchParams.published,
                "",
                searchParams.page + 1,
                searchParams.pageSize
            ),
        false,
        [searchParams, companyScope.id]
    );

    const data = filteredAdvertisements || {};
    const items = data.items;

    const onSearch = (searchText, type, published) => {
        setSearchParams({ ...searchParams, searchText, type, published, page: 0 });
    };

    const onPageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const onPageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    return (
        <PagedListInput
            loading={loading}
            itemCount={data.total}
            noResultsText={getResource(
                "Marketplace.advertisements.noResults",
                "No advertisements available"
            )}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            invoke={invoke}
            availablePageSizes={availablePageSizes}
            page={searchParams.page}
            pageSize={searchParams.pageSize}
            items={items}
            PagedListContent={PagedListContent}
            getCustomName={(row) => row.title}
            renderSearch={() => <Search onSearch={onSearchDebounced} searchParams={searchParams} />}
            {...props}
        />
    );
});

PagedListInputForAdvertisements.propTypes = {
    getResource: PropTypes.string,
};

export default PagedListInputForAdvertisements;
