import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import * as HighchartsMore from "highcharts-more";
import { formatDate } from "../../../../shared/formattedDate";
import DataValidator from "../../valuation/editor/helpers/datavalidator.js";
import LocalizationContext from "../../../../shared/localization/localizationcontext";

HighchartsMore(ReactHighcharts.Highcharts);

class Highcharts extends Component {
    chartProperties;

    static contextType = LocalizationContext;

    constructor(props) {
        super(props);

        this.chartProperties = props;

        this.state = {
            maxValue: 0,
            maxPositions: 0,
            config: {
                chart: {
                    zoomType: "xy",
                    animation: false,
                    plotBorderWidth: 1,
                    height: 500,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: "",
                },
                yAxis: {
                    tickAmount: 10,
                },
                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "bottom",
                },
                plotOptions: {
                    scatter: {
                        animation: false,
                        marker: {
                            radius: 3,
                            symbol: "circle",
                        },
                    },
                    series: {
                        cursor: "pointer",
                        dragPrecisionY: 1,
                        stickyTracking: false,
                        connectNulls: true,
                    },
                },
                tooltip: {
                    backgroundColor: "none",
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    padding: 5,
                    yDecimals: 2,
                    pointFormat: "<b>{point.y}</b><br/>",
                    positioner: function () {
                        return { x: 100, y: 10 };
                    },
                },
            },
        };
    }

    componentDidMount() {
        this.bindChartData(this.chartProperties.initParams);
    }

    bindChartData(initParams) {
        let chart = this.refs.reporthighcharts.getChart();
        chart.showLoading();

        let series = initParams.values;
        let serieTitles = initParams.titles;
        let serieColors = initParams.colors;
        let xAxisArray = initParams.xAxis.map((item, index) => {
            return parseFloat(item);
        });
        let reportDate = initParams.reportDate;
        let xLine = initParams.xLine;

        let seriesData = this.parseRawSeriesData(series, serieTitles, serieColors, xAxisArray);
        const dv = new DataValidator();

        // update xAxis
        chart.xAxis[0].update({
            title: { text: initParams.xAxisTitle },
            tickInterval: this.calculateTickInterval(xAxisArray),
            min: xAxisArray[0] - 0.25,
            max: xAxisArray[this.state.maxPositions] + 0.25, // add some extra marge (0.25)
            categories: xAxisArray,
        });

        chart.yAxis[0].update({
            title: { text: initParams.yAxisTitle },
        });

        // add scatter points
        seriesData.map((item, index) => {
            return chart.addSeries({
                data: item.Data,
                type: "scatter",
                name: item.Title,
                color: item.Color,
            });
        });

        // add vertical line (if xLine is present)
        if (dv.isFloat(xLine))
            this.addLine(
                chart,
                parseFloat(xLine),
                reportDate,
                initParams.reportDateColor,
                initParams.reportDateTitle
            );

        // finaly, redraw the chart
        chart.hideLoading();
        chart.redraw();
    }

    calculateTickInterval(xAxisArray) {
        let dataPoints = xAxisArray.length;

        if (dataPoints >= 15) return 5;

        if (dataPoints < 10) return 1;

        return 1;
    }

    parseRawSeriesData(rawSeriesData, serieTitles, serieColors, xAxisArray) {
        let maxValue = 0;
        let series = rawSeriesData.map((items, index) => {
            let data = items.map((dataItem, dataItemIndex) => {
                if (!dataItem) return null;

                if (Number(dataItem) > maxValue) maxValue = Number(dataItem);

                return [xAxisArray[dataItemIndex], Number(dataItem)];
            });

            return {
                Data: data,
                Title: serieTitles[index],
                Color: serieColors[index],
            };
        });

        // save the max value to the state
        let stateData = this.state;
        stateData.maxValue = maxValue;
        this.setState(stateData);

        // remove trailing nulls, only from the largest arrays
        return this.removeTrailingNulls(series);
    }

    removeTrailingNulls(series) {
        let lastNumberIndex = 0;

        series.map((serie, index) => {
            serie.Data.map((item, itemIndex) => {
                if (item !== null && itemIndex > lastNumberIndex) {
                    lastNumberIndex = itemIndex;
                }
                return null;
            });
            return null;
        });

        // set state so we can trim the xAxis based on this value
        let stateData = this.state;
        stateData.maxPositions = lastNumberIndex;
        this.setState(stateData);

        // trim all series
        return series.map((serie, index) => {
            if (serie.Data.length > lastNumberIndex)
                serie.Data = serie.Data.slice(0, lastNumberIndex + 1);

            return serie;
        });
    }

    addLine(chart, xLine, reportDate, purchaseDateColor, purchaseDateTitle) {
        let lineData = [];
        lineData.push({
            x: xLine,
            y: 0,
            name: formatDate(
                reportDate,
                this.context.selectedUiCulture,
                this.context.selectedTimezone,
                true,
                false,
                false,
                false
            ),
            color: purchaseDateColor,
        });

        lineData.push({
            x: xLine,
            y: this.state.maxValue,
            name: formatDate(
                reportDate,
                this.context.selectedUiCulture,
                this.context.selectedTimezone,
                true,
                false,
                false,
                false
            ),
            color: purchaseDateColor,
        });

        // add vertical line
        chart.addSeries({
            type: "line",
            color: purchaseDateColor,
            name: purchaseDateTitle,
            data: lineData,
            lineWidth: 1,
            marker: {
                enabled: true,
            },
            states: {
                hover: {
                    lineWidth: 0,
                },
            },
            enableMouseTracking: true,
        });
    }

    render() {
        return <ReactHighcharts config={this.state.config} isPureConfig ref="reporthighcharts" />;
    }
}

export default Highcharts;
