import React from "react";
import { TopLevelRoute } from "../shell";
import Apply from "./applications/apply";
import Login from "./login";
import ResetPassword from "./passwordReset/reset";
import interceptedFetch from "./../../shared/interceptedFetch";

export default () => {
    interceptedFetch.addPostInterceptor((response) => {
        if (response.status === 401) {
            window.location.href =
                "/login?from=" + window.location.pathname + window.location.search;
            response.skipErrorHandling = true;
        }
    });

    return [
        <TopLevelRoute key="ApplyRoute" href="/apply" render={() => <Apply />} />,
        <TopLevelRoute key="LoginRoute" href="/login" render={() => <Login />} />,
        <TopLevelRoute
            key="ResetPasswordRoute"
            href="/resetPassword"
            render={() => <ResetPassword />}
        />,
    ];
};
