const getItemInTree = (treeItem, itemId) => {
    let foundTreeItem = null;

    function recurse(treeItem, itemId) {
        if (treeItem.id === itemId) {
            foundTreeItem = treeItem;
            return;
        }

        if (treeItem.children) {
            treeItem.children.forEach((child) => foundTreeItem === null && recurse(child, itemId));
        }
    }

    recurse(treeItem, itemId);
    return foundTreeItem;
};

export default getItemInTree;
