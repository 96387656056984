import React, { useState, useRef, useEffect, useCallback } from "react";
import useStyles from "./useStyles";
import { Button, Typography } from "@material-ui/core";
import withResources from "../textresources/withresources";
import EditIcon from "@material-ui/icons/Edit";
import LabelledOutline from "../labelledoutline";
import clsx from "clsx";

const SubForm = withResources(
    ({
        getResource,
        renderChildren,
        title,
        validate,
        summaryLabel,
        summaryText,
        addButtonText,
        handleCancel,
        handleAdd,
        cancelButtonText,
    }) => {
        const classes = useStyles();
        const [added, setAdded] = useState(Boolean(summaryText));
        const [errors, setErrors] = useState(null);
        const subFormRef = useRef(null);
        const summaryButtonRef = useRef(null);

        const onAdd = useCallback(async () => {
            const validationErrors = await validate();
            setErrors(validationErrors);

            if (validationErrors === null) {
                if (handleAdd) {
                    handleAdd();
                }

                setAdded(true);
                summaryButtonRef.current.focus();
            }
        }, [validate, handleAdd]);

        useEffect(() => {
            const subForm = subFormRef.current;
            const handleSubFormEnterPress = (e) => {
                e.stopPropagation();

                const focusIsOnTextField =
                    document.activeElement &&
                    document.activeElement.getAttribute("type") === "text" &&
                    document.activeElement.hasAttribute("name");
                if (e.key === "Enter" && !added && focusIsOnTextField) {
                    onAdd();
                }
            };

            subForm.addEventListener("keypress", handleSubFormEnterPress);
            return () => {
                subForm.removeEventListener("keypress", handleSubFormEnterPress);
            };
        }, [onAdd, added]);

        const onCancel = () => {
            handleCancel();
            setAdded(false);
        };

        return (
            <div
                ref={subFormRef}
                className={clsx(classes.root, !added && "--show-overlay", "subForm")}
            >
                {added ? (
                    <Button
                        onClick={() => setAdded(false)}
                        color="secondary"
                        variant="contained"
                        className={classes.summaryButton}
                        ref={summaryButtonRef}
                        disableFocusRipple
                    >
                        <div className={classes.summaryContent}>
                            <div className={classes.summaryContentWrapper}>
                                <Typography component="h3" className={classes.summaryLabel}>
                                    {summaryLabel}
                                </Typography>
                                <Typography component="p" className={classes.summaryText}>
                                    {summaryText}
                                </Typography>
                            </div>

                            <EditIcon className={classes.summaryEditIcon} />
                            <span>
                                {getResource("subForm.addedSummaryInput.editSubForm", "Edit")}
                            </span>
                        </div>
                    </Button>
                ) : (
                    <LabelledOutline
                        label={title}
                        className={clsx(classes.labelledOutline, "labelledOutline")}
                    >
                        {/** .js-subFormContent is used in localizedform to prevent submit when sub form is open. **/}
                        <div className={clsx(classes.formContent, "js-subFormContent")}>
                            {renderChildren(errors)}

                            <div className={classes.buttons}>
                                <Button
                                    onClick={onAdd}
                                    color="primary"
                                    variant="contained"
                                    className={classes.submitButton}
                                >
                                    {addButtonText
                                        ? addButtonText
                                        : getResource("SubForm.confirmation.addButtonText", "Add")}
                                </Button>

                                <Button onClick={onCancel}>
                                    {cancelButtonText
                                        ? cancelButtonText
                                        : getResource(
                                              "SubForm.confirmation.cancelButtonText",
                                              "Cancel"
                                          )}
                                </Button>
                            </div>
                        </div>
                    </LabelledOutline>
                )}
            </div>
        );
    }
);

export default SubForm;
