import React, { Fragment, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import withResources from "../textresources/withresources";
import useStyles from "./useStyles";
import PagedList from "../pagedList";
import Button from "@material-ui/core/Button";
import Header from "../header";
import { Chip, Dialog, FormHelperText, IconButton, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import {
    ArrowDropDown as ArrowDropDownIcon,
    Clear as ClearIcon,
    Close as CloseIcon,
} from "@material-ui/icons";

const PagedListInput = withResources((props) => {
    const {
        getResource,
        name,
        label,
        value,
        multiple,
        errors,
        isMedium,
        fullWidth,
        noMargin,
        disabled,
        itemCount,
        loading,
        onSelection,
        onPageChange,
        onPageSizeChange,
        invoke,
        availablePageSizes,
        page,
        pageSize,
        dialogTitle,
        items,
        disableClearable, // Only works for single select (not for multiple)
        renderSearch,
        PagedListContent,
        getCustomName,
        noResultsText,
        helperText,
        required,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const labelId = useRef("id_" + Math.random().toString(36).substr(2, 9));
    const [pendingValue, setPendingValue] = useState([]);
    const [chipMaxWidth, setChipMaxWidth] = useState("none");
    const [blockDialogClicks, setBlockDialogClicks] = useState(false);

    const isClearable = multiple || (!disableClearable && value && value.id);
    const type = "pagedListInput";
    const error = errors && name in errors;
    const errorText = errors && errors[name];
    const classes = useStyles();
    const pageListInput = useRef(null);

    useEffect(() => {
        const hasNoValue = !value || value === undefined || (multiple && value[0] === undefined);
        if (hasNoValue) {
            setPendingValue([{ id: undefined, name: undefined }]);
            return;
        }

        setPendingValue(multiple ? value : [value]);
    }, [multiple, value]);

    useEffect(() => {
        const handlePagedListInputResize = () => {
            setChipMaxWidth(
                pageListInput.current ? pageListInput.current.offsetWidth - 70 + "px" : "none"
            );
        };
        handlePagedListInputResize();
        window.addEventListener("resize", handlePagedListInputResize);
        return () => window.removeEventListener("resize", handlePagedListInputResize);
    }, []);

    const saveSelection = (newValue, row = null) => {
        const event = {
            target: {
                name: name,
                type: type,
                value: newValue,
            },
        };

        if (multiple) {
            onSelection(event);
        } else {
            /** Short delay to prevent the deselect button to become visible while dialog is fading **/
            setTimeout(() => onSelection(event, row), 50);
        }
    };

    const select = (row) => {
        const newItem = {
            id: row.id,
            name: getCustomName ? getCustomName(row) : row.name,
        };

        if (!multiple) {
            saveSelection(newItem, row);
            return;
        }

        const hasNoValueYet = pendingValue[0].id === undefined;
        if (hasNoValueYet) {
            saveSelection([newItem]);
            return;
        }

        const newValue = [...pendingValue];
        newValue.push(newItem);
        saveSelection(newValue);
    };

    const deselect = (id) => {
        if (multiple) {
            saveSelection(pendingValue.filter((item) => item.id !== id));
        } else {
            saveSelection({ id: null, name: null });
        }
    };

    const selectOrDeselect = (row) => {
        const selectedItemIsClicked = pendingValue.some((item) => item.id === row.id);
        if (selectedItemIsClicked && !isClearable) {
            return;
        }

        if (!multiple) {
            toggleDialog();
        }

        if (selectedItemIsClicked) {
            deselect(row.id);
        } else {
            select(row);
        }
    };

    const toggleDialog = () => {
        if (disabled) return;

        const willOpen = !isOpen;
        if (willOpen) {
            invoke();
            setBlockDialogClicks(false);
        } else if (!multiple) {
            setBlockDialogClicks(true);
        }

        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <div
                className={clsx(
                    props.className,
                    classes.pagedListInputContainer,
                    isMedium && "--medium",
                    fullWidth && "--fullwidth",
                    noMargin && "--no-margin"
                )}
            >
                <Paper
                    onClick={toggleDialog}
                    className={clsx(
                        classes.pagedListInput,
                        "pagedListInput",
                        isMedium && "--medium",
                        error && "--error",
                        disabled && "--disabled"
                    )}
                    ref={pageListInput}
                >
                    {label && (
                        <label
                            className={clsx(classes.label, error && "--error")}
                            id={labelId.current}
                        >
                            {label}
                            {required && (
                                <span aria-hidden="true" className="MuiInputLabel-asterisk">
                                    {" "}
                                    *
                                </span>
                            )}
                        </label>
                    )}

                    <div className={classes.value}>
                        {multiple
                            ? value &&
                              value.map(
                                  (item) =>
                                      item &&
                                      item.id && (
                                          <Chip
                                              key={item.name}
                                              label={item.name}
                                              title={item.name}
                                              onDelete={() => deselect(item.id)}
                                              size={isMedium ? "medium" : "small"}
                                              disabled={disabled}
                                              className={classes.chip}
                                              style={{ maxWidth: chipMaxWidth }}
                                          />
                                      )
                              )
                            : value && value.name}
                    </div>

                    {isClearable && !multiple && (
                        <IconButton
                            aria-label={getResource(
                                "PagedListInput.clearInputLabel",
                                "Clear input"
                            )}
                            className={classes.clearIconButton}
                            onClick={(e) => {
                                deselect(pendingValue[0].id);
                                e.stopPropagation();
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    )}

                    <IconButton
                        aria-label={getResource(
                            "PagedListInput.openPopperLabel",
                            "Open the Select popup"
                        )}
                        size="small"
                        name={name}
                        className={classes.dialogToggle}
                        disabled={disabled}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                </Paper>

                {errorText && error ? (
                    <FormHelperText variant="outlined" error={error}>
                        {errorText}
                    </FormHelperText>
                ) : helperText ? (
                    <FormHelperText variant="outlined">{helperText}</FormHelperText>
                ) : (
                    ""
                )}
            </div>

            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="lg"
                onBackdropClick={toggleDialog}
                onEscapeKeyDown={toggleDialog}
                classes={{
                    paper: classes.dialogPaper,
                    paperScrollPaper: classes.dialogScrollPaper,
                }}
            >
                <Header
                    title={dialogTitle}
                    renderActions={() => {
                        return (
                            <IconButton
                                aria-label={getResource("PagedListInput.closeDialog", "Close")}
                                onClick={() => toggleDialog()}
                            >
                                <CloseIcon />
                            </IconButton>
                        );
                    }}
                />

                <div className={blockDialogClicks ? "click-blocker-active" : ""}>
                    <PagedList
                        loading={loading}
                        itemCount={itemCount}
                        noResultsText={noResultsText}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={page}
                        pageSize={pageSize}
                        renderSearch={renderSearch}
                    >
                        <PagedListContent
                            items={items}
                            getRequiredOperationForRowClick={() => null}
                            handleRowClick={(row) => selectOrDeselect(row)}
                            renderSelectButton={(id) => {
                                const itemIsSelected = pendingValue.some(
                                    (item) => item && item.id === id
                                );
                                if (itemIsSelected && isClearable) {
                                    return (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.selectDeselectButton}
                                        >
                                            {getResource("PagedListInput.Deselect", "Deselect")}
                                        </Button>
                                    );
                                } else if (itemIsSelected) {
                                    return (
                                        <Button disabled>
                                            {getResource("PagedListInput.selected", "Selected")}
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button className={classes.selectDeselectButton}>
                                            {getResource("PagedListInput.select", "Select")}
                                        </Button>
                                    );
                                }
                            }}
                        />
                    </PagedList>
                </div>
            </Dialog>
        </Fragment>
    );
});

PagedListInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    multiple: PropTypes.bool,
    errors: PropTypes.object,
    isMedium: PropTypes.bool,
    fullWidth: PropTypes.bool,
    noMargin: PropTypes.bool,
    disabled: PropTypes.bool,
    itemCount: PropTypes.number,
    loading: PropTypes.bool,
    onSelection: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    invoke: PropTypes.func.isRequired,
    availablePageSizes: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    dialogTitle: PropTypes.string,
    items: PropTypes.array,
    disableClearable: PropTypes.bool,
    renderSearch: PropTypes.func,
    PagedListContent: PropTypes.func,
    getCustomName: PropTypes.func,
    noResultsText: PropTypes.string,
    helperText: PropTypes.string,
};

export default PagedListInput;
