import React from "react";
import { makeStyles } from "@material-ui/styles";
import useAPI from "../../../shared/useapi";
import { get as getAssetModel } from "../../library/assetmodels/dataservice";
import ValuationList from "../valuation/components/valuationList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import ActionMenu from "../../../shared/actionmenu";
import { getRelevantValuations } from "../valuation/dataservice";
import { Remove as CountryCorrectionsIcon } from "@material-ui/icons";
import withResources from "../../../shared/textresources/withresources";
import StartValuation from "../valuation/startValuation";
import CountryCorrections from "../countryCorrection";
import useDialog from "../../../shared/usedialog";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
    content: {
        padding: 0,
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`,
            },
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
    },
    valueContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    buttonLoadingIndicator: {
        marginLeft: theme.spacing(1),
    },
}));

export default withResources(
    ({
        getResource,
        className,
        match: {
            params: { assetModelId },
        },
        history,
        ...rest
    }) => {
        const { data: assetModel } = useAPI(() => getAssetModel(assetModelId), true);
        const { data: relevantValuations, loading: relevantLoading } = useAPI(
            () => getRelevantValuations(assetModelId),
            true
        );

        const {
            open: handleStartValuation,
            close: handleCloseStartValuation,
            visible: startValuationToggled,
        } = useDialog();
        const {
            open: handleEditCountryCorrections,
            close: handleCloseEditCountryCorrections,
            visible: editCountryCorrectionsToggled,
        } = useDialog();

        const handleEditValuation = (valuationId) => {
            history.push(`/valuation/model-valuation/editor/${valuationId}`);
        };

        const classes = useStyles();

        if (!assetModel || !relevantValuations) {
            return <LinearProgress />;
        }

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Valuation.modelValuation.details.valuationTitle",
                        "Valuations for {manufacturerName} / {name}",
                        { ...assetModel }
                    )}
                    breadcrumb={assetModel.assetCategoryNames}
                    renderActions={() => {
                        return (
                            <React.Fragment>
                                {relevantValuations.currentDraftValuationId && (
                                    <AuthorizedButton
                                        color="primary"
                                        variant="contained"
                                        requiredOperation="model-valuations-create"
                                        onClick={() =>
                                            handleEditValuation(
                                                relevantValuations &&
                                                    relevantValuations.currentDraftValuationId
                                            )
                                        }
                                        buttonText={getResource(
                                            "Valuation.modelValuation.details.continueValuationButton",
                                            "Continue"
                                        )}
                                        className={classes.button}
                                    />
                                )}
                                <AuthorizedButton
                                    color={
                                        relevantValuations.currentDraftValuationId
                                            ? "secondary"
                                            : "primary"
                                    }
                                    variant="contained"
                                    requiredOperation="model-valuations-create"
                                    onClick={handleStartValuation}
                                    buttonText={getResource(
                                        "Valuation.modelValuation.details.startValuationButton",
                                        "Start new"
                                    )}
                                    className={classes.button}
                                >
                                    {relevantLoading && (
                                        <CircularProgress
                                            className={classes.buttonLoadingIndicator}
                                            size={20}
                                        />
                                    )}
                                </AuthorizedButton>
                                {relevantValuations.currentPublishedValuationId && (
                                    <AuthorizedButton
                                        color="secondary"
                                        variant="contained"
                                        requiredOperation="model-valuations-view-published"
                                        onClick={() =>
                                            handleEditValuation(
                                                relevantValuations &&
                                                    relevantValuations.currentPublishedValuationId
                                            )
                                        }
                                        buttonText={getResource(
                                            "Valuation.modelValuation.details.openValuationButton",
                                            "Open current"
                                        )}
                                        className={classes.button}
                                    >
                                        {relevantLoading && (
                                            <CircularProgress
                                                className={classes.buttonLoadingIndicator}
                                                size={20}
                                            />
                                        )}
                                    </AuthorizedButton>
                                )}
                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Valuation.modelValuation.details.openCountryCorrectionsButton",
                                                "Country corrections"
                                            ),
                                            icon: <CountryCorrectionsIcon />,
                                            itemId: assetModel.id,
                                            requiredOperation:
                                                "model-valuations-update-country-corrections",
                                            onClick: () => handleEditCountryCorrections(),
                                        },
                                    ]}
                                />
                            </React.Fragment>
                        );
                    }}
                />

                <ValuationList
                    assetModelId={assetModel.id}
                    onEditValuation={handleEditValuation}
                    requiredEditOperation={"model-valuations-create"}
                    requiredArchiveOperation={"model-valuations-archive"}
                    name={`${assetModel.manufacturerName} / ${assetModel.name}`}
                />

                <CountryCorrections
                    assetModelId={assetModel.id}
                    open={editCountryCorrectionsToggled}
                    onClose={handleCloseEditCountryCorrections}
                    history={history}
                    {...rest}
                />
                <StartValuation
                    assetModelId={assetModel.id}
                    open={startValuationToggled}
                    onClose={handleCloseStartValuation}
                    history={history}
                    onValuationStarted={handleEditValuation}
                    {...rest}
                />
            </ContentHolder>
        );
    }
);
