import React, { Fragment } from "react";
import {
    LinearProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
    FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useAPI from "../../../shared/useapi";
import { getChildren, getParents, getPath, mergeAndDelete, filter } from "./dataservice";
import { getAll as getQuestionnaire } from "../../valuation/questionnaire/dataservice";
import { getAll as getTrend } from "../../valuation/trend/dataservice";

import withResources from "../../../shared/textresources/withresources";
import useForm from "../../../shared/forms/useform";
import DrillDownInput from "../../../shared/drilldowninput";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            minHeight: 700, //Needed for DrillDownInput
        },
    },
}));

export default withResources(({ getResource, id, version, name, open, onClose, onMerge }) => {
    if (!open) {
        return false;
    }

    const classes = useStyles();

    const { data: sourceQuestionnaire } = useAPI(() => getQuestionnaire(id), true, [id]);
    const { data: targetQuestionnaire, invoke: loadTargetQuestionnaire } = useAPI(
        (targetId) => getQuestionnaire(targetId),
        false
    );

    const { data: sourceTrend } = useAPI(() => getTrend(id), true, [id]);
    const { data: targetTrend, invoke: loadTargetTrend } = useAPI(
        (targetId) => getTrend(targetId),
        false
    );

    const { loading, invoke } = useAPI(mergeAndDelete);

    const handleMerge = async (input) => {
        var values = {
            sourceVersion: version,
            targetId: input.values.targetCategory.id,
            questionnaireAnswer: input.values.questionnaireAnswer,
            trendAnswer: input.values.trendAnswer,
        };
        if (await invoke(id, values)) {
            onClose(true);
            onMerge(values.targetId);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.targetCategory || !values.targetCategory.id) {
            errors.targetCategory = getResource(
                "Library.assetCategories.merge.targetRequired",
                "You have to select the category to merge to"
            );
        } else if (values.targetCategory.id === id) {
            errors.targetCategory = getResource(
                "Library.assetCategories.merge.cannotMergeToSelf",
                "You cannot merge this category to itself"
            );
        }

        if (values.targetCategory) {
            if (sourceQuestionnaire.total > 0 && !values.questionnaireAnswer) {
                errors.questionnaireAnswer = getResource(
                    "Library.assetCategories.merge.questionnaireAnswerRequired",
                    "You have to select how you want to merge the questionnaire"
                );
            }

            if (sourceTrend.total > 0 && !values.trendAnswer) {
                errors.trendAnswer = getResource(
                    "Library.assetCategories.merge.trendAnswerRequired",
                    "You have to select how you want to merge the trend"
                );
            }
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { sourceId: id },
        onSubmit: handleMerge,
        validate: (values) => onValidate(values),
    });

    var isLoaded = sourceQuestionnaire && sourceTrend;

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ root: classes.dialog }}
            >
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <React.Fragment>
                        <DialogTitle id="alert-dialog-title">
                            {getResource("Library.assetCategories.merge.header", "Merge category")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {getResource(
                                    "Library.assetCategories.merge.body",
                                    "The category will be deleted, and all related models are moved to the selected category. Sub categories will be moved to the selected category"
                                )}
                            </DialogContentText>

                            <DrillDownInput
                                errors={errors}
                                name="targetCategory"
                                itemName={getResource(
                                    "Library.assetCategories.merge.assetCategoryItemName",
                                    "category"
                                )}
                                label={getResource(
                                    "Library.assetCategories.merge.targetField",
                                    "Target category"
                                )}
                                isMedium
                                fullWidth
                                getParents={getParents}
                                getChildren={getChildren}
                                getPath={getPath}
                                filter={filter}
                                value={values.targetCategory}
                                setValue={(e) => {
                                    handleChange(e);
                                    loadTargetQuestionnaire(e.target.value.id);
                                    loadTargetTrend(e.target.value.id);
                                }}
                                disableClearable
                                required
                            />
                            <FormHelperText variant="outlined">
                                {getResource(
                                    "Library.assetCategories.merge.selectTarget",
                                    "Select the category into which the {name} will be merged.",
                                    { name }
                                )}
                            </FormHelperText>

                            {values.targetCategory &&
                                sourceQuestionnaire.total > 0 &&
                                targetQuestionnaire && (
                                    <Fragment>
                                        <DialogContentText id="alert-dialog-description">
                                            {targetQuestionnaire.total > 0
                                                ? getResource(
                                                      "Library.assetCategories.merge.questionnaireOverwrite",
                                                      "Would you like to overwrite the target category questionnaire or keep the existing one?"
                                                  )
                                                : getResource(
                                                      "Library.assetCategories.merge.questionnaireCopy",
                                                      "Would you like to copy the category questionnaire to the target category?"
                                                  )}
                                        </DialogContentText>
                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="questionnaireAnswer"
                                            label={getResource(
                                                "Library.assetCategories.merge.questionnaireField",
                                                "Merge questionnaire"
                                            )}
                                            isMedium
                                            fullWidth
                                            value={values.questionnaireAnswer}
                                            onSelection={(e) => handleChange(e)}
                                            options={
                                                targetQuestionnaire.total > 0
                                                    ? [
                                                          {
                                                              id: "Overwrite",
                                                              name: getResource(
                                                                  "Library.assetCategories.merge.overwrite",
                                                                  "Overwrite"
                                                              ),
                                                          },
                                                          {
                                                              id: "Keep",
                                                              name: getResource(
                                                                  "Library.assetCategories.merge.keep",
                                                                  "Keep"
                                                              ),
                                                          },
                                                      ]
                                                    : [
                                                          {
                                                              id: "Copy",
                                                              name: getResource(
                                                                  "Library.assetCategories.merge.copy",
                                                                  "Copy"
                                                              ),
                                                          },
                                                          {
                                                              id: "DontCopy",
                                                              name: getResource(
                                                                  "Library.assetCategories.merge.dontCopy",
                                                                  "Don't copy"
                                                              ),
                                                          },
                                                      ]
                                            }
                                            disableClearable
                                            required
                                        />
                                    </Fragment>
                                )}

                            {values.targetCategory && sourceTrend.total > 0 && targetTrend && (
                                <Fragment>
                                    <DialogContentText id="alert-dialog-description">
                                        {targetTrend.total > 0
                                            ? getResource(
                                                  "Library.assetCategories.merge.trendOverwrite",
                                                  "Would you like to overwrite the target category trend or keep the existing one?"
                                              )
                                            : getResource(
                                                  "Library.assetCategories.merge.trendCopy",
                                                  "Would you like to copy the category trend to the target category?"
                                              )}
                                    </DialogContentText>
                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="trendAnswer"
                                        label={getResource(
                                            "Library.assetCategories.merge.trendField",
                                            "Merge trend"
                                        )}
                                        isMedium
                                        fullWidth
                                        value={values.trendAnswer}
                                        onSelection={(e) => handleChange(e)}
                                        options={
                                            targetTrend.total > 0
                                                ? [
                                                      {
                                                          id: "Overwrite",
                                                          name: getResource(
                                                              "Library.assetCategories.merge.overwrite",
                                                              "Overwrite"
                                                          ),
                                                      },
                                                      {
                                                          id: "Keep",
                                                          name: getResource(
                                                              "Library.assetCategories.merge.keep",
                                                              "Keep"
                                                          ),
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          id: "Copy",
                                                          name: getResource(
                                                              "Library.assetCategories.merge.copy",
                                                              "Copy"
                                                          ),
                                                      },
                                                      {
                                                          id: "DontCopy",
                                                          name: getResource(
                                                              "Library.assetCategories.merge.dontCopy",
                                                              "Don't copy"
                                                          ),
                                                      },
                                                  ]
                                        }
                                        disableClearable
                                        required
                                    />
                                </Fragment>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClose(false)} autoFocus>
                                {getResource(
                                    "Library.assetCategories.merge.cancelButtonText",
                                    "Cancel"
                                )}
                            </Button>
                            <Button disabled={loading} onClick={handleSubmit} color="primary">
                                {getResource("Library.assetCategories.merge.okButton", "Merge")}
                                {loading && <CircularProgress />}
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                )}
            </Dialog>
        </div>
    );
});
