import React, { Fragment } from "react";
import useStyles from "./useStyles";
import Typography from "@material-ui/core/Typography";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import clsx from "clsx";

const Path = React.memo(
    ({ path, useRegularText, keepOnOneLine, removeLastItem, alwaysReserveHeight }) => {
        const classes = useStyles();

        if (path && removeLastItem) {
            path.pop();
        }

        if (!path || path.length === 0) {
            if (alwaysReserveHeight) {
                return <Fragment>&nbsp;</Fragment>;
            }
            return null;
        }

        const pathForTitle = path.join(" > ");

        const renderItem = (item, i) => (
            <Fragment key={item}>
                {item}
                {i < path.length - 1 && (
                    <ChevronRightIcon
                        className={clsx(classes.chevronIcon, useRegularText && "--regular-size")}
                    />
                )}
            </Fragment>
        );

        return (
            <Typography
                variant={useRegularText ? "body1" : "body2"}
                component="p"
                color={useRegularText ? "inherit" : "textSecondary"}
                className={clsx(classes.path, "path", keepOnOneLine && "--keep-on-one-line")}
                title={pathForTitle}
            >
                {path.map(
                    (item, i) =>
                        keepOnOneLine ? (
                            renderItem(
                                item,
                                i
                            ) /** No span, since that causes problems for the rtl direction (needed for truncation at the beginning of the line) */
                        ) : (
                            <span key={item}>{renderItem(item, i)}</span>
                        ) /** A span here reduces the times a new line is started with an icon */
                )}
                &lrm;
            </Typography>
        );
    }
);

export default Path;
