import React from "react";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { listTimeZones } from "timezone-support";

export default withResources(({ getResource, name, value, onChange, errors, required }) => {
    const timeZones = listTimeZones();
    const options =
        timeZones &&
        timeZones
            .filter((zone) => !zone.startsWith("Etc") && zone.indexOf("/") > 0)
            .sort()
            .map((x) => {
                return { id: x, name: x };
            });

    return (
        <AutoCompleteStatic
            errors={errors}
            name={name}
            label={getResource("Security.users.timezoneField", "Timezone")}
            fullWidth
            isMedium
            value={value}
            onSelection={onChange}
            disableClearable
            options={options}
            required
        />
    );
});
