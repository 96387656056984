import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(name, manufacturerId, assetCategoryId, page, pageSize) {
    const params = {
        page,
        pageSize,
        name,
        manufacturerId,
        assetCategoryId,
    };
    const url = `/api/library/assetmodel?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAllForAssetContext(searchText, manufacturerId, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        manufacturerId,
    };
    const url = `/api/library/assetmodel/forAssetContext?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/library/assetmodel/${id}`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/library/assetmodel/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/library/assetmodel", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/library/assetmodel/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function approve(id, values) {
    return await interceptedFetch(`/api/library/assetmodel/${id}/approve`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function mergeAndDelete(sourceId, values) {
    return await interceptedFetch(`/api/library/assetmodel/${sourceId}/merge`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/library/assetmodel/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function getAssetAttributeDefinitions(id) {
    const url = `/api/library/assetmodel/${id}/assetattributedefinitions`;

    return await interceptedFetch(url);
}

async function nameExists(name, manufacturerId) {
    const params = {
        manufacturerId,
    };
    const url = `/api/library/assetmodel/${name}/exists?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getMergeInfo(id) {
    const url = `/api/library/assetmodel/${id}/mergeInfo`;

    return await interceptedFetch(url);
}

export {
    getAll,
    getAllForAssetContext,
    get,
    getIsReferenced,
    add,
    update,
    approve,
    mergeAndDelete,
    del,
    getAssetAttributeDefinitions,
    nameExists,
    getMergeInfo,
};
