import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { close } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, onClose, valuationRequestId }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(close);

    function handleCancel() {
        onClose(false);
    }

    const handleClose = async () => {
        if (await invoke(valuationRequestId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleClose}
            handleClose={handleCancel}
            headerText={getResource(
                "Valuation.valuationRequests.close.confirmationHeader",
                "Close valuation request?"
            )}
            buttonText={getResource(
                "Valuation.valuationRequests.close.confirmationButton",
                "Close"
            )}
        >
            <ResourcedText
                resourceKey="Valuation.valuationRequests.close.closeMessage"
                defaultValue="A customer may be waiting for the requested valuation. Are you sure you want to close this valuation request?"
            />
        </Confirmation>
    );
});
