import React, { Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useAPI from "../../../shared/useapi";
import { get as getWorkOrder } from "../workOrder/dataservice";
import { get as getWorkOrderTask } from "../workOrderTask/dataservice";
import Detail from "./detail";
import NotFound from "../../../shared/notFound";

const JobSheet = (props) => {
    const workOrderId = props.match.params["workOrderId"];
    const taskId = props.match.params["taskId"];

    const { data: workOrder, loading: workOrderLoading } = useAPI(
        () => getWorkOrder(workOrderId),
        true,
        [workOrderId]
    );
    const { data: task, invoke, loading: taskLoading } = useAPI(
        () => getWorkOrderTask(workOrderId, taskId),
        true,
        [workOrderId, taskId]
    );

    return (
        <Fragment>
            {(workOrderLoading || taskLoading) && <LinearProgress />}
            {!workOrderLoading && !workOrder && <NotFound element="work order" />}
            {workOrder && !taskLoading && !task && <NotFound element="task" />}
            {workOrder && task && (
                <Detail
                    workOrder={workOrder}
                    task={{
                        ...task,
                        labor: task.labor.map((labor) => {
                            return {
                                ...labor,
                                taskHourlyRate: task.hourlyRate,
                                allowRateOverride: task.allowRateOverride,
                            };
                        }),
                    }}
                    onSave={invoke}
                    {...props}
                />
            )}
        </Fragment>
    );
};

export default () => {
    return (
        <PrivateRoute
            exact
            requiredOperation={"work-orders-task-update"}
            path="/maintenance/work-orders/:workOrderId(\d+)/tasks/:taskId(\d+)"
            render={(props) => <JobSheet {...props} />}
        />
    );
};
