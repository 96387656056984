import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function get(id) {
    const url = `/api/valuation/appraisal/${id}`;

    return await interceptedFetch(url);
}

async function getAll(searchText, statuses, assetId, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        statuses,
        assetId,
    };
    const url = `/api/valuation/appraisal?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/valuation/appraisal", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/valuation/appraisal/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/valuation/appraisal/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function publish(id, values) {
    return await interceptedFetch(`/api/valuation/appraisal/${id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getLatestQuickScanValues(assetId) {
    return await interceptedFetch(
        `/api/valuation/quickscan/LatestQuickScanValues?assetId=${assetId}`
    );
}

export { getAll, get, add, update, del, getLatestQuickScanValues, publish };
