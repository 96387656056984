import Data from "../data.json";

class DateHelper {
    getBuildYear(i) {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        let beforeMonth = month - 6 >= 0 ? month - 6 : 12 - Math.abs(month - 6);
        let periodMonth = Data.FullMonthNames[month];
        let beforePeriodMonth = Data.FullMonthNames[beforeMonth];

        let monthName = i % 2 === 0 ? periodMonth : beforePeriodMonth;
        let skipYear = i === 1 && month < beforeMonth;

        if (skipYear) year--; // if we subtract 6 from the current month and we will end up in the previous year, we will have to sutract a year.

        let minus = Math.ceil(i > 0 ? i / 2 : 1);
        let outputYear = (year -= (i !== 0) & (i % 2 === 0) ? minus : minus - 1);

        return monthName.substring(0, 3) + " " + outputYear;
    }
}

export default DateHelper;
