class manager {
    constructor() {
        this.fills = new Map();
        this.slotCallbacks = new Map();

        this.registerFill = this.registerFill.bind(this);
        this.unRegisterFill = this.unRegisterFill.bind(this);
        this.registerSlot = this.registerSlot.bind(this);
        this.hasFills = this.hasFills.bind(this);
        this.fireCallback = this.fireCallback.bind(this);
    }

    registerFill(slot, fill) {
        let slotFills = this.fills.get(slot);

        if (!slotFills) {
            this.fills.set(slot, []);
            slotFills = this.fills.get(slot);
        }

        this.fills.set(slot, slotFills.concat(fill));

        this.fireCallback(slot);
    }

    unRegisterFill(slot, fill) {
        let slotFills = this.fills.get(slot);

        if (!slotFills) {
            return;
        }

        const index = slotFills.indexOf(fill);
        if (index > -1) {
            slotFills.splice(index, 1);
        }

        this.fills.set(slot, [...slotFills]);

        this.fireCallback(slot);
    }

    registerSlot(name, callback) {
        this.slotCallbacks.set(name, callback);
        this.fireCallback(name);
    }

    hasFills(slot) {
        return this.fills.get(slot) && this.fills.get(slot).length > 0;
    }

    fireCallback(slot) {
        let slotFills = this.fills.get(slot);

        if (!slotFills || slotFills.length === 0) {
            return;
        }

        const slotCallback = this.slotCallbacks.get(slot);
        if (!slotCallback) {
            return;
        }

        slotCallback(slotFills);
    }
}

export default manager;
