import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Overview from "./overview";
import Delete from "./delete";
import { getAll } from "./dataservice";
import { get as getComponent } from "../component/dataservice";

import useDialog from "../../../shared/usedialog";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";

const MediaLibrary = (props) => {
    var componentId = props.match.params["componentId"];
    var library = props.match.params["library"];

    const { defaultDebounceInterval } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-component-media", {
        searchText: null,
    });

    const { data: component } = useAPI(() => getComponent(componentId), true, [componentId]);

    const { invoke, data } = useAPI(
        () => getAll(componentId, library, searchParams.searchText),
        true,
        [searchParams, componentId, library]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);

    function onSearch(searchText) {
        setSearchParams({ ...searchParams, searchText });
    }

    return (
        component && (
            <React.Fragment>
                <Overview
                    data={data}
                    component={component}
                    searchParams={searchParams}
                    onSearch={onSearch}
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onMove={invoke}
                    defaultDebounceInterval={defaultDebounceInterval}
                    {...props}
                />

                <Add
                    componentId={componentId}
                    library={library}
                    open={addToggled}
                    onClose={handleCloseAdd}
                />
                <Edit
                    componentId={componentId}
                    library={library}
                    id={editId}
                    open={editToggled}
                    onClose={handleCloseEdit}
                />
                <Delete
                    componentId={componentId}
                    library={library}
                    id={deleteId}
                    open={deleteToggled}
                    onClose={handleCloseDelete}
                />
            </React.Fragment>
        )
    );
};

export default () => {
    return (
        <PrivateRoute
            requiredOperation={(match) =>
                `component-media-view-${match.params.library.toLowerCase()}`
            }
            path="/assets/component-media/:componentId(\d+)/:library(\w+)"
            render={(props) => <MediaLibrary {...props} />}
        />
    );
};
