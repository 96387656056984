import React, { useContext } from "react";
import withResources from "../../../../shared/textresources/withresources";
import { Cancel as ReviewIcon, Warning as RenewIcon } from "@material-ui/icons";
import { formatDate } from "../../../../shared/formattedDate";
import useStyles from "./useStyles";
import LocalizationContext from "../../../../shared/localization/localizationcontext";

const FactNotification = withResources((props) => {
    const { getResource, action, type } = props;

    const classes = useStyles();
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    let urgencyIcon;
    let needs;
    if (action.urgency === "impending") {
        urgencyIcon = <RenewIcon className={classes.icon} fontSize="small" />;
        needs = getResource("Asset.facts.notifications.needsFuture", "needs");
    } else if (action.urgency === "overdue") {
        urgencyIcon = <ReviewIcon className={classes.icon} fontSize="small" />;
        needs = getResource("Asset.facts.notifications.needsPast", "needed");
    }

    let actionText;
    if (action.action === "FactsReview" || action.action === "DocumentsReview") {
        actionText = getResource("Asset.facts.overview.action.plural.review", "reviewed");
    } else if (action.action === "FactsRenewal" || action.action === "DocumentsRenewal") {
        actionText = getResource("Asset.facts.overview.action.plural.renewal", "renewed");
    }

    return (
        <div className={`${classes.action} ${action.urgency}`}>
            {urgencyIcon}

            {getResource(
                "Asset.facts.notifications.action",
                "The {type} {needs} to be {actionText} before {deadline}",
                {
                    type: type.toLowerCase(),
                    needs,
                    actionText,
                    deadline: action.dueDate
                        ? formatDate(action.dueDate, selectedUiCulture, selectedTimezone, true)
                        : action.value + " " + action.unit,
                }
            )}
        </div>
    );
});

export default FactNotification;
