const handleSearchPress = async (searchText, setAutoCompleteResults, filter) => {
    if (searchText === "" || searchText.length < 3) {
        setAutoCompleteResults([]);
        return;
    }

    const newAutoCompleteResults = await filter(searchText, null, 1, 100);
    const jsonNewAutoCompleteResults = await newAutoCompleteResults.json();
    setAutoCompleteResults(jsonNewAutoCompleteResults.items);
};

export default handleSearchPress;
