import React, { useContext, useEffect } from "react";
import { Section } from "../shell";
import Documents from "./documents";
import withResources from "../../shared/textresources/withresources";
import DocumentContext from "./documents/documentcontext";
import ReferenceContext from "../../shared/reference/referenceContext";

import { DocumentHeader, DocumentSummary } from "./documents/overview";

export { default as DocumentProvider } from "./documents/documentprovider";

export const LibraryExtension = ({ library, referenceType, renderHeader, renderSummary }) => {
    const { registerReferenceType } = useContext(DocumentContext);
    const { getReference } = useContext(ReferenceContext);

    useEffect(() => {
        registerReferenceType(library, getReference(referenceType));
    }, [library, referenceType, registerReferenceType, getReference]);

    return (
        <React.Fragment>
            <DocumentHeader library={library}>{renderHeader()}</DocumentHeader>
            <DocumentSummary library={library}>{renderSummary()}</DocumentSummary>
        </React.Fragment>
    );
};

export default withResources(({ getResource }) => {
    const children = [
        {
            //No operation because the operation are set per library
            title: getResource("Document.documentsTitle", "Documents"),
            href: "/documents/documents",
            render: Documents,
            hideInMenu: true,
        },
    ];

    return (
        <Section
            key="Document"
            hideInMenu
            title={getResource("Document.title", "Document")}
            href="/documents"
            children={children}
        />
    );
});
