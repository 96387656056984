import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        marginBottom: theme.spacing(3),
    },

    factCard: {
        padding: theme.spacing(3),
    },

    factLabel: {
        display: "inline-block",
        marginBottom: theme.spacing(1),
        padding: "2px 7px",
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white,
        textTransform: "uppercase",
        fontSize: 10,
        fontWeight: 500,
    },

    factHeader: {
        marginBottom: theme.spacing(3),
    },

    subTitle: {
        fontWeight: 500,
    },

    factMedia: {
        marginBottom: theme.spacing(2),

        "& .alice-carousel__stage-item": {
            margin: theme.spacing(0, 0.5),
            borderRadius: 4,
        },

        "& .alice-carousel__prev-btn, & .alice-carousel__next-btn": {
            position: "absolute",
            top: "50%",
            width: 30,
            transition: "translateY(-50%)",

            "& .alice-carousel__prev-btn-item, & .alice-carousel__next-btn-item": {
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,

                "&.__inactive": {
                    visibility: "hidden",
                },

                "& span": {
                    display: "block",
                    textAlign: "center",
                    lineHeight: "1",

                    "&:after": {
                        color: theme.palette.white,
                        fontSize: 20,
                    },

                    "&:hover": {
                        "&:after": {
                            color: theme.palette.white,
                        },
                    },
                },
            },
        },

        "& .alice-carousel__prev-btn": {
            left: 10,
        },

        "& .alice-carousel__next-btn": {
            right: 10,
        },
    },

    mediaImg: {
        display: "block",
        width: "100%",
        height: 275,
        boxSizing: "border-box",
        borderRadius: 4,
        objectFit: "cover",
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },

    factActions: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        padding: "4px 0 4px 15px",
        border: "1px solid",
        borderRadius: 4,

        "&.red": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },

        "&.orange": {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
        },
    },

    icon: {
        marginRight: theme.spacing(2),
    },

    factFooter: {
        padding: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.dividerGray}`,

        "& a": {
            display: "inline-flex",
            alignItems: "center",
            marginRight: theme.spacing(3),
            color: theme.palette.primary.main,
            textDecoration: "none",

            "& span": {
                display: "block",
                marginLeft: theme.spacing(1),
            },

            "&:hover, &:focus": {
                textDecoration: "underline",
            },
        },
    },

    factActionMenu: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

export default useStyles;
