import React from "react";
import { FormControlLabel, RadioGroup, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import withResources from "../../../../shared/textresources/withresources";

const useStyles = makeStyles((theme) => ({
    answers: {
        margin: "20px 0",
    },

    buttonBar: {
        "& button": {
            marginRight: 10,
        },
    },
}));

const AnswerQuestionnaire = withResources(({ getResource, quickScan, question, onChange }) => {
    const classes = useStyles();

    const getGivenAnswer = () => {
        var givenAnswer = quickScan.answers[question.id];
        return givenAnswer ? givenAnswer : 0;
    };

    return (
        <div>
            <Typography variant="h4">{question.question}</Typography>

            <RadioGroup
                className={classes.answers}
                aria-label="Question"
                name="Question"
                value={getGivenAnswer()}
                onChange={(e) => onChange(parseInt(e.target.value))}
            >
                {question.answers.map((answer) => (
                    <FormControlLabel
                        key={answer.answerId}
                        value={answer.answerId}
                        control={<Radio />}
                        label={answer.answer}
                    />
                ))}
            </RadioGroup>
        </div>
    );
});

export default AnswerQuestionnaire;
