import React, { useContext } from "react";
import {
    FormControlLabel,
    Checkbox,
    LinearProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useAPI from "../../../shared/useapi";
import { get, moveCompany } from "./dataservice";
import { getWhereUserHasOperation as getCompanies } from "../../security/companies/dataservice";
import { getByCompanyWhereUserHasOperation as getAssetCollections } from "../assetCollection/dataservice";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import withResources from "../../../shared/textresources/withresources";
import useForm from "../../../shared/forms/useform";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    const { data: asset } = useAPI(() => get(id), true);
    const { data: allCompanies } = useAPI(() => getCompanies(["assets-assign-company"]), true);
    const { data: allAssetCollections, invoke: loadAssetCollections } = useAPI(
        (companyId) => getAssetCollections(companyId, "assets-assign-asset-collection"),
        false
    );

    var assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);

    const { loading, invoke } = useAPI(moveCompany);

    const handleMove = async (input) => {
        if (await invoke(id, input.values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.newCompanyId) {
            errors.newCompanyId = getResource(
                "Asset.asset.moveCompany.newCompanyId",
                "You have to select a new company"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { trackMovement: true, deleteFinancial: true },
        onSubmit: handleMove,
        validate: (values) => onValidate(values),
    });

    const setCompany = (e) => {
        handleChange(e);

        if (e.target.value) {
            loadAssetCollections(e.target.value);
        }
    };

    var isLoaded = asset && allCompanies;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <React.Fragment>
                    <DialogTitle id="alert-dialog-title">
                        {getResource("Asset.asset.moveCompany.header", "Move asset {assetName}?", {
                            assetName,
                        })}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getResource(
                                "Asset.asset.moveCompany.body",
                                "By moving this asset to another company users that currently have access might lose access to it."
                            )}
                        </DialogContentText>
                        <AutoCompleteStatic
                            errors={errors}
                            name="newCompanyId"
                            label={getResource(
                                "Asset.asset.moveCompany.newCompanyField",
                                "Target company"
                            )}
                            fullWidth
                            isMedium
                            value={values.newCompanyId}
                            onSelection={(e) => setCompany(e)}
                            options={allCompanies}
                            disableClearable
                        />
                        <AutoCompleteStatic
                            errors={errors}
                            name="newAssetCollectionId"
                            label={getResource(
                                "Asset.asset.moveCompany.newAssetCollectionField",
                                "Target collection"
                            )}
                            fullWidth
                            isMedium
                            value={values.newAssetCollectionId}
                            onSelection={(e) => handleChange(e)}
                            options={allAssetCollections}
                        />
                        <Alert severity="warning">
                            {getResource(
                                "Asset.asset.moveCompany.deleteFinancialExplain",
                                "By default all financial information and financial documents related to the asset will be deleted to ensure this information is not available to the new company."
                            )}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(values.deleteFinancial)}
                                        onChange={(e) => handleChange(e)}
                                        name="deleteFinancial"
                                    />
                                }
                                label={getResource(
                                    "Asset.asset.moveCompany.deleteFinancialField",
                                    "Delete all financial information and financial documents"
                                )}
                                variant="outlined"
                            />
                        </Alert>
                        &nbsp;
                        <Alert severity="info">
                            {getResource(
                                "Asset.asset.moveCompany.trackMovementExplain",
                                "By default all asset movements are tracked and visible in the asset’s movement history."
                            )}

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(values.trackMovement)}
                                        onChange={(e) => handleChange(e)}
                                        name="trackMovement"
                                    />
                                }
                                label={getResource(
                                    "Asset.asset.moveCompany.trackMovementField",
                                    "Track movement"
                                )}
                                variant="outlined"
                            />
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} autoFocus>
                            {getResource("Asset.asset.moveCompany.cancelButtonText", "Cancel")}
                        </Button>
                        <Button disabled={loading} onClick={handleSubmit} color="primary">
                            {getResource("Asset.asset.moveCompany.okButton", "Move")}
                            {loading && <CircularProgress />}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
});
