import React, { useContext, useState } from "react";

import ReferenceContext from "./referenceContext";
import LocalizationContext from "../localization/localizationcontext";

export default ({ children }) => {
    const [referenceTypes, setReferenceTypes] = useState([]);

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    function registerReference(type, fetchMethod, nameMethod, breadcrumbMethod) {
        var referenceType = {
            type,
            fetchMethod,
            nameMethod,
            breadcrumbMethod,
        };

        referenceTypes.push(referenceType);
        setReferenceTypes(referenceTypes);
    }

    function getReference(type) {
        var referenceType = referenceTypes.filter((x) => x.type === type);
        if (referenceType) {
            return referenceType;
        }
        return null;
    }

    async function getReferenceEntity(type, id) {
        if (!type || !id) {
            return null;
        }

        var referenceType = referenceTypes.filter((x) => x.type === type);
        if (referenceType) {
            var reference = await referenceType[0].fetchMethod(id);
            return reference;
        }
        return null;
    }

    function getEntityName(type, entity) {
        if (!type || !entity) {
            return null;
        }

        var referenceType = referenceTypes.filter((x) => x.type === type);
        if (referenceType) {
            var name = referenceType[0].nameMethod(entity, defaultCulture, selectedCulture);
            return name;
        }
        return null;
    }

    function getEntityBreadcrumb(type, entity) {
        if (!type || !entity) {
            return null;
        }

        var referenceType = referenceTypes.filter((x) => x.type === type);
        if (referenceType) {
            var name = referenceType[0].breadcrumbMethod(entity);
            return name;
        }
        return null;
    }

    return (
        <ReferenceContext.Provider
            value={{
                registerReference,
                getReference,
                getReferenceEntity,
                getEntityName,
                getEntityBreadcrumb,
            }}
        >
            {children}
        </ReferenceContext.Provider>
    );
};
