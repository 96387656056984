import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Visibility as ViewIcon,
    Description as DescriptionIcon,
    Receipt as InvoiceIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import WorkingContext from "../../security/workingContext";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ActionMenu from "../../../shared/actionmenu";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    root: {
        "& span + span": {
            paddingLeft: theme.spacing(1),

            "&::before": {
                content: "'|'",
                paddingRight: theme.spacing(1),
            },
        },
    },
}));

const AssetModelQuickScanList = withResources(
    ({
        getResource,
        data,
        page,
        pageSize,
        availablePageSizes,
        loading,
        history,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onPurchase,
        onDownloadReport,
        onDelete,
        onInvoice,
    }) => {
        const classes = useStyles();

        return (
            <React.Fragment>
                <WorkingContext
                    requiredOperation="asset-model-quick-scans-view"
                    hideAssetCollections
                />
                <ContentHolder>
                    <Header
                        title={getResource(
                            "Valuation.assetModelQuickScanList.header.title",
                            "Model quick scans"
                        )}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onPurchase}
                                    requiredOperation={"asset-model-quick-scans-purchase"}
                                    buttonText={getResource(
                                        "Valuation.assetModelQuickScanList.header.purchaseButton",
                                        "Purchase"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data && data.total}
                        noResultsText={getResource(
                            "Valuation.assetModelQuickScanList.noResults",
                            "No model quick scans available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={page}
                        pageSize={pageSize}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.root}>
                                        <span>
                                            <ResourcedText
                                                resourceKey="Valuation.assetModelQuickScanList.manufacturerHeader"
                                                defaultValue="Manufacturer"
                                            />
                                        </span>
                                        <span>
                                            <ResourcedText
                                                resourceKey="Valuation.assetModelQuickScanList.modelHeader"
                                                defaultValue="Model"
                                            />
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.assetModelQuickScanList.referenceHeader"
                                            defaultValue="Reference"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.assetModelQuickScanList.purchasedOnHeader"
                                            defaultValue="Purchased on"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.assetModelQuickScanList.purchasedByHeader"
                                            defaultValue="Purchased by"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Valuation.assetModelQuickScanList.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.items &&
                                    data.items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => {
                                                history.push(`/quick-scan/report/${row.id}`);
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className={classes.root}>
                                                    <span>{row.manufacturerName}</span>
                                                    <span>{row.assetModelName}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.reference}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <FormattedDate
                                                    date={row.purchasedDate}
                                                    includeTime
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.purchasedBy}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Valuation.assetModelQuickScanList.viewButton",
                                                                "View"
                                                            ),
                                                            icon: <ViewIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "asset-model-quick-scans-view"
                                                            ),
                                                            onClick: () =>
                                                                history.push(
                                                                    `/quick-scan/report/${row.id}`
                                                                ),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.assetModelQuickScanList.deleteButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "asset-model-quick-scans-delete"
                                                            ),
                                                            onClick: () =>
                                                                onDelete({
                                                                    id: row.id,
                                                                    reference: row.reference,
                                                                }),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.assetModelQuickScanList.downloadReportButton",
                                                                "Download report"
                                                            ),
                                                            icon: <DescriptionIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "asset-model-quick-scans-view"
                                                            ),
                                                            onClick: () => onDownloadReport(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.assetModelQuickScanList.invoiceButton",
                                                                "Create invoice"
                                                            ),
                                                            icon: <InvoiceIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "invoice-create"
                                                            ),
                                                            onClick: () => onInvoice(row.id),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default AssetModelQuickScanList;
