import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getInitialParameters(assetModelId, assetId) {
    const params = {
        assetModelId,
        assetId,
    };

    const url = `/api/valuation/valuation/initialparameters?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function startValuation(values) {
    return await interceptedFetch("/api/valuation/valuation/startvaluation", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getAvailableBuildYears() {
    return await interceptedFetch("/api/valuation/valuation/availablebuildyears");
}

async function getAssetModelsWithValuations(
    name,
    manufacturerId,
    assetCategoryId,
    statuses,
    page,
    pageSize
) {
    const params = {
        page,
        pageSize,
        name,
        manufacturerId,
        assetCategoryId,
        statuses,
    };

    const url = `/api/valuation/valuation/assetmodels?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAssetsWithValuations(
    searchText,
    manufacturerId,
    assetModelId,
    statuses,
    page,
    pageSize
) {
    const params = {
        page,
        pageSize,
        searchText,
        manufacturerId,
        assetModelId,
        statuses,
    };

    const url = `/api/valuation/valuation/assets?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(assetModelId, assetId, createdBy, statuses, page, pageSize) {
    const params = {
        page,
        pageSize,
        assetModelId,
        assetId,
        createdBy,
        statuses,
    };

    const url = `/api/valuation/valuation/?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getPublishedForAssetModel(assetModelId) {
    const url = `/api/valuation/valuation/assetModel/${assetModelId}`;

    return await interceptedFetch(url);
}

async function getPublishedForAsset(assetId) {
    const url = `/api/valuation/valuation/asset/${assetId}`;

    return await interceptedFetch(url);
}

async function getRelevantValuations(assetModelId, assetId) {
    const params = {
        assetModelId,
        assetId,
    };

    const url = `api/valuation/valuation/relevant/?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getDetails(valuationId) {
    const url = `/api/valuation/valuation/${valuationId}`;

    return await interceptedFetch(url);
}

async function archive(valuationId) {
    return await interceptedFetch(`/api/valuation/valuation/archive/${valuationId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export {
    getInitialParameters,
    startValuation,
    getAvailableBuildYears,
    getAssetModelsWithValuations,
    getAssetsWithValuations,
    getRelevantValuations,
    get,
    getPublishedForAssetModel,
    getPublishedForAsset,
    getDetails,
    archive,
};
