import React, { Fragment, useContext } from "react";
import { LinearProgress } from "@material-ui/core";

import useAPI from "../../../../shared/useapi";
import { getAll as getAllManufacturers } from "../../manufacturers/dataservice";
import { getAll as getAllAssetTypes } from "../../assettypes/dataservice";
import { getAll as getAllAssetCategories } from "../../assetcategories/dataservice";

import WorkingScopeContext from "../../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../../shared/textresources/withresources";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import DrillDownInput from "../../../../shared/drilldowninput";
import SubForm from "../../../../shared/subForm";
import { filter, getChildren, getParents, getPath } from "../../assetcategories/dataservice";
import { nameExists } from "../../manufacturers/dataservice";

import EditFormManufacturer, {
    validate as validateManufacturer,
} from "../../manufacturers/components/editForm";

export default withResources(({ getResource, errors, values, handleChange }) => {
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const { data: allManufacturers } = useAPI(() => getAllManufacturers(null, null, null), true);
    const { data: allAssetCategories } = useAPI(getAllAssetCategories, true);
    const { data: allAssetTypes } = useAPI(() => getAllAssetTypes(null, null, null), true);

    var isLoaded = allManufacturers && allAssetCategories && allAssetTypes;

    return !isLoaded ? (
        <LinearProgress />
    ) : (
        <Fragment>
            {values && values.suggestManufacturer ? (
                <SubForm
                    title={getResource(
                        "Library.assetModels.editForm.suggestManufacturer.title",
                        "Suggest Manufacturer"
                    )}
                    handleCancel={() =>
                        handleChange([
                            { target: { name: "suggestManufacturer", value: false } },
                            { target: { name: "suggestedManufacturer", value: null } },
                        ])
                    }
                    cancelButtonText={getResource(
                        "Library.assetModels.editForm.suggestManufacturer.cancelText",
                        "Cancel suggestion"
                    )}
                    validate={async () => {
                        var manufacturerErrors = validateManufacturer(
                            values.suggestedManufacturer,
                            null,
                            getResource
                        );

                        if (!manufacturerErrors) {
                            const response = await nameExists(values.suggestedManufacturer.name);
                            const exists = await response.json();

                            if (exists) {
                                if (manufacturerErrors === null) {
                                    manufacturerErrors = {};
                                }
                                manufacturerErrors.name = getResource(
                                    "Assets.assetModels.editForm.suggestedManufacturer.nameExists",
                                    "The suggested manufacturer already exists"
                                );
                            }
                        }

                        return manufacturerErrors;
                    }}
                    summaryLabel={getResource(
                        "Library.assetModels.editForm.suggestManufacturer.addedSummaryLabel",
                        "Suggested Manufacturer"
                    )}
                    summaryText={
                        values && values.suggestedManufacturer && values.suggestedManufacturer.name
                    }
                    addButtonText={getResource(
                        "Library.assetModels.editForm.suggestManufacturer.buttonText",
                        "Suggest"
                    )}
                    renderChildren={(validationErrors) => (
                        <EditFormManufacturer
                            errors={validationErrors || (errors && errors["suggestedManufacturer"])}
                            values={values && values.suggestedManufacturer}
                            handleChange={(e) => {
                                var copy = { ...values.suggestedManufacturer };
                                copy[e.target.name] = e.target.value;

                                handleChange({
                                    target: {
                                        name: "suggestedManufacturer",
                                        value: copy,
                                    },
                                });
                            }}
                        />
                    )}
                />
            ) : (
                <AutoCompleteStatic
                    autoFocus
                    errors={errors}
                    name="manufacturerId"
                    label={getResource(
                        "Library.assetModels.editForm.manufacturerField",
                        "Manufacturer"
                    )}
                    isMedium
                    fullWidth
                    value={values && values.manufacturerId}
                    onSelection={(e) => {
                        var events = [e];
                        if (e.target.value) {
                            events.push({
                                target: {
                                    name: "manufacturerName",
                                    value: allManufacturers.items.find(
                                        (x) => x.id === e.target.value
                                    ).name,
                                },
                            });
                        }
                        handleChange(events);
                    }}
                    options={allManufacturers.items}
                    disableClearable
                    handleAddNewItem={() =>
                        handleChange({ target: { name: "suggestManufacturer", value: true } })
                    }
                    addNewItemLabel={getResource(
                        "Library.assetModels.editForm.suggestManufactureLabel",
                        "Suggest manufacturer"
                    )}
                    addNewItemRequiredOperation={
                        companyScope.id || assetCollectionScope.id
                            ? {
                                  name: "manufacturers-suggest",
                                  companyId: companyScope.id,
                                  assetCollectionId: assetCollectionScope.id,
                              }
                            : "manufacturers-suggest"
                    }
                    required
                />
            )}

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="name"
                labelResourceKey="Library.assetModels.editForm.nameField"
                labelDefaultValue="Name"
                type="text"
                fullWidth
                value={(values && values.name) || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
                onChange={(e) => handleChange(e)}
                required
            />

            <DrillDownInput
                errors={errors}
                name="assetCategory"
                itemName={getResource(
                    "Library.assetModels.editForm.assetCategoryItemName",
                    "category"
                )}
                label={getResource("Library.assetModels.editForm.assetCategoryField", "Category")}
                isMedium
                fullWidth
                getParents={getParents}
                getChildren={getChildren}
                getPath={getPath}
                filter={filter}
                value={values && values.assetCategory}
                disableClearable
                setValue={(e) => {
                    handleChange([
                        e,
                        {
                            target: {
                                name: "assetCategoryId",
                                value: e.target.value ? e.target.value.id : null,
                            },
                        },
                        {
                            target: {
                                name: "assetTypeId",
                                value: e.target.value
                                    ? allAssetCategories.find((x) => x.id === e.target.value.id)
                                          .defaultAssetTypeId
                                    : "",
                            },
                        },
                    ]);
                }}
                required
            />

            <AutoCompleteStatic
                errors={errors}
                name="assetTypeId"
                isMedium
                label={getResource("Library.assetModels.editForm.assetTypeField", "Type")}
                fullWidth
                value={values && values.assetTypeId}
                onSelection={(e) => handleChange(e)}
                options={allAssetTypes.items}
                disableClearable={true}
                required
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="emissionImpact"
                labelResourceKey="Library.assetModels.editForm.emissionImpactField"
                labelDefaultValue="Emission impact"
                type="text"
                fullWidth
                value={(values && values.emissionImpact) || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
                onChange={(e) => handleChange(e)}
            />
        </Fragment>
    );
});

export function validate(values, errors, getResource) {
    const setError = (field, message) => {
        if (!errors) {
            errors = {};
        }
        errors[field] = message;
    };

    if (!values || !values.name) {
        setError(
            "name",
            getResource("Library.assetModels.editForm.enterName", "Please enter a name")
        );
    }

    if (values) {
        if (values.suggestManufacturer) {
            const manufacturerErrors = validateManufacturer(
                values.suggestedManufacturer,
                null,
                getResource
            );
            if (manufacturerErrors) {
                setError("suggestedManufacturer", manufacturerErrors);
            }
        } else {
            if (!values.manufacturerId) {
                setError(
                    "manufacturerId",
                    getResource(
                        "Library.assetModels.editForm.enterManufacturer",
                        "Please select a manufacturer"
                    )
                );
            }
        }

        if (!values.assetCategory) {
            setError(
                "assetCategory",
                getResource(
                    "Library.assetModels.editForm.selectAssetCategory",
                    "Please select a category"
                )
            );
        }

        if (!values.assetTypeId) {
            setError(
                "assetTypeId",
                getResource("Library.assetModels.editForm.selectAssetType", "Please select an type")
            );
        }
    }

    return errors;
}
