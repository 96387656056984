import React from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import Details from "./details";
import ChooseAssetModel from "./chooseAssetModel";
import Editor from "../valuation/editor";

const Valuation = () => {
    return [
        <PrivateRoute
            requiredOperationAtLeastOneOf={["model-valuations-create", "model-valuations-prepare"]}
            key="editor"
            path="/valuation/model-valuation/editor/:valuationId(\d+)"
            render={(props) => {
                return <Editor valuationId={props.match.params.valuationId} {...props} />;
            }}
        />,
        <PrivateRoute
            requiredOperationAtLeastOneOf={["model-valuations-create", "model-valuations-prepare"]}
            key="assetModel"
            path="/valuation/model-valuation/:assetModelId(\d+)"
            render={(props) => {
                return <Details {...props} />;
            }}
        />,
        <PrivateRoute
            requiredOperationAtLeastOneOf={["model-valuations-create", "model-valuations-prepare"]}
            key="valuation"
            exact
            path="/valuation/model-valuation"
            render={(props) => {
                return <ChooseAssetModel {...props} />;
            }}
        />,
    ];
};

export default Valuation;
