import React from "react";
import SlotFillContext from "./slotcontext";
import manager from "./manager";

const Machine = (props) => {
    return (
        <SlotFillContext.Provider value={new manager()}>{props.children}</SlotFillContext.Provider>
    );
};

export default Machine;
