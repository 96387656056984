import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Paper, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import Header from "./header";
import animateScrollTo from "animated-scroll-to";
import useStyles from "./useStyles";
import variables from "./variables";

const { itemHeight, itemMargin, ulWrapperAnimationMs } = variables;

const ItemWithChildren = (props) => {
    const { item, parentOfItem, handleToggle, getActions, addLabel } = props;

    const classes = useStyles();

    return (
        <li
            className={clsx(
                classes.item,
                "item",
                item.open && "--is-open",
                item.isActive === false && "--inactive"
            )}
            onClick={(e) => {
                e.stopPropagation();
                handleToggle(e, item, parentOfItem);
            }}
            key={item.id}
        >
            <Typography variant="h6" component="h3" title={item.name} className="name">
                {item.name}
            </Typography>

            <IconButton size="small" color="primary" aria-label="Toggle">
                <ChevronRightIcon />
            </IconButton>

            {item.open ? (
                <Paper className={clsx(classes.ulWrapper, "ulWrapper")}>
                    <ul className="childItems" onClick={(e) => e.stopPropagation()}>
                        <Header
                            parentId={item.id}
                            title={item.name}
                            isActive={item.isActive}
                            addLabel={addLabel}
                            getActions={() =>
                                getActions(280, item, item.id, parentOfItem.id, item.version)
                            }
                        />
                        {item.children &&
                            item.children.map((child) => (
                                <ItemWithChildren
                                    {...props}
                                    item={child}
                                    parentOfItem={item}
                                    key={child.id}
                                />
                            ))}
                    </ul>
                </Paper>
            ) : null}
        </li>
    );
};

const DrillDown = (props) => {
    const {
        itemTree,
        handleToggleItem,
        addButtonRequiredOperation,
        addLabel,
        handleClickAddButton,
    } = props;

    const classes = useStyles();
    const [heightOfHighestItem, setHeightOfHighestItem] = useState(0);
    const drillDownRef = useRef();

    useEffect(() => {
        /**
         * A correct fixed min height needs to be set on the root items level.
         * This min height needs to be the height of the highest item level (since anything other than the root level
         * is positioned absolute).
         */
        const setMinHeightBasedOnHighestItem = () => {
            if (!itemTree.children) {
                return;
            }

            function recurse(items, currentMax) {
                const newMax = items.length > currentMax ? items.length : currentMax;
                const itemWithChildren = items.find((item) => item.children);
                if (itemWithChildren) {
                    return recurse(itemWithChildren.children, newMax);
                }
                return newMax;
            }

            const maxNrOfitems = recurse(itemTree.children, 0);

            const highestHeight =
                maxNrOfitems * (itemHeight + itemMargin) + 100; /** Header plus extra space */
            if (highestHeight !== heightOfHighestItem) {
                setHeightOfHighestItem(highestHeight);
            }
        };

        setMinHeightBasedOnHighestItem();
    }, [itemTree, heightOfHighestItem, setHeightOfHighestItem]);

    useLayoutEffect(() => {
        if (!drillDownRef.current) {
            return;
        }

        const scrollToOpenedItem = () => {
            /** Prevent error in IE */
            if (!Element.prototype.closest) {
                return;
            }

            const mainEl = drillDownRef.current.closest("main");
            const scrollHorizontally = () => {
                return animateScrollTo([10000, null], { elementToScroll: mainEl });
            };

            if (mainEl.scrollTop === 0) {
                scrollHorizontally();
            } else {
                animateScrollTo([null, 0], { elementToScroll: mainEl }).then(
                    (hasScrolledToPosition) => hasScrolledToPosition && scrollHorizontally()
                );
            }
        };

        const scrollToItemTimeout = setTimeout(scrollToOpenedItem, ulWrapperAnimationMs);
        return () => clearTimeout(scrollToItemTimeout);
    }, [itemTree, drillDownRef]);

    const handleToggle = async (e, clickedItem, parentOfClickedItem) => {
        await handleToggleItem(clickedItem, parentOfClickedItem);
    };

    return itemTree.children ? (
        <Paper ref={drillDownRef} className={clsx(classes.ulWrapper, "drilldown", "--root")}>
            <ul className="childItems" style={{ minHeight: `${heightOfHighestItem}px` }}>
                <React.Fragment>
                    <Header
                        parentId={0}
                        addButtonRequiredOperation={addButtonRequiredOperation}
                        addLabel={addLabel}
                        handleClickAddButton={handleClickAddButton}
                    />
                    {itemTree.children.map((item) => (
                        <ItemWithChildren
                            {...props}
                            item={item}
                            parentOfItem={itemTree}
                            handleToggle={handleToggle}
                            key={item.id}
                        />
                    ))}
                </React.Fragment>
            </ul>
        </Paper>
    ) : null;
};

DrillDown.propTypes = {
    getActions: PropTypes.func.isRequired,
    itemTree: PropTypes.object.isRequired,
    handleToggleItem: PropTypes.func.isRequired,
    handleClickAddButton: PropTypes.func.isRequired,
    addButtonRequiredOperation: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    addLabel: PropTypes.string,
};

export default DrillDown;
