import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { remove, get } from "../component/dataservice";
import LocalizationContext from "../../../shared/localization/localizationcontext";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, assetId, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const { data: component } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI(remove);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(assetId, id)) {
            onClose(true);
        }
    };

    var componentName =
        component &&
        component.name &&
        (component.name[selectedCulture] || component.name[defaultCulture]);

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Asset.assetComponents.remove.header", "Remove component?")}
            buttonText={getResource("Asset.assetComponents.remove.okButton", "Remove")}
        >
            {getResource(
                "Asset.assetComponents.remove.message",
                "Are you sure you want to remove {componentName}? After removal the component can be found in the global components list. If the readings of other components are linked to this component, they will be unlinked.",
                { componentName }
            )}
        </Confirmation>
    );
});
