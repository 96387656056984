import React, { Fragment } from "react";
import WorkOrderHeader from "../shared/workOrderHeader";
import ContentHolder from "../../../shared/contentHolder";
import useAPI from "../../../shared/useapi";
import withResources from "../../../shared/textresources/withresources";
import { getReport } from "../workOrder/dataservice";
import Report from "./report";
import { LinearProgress } from "@material-ui/core";
import NotFound from "../../../shared/notFound";
import ActionMenu from "../../../shared/actionmenu";
import {
    GetApp as DownloadIcon
} from "@material-ui/icons";

export default withResources(({ getResource, match }) => {
    const workOrderId = match.params["workOrderId"];
    const { data: workOrder, loading } = useAPI(() => getReport(workOrderId), true);

    return (
        <Fragment>
            {loading && !workOrder && <LinearProgress />}
            {!loading && !workOrder && <NotFound element="work order" />}
            {workOrder && (
                <ContentHolder>
                    <WorkOrderHeader
                        status={workOrder.status}
                        title={getResource(
                            "Maintenance.workOrderReport.title",
                            "Work order report for {summary}",
                            { summary: workOrder.summary }
                        )}
                        renderActions={() => {
                            return (
                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Maintenance.workOrderReport.download",
                                                "Download"
                                            ),
                                            icon: <DownloadIcon />,
                                            itemId: workOrderId,
                                            requiredOperation: "work-orders-view-report",
                                            onClick: () =>
                                                window.open(
                                                    `/api/maintenance/workorder/${workOrderId}/report/download`,
                                                    "_blank"
                                                ),
                                        },
                                    ]}
                                />
                            );
                        }}
                    />
                    <Report workOrder={workOrder} />
                </ContentHolder>
            )}
        </Fragment>
    );
});
