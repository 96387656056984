import React from "react";
import { Section } from "../shell";
import Manufacturers from "./manufacturers";
import AssetAttributeDefinitions from "./assetattributedefinitions";
import AssetTypes from "./assettypes";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssetCategories from "./assetcategories";
import AssetModels from "./assetmodels";
import withResources from "../../shared/textresources/withresources";

import { registerIcon } from "../shell/components/topbar/components/notifications";

registerIcon("proposedManufacturers", AccountBalanceIcon);
registerIcon("proposedAssetModels", AccountBalanceIcon);

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Library.manufacturersTitle", "Manufacturers"),
            href: "/library/manufacturers",
            render: Manufacturers,
            requiredOperation: "manufacturers-view",
        },
        {
            title: getResource("Library.assetModelsTitle", "Models"),
            href: "/library/asset-models",
            render: AssetModels,
            requiredOperation: "asset-models-view",
        },
        {
            title: getResource("Library.assetCategoriesTitle", "Categories"),
            href: "/library/asset-categories",
            render: AssetCategories,
            requiredOperation: "asset-categories-view",
        },
        {
            title: getResource("Library.assetTypesTitle", "Types"),
            href: "/library/asset-types",
            render: AssetTypes,
            requiredOperation: "asset-types-view",
        },
        {
            title: getResource("Library.assetAttributeDefinitionsTitle", "Attribute definitions"),
            href: "/library/asset-attribute-definitions",
            render: AssetAttributeDefinitions,
            requiredOperation: "asset-attribute-definitions-view",
        },
    ];

    return (
        <Section
            key="Library"
            title={getResource("Library.title", "Library")}
            icon={AccountBalanceIcon}
            href="/library"
            children={children}
        />
    );
});
