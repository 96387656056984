import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, id, onClose, title }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Marketplace.marketplacePages.delete.header",
                "Delete page {title}?",
                { title }
            )}
            buttonText={getResource("Marketplace.marketplacePages.delete.okButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Marketplace.marketplacePages.delete.deleteMessage"
                defaultValue="Are you sure you want to delete this page?"
            />
        </Confirmation>
    );
});
