import React from "react";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    LinearProgress,
} from "@material-ui/core";

import { Delete as DeleteIcon, Visibility as ViewIcon } from "@material-ui/icons";

import { Slot } from "../../../shared/slotmachine/index";
import ActionMenu from "../../../shared/actionmenu";
import PagedList from "../../../shared/pagedList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import FormattedDate from "../../../shared/formattedDate";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        quickScan,
        getRequiredOperation,
        history,
        match: {
            params: { quickScanId },
        },
        onPageChange,
        onPageSizeChange,
        onPurchase,
        onDelete,
    }) => {
        data = data || {};
        const items = data.items;

        var isLoaded = data && quickScan;
        var assetName =
            quickScan &&
            (quickScan.assetName || `${quickScan.manufacturerName} / ${quickScan.assetModelName}`);

        return !isLoaded ? (
            <LinearProgress />
        ) : (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Valuation.financialReports.overview.header.title",
                        "Financial reports for {name} based on quick scan {reference}",
                        { name: assetName, reference: quickScan.reference }
                    )}
                    breadcrumb={quickScan.categories}
                    renderActions={() => (
                        <AuthorizedButton
                            color="primary"
                            variant="contained"
                            onClick={onPurchase}
                            requiredOperation={getRequiredOperation("financial-reports-purchase")}
                            buttonText={getResource(
                                "Valuation.financialReports.overview.purchaseButton",
                                "Purchase"
                            )}
                        />
                    )}
                />
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <PagedList
                            loading={loading}
                            itemCount={data.total}
                            noResultsText={getResource(
                                "Valuation.financialReports.overview.noResults",
                                "No financial reports available for this quick scan"
                            )}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            availablePageSizes={availablePageSizes}
                            page={searchParams.page}
                            pageSize={searchParams.pageSize}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Valuation.financialReports.overview.purchasedDate"
                                                defaultValue="Purchased date"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Valuation.financialReports.overview.purchasedBy"
                                                defaultValue="Purchased by"
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <ResourcedText
                                                resourceKey="Valuation.financialReports.overview.actionsHeader"
                                                defaultValue="Actions"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items &&
                                        items.map((row) => (
                                            <AuthorizedTableRow
                                                id={row.id}
                                                hover
                                                key={row.id}
                                                handleClick={() =>
                                                    history.push(
                                                        `/valuation/financial-report/${quickScanId}/${row.id}`
                                                    )
                                                }
                                                requiredOperation={getRequiredOperation(
                                                    "financial-reports-view"
                                                )}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <FormattedDate date={row.purchasedDate} />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.purchasedBy}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ActionMenu
                                                        actions={[
                                                            {
                                                                text: getResource(
                                                                    "Valuation.financialReports.overview.openReportButton",
                                                                    "Open report"
                                                                ),
                                                                icon: <ViewIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "financial-reports-view"
                                                                ),
                                                                onClick: () =>
                                                                    history.push(
                                                                        `/valuation/financial-report/${quickScanId}/${row.id}`,
                                                                        searchParams
                                                                    ),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Valuation.financialReports.overview.deleteButton",
                                                                    "Delete"
                                                                ),
                                                                icon: <DeleteIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "financial-reports-delete"
                                                                ),
                                                                onClick: () =>
                                                                    onDelete({
                                                                        id: row.id,
                                                                        purchasedDate:
                                                                            row.purchasedDate,
                                                                    }),
                                                            },
                                                        ]}
                                                    />
                                                </TableCell>
                                            </AuthorizedTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </PagedList>
                    </Grid>
                    <Grid item xs={3}>
                        <Slot
                            name="asset-summary"
                            list={true}
                            asset={{
                                companyName: quickScan.companyName,
                                manufacturerName: quickScan.manufacturerName,
                                assetModel: {
                                    name: quickScan.assetModelName,
                                },
                                constructionDate: quickScan.constructionDate,
                                id: quickScan.assetId,
                            }}
                        />
                    </Grid>
                </Grid>
            </ContentHolder>
        );
    }
);

export default Overview;
