import React from "react";
import { Section } from "../shell";
import ContentPages from "./contentpages";
import Configuration from "./configuration";
import Page from "./page";

import StorageIcon from "@material-ui/icons/Storage";
import withResources from "../../shared/textresources/withresources";

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Content.contentPagesTitle", "Content pages"),
            href: "/content/content-pages",
            render: ContentPages,
            requiredOperation: "content-pages-view",
        },
        {
            title: getResource("Content.configurationTitle", "Configuration"),
            href: "/content/configuration",
            render: Configuration,
            requiredOperation: "content-configuration-edit",
        },
    ];

    return [
        <Section
            key="Content"
            title={getResource("Content.title", "Content")}
            icon={StorageIcon}
            href="/content"
            children={children}
        />,
        <Section
            key="Page"
            href="/:culture([a-z]{2})/:slug"
            children={[
                {
                    render: Page,
                    href: "/:culture([a-z]{2})/:slug",
                    hideInMenu: true,
                },
            ]}
        />,
    ];
});
