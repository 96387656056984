import React, { useContext, useState, useEffect, Fragment } from "react";
import { Route, Switch } from "react-router";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useLocalStorage from "../../../shared/uselocalstorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import Add from "./add";
import Edit from "./edit";
import Detail from "./detail";
import Delete from "./delete";
import DeleteMultiple from "./deleteMultiple";

import { getAll, get, getStatuses } from "./dataservice";

import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";
import invalidatedBy from "../../../shared/invalidation/invalidatedby";
import NotFound from "../../../shared/notFound";

const List = invalidatedBy("component-list", (_) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [selectedComponents, setSelectedComponents] = useState([]);

    const [showThumbnails, setShowThumbnails] = useLocalStorage("show-thumbnails-components", false);

    const [searchParams, setSearchParams] = useSessionStorage("search-component", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        statuses: [],
        order: "Default"
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.statuses,
                null,
                searchParams.page + 1,
                searchParams.pageSize,
                null,
                null,
                null,
                searchParams.order
            ),
        true,
        [
            searchParams,
            searchParams.order,
            companyScope.id
        ]);

    const { data: allStatuses } = useAPI(() => getStatuses(), true);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleDeleteSelected,
        close: handleCloseDeleteSelected,
        visible: deleteSelectedToggled,
        args: deleteSelectedIds,
    } = useDialog(async (args) => {
        if (await invoke(args)) {
            setSelectedComponents([]);
        }
    });

    const handleSearch = (searchText, statuses, _, order) => {
        setSearchParams({ ...searchParams, searchText, statuses, order, page: 0 });
        setSelectedComponents([]);
    };

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const handleSelectComponent = (e) => {
        var componentId = Number(e.target.name);
        var selected = e.target.checked;

        var newSelections = [...selectedComponents];
        newSelections = handleComponentSelect(newSelections, componentId, selected);
        setSelectedComponents(newSelections);
    };

    const handleComponentSelect = (selections, componentId, itemSelected) => {
        if (!itemSelected) {
            selections = selections.filter((id) => id !== componentId);
        } else if (selections.filter((id) => id === componentId).length === 0) {
            selections.push(componentId);
        }
        return selections;
    };

    const handleSelectAllComponents = () => {
        if (!data || !data.items) {
            return;
        }
        var newSelections = [...selectedComponents];
        for (var componentIndex in data.items) {
            newSelections = handleComponentSelect(newSelections, data.items[componentIndex].id, true);
        }
        setSelectedComponents(newSelections);
    };

    const handleDeselectAllComponents = () => {
        var newSelections = [...selectedComponents];
        for (var componentIndex in data.items) {
            newSelections = handleComponentSelect(newSelections, data.items[componentIndex].id, false);
        }
        setSelectedComponents(newSelections);
    };

    useEffect(() => setSelectedComponents([]), [companyScope.id]);

    const selectedComponentsInfo = data && data.items && selectedComponents.map(id => {
        var component = data && data.items && data.items.find(x => x.id == id);
        return component && {
            id,
            isInstalled: component.status == "Installed",
            isSealed: component.isSealed
        }
    }).filter(x=>x != null);

    return (
        <PrivateRoute
            key={"component-overview"}
            requiredOperation={"components-view"}
            path="/assets/components"
            exact
            render={(props) => (
                <Fragment>
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onDeleteSelected={handleDeleteSelected}
                        defaultDebounceInterval={defaultDebounceInterval}
                        allStatuses={allStatuses}
                        showThumbnails={showThumbnails}
                        setShowThumbnails={setShowThumbnails}
                        selectedComponents={selectedComponents}
                        selectedComponentsInfo={selectedComponentsInfo}
                        onSelectComponent={handleSelectComponent}
                        onSelectAllComponents={handleSelectAllComponents}
                        onDeselectAllComponents={handleDeselectAllComponents}
                        {...props}
                    />

                    <Add open={addToggled} onClose={handleCloseAdd} allStatuses={allStatuses} />
                    <Edit
                        open={editToggled}
                        id={editId}
                        onClose={handleCloseEdit}
                        allStatuses={allStatuses}
                    />
                    <Delete
                        open={deleteToggled}
                        id={deleteArgs && deleteArgs.id}
                        onClose={handleCloseDelete}
                        isInstalled={deleteArgs && deleteArgs.isInstalled}
                        isSealed={deleteArgs && deleteArgs.isSealed}
                    />

                    <DeleteMultiple open={deleteSelectedToggled} components={selectedComponentsInfo} onClose={handleCloseDeleteSelected} />
                </Fragment>
            )}
        />
    );
});

const Details = (props) => {
    const id = props.match.params.id;

    const { invoke: loadComponent, data: component, loading } = useAPI(() => get(id), true, [id]);

    const { data: allStatuses } = useAPI(() => getStatuses(), true);

    const refresh = () => {
        loadComponent(id);
    };

    return (
        <Fragment>
            {component && (
                <PrivateRoute
                    requiredOperation={{
                        name: "components-view",
                        companyId: component.companyId,
                        assetCollectionId: component.assetCollectionIds,
                    }}
                    path="/assets/components/:id(\d+)"
                    render={(props) => (
                        <Detail
                            component={component}
                            onRefresh={refresh}
                            allStatuses={allStatuses}
                            {...props}
                        />
                    )}
                />
            )}
            {!loading && !component && <NotFound element="component" />}
        </Fragment>
    );
};

export default () => {
    return (
        <Switch>
            <Route path="/assets/components/:id(\d+)" render={(props) => <Details {...props} />} />
            <Route path="/assets/components" render={(props) => <List {...props} />} />
        </Switch>
    );
};
