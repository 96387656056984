import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";
import HighchartsMore from "highcharts-more";
import HighchartsExporting from "highcharts-exporting";
import HighchartsDraggable from "../chart/draggable.custom.js";
import DateHelper from "../helpers/datehelper.js";

HighchartsMore(ReactHighcharts.Highcharts);
HighchartsExporting(ReactHighcharts.Highcharts);

// the HighCharts throws this console error: Warning: Setting defaultProps as an instance property on HighchartsChart is not supported and will be ignored. Instead, define defaultProps as a static property on HighchartsChart.
// This looks like a persistent error in the package, and hasn't been fixed as of 15-12-2020.

class Highcharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopulated: false,
        };

        HighchartsDraggable(ReactHighcharts.Highcharts, this.onUpdateDataTable.bind(this));
    }

    adjustBetweenPoints() {
        let chart = this.refs.highcharts.getChart();
        chart.adjustBetweenPoints();
    }

    onUpdateDataTable() {
        this.props.onUpdateDataTable();
    }

    componentDidMount() {
        let chart = this.refs.highcharts.getChart();
        chart.showLoading();
    }

    componentDidUpdate(nextProps) {
        let chart = this.refs.highcharts.getChart();

        if (nextProps.config.seriesFetchCounter === 2 && !this.state.isPopulated) {
            let dh = new DateHelper();

            // create xAxis
            let categories = this.createXaxis(nextProps.config.valuationInfo.technicalLife);
            chart.xAxis[0].update({
                tickInterval: 2,
                categories: categories,
            });

            // add series data
            for (let i = 0; i <= 1; i++) {
                for (let j = 0; j < nextProps.config.series[i].data.length; j++) {
                    const item = nextProps.config.series[i].data[j];
                    chart.series[i].addPoint(
                        { x: j, y: item, buildYear: dh.getBuildYear(j) },
                        false
                    );
                }
            }

            nextProps.config.series[2].data.map((item, index) => {
                return chart.series[2].addPoint(
                    {
                        x: Number(item[0]),
                        y: Number(item[1]),
                        buildYear: "Build: " + item[2] + " - Purchased: " + item[3],
                    },
                    false
                );
            });

            if (nextProps.config.series[2].data.length > 0) this.setExtremes(nextProps);

            chart.hideLoading();
            chart.setTitle({ text: nextProps.config.title.text });
            chart.redraw();

            let currState = this.state;
            currState.isPopulated = true;

            this.setState(currState);
        } else if (nextProps.config.series[0].data.length > 0) {
            this.setExtremes(nextProps);
        }
    }

    setExtremes(nextProps) {
        let chart = this.refs.highcharts.getChart();

        // set extremes that fall out of the current graph view
        let max1 = Math.max.apply(null, nextProps.config.series[0].data);
        let max2 = Math.max.apply(null, nextProps.config.series[1].data);
        let min1 = Math.min.apply(null, nextProps.config.series[0].data);
        let min2 = Math.min.apply(null, nextProps.config.series[1].data);

        if (nextProps.config.series[2].data.length > 0) {
            let max3 = Math.max.apply(
                null,
                nextProps.config.series[2].data.map((item) => {
                    return Number(item[1]);
                })
            );
            let min3 = Math.min.apply(
                null,
                nextProps.config.series[2].data.map((item) => {
                    return Number(item[1]);
                })
            );
            chart.yAxis[0].setExtremes(Math.min(min1, min2, min3), Math.max(max1, max2, max3));
        } else {
            chart.yAxis[0].setExtremes(Math.min(min1, min2), Math.max(max1, max2));
        }
    }

    redrawChart(config) {
        let chart = this.refs.highcharts.getChart();

        for (let i = 0; i <= 2; i++) {
            for (let j = 0; j < config.series[i].data.length; j++) {
                const item = config.series[i].data[j];
                chart.series[i].data[j].update(item);
            }
        }
    }

    createXaxis(count) {
        let categories = [];

        for (let i = 0; i <= count * 2; i++) {
            categories.push(i / 2);
        }

        return categories;
    }

    render() {
        return <ReactHighcharts config={this.props.config} isPureConfig ref="highcharts" />;
    }
}

export default Highcharts;
