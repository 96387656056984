import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";

export default withResources(({ errors, values, handleChange }) => {
    return (
        <ResourcedTextField
            errors={errors}
            autoFocus
            margin="normal"
            name="name"
            labelResourceKey="Library.manufacturers.editForm.nameField"
            labelDefaultValue="Name"
            type="text"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 255 }}
            value={(values && values.name) || ""}
            onChange={(e) => handleChange(e)}
            required
        />
    );
});

export function validate(values, errors, getResource) {
    const setError = (field, message) => {
        if (!errors) {
            errors = {};
        }
        errors[field] = message;
    };

    if (!values || !values.name) {
        setError(
            "name",
            getResource("Library.manufacturers.editForm.enterName", "Please enter a name")
        );
    }

    return errors;
}
