import React, { useState } from "react";
import { Grid, IconButton, Slider, Typography } from "@material-ui/core";
import useStyles from "./useStyles";
import NrInput from "../nrInput";
import FormattedPercentage from "../../../../../shared/formattedPercentage";
import clsx from "clsx";
import { Visibility as VisibleIcon, VisibilityOff as InVisibleIcon } from "@material-ui/icons";

const Answer = (props) => {
    const {
        answer,
        answerIndex,
        questionIndex,
        onAnswerSliderChange,
        onAnswerVisibleChange,
        marksPositive,
        disabled,
    } = props;

    const classes = useStyles();
    const labelId = `answer-slider-${questionIndex}-${answerIndex}`;

    const [value, setValue] = useState(answer.score);

    const handleBlur = () => {
        let correctValue = value;
        if (value < 0 || value === "") {
            correctValue = 0;
        } else if (value > 100) {
            correctValue = 100;
        }
        setValue(correctValue);
        onAnswerSliderChange(questionIndex, answerIndex, correctValue);
    };

    const toggleVisibility = () => {
        onAnswerVisibleChange(questionIndex, answerIndex, !answer.visible);
        if (!value) {
            setValue(0);
        }
    };

    return (
        <Grid container className={classes.grid}>
            <Grid item xs={5}>
                <div className={classes.questionItem}>
                    {answer.visible ? (
                        <IconButton
                            className={clsx("e2e_visibilityIcon", classes.visibilityIcon)}
                            onClick={toggleVisibility}
                            disabled={disabled}
                        >
                            <VisibleIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            className={clsx(
                                "e2e_visibilityIcon",
                                classes.visibilityIcon,
                                "--invisible"
                            )}
                            onClick={toggleVisibility}
                            disabled={disabled}
                        >
                            <InVisibleIcon />
                        </IconButton>
                    )}

                    <label
                        htmlFor={labelId}
                        className={clsx(classes.label, !answer.visible && "--invisible")}
                    >
                        {answer.answer}
                        {answer.visible && answer.answerCorrection !== null && (
                            <Typography
                                variant={"body1"}
                                component="span"
                                className={classes.correctionScore}
                                title="Answer correction score"
                            >
                                <FormattedPercentage value={answer.answerCorrection} decimals={2} />
                            </Typography>
                        )}
                    </label>
                </div>
            </Grid>
            <Grid item xs={6} className={classes.sliderItem}>
                {answer.visible && (
                    <Slider
                        value={value === "" ? 0 : value}
                        step={5}
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                        marks={marksPositive}
                        onChange={(e, newValue) => setValue(newValue)}
                        onChangeCommitted={(e, newValue) =>
                            onAnswerSliderChange(questionIndex, answerIndex, newValue)
                        }
                        disabled={disabled}
                    />
                )}
            </Grid>
            <Grid item xs={1} className={classes.inputItem}>
                {answer.visible && (
                    <NrInput
                        value={value}
                        onChange={(e) =>
                            e.target.value === ""
                                ? setValue(e.target.value)
                                : setValue(Number(e.target.value))
                        }
                        onBlur={handleBlur}
                        inputAttributes={{
                            step: 1,
                            min: 0,
                            max: 100,
                            type: "number",
                            id: labelId,
                        }}
                        disabled={disabled}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default Answer;
