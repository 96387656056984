import React, { useState, useEffect } from "react";

function useIsFirstRun() {
    const [isFirstRun, setIsFirstRun] = useState(true)

    useEffect(() => {
        setIsFirstRun(false)
    }, [])
    return isFirstRun;
}

export default useIsFirstRun;
