import React, { useContext } from "react";
import {
    FormControlLabel,
    Checkbox,
    LinearProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
} from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { moveAllAssetCollection } from "./dataservice";
import { getWhereUserHasOperation as getAssetCollections } from "../assetCollection/dataservice";

import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useForm from "../../../shared/forms/useform";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { assetCollectionScope } = useContext(WorkingScopeContext);
    const { data: allAssetCollections } = useAPI(
        () => getAssetCollections(["assets-assign-asset-collection"]),
        true
    );

    const { loading, invoke } = useAPI(moveAllAssetCollection);

    const handleMove = async (input) => {
        if (await invoke(input.values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.newAssetCollectionId) {
            errors.newAssetCollectionId = getResource(
                "Asset.asset.moveAllAssetCollection.newAssetCollectionId",
                "You have to select a new collection"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { trackMovement: true },
        onSubmit: handleMove,
        validate: (values) => onValidate(values),
    });

    var isLoaded = allAssetCollections;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <React.Fragment>
                    <DialogTitle id="alert-dialog-title">
                        {getResource(
                            "Asset.asset.moveAllAssetCollection.header",
                            "Move all assets?"
                        )}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getResource(
                                "Asset.asset.moveAssetCollection.body",
                                "Moving these assets to a collection will remove them from the {currentAssetCollectionName} collection.",
                                { currentAssetCollectionName: assetCollectionScope.name }
                            )}
                        </DialogContentText>

                        <AutoCompleteStatic
                            errors={errors}
                            name="newAssetCollectionId"
                            label={getResource(
                                "Asset.asset.moveAllAssetCollection.newAssetCollectionField",
                                "Target collection"
                            )}
                            fullWidth
                            isMedium
                            value={values.newAssetCollectionId}
                            onSelection={(e) => handleChange(e)}
                            options={allAssetCollections}
                            disableClearable
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(values.trackMovement)}
                                    onChange={(e) => handleChange(e)}
                                    name="trackMovement"
                                />
                            }
                            label={getResource(
                                "Asset.asset.moveAllAssetCollection.trackMovementField",
                                "Track movement"
                            )}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} autoFocus>
                            {getResource(
                                "Asset.asset.moveAllAssetCollection.cancelButtonText",
                                "Cancel"
                            )}
                        </Button>
                        <Button disabled={loading} onClick={handleSubmit} color="primary">
                            {getResource("Asset.asset.moveAllAssetCollection.okButton", "Move")}
                            {loading && <CircularProgress />}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
});
