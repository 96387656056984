import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withresources from "../../../shared/textresources/withresources";
import { makeStyles } from "@material-ui/styles";
import FormattedDate, { formatDate } from "../../../shared/formattedDate";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import Status from "../../../shared/status/index";

const useStyles = makeStyles((theme) => ({
    description: {
        margin: 0,
        maxWidth: "30vw",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: ".9em",
        color: theme.typography.body2.color,
    },
}));

const PagedListContent = withresources(
    ({
        getResource,
        items,
        handleRowClick,
        getRequiredOperationForRowClick,
        renderActionMenu,
        renderSelectButton,
        isAssetComponentList,
        showThumbnails,
        sortedColumnHeader,
        renderSelectComponent,
        renderSelectAllComponents,
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        const classes = useStyles();

        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {renderSelectAllComponents && renderSelectAllComponents()}

                        {showThumbnails && <TableCell
                            style={{ width: 75 }} />}

                        {sortedColumnHeader && (
                            <TableCell component="th" scope="col">
                                <ResourcedText
                                    resourceKey={`Asset.components.overview.${sortedColumnHeader}Header`}
                                    defaultValue={sortedColumnHeader}
                                />
                            </TableCell>)
                        }
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.nameHeader"
                                defaultValue="Name"
                            />
                        </TableCell>
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.constructionDateHeader"
                                defaultValue="Construction date"
                            />
                        </TableCell>
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.modelTypeHeader"
                                defaultValue="Model/type"
                            />
                        </TableCell>
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.identificationHeader"
                                defaultValue="Identification"
                            />
                        </TableCell>
                        {!isAssetComponentList && (
                            <TableCell component="th" scope="col">
                                <ResourcedText
                                    resourceKey="Asset.components.overview.statusHeader"
                                    defaultValue="Status"
                                />
                            </TableCell>
                        )}
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.positionHeader"
                                defaultValue="Position"
                            />
                        </TableCell>
                        <TableCell component="th" scope="col">
                            <ResourcedText
                                resourceKey="Asset.components.overview.sealedHeader"
                                defaultValue="Sealed"
                            />
                        </TableCell>
                        <TableCell component="th" scope="col" align="right">
                            {renderActionMenu && (
                                <ResourcedText
                                    resourceKey="Asset.components.overview.actionsHeader"
                                    defaultValue="Actions"
                                />
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items &&
                        items.map((row) => (
                            <AuthorizedTableRow
                                id={row.id}
                                hover
                                key={row.id}
                                handleClick={() => handleRowClick(row)}
                                requiredOperation={getRequiredOperationForRowClick(row)}
                            >
                                {renderSelectComponent && renderSelectComponent(row)}

                                {showThumbnails && <TableCell>
                                    {(row.componentMediaId ? <img
                                        src={`/api/assets/component/${row.id}/media/generic/${row.componentMediaId}/view?imageSize=OverviewThumbnail&v=${row.componentMediaFileId}`}
                                        alt=""
                                        draggable="false"
                                    /> : <img
                                        src={`/images/notavailable?imageSize=OverviewThumbnail`}
                                        alt=""
                                        draggable="false"
                                    />)}
                                </TableCell>}

                                {sortedColumnHeader &&
                                    <TableCell>
                                        {row.sortedField}
                                    </TableCell>
                                }
                                <TableCell component="td">
                                    {row.name}
                                    {row.description && (
                                        <p className={classes.description}>{row.description}</p>
                                    )}
                                </TableCell>
                                <TableCell component="td">
                                    <FormattedDate date={row.constructionDate} />
                                </TableCell>
                                <TableCell component="td">{row.modelType}</TableCell>
                                <TableCell component="td">{row.identification}</TableCell>
                                {!isAssetComponentList && (
                                    <TableCell component="td">
                                        {getResource(
                                            `Assets.component.statuses.${row.status.toLowerCase()}`,
                                            row.status
                                        )}
                                    </TableCell>
                                )}
                                <TableCell component="td">
                                    {row.status === "Installed"
                                        ? !isAssetComponentList
                                            ? getResource(
                                                "Asset.components.overview.positionInstalledColumn",
                                                "Installed {installedOn}{assetName}{position}{by}",
                                                {
                                                    ...row,
                                                    installedOn: formatDate(
                                                        row.installedOn,
                                                        selectedUiCulture,
                                                        selectedTimezone,
                                                        true
                                                    ),
                                                    assetName: row.installedAssetName
                                                        ? getResource(
                                                            "Asset.components.overview.installedAtAssetName",
                                                            " on {installedAssetName}",
                                                            row
                                                        )
                                                        : "",
                                                    position: row.installationPosition
                                                        ? getResource(
                                                            "Asset.components.overview.installationPosition",
                                                            " in/at {installationPosition}",
                                                            row
                                                        )
                                                        : "",
                                                    by: row.installedBy
                                                        ? getResource(
                                                            "Asset.components.overview.installedBy",
                                                            " by {installedBy}",
                                                            row
                                                        )
                                                        : "",
                                                }
                                            )
                                            : getResource(
                                                "Asset.assetComponents.overview.positionColumn",
                                                "Installed on {installedOn}{position}{by}",
                                                {
                                                    ...row,
                                                    installedOn: formatDate(
                                                        row.installedOn,
                                                        selectedUiCulture,
                                                        selectedTimezone,
                                                        true
                                                    ),
                                                    position: row.installationPosition
                                                        ? getResource(
                                                            "Asset.components.overview.installationPosition",
                                                            " in/at {installationPosition}",
                                                            row
                                                        )
                                                        : "",
                                                    by: row.installedBy
                                                        ? getResource(
                                                            "Asset.components.overview.installedBy",
                                                            " by {installedBy}",
                                                            row
                                                        )
                                                        : "",
                                                }
                                            )
                                        : row.status === "Storage" && row.storagePosition
                                            ? getResource(
                                                "Asset.components.overview.positionStorageColumn",
                                                "Stored at {storagePosition}",
                                                { ...row }
                                            )
                                            : ""}
                                </TableCell>
                                <TableCell component="td">
                                    {row.isSealed ? (
                                        <Status text="Sealed" color="red" />
                                    ) : (
                                        <Status text="Not sealed" color="green" />
                                    )}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    style={{ width: renderSelectButton ? 136 : "auto" }}
                                >
                                    {renderActionMenu && renderActionMenu(row)}
                                    {renderSelectButton && renderSelectButton(row.id)}
                                </TableCell>
                            </AuthorizedTableRow>
                        ))}
                </TableBody>
            </Table>
        );
    }
);

export default PagedListContent;
