import React, { useRef, useState } from "react";
import withResources from "../textresources/withresources";
import AddButton from "../addButton";
import MediaItem from "./mediaItem";
import PropTypes from "prop-types";

/**
 * The MediaEditor can also be used to display a single media item (image) and description by passing
 * the singleMediaItem prop. To keep the component simple though, values always has to be an array.
 */
const MediaEditor = withResources((props) => {
    const {
        getResource,
        name,
        values,
        onChange,
        errors,
        disabled,
        uploadUrl,
        culture,
        getDownloadUrl,
        singleMediaItem,
    } = props;
    const addButtonRef = useRef(null);
    const multiLingual = Boolean(culture);
    const [expandedTaskIndexes, setExpandedTaskIndexes] = useState([]);

    const handleChange = (values) => {
        onChange({
            target: {
                name: name,
                value: values,
            },
        });
    };

    const handleAccordionToggle = (isExpanded, indexOfTask) => {
        if (isExpanded) {
            const newExpandedIndexes = [...expandedTaskIndexes].filter((i) => i !== indexOfTask);
            setExpandedTaskIndexes(newExpandedIndexes);
        } else {
            setExpandedTaskIndexes([...expandedTaskIndexes, indexOfTask]);
        }
    };

    const handleDelete = (index) => {
        const copy = [...values.slice(0, index), ...values.slice(index + 1)];
        handleChange(copy);
    };

    const handleFileDelete = (index) => {
        const copy = [...values];
        copy[index].fileId = null;
        copy[index].fileName = null;
        handleChange(copy);
    };

    const handleFileUpload = (file, index) => {
        let fileId = "";
        let extension = "";
        let fileNameWithoutExtension = "";
        let validFileName = "";

        if (file) {
            const indexOfExtension = file.name.lastIndexOf(".");
            extension = file.name.substring(indexOfExtension);
            fileNameWithoutExtension = file.name.substring(0, indexOfExtension);
            validFileName = fileNameWithoutExtension.replace(/[\\/:"*?<>|]+/);
            fileId = file.id;
        }

        const copy = [...values];
        copy[index].fileId = fileId;
        copy[index].fileName = validFileName + extension;
        handleChange(copy);
    };

    const handleDescriptionChange = (e, index) => {
        const copy = [...values];

        if (multiLingual) {
            copy[index].description[culture] = e.target.value;
            handleChange(copy);
        } else {
            copy[index].description = e.target.value;
            handleChange(copy);
        }
    };

    return (
        <div>
            {values &&
                values.map((media, i) => {
                    const isExpanded = expandedTaskIndexes.includes(i);
                    return (
                        <div key={i}>
                            <MediaItem
                                index={i}
                                isExpanded={isExpanded}
                                media={media}
                                multiLingual={multiLingual}
                                culture={culture}
                                disabled={disabled}
                                errors={errors}
                                uploadUrl={uploadUrl}
                                getDownloadUrl={getDownloadUrl}
                                singleMediaItem={singleMediaItem}
                                handleDelete={handleDelete}
                                handleAccordionToggle={handleAccordionToggle}
                                handleFileUpload={handleFileUpload}
                                handleDescriptionChange={handleDescriptionChange}
                                handleFileDelete={handleFileDelete}
                            />
                        </div>
                    );
                })}

            {!singleMediaItem && (
                <AddButton
                    disabled={disabled}
                    buttonRef={addButtonRef}
                    buttonText={getResource("Media.mediaEditor.addLinkText", "Add media")}
                    handleClick={() => {
                        const copy = values.concat([
                            {
                                fileId: "",
                                fileName: "",
                                description: multiLingual ? { [culture]: "" } : "",
                            },
                        ]);
                        handleChange(copy);
                        setExpandedTaskIndexes([copy.length - 1]);
                    }}
                />
            )}
        </div>
    );
});

MediaEditor.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    errors: PropTypes.array,
    disabled: PropTypes.bool,
    uploadUrl: PropTypes.string,
    culture: PropTypes.string,
    getDownloadUrl: PropTypes.func,
    singleMediaItem: PropTypes.bool,
};

export default MediaEditor;

export function validateMedia(values, name, errors, getResource) {
    const setMediaError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((media, i) => {
        if (!media.fileId) {
            setMediaError(
                i,
                "fileId",
                getResource("Media.mediaEditor.fileIdRequired", "Please upload a file")
            );
        }
    });
}
