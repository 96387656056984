import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import Batch from "./batch";
import AllAssetsIndex from "./allAssetsIndex";
import AssetIndex from "./assetIndex";

export default () => {
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    return [
        <PrivateRoute
            key="all-asset-quick-scans-view"
            exact
            requiredOperation="asset-quick-scans-view"
            path="/quick-scan/asset"
            render={(props) => <AllAssetsIndex {...props} />}
        />,
        <PrivateRoute
            key="asset-quick-scans-view"
            exact
            requiredOperation="asset-quick-scans-view"
            path="/quick-scan/asset/:assetId(\d+)"
            render={(props) => <AssetIndex {...props} />}
        />,
        <PrivateRoute
            key="asset-quick-scans-purchase-batch"
            exact
            requiredOperation={{
                name: "asset-quick-scans-purchase-batch",
                companyId: companyScope.id,
                assetCollectionId: assetCollectionScope.id,
            }}
            path="/quick-scan/asset/batch"
            render={(props) => <Batch {...props} />}
        />,
    ];
};
