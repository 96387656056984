import React, { Fragment, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Typography, Link, IconButton } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { Slot, Fill } from "../../../../shared/slotmachine/index";
import Navigation from "./navigation";
import AuthenticationContext from "../../../../shared/authentication/authenticationcontext";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import { Close as CloseIcon } from "@material-ui/icons";

const NavLink = ({ open, title, href, icon, pages }) => {
    return (
        <Fill name="nav-link">
            <Navigation
                component="div"
                open={open}
                key={title}
                href={href}
                icon={icon}
                pages={pages}
                title={title}
            />
        </Fill>
    );
};

export { NavLink };

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        overflowY: "auto",
    },
    content: {
        padding: theme.spacing(2),
    },
    mobileMenuClose: {
        position: "absolute",
        top: 6,
        right: 9,

        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },

    profile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        minHeight: 62,
    },
    divider: {
        marginTop: 15,
    },
    navigation: {
        marginTop: theme.spacing(2),
    },
}));

const closeMobileMenuIfNeeded = (e, onMobileClose) => {
    if (e.target.tagName === "A" || e.target.parentNode.tagName === "A") {
        onMobileClose();
    }
};

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
    const { isAuthenticated, loggedInUser } = useContext(AuthenticationContext);
    const classes = useStyles();

    const navbarContent = (
        <div className={classes.content}>
            <IconButton
                onClick={onMobileClose}
                className={classes.mobileMenuClose}
                disableRipple
                size="small"
            >
                <CloseIcon />
            </IconButton>
            {isAuthenticated && (
                <div className={classes.profile}>
                    <Typography className={classes.name} variant="h4" id="e2e_loggedInUserName">
                        {loggedInUser.firstName} {loggedInUser.lastName}
                    </Typography>
                    <Typography variant="body2">{loggedInUser.email}</Typography>
                    <Link
                        align="center"
                        component={RouterLink}
                        to="/profile"
                        underline="always"
                        variant="body2"
                    >
                        <ResourcedText
                            resourceKey="Security.profile.editProfileLink"
                            defaultValue="Edit profile"
                        />
                    </Link>
                </div>
            )}
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
                <Slot
                    name="nav-link"
                    list={true}
                    onClick={(e) => closeMobileMenuIfNeeded(e, onMobileClose)}
                />
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
                    <div {...rest} className={clsx(classes.root, className)}>
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
