import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import { getAll as getAllAdvertisements } from "../advertisements/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./components/editform";
import DuplicateWarningDialog from "../advertisements/components/duplicateWarningDialog";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const [duplicateWarningVisible, showDuplicateWarning] = useState(false);

    const { data: advertisement } = useAPI(() => get(id), true);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onUpdate = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onSubmit = async (values) => {
        if (advertisement.type === values.values.type) {
            await onUpdate(values);
            return;
        }

        const response = await getAllAdvertisements(
            null,
            values.values.type,
            null,
            values.values.assetId
        );

        const advertisementsForAsset = await response.json();

        if (advertisementsForAsset.total > 0) {
            showDuplicateWarning(true);
        } else {
            await onUpdate(values);
        }
    };

    const [isDirty, setIsDirty] = useState(false);

    return (
        <FormDrawer
            title={getResource("Marketplace.auctions.edit.title", "Edit auction")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!advertisement ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={advertisement}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Marketplace.auctions.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={(values, cultures, defaultCulture) =>
                        validate(values, defaultCulture, getResource)
                    }
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => (
                        <React.Fragment>
                            <EditForm
                                culture={culture}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                updateValues={updateValues}
                                isDefaultCulture={isDefaultCulture}
                            />

                            <DuplicateWarningDialog
                                open={duplicateWarningVisible}
                                onClose={() => showDuplicateWarning(false)}
                                onAction={() => onUpdate({ values: values })}
                                type={values.type}
                                isEdit={true}
                            />
                        </React.Fragment>
                    )}
                />
            )}
        </FormDrawer>
    );
});
