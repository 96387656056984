/**
 * Open the item and add children if it has any
 */
const openTheItem = async (tree, itemId, getChildren) => {
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];

        if (item.id === itemId) {
            item.open = true;

            if (item.hasChildren) {
                const children = await getChildren(itemId);
                item.children = await children.json();
            }
        }
    }
};

/**
 * Traverse up from the item level, till the root level is reached and give back the created tree
 */
const getNewTreeFromItemId = async (itemId, getParents, getChildren) => {
    const allParents = await getParents(itemId);
    const jsonAllParents = await allParents.json();
    const rootLevelNr = 99;

    function addParentLevel(tree, parentLevelNr) {
        let parents = jsonAllParents.filter((item) => item.depth === parentLevelNr);

        if (parents.length === 0) {
            return addParentLevel(tree, rootLevelNr);
        }

        parents.forEach((parent) => {
            if (parent.id === tree[0].parentId) {
                parent.open = true;
                parent.children = tree;
            }
        });

        if (parentLevelNr === rootLevelNr) {
            return parents;
        }

        return addParentLevel(parents, parentLevelNr + 1);
    }

    const itemIsOnRootLevel = jsonAllParents.every((item) => item.depth === rootLevelNr);
    let itemLevel = itemIsOnRootLevel
        ? jsonAllParents.filter((item) => item.depth === rootLevelNr)
        : jsonAllParents.filter((item) => item.depth === 0);

    await openTheItem(itemLevel, itemId, getChildren);

    return {
        id: 0,
        name: "root",
        children: itemIsOnRootLevel ? itemLevel : addParentLevel(itemLevel, 1),
    };
};

export default getNewTreeFromItemId;
