import React from "react";
import { Checkbox, FormControlLabel, TextField, InputAdornment } from "@material-ui/core";
import AttributeTypes from "./attributetypes";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import MoneyInput from "../../../shared/moneyInput";

export default ({
    attributeDefinitions,
    attributes,
    getAttributeValue,
    setAttributeValue,
    setAttributes,
    errors,
    handleChange,
    disabled,
}) => {
    const getAttributeField = (assetAttributeDefinition) => {
        const props = {
            margin: "normal",
            variant: "outlined",
            name: assetAttributeDefinition.id,
            onChange: (value) =>
                setAttributeValue(
                    assetAttributeDefinition,
                    attributes,
                    value,
                    setAttributes,
                    handleChange
                ),
            value: getAttributeValue(assetAttributeDefinition, attributes),
            fullWidth: true,
            InputLabelProps: { shrink: true },
            errors,
            error: assetAttributeDefinition.id in errors,
            helperText: errors[assetAttributeDefinition.id],
            label: assetAttributeDefinition.label,
            required: assetAttributeDefinition.isRequired,
            disabled,
        };

        switch (assetAttributeDefinition.assetAttributeTypeId) {
            case AttributeTypes.LINK:
                return <TextField {...props} type="text" inputProps={{ maxLength: 2048 }} />
            case AttributeTypes.SINGLE_LINE_TEXT:
                return <TextField {...props} type="text" />;
            case AttributeTypes.MULTI_LINE_TEXT:
                return <TextField multiline rows={2} rowsMax={4} {...props} />;
            case AttributeTypes.NUMBER:
                return (
                    <TextField
                        {...props}
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                );
            case AttributeTypes.MONEY:
                return (
                    <MoneyInput
                        {...props}
                        labelDefaultValue={props.label}
                        labelResourceKey="NOTEXISTINGKEY"
                        useDecimals
                        currencyCode={"EUR"}
                    />
                );
            //return <TextField {...props} />;
            case AttributeTypes.BOOLEAN:
                if (typeof props.value !== "boolean") {
                    props.onChange({ target: { name: props.name, checked: false } });
                }
                return (
                    <FormControlLabel
                        control={<Checkbox {...props} checked={props.value} />}
                        label={props.label}
                        variant="outlined"
                    />
                );
            case AttributeTypes.OPTION_LIST:
                return (
                    <AutoCompleteStatic
                        {...props}
                        options={assetAttributeDefinition.options.map((option) => ({
                            id: option.id,
                            name: option.value,
                        }))}
                        isMedium
                        onSelection={props.onChange}
                    />
                );
            case AttributeTypes.DATE:
                return (
                    <DatePicker
                        clearable
                        autoOk
                        format="dd-MM-yyyy"
                        {...props}
                        variant="inline"
                        inputVariant="outlined"
                    />
                );
            case AttributeTypes.TIME:
                return (
                    <TimePicker
                        ampm={false}
                        clearable
                        autoOk
                        format="HH:mm"
                        {...props}
                        variant="inline"
                        inputVariant="outlined"
                    />
                );
            case AttributeTypes.DATETIME:
                return (
                    <DateTimePicker
                        ampm={false}
                        clearable
                        autoOk
                        format="dd-MM-yyyy HH:mm"
                        {...props}
                        variant="inline"
                        inputVariant="outlined"
                    />
                );
            default:
                throw new Error(
                    `Unknown assetAttributeTypeId in getAttributeField '${assetAttributeDefinition.assetAttributeTypeId}'`
                );
        }
    };

    return (
        attributeDefinitions &&
        attributeDefinitions.map((attributeDefinition) => getAttributeField(attributeDefinition))
    );
};
