import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        overflow: "hidden",

        "& h4": {
            marginBottom: 20,
        },
    },

    tableWrapper: {
        overflow: "auto",

        "& table": {
            minWidth: 700,
        },
    },

    row: {
        "&.--has-error": {
            "& td": {
                paddingBottom: 0,
            },
        },

        "& > td": {
            verticalAlign: "top",
        },

        "& td:nth-child(2)": {
            width: 150,
        },

        "& td:nth-child(3)": {
            width: 130,
        },

        "& td:nth-child(4)": {
            width: 100,
        },

        "& td:nth-child(5)": {
            width: 48,
        },
    },

    errorRow: {
        "& td": {
            paddingTop: 0,
        },
    },

    error: {
        marginTop: 0,
    },

    amount: {
        textAlign: "right",
    },

    amountLabel: {
        fontSize: 12,
    },

    amountTotal: {
        fontWeight: 500,
    },

    totalCount: {
        display: "flex",
        justifyContent: "flex-end",
        padding: `${theme.spacing(4)}px 56px ${theme.spacing(4)}px 0`,
        fontSize: 20,
        fontWeight: 500,

        "& span": {
            marginRight: theme.spacing(1),
        },
    },
}));

export default useStyles;
