import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(spam, searchText, status, page, pageSize) {
    const params = {
        spam,
        page,
        pageSize,
        searchText,
        status,
    };

    const url = `/api/marketplace/message?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/marketplace/message/${id}`;

    return await interceptedFetch(url);
}

async function updateRead(values) {
    return await interceptedFetch(`/api/marketplace/message/read`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}
async function updateIsSpam(values) {
    return await interceptedFetch(`/api/marketplace/message/isSpam`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(values) {
    return await interceptedFetch(`/api/marketplace/message`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export { getAll, get, updateRead, updateIsSpam, del };
