import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(page, pageSize) {
    const params = {
        page,
        pageSize,
    };

    const url = `/api/valuation/quickscan/assetmodelquickscans?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function purchase(quickScan) {
    return await interceptedFetch("/api/valuation/quickscan/purchaseAssetModelQuickScan", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(quickScan)),
    });
}

export { getAll, purchase };
