import React, { Fragment, useContext } from "react";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import withResources from "../../../shared/textresources/withresources";
import Header from "../../../shared/header";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Lock as SealIcon,
    LockOpen as UnsealIcon,
    Backspace as RemoveIcon,
    InsertDriveFile as DocumentsIcon,
    PermMedia as MediaIcon,
    LinearScale as UsageIcon,
    FiberManualRecord as RecordIcon,
    Info as FactsIcon,
    Build as WorkOrdersIcon,
    FileCopy as DuplicateIcon,
    List as EventsIcon,
} from "@material-ui/icons";

import ActionMenu from "../../../shared/actionmenu";
import Edit from "./edit";
import Duplicate from "./duplicate";
import Seal from "./seal";
import Unseal from "./unseal";
import Remove from "../assetComponents/remove";
import Delete from "./delete";
import useDialog from "../../../shared/usedialog";
import InvalidationContext from "../../../shared/invalidation/invalidationcontext";
import Record from "../componentUsage/record";
import { config as factConfig } from "../fact/config";

const ComponentHeader = withResources(
    ({ getResource, history, component, onRefresh, allStatuses }) => {
        const { invalidate } = useContext(InvalidationContext);

        const onDeleted = () => {
            invalidate("component-list");
            history.push(`/assets/components`);
        };

        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

        const {
            open: handleEdit,
            close: handleCloseEdit,
            visible: editToggled,
            args: editId,
        } = useDialog(onRefresh);
        const {
            open: handleSeal,
            close: handleCloseSeal,
            visible: sealToggled,
            args: sealId,
        } = useDialog(onRefresh);
        const {
            open: handleUnseal,
            close: handleCloseUnseal,
            visible: unsealToggled,
            args: unsealId,
        } = useDialog(onRefresh);
        const {
            open: handleRemove,
            close: handleCloseRemove,
            visible: removeToggled,
            args: removeId,
        } = useDialog(onRefresh);
        const {
            open: handleDelete,
            close: handleCloseDelete,
            visible: deleteToggled,
            args: deleteId,
        } = useDialog(onDeleted);
        const {
            open: handleDuplicate,
            close: handleCloseDuplicate,
            visible: duplicateToggled
        } = useDialog(onRefresh);
        const { open: handleRecord, close: handleCloseRecord, visible: recordToggled } = useDialog(
            onRefresh
        );

        var componentName =
            component &&
            component.name &&
            (component.name[selectedCulture] || component.name[defaultCulture]);

        return (
            <Fragment>
                <Header
                    title={componentName}
                    renderActions={() => {
                        return (
                            <ActionMenu
                                actions={[
                                    {
                                        text: getResource("Asset.components.detail.edit", "Edit"),
                                        showOnlyWhen: !component.isSealed,
                                        icon: <EditIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-update",
                                            companyId: component.companyId,
                                        },
                                        onClick: () => handleEdit(component.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.deleteButton",
                                            "Delete"
                                        ),
                                        icon: <DeleteIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-delete",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () => handleDelete(component.id),
                                    },
                                    {
                                        text: getResource("Asset.components.detail.seal", "Seal"),
                                        showOnlyWhen: !component.isSealed,
                                        icon: <SealIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-seal",
                                            companyId: component.companyId,
                                        },
                                        onClick: () => handleSeal(component.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.unseal",
                                            "Unseal"
                                        ),
                                        showOnlyWhen: component.isSealed,
                                        icon: <UnsealIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-seal",
                                            companyId: component.companyId,
                                        },
                                        onClick: () => handleUnseal(component.id),
                                    },

                                    {
                                        text: getResource(
                                            "Asset.components.detail.documentsButton",
                                            "Documents"
                                        ),
                                        icon: <DocumentsIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "component-documents-download",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(
                                                `/documents/documents/component-documents?referenceId=${component.id}`
                                            ),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.mediaGenericButton",
                                            "Media library"
                                        ),
                                        icon: <MediaIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "component-media-view-generic",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(
                                                `/assets/component-media/${component.id}/generic`
                                            ),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.removeButton",
                                            "Remove"
                                        ),
                                        showOnlyWhen: component.installedAssetId !== null,
                                        icon: <RemoveIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-remove",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () => handleRemove(component.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.usageButton",
                                            "View usage"
                                        ),
                                        icon: <UsageIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-usage-view",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(`/assets/component-usage/${component.id}`),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.recordUsageButton",
                                            "Record usage"
                                        ),
                                        icon: <RecordIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-usage-record",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () => handleRecord(),
                                        showOnlyWhen: !component.hasLinkedReadings,
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.showFactsButton",
                                            "Facts"
                                        ),
                                        icon: <FactsIcon />,
                                        itemId: component.id,
                                        requiredOperationAtLeastOneOf:
                                            factConfig.operationTypes.map((type) => ({
                                                name: `facts-view-${type}`,
                                                companyId: component.companyId,
                                                assetCollectionId: component.assetCollectionIds,
                                            })),
                                        onClick: () =>
                                            history.push(`/assets/component-facts/${component.id}`),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.workOrdersButton",
                                            "Work orders"
                                        ),
                                        icon: <WorkOrdersIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "work-orders-view",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(
                                                `/maintenance/component-work-orders/${component.id}`
                                            ),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.detail.duplicateButton",
                                            "Duplicate"
                                        ),
                                        icon: <DuplicateIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "components-duplicate",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () => handleDuplicate(component),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.components.viewEventsButton",
                                            "View events"
                                        ),
                                        icon: <EventsIcon />,
                                        itemId: component.id,
                                        requiredOperation: {
                                            name: "scheduled-events-view",
                                            companyId: component.companyId,
                                            assetCollectionId: component.assetCollectionIds,
                                        },
                                        onClick: () => history.push(`/planning/events/components/${component.id}`),
                                    }
                                ]}
                            />
                        );
                    }}
                />

                <Edit
                    open={editToggled}
                    id={editId}
                    onClose={handleCloseEdit}
                    allStatuses={allStatuses}
                />
                <Duplicate
                    open={duplicateToggled}
                    component={component}
                    onClose={handleCloseDuplicate}
                    allStatuses={allStatuses}
                />
                <Seal open={sealToggled} id={sealId} onClose={handleCloseSeal} />
                <Unseal open={unsealToggled} id={unsealId} onClose={handleCloseUnseal} />
                <Remove id={removeId} open={removeToggled} onClose={handleCloseRemove} />
                <Delete
                    id={deleteId}
                    open={deleteToggled}
                    onClose={handleCloseDelete}
                    assetId={component.installedAssetId}
                />
                <Record component={component} open={recordToggled} onClose={handleCloseRecord} />
            </Fragment>
        );
    }
);

export default ComponentHeader;
