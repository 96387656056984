import React, { Fragment, useContext, useState, useEffect } from "react";
import { Route } from "react-router";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import Install from "./install";
import Edit from "../component/edit";
import Remove from "./remove";
import RemoveMultiple from "./removeMultiple";

import { get as getAsset } from "./../asset/dataservice";
import { getAllForAsset, getStatuses } from "../component/dataservice";

import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useDialog from "../../../shared/usedialog";

const AssetComponents = (props) => {
    const id = props.match.params.id;

    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [selectedComponents, setSelectedComponents] = useState([]);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-component", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
    });

    const { data: asset } = useAPI(() => getAsset(id), true, [id]);
    const { loading, invoke, data } = useAPI(
        () =>
            getAllForAsset(
                searchParams.searchText,
                id,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [id, searchParams]
    );
    const { data: allStatuses } = useAPI(() => getStatuses(), true);

    const { open: handleInstall, close: handleCloseInstall, visible: installToggled } = useDialog(
        invoke
    );
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleRemove,
        close: handleCloseRemove,
        visible: removeToggled,
        args: removeId,
    } = useDialog(invoke);
    const {
        open: handleRemoveSelected,
        close: handleCloseRemoveSelected,
        visible: removeSelectedToggled,
        args: removeSelectedIds,
    } = useDialog(async (args) => {
        if (await invoke(args)) {
            setSelectedComponents([]);
        }
    });

    const handleSearch = (searchText) => {
        setSearchParams({ ...searchParams, searchText, page: 0 });
        setSelectedComponents([]);
    };

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const handleSelectComponent = (e) => {
        var componentId = Number(e.target.name);
        var selected = e.target.checked;

        var newSelections = [...selectedComponents];
        newSelections = handleComponentSelect(newSelections, componentId, selected);
        setSelectedComponents(newSelections);
    };

    const handleComponentSelect = (selections, componentId, itemSelected) => {
        if (!itemSelected) {
            selections = selections.filter((id) => id !== componentId);
        } else if (selections.filter((id) => id === componentId).length === 0) {
            selections.push(componentId);
        }
        return selections;
    };

    const handleSelectAllComponents = () => {
        if (!data || !data.items) {
            return;
        }
        var newSelections = [...selectedComponents];
        for (var componentIndex in data.items) {
            newSelections = handleComponentSelect(newSelections, data.items[componentIndex].id, true);
        }
        setSelectedComponents(newSelections);
    };

    const handleDeselectAllComponents = () => {
        var newSelections = [...selectedComponents];
        for (var componentIndex in data.items) {
            newSelections = handleComponentSelect(newSelections, data.items[componentIndex].id, false);
        }
        setSelectedComponents(newSelections);
    };

    return (
        asset && (
            <PrivateRoute
                requiredOperation={{
                    name: "assets-view-components",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                path="/assets/asset-components/:id(\d+)"
                exact
                render={(props) => (
                    <Fragment>
                        <Overview
                            asset={asset}
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onSearch={handleSearch}
                            onInstall={handleInstall}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                            onRemoveSelected={handleRemoveSelected}
                            defaultDebounceInterval={defaultDebounceInterval}
                            selectedComponents={selectedComponents}
                            onSelectComponent={handleSelectComponent}
                            onSelectAllComponents={handleSelectAllComponents}
                            onDeselectAllComponents={handleDeselectAllComponents}
                            {...props}
                        />

                        <Install open={installToggled} onClose={handleCloseInstall} asset={asset} />
                        <Edit
                            open={editToggled}
                            id={editId}
                            onClose={handleCloseEdit}
                            allStatuses={allStatuses}
                        />
                        <Remove
                            id={removeId}
                            assetId={asset.id}
                            open={removeToggled}
                            onClose={handleCloseRemove}
                        />
                        <RemoveMultiple open={removeSelectedToggled} ids={removeSelectedIds} onClose={handleCloseRemoveSelected} />
                    </Fragment>
                )}
            />
        )
    );
};

export default () => {
    return (
        <Route
            path="/assets/asset-components/:id(\d+)"
            render={(props) => <AssetComponents {...props} />}
        />
    );
};
