import React, { useContext, useState } from "react";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useAPI from "../../../shared/useapi";
import { getAllforAsset } from "./dataservice";
import { get as getAsset } from "../../assets/asset/dataservice";
import AssetList from "./assetList";
import Purchase from "./purchase";
import DownloadReport from "../quickscan/downloadreport";
import Delete from "../quickscan/delete";
import useDialog from "../../../shared/usedialog";
import Invoice from "../../administration/invoices/forReference";

export default ({
    history,
    match: {
        params: { assetId },
    },
}) => {
    const { data: asset } = useAPI(() => getAsset(assetId), true, [assetId]);

    const { defaultPageSize, availablePageSizes } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
    });

    const { data, invoke, loading } = useAPI(
        () => getAllforAsset(assetId, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams, assetId]
    );

    const {
        open: handlePurchase,
        close: handleClosePurchase,
        visible: purchaseToggled,
    } = useDialog(invoke);
    const {
        open: handlePurchaseCustom,
        close: handleClosePurchaseCustom,
        visible: purchaseCustomToggled,
    } = useDialog(invoke);
    const {
        open: handleDownloadReport,
        close: handleCloseDownloadReport,
        visible: downloadReportToggled,
        args: downloadReportId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleInvoice,
        close: handleCloseInvoice,
        visible: invoiceToggled,
        args: invoiceId,
    } = useDialog();

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operationName) {
        if (!asset) {
            return operationName;
        }

        return {
            name: operationName,
            companyId: asset.companyId,
            assetCollectionId: asset.assetCollectionIds,
        };
    }

    return (
        <React.Fragment>
            <AssetList
                history={history}
                data={data}
                page={searchParams.page}
                pageSize={searchParams.pageSize}
                availablePageSizes={availablePageSizes}
                loading={loading}
                getRequiredOperation={getRequiredOperation}
                asset={asset}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onPurchase={handlePurchase}
                onPurchaseCustom={handlePurchaseCustom}
                onDelete={handleDelete}
                onDownloadReport={handleDownloadReport}
                onInvoice={handleInvoice}
            />

            <Purchase
                open={purchaseToggled}
                assetId={assetId}
                onClose={handleClosePurchase}
                history={history}
            />
            <Purchase
                open={purchaseCustomToggled}
                assetId={assetId}
                onClose={handleClosePurchaseCustom}
                isCustom
                history={history}
            />

            <DownloadReport
                open={downloadReportToggled}
                quickScanId={downloadReportId}
                onClose={handleCloseDownloadReport}
            />

            <Delete
                open={deleteToggled}
                onClose={handleCloseDelete}
                quickScanId={deleteArgs && deleteArgs.id}
                reference={deleteArgs && deleteArgs.reference}
            />

            <Invoice
                open={invoiceToggled}
                onClose={handleCloseInvoice}
                referenceType={"QuickScan"}
                referenceId={invoiceId}
            />
        </React.Fragment>
    );
};
