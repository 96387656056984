import React, { Fragment, useContext } from "react";
import { Alert } from "@material-ui/lab";
import {
    LinearProgress,
    Checkbox,
    FormControlLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { duplicate } from "./dataservice";
import { useHistory } from "react-router-dom";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import withResources from "../../../shared/textresources/withresources";

import { getByCompanyWhereUserHasOperation as getAssetCollections } from "../assetCollection/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import TranslatableTextField from "../../../shared/translatableTextField";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, asset, open, onClose }) => {
    if (!open) {
        return null;
    }
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const { loading, invoke, data: duplicatedAssetId } = useAPI((values) => duplicate(asset.id, values));
    const { data: assetCollections } = useAPI(
        () => getAssetCollections(asset.companyId, "assets-duplicate"),
        true
    );

    const history = useHistory();

    const onSubmit = async (values) => {
        await invoke(values);
    };

    const onValidate = (values, cultures, defaultCulture) => {
        var errors = {};

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);
    const basicInformationCheckboxes = [
        { name: "duplicateDescription", resourceString: getResource("Assets.asset.duplicate.duplicateDescription", "Description") },
        { name: "duplicateCountry", resourceString: getResource("Assets.asset.duplicate.duplicateCountry", "Country") },
        { name: "duplicateLocation", resourceString: getResource("Assets.asset.duplicate.duplicateLocation", "Location") },
        { name: "duplicatePositionUrl", resourceString: getResource("Assets.asset.duplicate.duplicatePositionUrl", "Position URL") },
        { name: "duplicateConstructionDate", resourceString: getResource("Assets.asset.duplicate.duplicateConstructionDate", "Construction date") },
        { name: "duplicateInternalId", resourceString: getResource("Assets.asset.duplicate.duplicateInternalId", "Internal id") },
        { name: "duplicateSerial", resourceString: getResource("Assets.asset.duplicate.duplicateSerial", "Serial") },
        { name: "duplicateRegistration", resourceString: getResource("Assets.asset.duplicate.duplicateRegistration", "Registration") },
        { name: "duplicateEmissionImpact", resourceString: getResource("Assets.asset.duplicate.duplicateEmissionImpact", "Emission impact") }
    ];
    const assetCheckboxes = [
        { name: "duplicateSpecifications", resourceString: getResource("Assets.asset.duplicate.duplicateSpecifications", "Specifications") },
        { name: "duplicateMedia", resourceString: getResource("Assets.asset.duplicate.duplicateMedia", "Media") },
        { name: "duplicateDocuments", resourceString: getResource("Assets.asset.duplicate.duplicateDocuments", "Documents") },
        { name: "duplicateFinancialDocuments", resourceString: getResource("Assets.asset.duplicate.duplicateFinancialDocuments", "Financial documents") },
        { name: "duplicateFinancialInformation", resourceString: getResource("Assets.asset.duplicate.duplicateFinancialInformation", "Financial information") },
        { name: "duplicateOwnershipDocuments", resourceString: getResource("Assets.asset.duplicate.duplicateOwnershipDocuments", "Ownership documents") },
        { name: "duplicateOwnershipInformation", resourceString: getResource("Assets.asset.duplicate.duplicateOwnershipInformation", "Ownership information") },
        { name: "duplicateAdvertisements", resourceString: getResource("Assets.asset.duplicate.duplicateAdvertisements", "Advertisements") },
        { name: "duplicatePlannedActions", resourceString: getResource("Assets.asset.duplicate.duplicatePlannedActions", "Planned actions") },
        { name: "duplicateComponents", resourceString: getResource("Assets.asset.duplicate.duplicateComponents", "Installed components") }

    ];
    const componentBasicInformationCheckboxes = [
        { name: "duplicateComponentsDescription", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsDescription", "Description") },
        { name: "duplicateComponentsModelType", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsModelType", "Model/Type") },
        { name: "duplicateComponentsIdentification", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsIdentification", "Identification") },
        { name: "duplicateComponentsPosition", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsPosition", "Position in storage") },
        { name: "duplicateComponentsConsumable", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsConsumable", "Consumable") }
    ];
    const componentCheckboxes = [
        { name: "duplicateComponentsExpectedUsages", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsExpectedUsages", "Expected usages") },
        { name: "duplicateComponentsMedia", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsMedia", "Media") },
        { name: "duplicateComponentsInstallationDate", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsInstallationDate", "Installation date"), helpText: getResource("Assets.asset.duplicate.duplicateComponentsInstallationDateHelpText", "When left unchecked, the installation date will be set to today") },
        { name: "duplicateComponentsPlannedActions", resourceString: getResource("Assets.asset.duplicate.duplicateComponentsPlannedActions", "Planned actions") }
    ];

    const isLoaded = assetCollections;

    const renderCheckbox = (checkbox, values, handleChange) => {
        return <div key={checkbox.name}><FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(values[checkbox.name])}
                    onChange={(e) => handleChange(e)}
                    name={checkbox.name}
                />
            }
            label={checkbox.resourceString}
            variant="outlined"
        />
            {checkbox.helpText && <Alert severity="info">
                {checkbox.helpText}
            </Alert>}</div>
    };

    return (
        <Fragment><FormDrawer
            title={getResource("Assets.asset.duplicate.title", "Duplicate asset {name}",
                {
                    name: asset &&
                        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                            `${asset.manufacturerName} / ${asset.assetModel.name}`)
                })}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                        initialValues={{
                            name: Object.keys(asset.name).reduce(
                                (attrs, key) => ({
                                    ...attrs,
                                    [key]: asset.name[key] ? getResource("Assets.asset.duplicate.prefix", "Duplicate of ") + asset.name[key] : "",
                                }),
                                {}
                            ),
                        assetCollections: asset.assetCollectionIds,
                        duplicateName: false,
                        duplicateDescription: false,
                        duplicateCountry: false,
                        duplicateLocation: false,
                        duplicatePositionUrl: false,
                        duplicateConstructionDate: false,
                        duplicateInternalId: false,
                        duplicateSerial: false,
                        duplicateRegistration: false,
                        duplicateEmissionImpact: false,
                        duplicateSpecifications: true,
                        duplicateMedia: true,
                        duplicateDocuments: false,
                        duplicateFinancialDocuments: false,
                        duplicateFinancialInformation: false,
                        duplicateOwnershipDocuments: false,
                        duplicateOwnershipInformation: false,
                        duplicateAdvertisements: false,
                        duplicatePlannedActions: false,
                        duplicateComponents: false,
                        duplicateComponentsDescription: true,
                        duplicateComponentsModelType: true,
                        duplicateComponentsIdentification: false,
                        duplicateComponentsStatus: false,
                        duplicateComponentsPosition: false,
                        duplicateComponentsConsumable: false,
                        duplicateComponentsExpectedUsages: false,
                        duplicateComponentsMedia: true,
                        duplicateComponentsInstallationDate: false,
                        duplicateComponentsPlannedActions: false
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.asset.duplicate.okButton", "Duplicate")}
                    onSubmit={onSubmit}
                        onValidate={onValidate}
                        showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => <React.Fragment>
                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="name"
                                labelResourceKey="Assets.asset.duplicate.nameField"
                                labelDefaultValue="Name"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.name}
                                culture={culture}
                                handleChange={handleChange}
                            />
                            <div>{getResource("Assets.asset.duplicate.collectionsText", "Select the collections to which the new asset should be added")}</div>
                            <AutoCompleteStatic
                                errors={errors}
                                name="assetCollections"
                                label={getResource(
                                    "Assets.asset.duplicate.assetCollectionsField",
                                    "Collections"
                                )}
                                isMedium
                                fullWidth
                                value={values.assetCollections}
                                multiple
                                onSelection={(e) => handleChange(e)}
                                options={assetCollections}
                            />

                            <div>{getResource("Assets.asset.duplicate.relatedInformationText", "Select the related information you would like to duplicate")}</div>
                            <div><FormControlLabel
                                control={
                                    <Checkbox
                                        checked={basicInformationCheckboxes.some(x => values[x.name])}
                                        onChange={(e) => {
                                            handleChange(basicInformationCheckboxes.map(x => ({
                                                target: { name: x.name, value: e.target.checked }
                                            }))
                                            );
                                        }}
                                    />
                                }
                                label={getResource("Assets.asset.duplicate.basicInformation", "Basic information")}
                                variant="outlined"
                            /></div>
                            <div style={{ paddingLeft: 32 }}>
                                <div><FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={true}
                                            disabled
                                        />
                                    }
                                    label={getResource("Assets.asset.duplicate.duplicateManufacturer", "Manufacturer")}
                                    variant="outlined"
                                /></div>
                                <div><FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={true}
                                            disabled
                                        />
                                    }
                                    label={getResource("Assets.asset.duplicate.duplicateModel", "Model")}
                                    variant="outlined"
                                /></div>
                                {basicInformationCheckboxes.map(x => renderCheckbox(x, values, handleChange))}
                            </div>
                            {assetCheckboxes.map(x => renderCheckbox(x, values, handleChange))}
                            {values.duplicateComponents && <div style={{ paddingLeft: 32 }}>
                                <div><FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={componentBasicInformationCheckboxes.some(x => values[x.name])}
                                            onChange={(e) => {
                                                handleChange(componentBasicInformationCheckboxes.map(x => ({
                                                    target: { name: x.name, value: e.target.checked }
                                                }))
                                                );
                                            }}
                                        />
                                    }
                                    label={getResource("Assets.asset.duplicate.componentsBasicInformation", "Basic information")}
                                    variant="outlined"
                                /></div>
                                <div style={{ paddingLeft: 32 }}>
                                    {componentBasicInformationCheckboxes.map(x => renderCheckbox(x, values, handleChange))}
                                </div>
                                {componentCheckboxes.map(x => renderCheckbox(x, values, handleChange))}
                            </div>}
                        </React.Fragment>
                    }
                />
            )}
        </FormDrawer>

            <Dialog
                onClose={() => {
                    onClose();
                    history.push(`/assets/assets/${duplicatedAssetId}`)
                }}
                open={Boolean(duplicatedAssetId)}
                size="small"
            >
                <DialogTitle>
                    <ResourcedText
                        resourceKey="Assets.asset.duplicate.dialog.title"
                        defaultValue="Duplication succeeded"
                    />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <ResourcedText
                            resourceKey="Assets.asset.duplicate.dialog.text"
                            defaultValue="The asset has been successfully duplicated. You will now be taken to the detail page of the newly created asset."
                        />
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        onClose();
                        history.push(`/assets/assets/${duplicatedAssetId}`)
                    }}>
                        <ResourcedText
                            resourceKey="Assets.asset.duplicate.dialog.ok"
                            defaultValue="OK"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
});
