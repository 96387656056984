import { getAll } from "./dataservice";

/*

We have to transform the text resources coming from the server from:

{"languages":{"en-GB":[{"key":"ContentTextResourcesKeyField","value":"Key"},..

to the react-localize format: 

{en-GB: { ContentTextResourcesKeyField: "Key", ...

*/

function transformToReactLocalizeFormat(resources) {
    if (!resources) {
        return null;
    }

    var result = {};

    const languages = Object.keys(resources.languages);

    if (languages.length === 0) {
        return null;
    }

    for (var i = 0; i < languages.length; i++) {
        const language = languages[i];
        result[language] = {};

        for (var j = 0; j < resources.languages[language].length; j++) {
            var resource = resources.languages[language][j];
            result[language][resource.key] = resource.value;
        }
    }

    return result;
}

export default async () => {
    var response = await getAll();

    if (!response.ok) {
        // eslint-disable-next-line no-console
        console.error("Failed to load text resources.");
        return null;
    }

    var textResources = await response.json();

    return transformToReactLocalizeFormat(textResources);
};
