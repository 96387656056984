import React, { useRef, Fragment } from "react";
import ImageGallery from "react-image-gallery";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import useAuthorization from "../../../shared/authentication/useauthorization";
import Widget from "../../../shared/widget";
import { ImageOutlined as ImageIcon } from "@material-ui/icons";
import Add from "./add";
import useDialog from "../../../shared/usedialog";
import useStyles from "../../../shared/mediaWidget/useStyles";

const AssetMediaWidget = withResources(({ getResource, asset, library, title }) => {
    const classes = useStyles();
    const imageGalleryRef = useRef(null);

    const { data: media, loading, invoke } = useAPI(() => getAll(asset.id, library, null), true);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);

    const images =
        media &&
        media.map((media) => ({
            original: `/api/assets/asset/${asset.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetImage&v=${media.fileId}`,
            thumbnail: `/api/assets/asset/${asset.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetThumb&v=${media.fileId}`,
            fullscreen: `/api/assets/asset/${asset.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetFullScreen&v=${media.fileId}`,
            description: media.description,
        }));

    const renderThumbnail = (item) => {
        return (
            <div
                className="image-gallery-thumbnail-inner"
                style={{
                    background: `url('${item.thumbnail}') 50% 50%`,
                }}
            >
                {item.thumbnailLabel && (
                    <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>
                )}
            </div>
        );
    };

    return (
        <Fragment>
            <Widget
                headerTitle={title}
                headerLink={`/assets/asset-media/${asset.id}/${library.toLowerCase()}`}
                headerLinkTitle={getResource("Asset.media.widget.chevronAlt", `Manage media items`)}
                renderContent={() => {
                    const showGalleryNav = images && images.length > 1;
                    return (
                        <ImageGallery
                            lazyLoad
                            ref={imageGalleryRef}
                            items={images}
                            showPlayButton={false}
                            showThumbnails={showGalleryNav}
                            showNav={showGalleryNav}
                            additionalClass={classes.imageGallery}
                            renderThumbInner={renderThumbnail}
                            onClick={() => imageGalleryRef.current.fullScreen()}
                        />
                    );
                }}
                loading={loading}
                hasContent={images != null && images.length > 0}
                icon={<ImageIcon />}
                noDataText={getResource(
                    "Asset.media.widget.noDataText",
                    "There are no media items for this asset yet"
                )}
                addButtonText={getResource("Asset.media.widget.noDataButtonText", "Add")}
                onAddButtonClick={handleAdd}
                addButtonRequiredOperation={{
                    name: `asset-media-create-${library.toLowerCase()}`,
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
            />
            <Add assetId={asset.id} library={library} open={addToggled} onClose={handleCloseAdd} />
        </Fragment>
    );
});

export default ({ asset, library, title }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: `asset-media-view-${library.toLowerCase()}`,
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading || !isAuthorized) {
        return null;
    }

    return <AssetMediaWidget asset={asset} library={library} title={title} />;
};
