import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";
import ResourcedTextField from "../../../../../shared/textresources/resourcedtextfield";

class AdjustBetweenPoints extends Component {
    executeCallback() {
        // todo: validate before execute
        this.props.highchartsRef.adjustBetweenPoints();
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h5>Fair market Value</h5>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                labelResourceKey="Valuation.edit.adjustBetweenPointField"
                                labelDefaultValue="Adjust between point"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "adjustBetweenPoints/startPoint"
                                    )
                                }
                                value={this.props.adjustBetweenPointsData.startPoint}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                labelResourceKey="Valuation.edit.andPointField"
                                labelDefaultValue="and point"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "adjustBetweenPoints/endPoint"
                                    )
                                }
                                value={this.props.adjustBetweenPointsData.endPoint}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                labelResourceKey="Valuation.edit.curveRatioField"
                                labelDefaultValue="with curve ratio"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "adjustBetweenPoints/curveRatio"
                                    )
                                }
                                value={this.props.adjustBetweenPointsData.curveRatio}
                                placeholder="(No value = linear)"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <h5>Liquidation value</h5>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                labelResourceKey="Valuation.edit.fairMarketValueField"
                                labelDefaultValue="% fair market value"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "adjustBetweenPoints/percent"
                                    )
                                }
                                value={this.props.adjustBetweenPointsData.percent}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                labelResourceKey="Valuation.edit.fixedAmountField"
                                labelDefaultValue="fixed amount"
                                onChange={(e) =>
                                    this.props.onHandleChange(e.target, "adjustBetweenPoints/fixed")
                                }
                                value={this.props.adjustBetweenPointsData.fixed}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.executeCallback()}
                            >
                                <ResourcedText
                                    resourceKey="Valuation.edit.adjustValueButton"
                                    defaultValue="Adjust value"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AdjustBetweenPoints;
