import React from "react";
import useAPI from "../../../shared/useapi";
import { getAll } from "../../marketplace/advertisements/dataservice";
import withResources from "../../../shared/textresources/withresources";
import TableWidget from "../../../shared/tableWidget";
import useAuthorization from "../../../shared/authentication/useauthorization";
import useDialog from "../../../shared/usedialog";
import Add from "../../marketplace/advertisements/add";
import Edit from "../../marketplace/advertisements/edit";
import { ShoppingCart as AdvertisementIcon } from "@material-ui/icons";

const Widget = withResources(({ getResource, asset }) => {
    const { data: assetAdvertisements, loading, invoke } = useAPI(
        () => getAll(null, null, true, asset.id, 1, 5),
        true
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);

    const data =
        assetAdvertisements &&
        assetAdvertisements.items.map((advertisement) => ({
            id: advertisement.id,
            type: getResource(
                `Marketplace.advertisement.type.${advertisement.type.toLowerCase()}`,
                advertisement.type
            ),
            title: advertisement.title,
        }));

    return (
        <React.Fragment>
            <TableWidget
                headerTitle={getResource(
                    "Asset.assetAdvertisements.widget.header",
                    "Published advertisements"
                )}
                headerLink={`/assets/asset-advertisement/${asset.id}`}
                headerLinkTitle={getResource(
                    "Asset.assetAdvertisements.widget.chevronAlt",
                    `See all advertisements`
                )}
                data={data}
                onRowClick={handleEdit}
                clickProperty="id"
                loading={loading}
                hasContent={data !== null && data.length > 0}
                icon={<AdvertisementIcon />}
                noDataText={getResource(
                    "Asset.assetAdvertisements.widget.noDataText",
                    `There are no published advertisements for this asset`
                )}
                addButtonText={getResource(
                    "Asset.assetAdvertisements.widget.noDataButtonText",
                    `Add`
                )}
                onAddButtonClick={handleAdd}
                leftCellWidth={125}
                rightCellWidth="auto"
                addButtonRequiredOperation={{
                    name: "advertisements-create",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
            />

            <Add open={addToggled} onClose={handleCloseAdd} asset={asset} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
        </React.Fragment>
    );
});

export default ({ asset }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: "advertisements-view",
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <Widget asset={asset} />;
};
