import React, { useContext } from "react";
import withResources from "../../../shared/textresources/withresources";
import InfoBlock from "../shared/infoBlock";
import { formatDate } from "../../../shared/formattedDate";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, workOrder }) => {
    if (!workOrder) {
        return null;
    }

    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    var rows = [];

    const addRow = (resourceTitle, title, value) => {
        var resourcedTitle = getResource(`Maintenance.workOrder.widget.${resourceTitle}`, title);
        rows.push({
            title: value ? resourcedTitle : null,
            value,
        });
    };

    addRow("company", "Company", workOrder.company ? workOrder.company.name : null);
    addRow("asset", "Asset", workOrder.asset ? workOrder.asset.name : null);
    addRow("component", "Component", workOrder.component ? workOrder.component.name : null);
    addRow(
        "reason",
        "Reason",
        getResource(
            `Maintenance.workOrder.reason.${workOrder.reason.toLowerCase()}`,
            workOrder.reason
        )
    );
    if (workOrder.scheduledEvent) {
        if (workOrder.scheduledEvent.exactLocationKnown) {
            var location = "";
            if (workOrder.scheduledEvent.address) {
                location += workOrder.scheduledEvent.address + " ";
            }
            if (workOrder.scheduledEvent.postcalCode) {
                location += workOrder.scheduledEvent.postcalCode + " ";
            }
            if (workOrder.scheduledEvent.city) {
                location += workOrder.scheduledEvent.city + " ";
            }
            if (workOrder.scheduledEvent.state) {
                location += workOrder.scheduledEvent.state + " ";
            }
            if (workOrder.scheduledEvent.country) {
                location += workOrder.scheduledEvent.country + " ";
            }

            addRow("location", "Location", location);
        } else {
            addRow("location", "Location", workOrder.scheduledEvent.approximateLocation);
        }

        var startDate = new Date(workOrder.scheduledEvent.start).setHours(0, 0, 0, 0);
        var endDate = new Date(workOrder.scheduledEvent.end).setHours(0, 0, 0, 0);

        var endDateToShow = new Date(workOrder.scheduledEvent.end);
        if (workOrder.scheduledEvent.allDay) {
            endDateToShow.setDate(endDateToShow.getDate() - 1);
        }

        addRow(
            "schedule",
            "Schedule",
            getResource("Maintenance.workOrders.detail.schedule", "From {start} until {end}", {
                start: formatDate(
                    workOrder.scheduledEvent.start,
                    selectedUiCulture,
                    selectedTimezone,
                    false,
                    !workOrder.scheduledEvent.allDay,
                    false,
                    false
                ),
                end:
                    startDate === endDate
                        ? formatDate(
                              endDateToShow,
                              selectedUiCulture,
                              selectedTimezone,
                              false,
                              false,
                              false,
                              true
                          )
                        : formatDate(
                              endDateToShow,
                              selectedUiCulture,
                              selectedTimezone,
                              false,
                              !workOrder.scheduledEvent.allDay,
                              false,
                              false
                          ),
            })
        );
        if (workOrder.scheduledEvent.referenceType === "Assets") {
            addRow(
                "assetStatus",
                "Asset status",
                getResource(
                    `Planning.scheduledEvent.referenceStatus.${workOrder.scheduledEvent.referenceStatus.toLowerCase()}`,
                    workOrder.scheduledEvent.referenceStatus
                )
            );
        } else if (workOrder.scheduledEvent.referenceType === "Components") {
            addRow(
                "componentStatus",
                "Component status",
                getResource(
                    `Planning.scheduledEvent.referenceStatus.${workOrder.scheduledEvent.referenceStatus.toLowerCase()}`,
                    workOrder.scheduledEvent.referenceStatus
                )
            );
        }
    }

    return (
        <InfoBlock
            title={getResource("Maintenance.workOrder.widget.title", "Work order")}
            rows={rows}
        />
    );
});
