import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./useStyles";
import { Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import withResources from "../textresources/withresources";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import ShowMoreText from "react-show-more-text";

const Summary = withResources(
    ({ getResource, title, icon, description, rows, nrOfRowsAlwaysVisible }) => {
        const classes = useStyles();
        const [isOpen, setIsOpen] = useState(false);

        const hasToggle = Boolean(
            nrOfRowsAlwaysVisible && rows && rows.length > nrOfRowsAlwaysVisible
        );

        return (
            <Paper className={clsx(classes.root, "--has-toggle")}>
                <div className={classes.header}>
                    <Typography variant="h4">{title}</Typography>
                    {icon ? <div className={classes.icon}>{icon}</div> : null}
                </div>
                <div className={classes.content}>
                    {description ? (
                        <div className={classes.description}>
                            <ShowMoreText
                                lines={3}
                                more={
                                    <Fragment>
                                        {getResource("Shell.summary.showMore", "more")}
                                        <ExpandMoreIcon fontSize="small" />
                                    </Fragment>
                                }
                                less={
                                    <Fragment>
                                        {getResource("Shell.summary.showLess", "less")}
                                        <ExpandLessIcon fontSize="small" />
                                    </Fragment>
                                }
                                expanded={false}
                                anchorClass={classes.descriptionToggle}
                            >
                                {description}
                            </ShowMoreText>
                        </div>
                    ) : null}

                    {rows.length > 0 && (
                        <dl>
                            {rows.map((row, i) => {
                                const showRow = Boolean(
                                    !hasToggle || i + 1 <= nrOfRowsAlwaysVisible || isOpen
                                );

                                return (
                                    showRow && (
                                        <Fragment key={i}>
                                            <dt className={hasToggle ? "--summary-has-toggle" : ""}>
                                                {row.title}
                                            </dt>
                                            <dd className={hasToggle ? "--summary-has-toggle" : ""}>
                                                {row.data}
                                            </dd>
                                        </Fragment>
                                    )
                                );
                            })}
                        </dl>
                    )}

                    {hasToggle &&
                        (isOpen ? (
                            <Button
                                className={classes.toggleButton}
                                onClick={() => setIsOpen(false)}
                            >
                                {getResource("Summary.lessInformationToggle", "Less information")}
                                <ExpandLessIcon fontSize="small" />
                            </Button>
                        ) : (
                            <Button
                                className={classes.toggleButton}
                                onClick={() => setIsOpen(true)}
                            >
                                {getResource("Summary.moreInformationToggle", "More information")}
                                <ExpandMoreIcon fontSize="small" />
                            </Button>
                        ))}
                </div>
            </Paper>
        );
    }
);

Summary.propTypes = {
    getResource: PropTypes.string,
    title: PropTypes.object.isRequired,
    icon: PropTypes.bool,
    description: PropTypes.string,
    rows: PropTypes.array.isRequired,
    nrOfRowsAlwaysVisible: PropTypes.number,
};

export default Summary;
