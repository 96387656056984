import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import MuiPickerUtilsWrapper from "./shared/MuiPickerUtilsWrapper";

import Shell, { ApplicationTitle } from "./features/shell";
import Snacks from "./shared/snackbar/snacks";
import { Machine } from "./shared/slotmachine";

import LibraryLeftNav from "./features/library";
import AssetsLeftNav from "./features/assets";
import ValuationLeftNav from "./features/valuation/valuationIndex";
import AppraisalLeftNav from "./features/valuation/appraisalIndex";
import QuickScanLeftNav from "./features/valuation/quickScanIndex";
import ContentLeftNav from "./features/content";
import DocumentLeftNav, { DocumentProvider } from "./features/document";
import SecurityLeftNav from "./features/security/leftNav";
import MarketplaceLeftNav from "./features/marketplace";
import SecurityTopNav from "./features/security/topNav";
import MaintenanceLeftNav from "./features/maintenance/index";
import PlanningLeftNav from "./features/planning/index";
import AdministrationLeftNav from "./features/administration/index";
import SystemLeftNav from "./features/system/index";

import AssetsWidgets from "./features/assets/widgets";
import ValuationWidgets from "./features/valuation/widgets";
import MaintenanceWidgets from "./features/maintenance/widgets";

import TextResourcesProvider from "./shared/textresources/textresourcesprovider";
import LocalizationProvider from "./shared/localization/localizationprovider";
import AuthenticationProvider from "./shared/authentication/authenticationprovider";
import InvalidationProvider from "./shared/invalidation/invalidationprovider";
import SnackbarProvider from "./shared/snackbar/snackbarProvider";
import ReferenceProvider from "./shared/reference/referenceProvider";

const rootElement = document.getElementById("root");

ReactDOM.render(
    <React.Fragment>
        <AuthenticationProvider>
            <LocalizationProvider>
                <MuiPickerUtilsWrapper>
                    <CssBaseline />
                    <Machine>
                        <TextResourcesProvider>
                            <InvalidationProvider>
                                <ReferenceProvider>
                                    <DocumentProvider>
                                        <ApplicationTitle>
                                            <SnackbarProvider>
                                                <Snacks />
                                                <Shell />

                                                <PlanningLeftNav />
                                                <AssetsLeftNav />
                                                <MaintenanceLeftNav />
                                                <SecurityLeftNav />
                                                <LibraryLeftNav />
                                                <ValuationLeftNav />
                                                <AppraisalLeftNav />
                                                <QuickScanLeftNav />
                                                <ContentLeftNav />
                                                <MarketplaceLeftNav />
                                                <AdministrationLeftNav />
                                                <DocumentLeftNav />
                                                <SystemLeftNav />

                                                <SecurityTopNav />

                                                <AssetsWidgets />
                                                <ValuationWidgets />
                                                <MaintenanceWidgets />
                                            </SnackbarProvider>
                                        </ApplicationTitle>
                                    </DocumentProvider>
                                </ReferenceProvider>
                            </InvalidationProvider>
                        </TextResourcesProvider>
                    </Machine>
                </MuiPickerUtilsWrapper>
            </LocalizationProvider>
        </AuthenticationProvider>
    </React.Fragment>,
    rootElement
);
