import React, { useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import ButtonFilter from "../../../shared/buttonFilter";

const Search = withResources(
    ({ getResource, onSearch, searchParams, allStatuses, showInstalledOrAllSwitch, hideStatusFilters }) => {
        const [searchValues, setSearchValues] = useState(searchParams);

        function handleValuesChanged(values) {
            setSearchValues(values);
            onSearch(values.searchText, values.statuses, values.showOnlyInstalled, values.order);
        }

        return (
            <PageListSearch
                searchFields={[
                    <ResourcedTextField
                        labelResourceKey="Asset.component.search.searchField"
                        labelDefaultValue="Keyword search"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoFocus
                        value={searchValues.searchText || ""}
                        onChange={(e) =>
                            handleValuesChanged({ ...searchValues, searchText: e.target.value })
                        }
                        AdornmentIcon={<SearchIcon />}
                        className="e2e_pagedListGenericSearchField"
                        clearable
                    />,
                    hideStatusFilters ? null : <AutoCompleteStatic
                        label={getResource("Asset.component.search.statusField", "Status")}
                        value={searchValues.statuses}
                        options={allStatuses}
                        onSelection={(e) =>
                            handleValuesChanged({ ...searchValues, statuses: e.target.value })
                        }
                        multiple
                    />,
                    (!hideStatusFilters && showInstalledOrAllSwitch) ? (
                        <ButtonFilter
                            value={searchValues.showOnlyInstalled}
                            name="showOnlyInstalled"
                            hideAllButton
                            options={[
                                {
                                    name: getResource(
                                        "Assets.component.search.installed",
                                        "Installed"
                                    ),
                                    value: true,
                                },
                                {
                                    name: getResource("Assets.component.search.all", "All"),
                                    value: false,
                                },
                            ]}
                            onSelection={(e) => {
                                handleValuesChanged({
                                    ...searchValues,
                                    showOnlyInstalled: e.target.value,
                                });
                            }}
                        />
                    ) : null,
                ]}
                sortField={
                    <AutoCompleteStatic
                        disableClearable
                        label={getResource("Asset.components.search.sortLabel", "Sort by")}
                        value={searchValues.order || "Default"}
                        options={[
                            "Default",
                            "NameAscending",
                            "NameDescending",
                            "ModelTypeAscending",
                            "ModelTypeDescending",
                            "IdentificationAscending",
                            "IdentificationDescending",
                            "InstallDateAscending",
                            "InstallDateDescending"
                        ].map(x => ({ id: x }))}
                        getOptionLabel={(option) => (
                            getResource(`Asset.components.search.orderBy.${option.id}`, option.id)
                        )}
                        onSelection={(e) =>
                            handleValuesChanged({
                                ...searchValues,
                                order: e.target.value,
                            })}
                    />
                }
            />
        );
    }
);

export default Search;
