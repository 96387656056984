import React, { Fragment, useState, useEffect } from "react";
import { IconButton, FormHelperText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import withResources from "../../../../shared/textresources/withresources";
import useAPI from "../../../../shared/useapi";
import { getPartyTypes, getParties } from "../dataservice";
import { getAll as getAllCountries } from "../../../library/countries/dataservice";
import LabelledOutline from "../../../../shared/labelledoutline";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import AddRowWithAutocompleteStatic from "../../../../shared/addRowWithAutocompleteStatic";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    partyContentHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const PartiesEditor = withResources(({ getResource, disabled, handleChange, errors, value }) => {
    const { data: allParties } = useAPI(getParties, true);
    const { data: allPartyTypes } = useAPI(getPartyTypes, true);
    const { data: allCountries } = useAPI(getAllCountries, true);

    const [parties, setParties] = useState([]);

    const classes = useStyles();

    const updateParty = (index, event) => {
        const copy = [
            ...parties.slice(0, index),
            {
                ...parties[index],
                [event.target.name]: event.target.value,
            },
            ...parties.slice(index + 1),
        ];
        setParties(copy);
        handleChange({ target: { name: "parties", value: copy } });
    };

    useEffect(() => {
        if (value) {
            setParties(value);
        }
    }, [setParties, value]);

    const isLoaded = allPartyTypes && allCountries;

    return (
        <Fragment>
            {isLoaded && (
                <Fragment>
                    {parties.map((party, index) => {
                        const partyType = party.partyType;
                        const partyName = allPartyTypes.find((x) => x.id === partyType).name;

                        return (
                            <LabelledOutline
                                key={partyName}
                                label={partyName}
                                className="partyLabelledOutline"
                            >
                                <div className={classes.partyContentHeader}>
                                    <ToggleButtonGroup
                                        value={party.selectionMethod || ""}
                                        exclusive
                                        size="small"
                                        aria-label={getResource(
                                            "Assets.component.partiesEditor.searchOrCreateParty",
                                            "Search for an existing party or create a new one"
                                        )}
                                        onChange={(e, value) => {
                                            if (value !== null) {
                                                updateParty(index, {
                                                    target: {
                                                        name: "selectionMethod",
                                                        value: value,
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <ToggleButton value="search" disabled={disabled}>
                                            {getResource(
                                                "Assets.component.partiesEditor.searchForParty",
                                                "Search"
                                            )}
                                        </ToggleButton>
                                        <ToggleButton value="new" disabled={disabled}>
                                            {getResource(
                                                "Assets.component.partiesEditor.createNewParty",
                                                "Create new"
                                            )}
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                    <IconButton
                                        aria-label="delete"
                                        disabled={disabled}
                                        onClick={() => {
                                            const copy = parties.filter(
                                                (item) => item.partyType !== party.partyType
                                            );
                                            setParties(copy);
                                            handleChange({
                                                target: {
                                                    name: "parties",
                                                    value: copy,
                                                },
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>

                                {errors &&
                                    party.partyType in errors &&
                                    "selectionMethod" in errors[party.partyType] && (
                                        <FormHelperText error>
                                            {errors[party.partyType]["selectionMethod"]}
                                        </FormHelperText>
                                    )}

                                {party.selectionMethod === "search" && (
                                    <AutoCompleteStatic
                                        errors={errors[party.partyType]}
                                        name="partyId"
                                        label={getResource(
                                            "Assets.component.partiesEditor.newParty.partyIdField",
                                            "Existing party"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={party.partyId}
                                        onSelection={(e) => updateParty(index, e)}
                                        options={allParties}
                                        disabled={disabled}
                                        required
                                    />
                                )}

                                {party.selectionMethod === "new" && (
                                    <Fragment>
                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="name"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.nameField"
                                            labelDefaultValue="Name"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.name}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                            required
                                        />

                                        <AutoCompleteStatic
                                            errors={errors[party.partyType]}
                                            name="country"
                                            label={getResource(
                                                "Assets.component.partiesEditor.newParty.countryField",
                                                "Country"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={party.country}
                                            onSelection={(e) => updateParty(index, e)}
                                            options={allCountries.map((c) => ({
                                                id: c.code,
                                                name: c.name,
                                            }))}
                                            disabled={disabled}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="address"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.addressField"
                                            labelDefaultValue="Address"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.address}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="postalCode"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.postalCodeField"
                                            labelDefaultValue="Postal code"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.postalCode}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="city"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.cityField"
                                            labelDefaultValue="City"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.city}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="state"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.stateField"
                                            labelDefaultValue="State"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.state}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="website"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.websiteField"
                                            labelDefaultValue="Website"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.website}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />

                                        <ResourcedTextField
                                            errors={errors[party.partyType]}
                                            margin="normal"
                                            name="email"
                                            labelResourceKey="Assets.component.partiesEditor.newParty.emailField"
                                            labelDefaultValue="Email"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={party.email}
                                            onChange={(e) => updateParty(index, e)}
                                            disabled={disabled}
                                        />
                                    </Fragment>
                                )}
                            </LabelledOutline>
                        );
                    })}
                </Fragment>
            )}

            <AddRowWithAutocompleteStatic
                disabled={disabled || (isLoaded && allPartyTypes.length === parties.length)}
                buttonText={getResource("Assets.component.partiesEditor.addField", "Add party")}
                renderAutocompleteStatic={() => (
                    <AutoCompleteStatic
                        fullWidth
                        label={getResource("Assets.component.partiesEditor.addField", "Add party")}
                        value=""
                        options={
                            isLoaded
                                ? allPartyTypes.filter(
                                      (party) => !parties.some((x) => x.partyType === party.id)
                                  )
                                : []
                        }
                        onSelection={(e) => {
                            if (e.target.value) {
                                const copy = parties.concat({
                                    partyType: e.target.value,
                                    selectionMethod: "search",
                                });
                                setParties(copy);
                                handleChange({
                                    target: { name: "parties", value: copy },
                                });
                            }
                        }}
                    />
                )}
            />
        </Fragment>
    );
});

export default PartiesEditor;

export function validateParties(values, errors, getResource) {
    const setPartyError = (partyType, field, message) => {
        if (errors[partyType] === undefined) {
            errors[partyType] = {};
        }
        errors[partyType][field] = message;
    };

    if (values.parties) {
        values.parties.forEach((party) => {
            if (!party.selectionMethod) {
                setPartyError(
                    party.partyType,
                    "selectionMethod",
                    getResource(
                        "Assets.component.partiesEditor.selectionMethodRequired",
                        "Please select a method to find a party"
                    )
                );
            }

            if (party.selectionMethod === "search" && !party.partyId) {
                setPartyError(
                    party.partyType,
                    "partyId",
                    getResource(
                        "Assets.component.partiesEditor.partyIdRequired",
                        "Please select an existing party"
                    )
                );
            }

            if (party.selectionMethod === "new" && !party.name) {
                setPartyError(
                    party.partyType,
                    "name",
                    getResource(
                        "Assets.component.partiesEditor.partyNameRequired",
                        "Please provide a name for the new party"
                    )
                );
            }

            if (party.selectionMethod === "new" && !party.country) {
                setPartyError(
                    party.partyType,
                    "country",
                    getResource(
                        "Assets.component.partiesEditor.partyCountryRequired",
                        "Please select a country for the new party"
                    )
                );
            }
        });
    }
}
