import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SlotFillContext from "./slotcontext";

const Fill = (props) => {
    const context = useContext(SlotFillContext);
    useEffect(() => {
        context.registerFill(props.name, props.children);

        return () => context.unRegisterFill(props.name, props.children);
    });

    return null;
};

Fill.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Fill;
