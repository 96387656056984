import React, { useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import {
    getParents,
    getActiveChildren,
    getPath,
    getAll,
} from "../../security/companies/dataservice";

import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import DrillDownInput from "../../../shared/drilldowninput";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { companyScope } = useContext(WorkingScopeContext);

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            companyId: values.values.company.id,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.company.id) {
            errors.company = getResource(
                "Assets.assetCollection.add.companyRequired",
                "Please select a company"
            );
        }

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Assets.assetCollection.add.enterName",
                "Please enter a name for the default language"
            );
        }

        return errors;
    };

    var isLoaded = true;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Assets.assetCollection.add.title", "Create collection")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        name: "",
                        company: companyScope,
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.assetCollection.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(culture, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <DrillDownInput
                                    errors={errors}
                                    name="company"
                                    itemName={getResource(
                                        "Assets.assetCollection.add.companiesField",
                                        "Company"
                                    )}
                                    label={getResource(
                                        "Assets.assetCollection.add.companiesField",
                                        "Company"
                                    )}
                                    isMedium
                                    fullWidth
                                    getParents={getParents}
                                    getChildren={getActiveChildren}
                                    getPath={getPath}
                                    filter={(searchText, noDescendantOf, page, pageSize) =>
                                        getAll(searchText, noDescendantOf, true, page, pageSize)
                                    }
                                    value={values.company}
                                    disableClearable
                                    setValue={(e) => handleChange(e)}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Assets.assetCollection.add.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
