import React from "react";
import withResources from "../../../../../shared/textresources/withresources";
import FormattedCurrency from "../../../../../shared/formattedCurrency";
import useStyles from "./useStyles";

const ReportTotal = withResources((props) => {
    const { getResource, totalAmount, totalEstimated, currencyShortName } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.totalBlock}>
                <div className="totalLabel">
                    {getResource(
                        "Maintenance.workOrderReport.reportTotal.totalCostsLabel",
                        "Total"
                    )}
                </div>
                <div>
                    <div className="totalAmount">
                        <FormattedCurrency
                            currency={currencyShortName}
                            decimals={2}
                            value={totalAmount}
                        />
                    </div>

                    <div className="totalEstimate">
                        {getResource(
                            "Maintenance.workOrderReport.reportTotal.totalEstimatedLabel",
                            "Estimated"
                        )}
                        &nbsp;
                        <FormattedCurrency
                            currency={currencyShortName}
                            decimals={2}
                            value={totalEstimated}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ReportTotal;
