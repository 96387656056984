import React, { useState } from "react";
import useAPI from "../../../shared/useapi";
import { publish } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import MoneyInput from "../../../shared/moneyInput";
import { InputAdornment } from "@material-ui/core";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import Upload from "../../../shared/upload";
import { config } from "config";

export default withResources(({ getResource, open, onClose, appraisal }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI((values) => publish(appraisal.id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.fileId) {
            errors.fileId = getResource(
                "Valuation.appraisals.publish.uploadFile",
                "Please upload a file"
            );
        }

        if (!values.fileName) {
            errors.fileName = getResource(
                "Valuation.appraisals.publish.enterFileName",
                "Please enter a file name"
            );
        } else if (!values.fileName.match(config.fileNameRegEx)) {
            errors.fileName = getResource(
                "Valuation.appraisals.publish.enterValidFileName",
                "Please enter a valid file name"
            );
        }

        if (!values.fairMarketValue) {
            errors.fairMarketValue = getResource(
                "Valuation.appraisals.publish.enterFairMarketValue",
                "Please enter the fair market value"
            );
        }

        if (!values.liquidationValue) {
            errors.liquidationValue = getResource(
                "Valuation.appraisals.publish.enterLiquidationValue",
                "Please enter the liquidation value"
            );
        }

        return errors;
    };

    const [isDirty, setIsDirty] = useState(false);

    return (
        <React.Fragment>
            <FormDrawer
                title={getResource("Valuation.appraisals.publish.title", "Publish appraisal")}
                onClose={() => onClose(false)}
                isDirty={isDirty}
            >
                <LocalizedForm
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Valuation.appraisals.publish.okButton", "Publish")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors, updateValues) => {
                        function handleFileUpload(file) {
                            let fileId = "",
                                extension = "",
                                fileNameWithoutExtension = "",
                                validFileName = "";

                            if (file) {
                                const indexOfExtension = file.name.lastIndexOf(".");
                                extension = file.name.substring(indexOfExtension);
                                fileNameWithoutExtension = file.name.substring(0, indexOfExtension);
                                validFileName = fileNameWithoutExtension.replace(/[\\/:"*?<>|]+/);
                                fileId = file.id;
                            }

                            updateValues([
                                {
                                    name: "fileId",
                                    value: fileId,
                                    isLocalizable: false,
                                },
                                {
                                    name: "fileName",
                                    value: validFileName,
                                    isLocalizable: false,
                                },
                                {
                                    name: "fileExtension",
                                    value: extension,
                                    isLocalizable: false,
                                },
                            ]);
                        }

                        return (
                            <React.Fragment>
                                <span>
                                    {getResource(
                                        "Valuation.appraisals.publish.infoText",
                                        "Upload the finished appraisal in pdf format and specify the fair market and liquidation values as included in the appraisal."
                                    )}
                                </span>

                                <Upload
                                    errors={errors}
                                    url={`/api/valuation/appraisal/${appraisal.id}/upload`}
                                    onFileUpload={handleFileUpload}
                                    allowedExtensions={[".pdf"]}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="fileName"
                                    labelResourceKey="Valuation.appraisals.publish.fileNameField"
                                    labelDefaultValue="File name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={values.fileName}
                                    onChange={(e) => handleChange(e)}
                                    InputProps={{
                                        maxLength: 255,
                                        endAdornment: values.fileExtension && (
                                            <InputAdornment position="end">
                                                {values.fileExtension}
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                />

                                <MoneyInput
                                    errors={errors}
                                    name="fairMarketValue"
                                    labelResourceKey="Valuation.appraisals.publish.fairMarketValueField"
                                    labelDefaultValue="Fair market value"
                                    fullWidth
                                    value={values.fairMarketValue}
                                    currencyCode={appraisal.currencyShortName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <MoneyInput
                                    errors={errors}
                                    name="liquidationValue"
                                    labelResourceKey="Valuation.appraisals.publish.liquidationValueField"
                                    labelDefaultValue="Liquidation value"
                                    fullWidth
                                    value={values.liquidationValue}
                                    currencyCode={appraisal.currencyShortName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </FormDrawer>
        </React.Fragment>
    );
});
