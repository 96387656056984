import React, { useState, useContext, Fragment } from "react";
import withResources from "../../../shared/textresources/withresources";
import { getAllForAssetContext as getAssetModels } from "./../../library/assetmodels/dataservice";
import { getAllForAssetContext as getManufacturers } from "./../../library/manufacturers/dataservice";
import useAPI from "../../../shared/useapi";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import PageListSearch from "../../../shared/pagelistsearch";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(
    ({ getResource, onSearch, searchParams, reasons, statuses, showExtendedFilters }) => {
        const [searchValues, setSearchValues] = useState(searchParams);

        const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

        const { data: allManufacturers } = useAPI(
            () => getManufacturers(null, null, null),
            showExtendedFilters,
            [companyScope.id, assetCollectionScope.id]
        );

        const { data: allAssetModels } = useAPI(
            () => getAssetModels(null, searchValues.manufacturerId, null, null),
            showExtendedFilters,
            [searchValues.manufacturerId, companyScope.id, assetCollectionScope.id]
        );

        function handleValuesChanged(values) {
            setSearchValues(values);
            onSearch(
                values.searchText,
                values.reason,
                values.status,
                values.assetModelId,
                values.manufacturerId
            );
        }

        var filteredStatuses = statuses && statuses.filter((status) => status.id !== "Archived");

        var searchFields = [
            <ResourcedTextField
                labelResourceKey="Maintenance.workOrder.search.searchField"
                labelDefaultValue="Search"
                size="small"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                autoFocus
                value={searchValues.searchText || ""}
                onChange={(e) =>
                    handleValuesChanged({ ...searchValues, searchText: e.target.value })
                }
                AdornmentIcon={<SearchIcon />}
                className="e2e_pagedListGenericSearchField"
                clearable
            />,
            <AutoCompleteStatic
                label={getResource("Maintenance.workOrder.search.reasonField", "Reason")}
                value={searchValues.reason}
                onSelection={(e) =>
                    handleValuesChanged({ ...searchValues, reason: e.target.value })
                }
                options={reasons}
            />,
            <AutoCompleteStatic
                label={getResource("Maintenance.workOrder.search.statusField", "Status")}
                value={searchValues.status}
                onSelection={(e) =>
                    handleValuesChanged({ ...searchValues, status: e.target.value })
                }
                options={filteredStatuses}
            />,
        ];

        if (showExtendedFilters) {
            searchFields.push(
                <AutoCompleteStatic
                    label={getResource(
                        "Maintenance.workOrder.search.manufacturerField",
                        "Manufacturer"
                    )}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    disabled={Boolean(searchValues.assetModelId)}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            manufacturerId: e.target.value,
                        })
                    }
                />
            );

            searchFields.push(
                <AutoCompleteStatic
                    label={getResource("Maintenance.workOrder.search.modelLabel", "Model")}
                    value={searchValues.assetModelId}
                    options={allAssetModels && allAssetModels.items}
                    getOptionLabel={(option) => (
                        <Fragment>
                            {option.manufacturerName} - {option.name}
                        </Fragment>
                    )}
                    onSelection={(e) => {
                        const selectedAsset = allAssetModels.items.filter(
                            (item) => item.id === e.target.value
                        )[0];
                        const manufacturerId = selectedAsset
                            ? selectedAsset.manufacturerId
                            : searchValues.manufacturerId;

                        handleValuesChanged({
                            ...searchValues,
                            assetModelId: e.target.value,
                            manufacturerId: manufacturerId,
                        });
                    }}
                />
            );
        }

        return <PageListSearch searchFields={searchFields} />;
    }
);

export default Search;
