import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import Confirmation from "../../../shared/confirmation";
import LabelledOutline from "../../../shared/labelledoutline";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import AnswersEditor from "./components/answersEditor";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, id, onClose, allAssetTypes }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke, data: updateResult } = useAPI((values) => update(id, values));

    const [submittedValues, setSubmittedValues] = useState(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [answers, setAnswers] = useState([]);

    const onSubmit = async (values) => {
        setSubmittedValues(values);
        await invoke(values);
    };

    const onSubmitDuplicate = async () => {
        var values = submittedValues;
        values.values.createDuplicateConfirmed = true;
        await invoke(values);
    };

    useEffect(() => {
        data && setAnswers(data.answers);
    }, [data]);

    useEffect(() => {
        if (updateResult == null) return;

        if (updateResult) {
            onClose(true);
        } else {
            setConfirmationDialogOpen(true);
        }
    }, [updateResult, onClose]);

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.question[defaultCulture]) {
            errors.question = getResource(
                "Valuation.questions.edit.enterLanguage",
                "Please enter a question for the default language"
            );
        }

        if (!values.assetTypeIds || values.assetTypeIds.length === 0) {
            errors.assetTypeIds = getResource(
                "Valuation.questions.edit.selectAssetType",
                "Please select an type"
            );
        }

        if (values.answers) {
            values.answers.forEach((answer, i) => {
                if (!answer.answers[defaultCulture] || answer.answers[defaultCulture] === "") {
                    errors.answers = getResource(
                        "Valuation.questions.edit.addAnswerDefaultLanguage",
                        "Answer {index} needs a value for the default language",
                        { index: i + 1 }
                    );
                }
            });
        }

        return errors;
    };

    var isLoaded = data && allAssetTypes;

    const [isDirty, setIsDirty] = useState(false);
    return (
        <React.Fragment>
            <Confirmation
                open={confirmationDialogOpen}
                handleAction={onSubmitDuplicate}
                handleClose={() => setConfirmationDialogOpen(false)}
                headerText={getResource(
                    "Valuation.questions.edit.duplicateConfirmationHeader",
                    "Create a duplicate?"
                )}
                buttonText={getResource(
                    "Valuation.questions.edit.duplicateConfirmationButton",
                    "Create duplicate"
                )}
            >
                <ResourcedText
                    resourceKey="Valuation.questions.edit.duplicateConfirmationMessage"
                    defaultValue="This question already exists. Do you want to create a duplicate?"
                />
            </Confirmation>
            <FormDrawer
                title={getResource("Valuation.questions.edit.title", "Edit question")}
                onClose={() => onClose(false)}
                isDirty={isDirty}
            >
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={data}
                        onIsDirty={setIsDirty}
                        loading={loading}
                        okButtonText={getResource("Valuation.questions.edit.okButton", "Update")}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        showCultureSelector
                        renderForm={(
                            culture,
                            values,
                            handleChange,
                            errors,
                            updateValues,
                            isDefaultCulture
                        ) => {
                            return (
                                <React.Fragment>
                                    <TranslatableTextField
                                        errors={errors}
                                        autoFocus
                                        margin="normal"
                                        name="question"
                                        labelResourceKey="Valuation.questions.edit.questionField"
                                        labelDefaultValue="Question"
                                        fullWidth
                                        value={values.question}
                                        culture={culture}
                                        inputProps={{ maxLength: 255 }}
                                        handleChange={handleChange}
                                        required
                                    />

                                    <AutoCompleteStatic
                                        label={getResource(
                                            "Valuation.questions.edit.assetTypesField",
                                            "Types"
                                        )}
                                        errors={errors}
                                        multiple
                                        isMedium
                                        fullWidth
                                        name="assetTypeIds"
                                        value={values.assetTypeIds}
                                        options={allAssetTypes.items}
                                        onSelection={(e) => handleChange(e)}
                                        disabled={!isDefaultCulture}
                                        required
                                    />

                                    <LabelledOutline
                                        label={getResource(
                                            "Valuation.questions.add.answersLabel",
                                            "Answers"
                                        )}
                                    >
                                        <AnswersEditor
                                            culture={culture}
                                            error={errors["answers"]}
                                            answers={answers}
                                            disabled={!isDefaultCulture}
                                            onAnswersChanged={(newAnswers) => {
                                                setAnswers(newAnswers);
                                                handleChange({
                                                    target: { name: "answers", value: newAnswers },
                                                });
                                            }}
                                        />
                                    </LabelledOutline>
                                </React.Fragment>
                            );
                        }}
                    />
                )}
            </FormDrawer>
        </React.Fragment>
    );
});
