import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { List, ListItem, ListItemIcon } from "@material-ui/core";
import arrayMove from "array-move";
import ReorderIcon from "@material-ui/icons/Reorder";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    list: {
        "&.--disabled .dragHandle": {
            display: "none",
        },
    },

    listItem: {
        marginBottom: theme.spacing(1),
    },

    itemContent: {
        flexGrow: 1,
        minWidth: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",

        "& > * + *": {
            marginLeft: `${theme.spacing(1)}px !important`,
        },

        "& > * + *:not(button)": {
            [theme.breakpoints.up("md")]: {
                marginLeft: `${theme.spacing(2)}px !important`,
            },
        },
    },

    helperClass: {
        zIndex: 10000,
        backgroundColor: theme.palette.white,
    },
}));

const DragHandle = SortableHandle(() => (
    <ListItemIcon className="dragHandle">
        <ReorderIcon />
    </ListItemIcon>
));

const SortableItem = SortableElement(({ item, itemIndex, renderItemContent }) => {
    const classes = useStyles();

    return (
        <ListItem
            className={classes.listItem}
            disableGutters
            ContainerComponent="div"
            key={itemIndex}
        >
            <DragHandle />
            <div className={classes.itemContent}>{renderItemContent(item, itemIndex)}</div>
        </ListItem>
    );
});

const SortableListContainer = SortableContainer(({ items, renderItemContent, disabled }) => {
    const classes = useStyles();
    return (
        items &&
        items.length > 0 && (
            <List component="ol" className={clsx(classes.list, disabled && "--disabled")}>
                {items.map((item, index) => (
                    <SortableItem
                        key={index}
                        index={index}
                        itemIndex={index}
                        item={item}
                        renderItemContent={renderItemContent}
                    />
                ))}
            </List>
        )
    );
});

const SortableList = ({ items, sortingChanged, renderItemContent, disabled }) => {
    const classes = useStyles();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sortedItems = arrayMove(items, oldIndex, newIndex);
        sortingChanged(sortedItems);
    };

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <SortableListContainer
            onSortEnd={onSortEnd}
            useDragHandle
            lockAxis="y"
            items={items}
            renderItemContent={renderItemContent}
            disabled={disabled}
            helperClass={classes.helperClass}
        />
    );
};

export default SortableList;
