import React, { useContext, useEffect } from "react";
import useDialog from "../../../shared/usedialog";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import Add from "./add";
import Revoke from "./revoke";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-users", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        roleId: null,
        scope: null,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.roleId,
                searchParams.scope,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.roleId,
            searchParams.scope,
            searchParams.page + 1,
            searchParams.pageSize,
            companyScope.id, assetCollectionScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleRevoke,
        close: handleCloseRevoke,
        visible: revokeToggled,
        args: revokeArgs,
    } = useDialog(invoke);

    function handleSearch(searchText, roleId, scope) {
        setSearchParams({ ...searchParams, searchText, roleId, scope, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operation) {
        if (!companyScope.id && !assetCollectionScope.id) {
            return operation;
        }

        return {
            name: operation,
            companyId: companyScope.id,
            assetCollectionId: assetCollectionScope.id,
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="user-roles-assign"
                path="/security/permissions"
                render={(props) => (
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        getRequiredOperation={getRequiredOperation}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onAdd={handleAdd}
                        onRevoke={handleRevoke}
                        defaultDebounceInterval={defaultDebounceInterval}
                        {...props}
                    />
                )}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />

            <Revoke
                id={revokeArgs && revokeArgs.id}
                userId={revokeArgs && revokeArgs.userId}
                userName={revokeArgs && revokeArgs.userName}
                roleName={revokeArgs && revokeArgs.roleName}
                open={revokeToggled}
                onClose={handleCloseRevoke}
            />
        </React.Fragment>
    );
};
