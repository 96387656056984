import React from "react";
import { makeStyles } from "@material-ui/styles";
import withResources from "../../../shared/textresources/withresources";
import FormattedPercentage from "../../../shared/formattedPercentage";
import LabelledOutline from "../../../shared/labelledoutline";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: "collapse",

        "& th": {
            textAlign: "left",
            fontWeight: "inherit",
        },

        "& th, & td": {
            padding: `0 ${theme.spacing(2)}px 0 0`,
        },
    },

    explanation: {
        marginBottom: 0,
    },
}));

export default withResources(({ getResource, questions }) => {
    if (!questions || questions.length === 0) {
        return null;
    }

    const classes = useStyles();

    const maxPercentage = () => {
        var percentage = 0;
        questions
            .filter((question) => question.answers.some((answer) => answer.visible))
            .forEach((question) => {
                if (question.answers.length) {
                    percentage =
                        percentage +
                        Math.max(
                            ...question.answers
                                .filter((answer) => answer.visible)
                                .map((answer) => answer.answerCorrection)
                        );
                }
            });
        return Math.round(percentage * 100) / 100;
    };

    const minPercentage = () => {
        var percentage = 0;
        questions
            .filter((question) => question.answers.some((answer) => answer.visible))
            .forEach((question) => {
                if (question.answers.length) {
                    percentage =
                        percentage +
                        Math.min(
                            ...question.answers
                                .filter((answer) => answer.visible)
                                .map((answer) => answer.answerCorrection)
                        );
                }
            });
        return Math.round(percentage * 100) / 100;
    };

    return (
        <LabelledOutline
            label={getResource(
                "Valuation.questionnaire.questions.creditScore.header",
                "Credit score"
            )}
        >
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <th>
                            {getResource(
                                "Valuation.questionnaire.questions.bestCase.header",
                                "Best case scenario"
                            )}
                            :
                        </th>
                        <td>
                            <FormattedPercentage value={maxPercentage()} decimals={2} />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            {getResource(
                                "Valuation.questionnaire.questions.worstCase.header",
                                "Worst case scenario"
                            )}
                            :
                        </th>
                        <td>
                            <FormattedPercentage value={minPercentage()} decimals={2} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <FormHelperText className={classes.explanation}>
                {getResource(
                    "Valuation.questionnaire.questions.creditScore.value",
                    "Each answer: question minimum + ((answer score / 100) * (question minimum + question maximum)) = NCS Answer"
                )}
            </FormHelperText>
        </LabelledOutline>
    );
});
