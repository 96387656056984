import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(assetModelId) {
    const url = `/api/valuation/countryCorrection/${assetModelId}`;

    return await interceptedFetch(url);
}

async function update(assetModelId, values) {
    return await interceptedFetch(`/api/valuation/countryCorrection/${assetModelId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

export { getAll, update };
