import DataValidator from "./datavalidator.js";
const DV = new DataValidator();

class ValuationDataBuilder {
    getValuationDataDto(data, series) {
        let curveRatio = DV.isFloatAndGreaterThanZero(data.valuationInfo.curveRatio)
            ? parseFloat(data.valuationInfo.curveRatio)
            : null;
        let liquidationValueFixed = DV.isNumberAndGreaterThanZero(
            data.valuationInfo.liquidationValueDifferenceAsFixed
        )
            ? Number(data.valuationInfo.liquidationValueDifferenceAsFixed)
            : null;
        let liquidationValuePercentage = DV.isNumberAndGreaterThanOrEqualToZero(
            data.valuationInfo.liquidationValueDifferenceAsPercentage
        )
            ? Number(data.valuationInfo.liquidationValueDifferenceAsPercentage)
            : null;

        let args = {
            data: {
                Comment: data.valuationInfo.comment,
                CurrencyId: data.valuationInfo.valutaId,
                CurveRatio: curveRatio,
                EndBuildYear: data.valuationInfo.endBuildYear,
                LiquidationValueDifferenceAsFixed: liquidationValueFixed,
                LiquidationValueDifferenceAsPercentage: liquidationValuePercentage,
                FairMarketValue: data.valuationInfo.fairMarketValue,
                Id: data.currentObjectId,
                MachineName: data.title.text,
                PeriodicValuationValues: [],
                RemainingValueAfterTechnicalLife: data.valuationInfo.remainingValueAfterTechLife,
                StartBuildYear: data.valuationInfo.startBuildYear,
                TechnicalLife: data.valuationInfo.technicalLife,
            },
        };

        args.data.PeriodicValuationValues = series[0].data.map((item, index) => {
            let fairMarketValue = item.y;
            let liquidationValue = series[1].data[index].y;

            return {
                Period: index,
                FairMarketValue: fairMarketValue,
                LiquidationValue: liquidationValue,
            };
        });

        return args;
    }
}

export default ValuationDataBuilder;
