import React, { useState, Fragment } from "react";
import { Checkbox, CircularProgress, FormControlLabel, FormHelperText } from "@material-ui/core";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import useAPI from "../../../../shared/useapi";
import {
    getQuestionsForAssetCategory,
    getQuestionsForAssetModel,
    getQuestionsForAsset,
} from "../dataservice";
import {
    getChildren,
    getParents,
    getPath,
    filter,
} from "../../../library/assetcategories/dataservice";
import { getAll as getAssetModels } from "../../../library/assetmodels/dataservice";

import Confirmation from "../../../../shared/confirmation";
import withResources from "../../../../shared/textresources/withresources";
import useStyles from "./useStyles";
import QuestionsEditor from "./index";
import PagedListInputForAssets from "../../../../shared/pagedListInput/pagedListInputForAssets";
import AutoComplete from "../../../../shared/autocomplete";
import DrillDownInput from "../../../../shared/drilldowninput";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return false;
    }

    const classes = useStyles();

    const { invoke: loadQuestions, data: questions, loading: questionsLoading } = useAPI(
        (assetCategoryId, assetModelId, assetId) => {
            if (assetCategoryId) {
                return getQuestionsForAssetCategory(assetCategoryId);
            } else if (assetModelId) {
                return getQuestionsForAssetModel(assetModelId);
            } else if (assetId) {
                return getQuestionsForAsset(assetId);
            } else {
                return null;
            }
        },
        false
    );

    const [values, setValues] = useState({
        type: null,
        replace: false,
    });

    return (
        <Confirmation
            open
            handleAction={() =>
                onClose({
                    replace: values.replace,
                    questions: questions,
                })
            }
            handleClose={() => onClose(false)}
            headerText={getResource(
                "Valuation.questionnaire.questions.copy.header",
                "Copy questions from an existing questionnaire"
            )}
            buttonText={getResource("Valuation.questionnaire.questions.copy.okButton", "Copy")}
            classes={{ root: classes.dialog }}
            actionDisabled={!questions || questions.length === 0}
        >
            <div>
                {getResource(
                    "Valuation.questionnaire.questions.copy.intro",
                    "You can copy questions from an existing questionnaire. After copying you can modify the questions without affecting the source questionnaire."
                )}
            </div>

            <div className={classes.typeSelector}>
                <ToggleButtonGroup
                    value={values.type}
                    exclusive
                    size="small"
                    onChange={(e, value) => {
                        var copy = {
                            ...values,
                            type: value,
                            assetCategory: null,
                            assetModel: null,
                            asset: null,
                        };
                        setValues(copy);
                        loadQuestions(null, null, null); //Reset questions
                    }}
                >
                    <ToggleButton value="assetCategory">
                        {getResource(
                            "Valuation.questionnaire.questions.copy.action.assetCategory",
                            "Category"
                        )}
                    </ToggleButton>
                    <ToggleButton value="assetModel">
                        {getResource(
                            "Valuation.questionnaire.questions.copy.action.assetModel",
                            "Model"
                        )}
                    </ToggleButton>
                    <ToggleButton value="asset">
                        {getResource(
                            "Valuation.questionnaire.questions.copy.action.asset",
                            "Asset"
                        )}
                    </ToggleButton>
                </ToggleButtonGroup>
                <FormHelperText>
                    {getResource(
                        "Valuation.questionnaire.questions.copy.selectType",
                        "Please select where to copy the questions from."
                    )}
                </FormHelperText>
            </div>

            {values.type && (
                <Fragment>
                    {values.type === "assetCategory" && (
                        <div>
                            <DrillDownInput
                                name="assetCategory"
                                itemName={getResource(
                                    "Valuation.questionnaire.questions.copy.assetCategoryItemName",
                                    "category"
                                )}
                                label={getResource(
                                    "Valuation.questionnaire.questions.copy.assetCategoryField",
                                    "Category"
                                )}
                                getParents={getParents}
                                getChildren={getChildren}
                                getPath={getPath}
                                filter={filter}
                                value={values.assetCategory}
                                setValue={(e) => {
                                    var copy = {
                                        ...values,
                                        assetCategory: e.target.value,
                                    };
                                    setValues(copy);

                                    loadQuestions(e.target.value.id, null, null);
                                }}
                                fullWidth
                                isMedium
                            />
                            <FormHelperText variant="outlined" className={classes.formHelperText}>
                                {getResource(
                                    "Valuation.questionnaire.questions.copy.selectAssetCategory",
                                    "Select the category from which to copy the questions."
                                )}
                            </FormHelperText>
                        </div>
                    )}
                    {values.type === "assetModel" && (
                        <div>
                            <AutoComplete
                                label={getResource(
                                    "Valuation.questionnaire.questions.copy.assetModelField",
                                    "Model"
                                )}
                                value={values.assetModel}
                                onGetData={(name, page, pageSize) =>
                                    getAssetModels(name, null, null, page, pageSize)
                                }
                                onSelection={(e) => {
                                    var copy = {
                                        ...values,
                                        assetModel: e.target.value,
                                    };
                                    setValues(copy);

                                    loadQuestions(null, e.target.value.id, null);
                                }}
                                getTextField={(option) =>
                                    option.manufacturerName
                                        ? `${option.manufacturerName} ${option.name}`
                                        : option.name
                                }
                                renderOption={(option) => (
                                    <div>
                                        {option.manufacturerName} {option.name}
                                    </div>
                                )}
                                disableClearable
                            />
                            <FormHelperText variant="outlined" className={classes.formHelperText}>
                                {getResource(
                                    "Valuation.questionnaire.questions.copy.selectAssetModel",
                                    "Select the model from which to copy the questions."
                                )}
                            </FormHelperText>
                        </div>
                    )}
                    {values.type === "asset" && (
                        <div>
                            <PagedListInputForAssets
                                label={getResource(
                                    "Valuation.questionnaire.questions.copy.assetField",
                                    "Asset"
                                )}
                                name="asset"
                                value={values.asset}
                                onSelection={(e) => {
                                    var copy = {
                                        ...values,
                                        asset: e.target.value,
                                    };
                                    setValues(copy);

                                    loadQuestions(null, null, e.target.value.id);
                                }}
                                fullWidth
                                isMedium
                                disableClearable
                                dialogTitle={getResource(
                                    "Valuation.questionnaire.questions.copy.assetDialogTitle",
                                    "Select an asset"
                                )}
                                helperText={getResource(
                                    "Valuation.questionnaire.questions.copy.selectAsset",
                                    "Select the asset from which to copy the questions."
                                )}
                            />
                        </div>
                    )}

                    {questionsLoading && (
                        <div>
                            <CircularProgress />
                            {getResource(
                                "Valuation.questionnaire.questions.copy.loadingQuestionnaire",
                                "Loading questionnaire"
                            )}
                        </div>
                    )}

                    {!questionsLoading && questions && questions.length > 0 && (
                        <div className={classes.inheritedQuestions}>
                            <QuestionsEditor
                                disabled
                                label={getResource(
                                    "Valuation.questionnaire.questions.copy.questionsToCopy",
                                    "Questions to copy"
                                )}
                                questions={questions}
                                isExpandable
                            />
                        </div>
                    )}

                    {!questionsLoading && questions && questions.length === 0 && (
                        <div style={{ margin: "16px 0" }}>
                            <Alert severity="warning">
                                {getResource(
                                    "Valuation.questionnaire.questions.copy.noQuestions",
                                    "There are no questions to copy"
                                )}
                            </Alert>
                        </div>
                    )}

                    {!questionsLoading && questions && questions.length > 0 && (
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.replace}
                                        onChange={(e) => {
                                            var copy = {
                                                ...values,
                                                replace: e.target.checked,
                                            };
                                            setValues(copy);
                                        }}
                                        name="replace"
                                    />
                                }
                                label={getResource(
                                    "Valuation.questionnaire.questions.copy.replaceField",
                                    "Replace questions"
                                )}
                                variant="outlined"
                            />
                            <FormHelperText variant="outlined" className={classes.formHelperText}>
                                {getResource(
                                    "Valuation.questionnaire.questions.copy.replaceWarning",
                                    "All questions already present on the questionnaire will be replaced by the questions listed above."
                                )}
                            </FormHelperText>
                        </div>
                    )}
                </Fragment>
            )}
        </Confirmation>
    );
});
