import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import useStyles from "./useStyles";
import PropTypes from "prop-types";
import withResources from "../textresources/withresources";
import clsx from "clsx";

const AddButton = withResources((props) => {
    const { getResource, buttonText, handleClick, disabled = false, buttonRef, className } = props;

    const classes = useStyles();

    return (
        <Button
            size="small"
            ref={buttonRef}
            onClick={handleClick}
            disabled={disabled}
            className={clsx(classes.root, className)}
        >
            <AddCircleIcon className={classes.icon} />
            {buttonText ? buttonText : getResource("Shell.addButton.addLabel", "Add")}
        </Button>
    );
});

AddButton.propTypes = {
    getResources: PropTypes.string,
    buttonText: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    buttonRef: PropTypes.object,
    className: PropTypes.string,
};

export default AddButton;
