import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { formatDate } from "../../../shared/formattedDate";
import { getAllForAsset } from "../component/dataservice";
import withResources from "../../../shared/textresources/withresources";
import TableWidget from "../../../shared/tableWidget";
import useAuthorization from "../../../shared/authentication/useauthorization";
import useDialog from "../../../shared/usedialog";
import Install from "./install";
import { Settings as ComponentIcon } from "@material-ui/icons";
import LocalizationContext from "../../../shared/localization/localizationcontext";

const Widget = withResources(({ getResource, asset }) => {
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const { data: assetComponents, loading, invoke } = useAPI(
        () => getAllForAsset(null, asset.id, 1, 4),
        true
    );
    const { open: handleInstall, close: handleCloseInstall, visible: installToggled } = useDialog(
        invoke
    );

    const data =
        assetComponents &&
        assetComponents.items.map((component) => ({
            __url: `/assets/components/${component.id}`,
            name: component.name,
            installedOn: formatDate(component.installedOn, selectedUiCulture, selectedTimezone),
        }));

    return (
        <React.Fragment>
            <TableWidget
                headerTitle={getResource("Asset.assetComponents.widget.header", "Components")}
                headerLink={`/assets/asset-components/${asset.id}`}
                headerLinkTitle={getResource(
                    "Asset.assetComponents.widget.chevronAlt",
                    `See all components`
                )}
                content={
                    assetComponents &&
                    getResource(
                        "Asset.assetComponents.widget.content",
                        `{count} components are currently installed`,
                        { count: assetComponents.total }
                    )
                }
                clickProperty="__url"
                data={data}
                loading={loading}
                hasContent={data !== null && data.length > 0}
                icon={<ComponentIcon />}
                noDataText={getResource(
                    "Asset.assetComponents.widget.noDataText",
                    `There are no components installed on this asset`
                )}
                addButtonText={getResource(
                    "Asset.assetComponents.widget.noDataButtonText",
                    `Install`
                )}
                onAddButtonClick={handleInstall}
                addButtonRequiredOperation={{
                    name: "components-install",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                rightCellTextAlign={"right"}
            />

            <Install open={installToggled} onClose={handleCloseInstall} asset={asset} />
        </React.Fragment>
    );
});

export default ({ asset }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: "assets-view-components",
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <Widget asset={asset} />;
};
