import React, { useContext, useEffect } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Create from "./create";
import Edit from "./edit";
import UpdateStatus from "./updateStatus";
import Download from "./download";
import Delete from "./delete";
import Overview from "./overview";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-invoices", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        status: null,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.status,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.status,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleCreate, close: handleCloseCreate, visible: createToggled } = useDialog(
        invoke
    );

    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: invoiceDelete
    } = useDialog(invoke);

    const {
        open: handleUpdateStatus,
        close: handleCloseUpdateStatus,
        visible: updateStatusToggled,
        args: updateStatusArgs,
    } = useDialog(invoke);
    const {
        open: handleDownload,
        close: handleCloseDownload,
        visible: downloadToggled,
        args: downloadId,
    } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);

    function handleSearch(searchText, status) {
        setSearchParams({ ...searchParams, searchText, status, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <div>
            <PrivateRoute
                requiredOperation={"invoice-view"}
                path="/administration/invoices"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onCreate={handleCreate}
                            onDownload={handleDownload}
                            onEdit={handleEdit}
                            onUpdateStatus={handleUpdateStatus}
                            onDelete={handleDelete}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />

            <Create open={createToggled} onClose={handleCloseCreate} companyId={companyScope.id} />

            <Edit open={editToggled} onClose={handleCloseEdit} id={editId} />

            <UpdateStatus
                id={updateStatusArgs && updateStatusArgs.id}
                currentStatus={updateStatusArgs && updateStatusArgs.status}
                open={updateStatusToggled}
                onClose={handleCloseUpdateStatus}
            />
            <Download
                invoiceId={downloadId}
                open={downloadToggled}
                onClose={handleCloseDownload}
                directDownload
            />

            <Delete open={deleteToggled} invoice={invoiceDelete} onClose={handleCloseDelete} />
        </div>
    );
};
