import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    factActions: {
        marginTop: theme.spacing(1),
    },

    action: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        padding: "4px 0 4px 15px",
        border: "1px solid",
        borderRadius: 4,

        "&.overdue": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },

        "&.impending": {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
        },
    },

    icon: {
        marginRight: theme.spacing(2),
    },
}));

export default useStyles;
