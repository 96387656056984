import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    scheduledEventOutline: {
        marginTop: theme.spacing(2),
    },

    locationSwitchWrapper: {
        marginTop: theme.spacing(2),
    },

    locationKnownHelperText: {
        margin: `0 0 ${theme.spacing(1)}px`,
    },
}));

export default useStyles;
