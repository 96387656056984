import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { get, deleteMultiple } from "./dataservice";

import Confirmation from "../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, components, open, onClose }) => {
    if (!open) {
        return false;
    }

    var isValid = (component) => !component.isSealed && !component.isInstalled;

    const { loading, invoke } = useAPI(deleteMultiple);

    const onlyValidComponentsSelected = components.every(isValid);
    const onlyInvalidComponentsSelected = components.every(x => !isValid(x));
    const someInvalidComponentsSelected = !onlyValidComponentsSelected && !onlyInvalidComponentsSelected;

    const handleAction = async () => {
        if (onlyInvalidComponentsSelected) {
            onClose(false);
        }
        else if (await invoke(components.map(x=>x.id))) {
            onClose(true);
        }
    };

    const headerText = getResource("Asset.component.deleteMultiple.header", "Delete components?");


    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleAction}
            handleClose={() => onClose(false)}
            headerText={headerText}
            buttonText={onlyInvalidComponentsSelected ? getResource("Asset.component.deleteMultiple.okButton", "OK") : getResource("Asset.component.deleteMultiple.deleteButton", "Delete")}
            cancelHidden={onlyInvalidComponentsSelected}
        >
            {
                onlyValidComponentsSelected && getResource(
                    "Asset.component.deleteMultiple.onlyValidComponentsSelectedMessage",
                    "Are you sure you want to delete {count} components?",
                    { count: components.length }
                )
            }
            {
                onlyInvalidComponentsSelected && getResource(
                    "Asset.component.deleteMultiple.onlyInvalidComponentsSelectedMessage",
                    "Installed/sealed components cannot be deleted and should be uninstalled/unsealed first."
                )
            }
            {
                someInvalidComponentsSelected && getResource(
                    "Asset.component.deleteMultiple.someInvalidComponentsSelectedMessage",
                    "Some of the components you have selected are installed/sealed and should be uninstalled/unsealed before they can be deleted. Would you like to delete the {count} components that are not installed/sealed?",
                    { count: components.filter(x => isValid(x)).length }
                )
            }
        </Confirmation>
    );
});
