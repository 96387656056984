import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(name, operationId, scope, page, pageSize) {
    const params = {
        page,
        pageSize,
        name,
        operationId,
        scope,
    };

    const url = `/api/security/role?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/security/role/${id}`;

    return await interceptedFetch(url);
}

async function getScopes() {
    const url = `/api/security/role/scopes`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/security/role", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/security/role/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/security/role/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, getScopes, add, update, del };
