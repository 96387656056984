import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "300px",
    },

    inputRoot: {
        minHeight: 56,
    },

    input: {
        fontSize: 13,
    },

    clearIndicator: {
        "& svg": {
            fontSize: 13,
        },
    },

    loading: {
        display: "none",
    },

    noOptions: {
        borderRadius: 3,
        border: "1px solid #263238",
        fontWeight: 500,
        textAlign: "center",
        color: "inherit",
    },

    paper: {
        borderRadius: 0,
    },

    listbox: {
        padding: 0,
        borderRadius: 3,
        border: "1px solid #263238",
    },

    addNewItemButton: {
        marginTop: theme.spacing(1),
    },
}));

export default useStyles;
