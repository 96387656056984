import React, { useState } from "react";
import InvalidationContext from "./invalidationcontext";

export default ({ children }) => {
    const [invalidationMap, setInvalidationMap] = useState({});

    const invalidate = (name) => {
        let copy = { ...invalidationMap };
        copy[name] = new Date();
        setInvalidationMap(copy);
    };

    return (
        <InvalidationContext.Provider
            value={{
                invalidate: invalidate,
                invalidationMap: invalidationMap,
            }}
        >
            {children}
        </InvalidationContext.Provider>
    );
};
