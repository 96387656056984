import React, { Fragment } from "react";
import { TableCell } from "@material-ui/core";
import Today from "@material-ui/icons/Today";
import _ from "underscore";

import ResourcedText from "../../../shared/textresources/resourcedtext";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import withResources from "../../../shared/textresources/withresources";
import PagedListContent from "../../assets/asset/pagedListContent";
import Status from "../../../shared/status";

const Overview = withResources((props) => {
    const {
        getResource,
        history,
        availablePageSizes,
        loading,
        searchParams,
        onPageChange,
        onPageSizeChange,
        onSearch,
        defaultDebounceInterval,
    } = props;
    const data = props.data || {};
    const items = data.items;
    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    return (
        <Fragment>
            <WorkingContext requiredOperation="assets-availability-view" />
            <ContentHolder>
                <Header
                    title={getResource(
                        "Planning.assetAvailability.header.title",
                        "Asset availability"
                    )}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Planning.assetAvailability.noResults",
                        "No assets available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <PagedListContent
                        items={items}
                        handleRowClick={(row) =>
                            history.push(`/planning/calendar/Assets/${row.id}`)
                        }
                        showFinancials={false}
                        getRequiredOperationForRowClick={(row) => {
                            return {
                                name: "scheduled-events-view",
                                companyId: row.companyId,
                                assetCollectionId: row.assetCollectionIds,
                            };
                        }}
                        renderExtraHeaders={() => (
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Planning.assetAvailability.availabilityHeader"
                                    defaultValue="Availability"
                                />
                            </TableCell>
                        )}
                        renderExtraColumns={(row) => (
                            <TableCell component="td">
                                <Status
                                    text={getResource(
                                        `Planning.scheduledEvent.referenceStatus.${row.availability.toLowerCase()}`,
                                        row.availability
                                    )}
                                    color={row.availability === "Available" ? "green" : "red"}
                                />
                            </TableCell>
                        )}
                        renderActionMenu={(row) => (
                            <ActionMenu
                                actions={[
                                    {
                                        text: getResource(
                                            "Planning.assetAvailability.viewCalendarButton",
                                            "View calendar"
                                        ),
                                        icon: <Today />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "scheduled-events-view",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(`/planning/calendar/Assets/${row.id}`),
                                    },
                                ]}
                            />
                        )}
                    />
                </PagedList>
            </ContentHolder>
        </Fragment>
    );
});

export default Overview;
