import React, { useContext, useState } from "react";
import withResources from "../../../shared/textresources/withresources";
import { getAllForAssetContext as getAssetModels } from "./../../library/assetmodels/dataservice";
import { getAllForAssetContext as getManufacturers } from "./../../library/manufacturers/dataservice";
import PageListSearch from "../../../shared/pagelistsearch";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import SearchIcon from "@material-ui/icons/Search";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import useAPI from "../../../shared/useapi";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.assetModelId, values.manufacturerId);
    }

    const { data: allManufacturers } = useAPI(() => getManufacturers(null, null, null), true, [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    const { data: allAssetModels } = useAPI(
        () => getAssetModels(null, searchValues.manufacturerId, null, null),
        true,
        [searchValues.manufacturerId, companyScope.id, assetCollectionScope.id]
    );

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Valuation.assetModelQuestionnaire.search.searchText"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    AdornmentIcon={<SearchIcon />}
                    clearable
                    className="e2e_pagedListGenericSearchField"
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Valuation.modelValuation.search.manufacturerField",
                        "Manufacturer"
                    )}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    disabled={Boolean(searchValues.assetModelId)}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            manufacturerId: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource("Valuation.assetQuestionnaire.search.modelLabel", "Model")}
                    value={searchValues.assetModelId}
                    options={allAssetModels && allAssetModels.items}
                    getOptionLabel={(option) => (
                        <>
                            {option.manufacturerName} - {option.name}
                        </>
                    )}
                    onSelection={(e) => {
                        const selectedAsset = allAssetModels.items.filter(
                            (item) => item.id === e.target.value
                        )[0];
                        const manufacturerId = selectedAsset
                            ? selectedAsset.manufacturerId
                            : searchValues.manufacturerId;

                        handleValuesChanged({
                            ...searchValues,
                            assetModelId: e.target.value,
                            manufacturerId: manufacturerId,
                        });
                    }}
                />,
            ]}
        />
    );
});

export default Search;
