import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(1)}px 0`,
    },

    fileUploaded: {
        display: "flex",
        alignItems: "center",
    },

    deleteButton: {
        marginLeft: theme.spacing(0.5),
    },

    fileName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

export default useStyles;
