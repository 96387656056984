import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import RoutedIndex from "../workOrder/routedIndex";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { get as getAsset } from "../../assets/asset/dataservice";

const AssetWorkOrderList = (props) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    var assetId = props.match.params["assetId"];
    const { data: asset } = useAPI(() => getAsset(assetId), true, [assetId]);

    var assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);

    return <RoutedIndex name={assetName} assetId={assetId} componentId={null} {...props} />;
};

export default () => {
    return (
        <PrivateRoute
            requiredOperation={"work-orders-view"}
            path="/maintenance/asset-work-orders/:assetId(\d+)"
            render={(props) => <AssetWorkOrderList {...props} />}
        />
    );
};
