import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },

    table: {
        tableLayout: "fixed",

        "& th": {
            background: "#F4F6F8",
            color: "#607D8B",
            borderBottom: `1px solid #607D8B`,
        },
    },

    cell: (props) => ({
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        transition: "0.1s background-color ease-out",

        "&:first-child": {
            width: props.leftCellWidth,
        },

        "&:last-child": {
            paddingLeft: 0,
            textAlign: props.rightCellTextAlign,
            width: props.rightCellWidth,
        },

        "&.odd": {
            backgroundColor: theme.palette.grey[100],
        },
    }),

    clickableRow: {
        cursor: "pointer",

        "& td:first-child": {
            textDecoration: "underline",
            color: theme.palette.primary.main,
        },

        "&:hover .odd": {
            backgroundColor: theme.palette.grey[200],
        },

        "&:hover .even": {
            backgroundColor: theme.palette.grey[100],
        },
    },

    caption: {
        display: "block",
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        textAlign: "center",
    },

    content: {
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        textAlign: "center",
    },
}));

export default useStyles;
