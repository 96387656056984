import React from "react";
import withResources from "../../../../../shared/textresources/withresources";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import FormattedCurrency from "../../../../../shared/formattedCurrency";
import useStyles from "./useStyles";

const TaskTable = withResources((props) => {
    const {
        getResource,
        data,
        currencyShortName,
        leftColumnHeader,
        rightColumnHeader,
        getLeftColumnDisplayValue,
        getLeftColumnValue,
        getRightColumnValue,
        getLeftColumnTotalValue,
        noItemsText,
    } = props;
    const classes = useStyles();

    const itemsAvailable = data.items.length > 0;

    return (
        <div className={classes.tableSection}>
            <Typography variant="h4" component="h3">
                {data.name}
            </Typography>

            {itemsAvailable ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow classes={{ root: classes.tableRow }}>
                                <TableCell>
                                    {getResource(
                                        "Maintenance.workOrderReport.TableHeader.Description",
                                        "Description"
                                    )}
                                </TableCell>
                                <TableCell>{leftColumnHeader}</TableCell>
                                <TableCell align="right">{rightColumnHeader}</TableCell>

                                <TableCell align="right">
                                    {getResource(
                                        "Maintenance.workOrderReport.TableHeader.amount",
                                        "Amount"
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.items.map((row, i) => {
                                const leftDisplay = getLeftColumnDisplayValue
                                    ? getLeftColumnDisplayValue(row)
                                    : null;
                                const left = getLeftColumnValue(row);
                                const right = getRightColumnValue(row);
                                const total = left * right;

                                return (
                                    <TableRow key={i} classes={{ root: classes.tableRow }}>
                                        <TableCell>{row.description}</TableCell>

                                        <TableCell>{leftDisplay ? leftDisplay : left}</TableCell>
                                        <TableCell align="right">
                                            <FormattedCurrency
                                                decimals={2}
                                                value={right}
                                                currency={currencyShortName}
                                            />
                                        </TableCell>

                                        <TableCell align="right">
                                            <FormattedCurrency
                                                decimals={2}
                                                value={total}
                                                currency={currencyShortName}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            <TableRow classes={{ root: classes.tableRow }} className="--totals">
                                <TableCell>
                                    <div className="totalLabel">
                                        {getResource(
                                            "Maintenance.workOrderReport.Table.totalAmount",
                                            "Total"
                                        )}
                                    </div>
                                </TableCell>

                                <TableCell>
                                    <div className="totalHours">
                                        {getLeftColumnTotalValue &&
                                            getLeftColumnTotalValue(data.items)}
                                    </div>
                                </TableCell>

                                <TableCell align="right" colSpan="2">
                                    <div className="totalAmount">
                                        <FormattedCurrency
                                            decimals={2}
                                            value={data.amount}
                                            currency={currencyShortName}
                                        />
                                    </div>

                                    <div className="totalEstimated">
                                        <span>
                                            {getResource(
                                                "Maintenance.workOrderReport.Table.Estimated",
                                                "Estimated"
                                            )}
                                            &nbsp;
                                        </span>
                                        <FormattedCurrency
                                            value={data.estimated}
                                            decimals={2}
                                            currency={currencyShortName}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <span>{noItemsText}</span>
            )}
        </div>
    );
});

export default TaskTable;
