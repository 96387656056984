export default {
    SINGLE_LINE_TEXT: 1,
    MULTI_LINE_TEXT: 2,
    NUMBER: 3,
    MONEY: 4,
    BOOLEAN: 5,
    OPTION_LIST: 6,
    DATE: 7,
    TIME: 8,
    DATETIME: 9,
    LINK: 10,
};
