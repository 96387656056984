import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 800,
        marginBottom: theme.spacing(3),

        "& p": {
            marginTop: 0,
        },
    },
}));

export default useStyles;
