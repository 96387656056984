import React from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import Configuration from "./configuration";

export default () => {
    return (
        <PrivateRoute
            requiredOperation="content-configuration-edit"
            path="/content/configuration"
            render={(props) => <Configuration {...props} />}
        />
    );
};
