function formatLibrary(library) {
    return library.toLowerCase().replace(" ", "-");
}

class DocumentLibraryRegistry {
    constructor(libraries) {
        this.libraries = new Map();

        if (libraries) {
            for (const library of libraries) {
                this.libraries.set(library.name.toLowerCase(), library);
            }
        }

        this.referenceTypes = new Map();

        this.registerReferenceType = this.registerReferenceType.bind(this);
        this.getRequiredOperation = this.getRequiredOperation.bind(this);
        this.getSupportedDocumentTypes = this.getSupportedDocumentTypes.bind(this);
        this.isSystemLibrary = this.isSystemLibrary.bind(this);
        this.getReferenceMethod = this.getReferenceMethod.bind(this);

        this.cache = new Map();
    }

    registerReferenceType(library, referenceType) {
        this.referenceTypes.set(library, referenceType);
    }

    getReferenceMethod(library) {
        const referenceType = this.referenceTypes.get(library);
        if (!referenceType) {
            return null;
        }

        return referenceType[0].fetchMethod;
    }

    getRequiredOperation(library, action, reference) {
        const operationName = `${formatLibrary(library)}-${action}`;

        return reference
            ? {
                  name: operationName,
                  companyId: reference.companyId,
                  assetCollectionId: reference.assetCollectionIds,
              }
            : operationName;
    }

    getSupportedDocumentTypes(library) {
        if (!this.libraries.has(library.toLowerCase())) {
            return [];
        }

        return this.libraries.get(library.toLowerCase()).supportedDocumentTypes;
    }

    isSystemLibrary(library) {
        if (!this.libraries.has(library.toLowerCase())) {
            return false;
        }

        return this.libraries.get(library.toLowerCase()).isSystemLibrary;
    }
}

export default DocumentLibraryRegistry;
