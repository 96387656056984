import React from "react";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./components/editForm";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        validate(values, errors, getResource);

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.manufacturers.add.title", "Create manufacturer")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            <LocalizedForm
                initialValues={{ name: "" }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource("Library.manufacturers.add.okButton", "Create")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                renderForm={(_, values, handleChange, errors) => (
                    <EditForm errors={errors} values={values} handleChange={handleChange} />
                )}
                required
            />
        </FormDrawer>
    );
});
