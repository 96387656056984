import React, { useState, useContext, useEffect, Fragment, useRef } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";
import useAPI from "../../../shared/useapi";
import { getAll, updateRead, updateIsSpam } from "./dataservice";
import Overview from "./overview";
import View from "./view";
import Delete from "./delete";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);
    const [selectedMessages, setSelectedMessages] = useState([]);

    const { invoke: invokeUpdateRead, loading: updateReadLoading } = useAPI((values) =>
        updateRead(values)
    );
    const { invoke: invokeUpdateIsSpam, loading: updateIsSpamLoading } = useAPI((values) =>
        updateIsSpam(values)
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-messages", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        status: null,
        spam: false,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.spam,
                searchParams.searchText,
                searchParams.status,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.spam,
            searchParams.searchText,
            searchParams.status,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const {
        open: handleView,
        close: handleCloseView,
        visible: viewToggled,
        args: viewId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteIds,
    } = useDialog(invoke);

    function handleSearch(searchText, status, spam) {
        setSearchParams({ ...searchParams, searchText, status, spam, page: 0 });
        setSelectedMessages([]);
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    const handleSelectMessage = (e) => {
        var messageId = Number(e.target.name);
        var selected = e.target.checked;

        var newSelections = [...selectedMessages];
        newSelections = handleMessageSelect(newSelections, messageId, selected);
        setSelectedMessages(newSelections);
    };

    const handleMessageSelect = (selections, messageId, itemSelected) => {
        if (!itemSelected) {
            selections = selections.filter((id) => id !== messageId);
        } else if (selections.filter((id) => id === messageId).length === 0) {
            selections.push(messageId);
        }
        return selections;
    };

    const handleSelectAllMessages = () => {
        var newSelections = [...selectedMessages];
        for (var messageIndex in data.items) {
            newSelections = handleMessageSelect(newSelections, data.items[messageIndex].id, true);
        }
        setSelectedMessages(newSelections);
    };

    const handleDeselectAllMessages = () => {
        var newSelections = [...selectedMessages];
        for (var messageIndex in data.items) {
            newSelections = handleMessageSelect(newSelections, data.items[messageIndex].id, false);
        }
        setSelectedMessages(newSelections);
    };

    const handleMarkAsRead = (selection) => {
        invokeUpdateRead({ messages: selection, isRead: true });
    };

    const handleMarkAsUnread = (selection) => {
        invokeUpdateRead({ messages: selection, isRead: false });
    };

    const handleMarkAsSpam = (selection) => {
        invokeUpdateIsSpam({ messages: selection, isSpam: true });
    };

    const handleMarkAsNormal = (selection) => {
        invokeUpdateIsSpam({ messages: selection, isSpam: false });
    };

    useEffect(() => {
        setSelectedMessages([]);
    }, [companyScope.id]);

    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else if (!updateReadLoading && !updateIsSpamLoading) {
            invoke();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateReadLoading, updateIsSpamLoading]); //Don't add invoke because otherwise the data keeps reloading

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <Fragment>
            <PrivateRoute
                requiredOperation="messages-view"
                path="/marketplace/messages"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onView={handleView}
                            selectedMessages={selectedMessages}
                            onSelectMessage={handleSelectMessage}
                            onDeselectAllMessages={handleDeselectAllMessages}
                            onSelectAllMessages={handleSelectAllMessages}
                            onMarkAsRead={handleMarkAsRead}
                            onMarkAsUnread={handleMarkAsUnread}
                            onMarkAsSpam={handleMarkAsSpam}
                            onMarkAsNormal={handleMarkAsNormal}
                            onDelete={handleDelete}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />
            <View
                id={viewId}
                open={viewToggled}
                onClose={() => {
                    handleMarkAsRead([viewId]);
                    handleCloseView();
                }}
            />
            <Delete messages={deleteIds} open={deleteToggled} onClose={handleCloseDelete} />
        </Fragment>
    );
};
