import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(0.5),
        tableLayout: "fixed",
        width: "100%",

        "& + .form-table": {
            marginTop: theme.spacing(4),
        },

        "& caption": {
            fontWeight: "bold",

            "& .caption-content-wrapper": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
        },

        "& tr > *": {
            verticalAlign: "middle",
            textAlign: "left",
            wordBreak: "break-word",
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        },

        "& tr > *:last-child": {
            paddingRight: 0,
        },

        "& th": {
            fontWeight: "normal",
        },
    },
}));

export default useStyles;
