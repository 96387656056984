import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, assetId, id, isLinkedToAdvertisement, library, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(assetId, library, id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Asset.media.delete.header", "Delete asset media item?")}
            buttonText={getResource("Asset.media.delete.okButton", "Delete")}
        >            
            {!isLinkedToAdvertisement && getResource("Asset.media.delete.deleteMessage", "The media item will be deleted.")}
            {isLinkedToAdvertisement && getResource("Asset.media.delete.deleteMessage.linkedToAdvertisement", "This media item is linked to an advertisement. If you delete this, it will also be removed from the advertisement.")}
        </Confirmation>
    );
});
