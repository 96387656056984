import makeStyles from "@material-ui/core/styles/makeStyles";
import { colors } from "@material-ui/core";

const noImagesTextColor = colors.grey[600];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        verticalAlign: "top",
        flexWrap: "wrap",
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },

    imageSelectElement: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        width: "100%",
        padding: "18px 14px 15px",
        borderRadius: 4,
        border: "1px solid rgba(0,0,0,0.15)",
        transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "white",

        "&.--error": {
            borderColor: theme.palette.error.main,
        },

        "&.--no-images": {
            borderColor: "rgba(0,0,0,0.10)",

            "& .imageSelectLabel": {
                color: noImagesTextColor,
            },

            "& .noImagesText": {
                fontSize: "0.95em",
                fontStyle: "italic",
                color: noImagesTextColor,
            },
        },
    },

    imageSelectLabel: {
        position: "absolute",
        top: 0,
        left: 8,
        padding: "0 4px",
        fontSize: 12,
        lineHeight: "11px",
        transform: "translateY(-6px)",

        "&.--error": {
            color: theme.palette.error.main,
        },

        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 1px)",
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: -1,
        },
    },

    checkbox: {
        "clip-path": "inset(50%)",
        "white-space": "nowrap",
        clip: "rect(0 0 0 0)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        width: 1,
    },

    imageLabel: {
        margin: "0 4px 4px 0",
        position: "relative",
        cursor: "pointer",
    },

    image: {
        width: 80,
        height: 80,
        objectFit: "cover",
        display: "block",
        userDrag: "none",
        transition: "0.1s opacity",

        "&.--is-checked": {
            opacity: 0.7,
        },
    },

    checkboxIconWrapper: {
        position: "absolute",
        right: 3,
        bottom: 3,
        color: theme.palette.secondary.main,
        opacity: 0.4,
        transition: "0.1s opacity",

        "label:hover &, &.--is-checked": {
            opacity: 1,
        },

        "&:before": {
            content: '""',
            position: "absolute",
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
            borderRadius: 3,
            backgroundColor: "white",
        },

        "& svg": {
            display: "block",
            position: "relative",
        },
    },
}));

export default useStyles;
