import React from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    ListAlt as JobSheetIcon,
    Replay as ReopenIcon,
} from "@material-ui/icons";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkOrderHeader from "../shared/workOrderHeader";
import PagedList from "../../../shared/pagedList";
import WorkOrderDetail from "../workOrder/detail";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import Status from "../../../shared/status";
import getStatusColor from "../shared/getStatusColor";

const Overview = withResources(
    ({
        getResource,
        data,
        workOrder,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onAdd,
        onEdit,
        onDelete,
        onReopen,
        history,
        getRequiredOperation,
    }) => {
        data = data || {};
        const items = data.items;

        return (
            <ContentHolder>
                <WorkOrderHeader
                    status={workOrder.status}
                    title={getResource(
                        "Maintenance.workOrderTasks.header.title",
                        "Work order tasks for {summary}",
                        { ...workOrder }
                    )}
                    renderActions={() => {
                        return (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                requiredOperation={getRequiredOperation("work-orders-task-create")}
                                buttonText={getResource(
                                    "Maintenance.workOrderTasks.newButton",
                                    "Create"
                                )}
                            />
                        );
                    }}
                />
                <WorkOrderDetail workOrder={workOrder} />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Maintenance.workOrderTasks.noResults",
                        "No work order tasks available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Maintenance.workOrderTasks.overview.summaryHeader"
                                        defaultValue="Summary"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Maintenance.workOrderTasks.overview.assignedToHeader"
                                        defaultValue="Assigned to"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Maintenance.workOrderTasks.overview.internalReferenceHeader"
                                        defaultValue="Internal reference"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Maintenance.workOrderTasks.overview.statusHeader"
                                        defaultValue="Status"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Maintenance.workOrderTasks.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() =>
                                            history.push(
                                                `/maintenance/work-orders/${workOrder.id}/tasks/${row.id}`
                                            )
                                        }
                                        requiredOperation={getRequiredOperation(
                                            "work-orders-task-update-jobsheet"
                                        )}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.summary}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.assignedTo}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.internalReference}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Status
                                                text={getResource(
                                                    `Maintenance.workOrderTask.status.${row.status.toLowerCase()}`,
                                                    row.status
                                                )}
                                                color={getStatusColor(row.status)}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Asset.workOrderTasks.editButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            "work-orders-task-update"
                                                        ),
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                    {
                                                        text:
                                                            row.status === "Open"
                                                                ? getResource(
                                                                      "Asset.workOrderTasks.startJobSheetButton",
                                                                      "Start work"
                                                                  )
                                                                : getResource(
                                                                      "Asset.workOrderTasks.continueJobSheetButton",
                                                                      "Continue work"
                                                                  ),
                                                        icon: <JobSheetIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            "work-orders-task-update-jobsheet"
                                                        ),
                                                        onClick: () =>
                                                            history.push(
                                                                `/maintenance/work-orders/${workOrder.id}/tasks/${row.id}`
                                                            ),
                                                        hide: row.status === "Completed",
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Asset.workOrderTasks.deleteButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            "work-orders-task-delete"
                                                        ),
                                                        onClick: () =>
                                                            onDelete({
                                                                id: row.id,
                                                                summary: row.summary,
                                                            }),
                                                        hide: items.length <= 1,
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Asset.workOrderTasks.reopenButton",
                                                            "Reopen"
                                                        ),
                                                        icon: <ReopenIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: getRequiredOperation(
                                                            "work-orders-task-update"
                                                        ),
                                                        onClick: () =>
                                                            onReopen({
                                                                id: row.id,
                                                                summary: row.summary,
                                                            }),
                                                        hide: workOrder.status == "Completed" || row.status != "Completed",
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
