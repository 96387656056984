import React from "react";
import withResources from "../../../shared/textresources/withresources";
import Header from "../../../shared/header";
import getStatusColor from "./getStatusColor";

export default withResources(({ getResource, title, renderActions, status }) => {
    return (
        <Header
            statusText={getResource(
                `Maintenance.workOrder.status.${status.toLowerCase()}`,
                status
            )}
            statusColor={getStatusColor(status)}
            renderActions={renderActions}
            title={title}
        />
    );
});
