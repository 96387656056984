import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
    Button,
    Checkbox,
    Chip,
    FormHelperText,
    IconButton,
    InputAdornment,
    Paper,
    Popper,
    TextField,
} from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon,
    Clear as ClearIcon,
    Search as SearchIcon,
} from "@material-ui/icons";
import clsx from "clsx";

import useStyles from "./useStyles";
import withResources from "../textresources/withresources";
import AddNewItem from "../autocomplete/addnewitem";
import useAuthorization from "../authentication/useauthorization";

const AutoCompleteStatic = withResources(
    ({
        getResource,
        options,
        onSelection,
        name,
        label,
        value,
        multiple,
        errors,
        isMedium,
        keepOnOneLine = true,
        fullWidth,
        noMargin,
        noOptionsText,
        noOptionSelectedText,
        autoFocus,
        getOptionLabel,
        disableClearable,
        backgroundColor,
        disabled,
        handleAddNewItem,
        addNewItemLabel,
        addNewItemRequiredOperation,
        initialize, // to prevent error when passing props to Textfield
        required,
        ...props
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [pendingValue, setPendingValue] = useState([]);
        const [popperWidth, setPopperWidth] = useState(300);
        const [listboxMaxHeight, setListboxMaxHeight] = useState(200);
        const [filterInputValue, setFilterInputValue] = useState("");

        /** Filtered version of the options (prop). Filtered by the value (prop, an id or array of id's): */
        const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : {});

        /** Version of selectedOptions that's always an array (used for keeping the rendering logic simple): **/
        const [selectedOptionsArray, setSelectedOptionsArray] = useState([]);

        const hasOptions = options && options.length > 0;

        const selectElement = useRef(null);
        const chipsWrapper = useRef(null);
        const labelId = useRef("id_" + Math.random().toString(36).substr(2, 9));
        const autoCompleteToggleRef = useRef(null);

        const autocompleteInputHasFocus = useRef(false);
        const classes = useStyles();
        const isBrowserEnvironment = typeof window !== "undefined";

        const error = errors && name in errors;
        const helperText = errors && errors[name];

        const { isAuthorized } = useAuthorization(addNewItemRequiredOperation);

        useEffect(() => {
            let selected;
            if (multiple) {
                selected =
                    options && value ? options.filter((option) => value.includes(option.id)) : [];
            } else {
                selected = options && options.find((option) => option.id === value);
                selected = selected ? selected : {};
            }

            setSelectedOptions(selected);
        }, [options, value, multiple]);

        useEffect(() => {
            setPendingValue(selectedOptions);
            setSelectedOptionsArray(multiple ? selectedOptions : [selectedOptions]);
        }, [selectedOptions, multiple]);

        useEffect(() => {
            const cancelInput = () => {
                setPendingValue(selectedOptions);
                setIsOpen(false);
            };

            if (isBrowserEnvironment) {
                const closeOnEscape = (e) => (e.key === "Escape" ? cancelInput() : null);
                document.addEventListener("keydown", closeOnEscape, {capture: true});
                return () => document.removeEventListener("keydown", closeOnEscape, {capture: true});
            }
        }, [isBrowserEnvironment, selectedOptions]);

        const getListboxMaxHeight = () => {
            const minHeight = 180;
            const containingDialog = selectElement.current.closest("[role=dialog]");

            let availableSpace;
            if (containingDialog) {
                const dialogActionsHeight = 52;
                availableSpace = containingDialog.offsetHeight - dialogActionsHeight;
            } else {
                availableSpace =
                    window.innerHeight - selectElement.current.getBoundingClientRect().bottom;
            }

            const availableSpaceForListbox = availableSpace - 100;
            return availableSpaceForListbox > minHeight ? availableSpaceForListbox : minHeight;
        };

        const handleOpen = (e) => {
            if (!hasOptions || disabled) {
                return;
            }

            const isChipClicked = e.target.classList.contains("MuiChip-deleteIcon");
            if (isChipClicked || isOpen) {
                return;
            }

            if (isBrowserEnvironment) {
                setListboxMaxHeight(getListboxMaxHeight());
                setPopperWidth(selectElement.current.offsetWidth);
            }

            setIsOpen(true);
        };

        const handleClose = () => {
            setTimeout(() => {
                /** This check prevents closing when the empty textField gets selected (default behavior of Autocomplete) */
                if (autocompleteInputHasFocus.current) {
                    return;
                }

                saveAndClose(pendingValue);
            }, 200);
        };

        const saveAndClose = (valueToSave) => {
            const selection = multiple
                ? valueToSave
                    ? valueToSave.map((entity) => entity.id)
                    : []
                : valueToSave
                ? valueToSave.id
                : "";

            const equals = multiple
                ? value &&
                  value.length === selection.length &&
                  value.every((id) => selection.includes(id))
                : value === selection;

            if (!equals) {
                onSelection({ target: { name: name, value: selection } });
            }
            setIsOpen(false);
            setFilterInputValue("");
            setTimeout(() => {
                if (autoCompleteToggleRef.current) {
                    autoCompleteToggleRef.current.focus();
                }
            }, 100);
        };

        const handleChipDelete = (id) => {
            let selection = multiple ? value.filter((entity) => entity !== id) : "";
            onSelection({ target: { name: name, value: selection } });
        };

        const getLabel = (option) => {
            if (!option) {
                return "";
            }

            if (getOptionLabel) {
                return getOptionLabel(option);
            }
            return option.name;
        };

        const renderOption = (option, { selected }) => {
            if (option && option.id === "addNewItem") {
                return <AddNewItem addNewItemLabel={addNewItemLabel} label={label} />;
            }

            return (
                <Fragment>
                    {multiple && (
                        <Checkbox
                            checked={Boolean(selected)}
                            color="primary"
                            size="small"
                            disableRipple
                            classes={{ root: classes.optionCheckboxRoot }}
                        />
                    )}
                    {getLabel(option)}
                </Fragment>
            );
        };

        const getOptionsWithSelectedFirst = () => {
            return options && multiple && selectedOptionsArray.length > 0
                ? options.sort((a, b) => {
                      let ai = selectedOptionsArray.indexOf(a);
                      ai = ai === -1 ? selectedOptionsArray.length + options.indexOf(a) : ai;
                      let bi = selectedOptionsArray.indexOf(b);
                      bi = bi === -1 ? selectedOptionsArray.length + options.indexOf(b) : bi;
                      return ai - bi;
                  })
                : options;
        };

        const onChange = (event, newValues) => {
            const addNewItemIsClicked = multiple
                ? newValues.some((value) => value.id === "addNewItem")
                : newValues.id === "addNewItem";

            if (addNewItemIsClicked) {
                saveAndClose([]);
                handleAddNewItem();
                return;
            }

            setPendingValue(newValues);
            !multiple && saveAndClose(newValues);
        };

        const filterOptions = createFilterOptions();

        // When no option is selected, the first item in the array is an empty object {}
        const isAnyOptionSelected = selectedOptionsArray 
            && (selectedOptionsArray.length > 1 || (selectedOptionsArray.length > 0 && Object.keys(selectedOptionsArray[0]).length > 0))

        return (
            <React.Fragment>
                <div
                    className={clsx(
                        classes.selectContainer,
                        isMedium && "--medium",
                        fullWidth && "--fullwidth",
                        noMargin && "--no-margin",
                        isOpen && "--show-page-blocker"
                    )}
                >
                    <Paper
                        className={clsx(
                            classes.selectElement,
                            "autoCompleteSelect",
                            isMedium && "--medium",
                            error && "--error",
                            !hasOptions && "--no-options",
                            disabled && "--disabled"
                        )}
                        style={{
                            "--background-color": backgroundColor ? backgroundColor : "white",
                        }}
                        ref={selectElement}
                        onClick={handleOpen}
                    >
                        {label && (
                            <label
                                className={clsx(
                                    classes.label,
                                    "autoCompleteLabel",
                                    error && "--error"
                                )}
                                id={labelId.current}
                            >
                                {label}
                                {required && (
                                    <span aria-hidden="true" className="MuiInputLabel-asterisk">
                                        {" "}
                                        *
                                    </span>
                                )}
                            </label>
                        )}

                        <div
                            className={clsx(
                                classes.chipsWrapper,
                                isOpen && "--disabled",
                                keepOnOneLine && "--keep-on-one-line",
                                keepOnOneLine && multiple && "--with-fading-gradient"
                            )}
                            ref={chipsWrapper}
                            aria-labelledby={labelId.current}
                        >   {noOptionSelectedText && hasOptions && !isAnyOptionSelected && <div className="autoCompleteChip --show-as-text">{noOptionSelectedText}</div>}
                            {hasOptions ? (
                                selectedOptionsArray.map((option) => {
                                    const showChipDelete = !disableClearable && multiple;
                                    return (
                                        option &&
                                        option.id != null && (
                                            <Chip
                                                key={getLabel(option)}
                                                label={getLabel(option)}
                                                size={isMedium ? "medium" : "small"}
                                                disabled={disabled}
                                                onDelete={
                                                    showChipDelete
                                                        ? () => handleChipDelete(option.id)
                                                        : null
                                                }
                                                className={clsx(
                                                    classes.chip,
                                                    "autoCompleteChip",
                                                    !multiple && "--show-as-text"
                                                )}
                                                style={{
                                                    maxWidth:
                                                        !multiple && chipsWrapper.current
                                                            ? chipsWrapper.current.offsetWidth +
                                                              "px"
                                                            : "none",
                                                }}
                                            />
                                        )
                                    );
                                })
                            ) : (
                                <div className="autoCompleteNoOptionsText">{noOptionsText}</div>
                            )}
                        </div>

                        {!multiple && !disableClearable && selectedOptions.id && (
                            <IconButton
                                className={clsx(classes.clearIconButton, "autoCompleteClearButton")}
                                disabled={disabled}
                                aria-label={getResource(
                                    "Autocomplete.clearSelectionLabel",
                                    "Clear selection"
                                )}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleChipDelete();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        )}

                        {isOpen ? (
                            <IconButton
                                size="small"
                                className={clsx(classes.autoCompleteToggle, "autoCompleteToggle")}
                                disabled={!hasOptions || disabled}
                                aria-label={getResource(
                                    "Autocomplete.closePopperLabel",
                                    "Close the Select popup"
                                )}
                            >
                                <ArrowDropUpIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                size="small"
                                className={clsx(classes.autoCompleteToggle, "autoCompleteToggle")}
                                name={name}
                                disabled={!hasOptions || disabled}
                                autoFocus={autoFocus}
                                ref={autoCompleteToggleRef}
                                aria-label={getResource(
                                    "Autocomplete.openPopperLabel",
                                    "Open the Select popup"
                                )}
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        )}
                    </Paper>

                    {helperText && error && (
                        <FormHelperText
                            className={classes.autoCompleteHelperText}
                            variant="outlined"
                            error={error}
                        >
                            {helperText}
                        </FormHelperText>
                    )}
                </div>

                {isOpen && <div className={classes.pageBlocker} />}

                <Popper
                    open={isOpen}
                    anchorEl={selectElement.current}
                    placement="bottom-end"
                    disablePortal
                    className={classes.popper}
                    modifiers={{ flip: { enabled: false } }}
                    style={{
                        width: popperWidth + "px",
                        "--listbox-max-height": listboxMaxHeight + "px",
                    }}
                >
                    <Autocomplete
                        {...props}
                        disabled={disabled}
                        open={isOpen}
                        value={pendingValue}
                        onClose={handleClose}
                        multiple={multiple}
                        autoHighlight
                        inputValue={filterInputValue}
                        disableCloseOnSelect={multiple}
                        disablePortal
                        disableClearable
                        renderTags={() => null}
                        freeSolo
                        getOptionLabel={() => ""}
                        onChange={onChange}
                        renderOption={renderOption}
                        filterOptions={(options, { inputValue }) => {
                            const newOptions = filterOptions(options, { inputValue });
                            if (handleAddNewItem && isAuthorized) {
                                newOptions.push({ id: "addNewItem" });
                            }
                            return newOptions;
                        }}
                        options={getOptionsWithSelectedFirst()}
                        onInputChange={(e, value, reason) =>
                            reason !== "reset" && setFilterInputValue(value)
                        }
                        classes={{
                            root: classes.autocompleteRoot,
                            paper: classes.autocompletePaper,
                            option: classes.option,
                            listbox: classes.listbox,
                            popperDisablePortal: classes.popperDisablePortal,
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onFocus={() => (autocompleteInputHasFocus.current = true)}
                                onBlur={() => (autocompleteInputHasFocus.current = false)}
                                autoFocus={true}
                                size="small"
                                variant="outlined"
                                fullWidth
                                placeholder={getResource(
                                    "Autocomplete.startTypingLabel",
                                    "Start typing"
                                )}
                                className={clsx(
                                    classes.autocompleteTextField,
                                    "autocompleteTextField"
                                )}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            className={classes.autocompleteAdornment}
                                            position="start"
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                required={required}
                            />
                        )}
                    />
                    {multiple && (
                        <Button
                            className={clsx(classes.applyButton, "e2e_autoCompleteCloseButton")}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={handleClose}
                        >
                            {getResource("Autocomplete.applyButton", "Close")}
                        </Button>
                    )}
                </Popper>
            </React.Fragment>
        );
    }
);

AutoCompleteStatic.propTypes = {
    options: PropTypes.array,
    onSelection: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.any,
    multiple: PropTypes.bool,
    errors: PropTypes.object,
    helperText: PropTypes.string,
    isMedium: PropTypes.bool,
    keepOnOneLine: PropTypes.bool,
    noMargin: PropTypes.bool,
    noOptionsText: PropTypes.string,
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    disableClearable: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default AutoCompleteStatic;
