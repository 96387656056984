import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import { ArrowForwardIos as ForwardIcon, ArrowBackIos as BackIcon } from "@material-ui/icons";
import withResources from "../../../../shared/textresources/withresources";

const BackgroundEventActions = withResources(
    ({ getResource, event, allowCreate, onClose, onGoToDate, onAdd }) => {
        return (
            <Dialog
                onClose={() => onClose()}
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{event.extendedProps.tooltip}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getResource(
                            "Planning.timeline.backgroundEventActions.content",
                            "Do you want to navigate to the predicted event or create a new event?"
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {allowCreate && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                onAdd();
                                onClose();
                            }}
                        >
                            {getResource(
                                "Planning.timeline.backgroundEventActions.createEvent",
                                "Create new event"
                            )}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            let date;
                            if (event.backgroundColor === "red") {
                                date = event.start;
                                date.setDate(date.getDate() - 1);
                            } else {
                                date = event.end;
                                date.setDate(date.getDate() + 1);
                            }
                            onGoToDate(date);
                            onClose();
                        }}
                        endIcon={event.backgroundColor === "red" ? null : <ForwardIcon />}
                        startIcon={event.backgroundColor === "red" ? <BackIcon /> : null}
                    >
                        {getResource(
                            "Planning.timeline.backgroundEventActions.goToDate",
                            "Navigate to predicted event"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default BackgroundEventActions;
