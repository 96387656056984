import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    fact: {
        position: "relative",
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
            3
        )}px`,
        width: "100%",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,

        "&:nth-child(odd)": {
            backgroundColor: theme.palette.grey[100],
        },
    },

    titleAndLabel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",

        "& > *": {
            flexShrink: 1,
        },
    },

    titleWrapper: {
        flexGrow: 1,
        minWidth: 0,
        paddingRight: theme.spacing(1),
    },

    title: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    factLabel: {
        padding: "2px 7px",
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white,
        textTransform: "uppercase",
        fontSize: 10,
        fontWeight: 500,
        textAlign: "center",
        whiteSpace: "nowrap",
    },

    subTitle: {
        display: "block",
        fontWeight: 500,
    },

    actionNotification: {
        position: "absolute",
        top: 22,
        left: theme.spacing(1),
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,

        "&.impending": {
            backgroundColor: theme.palette.warning.main,
        },

        "&.overdue": {
            backgroundColor: theme.palette.error.main,
        },
    },
}));

export default useStyles;
