import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useStyles from "./useStyles";
import Path from "../../path";
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";
import useAPI from "../../useapi";
import { Typography, Button, IconButton } from "@material-ui/core";
import withResources from "../../textresources/withresources";

const Header = withResources((props) => {
    const {
        getResource,
        itemName,
        id,
        title,
        valueArray,
        handleBack,
        handleSelectItem,
        handleDeselectItem,
        getPath,
        disableClearable,
        isSelectable,
    } = props;

    const classes = useStyles();
    const { data: path } = useAPI(() => getPath(id), Boolean(id), [id, getPath]);
    const itemIsAlreadySelected = valueArray && valueArray.some((item) => item && item.id === id);
    const itemIsDeselectable = (valueArray && valueArray.length > 1) || !disableClearable;

    return (
        <li className={clsx(classes.header)} onClick={(e) => e.stopPropagation()}>
            <Path
                path={path ? path.parentNames : null}
                removeLastItem
                keepOnOneLine
                alwaysReserveHeight={!!handleBack}
            />

            {(title || handleBack) && (
                <div className={classes.titleAndButtonsWrapper}>
                    {handleBack && (
                        <IconButton
                            size="small"
                            color="primary"
                            aria-label={getResource(
                                "Shell.drillDownInput.closeLabel",
                                "Close {itemName}",
                                { itemName }
                            )}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    )}

                    {title && (
                        <Typography className={classes.titleWrapper} variant="h5" component="div">
                            <div className={classes.title} title={title}>
                                {title}
                            </div>
                        </Typography>
                    )}

                    {itemIsAlreadySelected
                        ? handleDeselectItem &&
                          itemIsDeselectable && (
                              <Button
                                  className={classes.selectButton}
                                  onClick={() => handleDeselectItem({ id: id, name: title })}
                                  id={`e2e_drillDownInputDeselectButton${id}`}
                              >
                                  {getResource("Shell.drillDownInput.deselectLabel", "Deselect")}
                              </Button>
                          )
                        : handleSelectItem &&
                          isSelectable && (
                              <Button
                                  className={classes.selectButton}
                                  onClick={() => handleSelectItem({ id: id, name: title })}
                                  id={`e2e_drillDownInputSelectButton${id}`}
                              >
                                  {getResource("Shell.drillDownInput.selectLabel", "Select")}
                              </Button>
                          )}
                </div>
            )}
        </li>
    );
});

Header.propTypes = {
    id: PropTypes.number,
    itemName: PropTypes.string,
    title: PropTypes.string,
    valueArray: PropTypes.array,
    handleBack: PropTypes.func,
    selectItem: PropTypes.func,
    deselectItem: PropTypes.func,
    getPath: PropTypes.func.isRequired,
    disableClearable: PropTypes.bool,
};

export default Header;
