import React, { Component } from "react";
import {
    CircularProgress,
    Paper,
    Typography,
    Grid,
    Snackbar,
    SnackbarContent,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

class Notification extends Component {
    constructor() {
        super();
        this.state = {
            type: "none",
            backgroundColor: "",
            textColor: "",
            header: "",
            content: "",
        };
    }

    showSuccess(header, content) {
        this.setState({
            type: "message",
            backgroundColor: "#d4edda",
            textColor: "#155724",
            header: header,
            content: content,
        });
    }

    showError(header, content) {
        this.setState({
            type: "message",
            backgroundColor: "#f8d7da",
            textColor: "#721c24",
            header: header,
            content: content,
        });
    }

    showBusy(header, content) {
        this.setState({
            type: "busy",
            backgroundColor: "#fff3cd",
            textColor: "#856404",
            header: header,
            content: content,
        });
    }

    handleDismiss() {
        this.setState({ type: "none" });
    }

    render() {
        switch (this.state.type) {
            case "message":
                return (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open
                        onClose={() => this.handleDismiss()}
                    >
                        <SnackbarContent
                            aria-describedby="notification-snackbar"
                            message={
                                <span id="notification-snackbar">
                                    <strong>{this.state.header}</strong>
                                    <br />
                                    {this.state.content}
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => this.handleDismiss()}
                                >
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                            style={{
                                backgroundColor: this.state.backgroundColor,
                                color: this.state.textColor,
                            }}
                        />
                    </Snackbar>
                );
            case "busy":
                return (
                    <Paper
                        style={{
                            backgroundColor: this.state.backgroundColor,
                            color: this.state.textColor,
                            padding: 20,
                            marginBottom: 10,
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                                <CircularProgress />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="h5" component="h3">
                                    {this.state.header}
                                </Typography>
                                <Typography component="p">{this.state.content}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            case "none":
            default:
                return null;
        }
    }
}

export default Notification;
