import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    contentSection: {
        maxWidth: "65%",

        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
        },
    },

    searchBlock: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },

    pagedList: {
        "& .MuiCardContent-root": {
            backgroundColor: "transparent !important",
        },
    },
}));

export default useStyles;
