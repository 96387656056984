import React, { useContext, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ChevronRight } from "@material-ui/icons";
import { getAttributeValue } from "../asset/attributeHelper";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(3),
    },

    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        minHeight: 63,
    },

    content: {
        padding: theme.spacing(2),
    },
}));

export default withResources(({ getResource, asset, attributeDefinitions }) => {
    const { authorizationService } = useContext(AuthenticationContext);

    const [widgetLoaded, setWidgetLoaded] = useState(false);

    if (!authorizationService.isAuthorized("assets-view-position")) {
        return null;
    }

    const classes = useStyles();

    var mmsiValue = null;
    const mmsiAttribute = attributeDefinitions.find((x) => x.name === "MMSI");
    if (mmsiAttribute) {
        mmsiValue = getAttributeValue(mmsiAttribute, asset.attributes);
    }

    if (!mmsiValue) {
        return null;
    }

    if (!widgetLoaded) {
        const externalScript = document.createElement("script");

        externalScript.id = "myShipTrackingScriptLoader";
        externalScript.src = "//www.myshiptracking.com/js/widgetApi.js";
        externalScript.async = true;
        externalScript.defer = true;

        document.body.appendChild(externalScript);

        const settingsScript = document.createElement("script");
        settingsScript.innerHTML = `var mst_width="100%";
                var mst_height="300px";
                var mst_border="0";
                var mst_map_style="simple";
                var mst_mmsi="${mmsiValue}";
                var mst_show_track="false";
                var mst_show_info="false";
                var mst_show_names="0";
                var mst_scroll_wheel="false";
                var mst_show_menu="false";`;
        document.body.appendChild(settingsScript);

        setWidgetLoaded(true);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.header}>
                <Typography variant="h4">
                    <ResourcedText
                        resourceKey="Asset.detail.position.header"
                        defaultValue="Real time position"
                    />
                </Typography>
                {asset.positionUrl && (
                    <a
                        href={asset.positionUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        alt={getResource(
                            "Asset.detail.position.chevronAlt",
                            "Open real time position"
                        )}
                    >
                        <ChevronRight />
                    </a>
                )}
            </div>
            <div className={classes.content}>
                <span id="myshiptrackingscript"></span>
            </div>
        </Paper>
    );
});
