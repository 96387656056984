import React from "react";
import { ReactComponent as AppraisalSvg } from "./Appraisal-icon.svg";
import { ReactComponent as QuickScanSvg } from "./Quick-Scan-icon.svg";
import { ReactComponent as ValuationSvg } from "./Valuation-icon.svg";

import SvgIcon from "@material-ui/core/SvgIcon";

const AppraisalIcon = ({ className }) => (
    <SvgIcon className={className}>
        <AppraisalSvg />
    </SvgIcon>
);
const QuickScanIcon = ({ className }) => (
    <SvgIcon className={className}>
        <QuickScanSvg />
    </SvgIcon>
);
const ValuationIcon = ({ className }) => (
    <SvgIcon className={className}>
        <ValuationSvg />
    </SvgIcon>
);

export { AppraisalIcon, QuickScanIcon, ValuationIcon };
