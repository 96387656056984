import React, { Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import useAPI from "../../../shared/useapi";
import { getRoles, updateRoles } from "./dataservice";
import { getAll as getAllRoles } from "./../roles/dataservice";
import withResources from "../../../shared/textresources/withresources";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(1),
    },
}));

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => getRoles(id), true, [id]);
    const { data: allRoles } = useAPI(() => getAllRoles(null, null, 1, null, null), true);
    const classes = useStyles();

    const { loading, invoke } = useAPI((values) => updateRoles(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};
        return errors;
    };

    const isLoaded = data && allRoles;
    const [isDirty, setIsDirty] = React.useState(false);

    return (
        <FormDrawer
            title={getResource("Security.users.editRoles.title", "Edit system role assignments")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ roles: data }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText="Save"
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    confirmationOnSubmit
                    confirmationOnSubmitText={getResource(
                        "Security.users.editRoles.confirmationOnSubmitText",
                        "These role assignments will apply to the whole system."
                    )}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <Fragment>
                                <Alert severity="warning" className={classes.alert}>
                                    {getResource(
                                        "Security.users.editRoles.rolesWarning",
                                        "Mind that these roles apply to the whole system."
                                    )}
                                </Alert>
                                <AutoCompleteStatic
                                    errors={errors}
                                    autoFocus
                                    name="roles"
                                    label={getResource(
                                        "Security.users.editRoles.rolesField",
                                        "Roles"
                                    )}
                                    noOptionsText={getResource(
                                        "Security.users.editRoles.noRolesAvailable",
                                        "No roles available"
                                    )}
                                    value={values.roles}
                                    multiple={true}
                                    options={allRoles.items}
                                    onSelection={(e) => handleChange(e)}
                                    fullWidth
                                    isMedium
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
