import React, { useEffect } from "react";
import { Section } from "../shell";
import Dictionaries from "./dictionaries";
import TextResources from "./textresources";
import UsageUnits from "./usageunits";
import PromptTemplates from "./prompttemplates";

import SettingsIcon from "@material-ui/icons/Settings";
import withResources from "../../shared/textresources/withresources";
import getResources from "./textresources/getresources";

export default withResources(({ initialize, getResource }) => {
    // Retrieve the text resources and initialize the text resources provider.
    useEffect(() => {
        async function fetchResources() {
            const resources = await getResources();
            initialize(resources);
        }
        fetchResources();
    }, [initialize]);


    const children = [
        {
            title: getResource("System.textResourcesTitle", "Text resources"),
            href: "/system/text-resources",
            render: TextResources,
            requiredOperation: "text-resources-view",
        },
        {
            title: getResource("System.dictionariesTitle", "Dictionaries"),
            href: "/system/dictionaries",
            render: Dictionaries,
            requiredOperation: "dictionaries-view",
        },
        {
            title: getResource("System.usageUnitsTitle", "Usage units"),
            href: "/system/usageunits",
            render: UsageUnits,
            requiredOperation: "usageunits-view",
        },
        {
            title: getResource("System.promptTemplatesTitle", "AI Prompt templates"),
            href: "/system/prompttemplates",
            render: PromptTemplates,
            requiredOperation: "prompttemplates-view",
        },
        {
            title: getResource("System.pdfTemplatesTitle", "PDF templates"),
            href: "/documents/documents/pdf templates",
            linkOnly: true,
            requiredOperation: "pdf-templates-download",
        },
    ];

    return [
        <Section
            key="System"
            title={getResource("System.title", "System")}
            icon={SettingsIcon}
            href="/system"
            children={children}
        />
    ];
});
