import React, { Fragment, useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    ownerHistoryDl: {
        marginTop: theme.spacing(1),

        "& > *:last-of-type": {
            marginBottom: 0,
        },
    },

    ownerHistory: {
        margin: `${theme.spacing(3)}px 0`,

        "& h4": {
            margin: `${theme.spacing(1)}px 0`,
        },
    },
}));

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const {
        marketplace: { homeUrl },
    } = useContext(ConfigurationContext);

    const classes = useStyles();

    const { data } = useAPI(() => get(id), true, [id]);

    return (
        <Fragment>
            {!data ? (
                <LinearProgress />
            ) : (
                <FormDrawer title={data.subject} onClose={() => onClose(false)} isDirty={false}>
                    {data && (
                        <Fragment>
                            <dl className={classes.dl}>
                                <dt>
                                    <ResourcedText
                                        resourceKey="Marketplace.message.view.nameField"
                                        defaultValue="Name"
                                    />
                                </dt>
                                <dd>
                                    {data.firstName} {data.lastName}
                                    <br />
                                    <a href={`mailto:${data.email}`}>{data.email}</a>
                                    <br />
                                    <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                                </dd>

                                {data.advertisementTitle && (
                                    <Fragment>
                                        <dt>
                                            <ResourcedText
                                                resourceKey="Marketplace.message.view.advertisementField"
                                                defaultValue="Advertisement"
                                            />
                                        </dt>
                                        <dd>
                                            <a
                                                href={`${homeUrl}/advertisement/${data.advertisementId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {data.advertisementTitle}
                                            </a>
                                        </dd>
                                    </Fragment>
                                )}
                            </dl>
                            {data.message}
                        </Fragment>
                    )}
                </FormDrawer>
            )}
        </Fragment>
    );
});
