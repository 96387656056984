import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, assetModelId, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(assetModelId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Valuation.assetModelQuestionnaire.delete.confirmationHeader",
                "Delete model questionnaire?"
            )}
            buttonText={getResource(
                "Valuation.assetModelQuestionnaire.delete.confirmationButton",
                "Delete"
            )}
        >
            <ResourcedText
                resourceKey="Valuation.assetModelQuestionnaire.delete.confirmationText"
                defaultValue="The model questionnaire will be deleted"
            />
        </Confirmation>
    );
});
