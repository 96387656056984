import React, { Fragment } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow, LinearProgress } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import _ from "underscore";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import PagedList from "../../../shared/pagedList";

import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

const useOverviewStyles = makeStyles((theme) => ({
    row: {
        cursor: "pointer",
    },
}));

const Overview = withResources(
    ({
        getResource,
        data,
        loading,
        onEdit,
    }) => {
        const classes = useOverviewStyles();

        return (

             <ContentHolder>
                <Header
                    title={getResource("Settings.dictionaries.header.title", "Dictionaries")}                                   
                />
                <PagedList
                    disablePaging
                    loading={loading}                    
                    noResultsText={getResource(
                        "Settings.dictionaries.noResults",
                        "No dictionaries available"
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Settings.dictionaries.overview.nameHeader"
                                        defaultValue="Name"
                                    />
                                </TableCell>     
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="System.dictionaries.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.key}
                                        hover
                                        key={row.key}
                                        className={classes.row}
                                        handleClick={() => onEdit(row.id)}
                                        requiredOperation="dictionaries-update"
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Settings.dictionaries.editButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.key,
                                                        requiredOperation: "dictionaries-update",
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>            
        );
    }
);

export default Overview;
