import React, { useEffect, useState, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import { getSummary } from "./dataservice";

import withResources from "../../../shared/textresources/withresources";
import Confirmation from "../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, invoiceId, open, onClose, directDownload }) => {
    if (!open) {
        return null;
    }

    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        let timer;

        async function refresh() {
            const response = await getSummary(invoiceId);
            const invoiceSummary = await response.json();

            if (invoiceSummary && invoiceSummary.fileId) {
                clearTimeout(timer);

                if (directDownload) {
                    window.open(
                        `/api/administration/invoice/${invoiceSummary.id}/download`,
                        "_blank"
                    );
                    onClose(true);
                } else {
                    setInvoice(invoiceSummary);
                }
            } else {
                timer = setTimeout(refresh, 2000);
            }
        }

        timer = setTimeout(refresh, 2000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId, directDownload]);

    const downloadInvoice = () => {
        window.open(`/api/administration/invoice/${invoice.id}/download`, "_blank");
        onClose(true);
    };

    return (
        <Confirmation
            open={true}
            handleClose={() => onClose(false)}
            buttonHidden={!invoice}
            buttonText={getResource(
                "Valuation.invoice.loader.finishedButton",
                "Download the invoice"
            )}
            handleAction={() => downloadInvoice()}
            headerText={getResource("Administration.invoices.download.title", "Download invoice")}
        >
            {invoice && (
                <ResourcedText
                    resourceKey="Valuation.invoice.loader.finished"
                    defaultValue="The invoice report is ready."
                />
            )}
            {!invoice && (
                <Fragment>
                    <LinearProgress />
                    <ResourcedText
                        resourceKey="Administration.invoice.download.checkingText"
                        defaultValue="Checking if the invoice is generated"
                    />
                </Fragment>
            )}
        </Confirmation>
    );
});
