import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
    };

    const url = `/api/content/contentpage?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/content/contentpage", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function get(id) {
    const url = `/api/content/contentpage/${id}`;

    return await interceptedFetch(url);
}

async function getContent(culture, slug) {
    const url = `/api/content/contentpage/${culture}/${slug}`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/content/contentpage/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/content/contentpage/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, add, update, get, del, getContent };
