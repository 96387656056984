import React, { Fragment, useRef, useEffect } from "react";
import { LinearProgress, IconButton } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import TranslatableTextField from "../../../shared/translatableTextField";
import SortableList from "../../../shared/sortableList";
import AddButton from "../../../shared/addButton";
import DeleteIcon from "@material-ui/icons/Delete";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }
    const isBrowserAvailable = typeof window !== "undefined";
    let addButtonRef = useRef(null);
    let lastSection = useRef(null);

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, _, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Settings.dictionaries.edit.enterName",
                "Please enter a name for the default language"
            );
        }

        if (values.items) {
            values.items.forEach((item, i) => {
                if (!item.value[defaultCulture] || item.value[defaultCulture] === "") {
                    errors.items = errors.items || {};
                    errors.items[i] = {
                        value: getResource(
                            "Settings.dictionaries.edit.addItemDefaultLanguage",
                            "Item {index} needs a value for the default language",
                            { index: i + 1 }
                        )
                    };
                }
            });
        }

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Settings.dictionaries.edit.title", "Edit dictionary")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Settings.dictionaries.edit.okButtonText", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    useUILanguages={true}
                    showCultureSelector
                    renderForm={(culture, values, handleChange, errors) => {

                        useEffect(() => {
                            if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
                                lastSection.current.focus();
                                addButtonRef.current.value = "";
                            }
                        }, [values, isBrowserAvailable]);

                        return (
                            <Fragment>
                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Settings.dictionaries.edit.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <SortableList
                                    items={values.items}
                                    sortingChanged={(sortedItems) => {
                                        var copy = sortedItems.map((item, index) => ({ ...item, order: index }));


                                        handleChange({
                                            target: {
                                                name: "items",
                                                value: copy,
                                            },
                                        });
                                    }}
                                    culture={culture}
                                    renderItemContent={(section, index) => (
                                        <Fragment>
                                            <TranslatableTextField
                                                errors={errors.items && errors.items[index]}
                                                labelResourceKey="Settings.dictionaries.edit.valueField"
                                                labelDefaultValue="Value"
                                                fullWidth
                                                name="value"
                                                value={section.value}
                                                culture={culture}
                                                multiline
                                                rows={1}
                                                handleChange={(e) => {
                                                    const copy = [...values.items];
                                                    copy[index]["value"][culture] = e.target.value;

                                                    handleChange({
                                                        target: {
                                                            name: "items",
                                                            value: copy,
                                                        },
                                                    });
                                                }}
                                                required
                                                inputRef={lastSection}
                                            />

                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    const copy = [
                                                        ...values.items.slice(0, index),
                                                        ...values.items.slice(index + 1),
                                                    ];


                                                    handleChange({
                                                        target: {
                                                            name: "items",
                                                            value: copy,
                                                        },
                                                    });
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Fragment>
                                    )}
                                />

                                <AddButton
                                    buttonRef={addButtonRef}
                                    buttonText={getResource(
                                        "Settings.dictionaries.edit.addButton",
                                        "Add item"
                                    )}
                                    handleClick={() => {
                                        const copy = values.items.concat([
                                            {
                                                value: { [culture]: "" },
                                                order: values.items.length
                                            },
                                        ]);

                                        handleChange({
                                            target: {
                                                name: "items",
                                                value: copy,
                                            },
                                        });
                                       
                                    }}
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
