import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    event: {
        "& .fc-event-location": {
            overflowWrap: "break-word",
            fontWeight: 100,
        },

        /** Month view **/
        "& .fc-event-month-content-wrapper": {
            minWidth: 0,

            "& .fc-event-time": {
                minWidth: 33,
            },

            "& .fc-event-month-top-content": {
                display: "flex",

                "& .fc-event-title": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            },

            "& .fc-event-location": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },

        /** List view **/
        "& .fc-list-event-location": {
            fontWeight: "normal",
        },
    },

    timeZone: {
        textAlign: "right",
        color: theme.palette.text.darkGray,
        fontSize: 11,
        marginTop: 5,

        "& > span > span": {
            fontWeight: 500,
        },
    },

    loader: {
        visibility: "hidden",
        transition: "0s visibility linear 0.2s",

        "&.--visible": {
            visibility: "visible",
        },
    },

    noContent: {
        backgroundColor: theme.palette.white,
        padding: 20,
        textAlign: "center",
        fontWeight: 500,
        marginTop: 20,
    },

    icon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: 10,
        borderRadius: ".8em",
        fontSize: "3em",
    },

    tooltip: {
        fontSize: 12,
    },
}));

export default useStyles;
