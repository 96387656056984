import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
    descriptionDialogToggle: {
        padding: 0,
        margin: 0,
        background: "none",
        border: "none",
        display: "inline",
        textDecoration: "underline",
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    descriptionDialogTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    descriptionDialogContent: { whiteSpace: "pre-wrap" },
}));
