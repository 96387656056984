import React, { Fragment, useContext } from "react";
import useAuthorization from "../../../../shared/authentication/useauthorization";
import useAPI from "../../../../shared/useapi";
import { getAll, getFactActions, getFactTypes } from "../dataservice";
import useDialog from "../../../../shared/usedialog";
import Add from "../add";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import FactsWidget from "./factsWidget";
import { config as factConfig } from "../config";

const Widget = ({ asset }) => {
    const { loading, invoke, data } = useAPI(
        () => getAll(asset.id, null, null, null, 1, 5, true),
        true
    );

    const { data: factTypes } = useAPI(() => getFactTypes(), true);
    const { data: factActions } = useAPI(() => getFactActions(), true);
    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);

    return (
        <Fragment>
            <FactsWidget
                headerLink={`/assets/asset-facts/${asset.id}`}
                loading={loading}
                data={data}
                handleAdd={handleAdd}
                entity={asset}
            />

            <Add
                open={addToggled}
                onClose={handleCloseAdd}
                assetId={asset.id}
                name={assetName}
                factTypes={factTypes}
                factActions={factActions}
                assetTypeId={asset.assetTypeId}
            />
        </Fragment>
    );
};

export default ({ asset }) => {
    const { isLoading, isAuthorized } = useAuthorization(
        null,
        factConfig.operationTypes.map((type) => ({
            name: `facts-view-${type}`,
            companyId: asset.companyId,
            assetCollectionId: asset.assetCollectionIds,
        }))
    );

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <Widget asset={asset} />;
};
