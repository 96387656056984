import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import LocalizationContext from "./localization/localizationcontext";

const WtcDatePicker = ({
    name,
    onChange,
    errors,
    noMargin,
    disabled,
    useTime = false,
    value,
    disallowKeyboard,
    ...props
}) => {
    const { selectedTimezone } = useContext(LocalizationContext);
    const [isOpen, setIsOpen] = useState(false);

    const isValidDate = (dateString) => {
        const d = new Date(dateString);
        return d instanceof Date && !isNaN(d);
    };

    const handleChange = (date) => {
        var value = null;

        if (date && isValidDate(date)) {
            if (useTime) {
                value = zonedTimeToUtc(date, selectedTimezone);
            } else {
                value = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            }
        }

        onChange({ target: { name: name, type: "datepicker", value: value } });
    };

    const error = errors && name in errors;
    const helperText = errors && errors[name];

    var datePickerProps = {
        ...props,
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        autoOk: true,
        format: "dd-MM-yyyy",
        error,
        helperText,
        name,
        margin: noMargin ? "none" : "normal",
        variant: "inline",
        inputVariant: "outlined",
        InputLabelProps: { shrink: true },
        fullWidth: true,
        onChange: handleChange,
        disabled: disabled,
        value: useTime ? utcToZonedTime(value, selectedTimezone) : value,
    };

    return disallowKeyboard ? (
        <DatePicker {...datePickerProps} />
    ) : (
        <KeyboardDatePicker {...datePickerProps} />
    );
};

WtcDatePicker.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    noMargin: PropTypes.bool,
    disabled: PropTypes.bool,
    useTime: PropTypes.bool,
    disallowKeyboard: PropTypes.bool,
};

export default WtcDatePicker;
