import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";
import condionallyAddReferenceId from "./condionallyAddReferenceId";

async function getAll(referenceId, library, name, type, isArchived, page, pageSize) {
    const params = {
        name,
        type,
        page,
        pageSize,
        referenceId,
        isArchived,
    };

    const url = `/api/documents/libraries/${library}/documents?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAllContainingLibrary(referenceId, libraryPart, isArchived) {
    const params = {
        libraryPart,
        referenceId,
        isArchived,
    };

    const url = `/api/documents/documents?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id, library) {
    const url = `/api/documents/libraries/${library}/documents/${id}`;

    return await interceptedFetch(url);
}

async function add(referenceId, library, values) {
    return await interceptedFetch(
        condionallyAddReferenceId(`/api/documents/libraries/${library}/documents`, referenceId),
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(values.values)),
        }
    );
}

async function update(id, library, values) {
    return await interceptedFetch(`/api/documents/libraries/${library}/documents/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getLibraries() {
    const url = `/api/documents/libraries`;

    return await interceptedFetch(url);
}

async function del(library, id) {
    return await interceptedFetch(`/api/documents/libraries/${library}/documents/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function getActions() {
    const url = `/api/documents/actions`;

    return await interceptedFetch(url);
}

async function archive(library, id) {
    const url = `/api/documents/libraries/${library}/documents/${id}/archive`;

    return await interceptedFetch(url, {
        method: "PUT",
    });
}

async function unArchive(library, id) {
    const url = `/api/documents/libraries/${library}/documents/${id}/unArchive`;

    return await interceptedFetch(url, {
        method: "PUT",
    });
}

export {
    getAll,
    getAllContainingLibrary,
    get,
    add,
    update,
    getLibraries,
    del,
    getActions,
    archive,
    unArchive,
};
