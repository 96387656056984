import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        height: 50,
    },

    addButton: {
        position: "absolute",
        left: 0,
        marginLeft: -4,
    },

    autocompleteWrapper: {
        visibility: "hidden",
        width: "100%",

        "&.--is-visible": {
            visibility: "visible",

            "& .autoCompleteSelect": {
                visibility: "hidden",
            },
        },
    },
}));

export default useStyles;
