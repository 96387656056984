import React, { useContext } from "react";

import useAPI from "../../../shared/useapi";
import { link } from "./dataservice";

import LocalizedForm from "../../../shared/localization/localizedform";
import withResources from "../../../shared/textresources/withresources";
import PagedListInputForAssets from "../../../shared/pagedListInput/pagedListInputForAssets";
import PagedListInputForComponents from "../../../shared/pagedListInput/pagedListInputForComponents";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FormHelperText } from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import ResourceTextField from "../../../shared/textresources/resourcedtextfield";

export default withResources(
    ({
        getResource,
        onClose,
        id
    }) => {
        const { loading, invoke } = useAPI((values, referenceType) => link(values, referenceType));

        const onSubmit = async (values) => {
            var requestBody = {
                referenceId: values.values.referenceType == "Assets" ? values.values.asset.id : values.values.component.id,
                id: values.values.id
            }

            if (await invoke(requestBody, values.values.referenceType)) {
                onClose(values.values.referenceType, requestBody.referenceId);
            }
        };

        const onValidate = (values) => {
            var errors = {};

            if(!values.referenceType){
                errors.referenceType = getResource(
                    "Administration.qrCodes.link.assetOrComponentRequired",
                    "Select whether to link an asset or a component"
                );
            }
            
            if(values.referenceType == "Assets" && !values.asset?.id){
                errors.asset = getResource(
                    "Administration.qrCodes.link.assetRequired",
                    "Asset is required"
                );
            }

            if(values.referenceType == "Components" && !values.component?.id){
                errors.component = getResource(
                    "Administration.qrCodes.link.componentRequired",
                    "Component is required"
                );
            }

            if (!values.id) {
                errors.id = getResource(
                    "Administration.qrCodes.link.qrIdRequired",
                    "QR id not found"
                );
            }

            return errors;
        };

        const [isDirty, setIsDirty] = React.useState(false);

        return (
                <LocalizedForm
                    initialValues={{
                        id: id
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Administration.qrCodes.link.linkButton", "Link")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors) => {
                        var companyId = null;

                        if (values.asset && values.asset.id) {
                            companyId = values.assetCompanyId;
                        } else if (values.component && values.component.id) {
                            companyId = values.componentCompanyId;
                        }

                        return (
                            <React.Fragment>
                                <ResourceTextField
                                    errors={errors}
                                    margin="normal"
                                    name="id"
                                    labelResourceKey="Administration.qrCodes.link.idField"
                                    labelDefaultValue="QR code identifier"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.id}
                                    disabled="true"
                                />

                                <FormHelperText>
                                    <ResourcedText
                                        resourceKey="Administration.qrCodes.link.helperText"
                                        defaultValue="Link the QR code to either an asset or a component"
                                    />
                                </FormHelperText>

                                <ToggleButtonGroup
                                        value={values.referenceType}
                                        exclusive
                                        size="small"
                                        onChange={(e, value) => {
                                            handleChange(
                                                {
                                                    target: {
                                                        name: "referenceType",
                                                        value: value,
                                                    },
                                                });
                                        }}
                                    >
                                        <ToggleButton value="Assets">
                                            {getResource(
                                                "Administration.qrCodes.link.assetText",
                                                "Asset"
                                            )}
                                        </ToggleButton>
                                        <ToggleButton value="Components">
                                            {getResource(
                                                "Administration.qrCodes.link.componentText",
                                                "Component"
                                            )}
                                        </ToggleButton>
                                </ToggleButtonGroup>
                                {errors && "referenceType" in errors && (
                                    <FormHelperText error>{errors["referenceType"]}</FormHelperText>
                                )}

                                {values.referenceType && (
                                    <PagedListInputForAssets
                                        label={getResource(
                                            "Administration.qrCodes.link.assetField",
                                            "Asset"
                                        )}
                                        errors={errors}
                                        name="asset"
                                        value={values.asset}
                                        onSelection={(e, asset) =>
                                            handleChange([ e ])
                                        }
                                        fullWidth
                                        isMedium
                                        dialogTitle={getResource(
                                            "Administration.qrCodes.link.dialogTitle",
                                            "Select an asset"
                                        )}
                                        helperText={getResource(
                                            "Administration.qrCodes.link.selectAssetText",
                                            "Select the asset you want to link to the QR code"
                                        )}
                                    />
                                )}

                                {values.referenceType == "Components" && (
                                    <PagedListInputForComponents
                                        assetId={values.asset?.id}
                                        errors={errors}
                                        name="component"
                                        fullWidth
                                        isMedium
                                        value={values.component}
                                        onSelection={(e, component) =>
                                            handleChange([ e ])
                                        }
                                        label={getResource(
                                            "Administration.qrCodes.link.componentIdField",
                                            "Component"
                                        )}
                                        dialogTitle={getResource(
                                            "Administration.qrCodes.link.component.dialogTitle",
                                            "Select a component"
                                        )}
                                        helperText={getResource(
                                            "Administration.qrCodes.link.selectComponentText",
                                            "Select the component you want to link to the QR code"
                                        )}
                                    />
                                )}
                            </React.Fragment>
                        );
                    }}
                />
        );
    }
);
