import React from "react";
import { makeStyles } from "@material-ui/styles";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import FormattedCurrency from "../../../shared/formattedCurrency";
import Widget from "../../../shared/widget";
import useDialog from "../../../shared/usedialog";
import FinancialDetails from "./edit";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    dl: {
        "& dt": {
            marginBottom: 0,
        },

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",

            "&:not(.--summary-has-toggle):last-of-type": {
                marginBottom: 0,
            },
        },
    },
}));

const OutstandingLoanWidget = withResources(
    ({ getResource, asset, loading, onEditFinancialDetails, data, hideActions }) => {
        const classes = useStyles();

        if (!data) {
            return null;
        }

        if (data.loanOptional) {
            return null;
        }

        return (
            <Widget
                requiredOperation={{
                    name: "assets-view-financial",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                headerTitle={getResource(
                    "Assets.asset.financialDetails.outstandingLoanWidget.headerTitle",
                    "Outstanding loan"
                )}
                onHeaderClick={!hideActions ? () => onEditFinancialDetails(asset.id) : null}
                headerLinkTitle={getResource(
                    "Assets.asset.financialDetails.outstandingLoanWidget.chevronAlt",
                    "See complete financial information"
                )}
                loading={loading}
                hasContent={
                    data !== null &&
                    (data.loanStartDate ||
                        data.loanInitialAmount ||
                        data.loanRemainingAmount ||
                        data.loanInterestRate ||
                        data.loanPeriods ||
                        data.loanType)
                }
                noDataText={getResource(
                    "Assets.asset.financialDetails.outstandingLoanWidget.noDataText",
                    "No loan information available"
                )}
                addButtonText={getResource(
                    "Assets.asset.financialDetails.outstandingLoanWidget.noDataButtonText",
                    "Edit financial information"
                )}
                onAddButtonClick={!hideActions ? () => onEditFinancialDetails(asset.id) : null}
                addButtonRequiredOperation={{
                    name: "assets-update-financial",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                renderContent={() => {
                    return (
                        <dl className={classes.dl}>
                            {data.loanStartDate && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanStartDateField"
                                            defaultValue="Start date"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedDate
                                            long
                                            includeWeekDay
                                            date={data.loanStartDate}
                                        />
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.loanInitialAmount && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanInitialAmountField"
                                            defaultValue="Initial amount"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={data.loanInitialAmount}
                                            currency={data.currencyShortName}
                                            decimals={0}
                                        />
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.loanRemainingAmount && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanRemainingAmountField"
                                            defaultValue="Remaining amount"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={data.loanRemainingAmount}
                                            currency={data.currencyShortName}
                                            decimals={0}
                                        />
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.loanInterestRate && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanInterestRateField"
                                            defaultValue="Interest rate"
                                        />
                                    </dt>
                                    <dd>
                                        {getResource(
                                            "Assets.asset.financialDetails.outstandingLoanWidget.loanInterestRate",
                                            "{interestRate} %",
                                            { interestRate: data.loanInterestRate }
                                        )}
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.loanPeriods && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanNumberOfPeriodsField"
                                            defaultValue="Number of periods"
                                        />
                                    </dt>
                                    <dd>
                                        {getResource(
                                            "Assets.asset.financialDetails.outstandingLoanWidget.loanPeriods",
                                            "{periods} months",
                                            { periods: data.loanPeriods }
                                        )}
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.loanType && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.outstandingLoanWidget.loanType"
                                            defaultValue="Loan type"
                                        />
                                    </dt>
                                    <dd>{data.loanType}</dd>
                                </React.Fragment>
                            )}
                        </dl>
                    );
                }}
            />
        );
    }
);

export default ({ onRefresh, ...props }) => {
    const {
        open: handleFinancialDetails,
        close: handleCloseFinancialDetails,
        visible: financialDetailsToggled,
        args: financialDetailsId,
    } = useDialog(onRefresh);

    return (
        <React.Fragment>
            <OutstandingLoanWidget onEditFinancialDetails={handleFinancialDetails} {...props} />
            <FinancialDetails
                open={financialDetailsToggled}
                id={financialDetailsId}
                onClose={handleCloseFinancialDetails}
            />
        </React.Fragment>
    );
};
