import React, { Fragment, useState, useContext } from "react";
import { LinearProgress, FormControlLabel, Checkbox } from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import {
    getParents,
    getActiveChildren,
    getPath,
    getAll,
} from "../../security/companies/dataservice";

import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import PartiesEditor, { validateParties } from "./components/partiesEditor";
import DrillDownInput from "../../../shared/drilldowninput";
import TranslatableTextField from "../../../shared/translatableTextField";
import DatePicker from "../../../shared/datepicker";

export default withResources(({ getResource, open, onClose, allStatuses }) => {
    if (!open) {
        return null;
    }

    const { companyScope } = useContext(WorkingScopeContext);

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (input) => {
        var saveValues = {
            ...input.values,
            companyId: input.values.company.id,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.company.id) {
            errors.company = getResource(
                "Assets.component.add.companyRequired",
                "Please select a company"
            );
        }

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Assets.component.add.nameRequired",
                "Please enter a name for the default language"
            );
        }

        if (!values.status) {
            errors.status = getResource(
                "Assets.component.add.statusRequired",
                "Please select a status"
            );
        }

        validateParties(values, errors, getResource);

        return errors;
    };

    const statuses = allStatuses && allStatuses.filter((x) => x.id !== "Installed");
    const isLoaded = statuses;

    const [isDirty, setIsDirty] = useState(false);

    return (
        <FormDrawer
            title={getResource("Assets.component.add.title", "Create component")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        company: companyScope,
                        name: "",
                        description: "",
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.component.add.okButton", "Add")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => (
                        <Fragment>
                            <DrillDownInput
                                errors={errors}
                                name="company"
                                itemName={getResource(
                                    "Assets.component.add.companiesField",
                                    "Company"
                                )}
                                label={getResource(
                                    "Assets.component.add.companiesField",
                                    "Company"
                                )}
                                isMedium
                                fullWidth
                                getParents={getParents}
                                getChildren={getActiveChildren}
                                getPath={getPath}
                                filter={(searchText, noDescendantOf, page, pageSize) =>
                                    getAll(searchText, noDescendantOf, true, page, pageSize)
                                }
                                value={values.company}
                                disableClearable
                                setValue={(e) => handleChange(e)}
                                required
                            />

                            <TranslatableTextField
                                errors={errors}
                                autoFocus
                                margin="normal"
                                name="name"
                                labelResourceKey="Assets.component.add.nameField"
                                labelDefaultValue="Name"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.name}
                                culture={culture}
                                handleChange={handleChange}
                                required
                            />

                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="description"
                                labelResourceKey="Assets.component.add.descriptionField"
                                labelDefaultValue="Description"
                                fullWidth
                                multiline
                                value={values.description}
                                culture={culture}
                                handleChange={handleChange}
                            />

                            <DatePicker
                                errors={errors}
                                name="constructionDate"
                                value={values.constructionDate}
                                onChange={(e) => handleChange(e)}
                                disableFuture
                                disabled={!isDefaultCulture}
                                label={getResource(
                                    "Assets.component.add.constructionDateButton",
                                    "Construction date"
                                )}
                            />

                            <ResourcedTextField
                                errors={errors}
                                margin="normal"
                                name="modelType"
                                labelResourceKey="Assets.component.add.modelTypeField"
                                labelDefaultValue="Model/Type"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={values.modelType || ""}
                                onChange={(e) => handleChange(e)}
                                disabled={!isDefaultCulture}
                            />

                            <ResourcedTextField
                                errors={errors}
                                margin="normal"
                                name="identification"
                                labelResourceKey="Assets.component.add.identificationField"
                                labelDefaultValue="Identification"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={values.identification || ""}
                                onChange={(e) => handleChange(e)}
                                disabled={!isDefaultCulture}
                            />

                            <AutoCompleteStatic
                                errors={errors}
                                name="status"
                                label={getResource("Asset.component.add.statusField", "Status")}
                                fullWidth
                                isMedium
                                value={values.status}
                                onSelection={(e) => handleChange(e)}
                                options={statuses}
                                disabled={!isDefaultCulture}
                                required
                            />

                            <ResourcedTextField
                                errors={errors}
                                margin="normal"
                                name="position"
                                labelResourceKey="Assets.component.add.positionField"
                                labelDefaultValue="Position in storage"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={values.position || ""}
                                onChange={(e) => handleChange(e)}
                                disabled={!isDefaultCulture}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(values.isConsumable)}
                                        onChange={(e) => handleChange(e)}
                                        name="isConsumable"
                                        disabled={!isDefaultCulture}
                                    />
                                }
                                label={getResource(
                                    "Assets.component.add.isConsumable",
                                    "Consumable"
                                )}
                                variant="outlined"
                            />

                            <PartiesEditor
                                errors={errors}
                                handleChange={handleChange}
                                disabled={!isDefaultCulture}
                                value={values.parties}
                            />
                        </Fragment>
                    )}
                />
            )}
        </FormDrawer>
    );
});
