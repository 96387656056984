import React from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update, getPath } from "./dataservice";
import { getAll } from "../assettypes/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { data: path } = useAPI(() => getPath(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const { data: assetTypes } = useAPI(() => getAll(null, null, null), true);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Library.assetCategories.edit.enterName",
                "Please enter a name for the default language"
            );
        }

        if (!values.defaultAssetTypeId) {
            errors.defaultAssetTypeId = getResource(
                "Library.assetCategories.edit.selectAssetType",
                "Please select an type"
            );
        }

        return errors;
    };

    var isLoaded = data && assetTypes && path;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.assetCategories.edit.title", "Edit category")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Library.assetCategories.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    name="path"
                                    labelResourceKey="Library.assetCategories.edit.pathField"
                                    labelDefaultValue="Path"
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={path.parentNames.join(" > ")}
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Library.assetCategories.edit.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="defaultAssetTypeId"
                                    label={getResource(
                                        "Library.assetCategories.edit.defaultAssetTypeField",
                                        "Default type"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.defaultAssetTypeId}
                                    onSelection={(e) => handleChange(e)}
                                    options={assetTypes.items}
                                    disableClearable
                                    disabled={!isDefaultCulture}
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
