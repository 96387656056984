import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        margin: theme.spacing(3),
        padding: theme.spacing(2),
    },

    container: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },

    mainArea: {
        width: "calc(100% - 300px)",
        paddingRight: theme.spacing(3),

        [theme.breakpoints.down(1400)]: {
            width: "100%",
            paddingRight: 0,
        },
    },

    mainAreaDouble: {
        [theme.breakpoints.up(1400)]: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",

            "& > div": {
                marginBottom: theme.spacing(3),

                "&:first-child": {
                    flex: "auto",
                    marginRight: theme.spacing(3),
                },

                "&:last-child": {
                    flex: "0 0 350px",
                },
            },
        },
    },

    mainAreaSignOff: {
        [theme.breakpoints.up(1400)]: {
            marginLeft: "calc(100% - 350px)",
        },
    },

    sideBar: {
        width: 300,

        "& > div": {
            width: "100%",
        },

        [theme.breakpoints.down(1400)]: {
            width: "100%",
        },
    },
}));

export default useStyles;
