import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    event: {
        "& .fc-event-location": {
            overflowWrap: "break-word",
            fontWeight: 100,
        },

        /** Month view **/
        "& .fc-event-month-content-wrapper": {
            minWidth: 0,

            "& .fc-event-time": {
                minWidth: 33,
            },

            "& .fc-event-month-top-content": {
                display: "flex",

                "& .fc-event-title": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            },

            "& .fc-event-location": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },

        /** List view **/
        "& .fc-list-event-location": {
            fontWeight: "normal",
        },
    },

    timeZone: {
        textAlign: "right",
        color: theme.palette.text.darkGray,
        fontSize: 11,
        marginTop: 5,

        "& > span > span": {
            fontWeight: 500,
        },
    },

    loader: {
        position: "absolute",
        zIndex: 999,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

export default useStyles;
