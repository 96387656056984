const getTreeDepth = (itemTree) => {
    let treeDepth = 0;

    function recurse(treeItem) {
        if (treeItem.children || treeItem.open) {
            treeDepth = treeDepth + 1;
        }

        if (treeItem.children) {
            treeItem.children.forEach((childItem) => recurse(childItem));
        }
    }

    recurse(itemTree);
    return treeDepth;
};

export default getTreeDepth;
