import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";

const CloseConfirmation = withResources(
    ({ getResource, open, onCancel, onDiscard, onContinueLater }) => {
        return (
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getResource(
                        "Valuation.quickScan.closeConfirmation.title",
                        "Abort quick scan purchase"
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getResource(
                            "Valuation.quickScan.closeConfirmation.text",
                            "Would you like to continue your quick scan purchase at a later time?"
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} autoFocus>
                        {getResource(
                            "Valuation.quickScan.closeConfirmation.cancelButton",
                            "Cancel"
                        )}
                    </Button>
                    <Button onClick={onDiscard} color="primary">
                        {getResource(
                            "Valuation.quickScan.closeConfirmation.discardButton",
                            "Discard"
                        )}
                    </Button>
                    <Button onClick={onContinueLater} color="primary">
                        {getResource(
                            "Valuation.quickScan.closeConfirmation.continueLaterButton",
                            "Continue later"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
);

const ContinueConfirmation = withResources(({ getResource, open, onNew, onContinue }) => {
    return (
        <Dialog
            open={open}
            onClose={onNew}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {getResource(
                    "Valuation.quickScan.continueConfirmation.title",
                    "Continue previous quick scan purchase?"
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getResource(
                        "Valuation.quickScan.continueConfirmation.text",
                        "Would you like to continue the incomplete quick scan purchase you have abandoned earlier?"
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onNew} autoFocus>
                    {getResource(
                        "Valuation.quickScan.continueConfirmation.startNewButton",
                        "Start new"
                    )}
                </Button>
                <Button onClick={onContinue} color="primary">
                    {getResource(
                        "Valuation.quickScan.continueConfirmation.continueButton",
                        "Continue"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

function revalidateQuestions(quickScan, questions) {
    const filteredAnswers = Object.keys(quickScan.answers)

        //filter answers where question is deleted from questions
        .filter((key) => questions.filter((question) => question.id === Number(key)).length > 0)

        //filter answers where answer of the question is deleted from questions
        .filter(
            (key) =>
                questions
                    .find((question) => question.id === Number(key))
                    .answers.filter((answer) => answer.answerId === quickScan.answers[key]).length >
                0
        )

        .reduce((obj, key) => {
            obj[key] = quickScan.answers[key];
            return obj;
        }, {});

    quickScan.answers = filteredAnswers;
    return quickScan;
}

export { CloseConfirmation, ContinueConfirmation, revalidateQuestions };
