import interceptedFetch from "../../../../../shared/interceptedFetch";

async function handleErrors(response) {
    if (!response.ok) {
        throw Error(await response.text());
    }
    return response;
}

class ValuationService {
    isUserValuationHistoryViewer(success, error) {
        interceptedFetch("/api/valuation/valuation/IsUserValuationHistoryViewer")
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(err);
            });
    }
    getAvailableCurrencies(success, error) {
        interceptedFetch("/api/library/currency")
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(err);
            });
    }
    getAvailableBuildYears(success, error) {
        interceptedFetch("/api/valuation/valuation/availablebuildyears")
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(err);
            });
    }
    getValuationData(id, success, error) {
        interceptedFetch("/api/valuation/valuation/" + id)
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(err);
            });
    }
    getHistoricalPurchasePrices(id, success, error) {
        interceptedFetch("/api/valuation/valuation/GetHistoricalPurchasePrices/" + id)
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(err);
            });
    }
    saveValuationDraft(args, success, error) {
        interceptedFetch("/api/valuation/valuation/savedraft", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(args.data)),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
    releaseValuation(args, success, error) {
        interceptedFetch("/api/valuation/valuation/release", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(args.data)),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
    publishValuation(args, success, error) {
        interceptedFetch("/api/valuation/valuation/publish", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(args.data)),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
    saveTrend(args, success, error) {
        interceptedFetch("/api/valuation/trend", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(args.data)),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.text());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
    loadTrend(id, assetCategoryId, success, error) {
        interceptedFetch(`/api/valuation/valuation/loadtrend/${id}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ assetCategoryId }),
        })
            .then(handleErrors)
            .then(async function (response) {
                success(await response.json());
            })
            .catch(async function (err) {
                error(await err);
            });
    }
}

export default ValuationService;
