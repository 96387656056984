import React, { Fragment, useContext } from "react";
import { Alert } from "@material-ui/lab";
import {
    LinearProgress,
    Checkbox,
    FormControlLabel,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { duplicate } from "./dataservice";
import { get } from "./../asset/dataservice";
import { useHistory } from "react-router-dom";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import withResources from "../../../shared/textresources/withresources";


import PagedListInputForAssets from "../../../shared/pagedListInput/pagedListInputForAssets";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import TranslatableTextField from "../../../shared/translatableTextField";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, component, open, onClose, allStatuses }) => {
    if (!open) {
        return null;
    }
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const { loading, invoke, data: duplicatedComponentId } = useAPI((values) => duplicate(component.id, values));

    const { data: asset } = useAPI(() => get(component.installedAssetId), component.installedAssetId);

    const history = useHistory();

    const onSubmit = async (values) => {
        await invoke(values);
    };

    const onValidate = (values, cultures, defaultCulture) => {
        var errors = {};

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);

    const basicInformationCheckboxes = [
        { name: "duplicateDescription", resourceString: getResource("Assets.component.duplicate.duplicateDescription", "Description") },
        { name: "duplicateModelType", resourceString: getResource("Assets.component.duplicate.duplicateModelType", "Model/Type") },
        { name: "duplicateIdentification", resourceString: getResource("Assets.component.duplicate.duplicateIdentification", "Identification") },
        { name: "duplicatePosition", resourceString: getResource("Assets.component.duplicate.duplicatePosition", "Position in storage") },
        { name: "duplicateConsumable", resourceString: getResource("Assets.component.duplicate.duplicateConsumable", "Consumable") }
    ];
    const checkboxes = [
        { name: "duplicateExpectedUsages", resourceString: getResource("Assets.component.duplicate.duplicateExpectedUsages", "Expected usages") },
        { name: "duplicateMedia", resourceString: getResource("Assets.component.duplicate.duplicateMedia", "Media") },
        { name: "duplicateInstallationDate", resourceString: getResource("Assets.component.duplicate.duplicateInstallationDate", "Installation date"), helpText: getResource("Assets.component.duplicate.duplicateComponentsInstallationDateHelpText", "When left unchecked, the installation date will be set to today") },
        { name: "duplicatePlannedActions", resourceString: getResource("Assets.component.duplicate.duplicatePlannedActions", "Planned actions") }
    ];

    const isLoaded = (!component.installedAssetId || asset);

    const statuses = allStatuses && allStatuses.filter((x) => x.id !== "Installed");

    const renderCheckbox = (checkbox, values, handleChange, isDefaultCulture) => {
        return <div key={checkbox.name}><FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(values[checkbox.name])}
                    onChange={(e) => handleChange(e)}
                    name={checkbox.name}
                />
            }
            label={checkbox.resourceString}
            variant="outlined"
            disabled={!isDefaultCulture}
        />
            {checkbox.helpText && <Alert severity="info">
                {checkbox.helpText}
            </Alert>}</div>
    };

    return (
        <Fragment><FormDrawer
            title={getResource("Assets.component.duplicate.title", "Duplicate component {name}",
                {
                    name: component && component.name && (component.name[selectedCulture] || component.name[defaultCulture])
                })}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        name: Object.keys(component.name).reduce(
                            (attrs, key) => ({
                                ...attrs,
                                [key]: component.name[key] ? getResource("Assets.component.duplicate.prefix", "Duplicate of ") + component.name[key] : "",
                            }),
                            {}
                        ),
                        assetId: component.installedAssetId,
                        status: component.status == "Installed" ? null : component.status,
                        duplicateDescription: true,
                        duplicateModelType: true,
                        duplicateIdentification: false,
                        duplicatePosition: false,
                        duplicateConsumable: false,
                        duplicateExpectedUsages: false,
                        duplicateMedia: true,
                        duplicateInstallationDate: false,
                        duplicatePlannedActions: false,
                        asset: {
                            ...asset,
                            name: asset && ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                                `${asset.manufacturerName} / ${asset.assetModel.name}`)
                        }
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.component.duplicate.okButton", "Duplicate")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => <React.Fragment>
                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="name"
                                labelResourceKey="Assets.component.duplicate.nameField"
                                labelDefaultValue="Name"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.name}
                                culture={culture}
                                handleChange={handleChange}
                            />


                            <PagedListInputForAssets
                                label={getResource("Assets.component.duplicate.assetField", "Asset")}
                                name="asset"
                                value={values.asset}
                                onSelection={(e) =>
                                    handleChange([
                                        e,
                                        {
                                            target: {
                                                name: "assetId",
                                                value: e.target.value.id,
                                            },
                                        },
                                    ])
                                }
                                fullWidth
                                isMedium
                                dialogTitle={getResource(
                                    "Assets.component.duplicate.asset.dialogTitle",
                                    "Select an asset"
                                )}
                                helperText={getResource(
                                    "Assets.component.duplicate.assetText",
                                    "Select the asset to which the new component should be added. When left blank, the component will be added to the inventory."
                                )}
                                disabled={!isDefaultCulture}
                            />

                            {!values.asset.id && <AutoCompleteStatic
                                errors={errors}
                                name="status"
                                label={getResource("Asset.component.add.statusField", "Status")}
                                fullWidth
                                isMedium
                                value={values.status}
                                onSelection={(e) => handleChange(e)}
                                options={statuses}
                                disabled={!isDefaultCulture}
                                required
                            />}

                            <div>{getResource("Assets.component.duplicate.relatedInformationText", "Select the related information you would like to duplicate")}</div>
                            <div><FormControlLabel
                                control={
                                    <Checkbox
                                        checked={basicInformationCheckboxes.some(x => values[x.name])}
                                        onChange={(e) => {
                                            handleChange(basicInformationCheckboxes.map(x => ({
                                                target: { name: x.name, value: e.target.checked }
                                            }))
                                            );
                                        }}
                                        disabled={!isDefaultCulture}
                                    />
                                }
                                label={getResource("Assets.component.duplicate.basicInformation", "Basic information")}
                                variant="outlined"
                            /></div>
                            <div style={{ paddingLeft: 32 }}>
                                {basicInformationCheckboxes.map(x => renderCheckbox(x, values, handleChange, isDefaultCulture))}
                            </div>
                            {checkboxes.map(x => renderCheckbox(x, values, handleChange, isDefaultCulture))}
                        </React.Fragment>
                    }
                />
            )}
        </FormDrawer>

            <Dialog
                onClose={() => {
                    onClose();
                    history.push(`/assets/components/${duplicatedComponentId}`)
                }}
                open={Boolean(duplicatedComponentId)}
                size="small"
            >
                <DialogTitle>
                    <ResourcedText
                        resourceKey="Assets.component.duplicate.dialog.title"
                        defaultValue="Duplication succeeded"
                    />
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <ResourcedText
                            resourceKey="Assets.component.duplicate.dialog.text"
                            defaultValue="The component has been successfully duplicated. You will now be taken to the detail page of the newly created component."
                        />
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        onClose();
                        history.push(`/assets/components/${duplicatedComponentId}`)
                    }}>
                        <ResourcedText
                            resourceKey="Assets.component.duplicate.dialog.ok"
                            defaultValue="OK"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
});
