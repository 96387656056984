import { useState } from "react";

export default (onDataChanged) => {
    const [current, toggle] = useState({ visible: false });

    const open = (args) => {
        toggle({ visible: true, args: args });
    };

    const close = (changed) => {
        if (changed && onDataChanged) {
            onDataChanged(current.args, changed);
        }

        toggle({ visible: false });
    };

    return {
        visible: current.visible,
        open,
        close,
        args: current.args,
    };
};
