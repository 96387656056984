import React, { Fragment, useContext, useCallback, useRef } from "react";
import { utcToZonedTime } from "date-fns-tz";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import ReferenceContext from "../../../shared/reference/referenceContext";

import PrivateRoute from "../../../shared/authentication/privateroute";
import withResources from "../../../shared/textresources/withresources";
import Overview from "./overview";
import AddScheduledEvent from "./add";
import AddWorkOrder from "../../maintenance/workOrder/add";
import { getReasons, getStatuses } from "../../maintenance/workOrder/dataservice";
import AddEventOrWorkOrder from "./addEventOrWorkOrder";
import EditScheduledEvent from "./edit";
import EditWorkOrder from "../../maintenance/workOrder/edit";
import useAPI from "../../../shared/useapi";
import useDialog from "../../../shared/usedialog";
import { getAll } from "./dataservice";

const Calendar = withResources(
    ({
        getResource,
        match: {
            params: { referenceId, referenceType },
        },
    }) => {
        const calendarRef = useRef(null);

        const { getReferenceEntity, getEntityName} = useContext(ReferenceContext);
        const { selectedTimezone } = useContext(LocalizationContext);

        const { data: reasons } = useAPI(() => getReasons(), true);
        const { data: statuses } = useAPI(() => getStatuses(), true);

        const { data: reference, loading: referenceLoading } = useAPI(
            () => getReferenceEntity(referenceType, referenceId),
            true,
            [referenceId, referenceType]
        );

        const reloadEvents = () => {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.refetchEvents();
        };

        const loadEvents = useCallback(
            async (info, successCallback, failureCallback) => {
                const response = await getAll(
                    referenceId,
                    referenceType,
                    info.startStr,
                    info.endStr
                );
                const data = await response.json();

                const calendarData =
                    data &&
                    data.map((event) => {
                        const zonedStart = utcToZonedTime(event.start, selectedTimezone);
                        const zonedEnd = utcToZonedTime(event.end, selectedTimezone);

                        var title = event.title;
                        if (
                            event.allDay &&
                            (zonedStart.getHours() !== 0 || zonedStart.getMinutes() !== 0)
                        ) {
                            title = getResource(
                                "Planning.calendar.event.titleAllDayOtherTimezone",
                                `{title} (starts at {hour}:{minutes})`,
                                {
                                    title,
                                    hour: zonedStart.getHours(),
                                    minutes: `0${zonedStart.getMinutes()}`.slice(-2),
                                }
                            );
                        }

                        return {
                            ...event,
                            title,
                            start: zonedStart,
                            end: zonedEnd,
                        };
                    });

                successCallback(calendarData);
            },
            [getResource, referenceId, referenceType, selectedTimezone]
        );

        const {
            open: handleAddScheduledEvent,
            close: handleCloseAddScheduledEvent,
            visible: addScheduledEventToggled,
            args: addScheduledEventArgs,
        } = useDialog(reloadEvents);

        const {
            open: handleAddWorkOrder,
            close: handleCloseAddWorkOrder,
            visible: addWorkOrderToggled,
            args: addWorkOrderArgs,
        } = useDialog(reloadEvents);

        const {
            open: handleAddEventOrWorkOrder,
            close: handleCloseAddEventOrWorkOrder,
            visible: addEventOrWorkOrderToggled,
            args: addEventOrWorkOrderArgs,
        } = useDialog();

        const {
            open: handleEditScheduledEvent,
            close: handleCloseEditScheduledEvent,
            visible: editScheduledEventToggled,
            args: editScheduledEventId,
        } = useDialog(reloadEvents);

        const {
            open: handleEditWorkOrder,
            close: handleCloseEditWorkOrder,
            visible: editWorkOrderToggled,
            args: editWorkOrderId,
        } = useDialog(reloadEvents);

        return (
            <Fragment>
                <Overview
                    calendarRef={calendarRef}
                    loadEvents={loadEvents}
                    loading={referenceLoading}
                    referenceId={referenceId}
                    referenceType={referenceType}
                    reference={reference}
                    onAdd={handleAddEventOrWorkOrder}
                    onEditScheduledEvent={handleEditScheduledEvent}
                    onEditWorkOrder={handleEditWorkOrder}
                />

                <AddScheduledEvent
                    open={addScheduledEventToggled}
                    onClose={handleCloseAddScheduledEvent}
                    start={addScheduledEventArgs && addScheduledEventArgs.start}
                    end={addScheduledEventArgs && addScheduledEventArgs.end}
                    allDay={addScheduledEventArgs && addScheduledEventArgs.allDay}
                    referenceId={referenceId}
                    referenceType={referenceType}
                />

                <AddWorkOrder
                    open={addWorkOrderToggled}
                    onClose={handleCloseAddWorkOrder}
                    reasons={reasons}
                    statuses={statuses}
                    assetIdPreselected={referenceType == "Assets" ? referenceId : null}
                    componentIdPreselected={referenceType == "Components" ? referenceId : null}
                    entityName={getEntityName(referenceType, reference)}
                    scheduleEvent
                    start={addWorkOrderArgs && addWorkOrderArgs.start}
                    end={addWorkOrderArgs && addWorkOrderArgs.end}
                    allDay={addWorkOrderArgs && addWorkOrderArgs.allDay}
                />

                <AddEventOrWorkOrder
                    open={addEventOrWorkOrderToggled}
                    onClose={handleCloseAddEventOrWorkOrder}
                    start={addEventOrWorkOrderArgs && addEventOrWorkOrderArgs.start}
                    end={addEventOrWorkOrderArgs && addEventOrWorkOrderArgs.end}
                    allDay={addEventOrWorkOrderArgs && addEventOrWorkOrderArgs.allDay}
                    referenceId={referenceId}
                    referenceType={referenceType}
                    onCreateWorkOrder={() => handleAddWorkOrder(addEventOrWorkOrderArgs)}
                    onCreateScheduledEvent={() => handleAddScheduledEvent(addEventOrWorkOrderArgs)}
                />

                <EditScheduledEvent open={editScheduledEventToggled} onClose={handleCloseEditScheduledEvent} id={editScheduledEventId} />


                <EditWorkOrder
                    open={editWorkOrderToggled}
                    onClose={handleCloseEditWorkOrder}
                    reasons={reasons}
                    statuses={statuses}
                    id={editWorkOrderId}
                />
            </Fragment>
        );
    }
);

export default () => {
    return (
        <PrivateRoute
            exact
            requiredOperation={"scheduled-events-view"}
            path="/planning/calendar/:referenceType/:referenceId"
            render={(props) => <Calendar {...props} />}
        />
    );
};
