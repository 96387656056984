import React from "react";

import useAPI from "../../../shared/useapi";
import { get } from "../asset/dataservice";

import AssetSummary from "../assetDetail/summary";

export default ({ component, nrOfRowsAlwaysVisible }) => {
    if (!component.installedAssetId) {
        return null;
    }

    const { data: asset } = useAPI(() => get(component.installedAssetId), true, [
        component.installedAssetId,
    ]);

    if (!asset) {
        return null;
    }

    return <AssetSummary asset={asset} nrOfRowsAlwaysVisible={nrOfRowsAlwaysVisible} />;
};
