import React from "react";
import withResources from "../../../shared/textresources/withresources";
import InfoBlock from "../shared/infoBlock";

export default withResources(({ getResource, task }) => {
    if (
        !task ||
        (task && !task.summary && !task.description && !task.assignedTo && !task.internalReference)
    ) {
        return null;
    }

    var rows = [];

    const addRow = (resourceTitle, title, value, isDescription) => {
        const resourcedTitle = resourceTitle
            ? getResource(`Maintenance.workOrderTask.widget.${resourceTitle}`, title)
            : title;
        rows.push({
            title: value ? resourcedTitle : null,
            value,
            isDescription,
        });
    };

    addRow(null, task.summary, task.description, true);
    addRow("assignedTo", "Assigned to", task.assignedTo);
    addRow("internalReference", "Internal reference", task.internalReference);

    return (
        <InfoBlock
            title={getResource("Maintenance.workOrderTask.widget.title", "Task")}
            rows={rows}
        />
    );
});
