import React, { Fragment, useEffect } from "react";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import EventCard from "./eventCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import { LinearProgress } from '@material-ui/core';
import {
    Search as SearchIcon,
    Business as BusinessIcon
} from "@material-ui/icons";
import withResources from "../../../shared/textresources/withresources";
import useStyles from "./useStyles";

const Overview = withResources((props) => {
    const {
        loadMore,
        items,
        total,
        showWorkingContext,
        header,
        shouldSelectCompany,
        onEdit,
        onAddWorkOrder,
        reset,
        onResolved,
        onReopened,
        getResource,
        units
    } = props;

    useEffect(() => {
        loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classes = useStyles();

    return (<Fragment>
        { showWorkingContext && <WorkingContext requiredOperation="scheduled-events-view" />}
        <ContentHolder>
            {shouldSelectCompany ? (
                <div className={classes.noContent}>
                    <BusinessIcon className={classes.icon} />
                    {getResource(
                        "Planning.events.selectCompany",
                        "Select a company to view events"
                    )}
                </div>
            ) :
                (!header ? <LinearProgress /> : <Fragment>
                    <Header title={header} />
                    {(total == 0 ? (
                        <div className={classes.noContent}>
                            <SearchIcon className={classes.searchIcon} />
                            {getResource(
                                "Planning.events.noResults",
                                "No events available"
                            )}
                        </div>
                    ) : (
                        <InfiniteScroll
                            dataLength={items && items.length || 0}
                            next={loadMore}
                            hasMore={total == null || total > items.length}
                            loader={<LinearProgress />}
                            scrollableTarget="main"
                        >
                            {items &&
                                items.map((item) => (
                                    <EventCard
                                        item={item}
                                        onEdit={onEdit}
                                        onAddWorkOrder={onAddWorkOrder}
                                        reset={reset}
                                        onResolved={onResolved}
                                        onReopened={onReopened}
                                        units={units}

                                    />
                                ))}
                        </InfiniteScroll>))}
                </Fragment>)}
        </ContentHolder>
    </Fragment>
    );
});

export default Overview;
