import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import SlotFillContext from "./slotcontext";

const Slot = ({ name, list, renderInner, children, ...childProps }) => {
    const [fills, setFills] = useState([]);
    const context = useContext(SlotFillContext);

    useEffect(() => {
        context.registerSlot(name, setFills);
    }, [context, name]);

    const fillsWithProps = fills.map((fill, index) =>
        React.cloneElement(fill, { key: index, ...childProps })
    );

    if (!list && fillsWithProps.length > 0) {
        return fillsWithProps[fillsWithProps.length - 1];
    }

    if (renderInner) {
        return renderInner([...fillsWithProps]);
    }

    if (children && fillsWithProps.length === 0) {
        return children;
    }

    return [...fillsWithProps];
};

Slot.defaultProps = {
    list: false,
};

Slot.propTypes = {
    name: PropTypes.string.isRequired,
    list: PropTypes.bool,
    renderInner: PropTypes.func,
};

export default Slot;
