import React from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import TaskFields, { validateTask } from "../workOrder/components/taskFields";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import { getByCompany } from "../../security/users/dataservice";

export default withResources((props) => {
    const { getResource, open, onClose, workOrderId, currencyCode, companyId } = props;

    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI((values) => add(values));

    const { data: users, loading: usersLoading } = useAPI(() => getByCompany(companyId), true, [
        companyId,
    ]);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        errors = validateTask(values, getResource);

        return errors;
    };

    const isLoaded = !usersLoading;

    const [isDirty, setIsDirty] = React.useState(false);

    return (
        <FormDrawer
            title={getResource("Maintenance.workOrderTask.add.title", "Create work order task")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        isDefaultTask: false,
                        workOrderId: workOrderId,
                        allowRateOverride: false,
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Maintenance.workOrder.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <TaskFields
                                    users={users}
                                    task={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    currencyCode={currencyCode}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
