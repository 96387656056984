import React from "react";
import { Paper } from "@material-ui/core";
import withResources from "../../../../shared/textresources/withresources";
import FormattedCurrency from "../../../../shared/formattedCurrency";
import useStyles from "./useStyles";

import { getTaskLaborTotal, getTaskMaterialTotal } from "../../workOrderTask/helpers";

const Totals = withResources(({ getResource, task, currency }) => {
    if (!task) {
        return null;
    }

    const classes = useStyles();

    const totalLabor = getTaskLaborTotal(task);
    const totalMaterials = getTaskMaterialTotal(task);
    const jobTotal = totalLabor + totalMaterials;

    return (
        <Paper className={classes.paper}>
            <div className={classes.totalsRow}>
                <span>
                    {getResource(
                        "Maintenance.workOrder.reportTotal.laborTotalText",
                        "Labor total:"
                    )}
                </span>
                <FormattedCurrency value={totalLabor} currency={currency} decimals={2} />
            </div>
            <div className={classes.totalsRow}>
                <span>
                    {getResource(
                        "Maintenance.workOrder.reportTotal.materialsTotalText",
                        "Materials total:"
                    )}
                </span>
                <FormattedCurrency value={totalMaterials} currency={currency} decimals={2} />
            </div>
            <div className={classes.totalsRow}>
                <span>
                    {getResource("Maintenance.workOrder.reportTotal.jobTotalText", "Job total:")}
                </span>
                <FormattedCurrency value={jobTotal} currency={currency} decimals={2} />
            </div>
        </Paper>
    );
});

export default Totals;
