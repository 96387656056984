class DataValidator {
    validateDraft(series) {
        let content = "The flow of your valuation is not correct!";
        let totalPoints = series[0].data.length;
        let validationErrorCount = 0;
        const maxValidationMessages = 10;

        for (let i = 0; i < totalPoints; i++) {
            if (i <= totalPoints - 2) {
                if (
                    series[1].data[i].y < series[1].data[i + 1].y ||
                    series[0].data[i].y < series[0].data[i + 1].y
                ) {
                    if (validationErrorCount < maxValidationMessages) {
                        content += " Point " + i + " does not follow a normal flow.";
                    }

                    validationErrorCount++;
                }
            }
        }

        if (validationErrorCount > maxValidationMessages) {
            content +=
                " There are " +
                (validationErrorCount - maxValidationMessages) +
                " more messages not shown in this list.";
        }

        content += " Would you like to save anyway?";

        return {
            message: content,
            errorCount: validationErrorCount,
        };
    }
    isNumberAndGreaterThanZero(value) {
        return !isNaN(Number(value)) && Number(value) > 0;
    }
    isNumberAndGreaterThanOrEqualToZero(value) {
        return !isNaN(Number(value)) && Number(value) > 0;
    }
    isFloatAndGreaterThanZero(value) {
        return !isNaN(parseFloat(value)) && parseFloat(value) > 0;
    }
    isFloat(value) {
        return !isNaN(parseFloat(value));
    }
}

export default DataValidator;
