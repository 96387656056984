import React, { Fragment } from "react";
import useStyles from "./useStyles";
import WorkOrderDetail from "../../workOrder/detail";
import { Slot } from "../../../../shared/slotmachine";
import IntroBlock from "../../shared/introBlock";
import { Paper, Typography } from "@material-ui/core";
import TaskPanel from "./taskPanel";
import ReportTotal from "./reportTotal";
import { getWorkOrderEstimationTotal, getWorkOrderTotal } from "../../workOrderTask/helpers";
import withResources from "../../../../shared/textresources/withresources";

const Report = withResources((props) => {
    const { getResource, workOrder } = props;
    const classes = useStyles();

    return (
        <Fragment>
            <IntroBlock summary={workOrder.summary} description={workOrder.description} />

            <div className={classes.container}>
                <div className={classes.mainArea}>
                    <WorkOrderDetail workOrder={workOrder} />

                    <Paper>
                        <div className={classes.heading}>
                            <Typography variant="h4" component="h2">
                                {getResource("Maintenance.workOrderReport.reportTitle", "Tasks")}
                            </Typography>
                        </div>

                        {workOrder &&
                            workOrder.tasks.length > 0 &&
                            workOrder.tasks.map((task, i) => (
                                <TaskPanel
                                    task={task}
                                    currencyShortName={workOrder.currencyShortName}
                                    key={i}
                                />
                            ))}
                    </Paper>

                    <ReportTotal
                        totalAmount={getWorkOrderTotal(workOrder)}
                        totalEstimated={getWorkOrderEstimationTotal(workOrder)}
                        currencyShortName={workOrder.currencyShortName}
                    />
                </div>

                <div className={classes.sideBar}>
                    <Slot name="work-order-media" workOrder={workOrder} />
                    <Slot name="work-order-documents" workOrder={workOrder} list={true} />
                </div>
            </div>
        </Fragment>
    );
});

export default Report;
