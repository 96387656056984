import { makeStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    pagedListInputContainer: {
        position: "relative",
        display: "inline-flex",
        verticalAlign: "top",
        flexWrap: "wrap",
        maxWidth: "100%",
        width: 300,

        "&.--medium": {
            margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        },

        "&.--fullwidth": {
            width: "100%",
        },

        "&.--no-margin": {
            margin: 0,
        },
    },

    pagedListInput: {
        padding: "2px 3px 0 11px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        minHeight: 40,
        border: "1px solid rgba(0,0,0,0.15)",
        boxShadow: "none",
        transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: theme.palette.white,

        "&:hover:not(.--disabled)": {
            borderColor: theme.palette.black,
            cursor: "pointer",
        },

        "&.--medium": {
            minHeight: 56,
            paddingTop: 9,
            paddingBottom: 7,
        },

        "&.--error": {
            borderColor: theme.palette.error.main,
        },

        "&.--disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            borderColor: "rgba(0, 0, 0, 0.28)",
        },
    },

    label: {
        position: "absolute",
        top: 0,
        left: 9,
        padding: "0 4px",
        fontSize: 12,
        lineHeight: "11px",
        transform: "translateY(-6px)",

        "&.--error": {
            color: theme.palette.error.main,
        },

        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 1px)",
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme.palette.white,
            zIndex: -1,
        },
    },

    value: {
        marginRight: "auto",
        fontSize: 16,
    },

    chip: {
        maxWidth: 250,
        margin: "3px 6px 3px 0",

        "&:hover": {
            cursor: "pointer",
        },
    },

    clearIconButton: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: 4,
        opacity: 0,

        ".pagedListInput:hover &, &:focus": {
            opacity: 1,
        },

        "& svg": {
            fontSize: 13,
        },
    },

    dialogToggle: {
        /** To be able to set focus on the button with JS from outside of the React component **/
        "&:focus:not(.Mui-focusVisible)": {
            backgroundColor: colors.grey[300],
        },
    },

    dialogPaper: {
        padding: "0 20px 20px",
        backgroundColor: "#fafafa",

        /**
         * Temporary page blocker to prevent the user from being able to click on another item
         * while the dialog fades out:
         **/
        "& .click-blocker-active::after": {
            content: "''",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },

    dialogScrollPaper: {
        height: "100vh",
    },

    selectDeselectButton: {
        transition: "none",
    },
}));

export default useStyles;
