import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, noDescendantOf, activeOnly, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        noDescendantOf,
        activeOnly,
    };

    const url = `/api/security/company?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getChildren(parentId) {
    const params = {
        parentId,
    };

    return await interceptedFetch(
        `/api/security/company/children?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function getActiveChildren(parentId) {
    const params = {
        parentId,
        activeOnly: true,
    };

    return await interceptedFetch(
        `/api/security/company/children?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function getParents(id) {
    const params = { id };
    return await interceptedFetch(
        `/api/security/company/parents?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function get(id) {
    const url = `/api/security/company/${id}`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/security/company/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/security/company", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/security/company/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/security/company/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function move(id, newParent) {
    return await interceptedFetch(`/api/security/company/${id}/move`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            newParent,
        }),
    });
}

async function deactivate(id) {
    const url = `/api/security/company/${id}/deactivate`;

    return await interceptedFetch(url, {
        method: "POST",
    });
}

async function activate(id) {
    const url = `/api/security/company/${id}/activate`;

    return await interceptedFetch(url, {
        method: "POST",
    });
}

async function getPath(id) {
    return await interceptedFetch(`/api/security/company/${id}/path`);
}

async function getWhereUserHasOperation(operations) {
    const url = `/api/security/company/my/assignedOperation/${operations.join("|")}`;

    return await interceptedFetch(url);
}

async function getRoles() {
    const url = `/api/security/company/roles`;

    return await interceptedFetch(url);
}

async function updateRoles(values) {
    return await interceptedFetch(`/api/security/company/roles`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export {
    getAll,
    getChildren,
    getActiveChildren,
    getParents,
    get,
    getIsReferenced,
    add,
    update,
    del,
    move,
    deactivate,
    activate,
    getPath,
    getWhereUserHasOperation,
    getRoles,
    updateRoles,
};
