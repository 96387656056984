import React from "react";
import { Fill } from "../../shared/slotmachine/index";
import withResources from "../../shared/textresources/withresources";
import AssetHeader from "./assetDetail/header";
import AssetSummary from "./assetDetail/summary";
import AssetPosition from "./assetDetail/position";
import Documents from "../document/documents/widget";
import AssetMedia from "./assetMedia/widget";
import AssetOwnership from "./assetOwnership/widget";
import AssetFinancialDetail from "./assetFinancialDetail/financialDetailsWidgetWrapper";
import AssetUsage from "./assetUsage/widget";
import AssetComponents from "./assetComponents/widget";
import AssetAdvertisements from "./assetAdvertisements/widget";
import AssetFacts from "./fact/widget/assetFactsWidget";
import ComponentHeader from "./component/header";
import ComponentSummary from "./component/summary";
import ComponentMedia from "./componentMedia/widget";
import ComponentParties from "./component/componentPartyWidget";
import ComponentAssetSummary from "./component/assetSummaryWidget";
import ComponentUsage from "./componentUsage/widget";
import ComponentFacts from "./fact/widget/componentFactsWidget";

const AssetDocuments = ({ asset, ...other }) => {
    return <Documents reference={asset} referenceId={asset.id} {...other} />;
};

const ComponentDocuments = ({ component, ...other }) => {
    return <Documents reference={component} referenceId={component.id} {...other} />;
};

export default withResources(({ getResource }) => {
    return [
        <Fill key="assetHeader" name="asset-header">
            <AssetHeader />
        </Fill>,
        <Fill key="assetSummary" name="asset-summary">
            <AssetSummary />
        </Fill>,
        <Fill key="assetPosition" name="asset-position">
            <AssetPosition />
        </Fill>,
        <Fill key="assetDocumentsGeneral" name="asset-documents-generic">
            <AssetDocuments
                library="asset-documents-generic"
                title={getResource("Asset.documents.widget.generic.header", "Documents")}
            />
        </Fill>,
        <Fill key="assetDocumentsFinancial" name="asset-documents-financial">
            <AssetDocuments
                library="asset-documents-financial"
                title={getResource(
                    "Asset.documents.widget.financial.header",
                    "Financial documents"
                )}
            />
        </Fill>,
        <Fill key="assetDocumentsOwnership" name="asset-documents-ownership">
            <AssetDocuments
                library="asset-documents-ownership"
                title={getResource(
                    "Asset.documents.widget.ownership.header",
                    "Ownership documents"
                )}
            />
        </Fill>,
        <Fill key="assetMedia" name="asset-media">
            <AssetMedia
                library="generic"
                title={getResource("Asset.media.widget.generic.header", "Media library")}
            />
        </Fill>,
        <Fill key="assetOwnership" name="asset-ownership">
            <AssetOwnership />
        </Fill>,
        <Fill key="assetFinancialDetail" name="asset-financial-detail">
            <AssetFinancialDetail />
        </Fill>,
        <Fill key="assetUsage" name="asset-usage">
            <AssetUsage />
        </Fill>,
        <Fill key="assetComponents" name="asset-components">
            <AssetComponents />
        </Fill>,
        <Fill key="assetAdvertisements" name="asset-advertisements">
            <AssetAdvertisements />
        </Fill>,
        <Fill key="assetFacts" name="asset-facts">
            <AssetFacts />
        </Fill>,

        <Fill key="componentHeader" name="component-header">
            <ComponentHeader />
        </Fill>,
        <Fill key="componentSummary" name="component-summary">
            <ComponentSummary />
        </Fill>,
        <Fill key="componentDocuments" name="component-documents">
            <ComponentDocuments
                library="component-documents"
                title={getResource("Asset.components.documents.widget.header", "Documents")}
            />
        </Fill>,
        <Fill key="componentMedia" name="component-media">
            <ComponentMedia
                library="generic"
                title={getResource("Asset.components.media.widget.generic.header", "Media library")}
            />
        </Fill>,
        <Fill key="componentParties" name="component-parties">
            <ComponentParties />
        </Fill>,
        <Fill key="componentAssetSummary" name="component-asset-summary">
            <ComponentAssetSummary nrOfRowsAlwaysVisible={6} />
        </Fill>,
        <Fill key="componentUsage" name="component-usage">
            <ComponentUsage />
        </Fill>,
        <Fill key="componentFacts" name="component-facts">
            <ComponentFacts />
        </Fill>,
    ];
});
