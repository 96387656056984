import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import useStyles from "./useStyles";

/**
 * This is a wrapper component for styling purposes only for tables that are use a form.
 * the children should be native table elements (so no Material UI components). So <tr>'s
 * with <th scope='row'>'s for row headings and <td>'s for the data cells. style={{width: x}} attributes
 * can be placed on these elements to make sure cells don't take more space than
 * needed. Otherwise, the width is divided equally.
 **/
const FormTable = ({ renderCaption, children, id }) => {
    const classes = useStyles();

    return (
        <table className={clsx(classes.root, "form-table")} id={id}>
            {renderCaption && renderCaption()}
            <tbody>{children}</tbody>
        </table>
    );
};

FormTable.propTypes = {
    renderCaption: PropTypes.func,
};

export default FormTable;
