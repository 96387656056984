import React from "react";
import { makeStyles } from "@material-ui/styles";
import variables from "./variables";
import Paper from "@material-ui/core/Paper";

/**
 * This Loading skelleton is shown when all widgets are loaded. This is determined via CSS, by
 * checking via the general sibling selector (.widget--is-loading ~ &)
 */

const useStyles = makeStyles((theme) => ({
    loadingSkelleton: {
        position: "absolute",
        top: -2,
        right: -2,
        bottom: -2,
        left: -2,
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        margin: "0 !important",
        padding: 2,
        /** Gradient is used to prevent overlapping the sidebar: **/
        background: "linear-gradient(to right, transparent, transparent 5px, #fafafa 5px)",
        width: "auto !important",
        opacity: 0,
        visibility: "hidden",
        transition: "opacity 0.5s, visibility 0s linear 0.5s",
        zIndex: 5,

        /** Show the loading skelleton when at least one preceding widget is still loading: **/
        ".widget--is-loading ~ &": {
            opacity: 1,
            visibility: "visible",
            transition: "none",
        },

        "& > *": {
            position: "relative",
            width: "100%",
            height: "40vh",
            minHeight: 300,
            overflow: "hidden",
            marginBottom: theme.spacing(3),

            [theme.breakpoints.up(variables.firstBp)]: {
                marginLeft: theme.spacing(3),
            },

            [theme.breakpoints.up(variables.secondBp)]: {
                width: `calc(100% / 2 - ${theme.spacing(3)}px)`,
                height: "30vh",
            },

            [theme.breakpoints.up(variables.thirdBp)]: {
                width: `calc(100% / 3 - ${theme.spacing(3)}px)`,
            },

            "&::before": {
                content: "''",
                position: "absolute",
                top: theme.spacing(2),
                left: "10%",
                width: "80%",
                height: theme.spacing(3),
                backgroundColor: "#eee",
            },

            "&::after": {
                content: "''",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                transform: "translateX(-100%)",
                backgroundImage:
                    "linear-gradient(" +
                    "90deg," +
                    "rgba(255, 255, 255, 0) 0," +
                    "rgba(255, 255, 255, 0.2) 20%," +
                    "rgba(255, 255, 255, 0.5) 60%," +
                    "rgba(255, 255, 255, 0)" +
                    ")",
                animation: "$shimmer 2s infinite",
            },
        },
    },

    fakeButton: {
        content: "''",
        position: "absolute",
        right: 0,
        bottom: theme.spacing(1.5),
        left: 0,
        height: 32,
        width: 90,
        margin: "auto",
        borderRadius: 4,
        backgroundColor: "#eee",
    },

    "@keyframes shimmer": {
        "100%": {
            transform: "translateX(100%)",
        },
    },
}));

const LoadingSkelleton = ({ nrOfWidgets }) => {
    const classes = useStyles();

    return (
        <div className={classes.loadingSkelleton}>
            {[...Array(nrOfWidgets)].map((item, i) => (
                <Paper key={i}>
                    <div className={classes.fakeButton}>&nbsp;</div>
                </Paper>
            ))}
        </div>
    );
};

export default LoadingSkelleton;
