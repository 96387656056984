import React, { useContext } from "react";
import { LinearProgress, InputAdornment } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { add, getActions } from "./dataservice";
import { getCascading as getCascadingUsageRecords } from "../../assets/usages/dataservice";
import { getCascading as getCascadingExpectedUsages } from "../../assets/expectedUsages/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import DocumentContext from "./documentcontext";
import Upload from "../../../shared/upload";
import condionallyAddReferenceId from "./condionallyAddReferenceId";
import TranslatableTextField from "../../../shared/translatableTextField";
import ActionsEditor, { validateActions } from "../../../shared/actionsEditor";
import { getAll as getAllUsageUnits } from "../../system/usageunits/dataservice";
import { config } from "config";

export default withResources(({ getResource, open, onClose, library, referenceId }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI((values) => add(referenceId, library, values));
    const { data: allActions } = useAPI(getActions, true);
    const { data: units } = useAPI(() => getAllUsageUnits(), true);
    const { data: usageRecords } = useAPI(() => {
        if (library.startsWith("asset")) {
            return getCascadingUsageRecords("assets", referenceId);
        } else if (library.startsWith("component")) {
            return getCascadingUsageRecords("components", referenceId);
        } else {
            return null;
        }
    }, true);
    const { data: expectedUsages } = useAPI(() => {
        if (library.startsWith("asset")) {
            return getCascadingExpectedUsages("assets", referenceId);
        } else if (library.startsWith("component")) {
            return getCascadingExpectedUsages("components", referenceId);
        } else {
            return null;
        }
    }, true);

    const { allowedDocumentExtensions } = useContext(ConfigurationContext);

    const { getSupportedDocumentTypes } = useContext(DocumentContext);
    const documentTypes = getSupportedDocumentTypes(library);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Document.document.add.enterName",
                "Please enter a name for the default language"
            );
        }

        if (!values.fileName) {
            errors.fileName = getResource(
                "Document.document.add.enterFileName",
                "Please enter a file name"
            );
        } else if (!values.fileName.match(config.fileNameRegEx)) {
            errors.fileName = getResource(
                "Document.document.add.enterValidFileName",
                "Please enter a valid file name"
            );
        }

        if (!values.type) {
            errors.type = getResource("Document.document.add.enterType", "Please enter a type");
        }

        if (!values.fileId) {
            errors.fileId = getResource("Document.document.add.uploadFile", "Please upload a file");
        }

        validateActions(values, "actions", errors, getResource, units);

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);
    const isLoaded = units;

    return (
        <FormDrawer
            title={getResource("Document.document.add.title", "Add document")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ name: "" }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Document.document.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        function handleFileUpload(file) {
                            let fileId = "",
                                extension = "",
                                fileNameWithoutExtension = "",
                                validFileName = "";

                            if (file) {
                                const indexOfExtension = file.name.lastIndexOf(".");
                                extension = file.name.substring(indexOfExtension);
                                fileNameWithoutExtension = file.name.substring(0, indexOfExtension);
                                validFileName = fileNameWithoutExtension.replace(/[\\/:"*?<>|]+/);
                                fileId = file.id;
                            }

                            updateValues([
                                {
                                    name: "fileId",
                                    value: fileId,
                                    isLocalizable: false,
                                },
                                {
                                    name: "name",
                                    value: fileNameWithoutExtension,
                                    isLocalizable: true,
                                },
                                {
                                    name: "fileName",
                                    value: validFileName,
                                    isLocalizable: false,
                                },
                                {
                                    name: "extension",
                                    value: extension,
                                    isLocalizable: false,
                                },
                            ]);
                        }

                        return (
                            <React.Fragment>
                                <Upload
                                    errors={errors}
                                    url={condionallyAddReferenceId(
                                        `/api/documents/libraries/${library}/upload`,
                                        referenceId
                                    )}
                                    onFileUpload={handleFileUpload}
                                    disabled={!isDefaultCulture}
                                    allowedExtensions={allowedDocumentExtensions}
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Document.document.add.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="fileName"
                                    labelResourceKey="Document.document.add.fileNameField"
                                    labelDefaultValue="File name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 255 }}
                                    value={values.fileName}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture}
                                    InputProps={{
                                        endAdornment: values.extension && (
                                            <InputAdornment position="end">
                                                {values.extension}
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="type"
                                    label={getResource("Document.document.add.typeField", "Type")}
                                    fullWidth
                                    isMedium
                                    value={values.type}
                                    onSelection={(e) => handleChange(e)}
                                    options={
                                        documentTypes
                                            ? documentTypes.map((t) => ({
                                                id: t,
                                                name: t,
                                            }))
                                            : null
                                    }
                                    disabled={!isDefaultCulture}
                                    required
                                />

                                {(library.startsWith("asset") ||
                                    library.startsWith("component")) && (
                                        <LabelledOutline
                                            label={getResource(
                                                "Document.document.add.actionsHeader",
                                                "When is the document due for review, renewal, etc"
                                            )}
                                        >
                                            <ActionsEditor
                                                errors={errors["actions"]}
                                                values={values.actions}
                                                name="actions"
                                                onChange={(e) => handleChange(e)}
                                                disabled={!isDefaultCulture}
                                                actions={allActions}
                                                linkedToComponent={library.startsWith("component")}
                                                expectedUsages={expectedUsages}
                                                usageRecord={usageRecords}
                                                isInstalled={false}
                                                hasConstructionDate={false}
                                                units={units}
                                            />
                                        </LabelledOutline>
                                    )}
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
