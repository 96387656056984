import React, { useContext } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import LocalizationContext from "./localization/localizationcontext";

const MuiPickerUtilsWrapper = ({ children }) => {
    const { dateFnsLocale } = useContext(LocalizationContext);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default MuiPickerUtilsWrapper;
