import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { get as getAsset } from "../../assets/asset/dataservice";
import ValuationList from "../valuation/components/valuationList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import { getRelevantValuations } from "../valuation/dataservice";
import withResources from "../../../shared/textresources/withresources";
import StartValuation from "../valuation/startValuation";
import useDialog from "../../../shared/usedialog";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
    content: {
        padding: 0,
    },
    item: {
        padding: theme.spacing(3),
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            "&:not(:last-of-type)": {
                borderRight: `1px solid ${theme.palette.divider}`,
            },
        },
        [theme.breakpoints.down("sm")]: {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
    },
    valueContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    label: {
        marginLeft: theme.spacing(1),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    buttonLoadingIndicator: {
        marginLeft: theme.spacing(1),
    },
}));

export default withResources(
    ({
        getResource,
        className,
        match: {
            params: { assetId },
        },
        history,
        ...rest
    }) => {
        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

        const { data: asset } = useAPI(() => getAsset(assetId), true);
        const { data: relevantValuations, loading: relevantLoading } = useAPI(
            () => getRelevantValuations(null, assetId),
            true
        );

        const {
            open: handleStartValuation,
            close: handleCloseStartValuation,
            visible: startValuationToggled,
        } = useDialog();

        const handleEditValuation = (valuationId) => {
            history.push(`/valuation/asset-valuation/editor/${valuationId}`);
        };

        const classes = useStyles();

        if (!asset || !relevantValuations) {
            return <LinearProgress />;
        }

        var assetName =
            asset &&
            ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                `${asset.manufacturerName} / ${asset.assetModel.name}`);

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Valuation.assetValuation.details.valuationTitle",
                        "Valuations for {assetName}",
                        { assetName }
                    )}
                    breadcrumb={asset.assetCategories}
                    renderActions={() => {
                        return (
                            <React.Fragment>
                                {relevantValuations.currentDraftValuationId && (
                                    <AuthorizedButton
                                        color="primary"
                                        variant="contained"
                                        requiredOperation="asset-valuations-create"
                                        onClick={() =>
                                            handleEditValuation(
                                                relevantValuations &&
                                                    relevantValuations.currentDraftValuationId
                                            )
                                        }
                                        buttonText={getResource(
                                            "Valuation.assetValuation.details.continueValuationButton",
                                            "Continue"
                                        )}
                                        className={classes.button}
                                    />
                                )}
                                <AuthorizedButton
                                    color={
                                        relevantValuations.currentDraftValuationId
                                            ? "secondary"
                                            : "primary"
                                    }
                                    variant="contained"
                                    requiredOperation="asset-valuations-create"
                                    onClick={handleStartValuation}
                                    buttonText={getResource(
                                        "Valuation.assetValuation.details.startValuationButton",
                                        "Start new"
                                    )}
                                    className={classes.button}
                                >
                                    {relevantLoading && (
                                        <CircularProgress
                                            className={classes.buttonLoadingIndicator}
                                            size={20}
                                        />
                                    )}
                                </AuthorizedButton>
                                {relevantValuations.currentPublishedValuationId && (
                                    <AuthorizedButton
                                        color="secondary"
                                        variant="contained"
                                        requiredOperation="asset-valuations-view-published"
                                        onClick={() =>
                                            handleEditValuation(
                                                relevantValuations &&
                                                    relevantValuations.currentPublishedValuationId
                                            )
                                        }
                                        buttonText={getResource(
                                            "Valuation.assetValuation.details.openValuationButton",
                                            "Open current"
                                        )}
                                        className={classes.button}
                                    >
                                        {relevantLoading && (
                                            <CircularProgress
                                                className={classes.buttonLoadingIndicator}
                                                size={20}
                                            />
                                        )}
                                    </AuthorizedButton>
                                )}
                            </React.Fragment>
                        );
                    }}
                />

                <ValuationList
                    assetId={asset.id}
                    onEditValuation={handleEditValuation}
                    requiredEditOperation={"asset-valuations-create"}
                    requiredArchiveOperation={"asset-valuations-archive"}
                    name={assetName}
                />

                <StartValuation
                    assetId={asset.id}
                    open={startValuationToggled}
                    onClose={handleCloseStartValuation}
                    history={history}
                    onValuationStarted={handleEditValuation}
                    {...rest}
                />
            </ContentHolder>
        );
    }
);
