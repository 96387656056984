const variables = {
    marksPositiveAge: [
        { value: 0, label: "0 year" },
        { value: 25, label: "25" },
        { value: 50, label: "50" },
        { value: 75, label: "75" },
        { value: 100, label: "100" },
    ],
    marksPositivePercentage: [
        { value: 0, label: "0%" },
        { value: 25, label: "25%" },
        { value: 50, label: "50%" },
        { value: 75, label: "75%" },
        { value: 100, label: "100%" },
    ],
    marksPositiveNegativePercentage: [
        { value: -100, label: "-100%" },
        { value: -75, label: "-75%" },
        { value: -50, label: "-50%" },
        { value: -25, label: "-25%" },
        { value: 0, label: "0%" },
        { value: 25, label: "25%" },
        { value: 50, label: "50%" },
        { value: 75, label: "75%" },
        { value: 100, label: "100%" },
    ],
};

export default variables;
