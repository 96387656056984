import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(freeText, attributeTypeId, missingLanguages, page, pageSize) {
    const params = {
        page,
        pageSize,
        freeText,
        attributeTypeId,
        missingLanguages,
    };

    const url = `/api/library/assetattributedefinition?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAttributeTypes() {
    const url = `/api/library/attributetype`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/library/assetattributedefinition/${id}`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/library/assetattributedefinition/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/library/assetattributedefinition", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/library/assetattributedefinition/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/library/assetattributedefinition/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { get, getIsReferenced, getAll, add, del, getAttributeTypes, update };
