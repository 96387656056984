import React, { useRef } from "react";
import ImageGallery from "react-image-gallery";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import useAuthorization from "../../../shared/authentication/useauthorization";
import Widget from "../../../shared/widget";
import { ImageOutlined as ImageIcon } from "@material-ui/icons";
import useStyles from "../../../shared/mediaWidget/useStyles";

const WorkOrderMedia = withResources(({ getResource, workOrder }) => {
    const classes = useStyles();
    const imageGalleryRef = useRef(null);

    const { data: media, loading } = useAPI(() => getAll(workOrder.id, "generic", null), true);

    const images =
        media &&
        media.map((media) => ({
            original: `/api/maintenance/workOrderMedia/${workOrder.id}/media/generic/${media.id}/view?imageSize=MediaLibraryWidgetImage&v=${media.fileId}`,
            thumbnail: `/api/maintenance/workOrderMedia/${workOrder.id}/media/generic/${media.id}/view?imageSize=MediaLibraryWidgetThumb&v=${media.fileId}`,
            fullscreen: `/api/maintenance/workOrderMedia/${workOrder.id}/media/generic/${media.id}/view?imageSize=MediaLibraryWidgetFullScreen&v=${media.fileId}`,
            description: media.description,
        }));

    const renderThumbnail = (item) => {
        return (
            <div
                className="image-gallery-thumbnail-inner"
                style={{
                    background: `url('${item.thumbnail}') 50% 50%`,
                }}
            >
                {item.thumbnailLabel && (
                    <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>
                )}
            </div>
        );
    };

    return (
        <Widget
            headerTitle={getResource("Maintenance.workOrder.media.widget.header", "Media")}
            renderContent={() => {
                const showGalleryNav = images && images.length > 1;
                return (
                    <ImageGallery
                        lazyLoad
                        ref={imageGalleryRef}
                        items={images}
                        showPlayButton={false}
                        showThumbnails={showGalleryNav}
                        showNav={showGalleryNav}
                        additionalClass={classes.imageGallery}
                        renderThumbInner={renderThumbnail}
                        onClick={() => imageGalleryRef.current.fullScreen()}
                    />
                );
            }}
            loading={loading}
            hasContent={images != null && images.length > 0}
            icon={<ImageIcon />}
            noDataText={getResource(
                "Maintenance.workOrder.media.widget.noDataText",
                "There are no media items for this work order yet"
            )}
        />
    );
});

export default ({ workOrder }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: `work-order-media-view-generic`,
        companyId: workOrder.companyId,
        assetCollectionId: workOrder.assetCollectionIds,
    });

    if (isLoading || !isAuthorized) {
        return null;
    }

    return <WorkOrderMedia workOrder={workOrder} />;
};
