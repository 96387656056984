import React, { useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, allAssetTypes, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.question, values.assetTypeId);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Valuation.questions.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.question || ""}
                    AdornmentIcon={<SearchIcon />}
                    clearable
                    className="e2e_pagedListGenericSearchField"
                    onChange={(e) => {
                        handleValuesChanged({ ...searchValues, question: e.target.value });
                    }}
                />,
                <AutoCompleteStatic
                    label={getResource("Valuation.questions.search.assetTypeField", "Type")}
                    isSearchBox
                    value={searchValues.assetTypeId}
                    options={allAssetTypes && allAssetTypes.items}
                    onSelection={(e) => {
                        handleValuesChanged({ ...searchValues, assetTypeId: e.target.value });
                    }}
                />,
            ]}
        />
    );
});

export default Search;
