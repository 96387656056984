import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import useAPI from "../../../shared/useapi";
import { makeStyles } from "@material-ui/styles";
import { get } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Widget from "../../../shared/widget";
import useAuthorization from "../../../shared/authentication/useauthorization";
import useDialog from "../../../shared/usedialog";
import { Business as OwnerIcon } from "@material-ui/icons";
import Ownership from "./index";

const useStyles = makeStyles((theme) => ({
    dl: {
        "& dt": {
            marginBottom: 0,
        },

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",

            "&:not(.--summary-has-toggle):last-of-type": {
                marginBottom: 0,
            },
        },

        "& dd": {
            "&.email": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    },
}));

const OwnershipWidget = withResources(({ getResource, asset }) => {
    const classes = useStyles();

    const location = useLocation();

    const refreshParam = new URLSearchParams(location.search).get("refresh");
    const refreshData = refreshParam === "ownership";

    const { data, invoke } = useAPI(() => get(asset.id), true, [asset.id, refreshData]);

    const { data: allCountries, loading } = useAPI(() => getAllCountries(), true);

    const {
        open: handleViewOwnership,
        close: handleCloseViewOwnership,
        visible: viewOwnershipToggled,
        args: viewOwnershipId,
    } = useDialog(invoke);
    const {
        open: handleUpdateOwnership,
        close: handleCloseUpdateOwnership,
        visible: updateOwnershipToggled,
        args: updateOwnershipId,
    } = useDialog(invoke);

    if (!data || !allCountries) {
        return null;
    }

    return (
        <Fragment>
            <Widget
                headerTitle={getResource("Asset.asset.ownership.widget.headerTitle", "Ownership")}
                onHeaderClick={() => handleViewOwnership(asset.id)}
                headerLinkTitle={getResource(
                    "Asset.asset.ownership.widget.chevronAlt",
                    "See complete ownership information"
                )}
                loading={loading}
                hasContent={data !== null && data.current !== null}
                noDataText={getResource(
                    "Asset.asset.ownership.widget.noDataText",
                    "The owner of this asset is unknown"
                )}
                addButtonText={getResource(
                    "Asset.asset.ownership.widget.noDataButtonText",
                    "Set owner"
                )}
                onAddButtonClick={() => handleUpdateOwnership(asset.id)}
                icon={<OwnerIcon />}
                addButtonRequiredOperation={{
                    name: "assets-update-ownership",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                renderContent={() => {
                    return (
                        <dl className={classes.dl}>
                            <dt>
                                <ResourcedText
                                    resourceKey="Assets.asset.ownership.widget.NameField"
                                    defaultValue="Name"
                                />
                            </dt>
                            <dd>{data.current.name}</dd>
                            <dt>
                                <ResourcedText
                                    resourceKey="Assets.asset.ownership.widget.addressField"
                                    defaultValue="Address"
                                />
                            </dt>
                            <dd>
                                {data.current.address}
                                <br />
                                {data.current.postalCode} {data.current.city}
                                <br />
                                {data.current.state && `${data.current.state} - `}
                                {allCountries.find((x) => x.code === data.current.country).name}
                            </dd>
                            {data.current.chamberOfCommerce && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.widget.chamberOfCommerceField"
                                            defaultValue="Chamber of commerce"
                                        />
                                    </dt>
                                    <dd>{data.current.chamberOfCommerce}</dd>
                                </React.Fragment>
                            )}
                            {data.current.vatNumber && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.widget.vatNumberField"
                                            defaultValue="VAT Number"
                                        />
                                    </dt>
                                    <dd>{data.current.vatNumber}</dd>
                                </React.Fragment>
                            )}
                            {data.current.email && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.widget.emailField"
                                            defaultValue="Email"
                                        />
                                    </dt>
                                    <dd className="email" title={data.current.email}>
                                        {data.current.email}
                                    </dd>
                                </React.Fragment>
                            )}
                            {data.current.phoneNumber1 && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.widget.phoneNumber1Field"
                                            defaultValue="Phone number 1"
                                        />
                                    </dt>
                                    <dd>{data.current.phoneNumber1}</dd>
                                </React.Fragment>
                            )}
                            {data.current.phoneNumber2 && (
                                <React.Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.widget.phoneNumber2Field"
                                            defaultValue="Phone number 2"
                                        />
                                    </dt>
                                    <dd>{data.current.phoneNumber2}</dd>
                                </React.Fragment>
                            )}
                        </dl>
                    );
                }}
            />
            <Ownership
                open={viewOwnershipToggled}
                id={viewOwnershipId}
                onClose={handleCloseViewOwnership}
            />
            <Ownership
                update
                open={updateOwnershipToggled}
                id={updateOwnershipId}
                onClose={handleCloseUpdateOwnership}
            />
        </Fragment>
    );
});

export default ({ asset }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: "assets-view-ownership",
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading || !isAuthorized) {
        return null;
    }

    return <OwnershipWidget asset={asset} />;
};
