import React, { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import LabelledOutline from "../../../../shared/labelledoutline";
import useAPI from "../../../../shared/useapi";
import { getMergeInfo } from "../dataservice";
import LinkedAssets from "./linkedAssets";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import FormattedDate from "../../../../shared/formattedDate";

import withResources from "../../../../shared/textresources/withresources";

export default withResources(({ getResource, id, name }) => {
    const { data, loading } = useAPI(() => getMergeInfo(id), true);
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const useStyles = makeStyles((theme) => ({    
        property: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
        }
    }));

    const Property = ({ titleResourceKey, titleDefaultValue, titleTokens, value }) => {
        const classes = useStyles();
    
        return (
            <div className={classes.property}>
                <dt>
                    <ResourcedText
                        resourceKey={titleResourceKey}
                        defaultValue={titleDefaultValue}
                        tokens={titleTokens}
                    />
                </dt>
                <dd>{value}</dd>
            </div>
        );
    };

    return <LabelledOutline
            label={getResource(
                "Library.assetModel.merge.mergeInfo",
                "Details for model {name}",
                {
                    name: name
                }
            )}
        >{
            loading ? (
                <CircularProgress /> 
            ) : (
                <React.Fragment>
                    <dl>
                        <Property
                            titleResourceKey="Library.assetModel.merge.mergeInfo.valuationPublished"
                            titleDefaultValue="Valuation published"
                            value={data.valuationPublishDate ? 
                                getResource(
                                    "Library.assetModel.merge.mergeInfo.valuationPublished.yes",
                                    "Yes ({date})",
                                    {
                                        date: 
                                            <FormattedDate
                                                long
                                                date={data.valuationPublishDate}
                                            />
                                    }
                                ) :
                                getResource(
                                    "Library.assetModel.merge.mergeInfo.valuationPublished.no",
                                    "No"
                                )
                            }
                        />

                        <Property
                            titleResourceKey="Library.assetModel.merge.mergeInfo.quickScans"
                            titleDefaultValue="Quick scans"
                            value={data.quickScanCount}
                        />
                        
                        <Property
                            titleResourceKey="Library.assetModel.merge.mergeInfo.countryCorrections"
                            titleDefaultValue="Country corrections"
                            value={data.countryCorrectionsCount}
                        />
                    </dl>
                    <LinkedAssets linkedAssets={data.assets}/>
                </React.Fragment>) 
        }
        </LabelledOutline>            
});
