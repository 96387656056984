import React from "react";
import { LinearProgress, Paper } from "@material-ui/core";
import { getContent } from "../contentpages/dataservice";
import useAPI from "../../../shared/useapi";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import useStyles from "./useStyles";

export default (props) => {
    const { culture, slug } = { ...props.match.params };
    const { loading, data } = useAPI(() => getContent(culture, slug), true);
    const classes = useStyles();

    return loading ? (
        <LinearProgress />
    ) : (
        <div className={classes.contentSection}>
            <ContentHolder>
                <Header title={data.title} />
                <Paper>
                    <div
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: data.body }}
                    />
                </Paper>
            </ContentHolder>
        </div>
    );
};
