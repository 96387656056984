import closeSiblings from "./closeSiblings";
import setChildrenOnItem from "./setChildrenOnItem";
import getItemInTree from "./getItemInTree";

const handleUpdateChildren = async (
    parentId,
    itemTree,
    setItemTree,
    getChildren,
    itemId = null
) => {
    let copyOfItemTree = { ...itemTree };

    /** Refresh the item level **/
    if (parentId === 0) {
        await setChildrenOnItem(copyOfItemTree, getChildren);
    } else {
        await closeSiblings(copyOfItemTree, parentId);
        const parentInTree = await getItemInTree(copyOfItemTree, parentId);
        parentInTree.open = true;
        await setChildrenOnItem(parentInTree, getChildren);
    }

    /** Also open the item itself if itemId is passed */
    if (itemId) {
        const itemInTree = await getItemInTree(copyOfItemTree, itemId);
        itemInTree.open = true;
        await setChildrenOnItem(itemInTree, getChildren);
    }

    setItemTree(copyOfItemTree);
};

export default handleUpdateChildren;
