import { useState } from "react";
import { useLocation } from "react-router-dom";

// hook for using session storage
function useSessionStorage(key, initialValue, preventRemove = false) {
    const preventRemovePrefix = "__";
    const location = useLocation();

    const getSessionKey = (module) => {
        if (preventRemove) {
            return `${preventRemovePrefix}${key}`;
        }
        return module ? `${module}-${key}` : key;
    };

    const getModule = () => {
        const pathParts = location.pathname.split("/", 2);
        return pathParts.length === 2 ? pathParts[1].toLowerCase() : null;
    };

    const removeSessionStorageForOtherModules = (module) => {
        for (let i = 0; i < window.sessionStorage.length; i++) {
            const storageKey = window.sessionStorage.key(i);
            if (!storageKey.startsWith(module) && !storageKey.startsWith(preventRemovePrefix)) {
                window.sessionStorage.removeItem(storageKey);
            }
        }
    };

    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        const module = getModule();
        if (module) {
            removeSessionStorageForOtherModules(module);
        }

        const item = window.sessionStorage.getItem(getSessionKey(module));
        return item ? JSON.parse(item) : initialValue;
    });

    const setValue = (value) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        window.sessionStorage.setItem(getSessionKey(getModule()), JSON.stringify(valueToStore));
    };

    return [storedValue, setValue];
}

export default useSessionStorage;
