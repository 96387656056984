import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, messages, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke({ messages })) {
            onClose(true);
        }
    };

    var headerText =
        messages.length === 1
            ? getResource("Marketplace.messages.delete.headerSingle", "Delete message?")
            : getResource("Marketplace.messages.delete.headerMultiple", "Delete messages?");

    var bodyText =
        messages.length === 1
            ? getResource(
                  "Marketplace.messages.delete.deleteMessageSingle",
                  "Are you sure you want to delete this message? "
              )
            : getResource(
                  "Marketplace.messages.delete.deleteMessageMultiple",
                  "Are you sure you want to delete these messages? "
              );

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={headerText}
            buttonText={getResource("Marketplace.messages.delete.okButton", "Delete")}
        >
            {bodyText}
        </Confirmation>
    );
});
