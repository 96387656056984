import React, { Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Publish as PublishIcon,
    Description as DescriptionIcon,
    GetApp as DownloadIcon,
} from "@material-ui/icons";

import Search from "./search";
import ActionMenu from "../../../shared/actionmenu";
import PagedList from "../../../shared/pagedList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import _ from "underscore";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import FormattedCurrency from "../../../shared/formattedCurrency";
import FormattedDate from "../../../shared/formattedDate";
import WorkingContext from "../../security/workingContext";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        onDownloadDraft,
        onPublish,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <Fragment>
                <WorkingContext
                    requiredOperations={["appraisals-view-draft", "appraisals-view-published"]}
                    hideAssetCollections
                />
                <ContentHolder>
                    <Header
                        title={getResource("Valuation.appraisals.header.title", "Appraisals")}
                        renderActions={() => (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                requiredOperation="appraisals-create"
                                buttonText={getResource(
                                    "Valuation.appraisals.newAppraisalButton",
                                    "Create"
                                )}
                            />
                        )}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Valuation.appraisals.noResults",
                            "No appraisals available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.assetHeader"
                                            defaultValue="Asset"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.fairMarketValueHeader"
                                            defaultValue="Fair market value"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.liquidationValueHeader"
                                            defaultValue="Liquidation value"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.dateHeader"
                                            defaultValue="Date"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.statusHeader"
                                            defaultValue="Status"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Valuation.appraisals.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => onEdit(row.id)}
                                            requiredOperation="questions-update"
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.assetName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <FormattedCurrency
                                                    value={row.fairMarketValue}
                                                    currency={row.currencyShortName}
                                                    decimals={0}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <FormattedCurrency
                                                    value={row.liquidationValue}
                                                    currency={row.currencyShortName}
                                                    decimals={0}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <FormattedDate long date={row.date} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getResource(
                                                    `Valuation.appraisals.status.${row.status}`,
                                                    row.status
                                                )}
                                            </TableCell>

                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Valuation.appraisals.downloadAppraisalDraftButton",
                                                                "Download draft"
                                                            ),
                                                            icon: <DescriptionIcon />,
                                                            itemId: row.id,
                                                            requiredOperation:
                                                                "appraisals-download-draft",
                                                            onClick: () => onDownloadDraft(row.id),
                                                            showOnlyWhen: row.status === "Draft",
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.appraisals.editAppraisalButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "appraisals-update",
                                                            onClick: () => onEdit(row.id),
                                                            showOnlyWhen: row.status === "Draft",
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.appraisals.deleteAppraisalButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "appraisals-delete",
                                                            onClick: () =>
                                                                onDelete({
                                                                    id: row.id,
                                                                    assetName: row.assetName,
                                                                }),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.appraisals.publishAppraisalButton",
                                                                "Publish"
                                                            ),
                                                            icon: <PublishIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "appraisals-publish",
                                                            onClick: () => onPublish(row),
                                                            showOnlyWhen: row.status === "Draft",
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Valuation.appraisals.downloadAppraisalButton",
                                                                "Download"
                                                            ),
                                                            icon: <DownloadIcon />,
                                                            itemId: row.id,
                                                            requiredOperation:
                                                                "appraisals-download",
                                                            onClick: () =>
                                                                window.open(
                                                                    `/api/valuation/appraisal/${row.id}/download`,
                                                                    "_blank"
                                                                ),
                                                            showOnlyWhen:
                                                                row.status === "Published" ||
                                                                row.status === "Archived",
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </Fragment>
        );
    }
);

export default Overview;
