import React from "react";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import Edit from "./edit";
import { getAll } from "./dataservice";

import useDialog from "../../../shared/usedialog";

export default () => {
    const { loading, invoke, data } = useAPI(
        () => getAll(),
        true
    );
    
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="dictionaries-view"
                path="/system/dictionaries"
                render={(props) => (
                    <Overview
                        data={data}
                        loading={loading}
                        onEdit={handleEdit}
                        {...props}
                    />
                )}
            />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
        </React.Fragment>
    );
};
