import React, { useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import _ from "underscore";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../shared/textresources/withresources";
import PagedListContent from "./pagedListContent";
import clsx from "clsx";
import { Checkbox, FormControlLabel, Switch, TableCell } from "@material-ui/core";

import {
    Delete as DeleteIcon,
    Visibility as ViewIcon,
    Edit as EditIcon,
    DynamicFeed as Batch,
    Money as TotalValues,
    FindInPage as QuickScanIcon,
    Euro as FinancialDetailsIcon,
    AssignmentInd as ShowOwnershipIcon,
    EuroSymbol as ShowFinancialIcon,
    ArrowForward as AssignAssetCollectionIcon,
    SyncAlt as MoveAssetCollectionIcon,
    SwapHoriz as MoveAllAssetCollectionIcon,
    ArrowBack as RemoveAssetCollectionIcon,
    OpenWith as MovementsIcon,
    EmojiTransportation as MoveCompanyIcon,
    DateRange as TimelineIcon,
    ImportExport as ExportIcon,
    ImportExport as ImportIcon,
    Image as ShowThumbnailsIcon,
    List as EventsIcon,
    Lock as SealIcon,
    LockOpen as UnsealIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    checkboxCell: {
        width: 52,
        cursor: "auto",

        "& + td, & + th": {
            paddingLeft: 8,
        },
    },
}));

const Overview = withResources((props) => {
    const {
        getResource,
        history,
        availablePageSizes,
        loading,
        selectedAssets,
        searchParams,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onSeal,
        onUnseal,
        onDelete,
        onTotalValue,
        onFinancialDetails,
        onOwnership,
        onPurchaseAssetQuickScanBatch,
        onSelectAsset,
        onSelectAllAssets,
        onDeselectAllAssets,
        onAssignAssetCollection,
        onMoveAssetCollection,
        onMoveAllAssetCollection,
        onRemoveAssetCollection,
        onMoveCompany,
        onMovements,
        onExport,
        onImport,
        defaultDebounceInterval,
        showFinancials,
        setShowFinancials,
        showThumbnails,
        setShowThumbnails
    } = props;
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const classes = useStyles();
    const data = props.data || {};
    const items = data.items;
    const sortedColumnHeader = data.sortedColumnHeader;
    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    return (
        <Fragment>
            <WorkingContext requiredOperation="assets-view" />
            <ContentHolder>
                <Header
                    title={getResource("Asset.assets.header.title", "Assets")}
                    renderActions={() => {
                        if (companyScope.id && selectedAssets.length > 0) {
                            return (
                                <ActionMenu
                                    buttonLabel={getResource(
                                        "Asset.assets.bulkMenuLabel",
                                        "Actions"
                                    )}
                                    actions={[
                                        {
                                            text: getResource(
                                                "Asset.assets.assignAssetCollectionButton",
                                                "Assign to collection"
                                            ),
                                            icon: <AssignAssetCollectionIcon />,
                                            requiredOperation: {
                                                name: "assets-assign-asset-collection",
                                                companyId: companyScope.id,
                                            },
                                            onClick: () => onAssignAssetCollection(selectedAssets),
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.moveAssetCollectionButton",
                                                "Move to collection"
                                            ),
                                            icon: <MoveAssetCollectionIcon />,
                                            requiredOperation: {
                                                name: "assets-remove-asset-collection",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: () => onMoveAssetCollection(selectedAssets),
                                            hide: assetCollectionScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.removeAssetCollectionButton",
                                                "Remove from collection"
                                            ),
                                            icon: <RemoveAssetCollectionIcon />,
                                            requiredOperation: {
                                                name: "assets-remove-asset-collection",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: () => onRemoveAssetCollection(selectedAssets),
                                            hide: assetCollectionScope.id === null,
                                        },
                                    ]}
                                />
                            );
                        }

                        return (
                            <Fragment>
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="assets-create"
                                    id="e2e_createButtonAsset"
                                    buttonText={getResource(
                                        "Asset.assets.newAssetButton",
                                        "Create"
                                    )}
                                />
                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Asset.assets.purchaseQuickScanBatchButton",
                                                "Purchase quick scan batch"
                                            ),
                                            icon: <Batch />,
                                            requiredOperation: {
                                                name: "asset-quick-scans-purchase-batch",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: onPurchaseAssetQuickScanBatch,
                                            hide: companyScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.totalValueButton",
                                                "Calculate total values"
                                            ),
                                            icon: <TotalValues />,
                                            requiredOperation: {
                                                name: "assets-view-total-value",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: onTotalValue,
                                            hide: companyScope.id === null,
                                        },
                                        {
                                            preventClose: true,
                                            text: (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={showFinancials}
                                                            onChange={(e) =>
                                                                setShowFinancials(e.target.checked)
                                                            }
                                                            name="showFinancials"
                                                        />
                                                    }
                                                    label={getResource(
                                                        "Asset.assets.search.showFinancials",
                                                        "Show financials"
                                                    )}
                                                    labelPlacement="start"
                                                    variant="outlined"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginLeft: 0,
                                                    }}
                                                />
                                            ),
                                            icon: <ShowFinancialIcon />,
                                            requiredOperation: {
                                                name: "assets-view-financial",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                        },
                                        {
                                            preventClose: true,
                                            text: (
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={showThumbnails}
                                                            onChange={(e) =>
                                                                setShowThumbnails(e.target.checked)
                                                            }
                                                            name="showThumbnails"
                                                        />
                                                    }
                                                    label={getResource(
                                                        "Asset.assets.search.showThumbnails",
                                                        "Show thumbnails"
                                                    )}
                                                    labelPlacement="start"
                                                    variant="outlined"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginLeft: 0,
                                                    }}
                                                />
                                            ),
                                            icon: <ShowThumbnailsIcon />,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.moveAllAssetCollectionButton",
                                                "Move all to collection"
                                            ),
                                            icon: <MoveAllAssetCollectionIcon />,
                                            requiredOperation: {
                                                name: "assets-remove-asset-collection",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: () => onMoveAllAssetCollection(),
                                            hide:
                                                companyScope.id === null ||
                                                assetCollectionScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.viewTimelineButton",
                                                "View timeline"
                                            ),
                                            icon: <TimelineIcon />,
                                            requiredOperation: {
                                                name: "scheduled-events-view",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: () => history.push("/planning/timeline"),
                                            hide: companyScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.viewEventsButton",
                                                "View events"
                                            ),
                                            icon: <EventsIcon />,
                                            requiredOperation: {
                                                name: "scheduled-events-view",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            onClick: () => history.push("/planning/events"),
                                            hide: companyScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.exportButton",
                                                "Export to excel"
                                            ),
                                            requiredOperation: {
                                                name: "assets-export",
                                                companyId: companyScope.id,
                                                assetCollectionId: assetCollectionScope.id,
                                            },
                                            icon: <ExportIcon />,
                                            onClick: onExport,
                                            hide: companyScope.id === null,
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assets.importButton",
                                                "Import from Excel"
                                            ),
                                            requiredOperation: {
                                                name: "assets-import",
                                                companyId: companyScope.id,
                                            },
                                            icon: <ImportIcon />,
                                            onClick: onImport,
                                            hide: companyScope.id === null,
                                        },
                                    ]}
                                />
                            </Fragment>
                        );
                    }}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource("Asset.assets.noResults", "No assets available")}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <PagedListContent
                        items={items}
                        sortedColumnHeader={sortedColumnHeader}
                        handleRowClick={(row) => history.push(`/assets/assets/${row.id}`)}
                        showFinancials={showFinancials}
                        showThumbnails={showThumbnails}
                        getRequiredOperationForRowClick={(row) => {
                            return {
                                name: "assets-view",
                                companyId: row.companyId,
                                assetCollectionId: row.assetCollectionIds,
                            };
                        }}
                        renderSelectAllAssets={() => (
                            <TableCell
                                className={clsx(classes.checkboxCell, "checkboxCell")}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Checkbox
                                    checked={
                                        items && selectedAssets
                                            ? items.every((row) => selectedAssets.includes(row.id))
                                            : false
                                    }
                                    onChange={(e) =>
                                        e.target.checked
                                            ? onSelectAllAssets()
                                            : onDeselectAllAssets()
                                    }
                                    name="SelectAll"
                                    size="small"
                                />
                            </TableCell>
                        )}
                        renderSelectAsset={(row) => (
                            <TableCell
                                className={classes.checkboxCell}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Checkbox
                                    checked={selectedAssets.filter((x) => x === row.id).length > 0}
                                    onChange={(e) => onSelectAsset(e)}
                                    name={row.id.toString()}
                                    size="small"
                                />
                            </TableCell>
                        )}
                        renderActionMenu={(row) => (
                            <ActionMenu
                                actions={[
                                    {
                                        text: getResource("Asset.assets.viewAssetButton", "View"),
                                        icon: <ViewIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-view",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () =>
                                            history.push(`/assets/assets/${row.id}`, searchParams),
                                    },
                                    {
                                        showOnlyWhen: !row.isSealed,
                                        text: getResource("Asset.assets.editAssetButton", "Edit"),
                                        icon: <EditIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-update",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onEdit(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.deleteAssetButton",
                                            "Delete"
                                        ),
                                        icon: <DeleteIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-delete",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onDelete(row.id),
                                    },
                                    {
                                        text: getResource("Asset.assets.detail.seal", "Seal"),
                                        showOnlyWhen: !row.isSealed,
                                        icon: <SealIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-seal",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onSeal(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.detail.unseal",
                                            "Unseal"
                                        ),
                                        showOnlyWhen: row.isSealed,
                                        icon: <UnsealIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-seal",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onUnseal(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.viewAssetQuickScansButton",
                                            "View asset quick scans"
                                        ),
                                        icon: <QuickScanIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "asset-quick-scans-view",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => history.push(`/quick-scan/asset/${row.id}`),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.financialDetailsButton",
                                            "Financial details"
                                        ),
                                        icon: <FinancialDetailsIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-update-financial",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onFinancialDetails(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.viewOwnershipButton",
                                            "View ownership"
                                        ),
                                        icon: <ShowOwnershipIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-view-ownership",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onOwnership(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.moveCompanyButton",
                                            "Move to company"
                                        ),
                                        icon: <MoveCompanyIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-remove-company",
                                            companyId: row.companyId,
                                        },
                                        onClick: () => onMoveCompany(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.viewMovementsButton",
                                            "View movements"
                                        ),
                                        icon: <MovementsIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "assets-view-movements",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => onMovements(row.id),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.viewTimelineButton",
                                            "View timeline"
                                        ),
                                        icon: <TimelineIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "scheduled-events-view",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => history.push(`/planning/timeline/${row.id}`),
                                    },
                                    {
                                        text: getResource(
                                            "Asset.assets.viewEventsButton",
                                            "View events"
                                        ),
                                        icon: <EventsIcon />,
                                        itemId: row.id,
                                        requiredOperation: {
                                            name: "scheduled-events-view",
                                            companyId: row.companyId,
                                            assetCollectionId: row.assetCollectionIds,
                                        },
                                        onClick: () => history.push(`/planning/events/assets/${row.id}`),
                                    },
                                ]}
                            />
                        )}
                    />
                </PagedList>
            </ContentHolder>
        </Fragment>
    );
});

export default Overview;
