import getItemInTree from "./getItemInTree";
import closeSiblings from "./closeSiblings";
import setChildrenOnItem from "./setChildrenOnItem";

const handleToggle = async (clickedItem, itemTree, setItemTree, getChildren) => {
    let copyOfItemTree = { ...itemTree };
    let clickedItemInTree = getItemInTree(copyOfItemTree, clickedItem.id);

    if (clickedItem.open) {
        delete clickedItemInTree.open;
        delete clickedItemInTree.children;
        setItemTree(copyOfItemTree);
        return;
    }

    closeSiblings(copyOfItemTree, clickedItem.id, clickedItem);
    clickedItemInTree.open = true;
    /** Directly open the item for good perceived performance */
    setItemTree(copyOfItemTree);

    copyOfItemTree = { ...itemTree };
    clickedItemInTree = getItemInTree(copyOfItemTree, clickedItem.id);
    await setChildrenOnItem(clickedItemInTree, getChildren);
    setItemTree(copyOfItemTree);
};

export default handleToggle;
