import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import withResources from "../../../../../shared/textresources/withresources";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        padding: theme.spacing(3),
    },
}));

const EmptyNotificationList = withResources(({ getResource, className, ...rest }) => {
    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant="h4">
                {getResource(
                    "Shell.notifications.noNotificationsAvailable",
                    "No notications available"
                )}
            </Typography>
        </div>
    );
});

EmptyNotificationList.propTypes = {
    className: PropTypes.string,
};

export default EmptyNotificationList;
