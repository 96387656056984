import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardActions,
    CardContent,
    LinearProgress,
    TableContainer,
    TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Search as SearchIcon } from "@material-ui/icons";
import withResources from "./textresources/withresources";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    loaderRoot: {
        backgroundColor: theme.palette.grey[50],
    },
    loader: {
        visibility: "hidden",
        transition: "0s visibility linear 0.2s",

        "&.--visible": {
            visibility: "visible",
        },
    },
    topPaginationRoot: {
        marginTop: theme.spacing(1),
        flexShrink: 0,
    },
    topPaginationToolbar: {
        paddingLeft: 0,
    },
    filterButton: {
        marginRight: theme.spacing(2),
    },
    content: {
        padding: 0,
    },
    noTable: {
        backgroundColor: "transparent",
    },
    inner: {
        minWidth: "100%",
    },
    searchAndActions: {
        padding: theme.spacing(0, 1),
        justifyContent: "space-between",
        backgroundColor: theme.palette.grey[50],
    },
    actions: {
        padding: theme.spacing(0, 1),
        justifyContent: "flex-end",
        backgroundColor: theme.palette.grey[50],
    },
    noContent: {
        backgroundColor: theme.palette.white,
        padding: "20px",
        textAlign: "center",
        fontWeight: 500,
    },
    searchIcon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: "10px",
        borderRadius: ".8em",
        fontSize: "3em",
    },
}));

const PagedList = withResources(
    ({
        getResource,
        loading,
        itemCount,
        noResultsText,
        onPageChange,
        onPageSizeChange,
        availablePageSizes,
        page,
        pageSize,
        children,
        renderSearch,
        noTable,
        disablePaging
    }) => {
        const classes = useStyles();

        const displayedRows = ({ from, to, count }) => {
            //return `${from}- ${to === -1 ? count : to} of ${count !== -1 ? count : `more than ${to}`}`;

            const params = {
                from: from,
                to: to === -1 ? count : to,
                count: count,
            };
            return getResource("Shell.pagedList.displayedRows", "{from}-{to} of {count}", params);
        };

        return (
            <div>
                <Card className={noTable ? classes.noTable : ""}>
                    <CardActions
                        className={clsx(
                            renderSearch ? classes.searchAndActions : classes.actions,
                            "e2e_pagedListSearchAndActions"
                        )}
                    >
                        {renderSearch && renderSearch()}
                        {itemCount > 0 && !disablePaging && (
                            <TablePagination
                                classes={{
                                    root: classes.topPaginationRoot,
                                    toolbar: classes.topPaginationToolbar,
                                }}
                                className="pagedList__top-pagination"
                                component="div"
                                count={itemCount}
                                onChangePage={(e, page) => onPageChange(page)}
                                onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
                                page={page}
                                rowsPerPage={pageSize}
                                rowsPerPageOptions={[
                                    pageSize,
                                ]} /* Only way to hide selection box, is to give an array of 1 item */
                                labelRowsPerPage=""
                                labelDisplayedRows={displayedRows}
                            />
                        )}
                    </CardActions>
                    <CardContent className={classes.content}>
                        <div className={classes.inner}>
                            <div className={classes.loaderRoot}>
                                <LinearProgress
                                    className={clsx(classes.loader, loading && "--visible")}
                                />
                            </div>
                            {itemCount === 0 && (
                                <div className={classes.noContent}>
                                    <SearchIcon className={classes.searchIcon} />
                                    {noResultsText}
                                </div>
                            )}
                            {itemCount !== 0 && <TableContainer>{children}</TableContainer>}
                        </div>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        {itemCount > 0 && !disablePaging && (
                            <TablePagination
                                component="div"
                                count={itemCount}
                                onChangePage={(e, page) => onPageChange(page)}
                                onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
                                page={page}
                                rowsPerPage={pageSize}
                                rowsPerPageOptions={availablePageSizes}
                                labelRowsPerPage={getResource(
                                    "Shell.pagedList.rowsPerPage",
                                    "Rows per page"
                                )}
                                labelDisplayedRows={displayedRows}
                            />
                        )}
                    </CardActions>
                </Card>
            </div>
        );
    }
);

PagedList.propTypes = {
    getResource: PropTypes.string,
    loading: PropTypes.bool,
    itemCount: PropTypes.number,
    noResultsText: PropTypes.string,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    availablePageSizes: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    children: PropTypes.node,
    renderSearch: PropTypes.func,
    disablePaging: PropTypes.bool,
};

export default PagedList;
