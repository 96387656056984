import React, { Fragment, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import {
    KeyboardArrowDown as CollapseIcon,
    KeyboardArrowRight as ExpandIcon,
} from "@material-ui/icons";
import FormattedCurrency from "../../../../../shared/formattedCurrency";
import TaskTable from "../taskTable";
import TaskFooter from "../taskFooter";
import useStyles from "./useStyles";
import withResources from "../../../../../shared/textresources/withresources";
import {
    getTaskTotal,
    getTaskLaborTotal,
    getTaskMaterialTotal,
    getTaskLaborTotalHours,
} from "../../../workOrderTask/helpers";
import clsx from "clsx";

const TaskPanel = withResources(({ getResource, task, currencyShortName }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles();

    const records = [
        {
            title: task.summary,
            value: task.description,
            isDescription: true,
        },
        {
            title: getResource("Maintenance.workOrderReport.task.assignedTo.title", "Assigned to"),
            value: task.assignedTo,
        },
        {
            title: getResource(
                "Maintenance.workOrderReport.task.internalReference.title",
                "Internal reference"
            ),
            value: task.internalReference,
        },
        {
            title: getResource(
                "Maintenance.workOrderReport.task.totalEstimated.title",
                "Estimated"
            ),
            value: task.laborEstimated + task.materialsEstimated,
            isCurrency: true,
        },
        {
            title: getResource("Maintenance.workOrderReport.task.totalAmount.title", "Total"),
            value: getTaskTotal(task),
            isCurrency: true,
        },
    ];

    const labor = {
        name: getResource("Maintenance.workOrderReport.labor.title", "Labor"),
        items: task.labor,
        amount: getTaskLaborTotal(task),
        estimated: task.laborEstimated,
    };

    const materials = {
        name: getResource("Maintenance.workOrderReport.materials.title", "Materials"),
        items: task.materials,
        amount: getTaskMaterialTotal(task),
        estimated: task.materialsEstimated,
    };

    return (
        <Accordion
            square={true}
            classes={{ root: classes.AccordionRoot }}
            onChange={() => setIsExpanded(!isExpanded)}
            expanded={isExpanded}
        >
            <AccordionSummary
                classes={{
                    root: classes.AccordionSummary,
                    expanded: classes.AccordionSummaryExpanded,
                }}
            >
                <Fragment>
                    {isExpanded ? (
                        <IconButton className={`${classes.icon} expanded`}>
                            <CollapseIcon />
                        </IconButton>
                    ) : (
                        <IconButton className={classes.icon}>
                            <ExpandIcon />
                        </IconButton>
                    )}
                    <dl>
                        {records.map((record) => {
                            return (
                                <div
                                    key={record.title}
                                    className={clsx(
                                        record.isDescription && "description",
                                        isExpanded && "--is-expanded"
                                    )}
                                >
                                    <dt>{record.title}</dt>
                                    {record.isDescription && !record.value ? null : (
                                        <dd>
                                            {record.isCurrency ? (
                                                <FormattedCurrency
                                                    value={record.value}
                                                    decimals={2}
                                                    currency={currencyShortName}
                                                />
                                            ) : record.value ? (
                                                record.value
                                            ) : (
                                                "-"
                                            )}
                                        </dd>
                                    )}
                                </div>
                            );
                        })}
                    </dl>
                </Fragment>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.AccordionDetails }}>
                <TaskTable
                    data={labor}
                    currencyShortName={currencyShortName}
                    leftColumnHeader={getResource(
                        "Maintenance.workOrderReport.TableHeader.hoursWorked",
                        "Hours worked"
                    )}
                    rightColumnHeader={getResource(
                        "Maintenance.workOrderReport.TableHeader.hourlyRate",
                        "Hourly rate"
                    )}
                    getLeftColumnValue={(row) => row.hours}
                    getLeftColumnDisplayValue={(row) =>
                        row.hoursAsText === null ? row.hours : row.hoursAsText
                    }
                    getRightColumnValue={(row) =>
                        row.hourlyRate ? row.hourlyRate : task.hourlyRate
                    }
                    getLeftColumnTotalValue={() => getTaskLaborTotalHours(task)}
                    noItemsText={getResource(
                        "Maintenance.workOrderReport.noLaborPlaceholder",
                        "No labor registered"
                    )}
                />

                <TaskTable
                    data={materials}
                    currencyShortName={currencyShortName}
                    leftColumnHeader={getResource(
                        "Maintenance.workOrderReport.TableHeader.quantity",
                        "Quantity"
                    )}
                    rightColumnHeader={getResource(
                        "Maintenance.workOrderReport.TableHeader.unitPrice",
                        "Unit price"
                    )}
                    getLeftColumnValue={(row) => row.quantity}
                    getLeftColumnDisplayValue={(row) => row.quantity}
                    getRightColumnValue={(row) => row.pricePerUnit}
                    noItemsText={getResource(
                        "Maintenance.workOrderReport.noMaterialsPlaceholder",
                        "No parts and materials used"
                    )}
                />

                <TaskFooter data={task} />
            </AccordionDetails>
        </Accordion>
    );
});

export default TaskPanel;
