import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

import withResources from "./textresources/withresources";
import ContentHolder from "./contentHolder";

const useStyles = makeStyles((theme) => ({
    notFoundMessage: {
        position: "absolute",
        width: 450,
        maxWidth: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        "& .MuiAlert-standardError": {
            border: "2px solid #e53935",
            borderRadius: theme.spacing(1),
        },
    },
}));

export default withResources(({ getResource, element }) => {
    const classes = useStyles();

    return (
        <ContentHolder>
            <div className={classes.notFoundMessage}>
                <Alert severity="error">
                    <AlertTitle>
                        {getResource("Shell.notFound.title", "We can't find this {element}", {
                            element,
                        })}
                    </AlertTitle>
                    {getResource(
                        "Shell.notFound.text",
                        "We have searched everywhere, but this {element} is nowhere to be found. Are you sure you are looking for this {element}?",
                        { element }
                    )}
                </Alert>
            </div>
        </ContentHolder>
    );
});
