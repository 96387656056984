import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getResources(assetId) {
    const params = {
        assetId,
    };

    const url = `/api/planning/timeline/resources?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getEvents(assetId, start, end) {
    const params = {
        assetId,
        start,
        end,
    };

    const url = `/api/planning/timeline/events?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function resolve(id) {
    return await interceptedFetch(`/api/planning/assignedaction/${id}/resolve`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function reopen(id) {
    return await interceptedFetch(`/api/planning/assignedaction/${id}/reopen`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getResources, getEvents, resolve, reopen };
