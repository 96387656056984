// Required operation can be an object, string or an (async) function used to resolve the key.
async function createOperation(operation) {
    if (!(operation instanceof Function)) {
        // The operation is not a factory.
        return operation;
    }

    if (operation.constructor.name === "AsyncFunction") {
        // Async factory.
        return await operation();
    }

    // Factory.
    return operation();
}

// Checks if any of the promises returns true
async function any(promises) {
    for (const fn of promises) {
        if (await fn) {
            return true;
        }
    }

    return false;
}

async function isAuthorized(authorizationService, operationOrFactory) {
    const operation = await createOperation(operationOrFactory);

    return authorizationService.isAuthorized(operation);
}

// Takes a list of operations or factories that create the operations and checks if
// the current user is authorized to perform any of these.
async function isAuthorizedAny(authorizationService, operationsOrFactories) {
    if (!operationsOrFactories || operationsOrFactories.length === 0) {
        return true;
    }

    let promises = operationsOrFactories.map(
        async (x) => await isAuthorized(authorizationService, x)
    );

    return await any(promises);
}

export default isAuthorizedAny;
