import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { getAttributeValue } from "../asset/attributeHelper";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Summary from "../../../shared/summary";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";
import { Lock as SealedIcon } from "@material-ui/icons";
import QrCode from "../../../shared/qrCode"

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(1),
    },
}));

export default ({ asset, attributeDefinitions, nrOfRowsAlwaysVisible }) => {
    const classes = useStyles();
    const [summaryRows, setSummaryRows] = useState([]);
    const { selectedCulture, selectedUiCulture, defaultCulture, selectedTimezone } = useContext(
        LocalizationContext
    );

    useEffect(() => {
        if (!asset) {
            setSummaryRows([]);
            return;
        }

        let newSummaryRows = [];

        function addToSummaryRows(resourceKey, defaultResourceValue, value) {
            if (!value) {
                return;
            }

            newSummaryRows.push({
                title: (
                    <ResourcedText resourceKey={resourceKey} defaultValue={defaultResourceValue} />
                ),
                data: value,
            });
        }

        addToSummaryRows("Asset.detail.companyField", "Company", asset.companyName);
        addToSummaryRows("Asset.detail.manufacturerField", "Manufacturer", asset.manufacturerName);
        addToSummaryRows("Asset.detail.modelField", "Model", asset.assetModel.name);
        addToSummaryRows(
            "Asset.detail.constructionDateField",
            "Construction date",
            asset.constructionDate &&
            formatDate(asset.constructionDate, selectedUiCulture, selectedTimezone, true)
        );
        addToSummaryRows("Asset.detail.internalIdField", "Internal Id", asset.internalId);
        addToSummaryRows("Asset.detail.serialField", "Serial", asset.serial);
        addToSummaryRows("Asset.detail.idField", "Asset id", asset.id);
        addToSummaryRows("Asset.detail.registrationField", "Registration", asset.registration);

        if (asset.emissionImpact) {
            addToSummaryRows(
                "Asset.detail.assetEmissionImpactField",
                "Asset emission impact",
                asset.emissionImpact
            );
        } else if (asset.assetModelEmissionImpact) {
            addToSummaryRows(
                "Asset.detail.assetModelEmissionImpactField",
                "Model emission impact",
                asset.assetModelEmissionImpact
            );
        }

        addToSummaryRows("Asset.detail.locationField", "Location", asset.locationCountryName);

        if (asset.positionUrl) {
            addToSummaryRows(
                "Asset.detail.positionUrlField",
                "Position",
                <a href={asset.positionUrl} target="_blank" rel="noopener noreferrer">
                    <ResourcedText
                        resourceKey={"Asset.detail.positionUrlValue"}
                        defaultValue={"Open external link"}
                    />
                </a>
            );
        }

        addToSummaryRows(
            "Asset.detail.collectionsField",
            "Collections",
            asset.assetCollections && (
                <ul>
                    {asset.assetCollections.map((assetCollection) => (
                        <li key={assetCollection.name}>{assetCollection.name}</li>
                    ))}
                </ul>
            )
        );

        if (asset.firstQrCode) {
            const qrUrl = window.location.origin + '/qr/' + asset.firstQrCode;

            addToSummaryRows(
                "Asset.detail.firstQrCode",
                "QR Code",
                <QrCode qrCodeValue={qrUrl} />);
        }

        function getUiFriendlyValue(attributeDefinition, value) {
            switch (attributeDefinition.assetAttributeTypeId) {
                case 5: //Boolean
                    return value ? "Yes" : "No";
                case 6: //Option list
                    return attributeDefinition.options.find((option) => option.id === value).value;
                case 7: //Date
                    return formatDate(value, selectedUiCulture, selectedTimezone);
                case 8: //Time
                    return new Date(value).toLocaleTimeString(selectedUiCulture);
                case 9: //Date Time
                    return formatDate(value, selectedUiCulture, selectedTimezone, false, true);
                case 10: //Link
                    return <a href={value} target="_blank" rel="noopener noreferrer">
                        <ResourcedText
                            resourceKey={"Asset.detail.openExternalLinkText"}
                            defaultValue={"Open external link"}
                        />
                    </a>
                default:
                    return value;
            }
        }

        if (attributeDefinitions) {
            attributeDefinitions.forEach((definition) => {
                const value = getAttributeValue(definition, asset.attributes);
                if (!value) return;

                newSummaryRows.push({
                    title: definition.label,
                    data: getUiFriendlyValue(definition, value),
                });
            });
        }

        if (asset.specifications) {
            asset.specifications.forEach((specification) => {
                newSummaryRows.push({
                    title:
                        specification.name[selectedCulture] || specification.name[defaultCulture],
                    data:
                        specification.value[selectedCulture] || specification.value[defaultCulture],
                });
            });
        }

        setSummaryRows(newSummaryRows);
    }, [
        asset,
        setSummaryRows,
        attributeDefinitions,
        classes.chip,
        defaultCulture,
        selectedCulture,
        selectedUiCulture,
        selectedTimezone,
    ]);

    return (
        <Summary
            title={
                <ResourcedText resourceKey="Asset.detail.header" defaultValue="Asset information" />
            }
            description={
                asset.description &&
                (asset.description[selectedCulture] || asset.description[defaultCulture])
            }
            rows={summaryRows}
            nrOfRowsAlwaysVisible={nrOfRowsAlwaysVisible}
            icon={asset.isSealed ? <SealedIcon /> : null}
        />
    );
};
