import React, { Component } from "react";
import ValuationService from "../helpers/valuationService.js";
import ValuationDataBuilder from "../helpers/valuationDataBuilder.js";
import DataValidator from "../helpers/datavalidator.js";
import AuthenticationContext from "../../../../../shared/authentication/authenticationcontext";
import withResources from "../../../../../shared/textresources/withresources";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";

import {
    CloudUpload as PublishIcon,
    CloudDone as ReleaseIcon,
    Save as SaveDraftIcon,
    Print as PrintIcon,
    Publish as SaveTrendIcon,
    GetApp as LoadTrendIcon,
    Help as QuestionnaireIcon,
    InsertChart as ChartIcon,
    Description as DocumentIcon,
} from "@material-ui/icons";
import { ButtonGroup, Button } from "@material-ui/core";

let VS = null;
const VDB = new ValuationDataBuilder();
const DV = new DataValidator();

class ActionButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            isPopulated: false,
            publishFunctionalityDisabled: true,
            trendFunctionalityDisabled: true,
            releaseFunctionalityDisabled: false,
            backUrl: undefined,
            loadTrendUrl: undefined,
        };

        VS = new ValuationService();
    }

    showError(error) {
        let notify = this.props.notificationRef;
        notify.showError(error.title, error.message);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.isFetching && nextProps.data && nextProps.data.currentAssetModelId) {
            let stateData = this.state;

            stateData.isFetching = true;
            stateData.backUrl = "/valuation/model-valuation/" + nextProps.data.currentAssetModelId;
            stateData.loadTrendUrl = "";

            this.setState(stateData);
        } else if (!this.state.isFetching && nextProps.data && nextProps.data.currentAssetId) {
            let stateData = this.state;

            stateData.isFetching = true;
            stateData.backUrl = "/valuation/asset-valuation/" + nextProps.data.currentAssetId;
            stateData.loadTrendUrl = "";

            this.setState(stateData);
        }
    }

    onSaveDraft() {
        let modal = this.props.messageModelRef;
        let validationResult = DV.validateDraft(
            this.props.highchartsRef.refs.highcharts.chart.series
        );
        if (validationResult.errorCount > 0) {
            modal.show(
                "tiny",
                this.props.getResource("Valuation.actionButtons.saveDraftTitle", "Save draft"),
                validationResult.message,
                () => {
                    this.onOKSaveDraft();
                },
                this.props.getResource("Valuation.saveDraft.save", "Save")
            );
        } else {
            this.onOKSaveDraft();
        }
    }

    onOKSaveDraft() {
        let notify = this.props.notificationRef;
        notify.showBusy(
            this.props.getResource("Valuation.actionButtons.savingDraftTitle", "Saving draft..."),
            this.props.getResource(
                "Valuation.actionButtons.savingDraftMessage",
                "Please wait while the server is processing your draft."
            )
        );
        let args = VDB.getValuationDataDto(
            this.props.data,
            this.props.highchartsRef.refs.highcharts.chart.series
        );
        let error = {
            title: this.props.getResource(
                "Valuation.actionButtons.saveDraftErrorTitle",
                "Save draft failed"
            ),
            message: this.props.getResource(
                "Valuation.actionButtons.saveDraftErrorMessage",
                "An error occurred, your draft has not been saved. Please try again later."
            ),
        };

        // save the draft
        VS.saveValuationDraft(
            args,
            (data) => {
                if (typeof data.newValuationId !== "undefined") {
                    this.props.data.currentObjectId = data.newValuationId;
                    notify.showSuccess(
                        this.props.getResource(
                            "Valuation.actionButtons.saveDraftSuccessTitle",
                            "Draft saved"
                        ),
                        this.props.getResource(
                            "Valuation.actionButtons.saveDraftSuccessMessage",
                            "Your draft has been saved successfully."
                        )
                    );
                } else {
                    this.showError(error);
                }
            },
            (err) => {
                this.showError(error);
            }
        );
    }

    onPublish() {
        let modal = this.props.messageModelRef;
        let notify = this.props.notificationRef;
        let error = {
            title: this.props.getResource(
                "Valuation.actionButtons.publishErrorTitle",
                "Failed to publish"
            ),
            message: this.props.getResource(
                "Valuation.actionButtons.publishErrorMessage",
                "An error occurred, your valuation has not been published. Please try again later."
            ),
        };

        modal.show(
            "tiny",
            this.props.getResource("Valuation.actionButtons.publishConfirmationTitle", "Publish"),
            this.props.getResource(
                "Valuation.actionButtons.publishConfirmationMessage",
                "Are you sure you want to publish this valuation?"
            ),
            () => {
                notify.showBusy(
                    this.props.getResource(
                        "Valuation.actionButtons.publishingTitle",
                        "Publishing..."
                    ),
                    this.props.getResource(
                        "Valuation.actionButtons.publishingMessage",
                        "Please wait while the server is processing your valuation."
                    )
                );
                let args = VDB.getValuationDataDto(
                    this.props.data,
                    this.props.highchartsRef.refs.highcharts.chart.series
                );

                VS.publishValuation(
                    args,
                    (data) => {
                        if (data.failureReason) {
                            notify.handleDismiss();
                            modal.showWarning("tiny", error.title, data.failureReason, () => null);
                        } else if (typeof data.newValuationId !== "undefined") {
                            if (typeof this.state.backUrl !== undefined) {
                                window.location = this.state.backUrl;
                            }
                        } else {
                            this.showError(error);
                        }
                    },
                    (err) => {
                        this.showError({
                            ...error,
                            message: err.message,
                        });
                    }
                );
            },
            this.props.getResource("Valuation.publishValuation.publish", "Publish")
        );
    }

    onRelease() {
        let modal = this.props.messageModelRef;
        let notify = this.props.notificationRef;
        let error = {
            title: this.props.getResource(
                "Valuation.actionButtons.releaseErrorTitle",
                "Failed to release"
            ),
            message: this.props.getResource(
                "Valuation.actionButtons.releaseErrorMessage",
                "An error occurred, your valuation has not been released. Please try again later."
            ),
        };

        modal.show(
            "tiny",
            this.props.getResource("Valuation.actionButtons.releaseConfirmationTitle", "Release"),
            this.props.getResource(
                "Valuation.actionButtons.releaseConfirmationMessage",
                "Are you sure you want to release this valuation?"
            ),
            () => {
                notify.showBusy(
                    this.props.getResource(
                        "Valuation.actionButtons.releasingTitle",
                        "Releasing..."
                    ),
                    this.props.getResource(
                        "Valuation.actionButtons.releasingMessage",
                        "Please wait while the server is processing your valuation."
                    )
                );
                let args = VDB.getValuationDataDto(
                    this.props.data,
                    this.props.highchartsRef.refs.highcharts.chart.series
                );

                VS.releaseValuation(
                    args,
                    (data) => {
                        if (typeof data.newValuationId !== "undefined") {
                            if (typeof this.state.backUrl !== undefined) {
                                window.location = this.state.backUrl;
                            }
                        } else {
                            this.showError(error);
                        }
                    },
                    (err) => {
                        this.showError(error);
                    }
                );
            },
            this.props.getResource("Valuation.release.release", "Release")
        );
    }

    onPrint() {
        let modal = this.props.messageModelRef;
        modal.showCustomButtons(
            "tiny",
            this.props.getResource("Valuation.actionButtons.printConfirmationTitle", "Print"),
            this.props.getResource(
                "Valuation.actionButtons.printConfirmationMessage",
                "Do you want to print the whole document or only the chart?"
            ),
            [
                {
                    icon: <DocumentIcon />,
                    content: this.props.getResource(
                        "Valuation.actionButtons.printConfirmationDocumentButton",
                        "Document"
                    ),
                },
                {
                    icon: <ChartIcon />,
                    content: this.props.getResource(
                        "Valuation.actionButtons.printConfirmationChartButton",
                        "Chart"
                    ),
                },
            ],
            () => {
                setTimeout(function () {
                    window.print();
                }, 500); // timeout in order to be sure the modal has dissapeared
            },
            () => {
                let chart = this.props.highchartsRef.refs.highcharts.getChart();
                setTimeout(function () {
                    chart.print();
                }, 500); // timeout in order to be sure the modal has dissapeared
            }
        );
    }

    onSaveAsTrend() {
        let modal = this.props.messageModelRef;
        let notify = this.props.notificationRef;
        let error = {
            title: this.props.getResource(
                "Valuation.actionButtons.saveAsTrendConfirmationTitle",
                "Save as trend failed"
            ),
            message: this.props.getResource(
                "Valuation.actionButtons.saveAsTrendConfirmationMessage",
                "An error occurred, your trend has not been saved. Please try again later."
            ),
        };

        modal.show(
            "tiny",
            this.props.getResource("Valuation.actionButtons.saveAsTrendTitle", "Save as trend"),
            this.props.getResource(
                "Valuation.actionButtons.saveAsTrendMessage",
                "Are you sure you want to remove the previous template and make this valuation the new template?"
            ),
            () => {
                notify.showBusy(
                    this.props.getResource(
                        "Valuation.actionButtons.savingAsTrendTitle",
                        "Saving trend..."
                    ),
                    this.props.getResource(
                        "Valuation.actionButtons.savingAsTrendMessage",
                        "Please wait while the server is processing the data."
                    )
                );
                let args = VDB.getValuationDataDto(
                    this.props.data,
                    this.props.highchartsRef.refs.highcharts.chart.series
                );

                VS.saveTrend(
                    args,
                    (data) => {
                        if (typeof data === "string") {
                            notify.showSuccess(
                                this.props.getResource(
                                    "Valuation.actionButtons.saveAsTrendSuccessTitle",
                                    "Saved as trend"
                                ),
                                this.props.getResource(
                                    "Valuation.actionButtons.saveAsTrendSuccessMessage",
                                    "Your valuation has been saved as a trend."
                                )
                            );
                        } else {
                            this.showError(error);
                        }
                    },
                    (err) => {
                        this.showError(error);
                    }
                );
            },
            this.props.getResource("Valuation.saveAsTrend.save", "Save")
        );
    }

    onLoadTrend() {
        let modal = this.props.chooseCategoryModalRef;
        let notify = this.props.notificationRef;

        modal.show(
            "tiny",
            this.props.getResource("Valuation.actionButtons.loadTrendTitle", "Load trend"),
            this.props.getResource(
                "Valuation.actionButtons.loadTrendMessage",
                "Are you sure you want to discard this valuation and start valuating from the template?"
            ),
            (assetCategory) => {
                notify.showBusy(
                    this.props.getResource(
                        "Valuation.actionButtons.loadingTrendTitle",
                        "Loading trend..."
                    ),
                    this.props.getResource(
                        "Valuation.actionButtons.loadingTrendMessage",
                        "Please wait while the server is loading the trend."
                    )
                );

                VS.loadTrend(
                    this.props.data.currentObjectId,
                    assetCategory.id,
                    (data) => {
                        if (data.success) {
                            this.props.reloadValuation();
                        } else {
                            this.showError({
                                title: this.props.getResource(
                                    "Valuation.actionButtons.loadTrendErrorTitle",
                                    "Load trend failed"
                                ),
                                message: data.error,
                            });
                        }
                    },
                    (err) => {
                        this.showError(err);
                    }
                );
            }
        );
    }

    onOpenQuestionnaire() {
        this.props.openQuestionnaire();
    }

    render() {
        return (
            <AuthenticationContext.Consumer>
                {({ authorizationService }) => (
                    <ButtonGroup
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ marginBottom: 10 }}
                    >
                        <Button
                            onClick={this.onPublish.bind(this)}
                            disabled={
                                !authorizationService.isAuthorized("model-valuations-publish")
                            }
                            startIcon={<PublishIcon />}
                        >
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.publishButton"
                                defaultValue="Publish"
                            />
                        </Button>
                        <Button
                            onClick={this.onRelease.bind(this)}
                            disabled={
                                !authorizationService.isAuthorized("model-valuations-release")
                            }
                            startIcon={<ReleaseIcon />}
                        >
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.releaseButton"
                                defaultValue="Release"
                            />
                        </Button>
                        <Button onClick={this.onSaveDraft.bind(this)} startIcon={<SaveDraftIcon />}>
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.saveDraftButton"
                                defaultValue="Save draft"
                            />
                        </Button>
                        <Button onClick={this.onPrint.bind(this)} startIcon={<PrintIcon />}>
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.printButton"
                                defaultValue="Print"
                            />
                        </Button>
                        <Button
                            onClick={this.onSaveAsTrend.bind(this)}
                            disabled={!authorizationService.isAuthorized("trends-create")}
                            startIcon={<SaveTrendIcon />}
                        >
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.saveAsTrendButton"
                                defaultValue="Save as trend"
                            />
                        </Button>
                        <Button
                            onClick={this.onLoadTrend.bind(this)}
                            disabled={!authorizationService.isAuthorized("model-valuations-create")}
                            startIcon={<LoadTrendIcon />}
                        >
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.loadTrendButton"
                                defaultValue="Load trend"
                            />
                        </Button>
                        <Button
                            onClick={this.onOpenQuestionnaire.bind(this)}
                            disabled={
                                !authorizationService.isAuthorized("model-valuations-publish")
                            }
                            startIcon={<QuestionnaireIcon />}
                        >
                            <ResourcedText
                                resourceKey="Valuation.actionButtons.questionnaireButton"
                                defaultValue="Questionnaire"
                            />
                        </Button>
                    </ButtonGroup>
                )}
            </AuthenticationContext.Consumer>
        );
    }
}

export default withResources(ActionButtons);
