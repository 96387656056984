import React, { useState, useContext } from "react";
import { LinearProgress } from "@material-ui/core";
import { addHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import useAPI from "../../../shared/useapi";
import { create } from "./dataservice";
import { getAll as getAllAdvertisements } from "../advertisements/dataservice";
import useCurrencies from "./../../library/currencies/usecurrencies";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./components/editform";
import DuplicateWarningDialog from "../advertisements/components/duplicateWarningDialog";

export default withResources(({ getResource, asset, open, onClose }) => {
    if (!open) {
        return null;
    }
    const { defaultCulture, selectedTimezone } = useContext(LocalizationContext);

    const { currencies } = useCurrencies();

    const [duplicateWarningVisible, showDuplicateWarning] = useState(false);

    const { loading, invoke } = useAPI((values) => create(values));

    const onCreate = async (values) => {
        const saveValues = {
            ...values.values,
            assetId: values.values.asset.id,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onSubmit = async (values) => {
        const response = await getAllAdvertisements(
            null,
            values.values.type,
            null,
            values.values.asset.id
        );

        const advertisementsForAsset = await response.json();

        if (advertisementsForAsset.total > 0) {
            showDuplicateWarning(true);
        } else {
            await onCreate(values);
        }
    };

    const [isDirty, setIsDirty] = useState(false);

    const assetTitle =
        asset &&
        ((asset.name && asset.name[defaultCulture]) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);

    return (
        <FormDrawer
            title={getResource("Marketplace.auctions.add.title", "Create auction")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!currencies ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        type: "auction",
                        asset: {
                            id: asset && asset.id,
                            name: asset && asset.name,
                        },
                        auctionStart: zonedTimeToUtc(addHours(new Date(), 1), selectedTimezone),
                        auctionStop: zonedTimeToUtc(addHours(new Date(), 2), selectedTimezone),
                        concealedBids: true,
                        currencyId: currencies.find((x) => x.currencyCode === "EUR").id,
                        buyoutType: "Temporary",
                        minimumBidIncrement: 1,
                        finalBidPeriod: 1,
                        bidPeriodExtension: 5,
                        title: { [defaultCulture]: assetTitle },
                        description: {},
                        additionalInformation: [],
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Marketplace.auctions.add.createButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={(values, cultures, defaultCulture) =>
                        validate(values, defaultCulture, getResource)
                    }
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => (
                        <React.Fragment>
                            <EditForm
                                showAssetSelector={!asset}
                                culture={culture}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                updateValues={updateValues}
                                isDefaultCulture={isDefaultCulture}
                            />

                            <DuplicateWarningDialog
                                open={duplicateWarningVisible}
                                onClose={() => showDuplicateWarning(false)}
                                onAction={() => onCreate({ values: values })}
                                type={values.type}
                                isEdit={false}
                            />
                        </React.Fragment>
                    )}
                />
            )}
        </FormDrawer>
    );
});
