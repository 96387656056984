import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import PagedListInput from "./index";
import ConfigurationContext from "../configuration/configurationcontext";
import useAPI from "../useapi";
import { getAll, getStatuses } from "../../features/assets/component/dataservice";
import Search from "../../features/assets/component/search";
import _ from "underscore";
import withResources from "../textresources/withresources";
import PagedListContent from "../../features/assets/component/pagedListContent";

const PagedListInputForComponents = withResources((props) => {
    const { data: allStatuses } = useAPI(() => getStatuses(), true);

    const { getResource, companyId, hiddenStatuses, assetId, hideStatusFilters, ignore } = props;
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        statuses: [],
        showOnlyInstalled: Boolean(assetId),
        order: "Default"
    });

    const { loading, invoke, data: filteredComponents } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.statuses,
                searchParams.showOnlyInstalled ? assetId : null,
                searchParams.page + 1,
                searchParams.pageSize,
                companyId,
                hiddenStatuses,
                ignore,
                searchParams.order
            ),
        false,
        [searchParams, companyId]
    );

    const data = filteredComponents || {};
    const items = data.items;
    const allowedStatuses = useRef([]);

    const onSearch = (searchText, statuses, showOnlyInstalled, order) => {
        setSearchParams({ ...searchParams, searchText, statuses, order, page: 0, showOnlyInstalled });
    };

    const onPageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const onPageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    useEffect(() => {
        if (allStatuses) {
            if (hiddenStatuses) {
                allowedStatuses.current = allStatuses.filter((status) =>
                    hiddenStatuses.every((hiddenStatus) => status.id !== hiddenStatus)
                );
            } else {
                allowedStatuses.current = allStatuses;
            }
        }
    }, [allStatuses, hiddenStatuses]);

    useEffect(() => {
        setSearchParams({ ...searchParams, showOnlyInstalled: Boolean(assetId) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);

    return (
        <PagedListInput
            itemCount={data.total}
            loading={loading}
            noResultsText={getResource(
                "Asset.components.overview.noResults",
                "No components available"
            )}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            invoke={invoke}
            availablePageSizes={availablePageSizes}
            page={searchParams.page}
            pageSize={searchParams.pageSize}
            items={items}
            PagedListContent={PagedListContent}
            renderSearch={() => (
                <Search
                    onSearch={onSearchDebounced}
                    searchParams={searchParams}
                    allStatuses={allowedStatuses.current}
                    showInstalledOrAllSwitch={Boolean(assetId)}
                    hideStatusFilters={hideStatusFilters}
                />
            )}
            {...props}
        />
    );
});

PagedListInputForComponents.propTypes = {
    getResource: PropTypes.string,
    companyId: PropTypes.string,
    hiddenStatuses: PropTypes.bool,
    assetId: PropTypes.string,
};

export default PagedListInputForComponents;
