import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete as RevokeIcon } from "@material-ui/icons";
import _ from "underscore";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onRevoke,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="user-roles-assign" />
                <ContentHolder>
                    <Header
                        title={getResource("Security.permissions.header.title", "Permissions")}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation={getRequiredOperation("user-roles-assign")}
                                    buttonText={getResource(
                                        "Security.permissions.overview.newButton",
                                        "Add"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Security.permissions.noResults",
                            "No permissions have been granted"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.permissions.overview.userHeader"
                                            defaultValue="Name"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.permissions.overview.emailHeader"
                                            defaultValue="E-mail"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.permissions.overview.roleHeader"
                                            defaultValue="Role"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.permissions.overview.scopeHeader"
                                            defaultValue="Scope"
                                        />
                                    </TableCell>
                                    {companyScope.id === null && (
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Security.permissions.overview.companyHeader"
                                                defaultValue="Company"
                                            />
                                        </TableCell>
                                    )}
                                    {assetCollectionScope.id === null && (
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Security.permissions.overview.assetCollectionHeader"
                                                defaultValue="Asset collection"
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Security.permissions.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow id={row.id} hover key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.userName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.roleName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {getResource(
                                                    `Security.roles.scope.${row.scope}`,
                                                    row.scope
                                                )}
                                            </TableCell>
                                            {companyScope.id === null && (
                                                <TableCell component="th" scope="row">
                                                    {row.companyName}
                                                </TableCell>
                                            )}
                                            {assetCollectionScope.id === null && (
                                                <TableCell component="th" scope="row">
                                                    {row.assetCollectionName}
                                                </TableCell>
                                            )}
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Security.permissions.overview.revokeButton",
                                                                "Revoke"
                                                            ),
                                                            icon: <RevokeIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "user-roles-assign",
                                                            onClick: () => onRevoke(row),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
