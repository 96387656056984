import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    FindInPage as QuickScanIcon,
    Euro as FinancialDetailsIcon,
    InsertDriveFile as DocumentsIcon,
    PermMedia as MediaIcon,
    AssignmentInd as ShowOwnershipIcon,
    OpenWith as MovementsIcon,
    EmojiTransportation as MoveCompanyIcon,
    SettingsApplications as ComponentIcon,
    AddBox as InstallComponentIcon,
    LinearScale as UsageIcon,
    FiberManualRecord as RecordIcon,
    QuestionAnswer as EditQuestionnaireIcon,
    Info as FactsIcon,
    ShoppingCart as AdvertisementIcon,
    Build as WorkOrdersIcon,
    Event as CalendarIcon,
    DateRange as TimelineIcon,
    FileCopy as DuplicateIcon,
    List as EventsIcon,
    Lock as SealIcon,
    LockOpen as UnsealIcon,
} from "@material-ui/icons";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import InvalidationContext from "../../../shared/invalidation/invalidationcontext";
import withResources from "../../../shared/textresources/withresources";
import ActionMenu from "../../../shared/actionmenu";
import Header from "../../../shared/header";

import Ownership from "../assetOwnership";
import FinancialDetails from "../assetFinancialDetail/edit";
import Edit from "../asset/edit";
import Duplicate from "../asset/duplicate";
import Delete from "../asset/delete";
import MoveCompany from "../asset/moveCompany";
import Movements from "../asset/movements";
import Install from "../assetComponents/install";
import useDialog from "../../../shared/usedialog";
import Record from "../assetUsage/record";
import EditQuestionnaire from "../../valuation/assetquestionnaire/edit";
import Seal from "../asset/seal";
import Unseal from "../asset/unseal";
import { config as factConfig } from "../fact/config";

const AssetHeader = withResources(({ getResource, asset, onRefresh }) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const history = useHistory();

    const { invalidate } = useContext(InvalidationContext);

    const onDeleted = () => {
        invalidate("asset-list");
        history.push(`/assets/assets`);
    };

    const onMoved = () => {
        invalidate("asset-list");
        onRefresh();
    };

    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(onRefresh);
    const {
        open: handleDuplicate,
        close: handleCloseDuplicate,
        visible: duplicateToggled,
    } = useDialog(onRefresh);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(onDeleted);
    const {
        open: handleSeal,
        close: handleCloseSeal,
        visible: sealToggled,
        args: sealId,
    } = useDialog(onRefresh);
    const {
        open: handleUnseal,
        close: handleCloseUnseal,
        visible: unsealToggled,
        args: unsealId,
    } = useDialog(onRefresh);
    const {
        open: handleFinancialDetails,
        close: handleCloseFinancialDetails,
        visible: financialDetailsToggled,
        args: financialDetailsId,
    } = useDialog(onRefresh);
    const {
        open: handleOwnership,
        close: handleCloseOwnership,
        visible: ownershipToggled,
        args: ownershipId,
    } = useDialog(onRefresh);
    const {
        open: handleMoveCompany,
        close: handleCloseMoveCompany,
        visible: moveCompanyToggled,
        args: moveCompanyId,
    } = useDialog(onMoved);
    const {
        open: handleMovements,
        close: handleCloseMovements,
        visible: movementsToggled,
        args: movementsId,
    } = useDialog();
    const {
        open: handleInstallComponent,
        close: handleCloseInstallComponent,
        visible: installComponentToggled,
    } = useDialog();
    const {
        open: handleRecordUsage,
        close: handleCloseRecordUsage,
        visible: recordUsageToggled,
    } = useDialog();
    const {
        open: handleEditQuestionnaire,
        close: handleCloseEditQuestionnaire,
        visible: editQuestionnaireToggled,
    } = useDialog();

    var assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);

    return (
        <React.Fragment>
            <Header
                title={assetName}
                breadcrumb={asset.assetCategories}
                renderActions={() => {
                    return (
                        <ActionMenu
                            actions={[
                                {
                                    text: getResource("Asset.detail.editAssetButton", "Edit"),
                                    showOnlyWhen: !asset.isSealed,
                                    icon: <EditIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-update",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleEdit(asset.id),
                                },
                                {
                                    text: getResource("Asset.detail.deleteAssetButton", "Delete"),
                                    icon: <DeleteIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-delete",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleDelete(asset.id),
                                },
                                {
                                    text: getResource("Asset.assets.detail.seal", "Seal"),
                                    showOnlyWhen: !asset.isSealed,
                                    icon: <SealIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-seal",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleSeal(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.assets.detail.unseal",
                                        "Unseal"
                                    ),
                                    showOnlyWhen: asset.isSealed,
                                    icon: <UnsealIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-seal",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleUnseal(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.viewAssetQuickScansButton",
                                        "View asset quick scans"
                                    ),
                                    icon: <QuickScanIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "asset-quick-scans-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => history.push(`/quick-scan/asset/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.financialDetailsButton",
                                        "Financial details"
                                    ),
                                    icon: <FinancialDetailsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-update-financial",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleFinancialDetails(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.viewOwnershipButton",
                                        "View ownership"
                                    ),
                                    icon: <ShowOwnershipIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-view-ownership",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleOwnership(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.documentsGenericButton",
                                        "Documents"
                                    ),
                                    icon: <DocumentsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "asset-documents-download-generic",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(
                                            `/documents/documents/asset-documents-generic?referenceId=${asset.id}`
                                        ),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.documentsFinancialButton",
                                        "Financial documents"
                                    ),
                                    icon: <DocumentsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "asset-documents-download-financial",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(
                                            `/documents/documents/asset-documents-financial?referenceId=${asset.id}`
                                        ),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.documentsOwnershipButton",
                                        "Ownership Documents"
                                    ),
                                    icon: <DocumentsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "asset-documents-download-ownership",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(
                                            `/documents/documents/asset-documents-ownership?referenceId=${asset.id}`
                                        ),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.mediaGenericButton",
                                        "Media library"
                                    ),
                                    icon: <MediaIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "asset-media-view-generic",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(`/assets/asset-media/${asset.id}/generic`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.moveCompanyButton",
                                        "Move to company"
                                    ),
                                    icon: <MoveCompanyIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-remove-company",
                                        companyId: asset.companyId,
                                    },
                                    onClick: () => handleMoveCompany(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.viewMovementsButton",
                                        "View movements"
                                    ),
                                    icon: <MovementsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-view-movements",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleMovements(asset.id),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.viewComponentsButton",
                                        "View components"
                                    ),
                                    icon: <ComponentIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-view-components",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(`/assets/asset-components/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.installComponentButton",
                                        "Add component"
                                    ),
                                    icon: <InstallComponentIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "components-install",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleInstallComponent(),
                                },
                                {
                                    text: getResource("Asset.detail.usageButton", "View usage"),
                                    icon: <UsageIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-usage-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => history.push(`/assets/asset-usage/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.recordUsageButton",
                                        "Record usage"
                                    ),
                                    icon: <RecordIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-usage-record",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleRecordUsage(),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.editQuestionnaireButton",
                                        "Edit asset questionnaire"
                                    ),
                                    icon: <EditQuestionnaireIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "questionnaires-update",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleEditQuestionnaire(),
                                },
                                {
                                    text: getResource("Asset.detail.showFactsButton", "Facts"),
                                    icon: <FactsIcon />,
                                    itemId: asset.id,
                                    requiredOperationAtLeastOneOf:
                                        factConfig.operationTypes.map((type) => {
                                            return {
                                                name: `facts-view-${type}`,
                                                companyId: asset.companyId,
                                                assetCollectionId: asset.assetCollectionIds,
                                            }
                                        }),
                                    onClick: () => history.push(`/assets/asset-facts/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.advertisementsButton",
                                        "Advertisements"
                                    ),
                                    icon: <AdvertisementIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "advertisements-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(`/assets/asset-advertisement/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.detail.workOrdersButton",
                                        "Work orders"
                                    ),
                                    icon: <WorkOrdersIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "work-orders-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(`/maintenance/asset-work-orders/${asset.id}`),
                                },
                                {
                                    text: getResource("Asset.detail.calendarButton", "Calendar"),
                                    icon: <CalendarIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "scheduled-events-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () =>
                                        history.push(`/planning/calendar/Assets/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.assets.viewTimelineButton",
                                        "View timeline"
                                    ),
                                    icon: <TimelineIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "scheduled-events-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => history.push(`/planning/timeline/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.assets.viewEventsButton",
                                        "View events"
                                    ),
                                    icon: <EventsIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "scheduled-events-view",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => history.push(`/planning/events/assets/${asset.id}`),
                                },
                                {
                                    text: getResource(
                                        "Asset.assets.duplicateButton",
                                        "Duplicate"
                                    ),
                                    icon: <DuplicateIcon />,
                                    itemId: asset.id,
                                    requiredOperation: {
                                        name: "assets-duplicate",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    },
                                    onClick: () => handleDuplicate(),
                                },
                            ]}
                        />
                    );
                }}
            />
            <Edit open={editToggled} id={editId} onClose={handleCloseEdit} />
            <Duplicate open={duplicateToggled} asset={asset} onClose={handleCloseDuplicate} />
            <Delete open={deleteToggled} id={deleteId} onClose={handleCloseDelete} />
            <Seal open={sealToggled} id={sealId} onClose={handleCloseSeal} />
            <Unseal open={unsealToggled} id={unsealId} onClose={handleCloseUnseal} />
            <FinancialDetails
                open={financialDetailsToggled}
                id={financialDetailsId}
                onClose={handleCloseFinancialDetails}
            />
            <Ownership open={ownershipToggled} id={ownershipId} onClose={handleCloseOwnership} />
            <MoveCompany
                open={moveCompanyToggled}
                id={moveCompanyId}
                onClose={handleCloseMoveCompany}
            />
            <Movements open={movementsToggled} id={movementsId} onClose={handleCloseMovements} />
            <Install
                open={installComponentToggled}
                onClose={handleCloseInstallComponent}
                asset={asset}
            />
            <Record open={recordUsageToggled} onClose={handleCloseRecordUsage} asset={asset} />
            <EditQuestionnaire
                assetId={asset.id}
                assetModelId={asset.assetModel.id}
                assetModelName={asset.assetModel.name}
                open={editQuestionnaireToggled}
                onClose={handleCloseEditQuestionnaire}
            />
        </React.Fragment>
    );
});

export default AssetHeader;
