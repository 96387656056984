import React, { Fragment, useContext } from "react";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import TableWidget from "../../../shared/tableWidget";
import condionallyAddReferenceId from "./condionallyAddReferenceId";
import DocumentContext from "./documentcontext";
import useAuthorization from "../../../shared/authentication/useauthorization";
import { DescriptionOutlined as DocumentIcon } from "@material-ui/icons";
import Add from "./add";
import useDialog from "../../../shared/usedialog";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";

const DocumentWidget = withResources(
    ({
        getResource,
        referenceId,
        reference,
        library,
        title,
        getRequiredOperation,
        hideAddButton,
        hideHeaderLink,
        pageSize,
        ...rest
    }) => {
        const { data: documents, loading: documentsLoading, invoke } = useAPI(
            () => getAll(referenceId, library, null, null, false, 1, pageSize),
            true
        );
        const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);

        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);
        const data =
            documents &&
            documents.items.map((document) => ({
                id: document.id,
                name: document.name,
                date: formatDate(document.createDate, selectedUiCulture, selectedTimezone),
            }));

        return (
            <Fragment>
                <TableWidget
                    clickProperty="id"
                    headerTitle={title}
                    headerLink={
                        hideHeaderLink
                            ? null
                            : condionallyAddReferenceId(
                                  `/documents/documents/${library.toLowerCase()}`,
                                  referenceId
                              )
                    }
                    headerLinkTitle={
                        hideHeaderLink
                            ? null
                            : getResource(
                                  "Document.documents.widget.chevronAlt",
                                  `See all documents`
                              )
                    }
                    data={data}
                    loading={documentsLoading}
                    hasContent={data !== null && data.length > 0}
                    icon={<DocumentIcon />}
                    noDataText={getResource(
                        "Document.documents.widget.noDataText",
                        "There are no documents yet"
                    )}
                    addButtonText={getResource("Document.documents.widget.noDataButtonText", "Add")}
                    onAddButtonClick={hideAddButton ? null : handleAdd}
                    addButtonRequiredOperation={getRequiredOperation(library, "create", reference)}
                    rightCellTextAlign={"right"}
                    {...rest}
                />
                {!hideAddButton && (
                    <Add
                        referenceId={referenceId}
                        library={library}
                        open={addToggled}
                        onClose={handleCloseAdd}
                    />
                )}
            </Fragment>
        );
    }
);

export default ({
    reference,
    referenceId,
    library,
    title,
    hideAddButton,
    hideHeaderLink,
    pageSize = 4,
    ...rest
}) => {
    const { getRequiredOperation } = useContext(DocumentContext);

    const { isLoading, isAuthorized } = useAuthorization(
        getRequiredOperation(library, "download", reference)
    );

    if (isLoading || !isAuthorized) {
        return null;
    }

    return (
        <DocumentWidget
            referenceId={referenceId}
            reference={reference}
            library={library}
            title={title}
            getRequiredOperation={getRequiredOperation}
            hideAddButton={hideAddButton}
            hideHeaderLink={hideHeaderLink}
            pageSize={pageSize}
            {...rest}
        />
    );
};
