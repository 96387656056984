import React, { useState } from "react";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import PageListSearch from "../../../shared/pagelistsearch";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.isActive);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Security.users.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource("Security.users.search.statusField", "Status")}
                    value={searchValues.isActive}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, isActive: e.target.value })
                    }
                    options={[
                        {
                            id: "true",
                            name: getResource("Security.users.search.statusActiveOption", "Active"),
                        },
                        {
                            id: "false",
                            name: getResource(
                                "Security.users.search.statusInActiveOption",
                                "Inactive"
                            ),
                        },
                    ]}
                    disableClearable
                />,
            ]}
        />
    );
});

export default Search;
