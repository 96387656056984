import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import withResources from "../../../../shared/textresources/withresources";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import AssetCellContent from "../../../../shared/assetCellContent";

const useStyles = makeStyles((theme) => ({
    accordionsWrapper: {
        margin: theme.spacing(2) + "px 0",
    },

    accordionDetails: {
        padding: 0,
    },
}));

export default withResources(({ getResource, linkedAssets }) => {
    const classes = useStyles();

    return (
        <div className={classes.accordionsWrapper}>
            <Accordion TransitionProps={{ timeout: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="linkedAsset-content"
                    id="linkedAsset-header"
                >
                    {getResource(
                        "Library.assetModel.merge.linkedAssets",
                        "{linkedAssetCount} linked assets",
                        { linkedAssetCount: linkedAssets.length }
                    )}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Library.assetModel.merge.linkedAssets.name"
                                            defaultValue="Name"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {linkedAssets.map((row) => (
                                    <TableRow>
                                        <TableCell component="th" scope="col">
                                                <AssetCellContent
                                                assetName={row.name}
                                                manufacturerName={row.manufacturerName}
                                                assetModelName={row.assetModelName}
                                                constructionYear={row.constructionYear}
                                                serial={row.serial}
                                                internalId={row.internalId}
                                                assetId={row.id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </div>
    );
});
