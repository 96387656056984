/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Button, IconButton, Toolbar, Hidden } from "@material-ui/core";
import { Input as InputIcon, ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import AuthenticationContext from "../../../../shared/authentication/authenticationcontext";
import { Slot, Fill } from "../../../../shared/slotmachine/index";
import withResources from "../../../../shared/textresources/withresources";
import Notifications from "./components/notifications";
import useStyles from "./useStyles";

const TopNavLink = ({ children }) => {
    return <Fill name="top-nav-link">{children}</Fill>;
};

export { TopNavLink };

const TopBar = withResources(({ getResource, onOpenNavBarMobile, className }) => {
    const history = useHistory();
    const classes = useStyles();
    const { logOut, isAuthenticated } = useContext(AuthenticationContext);

    const handleLogout = () => {
        logOut();
        history.push("/login");
    };

    return (
        <AppBar className={clsx(classes.root, className)}>
            <Toolbar>
                {isAuthenticated && (
                    <Fragment>
                        <IconButton
                            onClick={onOpenNavBarMobile}
                            className={classes.mobileMenuOpen}
                            disableRipple
                            size="small"
                        >
                            <ChevronRightIcon fontSize="small" />
                        </IconButton>

                        <RouterLink to="/">
                            <img
                                alt="World Treasury Center"
                                src="/logo.svg"
                                className={classes.logo}
                            />
                        </RouterLink>
                    </Fragment>
                )}

                {!isAuthenticated && (
                    <a href="/">
                        <img alt="World Treasury Center" src="/logo.svg" className={classes.logo} />
                    </a>
                )}

                <div className={classes.flexGrow} />

                <Hidden smDown>
                    <Slot name="top-nav-link" list />
                </Hidden>

                {isAuthenticated && (
                    <Fragment>
                        <Notifications />
                        <Button
                            className={classes.logoutButton}
                            color="inherit"
                            onClick={handleLogout}
                        >
                            <InputIcon className={classes.logoutIcon} />
                            {getResource("Shell.topBar.logOutButton", "Log out")}
                        </Button>
                    </Fragment>
                )}
            </Toolbar>
        </AppBar>
    );
});

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
