import React from "react";
import useAPI from "../../../shared/useapi";
import DocumentContext from "./documentcontext";
import DocumentLibraryRegistry from "./documentlibraryregistry";
import { getLibraries } from "./dataservice";

export default ({ children }) => {
    const { data: libraries } = useAPI(getLibraries, true);

    return (
        <DocumentContext.Provider value={new DocumentLibraryRegistry(libraries)}>
            {libraries && children}
        </DocumentContext.Provider>
    );
};
