import React, { Component } from "react";
import { Checkbox, Grid } from "@material-ui/core";
import ResourcedTextField from "../../../../../shared/textresources/resourcedtextfield";

class Settings extends Component {
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <Checkbox
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "settings/numberOfAdjustmentsEnabled"
                                    )
                                }
                                checked={Boolean(this.props.settings.numberOfAdjustmentsEnabled)}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                labelResourceKey="Valuation.settings.numberOfAdjustmentsField"
                                labelDefaultValue="Number of adjustments"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "settings/numberOfAdjustments"
                                    )
                                }
                                value={this.props.settings.numberOfAdjustments}
                                disabled={!Boolean(this.props.settings.numberOfAdjustmentsEnabled)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <Checkbox
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "settings/liquidationValuePercentageEnabled"
                                    )
                                }
                                checked={Boolean(
                                    this.props.settings.liquidationValuePercentageEnabled
                                )}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <ResourcedTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                labelResourceKey="Valuation.settings.liquidationValuePercentageField"
                                labelDefaultValue="Liquidation value percentage"
                                onChange={(e) =>
                                    this.props.onHandleChange(
                                        e.target,
                                        "settings/liquidationValuePercentage"
                                    )
                                }
                                value={this.props.settings.liquidationValuePercentage}
                                disabled={
                                    !Boolean(this.props.settings.liquidationValuePercentageEnabled)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Settings;
