import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    EnhancedEncryption as SecurityIcon,
    TrendingDown as CorrectionIcon,
} from "@material-ui/icons";

import _ from "underscore";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

const Overview = withResources(
    ({
        getResource,
        data,
        history,
        searchParams,
        availablePageSizes,
        loading,
        showValuationCorrection,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        onEditRoleAssignments,
        onEditValuationCorrection,
        defaultDebounceInterval,
    }) => {
        const { setCompanyScope, setAssetCollectionScope } = useContext(WorkingScopeContext);

        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="asset-collections-view" hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource("Asset.assetCollection.header.title", "Collections")}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="asset-collections-create"
                                    buttonText={getResource(
                                        "Asset.assetCollection.newAssetCollectionButton",
                                        "Create"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Asset.assetCollection.noResults",
                            "No collections available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Asset.assetCollection.overview.companyHeader"
                                            defaultValue="Company"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Asset.assetCollection.overview.nameHeader"
                                            defaultValue="Name"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Asset.assetCollection.overview.assetCountHeader"
                                            defaultValue="Asset count"
                                        />
                                    </TableCell>
                                    {showValuationCorrection && (
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Asset.assetCollection.overview.correctionHeader"
                                                defaultValue="Correction"
                                            />
                                        </TableCell>
                                    )}
                                    {showValuationCorrection && (
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Asset.assetCollection.overview.correctionNoteHeader"
                                                defaultValue="Correction note"
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Asset.assetCollection.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => {
                                                setCompanyScope({
                                                    id: row.companyId,
                                                    name: row.companyName,
                                                });
                                                setAssetCollectionScope({
                                                    id: row.id,
                                                    name: row.name,
                                                });
                                                history.push(`/assets/assets`);
                                            }}
                                            requiredOperation={{
                                                name: "assets-view",
                                                companyId: row.companyId,
                                                assetCollectionId: row.id,
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.companyName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.assetCount}
                                            </TableCell>
                                            {showValuationCorrection && (
                                                <TableCell component="th" scope="row">
                                                    {row.valuationCorrectionPercentage} %
                                                </TableCell>
                                            )}
                                            {showValuationCorrection && (
                                                <TableCell component="th" scope="row">
                                                    {row.valuationCorrectionNote}
                                                </TableCell>
                                            )}
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Asset.assetCollection.editAssetCollectionButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: {
                                                                name: "asset-collections-update",
                                                                companyId: row.companyId,
                                                                assetCollectionId: row.id,
                                                            },
                                                            onClick: () => onEdit(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Asset.assetCollection.deleteAssetCollectionButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: {
                                                                name: "asset-collections-delete",
                                                                companyId: row.companyId,
                                                                assetCollectionId: row.id,
                                                            },
                                                            onClick: () => onDelete(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Asset.assetCollection.roleAssignmentsButton",
                                                                "Role assignments"
                                                            ),
                                                            icon: <SecurityIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: {
                                                                name: "user-roles-assign",
                                                                companyId: row.companyId,
                                                                assetCollectionId: row.id,
                                                            },
                                                            onClick: () => {
                                                                setAssetCollectionScope({
                                                                    id: row.id,
                                                                    name: row.name,
                                                                });
                                                                onEditRoleAssignments(row.id);
                                                            },
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Asset.assetCollection.valuationCorrectionButton",
                                                                "Valuation correction"
                                                            ),
                                                            icon: <CorrectionIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: {
                                                                name:
                                                                    "valuation-corrections-update",
                                                                companyId: row.companyId,
                                                                assetCollectionId:
                                                                    row.assetCollectionIds,
                                                            },
                                                            onClick: () =>
                                                                onEditValuationCorrection(row.id),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
