import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    widget: {
        marginBottom: theme.spacing(3),
        overflow: "hidden" /** prevent margin collapsing for the footer on smaller screens **/,
    },

    header: {
        display: "flex",
        alignItems: "center",
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        height: 56,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        textAlign: "center",

        "&, &:hover": {
            textDecoration: "none",
        },
    },

    widgetIcon: {
        color: theme.palette.black,
        marginRight: theme.spacing(1),

        "& svg": {
            verticalAlign: "middle",
        },
    },

    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 18,
    },

    viewIcon: {
        marginLeft: "auto",
    },

    content: {
        margin: theme.spacing(1.5),

        "&.--without-spacing": {
            margin: 0,
        },

        "&.--no-data": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "calc(100% - 56px)",
            padding: "50px 0",
        },
    },

    noDataText: {
        margin: "0 auto",
        padding: `0 ${theme.spacing(1.5)}px`,
        maxWidth: 250,
        textAlign: "center",
    },

    footer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: `${theme.spacing(2)}px 0`,
        padding: `0 ${theme.spacing(1.5)}px`,

        "&:empty": {
            display: "none",
        },
    },
}));

export default useStyles;
