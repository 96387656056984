import React from "react";
import Link from "./link";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import withResources from "../../../shared/textresources/withresources";
import { useHistory } from "react-router-dom";

export default withResources(
    ({
        getResource,
        id
    }) => {

        var history = useHistory();

        const handleClose = (referenceType, referenceId) => {
            history.push(`/assets/${referenceType}/${referenceId}`);
        };

        return (
            <ContentHolder>
                    <Header
                        title={getResource(
                            "Administration.qrCodes.link.header.title",
                            "Link QR Code"
                        )}
                    />
                <Link onClose={handleClose} id={id} />
            </ContentHolder> 
        );
    });
