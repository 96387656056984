import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
        "& dt": {
            marginBottom: 0,
        },

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",

            "&:not(.--summary-has-toggle):last-of-type": {
                marginBottom: 0,
            },
        },

        "& dd": {
            "& ul": {
                margin: 0,
                padding: 0,
                listStyle: "none",
            },
        },
    },

    icon: {
        marginLeft: "auto",
    },

    description: {
        letterSpacing: 0,
        marginBottom: theme.spacing(2),
    },

    descriptionToggle: {
        display: "inline-flex",
        alignItems: "center",
    },

    header: {
        display: "flex",
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },

    content: {
        padding: theme.spacing(2),
    },

    toggleButton: {
        display: "flex",
        alignItems: "center",
        fontWeight: "normal",
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        textDecoration: "underline !important",
        margin: `0 -${theme.spacing(2)}px -${theme.spacing(2)}px`,
        width: `calc(100% + ${theme.spacing(4)}px)`,
        background: theme.palette.grey[100],
        textTransform: "none",
        color: theme.palette.primary.main,
    },
}));

export default useStyles;
