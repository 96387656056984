import React, { Fragment, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import withResources from "../../../../shared/textresources/withresources";
import TaskFields, { validateTask } from "./taskFields";
import AddButton from "../../../../shared/addButton";
import useStyles from "./useStyles";
import TaskSummary from "./taskSummary";
import LabelledOutline from "../../../../shared/labelledoutline";
import FormHelperText from "@material-ui/core/FormHelperText";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import useAPI from "../../../../shared/useapi";
import { getByCompany } from "../../../security/users/dataservice";

const TasksEditor = withResources(
    ({ getResource, onTasksChanged, errors, tasks, currencyCode, companyId }) => {
        const [splitted, setSplitted] = useState(false);
        const [expandedTaskIndexes, setExpandedTaskIndexes] = useState([]);
        const classes = useStyles();
        const { data: users } = useAPI(() => getByCompany(companyId), companyId, [companyId]);

        const toggleSplit = (newValue) => {
            setSplitted(newValue);

            if (newValue) {
                const copy = [...tasks];
                copy[0].isDefaultTask = false;
                setExpandedTaskIndexes([0]);
                onTasksChanged(copy);
            } else {
                onTasksChanged([{ isDefaultTask: true, allowRateOverride: false }]);
            }
        };

        const handleAccordionToggle = (isExpanded, indexOfTask) => {
            if (isExpanded) {
                const newExpanedIndexes = [...expandedTaskIndexes].filter((i) => i !== indexOfTask);
                setExpandedTaskIndexes(newExpanedIndexes);
            } else {
                setExpandedTaskIndexes([...expandedTaskIndexes, indexOfTask]);
            }
        };

        const handleAddTask = () => {
            const newTasks = [...tasks, { allowRateOverride: false }];
            onTasksChanged(newTasks);
            /** Short timeout causes the accordion to be opened with an animation: **/
            setTimeout(() => setExpandedTaskIndexes([newTasks.length - 1]), 100);
        };

        const handleDeleteTask = (indexOfTaskToDelete) => {
            const newTasks = [...tasks].filter((task, i) => i !== indexOfTaskToDelete);
            onTasksChanged(newTasks);
        };

        const handleChange = (index, e) => {
            const copy = [...tasks];
            const value = e.target.value || e.target.checked;
            copy[index][e.target.name] = value;
            onTasksChanged(copy);
        };

        return (
            <Fragment>
                <FormControlLabel
                    label={getResource(
                        "Maintenance.workOrder.tasks.splittedField",
                        "Split into multiple tasks"
                    )}
                    variant="outlined"
                    control={
                        <Switch
                            checked={splitted}
                            onChange={(e) => toggleSplit(e.target.checked)}
                        />
                    }
                />

                <FormHelperText className={classes.taskSplittingHelperText}>
                    <ResourcedText
                        resourceKey="Maintenance.workOrder.tasks.taskSplittingHelperText"
                        defaultValue="You can split the work order into multiple tasks to allow
                    different people to work on individual tasks."
                    />
                </FormHelperText>

                <LabelledOutline
                    label={
                        splitted
                            ? getResource("Maintenance.workOrder.tasks.tasksOutlineLabel", "Tasks")
                            : getResource("Maintenance.workOrder.tasks.taskOutlineLabel", "Task")
                    }
                    className={classes.tasksOutline}
                >
                    <Fragment>
                        {tasks.map((task, i) => {
                            if (splitted) {
                                const isExpanded = expandedTaskIndexes.includes(i);
                                return (
                                    <div key={i}>
                                        <Accordion
                                            id={`taskPanel${i}`}
                                            key={i}
                                            square
                                            expanded={isExpanded}
                                            onChange={() => handleAccordionToggle(isExpanded, i)}
                                        >
                                            <AccordionSummary
                                                aria-controls={`taskPanel${i}-content`}
                                                id={`taskPanel${i}-header`}
                                                classes={{ content: classes.taskSummaryContent }}
                                            >
                                                <TaskSummary
                                                    summary={task.summary}
                                                    showDelete={tasks.length > 1}
                                                    index={i}
                                                    isExpanded={isExpanded}
                                                    handleDeleteTask={handleDeleteTask}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails id={`taskPanel${i}-content`}>
                                                <div>
                                                    <TaskFields
                                                        task={task}
                                                        errors={errors && errors[i]}
                                                        handleChange={(e) => handleChange(i, e)}
                                                        currencyCode={currencyCode}
                                                        splitted={splitted}
                                                        users={users}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                );
                            } else {
                                return (
                                    <TaskFields
                                        task={task}
                                        key={i}
                                        errors={errors && errors[i]}
                                        handleChange={(e) => handleChange(i, e)}
                                        currencyCode={currencyCode}
                                        splitted={splitted}
                                        users={users}
                                    />
                                );
                            }
                        })}

                        {splitted && (
                            <AddButton
                                handleClick={handleAddTask}
                                buttonText={getResource(
                                    "Maintenance.workOrder.tasks.addTask",
                                    "Add"
                                )}
                            />
                        )}
                    </Fragment>
                </LabelledOutline>
            </Fragment>
        );
    }
);

export default TasksEditor;

export function validateTasks(values, name, errors, getResource) {
    const tasks = values[name];

    const setError = (i, taskErrors) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        errors[name][i] = taskErrors;
    };

    tasks.forEach((task, i) => {
        const taskErrors = validateTask(task, getResource);
        if (taskErrors) {
            setError(i, taskErrors);
        }
    });
}
