import React, { useContext } from "react";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContext from "./snackbarContext";
import useStyles from "./useStyles";

export default () => {
    const { snackInfo, setSnackInfo } = useContext(SnackbarContext);
    const classes = useStyles();

    const onClose = () => {
        setSnackInfo(null);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            open={snackInfo !== null}
            autoHideDuration={6000}
            onClose={onClose}
            classes={{ root: classes.root }}
        >
            <SnackbarContent
                aria-describedby="client-snackbar"
                message={
                    snackInfo && (
                        <span dangerouslySetInnerHTML={{ __html: snackInfo.message }}></span>
                    )
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};
