import React, { useContext, useState } from "react";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.name, values.missingLanguages, values.hasValuationCorrection);
    }

    const { cultures } = useContext(LocalizationContext);

    const filterCultures =
        cultures &&
        cultures
            .filter((culture) => culture.default !== true)
            .map((culture) => ({ id: culture.code, name: culture.description }));

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Asset.assetCollection.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.name || ""}
                    onChange={(e) => handleValuesChanged({ ...searchValues, name: e.target.value })}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Asset.assetCollection.search.missingTranslationsField",
                        "Missing translation(s)"
                    )}
                    multiple
                    value={searchValues.missingLanguages}
                    options={filterCultures}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, missingLanguages: e.target.value })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Asset.assetCollection.search.correctionField",
                        "Valuation correction"
                    )}
                    value={
                        searchValues.hasValuationCorrection
                            ? searchValues.hasValuationCorrection
                            : ""
                    }
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            hasValuationCorrection: e.target.value,
                        })
                    }
                    options={[
                        {
                            id: "true",
                            name: getResource(
                                "Asset.assetCollection.search.valueCorrection.Yes",
                                "Yes"
                            ),
                        },
                        {
                            id: "false",
                            name: getResource(
                                "Asset.assetCollection.search.valueCorrection.No",
                                "No"
                            ),
                        },
                    ]}
                />,
            ]}
        />
    );
});

export default Search;
