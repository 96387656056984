import React from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourceTextField from "../../../shared/textresources/resourcedtextfield";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import Wysiwyg from "./components/wysiwyg";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, _, defaultCulture) => {
        const errors = {};

        if (!values.title[defaultCulture]) {
            errors.title = getResource(
                "Content.contentPages.edit.titleRequired",
                "Please enter a title for the default language"
            );
        }

        if (!values.urlSlug) {
            errors.urlSlug = getResource(
                "Content.contentPages.edit.urlSlugRequired",
                "Please enter a URL slug"
            );
        }

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);

    const isLoaded = data;

    return (
        <FormDrawer
            disableAutoFocus={true}
            disableEnforceFocus={true}
            disablePortal={true}
            title={getResource("Content.contentPages.edit.title", "Edit content page")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
            size={"medium"}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Content.contentPages.edit.okButtonText", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    useUILanguages={true}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        return (
                            <React.Fragment>
                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="title"
                                    labelResourceKey="Content.contentPages.edit.titleField"
                                    labelDefaultValue="Title"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.title}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Content.contentPages.edit.bodyLabel",
                                        "Body"
                                    )}
                                >
                                    <Wysiwyg
                                        value={values.body[culture] || ""}
                                        onEditorChange={(content) => {
                                            handleChange(
                                                {
                                                    target: {
                                                        name: "body",
                                                        value: content,
                                                    },
                                                },
                                                true
                                            );
                                        }}
                                    />
                                </LabelledOutline>

                                <ResourceTextField
                                    errors={errors}
                                    margin="normal"
                                    name="urlSlug"
                                    labelResourceKey="Content.contentPages.edit.urlSlugField"
                                    labelDefaultValue="URL slug"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 255 }}
                                    value={values.urlSlug}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture || data.isSystemPage}
                                    required
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Content.contentPages.edit.metadataLabel",
                                        "Metadata"
                                    )}
                                >
                                    <TranslatableTextField
                                        errors={errors}
                                        margin="normal"
                                        name="metadataTitle"
                                        labelResourceKey="Content.contentPages.edit.metadataTitleField"
                                        labelDefaultValue="Title"
                                        fullWidth
                                        inputProps={{ maxLength: 255 }}
                                        value={values.metadataTitle}
                                        culture={culture}
                                        handleChange={handleChange}
                                    />

                                    <TranslatableTextField
                                        errors={errors}
                                        margin="normal"
                                        name="metadataDescription"
                                        labelResourceKey="Content.contentPages.edit.metadataDescriptionField"
                                        labelDefaultValue="Description"
                                        multiline
                                        fullWidth
                                        value={values.metadataDescription}
                                        culture={culture}
                                        handleChange={handleChange}
                                    />

                                    <TranslatableTextField
                                        errors={errors}
                                        margin="normal"
                                        name="metadataKeywords"
                                        labelResourceKey="Content.contentPages.edit.metadataKeywordsField"
                                        labelDefaultValue="Keywords"
                                        multiline
                                        fullWidth
                                        value={values.metadataKeywords}
                                        culture={culture}
                                        handleChange={handleChange}
                                    />
                                </LabelledOutline>
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
