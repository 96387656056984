import React, { Fragment, useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, configure } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import Header from "../../../shared/header";
import LabelledOutline from "../../../shared/labelledoutline";
import LinksEditor, { validate as validateLinks } from "./components/linksEditor";
import Confirmation from "../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import TranslatableTextField from "../../../shared/translatableTextField";

const useStyles = makeStyles((theme) => ({
    configurationsPaper: {
        padding: `0 ${theme.spacing(4)}px ${theme.spacing(12)}px ${theme.spacing(4)}px`,
    },
}));

export default withResources(({ getResource }) => {
    const { data } = useAPI(() => get(), true);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const classes = useStyles();

    const { loading, invoke } = useAPI(configure);

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            setConfirmationOpen(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.copyright[defaultCulture]) {
            errors.copyright = getResource(
                "Content.advertisement.edit.copyrightRequired",
                "Please enter a copyright for the default language"
            );
        }

        validateLinks(values, "headerLinks", errors, getResource, defaultCulture, true);
        validateLinks(values, "footerLinks", errors, getResource, defaultCulture, true);
        validateLinks(values, "backOfficeLinks", errors, getResource, defaultCulture, false);

        return errors;
    };

    const isLoaded = data;

    return !isLoaded ? (
        <LinearProgress />
    ) : (
        <Paper className={classes.configurationsPaper}>
            <LocalizedForm
                initialValues={data}
                onIsDirty={() => null}
                loading={loading}
                okButtonText={getResource("Content.configuration.okButton", "Update")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                showCultureSelector
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture,
                    resetValues
                ) => {
                    useEffect(() => {
                        async function reloadConfiguration() {
                            const response = await get();

                            if (!response.ok) {
                                return;
                            }

                            const newConfiguration = await response.json();
                            resetValues(newConfiguration);
                        }

                        if (confirmationOpen) {
                            reloadConfiguration();
                        }
                        // eslint-disable-next-line
                    }, [confirmationOpen]);

                    return (
                        <Fragment>
                            <Header
                                title={getResource(
                                    "Content.configuration.header.title",
                                    "Content configuration"
                                )}
                            />

                            <LabelledOutline
                                label={getResource("Content.configuration.headerLabel", "Header")}
                            >
                                <LinksEditor
                                    name="headerLinks"
                                    errors={errors["headerLinks"]}
                                    values={values.headerLinks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={true}
                                />
                            </LabelledOutline>

                            <LabelledOutline
                                label={getResource("Content.configuration.footerLabel", "Footer")}
                            >
                                <LinksEditor
                                    name="footerLinks"
                                    errors={errors["footerLinks"]}
                                    values={values.footerLinks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={true}
                                />
                            </LabelledOutline>

                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="copyright"
                                labelResourceKey="Content.configuration.copyrightField"
                                labelDefaultValue="Copyright"
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                value={values.copyright}
                                culture={culture}
                                handleChange={handleChange}
                                required
                            />

                            <LabelledOutline
                                label={getResource(
                                    "Content.configuration.backOfficeLabel",
                                    "Back office"
                                )}
                            >
                                <LinksEditor
                                    name="backOfficeLinks"
                                    errors={errors["backOfficeLinks"]}
                                    values={values.backOfficeLinks}
                                    onChange={(e) => handleChange(e)}
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    urlRequired={true}
                                />
                            </LabelledOutline>
                        </Fragment>
                    );
                }}
            />
            <Confirmation
                open={confirmationOpen}
                cancelHidden
                handleAction={() => setConfirmationOpen(false)}
                headerText={getResource(
                    "Content.configuration.confirmation.header",
                    "Changes saved"
                )}
                buttonText={getResource("Content.configuration.confirmation.buttonText", "Ok")}
                loading={loading}
            >
                {getResource(
                    "Content.configuration.confirmation.content",
                    "All changes made to the content configuration are saved successful"
                )}
            </Confirmation>
        </Paper>
    );
});
