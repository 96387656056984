import interceptedFetch from "../../../shared/interceptedFetch";

async function get() {
    const url = `/api/marketplace/configuration`;

    return await interceptedFetch(url);
}

async function configure(values) {
    return await interceptedFetch("/api/marketplace/configuration", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export { get, configure };
