import React, { useContext } from "react";

import {
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
    Delete as DeleteIcon,
    Visibility as ViewIcon,
    Description as DescriptionIcon,
    Assessment as FinancialReportsIcon,
    Receipt as InvoiceIcon,
} from "@material-ui/icons";
import { Slot } from "../../../shared/slotmachine/index";
import Header from "../../../shared/header";
import ContentHolder from "../../../shared/contentHolder";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import ActionMenu from "../../../shared/actionmenu";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
}));

export default withResources(
    ({
        getResource,
        data,
        page,
        pageSize,
        availablePageSizes,
        loading,
        history,
        asset,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onPurchase,
        onPurchaseCustom,
        onDownloadReport,
        onDelete,
        onInvoice,
    }) => {
        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

        const classes = useStyles();

        var isLoading = !asset;

        var assetName =
            asset &&
            ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                `${asset.manufacturerName} / ${asset.assetModel.name}`);

        return isLoading ? (
            <LinearProgress />
        ) : (
            <ContentHolder>
                <Header
                    className={classes.header}
                    title={getResource(
                        "Valuation.assetQuickScanList.header.title",
                        "Quick scans for {assetName}",
                        { assetName }
                    )}
                    breadcrumb={asset.assetCategories}
                    renderActions={() => {
                        return [
                            <AuthorizedButton
                                key="purchaseButton"
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                onClick={onPurchase}
                                requiredOperation={getRequiredOperation(
                                    "asset-quick-scans-purchase"
                                )}
                                buttonText={getResource(
                                    "Valuation.assetQuickScanList.header.purchaseButton",
                                    "Purchase"
                                )}
                            />,
                            <AuthorizedButton
                                key="createCustomButton"
                                className={classes.button}
                                color="secondary"
                                variant="contained"
                                onClick={onPurchaseCustom}
                                requiredOperation={getRequiredOperation(
                                    "custom-asset-quick-scans-purchase"
                                )}
                                buttonText={getResource(
                                    "Valuation.assetQuickScanList.header.createCustomButton",
                                    "Create custom"
                                )}
                            />,
                        ];
                    }}
                />

                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <PagedList
                            loading={loading}
                            itemCount={data && data.total}
                            noResultsText={getResource(
                                "Valuation.assetQuickScanList.noResults",
                                "No asset quick scans available"
                            )}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            availablePageSizes={availablePageSizes}
                            page={page}
                            pageSize={pageSize}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Valuation.assetQuickScanList.referenceHeader"
                                                defaultValue="Reference"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Valuation.assetQuickScanList.purchasedOnHeader"
                                                defaultValue="Purchased on"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Valuation.assetQuickScanList.purchasedByHeader"
                                                defaultValue="Purchased by"
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <ResourcedText
                                                resourceKey="Valuation.assetQuickScanList.actionsHeader"
                                                defaultValue="Actions"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data &&
                                        data.items &&
                                        data.items.map((row) => (
                                            <AuthorizedTableRow
                                                id={row.id}
                                                hover
                                                key={row.id}
                                                handleClick={() => {
                                                    history.push(`/quick-scan/report/${row.id}`);
                                                }}
                                                requiredOperation={getRequiredOperation(
                                                    "asset-quick-scans-view"
                                                )}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.reference}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormattedDate
                                                        date={row.purchasedDate}
                                                        includeTime
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.purchasedBy}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ActionMenu
                                                        actions={[
                                                            {
                                                                text: getResource(
                                                                    "Valuation.assetQuickScanList.viewQuickScanButton",
                                                                    "View"
                                                                ),
                                                                icon: <ViewIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "asset-quick-scans-view"
                                                                ),
                                                                onClick: () =>
                                                                    history.push(
                                                                        `/quick-scan/report/${row.id}`
                                                                    ),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Valuation.assetQuickScanList.deleteButton",
                                                                    "Delete"
                                                                ),
                                                                icon: <DeleteIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "asset-quick-scans-delete"
                                                                ),
                                                                onClick: () =>
                                                                    onDelete({
                                                                        id: row.id,
                                                                        reference: row.reference,
                                                                    }),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Valuation.assetQuickScanList.downloadReportButton",
                                                                    "Download report"
                                                                ),
                                                                icon: <DescriptionIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "asset-quick-scans-view"
                                                                ),
                                                                onClick: () =>
                                                                    onDownloadReport(row.id),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Valuation.assetQuickScanList.financialReportsButton",
                                                                    "Financial reports"
                                                                ),
                                                                icon: <FinancialReportsIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "financial-reports-view"
                                                                ),
                                                                onClick: () =>
                                                                    history.push(
                                                                        `/valuation/financial-report/${row.id}`
                                                                    ),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Valuation.assetQuickScanList.invoiceButton",
                                                                    "Create invoice"
                                                                ),
                                                                icon: <InvoiceIcon />,
                                                                itemId: row.id,
                                                                requiredOperation: getRequiredOperation(
                                                                    "invoice-create"
                                                                ),
                                                                onClick: () => onInvoice(row.id),
                                                            },
                                                        ]}
                                                    />
                                                </TableCell>
                                            </AuthorizedTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </PagedList>
                    </Grid>
                    <Grid item xs={3} className={classes.header}>
                        <Slot name="asset-summary" list={true} asset={asset} />
                    </Grid>
                </Grid>
            </ContentHolder>
        );
    }
);
