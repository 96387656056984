import React, { useContext } from "react";
import { LinearProgress, IconButton } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import DeleteIcon from "@material-ui/icons/Delete";
import AddButton from "../../../shared/addButton";
import FormTable from "../../../shared/formTable";
import TranslatableTextField from "../../../shared/translatableTextField";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, open, id, onClose, attributeTypes }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.label[defaultCulture]) {
            errors.label = getResource(
                "Library.assetAttributeDefinitions.edit.enterLabelDefaultLanguage",
                "Please enter a label for the default language"
            );
        }

        if (!values.name) {
            errors.name = getResource(
                "Library.assetAttributeDefinitions.edit.enterName",
                "Please enter a name"
            );
        }

        if (!values.label) {
            errors.label = getResource(
                "Library.assetAttributeDefinitions.edit.enterLabel",
                "Please enter a label"
            );
        }

        if (!values.attributeTypeId) {
            errors.attributeTypeId = getResource(
                "Library.assetAttributeDefinitions.edit.enterAttributeTypeId",
                "Please select a type"
            );
        }

        values.options.forEach((option, i) => {
            if (!option.values[defaultCulture]) {
                errors.options = errors.options || {};
                errors.options[i] = {
                    values: getResource(
                        "Library.assetAttributeDefinitions.edit.enterOptionValueDefaultLanguage",
                        "Please enter a value for the default language"
                    )
                };
            }
        });

        return errors;
    };

    const isLoaded = data && attributeTypes;
    const [isDirty, setIsDirty] = React.useState(false);

    const { defaultCulture } = useContext(LocalizationContext);

    return (
        <FormDrawer
            title={getResource(
                "Library.assetAttributeDefinitions.edit.title",
                "Edit attribute definitions"
            )}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource(
                        "Library.assetAttributeDefinitions.edit.okButton",
                        "Update"
                    )}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        const selectedAttributeType = attributeTypes.find(
                            (x) => x.id === parseInt(values.attributeTypeId)
                        );
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    variant="outlined"
                                    name="name"
                                    labelResourceKey="Library.assetAttributeDefinitions.edit.nameField"
                                    labelDefaultValue="Name"
                                    type="text"
                                    fullWidth
                                    value={values.name || ""}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 255 }}
                                    onChange={(e) => handleChange(e, false)}
                                    disabled={!isDefaultCulture}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="label"
                                    labelResourceKey="Library.assetAttributeDefinitions.edit.labelField"
                                    labelDefaultValue="Label"
                                    fullWidth
                                    value={values.label}
                                    culture={culture}
                                    inputProps={{ maxLength: 255 }}
                                    handleChange={handleChange}
                                    required
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="attributeTypeId"
                                    fullWidth
                                    isMedium
                                    label={getResource(
                                        "Library.assetAttributeDefinitions.edit.attributeTypeField",
                                        "Type"
                                    )}
                                    value={values.attributeTypeId}
                                    onSelection={(e) => handleChange(e, false)}
                                    options={attributeTypes}
                                    disableClearable
                                    disabled={!isDefaultCulture}
                                    required
                                />

                                {selectedAttributeType && selectedAttributeType.hasOptions && (
                                    <LabelledOutline
                                        label={getResource(
                                            "Library.assetAttributeDefinitions.edit.optionsHeader",
                                            "Options"
                                        )}
                                    >
                                        <FormTable>
                                            {values.options.map((option, index) => (
                                                <tr key={index}>
                                                    <td>

                                                        <TranslatableTextField
                                                            fullWidth
                                                            name="values"
                                                            label={"Option " + (index + 1)}
                                                            value={option.values}
                                                            errors={errors && errors.options && errors.options[index]}
                                                            culture={culture}
                                                            handleChange={(e) => {
                                                                const copy = [...values.options];
                                                                copy[index].values[culture] = e.target.value;
                                                                handleChange(
                                                                    {
                                                                        target: {
                                                                            name: "options",
                                                                            value: copy,
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ width: 48 }}>
                                                        <IconButton
                                                            aria-label="delete"
                                                            disabled={!isDefaultCulture}
                                                            onClick={() => {
                                                                const copy = [
                                                                    ...values.options.slice(0, index),
                                                                    ...values.options.slice(index + 1),
                                                                ];
                                                                handleChange({
                                                                    target: {
                                                                        name: "options",
                                                                        value: copy,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </FormTable>

                                        <AddButton
                                            buttonText={getResource(
                                                "Library.assetAttributeDefinitions.edit.addOptionButton",
                                                "Add option"
                                            )}
                                            disabled={!isDefaultCulture}
                                            handleClick={() => {
                                                var newOption = {
                                                    values: {}
                                                };
                                                newOption.values[defaultCulture] = "";
                                                handleChange({
                                                    target: {
                                                        name: "options",
                                                        value: values.options.concat([newOption]),
                                                    },
                                                });
                                            }}
                                        />
                                    </LabelledOutline>
                                )}
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
