import React, { useContext, useState } from "react";
import {
    startOfWeek,
    endOfWeek,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
    addYears,
} from "date-fns";

import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { getAllForAssetContext as getAssetModels } from "./../../library/assetmodels/dataservice";
import { getAllForAssetContext as getManufacturers } from "./../../library/manufacturers/dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import DateRangePicker from "../../../shared/dateRangePicker";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const { dateFnsLocale } = useContext(LocalizationContext);

    const { data: allManufacturers } = useAPI(() => getManufacturers(null, null, null), true, [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    const { data: allAssetModels } = useAPI(
        () => getAssetModels(null, searchValues.manufacturerId, null, null),
        true,
        [searchValues.manufacturerId, companyScope.id, assetCollectionScope.id]
    );

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.assetModelId, values.manufacturerId, values.dateRange);
    }

    const getSearchRanges = (date) => {
        return [
            {
                label: getResource("Planning.assetAvailability.search.range.today", "Today"),
                startDate: date,
                endDate: date,
            },
            {
                label: getResource(
                    "Planning.assetAvailability.search.range.untilNextWeek",
                    "Until next week"
                ),
                startDate: date,
                endDate: endOfWeek(date, { locale: dateFnsLocale }),
            },
            {
                label: getResource(
                    "Planning.assetAvailability.search.range.untilNextMonth",
                    "Until next month"
                ),
                startDate: date,
                endDate: endOfMonth(date),
            },
            {
                label: getResource(
                    "Planning.assetAvailability.search.range.untilNextYear",
                    "Until next year"
                ),
                startDate: date,
                endDate: endOfYear(date),
            },
            {
                label: getResource("Planning.assetAvailability.search.range.nextWeek", "Next week"),
                startDate: startOfWeek(addWeeks(date, 1), { locale: dateFnsLocale }),
                endDate: endOfWeek(addWeeks(date, 1), { locale: dateFnsLocale }),
            },
            {
                label: getResource(
                    "Planning.assetAvailability.search.range.nextMonth",
                    "Next month"
                ),
                startDate: startOfMonth(addMonths(date, 1)),
                endDate: endOfMonth(addMonths(date, 1)),
            },
            {
                label: getResource("Planning.assetAvailability.search.range.nextYear", "Next year"),
                startDate: startOfYear(addYears(date, 1)),
                endDate: endOfYear(addYears(date, 1)),
            },
        ];
    };

    return (
        <PageListSearch
            searchFields={[
                <DateRangePicker
                    label={getResource(
                        "Planning.assetAvailability.search.rangeField",
                        "Date range"
                    )}
                    name="dateRange"
                    value={searchValues.dateRange}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, dateRange: e.target.value })
                    }
                    definedRanges={getSearchRanges(new Date())}
                />,
                <ResourcedTextField
                    autoFocus
                    labelResourceKey="Planning.assetAvailability.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={searchValues.searchText ? searchValues.searchText : ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Planning.assetAvailability.search.manufacturerField",
                        "Manufacturer"
                    )}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    disabled={Boolean(searchValues.assetModelId)}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, manufacturerId: e.target.value })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource("Planning.assetAvailability.search.modelLabel", "Model")}
                    value={searchValues.assetModelId}
                    options={allAssetModels && allAssetModels.items}
                    getOptionLabel={(option) => (
                        <>
                            {option.manufacturerName} - {option.name}
                        </>
                    )}
                    onSelection={(e, value) => {
                        const selectedAsset = allAssetModels.items.filter(
                            (item) => item.id === e.target.value
                        )[0];
                        const manufacturerId = selectedAsset
                            ? selectedAsset.manufacturerId
                            : searchValues.manufacturerId;

                        handleValuesChanged({
                            ...searchValues,
                            assetModelId: e.target.value,
                            manufacturerId: manufacturerId,
                        });
                    }}
                />,
            ]}
        />
    );
});

export default Search;
