import React, { useState } from "react";

import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";

import View from "./view";
import Edit from "./edit";

export default withResources(({ getResource, id, open, onClose, update }) => {
    if (!open) {
        return null;
    }

    const [isDirty, setIsDirty] = useState(false);
    const [updatedToggled, toggleUpdate] = useState(update);

    return (
        <FormDrawer
            title={getResource("Assets.asset.ownership.title", "Asset ownership")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!updatedToggled && <View id={id} onUpdate={() => toggleUpdate(true)} />}

            {updatedToggled && <Edit id={id} onDirtyChanged={setIsDirty} onClose={onClose} />}
        </FormDrawer>
    );
});
