import React, { Component } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormHelperText,
} from "@material-ui/core";
import {
    getChildren,
    getParents,
    getPath,
    filter,
} from "../../../../library/assetcategories/dataservice";
import DrillDownInput from "../../../../../shared/drilldowninput";

class ChooseCategoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetCategory: null,
            open: false,
            size: "tiny",
            positive: undefined,
            negative: undefined,
            header: "",
            content: "",
            positiveButton: {
                content: this.props.getResource("Valuation.loadTrend.load", "Load"),
            },
            negativeButton: {
                content: this.props.getResource("Valuation.loadTrend.cancel", "Cancel"),
            },
        };
    }

    show(size, header, content, positive, negative) {
        this.setState({
            open: true,
            size: size,
            header: header,
            content: content,
            positive: positive,
            negative: negative,
        });
    }

    positive() {
        this.close();
        this.state.positive(this.state.assetCategory);
    }

    negative() {
        this.close();

        if (typeof this.state.negative !== "undefined") this.state.negative();
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        const { open, size, header, assetCategory } = this.state;

        return (
            <div>
                <Dialog size={size} open={open} onClose={this.close.bind(this)}>
                    <DialogTitle>{header}</DialogTitle>
                    <DialogContent style={{ height: 600 }}>
                        <div>
                            {this.props.getResource(
                                "Valuation.loadTrend.text",
                                "You can load a trend which has previously been saved to a category. Loading a trend will overwrite the current valuation graph."
                            )}
                            <DrillDownInput
                                name="assetCategory"
                                itemName={this.props.getResource(
                                    "Valuation.loadTrend.assetCategoryItemName",
                                    "category"
                                )}
                                label={this.props.getResource(
                                    "Valuation.loadTrend.assetCategoryField",
                                    "Asset category"
                                )}
                                getParents={(id) => getParents(id, true)}
                                getChildren={(parentId) => getChildren(parentId, true)}
                                getPath={getPath}
                                filter={(name, noDescendantOf, page, pageSize) =>
                                    filter(name, noDescendantOf, page, pageSize, true)
                                }
                                value={assetCategory}
                                setValue={(e) => {
                                    this.setState({ ...this.state, assetCategory: e.target.value });
                                }}
                                fullWidth
                                isMedium
                            />
                            <FormHelperText variant="outlined">
                                {this.props.getResource(
                                    "Valuation.loadTrend.selectAssetCategory",
                                    "Please select the category from which to load the trend."
                                )}
                            </FormHelperText>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.positive.bind(this)}
                        >
                            {this.props.getResource("Valuation.loadTrend.load", "Load")}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={this.negative.bind(this)}
                        >
                            {this.props.getResource("Valuation.loadTrend.cancel", "Cancel")}
                        </Button>
                        <br />
                        <br />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ChooseCategoryModal;
