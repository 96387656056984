import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    threeDotsLoader: {
        "& circle": {
            animation: "$hopping 1.05s infinite",
            fill: theme.palette.grey[500],

            "&:nth-of-type(2)": {
                "animation-delay": 100,
            },

            "&:nth-of-type(3)": {
                "animation-delay": 200,
            },
        },
    },

    "@keyframes hopping": {
        "0%, 57.14%": {
            "animation-timing-function": "cubic-bezier(0.33,.66,.66,1)",
            transform: "translate(0)",
        },

        "28.57%": {
            "animation-timing-function": "cubic-bezier(0.33,0,.66,.33)",
            transform: "translateY(-6px)",
        },

        "100%": {
            transform: "translate(0)",
        },
    },
}));

export default useStyles;
