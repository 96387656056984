import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import Close from "./close";
import useDialog from "../../../shared/usedialog";
import Start from "../valuation/startValuation";

export default ({ history }) => {
    const { defaultPageSize, availablePageSizes } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-model-valuation-requests", {
        page: 0,
        pageSize: defaultPageSize,
    });

    const { loading, data, invoke } = useAPI(
        () => getAll(searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams]
    );

    const {
        open: handleClose,
        close: handleCloseClose,
        visible: closeToggled,
        args: closeId,
    } = useDialog(invoke);
    const {
        open: handleStart,
        close: handleCloseStart,
        visible: startToggled,
        args: startArgs,
    } = useDialog(invoke);

    const handleEditValuation = (valuationId, assetModelId, assetId) => {
        if (assetId) {
            history.push(`/valuation/asset-valuation/editor/${valuationId}`);
        } else if (assetModelId) {
            history.push(`/valuation/model-valuation/editor/${valuationId}`);
        }
    };

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="model-valuations-create"
                path="/valuation/valuation-requests"
                render={(props) => {
                    return (
                        <Overview
                            searchParams={searchParams}
                            data={data}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onStart={handleStart}
                            onClose={handleClose}
                            onEdit={handleEditValuation}
                            {...props}
                        />
                    );
                }}
            />

            <Close open={closeToggled} onClose={handleCloseClose} valuationRequestId={closeId} />
            <Start
                open={startToggled}
                onClose={handleCloseStart}
                assetId={startArgs && startArgs.assetId}
                assetModelId={startArgs && startArgs.assetModelId}
                technicalLifeNeeded={startArgs && startArgs.technicalLifeNeeded}
                valuationRequestId={startArgs && startArgs.valuationRequestId}
                history={history}
                onValuationStarted={handleEditValuation}
            />
        </React.Fragment>
    );
};
