import React from "react";
import { LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

import useAPI from "../../../shared/useapi";
import { get, getScopes, update } from "./dataservice";
import { getAll as getOperations } from "./../operations/dataservice";
import withResources from "../../../shared/textresources/withresources";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);
    const { data: scopes } = useAPI(() => getScopes(), true);
    const { data: operations } = useAPI(() => getOperations(null, false, null, null), true);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Security.roles.edit.enterDefaultName",
                "Please enter a name for the default language"
            );
        }

        if (!errors.name) {
            cultures.forEach((culture) => {
                if (values.description[culture.code] && !values.name[culture.code]) {
                    errors.name = getResource(
                        "Security.roles.edit.enterLanguageName",
                        "Please enter a name for the language '{language}'",
                        { language: culture.description }
                    );
                }
            });
        }

        if (!values.operations || values.operations.length === 0) {
            errors.operations = getResource(
                "Security.roles.edit.selectOperation",
                "Please select one or more operations"
            );
        }

        return errors;
    };

    var isLoaded = data && scopes && operations;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.roles.edit.title", "Edit role")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.roles.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        return (
                            <React.Fragment>
                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Security.roles.edit.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    margin="normal"
                                    name="description"
                                    labelResourceKey="Security.roles.edit.descriptionField"
                                    labelDefaultValue="Description"
                                    fullWidth
                                    multiline
                                    value={values.description}
                                    culture={culture}
                                    handleChange={handleChange}
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="scope"
                                    label={getResource("Security.roles.edit.scopeField", "Scope")}
                                    value={values.scope}
                                    multiple={true}
                                    options={scopes}
                                    onSelection={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture}
                                    fullWidth
                                    isMedium
                                />

                                {!data.scope.every((x) => values.scope.includes(x)) && (
                                    <Alert severity="warning">
                                        {getResource(
                                            "Security.roles.edit.scopeWarningText",
                                            "Warning: deselecting scopes will result in deletion of user roles where they have this role with one of these scopes!"
                                        )}
                                    </Alert>
                                )}

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="operations"
                                    label={getResource(
                                        "Security.roles.edit.operationsField",
                                        "Permissions"
                                    )}
                                    value={values.operations}
                                    multiple={true}
                                    options={operations.items}
                                    onSelection={(e) => handleChange(e)}
                                    disabled={!isDefaultCulture}
                                    fullWidth
                                    isMedium
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
