import React from "react";
import { Typography } from "@material-ui/core";

import withResources from "../../../../shared/textresources/withresources";
import ReportHighcharts from "./ReportHighcharts";
import Widget from "../../../../shared/widget";

export default withResources(({ getResource, graphParameters }) => {
    graphParameters.reportDateColor = getResource(
        "Valuation.financialReports.report.graph.purchaseDate.color",
        "#FF00FF"
    );
    graphParameters.reportDateTitle = getResource(
        "Valuation.financialReports.report.graph.purchaseDate.title",
        "Report date"
    );
    graphParameters.xAxisTitle = getResource(
        "Valuation.financialReports.report.graph.xAxis.title",
        "Years"
    );
    graphParameters.yAxisTitle = getResource(
        "Valuation.financialReports.report.graph.yAxis.title",
        "Price"
    );

    return (
        <Widget
            headerTitle={getResource(
                "Valuation.financialReports.report.graph.title",
                "Financial overview"
            )}
            hasContent={true}
            renderContent={() => {
                return (
                    <React.Fragment>
                        <ReportHighcharts initParams={graphParameters} />
                        <Typography variant="caption">
                            {getResource(
                                "Valuation.financialReports.report.graph.caption",
                                "Financial forecast (green and red dots) are based on historical market information since 1970, excl. remaining interest and/or penalties. This is an indication - No warranty given or implied."
                            )}
                        </Typography>
                    </React.Fragment>
                );
            }}
        />
    );
});
