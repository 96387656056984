import React from "react";
import { makeStyles } from "@material-ui/styles";

import useForm from "../../../shared/forms/useform";
import useAPI from "../../../shared/useapi";
import { getInvoiceStatuses } from "./dataservice";
import { updateStatus } from "./dataservice";

import Confirmation from "../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            minHeight: 400, //Needed for AutoCompleteStatic
        },
    },
}));

export default withResources(({ getResource, open, id, currentStatus, onClose }) => {
    if (!open) {
        return null;
    }

    const classes = useStyles();

    const { data: statuses } = useAPI(() => getInvoiceStatuses(), true);
    const { loading, invoke } = useAPI(updateStatus);

    const handleUpdate = async (input) => {
        if (await invoke(id, input.values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.status) {
            errors.status = getResource(
                "Administration.invoice.updateStatus.statusRequired",
                "You have to select a new status"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { status: currentStatus },
        onSubmit: handleUpdate,
        validate: (values) => onValidate(values),
    });

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleSubmit}
            handleClose={() => onClose(false)}
            headerText={getResource(
                "Administration.invoice.updateStatus.confirmationHeader",
                "Update status"
            )}
            buttonText={getResource(
                "Administration.invoice.updateStatus.confirmationButton",
                "Update"
            )}
            classes={{ root: classes.dialog }}
        >
            {getResource(
                "Administration.invoice.updateStatus.message",
                "Select the new status of the invoice"
            )}

            <AutoCompleteStatic
                errors={errors}
                name="status"
                label={getResource("Administration.invoices.search.statusesField", "Status")}
                fullWidth
                isMedium
                value={values.status}
                options={statuses}
                onSelection={handleChange}
                disableClearable
            />
        </Confirmation>
    );
});
