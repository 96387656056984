import React, { Fragment, useEffect, useRef } from "react";
import {
    FormControlLabel,
    Checkbox,
    InputAdornment,
    LinearProgress,
    Switch,
} from "@material-ui/core";

import useAPI from "../../../../shared/useapi";
import { getBuyoutTypes } from "../dataservice";
import { get as getAsset } from "../../../assets/asset/dataservice";

import withResources from "../../../../shared/textresources/withresources";
import useCurrencies from "./../../../library/currencies/usecurrencies";
import LabelledOutline from "../../../../shared/labelledoutline";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import DateTimePicker from "../../../../shared/datetimepicker";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import MoneyInput from "../../../../shared/moneyInput";
import PagedListInputForAssets from "../../../../shared/pagedListInput/pagedListInputForAssets";
import AdvertisementEditForm, { validate as validateAdvertisement } from "./advertisementEditForm";

export default withResources(
    ({
        showAssetSelector,
        culture,
        values,
        handleChange,
        errors,
        isDefaultCulture,
        getResource,
    }) => {
        const { currencies, getCode } = useCurrencies();

        const { data: buyoutTypes } = useAPI(() => getBuyoutTypes(), true);

        const prevAssetIdRef = useRef();
        const selectedAssetId = values.asset && values.asset.id; //Asset that is selected and is used to fetch the title. This is only needed with selected assets and not on edit action.

        useEffect(() => {
            async function getAssetData() {
                const response = await getAsset(selectedAssetId);

                if (!response.ok) {
                    return;
                }

                const asset = await response.json();

                const title =
                    asset.name && asset.name[culture]
                        ? asset.name[culture]
                        : `${asset.manufacturerName} ${asset.assetModel.name}`;

                handleChange({ target: { name: "title", value: title } }, true);
            }

            if (showAssetSelector && prevAssetIdRef.current !== selectedAssetId) {
                getAssetData();
                prevAssetIdRef.current = selectedAssetId;
            }
        }, [selectedAssetId, culture, handleChange, showAssetSelector]);

        const isLoaded = currencies && buyoutTypes;

        return !isLoaded ? (
            <LinearProgress />
        ) : (
            <Fragment>
                {showAssetSelector && (
                    <PagedListInputForAssets
                        label={getResource("Marketplace.auction.editForm.asset", "Asset")}
                        errors={errors}
                        name="asset"
                        value={values.asset}
                        onSelection={(e) => handleChange(e)}
                        fullWidth
                        isMedium
                        disableClearable
                        dialogTitle={getResource(
                            "Marketplace.auction.editForm.dialogTitle",
                            "Select an asset"
                        )}
                        helperText={getResource(
                            "Marketplace.auction.editForm.selectAssetText",
                            "Select the asset for which you want to auction"
                        )}
                        required
                    />
                )}

                <DateTimePicker
                    errors={errors}
                    name="auctionStart"
                    label={getResource(
                        "Marketplace.auction.editForm.auctionStart",
                        "Auction start"
                    )}
                    value={values.auctionStart}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                    required
                />

                <DateTimePicker
                    errors={errors}
                    name="auctionStop"
                    label={getResource(
                        "Marketplace.auction.editForm.auctionStop",
                        "Auction closing"
                    )}
                    value={values.auctionStop}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                    required
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(values.concealedBids)}
                            onChange={(e) => handleChange(e)}
                            name="concealedBids"
                            disabled={!isDefaultCulture}
                        />
                    }
                    label={getResource(
                        "Marketplace.auction.editForm.concealedBids",
                        "Concealed bids"
                    )}
                    variant="outlined"
                />

                <AutoCompleteStatic
                    label={getResource("Marketplace.auction.editForm.currencyId", "Currency")}
                    errors={errors}
                    isMedium
                    fullWidth
                    name="currencyId"
                    value={values.currencyId}
                    options={currencies}
                    onSelection={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                    disableClearable
                    required
                />

                <MoneyInput
                    errors={errors}
                    name="startingPrice"
                    labelResourceKey="Marketplace.auction.editForm.startingPrice"
                    labelDefaultValue="Starting price"
                    fullWidth
                    value={values.startingPrice}
                    currencyCode={getCode(values.currencyId)}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                    required
                />

                <MoneyInput
                    errors={errors}
                    name="reservePrice"
                    labelResourceKey="Marketplace.auction.editForm.reservePrice"
                    labelDefaultValue="Reserve price"
                    fullWidth
                    value={values.reservePrice}
                    currencyCode={getCode(values.currencyId)}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(values.showReservePrice)}
                            onChange={(e) => handleChange(e)}
                            name="showReservePrice"
                            disabled={!isDefaultCulture}
                        />
                    }
                    label={getResource(
                        "Marketplace.auction.editForm.showReservePrice",
                        "Show reserve price"
                    )}
                    variant="outlined"
                />

                <MoneyInput
                    errors={errors}
                    name="buyoutPrice"
                    labelResourceKey="Marketplace.auction.editForm.buyoutPrice"
                    labelDefaultValue="Buyout price"
                    fullWidth
                    value={values.buyoutPrice}
                    currencyCode={getCode(values.currencyId)}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                />

                <AutoCompleteStatic
                    errors={errors}
                    name="buyoutType"
                    label={getResource("Marketplace.auction.editForm.buyoutType", "Buyout type")}
                    fullWidth
                    isMedium
                    value={values.buyoutType}
                    onSelection={handleChange}
                    options={buyoutTypes}
                    disabled={!isDefaultCulture}
                    disableClearable
                />

                <MoneyInput
                    errors={errors}
                    name="minimumBidIncrement"
                    labelResourceKey="Marketplace.auction.editForm.minimumBidIncrement"
                    labelDefaultValue="Minimum bid increment"
                    fullWidth
                    value={values.minimumBidIncrement}
                    currencyCode={getCode(values.currencyId)}
                    onChange={(e) => handleChange(e)}
                    disabled={!isDefaultCulture}
                    required
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={Boolean(values.extendOnLastMinuteBid)}
                            onChange={(e) => handleChange(e)}
                            name="extendOnLastMinuteBid"
                            disabled={!isDefaultCulture}
                        />
                    }
                    label={getResource(
                        "Marketplace.auction.editForm.extendOnLastMinuteBid",
                        "Extend on last minute bid"
                    )}
                    variant="outlined"
                />

                {values.extendOnLastMinuteBid && (
                    <Fragment>
                        <ResourcedTextField
                            errors={errors}
                            name="finalBidPeriod"
                            labelResourceKey="Marketplace.auction.editForm.finalBidPeriod"
                            labelDefaultValue="Final bid period"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {getResource(
                                            "Marketplace.auction.editForm.finalBidPeriod.endAdornment",
                                            "minutes"
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            value={values.finalBidPeriod}
                            onChange={(e) => handleChange(e)}
                            disabled={!isDefaultCulture}
                            required
                        />

                        <ResourcedTextField
                            errors={errors}
                            name="bidPeriodExtension"
                            labelResourceKey="Marketplace.auction.editForm.bidPeriodExtension"
                            labelDefaultValue="Bid period extension"
                            margin="normal"
                            type="number"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                inputProps: { min: 0 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {getResource(
                                            "Marketplace.auction.editForm.bidPeriodExtension.endAdornment",
                                            "minutes"
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            value={values.bidPeriodExtension}
                            onChange={(e) => handleChange(e)}
                            disabled={!isDefaultCulture}
                            required
                        />
                    </Fragment>
                )}

                <LabelledOutline
                    label={getResource(
                        "Marketplace.auction.editForm.advertisement",
                        "Advertisement"
                    )}
                >
                    <AdvertisementEditForm
                        culture={culture}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        isDefaultCulture={isDefaultCulture}
                    />
                </LabelledOutline>
            </Fragment>
        );
    }
);

export function validate(values, defaultCulture, getResource) {
    const isValidDate = (dateString) => {
        const d = new Date(dateString);
        return d instanceof Date && !isNaN(d);
    };

    const errors = {};

    if ((!values.asset || !values.asset.id) && !values.assetId) {
        errors.asset = getResource(
            "Marketplace.auction.editForm.assetRequired",
            "Please select an asset"
        );
    }

    if (!isValidDate(values.auctionStart)) {
        errors.auctionStart = getResource(
            "Marketplace.auction.editForm.auctionStartRequired",
            "Please select the date the auction starts"
        );
    }

    if (!isValidDate(values.auctionStop)) {
        errors.auctionStop = getResource(
            "Marketplace.auction.editForm.auctionStopRequired",
            "Please select the date the auction closes"
        );
    }

    if (
        isValidDate(values.auctionStart) &&
        isValidDate(values.auctionStop) &&
        values.auctionStart >= values.auctionStop
    ) {
        errors.auctionStop = getResource(
            "Marketplace.auction.editForm.auctionStopAfterStart",
            "The closing date needs to be after the starting date"
        );
    }

    if (!values.currencyId) {
        errors.currencyId = getResource(
            "Marketplace.auction.editForm.currencyIdRequired",
            "Please select a currency"
        );
    }

    if (!values.startingPrice) {
        errors.startingPrice = getResource(
            "Marketplace.auction.editForm.startingPriceRequired",
            "Please provide a starting price"
        );
    }

    if (
        values.startingPrice &&
        values.reservePrice &&
        values.startingPrice >= values.reservePrice
    ) {
        errors.reservePrice = getResource(
            "Marketplace.auction.editForm.reservePriceHigherThenStartingPrice",
            "The reserve price needs to be higher than the starting price"
        );
    }

    if (values.startingPrice && values.buyoutPrice && values.startingPrice >= values.buyoutPrice) {
        errors.buyoutPrice = getResource(
            "Marketplace.auction.editForm.buyoutPriceHigherThenStartingPrice",
            "The buyout price needs to be higher than the starting price"
        );
    }

    if (!values.buyoutType) {
        errors.buyoutType = getResource(
            "Marketplace.auction.editForm.buyoutTypeRequired",
            "Please select a buyout type"
        );
    }

    if (!values.minimumBidIncrement) {
        errors.minimumBidIncrement = getResource(
            "Marketplace.auction.editForm.minimumBidIncrementRequired",
            "Please provide a minimum bid increment"
        );
    }

    if (values.extendOnLastMinuteBid) {
        if (!values.finalBidPeriod) {
            errors.finalBidPeriod = getResource(
                "Marketplace.auction.editForm.finalBidPeriodRequired",
                "Please provide a final bid period"
            );
        }

        if (values.finalBidPeriod && values.finalBidPeriod < 0) {
            errors.finalBidPeriod = getResource(
                "Marketplace.auction.editForm.finalBidPeriodPositive",
                "Final bid period needs to be a positive number"
            );
        }

        if (!values.bidPeriodExtension) {
            errors.bidPeriodExtension = getResource(
                "Marketplace.auction.editForm.bidPeriodExtensionRequired",
                "Please provide a bid period extension"
            );
        }

        if (values.bidPeriodExtension && values.bidPeriodExtension < 0) {
            errors.bidPeriodExtension = getResource(
                "Marketplace.auction.editForm.bidPeriodExtensionPositive",
                "Bid period extension needs to be a positive number"
            );
        }
    }

    validateAdvertisement(values, defaultCulture, errors, getResource);

    return errors;
}
