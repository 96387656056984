import React, { Fragment, useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Description as ReportIcon,
    Edit as EditIcon,
    FormatListBulleted as TasksIcon,
} from "@material-ui/icons";
import _ from "underscore";

import WorkingContext from "../../security/workingContext";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import PagedList from "../../../shared/pagedList";
import ActionMenu from "../../../shared/actionmenu";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import Search from "./search";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import { formatDate } from "../../../shared/formattedDate";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import Status from "../../../shared/status";
import getStatusColor from "../shared/getStatusColor";
import useStyles from "./useStyles";

export default withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
        reasons,
        statuses,
        assetId,
        componentId,
        name,
        history,
    }) => {
        data = data || {};
        const items = data.items;

        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        const getSchedule = (allDay, start, end) => {
            const startDate = new Date(start).setHours(0, 0, 0, 0);
            var endDate = new Date(end).setHours(0, 0, 0, 0);

            if (allDay) {
                var endDateObject = new Date(end);
                end = endDateObject.setDate(endDateObject.getDate() - 1);
            }

            return getResource(
                "Maintenance.workOrders.overview.scheduleCell",
                "{start} until {end}",
                {
                    start: formatDate(
                        start,
                        selectedUiCulture,
                        selectedTimezone,
                        false,
                        !allDay,
                        false,
                        false
                    ),
                    end:
                        startDate === endDate
                            ? formatDate(
                                  end,
                                  selectedUiCulture,
                                  selectedTimezone,
                                  false,
                                  false,
                                  false,
                                  true
                              )
                            : formatDate(
                                  end,
                                  selectedUiCulture,
                                  selectedTimezone,
                                  false,
                                  !allDay,
                                  false,
                                  false
                              ),
                }
            );
        };

        const classes = useStyles();

        return (
            <Fragment>
                {!assetId && !componentId && (
                    <WorkingContext requiredOperation="work-orders-view" />
                )}
                <ContentHolder>
                    <Header
                        title={
                            name
                                ? getResource(
                                      "Maintenance.workOrder.header.titleFor",
                                      "Work orders for {name}",
                                      { name }
                                  )
                                : getResource("Maintenance.workOrder.header.title", "Work orders")
                        }
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation={getRequiredOperation("work-orders-create")}
                                    buttonText={getResource(
                                        "Maintenance.workOrders.newButton",
                                        "Create"
                                    )}
                                />
                            );
                        }}
                    />

                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Maintenance.workOrders.noResults",
                            "No work orders available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search
                                onSearch={onSearchDebounced}
                                searchParams={searchParams}
                                reasons={reasons}
                                statuses={statuses}
                                showExtendedFilters={!assetId && !componentId}
                            />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Maintenance.workOrders.overview.summaryHeader"
                                            defaultValue="Summary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Maintenance.workOrders.overview.reasonHeader"
                                            defaultValue="Reason"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Maintenance.workOrders.overview.scheduleHeader"
                                            defaultValue="Schedule"
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: 125 }}>
                                        <ResourcedText
                                            resourceKey="Maintenance.workOrders.overview.statusHeader"
                                            defaultValue="Status"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Maintenance.workOrders.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => {
                                                if (row.status === "Completed") {
                                                    history.push(
                                                        `/maintenance/work-orders/${row.id}/report`
                                                    );
                                                } else {
                                                    history.push(
                                                        `/maintenance/work-orders/${row.id}/tasks`
                                                    );
                                                }
                                            }}
                                            requiredOperation={
                                                row.status === "Completed"
                                                    ? getRequiredOperation(
                                                          "work-orders-view-report",
                                                          row
                                                      )
                                                    : getRequiredOperation(
                                                          "work-orders-task-view",
                                                          row
                                                      )
                                            }
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.summary ? (
                                                    <span className={classes.firstRow}>
                                                        {row.summary}
                                                    </span>
                                                ) : (
                                                    <span className={classes.firstRow}>-</span>
                                                )}
                                                <span className={classes.secondRow}>
                                                    {(row.assetName) && (
                                                        <span>{row.assetName}</span>
                                                    )}
                                                    {row.componentName && (
                                                        <span>{row.componentName}</span>
                                                    )}
                                                    {!row.componentName && !row.assetName && (
                                                        <span>{row.companyName}</span>
                                                    )}
                                                </span>
                                            </TableCell>
                                            <TableCell component="td">
                                                {getResource(
                                                    `Maintenance.workOrder.reason.${row.reason.toLowerCase()}`,
                                                    row.reason
                                                )}
                                            </TableCell>
                                            <TableCell component="td">
                                                {row.start &&
                                                    row.end &&
                                                    getSchedule(row.allDay, row.start, row.end)}
                                            </TableCell>
                                            <TableCell component="td">
                                                <Status
                                                    text={getResource(
                                                        `Maintenance.workOrder.status.${row.status.toLowerCase()}`,
                                                        row.status
                                                    )}
                                                    color={getStatusColor(row.status)}
                                                />
                                            </TableCell>
                                            <TableCell component="td" align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Maintenance.workOrders.editButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "work-orders-update",
                                                                row
                                                            ),
                                                            onClick: () => onEdit(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Maintenance.workOrders.tasksButton",
                                                                "Tasks"
                                                            ),
                                                            icon: <TasksIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "work-orders-task-view",
                                                                row
                                                            ),
                                                            onClick: () =>
                                                                history.push(
                                                                    `/maintenance/work-orders/${row.id}/tasks`
                                                                ),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Maintenance.workOrders.viewReportButton",
                                                                "View report"
                                                            ),
                                                            icon: <ReportIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "work-orders-view-report",
                                                                row
                                                            ),
                                                            onClick: () =>
                                                                history.push(
                                                                    `/maintenance/work-orders/${row.id}/report`
                                                                ),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Maintenance.workOrders.deleteButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "work-orders-delete",
                                                                row
                                                            ),
                                                            onClick: () =>
                                                                onDelete({
                                                                    id: row.id,
                                                                    summary: row.summary,
                                                                }),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </Fragment>
        );
    }
);
