export default {
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",

        "&:last-child": {
            marginBottom: "15px !important",

            ".MuiListItem-root &": {
                marginBottom: "0 !important",
            },
        },

        "&:not(:last-child)": {
            borderBottom: 0,
        },

        "&:before": {
            display: "none",
        },

        "&$expanded": {
            margin: "0",
        },
    },
};
