import React, { Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, _, defaultCulture) => {
        const errors = {};

        if (!values.text[defaultCulture]) {
            errors.text = getResource(
                "System.textResources.edit.enterText",
                "Please enter a text for the default language"
            );
        }

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("System.textResources.edit.title", "Edit text resource")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("System.textResources.edit.okButtonText", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    useUILanguages={true}
                    showCultureSelector
                    renderForm={(culture, values, handleChange, errors) => {
                        return (
                            <Fragment>
                                <ResourcedTextField
                                    margin="normal"
                                    name="key"
                                    labelResourceKey="System.textResources.edit.keyField"
                                    labelDefaultValue="Key"
                                    type="text"
                                    variant="filled"
                                    fullWidth
                                    readOnly
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 255 }}
                                    value={values.key}
                                    required
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="text"
                                    labelResourceKey="System.textResources.edit.textField"
                                    labelDefaultValue="Text"
                                    fullWidth
                                    inputProps={{ maxLength: 1000 }}
                                    value={values.text}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
