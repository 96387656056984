import React, { useRef, useEffect, Fragment } from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SortableList from "../../../../shared/sortableList";
import withResources from "../../../../shared/textresources/withresources";
import AddButton from "../../../../shared/addButton";
import TranslatableTextField from "../../../../shared/translatableTextField";

export default withResources(
    ({ getResource, name, onChange, values, errors, culture, disabled }) => {
        const isBrowserAvailable = typeof window !== "undefined";
        let addButtonRef = useRef(null);
        let lastSection = useRef(null);

        useEffect(() => {
            if (isBrowserAvailable && document.activeElement === addButtonRef.current) {
                lastSection.current.focus();
                addButtonRef.current.value = "";
            }
        }, [values, isBrowserAvailable]);

        const handleChange = (values) => {
            onChange({
                target: {
                    name: name,
                    value: values,
                },
            });
        };

        return (
            <Fragment>
                <SortableList
                    items={values}
                    sortingChanged={(sortedSections) => {
                        var copy = sortedSections.map((item, index) => ({ ...item, order: index }));
                        handleChange(copy);
                    }}
                    culture={culture}
                    disabled={disabled}
                    renderItemContent={(section, index) => (
                        <Fragment>
                            <TranslatableTextField
                                errors={errors && errors[index]}
                                labelResourceKey="Marketplace.advertisement.additionalInformation.titleField"
                                labelDefaultValue="Title"
                                fullWidth
                                name="title"
                                inputRef={lastSection}
                                value={section.title}
                                culture={culture}
                                handleChange={(e) => {
                                    const copy = [...values];
                                    copy[index]["title"][culture] = e.target.value;
                                    handleChange(copy, true);
                                }}
                                required
                            />

                            <TranslatableTextField
                                errors={errors && errors[index]}
                                labelResourceKey="Marketplace.advertisement.additionalInformation.descriptionField"
                                labelDefaultValue="Description"
                                fullWidth
                                name="description"
                                value={section.description}
                                culture={culture}
                                multiline
                                handleChange={(e) => {
                                    const copy = [...values];
                                    copy[index]["description"][culture] = e.target.value;
                                    handleChange(copy, true);
                                }}
                                required
                            />

                            <IconButton
                                aria-label="delete"
                                disabled={disabled}
                                onClick={() => {
                                    const copy = [
                                        ...values.slice(0, index),
                                        ...values.slice(index + 1),
                                    ];
                                    handleChange(copy);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Fragment>
                    )}
                />

                <AddButton
                    buttonRef={addButtonRef}
                    buttonText={getResource(
                        "Marketplace.advertisement.additionalInformation.addButton",
                        "Add section"
                    )}
                    disabled={disabled}
                    handleClick={() => {
                        const copy = values.concat([
                            {
                                title: { [culture]: "" },
                                description: {},
                            },
                        ]);
                        handleChange(copy);
                    }}
                />
            </Fragment>
        );
    }
);

export function validate(values, name, errors, getResource, defaultCulture) {
    const setError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((row, i) => {
        if (!row.title[defaultCulture]) {
            setError(
                i,
                "title",
                getResource(
                    "Marketplace.advertisement.additionalInformation.titleRequired",
                    "Please provide a title for the default language"
                )
            );
        }

        if (!row.description[defaultCulture]) {
            setError(
                i,
                "description",
                getResource(
                    "Marketplace.advertisement.additionalInformation.descriptionRequired",
                    "Please provide a description for the default language"
                )
            );
        }
    });
}
