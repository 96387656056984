import interceptedFetch from "../../../shared/interceptedFetch";

async function getDictionaryItems(key) {
    const url = `/api/settings/dictionary/${key}/items`;

    return await interceptedFetch(url);
}

async function getAll() {
    const url = `/api/settings/dictionary`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/settings/dictionary/${id}`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/settings/dictionary/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

export { getDictionaryItems, getAll, get, update };
