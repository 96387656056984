import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { get, del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, id, open, onClose }) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    if (!open) {
        return false;
    }

    const { data: asset } = useAPI(() => get(id), true);

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    var assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);
    const isSealed = asset && asset.isSealed;

    const headerText = isSealed
        ? getResource("Asset.asset.delete.sealedHeader", "Cannot delete sealed asset")
        : getResource("Asset.asset.delete.header", "Delete asset {assetName}?", {
            assetName,
          });

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={headerText}
            buttonText={getResource("Asset.asset.delete.okButton", "Delete")}
            buttonHidden={isSealed}
        >
            {!isSealed && (
                <ResourcedText
                    resourceKey="Asset.asset.delete.deleteMessage"
                    defaultValue="Are you sure you want to delete this asset? "
                />
            )}

            {isSealed && (
                <ResourcedText
                    resourceKey="Asset.asset.delete.sealedMessage"
                    defaultValue="This asset is currently sealed and cannot be deleted. "
                />
            )}
        </Confirmation>
    );
});
