import React, { useState } from "react";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Valuation.assetModelQuestionnaire.search.searchText"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
            ]}
        />
    );
});

export default Search;
