import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    taskSplittingHelperText: {
        margin: `0 0 ${theme.spacing(2)}px`,
    },

    tasksOutline: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },

    taskSummaryContent: {
        alignItems: "center",
        justifyContent: "space-between",

        "&, &.Mui-expanded": {
            margin: 0,
        },

        "& > *": {
            marginBottom: 0,
        },
    },
}));

export default useStyles;
