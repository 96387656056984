import React, { useState } from "react";
import useAPI from "../../../shared/useapi";
import { getStatuses } from "./dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.status);
    }

    const { data: statuses } = useAPI(() => getStatuses(), true);

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Marketplace.auctions.search.searchField"
                    labelDefaultValue="Keyword search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource("Marketplace.auctions.search.statusField", "Status")}
                    value={searchValues.status}
                    options={statuses}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, status: e.target.value })
                    }
                />,
            ]}
        />
    );
});

export default Search;
