import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import useStyles from "./useStyles";
import {
    CloudUpload as CloudUploadIcon,
    CropOriginal as ImageIcon,
    Delete as DeleteIcon,
    ExpandLess as CollapseIcon,
    ExpandMore as ExpandIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import TranslatableTextField from "../../translatableTextField";
import ResourcedTextField from "../../textresources/resourcedtextfield";
import ConfigurationContext from "../../configuration/configurationcontext";
import Upload from "../../upload";

const MediaItem = ({
    media,
    multiLingual,
    culture,
    disabled,
    handleDelete,
    isExpanded,
    index,
    errors,
    uploadUrl,
    getDownloadUrl,
    handleAccordionToggle,
    handleFileUpload,
    handleDescriptionChange,
    handleFileDelete,
    singleMediaItem,
}) => {
    const [imageSource, setImageSource] = useState("");
    const [tempImageSource, setTempImageSource] = useState("");
    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const { allowedMediaExtensions } = useContext(ConfigurationContext);
    const classes = useStyles();

    useEffect(() => {
        if (tempImageSource) {
            setImageSource(tempImageSource);
        } else if (media.id && media.fileId) {
            setImageSource(getDownloadUrl(media));
        } else {
            setImageSource("");
            setImageIsLoaded(false);
        }
    }, [media, tempImageSource, getDownloadUrl]);

    const onFileUpload = (file, originalFileObject, index) => {
        handleFileUpload(file, index);

        const reader = new FileReader();
        reader.onload = (e) => {
            setTempImageSource(e.target.result);
        };
        reader.readAsDataURL(originalFileObject);
    };

    const onFileDelete = () => {
        setTempImageSource("");
        handleFileDelete(index);
    };

    const renderDetails = () => (
        <div className={classes.details}>
            <div className={classes.detailsImageOrUploadContainer}>
                {imageSource && (
                    <div className={clsx("imageAndDelete", imageIsLoaded && "--image-is-loaded")}>
                        <img src={imageSource} alt="" onLoad={() => setImageIsLoaded(true)} />
                        <IconButton
                            aria-label="delete"
                            disabled={disabled}
                            className="deleteButton"
                            size="small"
                            onClick={onFileDelete}
                        >
                            <CloudUploadIcon />
                        </IconButton>
                    </div>
                )}

                {/** Gets hidden via styling, since the component needs to continue handling
                 uploading, when it gets hidden after selecting a file. **/}
                <div className={clsx(classes.detailsUploadContainer, imageSource && "--hidden")}>
                    <Upload
                        fileId={media.fileId}
                        fileName={media.fileName}
                        errors={errors && errors[index]}
                        url={uploadUrl}
                        disabled={disabled}
                        allowedExtensions={allowedMediaExtensions}
                        showDelete={false}
                        onFileUpload={(file, originalFileObject) => {
                            onFileUpload(file, originalFileObject, index);
                        }}
                    />
                </div>
            </div>

            {multiLingual ? (
                <TranslatableTextField
                    errors={errors && errors[index]}
                    name="description"
                    labelResourceKey="Media.mediaEditor.description"
                    labelDefaultValue="Description"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    value={media.description}
                    culture={culture}
                    handleChange={(file) => handleDescriptionChange(file, index)}
                />
            ) : (
                <ResourcedTextField
                    errors={errors && errors[index]}
                    name="description"
                    labelResourceKey="Media.mediaEditor.description"
                    labelDefaultValue="Description"
                    type="text"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 255 }}
                    value={media.description || ""}
                    onChange={(file) => handleDescriptionChange(file, index)}
                />
            )}
        </div>
    );

    if (singleMediaItem) {
        return <div className={classes.singleMediaItem}>{renderDetails()}</div>;
    } else {
        return (
            <Accordion
                id={`mediaPanel${index}`}
                square
                expanded={isExpanded}
                onChange={() => handleAccordionToggle(isExpanded, index)}
            >
                <AccordionSummary
                    aria-controls={`mediaPanel${index}-content`}
                    id={`mediaPanel${index}-header`}
                    classes={{ content: classes.summaryContent }}
                >
                    {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    <div className={classes.summaryImageWrapper}>
                        {imageSource ? <img src={imageSource} alt="" /> : <ImageIcon />}
                    </div>
                    <div className={classes.summaryDescription}>
                        {multiLingual ? media.description[culture] : media.description}
                    </div>
                    <div className={classes.summaryDeleteButtonWrapper}>
                        {isExpanded && (
                            <IconButton
                                aria-label="delete"
                                disabled={disabled}
                                onClick={() => handleDelete(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </div>
                </AccordionSummary>

                <AccordionDetails id={`mediaPanel${index}-content`}>
                    {renderDetails()}
                </AccordionDetails>
            </Accordion>
        );
    }
};

export default MediaItem;
