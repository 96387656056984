import React from "react";
import {
    LinearProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress,
} from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { getAll, mergeAndDelete } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import useForm from "../../../shared/forms/useform";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        minHeight: 200,
    },
}));

export default withResources(({ getResource, id, version, name, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(mergeAndDelete);

    const handleMerge = async (input) => {
        var values = {
            sourceVersion: version,
            targetId: input.values.targetManufacturerId,
        };
        if (await invoke(id, values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        if (!values.targetManufacturerId) {
            errors.targetManufacturerId = getResource(
                "Library.manufacturer.merge.targetManufacturerId",
                "You have to select the manufacturer to merge to"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: { sourceId: id },
        onSubmit: handleMerge,
        validate: (values) => onValidate(values),
    });

    const classes = useStyles();
    const { data: allManufacturers } = useAPI(() => getAll(null, null, null), true);

    var isLoaded = allManufacturers;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <React.Fragment>
                    <DialogTitle id="alert-dialog-title">
                        {getResource(
                            "Library.manufacturer.merge.header",
                            "Merge manufacturer {name}",
                            { name }
                        )}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            {getResource(
                                "Library.manufacturer.merge.body",
                                "The manufacturer will be deleted, and all related models are moved to the selected manufacturer."
                            )}
                        </DialogContentText>

                        <AutoCompleteStatic
                            errors={errors}
                            name="targetManufacturerId"
                            label={getResource(
                                "Library.manufacturer.merge.targetManufacturerId",
                                "Manufacturer"
                            )}
                            isMedium
                            fullWidth
                            value={values.targetManufacturerId}
                            onSelection={(e) => handleChange(e)}
                            options={allManufacturers.items.filter(
                                (manufacturer) => manufacturer.id !== id
                            )}
                            disableClearable={true}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose(false)} autoFocus>
                            {getResource("Library.manufacturer.merge.cancelButtonText", "Cancel")}
                        </Button>
                        <Button disabled={loading} onClick={handleSubmit} color="primary">
                            {getResource("Library.manufacturer.merge.okButton", "Merge")}
                            {loading && <CircularProgress />}
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
});
