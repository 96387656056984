import React, { useContext, Fragment } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get } from "./dataservice";
import { Slot } from "../../../shared/slotmachine/index";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import withResources from "../../../shared/textresources/withresources";
import FinancialDetailsWidget from "../../assets/assetFinancialDetail/financialDetailsWidget";
import OutstandingLoanWidget from "../../assets/assetFinancialDetail/outstandingLoanWidget";
import FinancialOverviewGraph from "./components/financialOverviewGraph";
import NetOutstandingTable from "./components/netOutstandingTable";
import BookValueTable from "./components/bookValueTable";
import { formatDate } from "../../../shared/formattedDate";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import NotFound from "../../../shared/notFound";

export default withResources(
    ({
        getResource,
        match: {
            params: { financialReportId },
        },
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);
        const { data: financialReport, loading } = useAPI(() => get(financialReportId), true, [
            financialReportId,
        ]);

        var assetName =
            financialReport &&
            financialReport.quickScan &&
            (financialReport.quickScan.assetName ||
                `${financialReport.quickScan.manufacturerName} / ${financialReport.quickScan.assetModelName}`);

        var quickScanAsset = financialReport &&
            financialReport.quickScan && {
                companyName: financialReport.quickScan.companyName,
                manufacturerName: financialReport.quickScan.manufacturerName,
                assetModel: {
                    name: financialReport.quickScan.assetModelName,
                },
                constructionDate: financialReport.quickScan.constructionDate,
                id: financialReport.quickScan.assetId,
            };

        const widgetWidth = financialReport && financialReport.financialDetail.loanOptional ? 6 : 4;
        const tableWidth = financialReport && financialReport.financialDetail.loanOptional ? 12 : 6;

        return (
            <Fragment>
                {loading && <LinearProgress />}
                {!loading && !financialReport && <NotFound element="financial report" />}
                {financialReport && (
                    <ContentHolder>
                        <Header
                            title={
                                assetName
                                    ? getResource(
                                          "Valuation.financialReports.report.header.title",
                                          "Financial report for {name}",
                                          { name: assetName }
                                      )
                                    : getResource(
                                          "Valuation.financialReports.report.header.loadingTitle",
                                          "Financial report"
                                      )
                            }
                            breadcrumb={financialReport.quickScan.categories}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <p>
                                    {getResource(
                                        "Valuation.financialReports.report.financialReportPurchasedOn",
                                        "Report issued on {purchasedDate}",
                                        {
                                            purchasedDate: formatDate(
                                                financialReport.purchasedDate,
                                                selectedUiCulture,
                                                selectedTimezone,
                                                true,
                                                false,
                                                true
                                            ),
                                        }
                                    )}
                                </p>
                                <p>
                                    {getResource(
                                        "Valuation.financialReports.report.quickScanPurchasedOn",
                                        "The information in this report is based on a quick scan made on {purchasedDate}",
                                        {
                                            purchasedDate: formatDate(
                                                financialReport.quickScan.purchasedOn,
                                                selectedUiCulture,
                                                selectedTimezone,
                                                true,
                                                false,
                                                true
                                            ),
                                        }
                                    )}
                                </p>
                            </Grid>
                            <Grid item xs={widgetWidth}>
                                <Slot name="asset-summary" list={true} asset={quickScanAsset} />
                            </Grid>
                            <Grid item xs={widgetWidth}>
                                <FinancialDetailsWidget
                                    assetId={financialReport.quickScan.assetId}
                                    asset={quickScanAsset}
                                    data={{
                                        currencyShortName:
                                            financialReport.quickScan.currencyShortName,
                                        ...financialReport.financialDetail,
                                    }}
                                    hideActions
                                />
                            </Grid>
                            {!financialReport.financialDetail.loanOptional && (
                                <Grid item xs={4}>
                                    <OutstandingLoanWidget
                                        assetId={financialReport.quickScan.assetId}
                                        loading={loading}
                                        asset={quickScanAsset}
                                        data={{
                                            currencyShortName:
                                                financialReport.quickScan.currencyShortName,
                                            ...financialReport.financialDetail,
                                        }}
                                        hideActions
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FinancialOverviewGraph
                                    graphParameters={financialReport.graphParameters}
                                />
                            </Grid>
                            {!financialReport.financialDetail.loanOptional && (
                                <Grid item xs={6}>
                                    <NetOutstandingTable
                                        values={financialReport.netOutstandingTableValues}
                                        loading={loading}
                                        currencyShortName={
                                            financialReport.quickScan.currencyShortName
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={tableWidth}>
                                <BookValueTable
                                    values={financialReport.bookValueTableValues}
                                    loading={loading}
                                    currencyShortName={financialReport.quickScan.currencyShortName}
                                />
                            </Grid>
                        </Grid>
                    </ContentHolder>
                )}
            </Fragment>
        );
    }
);
