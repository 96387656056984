import React, { Fragment, useContext, useState } from "react";
import { Event as EventIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker } from "material-ui-i18n-daterange-picker";
import LocalizationContext from "./localization/localizationcontext";
import { formatDate } from "./formattedDate";
import withResources from "./textresources/withresources";
import LabelledOutline from "./labelledoutline";

const useStyles = makeStyles((theme) => ({
    labelledOutline: {
        margin: 0,

        "& .MuiFormLabel-root": {
            fontWeight: "normal",
            fontSize: 16,
        },

        "& #dateRangePickerLabel": {
            padding: "10px 14px",
            display: "flex",
            height: 40,
            whiteSpace: "nowrap",
            cursor: "pointer",
        },

        "& .MuiSvgIcon-root": {
            position: "absolute",
            right: theme.spacing(1),
        },
    },

    dateRangePicker: {
        position: "absolute",

        "& div[class*=weekDaysContainer] span:first-child": {
            order: 1,
        },
    },
}));

export default withResources(
    ({ getResource, label, name, onChange, errors, value, disabled, definedRanges, ...props }) => {
        const { selectedUiCulture, dateFnsLocale, selectedTimezone } = useContext(
            LocalizationContext
        );

        const classes = useStyles();

        const [isOpen, setIsOpen] = useState(false);

        const toggleOpen = () => setIsOpen(!isOpen);

        const handleChange = (range) => {
            onChange({ target: { name: name, type: "daterangepicker", value: range } });
            toggleOpen();
        };

        return (
            <Fragment>
                <LabelledOutline
                    id="dateRangePickerLabel"
                    label={label}
                    className={classes.labelledOutline}
                    onClick={toggleOpen}
                >
                    {value
                        ? getResource(
                              "Shell.dateRangePicker.selection",
                              "{startDate} - {endDate}",
                              {
                                  startDate: formatDate(
                                      value.startDate,
                                      selectedUiCulture,
                                      selectedTimezone,
                                      false,
                                      false,
                                      false,
                                      false
                                  ),
                                  endDate: formatDate(
                                      value.endDate,
                                      selectedUiCulture,
                                      selectedTimezone,
                                      false,
                                      false,
                                      false,
                                      false
                                  ),
                              }
                          )
                        : getResource("Shell.dateRangePicker.noSelection", "-")}
                    <EventIcon fontSize="small" />
                </LabelledOutline>
                <div className={classes.dateRangePicker}>
                    <DateRangePicker
                        initialDateRange={value}
                        open={isOpen}
                        toggle={toggleOpen}
                        onChange={handleChange}
                        disabled={disabled}
                        definedRanges={definedRanges}
                        locale={dateFnsLocale}
                        {...props}
                    />
                </div>
            </Fragment>
        );
    }
);
