import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const IntroBlock = ({ summary, description }) => {
    const classes = useStyles();

    if (!summary || !description) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6">{summary}</Typography>
            <p>{description}</p>
        </div>
    );
};

export default IntroBlock;
