import React from "react";
import withResources from "../../../../shared/textresources/withresources";

import useAPI from "../../../../shared/useapi";
import { requestValuation } from "../dataservice";

import Confirmation from "../../../../shared/confirmation";

const RequestValuationDialog = withResources(
    ({
        getResource,
        open,
        companyId,
        assetId,
        assetModelId,
        constructionDate,
        futureMonths,
        onValuationRequested,
        onCancel,
        children,
    }) => {
        const { loading, invoke } = useAPI(
            () =>
                requestValuation(companyId, assetId, assetModelId, constructionDate, futureMonths),
            false
        );

        const handleRequestValuationDialog = async () => {
            if (await invoke()) {
                onValuationRequested();
            }
        };

        return (
            <Confirmation
                open={open}
                handleAction={handleRequestValuationDialog}
                handleClose={onCancel}
                headerText={getResource(
                    "Valuation.quickScan.requestValuationDialog.header",
                    "Valuation information is missing"
                )}
                buttonText={getResource(
                    "Valuation.quickScan.requestValuationDialog.requestValuationButton",
                    "Request valuation"
                )}
                loading={loading}
            >
                {children}
            </Confirmation>
        );
    }
);

export default RequestValuationDialog;
