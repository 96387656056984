import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { getIsReferenced, del } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data: isReferenced } = useAPI(() => getIsReferenced(id), true, [id]);

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Valuation.questions.delete.confirmationHeader",
                "Delete question?"
            )}
            buttonText={getResource("Valuation.questions.delete.confirmationButton", "Delete")}
            buttonHidden={isReferenced}
        >
            {isReferenced && (
                <ResourcedText
                    resourceKey="Valuation.questions.delete.inUseMessage"
                    defaultValue="Question already in use; first delete all items referencing this question."
                />
            )}
            {!isReferenced && (
                <ResourcedText
                    resourceKey="Valuation.questions.delete.deleteMessage"
                    defaultValue="The question will be deleted."
                />
            )}
        </Confirmation>
    );
});
