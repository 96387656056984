import React from "react";

import useAPI from "../../../shared/useapi";
import { unlinkReadings } from "../component/dataservice";

import withResources from "../../../shared/textresources/withresources";
import Confirmation from "../../../shared/confirmation";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(() => unlinkReadings(id));

    const handleUnlink = async () => {
        if (await invoke()) {
            onClose(true);
        }
    };

    return (
         <Confirmation
            open
            loading={loading}
            handleAction={handleUnlink}
            handleClose={() => onClose(false)}
            headerText={getResource("Assets.component.unlinkReadings.title", "Unlink component readings")}
            buttonText={getResource("Assets.component.unlinkReadings.okButton", "Unlink")}
        >
            {getResource("Assets.component.unlinkReadings.text", "Are you sure you want to unlink the readings?")}
        </Confirmation>        
    );
});
