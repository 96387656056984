import { useContext } from "react";
import LocalizationContext from "./localization/localizationcontext";

export default ({ value, decimals }) => {
    const { selectedUiCulture } = useContext(LocalizationContext);

    if (value == null) {
        return null;
    }

    const formatter = new Intl.NumberFormat(selectedUiCulture, {
        style: "percent",
        maximumFractionDigits: decimals || 0,
        minimumFractionDigits: decimals || 0,
    });

    return formatter.format(value / 100);
};
