import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    questionsContainer: {
        width: "100%",
    },

    accordion: {
        width: "100%",
    },

    labelItem: {
        paddingRight: 20,
    },

    label: {
        fontWeight: "bold",
    },

    questionNameItem: {
        marginTop: 10,
        marginBottom: 10,
        alignSelf: "center",
    },

    deleteItem: {
        textAlign: "right",
        marginBottom: 10,
    },

    deleteIcon: {
        marginRight: "-11px",
    },

    dependentOnAgeCheckbox: {
        marginRight: 5,
        marginLeft: 0,
    },

    dependentOnAgeSlider: {
        marginTop: 40,
    },

    correctionLabel: {
        paddingTop: 10,
        fontWeight: "bold",
    },

    answersHeading: {
        fontWeight: "bold",
        padding: "5px 0 10px",
    },

    addButtonBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    addButtonWrapper: {
        flexGrow: 1
    },

    copyButton: {
        color: "#546e7a",
    },

    copyIcon: {
        marginRight: 8
    },

    dialog: {
        "& .MuiDialog-paper": {
            minHeight: 500, //This way the dialog won't jump al the time
        },
    },

    typeSelector: {
        margin: `${theme.spacing(2)}px 0`,

        "& .MuiToggleButtonGroup-root": {
            display: "block",
            marginTop: theme.spacing(1),
        },
    },

    inheritedQuestions: {
        margin: `${theme.spacing(2)}px 0`,

        "& .questionsEditorAccordion": {
            marginBottom: "0 !important",
        },
    },

    formHelperText: {
        marginTop: -5,
    },

    accordionInformation: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        width: "100%",
    },

    accordionQuestion: {
        width: "auto",
        paddingRight: theme.spacing(4),
        fontWeight: 500,
    },

    accordionDetails: {
        display: "flex",
        width: 255,
        color: "#546e7a",
    },

    accordionAge: {
        width: 100,
        marginRight: 5,
    },

    accordionCorrection: {
        width: 150,
    },
}));

export default useStyles;
