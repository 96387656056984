import React, { Fragment } from "react";
import withResources from "../textresources/withresources";
import useStyles from "./useStyles";
import PropTypes from "prop-types";

const AssetCellContent = withResources(
    ({
        getResource,
        assetName,
        manufacturerName,
        assetModelName,
        constructionYear,
        serial,
        internalId,
        assetId,
    }) => {
        const classes = useStyles();

        const renderOtherData = () => (
            <Fragment>
                {constructionYear && (
                    <span
                        title={getResource(
                            "Asset.assets.assetCellContent.constructionYearTitle",
                            "Construction year"
                        )}
                    >
                        {constructionYear}
                    </span>
                )}
                {assetId && (
                    <span
                        title={getResource(
                            "Asset.assets.assetCellContent.assetIdTitle",
                            "Asset id"
                        )}
                    >
                        {getResource("Asset.assets.assetCellContent.assetIdPrefix", "Asset id:")}{" "}
                        {assetId}
                    </span>
                )}
                {serial && (
                    <span
                        title={getResource(
                            "Asset.assets.assetCellContent.serialNumberTitle",
                            "Serial number"
                        )}
                    >
                        {getResource("Asset.assets.assetCellContent.serialNumberPrefix", "S/N:")}{" "}
                        {serial}
                    </span>
                )}
                {internalId && (
                    <span
                        title={getResource(
                            "Asset.assets.assetCellContent.internalIdLabel",
                            "Internal ID"
                        )}
                    >
                        {internalId}
                    </span>
                )}
            </Fragment>
        );

        return assetName ? (
            <div className={classes.root}>
                <div
                    title={getResource(
                        "Asset.assets.assetCellContent.assetNameLabel",
                        "Asset name"
                    )}
                >
                    {assetName}
                </div>
                <div className={classes.secondRow}>
                    <span>{manufacturerName}</span>
                    <span>{assetModelName}</span>
                    {renderOtherData()}
                </div>
            </div>
        ) : (
            <div className={classes.root}>
                <span
                    title={getResource(
                        "Asset.assets.assetCellContent.manufacturerNameLabel",
                        "Manufacturer name"
                    )}
                >
                    {manufacturerName}
                </span>
                <span
                    title={getResource(
                        "Asset.assets.assetCellContent.assetModelNameLabel",
                        "Model name"
                    )}
                >
                    {assetModelName}
                </span>
                <div className={classes.secondRow}>{renderOtherData()}</div>
            </div>
        );
    }
);

AssetCellContent.propTypes = {
    getResource: PropTypes.string,
    assetName: PropTypes.string,
    manufacturerName: PropTypes.string,
    assetModelName: PropTypes.string,
    constructionYear: PropTypes.number,
    serial: PropTypes.string,
    internalId: PropTypes.string,
};

export default AssetCellContent;
