import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { get, seal } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    const { data: component } = useAPI(() => get(id), true);

    const { loading, invoke } = useAPI(seal);

    const handleSeal = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    var componentName =
        component &&
        component.name &&
        (component.name[selectedCulture] || component.name[defaultCulture]);

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleSeal}
            handleClose={() => onClose(false)}
            headerText={getResource("Asset.component.seal.header", "Seal component?")}
            buttonText={getResource("Asset.component.seal.okButton", "Seal component")}
        >
            <ResourcedText
                resourceKey="Asset.component.seal.sealMessage"
                defaultValue="Are you sure you want to seal {componentName}? After sealing users can no longer update the component information."
                tokens={{ componentName }}
            />
        </Confirmation>
    );
});
