import React, { useContext } from "react";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PlayCircleOutline as OpenIcon } from "@material-ui/icons";
import useSessionStorage from "../../../shared/useSessionStorage";
import Search from "./search";
import ActionMenu from "../../../shared/actionmenu";
import useAPI from "../../../shared/useapi";
import _ from "underscore";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import { getAssetModelsWithValuations } from "../valuation/dataservice";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import PagedList from "../../../shared/pagedList";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import Path from "../../../shared/path";
import { formatDate } from "../../../shared/formattedDate";

const useOverviewStyles = makeStyles((theme) => ({
    root: {},
    filterButton: {
        marginRight: theme.spacing(2),
    },
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 1150,
    },
    actions: {
        padding: theme.spacing(0, 1),
        justifyContent: "flex-end",
    },
    noContent: {
        backgroundColor: theme.palette.white,
        padding: "20px",
        textAlign: "center",
        fontWeight: 500,
    },
    searchIcon: {
        display: "block",
        margin: "10px auto",
        backgroundColor: "#3f51b5",
        color: "#fff",
        padding: "10px",
        borderRadius: ".8em",
        fontSize: "3em",
    },
    chip: {
        marginRight: theme.spacing(1),
    },
}));

const ChooseAssetModel = withResources(({ getResource, history }) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-model-valuation", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        manufacturerId: null,
        assetCategory: { id: null, name: null },
        statuses: [],
    });

    const { loading, data } = useAPI(
        () =>
            getAssetModelsWithValuations(
                searchParams.searchText,
                searchParams.manufacturerId,
                searchParams.assetCategory.id,
                searchParams.statuses,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [searchParams]
    );

    function onSearch(searchText, manufacturerId, assetCategory, statuses) {
        setSearchParams({
            ...searchParams,
            searchText,
            manufacturerId,
            assetCategory,
            statuses,
            page: 0,
        });
    }

    function onPageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function onPageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    const classes = useOverviewStyles();

    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    return (
        <ContentHolder>
            <Header title={getResource("Valuation.chooseAssetModel.title", "Choose model")} />
            <PagedList
                loading={loading}
                itemCount={data && data.total}
                noResultsText={getResource(
                    "Valuation.chooseAssetModel.noResults",
                    "No models available"
                )}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                availablePageSizes={availablePageSizes}
                page={searchParams.page}
                pageSize={searchParams.pageSize}
                renderSearch={() => (
                    <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                )}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Valuation.chooseAssetModel.manufacturerHeader"
                                    defaultValue="Manufacturer"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Valuation.chooseAssetModel.nameHeader"
                                    defaultValue="Name"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Valuation.chooseAssetModel.categoriesHeader"
                                    defaultValue="Category"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Valuation.chooseAssetModel.valuationStatusHeader"
                                    defaultValue="Valuation status"
                                />
                            </TableCell>
                            <TableCell align="right">
                                <ResourcedText
                                    resourceKey="Valuation.chooseAssetModel.actionsHeader"
                                    defaultValue="Actions"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.items.map((row) => (
                                <AuthorizedTableRow
                                    hover
                                    key={row.id}
                                    handleClick={() =>
                                        history.push(`/valuation/model-valuation/${row.id}`)
                                    }
                                    requiredOperationAtLeastOneOf={[
                                        "model-valuations-create",
                                        "model-valuations-prepare",
                                    ]}
                                >
                                    <TableCell component="td">{row.manufacturerName}</TableCell>
                                    <TableCell component="td">{row.name}</TableCell>
                                    <TableCell component="td">
                                        <Path path={row.assetCategoryNames} useRegularText />
                                    </TableCell>
                                    <TableCell component="td">
                                        {row.statuses.map(function (status) {
                                            var suffix = "";
                                            if (status === "Published") {
                                                suffix = ` (${formatDate(
                                                    row.lastPublishedDate,
                                                    selectedUiCulture,
                                                    selectedTimezone
                                                )})`;
                                            }
                                            return (
                                                <Chip
                                                    key={status}
                                                    className={classes.chip}
                                                    label={
                                                        getResource(
                                                            `Valuation.status.${status}`,
                                                            status
                                                        ) + suffix
                                                    }
                                                />
                                            );
                                        })}
                                    </TableCell>
                                    <TableCell align="right">
                                        <ActionMenu
                                            actions={[
                                                {
                                                    text: "Open",
                                                    icon: <OpenIcon />,
                                                    itemId: row.id,
                                                    requiredOperationAtLeastOneOf: [
                                                        "model-valuations-create",
                                                        "model-valuations-prepare",
                                                    ],
                                                    onClick: () =>
                                                        history.push(
                                                            `/valuation/model-valuation/${row.id}`
                                                        ),
                                                },
                                            ]}
                                        />
                                    </TableCell>
                                </AuthorizedTableRow>
                            ))}
                    </TableBody>
                </Table>
            </PagedList>
        </ContentHolder>
    );
});

export default ChooseAssetModel;
