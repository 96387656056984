import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Reorder as ReorderIcon,
    Visibility as ViewIcon,
} from "@material-ui/icons";
import { Grid, IconButton, LinearProgress, Typography } from "@material-ui/core";
import _ from "underscore";
import useStyles from "./useStyles";
import useAPI from "../../../../shared/useapi";
import FormattedDate from "../../../../shared/formattedDate";
import { move } from "../dataservice";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import { Slot } from "../../../../shared/slotmachine/index";
import ActionMenu from "../../../../shared/actionmenu";
import ContentHolder from "../../../../shared/contentHolder";
import Header from "../../../../shared/header";
import Search from "../../../../shared/search";
import AuthorizedButton from "../../../../shared/authentication/authorizedbutton";
import withResources from "../../../../shared/textresources/withresources";

const Overview = withResources(
    ({
        getResource,
        asset,
        data,
        searchParams,
        onSearch,
        onMove,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
        match: {
            params: { assetId, library },
        },
    }) => {
        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
        const [localData, setLocalData] = useState([]);
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);
        const { invoke } = useAPI((assetMediaId, currentPosition, newPosition) =>
            move(assetId, library, assetMediaId, currentPosition, newPosition)
        );
        const isLoading = JSON.stringify(data) !== JSON.stringify(localData);
        const searchIsActive = searchParams.searchText !== "" && searchParams.searchText !== null;

        useEffect(() => {
            if (setLocalData) {
                setLocalData(data ? [...data] : []);
            }
        }, [data]);

        const updateLocalData = (oldIndex, newIndex) => {
            const newLocalData = [...localData];
            const itemCopy = { ...localData[oldIndex] };

            newLocalData.splice(oldIndex, 1);
            newLocalData.splice(newIndex, 0, itemCopy);
            setLocalData(newLocalData);
        };

        const onSortEnd = async ({ oldIndex, newIndex }) => {
            updateLocalData(oldIndex, newIndex);

            const assetMediaId = localData[oldIndex].id;
            if (await invoke(assetMediaId, oldIndex, newIndex)) {
                onMove();
            }
        };

        const shouldCancelStart = () => {
            return searchIsActive;
        };

        const getRequiredOperation = (operationName) => {
            if (!asset) {
                return operationName;
            }

            return {
                name: operationName,
                companyId: asset.companyId,
                assetCollectionId: asset.assetCollectionIds,
            };
        };

        const DragHandle = SortableHandle(() => {
            const classes = useStyles();
            return (
                <IconButton
                    size="small"
                    aria-label={getResource("Asset.media.dragLabel", "Drag asset")}
                    className={clsx(classes.dragHandle, searchIsActive && "--disabled")}
                    disableRipple
                    tabIndex={searchIsActive ? -1 : undefined}
                >
                    <ReorderIcon />
                </IconButton>
            );
        });

        const SortableItem = SortableElement(({ mediaItem }) => {
            const classes = useStyles();
            return (
                <li className={clsx(classes.item, "sortableItem")}>
                    <DragHandle />
                    <img
                        className={classes.image}
                        src={`/api/assets/asset/${assetId}/media/${library}/${mediaItem.id}/view?imageSize=MediaLibrary&v=${mediaItem.fileId}`}
                        alt=""
                        draggable="false"
                    />
                    <div className={classes.itemBar}>
                        <div className={classes.titleAndDate}>
                            <Typography
                                component="h2"
                                variant="body1"
                                className={classes.title}
                                title={mediaItem.description}
                            >
                                {mediaItem.description}
                            </Typography>
                            <Typography component="p" variant="body2" className={classes.date}>
                                <FormattedDate date={mediaItem.date} />
                            </Typography>
                        </div>
                        <ActionMenu
                            className={clsx(classes.actionMenu, "actionMenu")}
                            actions={[
                                {
                                    text: getResource("Asset.media.viewButton", "View"),
                                    icon: <ViewIcon />,
                                    itemId: mediaItem.id,
                                    requiredOperation: getRequiredOperation(
                                        `asset-media-view-${library.toLowerCase()}`
                                    ),
                                    onClick: () =>
                                        window.open(
                                            `/api/assets/asset/${assetId}/media/${library}/${mediaItem.id}/view?imageSize=FullImage&v=${mediaItem.fileId}`,
                                            "_blank"
                                        ),
                                },
                                {
                                    text: getResource("Asset.media.editButton", "Edit"),
                                    icon: <EditIcon />,
                                    itemId: mediaItem.id,
                                    requiredOperation: getRequiredOperation(
                                        `asset-media-update-${library.toLowerCase()}`
                                    ),
                                    onClick: () => onEdit(mediaItem.id),
                                },
                                {
                                    text: getResource("Asset.media.deleteButton", "Delete"),
                                    icon: <DeleteIcon />,
                                    itemId: mediaItem.id,
                                    requiredOperation: getRequiredOperation(
                                        `asset-media-delete-${library.toLowerCase()}`
                                    ),
                                    onClick: () => onDelete(mediaItem),
                                },
                            ]}
                        />
                    </div>
                </li>
            );
        });

        const SortableGrid = SortableContainer(({ items }) => {
            const classes = useStyles();
            return items ? (
                <ol className={classes.grid}>
                    {items.map((mediaItem, index) => (
                        <SortableItem mediaItem={mediaItem} index={index} key={mediaItem.id} />
                    ))}
                </ol>
            ) : null;
        });

        var assetName =
            asset &&
            ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                `${asset.manufacturerName} / ${asset.assetModel.name}`);

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Asset.media.header.title",
                        "Media library for {assetName}",
                        { assetName }
                    )}
                    breadcrumb={asset.assetCategories}
                    renderActions={() => {
                        return (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                requiredOperation={getRequiredOperation(
                                    `asset-media-create-${library.toLowerCase()}`
                                )}
                                buttonText={getResource("Asset.media.newButton", "Add media")}
                            />
                        );
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Search
                            onSearch={onSearchDebounced}
                            searchParams={searchParams}
                            labelResourceKey="Asset.media.search.description"
                            labelDefaultValue="Description"
                        />

                        <LinearProgress style={{ visibility: isLoading ? "visible" : "hidden" }} />

                        <SortableGrid
                            items={localData}
                            axis="xy"
                            shouldCancelStart={shouldCancelStart}
                            onSortEnd={onSortEnd}
                            useDragHandle
                            helperClass="--is-moving"
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Slot name="asset-summary" list={true} asset={asset} />
                    </Grid>
                </Grid>
            </ContentHolder>
        );
    }
);

export default Overview;
