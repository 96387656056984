import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import useStyles from "./useStyles";
import withResources from "../textresources/withresources";
import AddButton from "../addButton";

const AddRowWithAutocompleteStatic = withResources((props) => {
    const { disabled, buttonText, renderAutocompleteStatic } = props;

    const thisComponentRef = useRef(null);
    const classes = useStyles();

    const handleButtonClick = () => {
        thisComponentRef.current.querySelector(".autoCompleteSelect").click();
    };

    return (
        <div className={classes.root} ref={thisComponentRef}>
            <AddButton
                handleClick={() => !disabled && handleButtonClick()}
                disabled={Boolean(disabled)}
                buttonText={buttonText}
                className={clsx(classes.addButton, "e2e_addRowButton")}
            />
            <div
                className={clsx(
                    classes.autocompleteWrapper,
                    "--is-visible"
                )}
            >
                {renderAutocompleteStatic()}
            </div>
        </div>
    );
});

AddRowWithAutocompleteStatic.propTypes = {
    disabled: PropTypes.bool,
    buttonText: PropTypes.string,
    renderAutocompleteStatic: PropTypes.func.isRequired,
};

export default AddRowWithAutocompleteStatic;
