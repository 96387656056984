const handleLoad = async (setItemTree, setIsLoading, getChildren) => {
    await setIsLoading(true);
    const rootChildren = await getChildren();
    const jsonRootChildren = await rootChildren.json();
    const newItemTree = {
        id: 0,
        name: "root",
        children: jsonRootChildren,
    };
    setItemTree(newItemTree);
    setIsLoading(false);
};

export default handleLoad;
