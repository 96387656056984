import Search from "../drilldown/search";
import PropTypes from "prop-types";
import React, { useState } from "react";
import handleSearchPress from "../drilldown/utilities/search/handleSearchPress";

/**
 * Implementation of the (Drilldown) Search component, that keeps the state of autoCompleteResults isolated to prevent
 * re-rendering while typing, which causes the Popper component to reposition every time
 */
const DrillDownInputSearch = ({ handleSearchOptionChange, itemName, filter }) => {
    const [autoCompleteResults, setAutoCompleteResults] = useState([]);

    const handleSearchKeyPress = async (searchText) => {
        await handleSearchPress(searchText, setAutoCompleteResults, filter);
    };

    return (
        <Search
            itemName={itemName}
            autoCompleteResults={autoCompleteResults}
            handleSearchKeyPress={handleSearchKeyPress}
            handleSearchOptionChange={handleSearchOptionChange}
            isWhite
        />
    );
};

DrillDownInputSearch.propTypes = {
    itemName: PropTypes.string,
    handleSearchOptionChange: PropTypes.func.isRequired,
};

export default DrillDownInputSearch;
