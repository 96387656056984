import React from "react";
import { Section } from "../shell";
import AssetAvailability from "./assetAvailability";
import Today from "@material-ui/icons/Today";
import withResources from "../../shared/textresources/withresources";
import ScheduledEvent from "./scheduledEvent";
import Timeline from "./timeline";
import EventList from "./eventList";

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Planning.assetAvailability", "Availability"),
            href: "/planning/asset-availability",
            render: AssetAvailability,
            requiredOperation: "assets-availability-view",
        },
        {
            title: getResource("Planning.calendar", "Calendar"),
            href: "/planning/calendar",
            render: ScheduledEvent,
            requiredOperation: "scheduled-events-view",
            hideInMenu: true,
        },
        {
            title: getResource("Planning.timeline", "Timeline"),
            href: "/planning/timeline",
            render: Timeline,
            requiredOperation: "scheduled-events-view",
        },
        {
            title: getResource("Planning.events", "Events"),
            href: "/planning/events",
            path: "/planning/events/:referenceType?/:referenceId?",
            render: EventList,
            requiredOperation: "scheduled-events-view",
        },
    ];

    return [
        <Section
            key="Planning"
            title={getResource("Planning.title", "Planning")}
            icon={Today}
            href="/planning"
            children={children}
        />,
    ];
});
