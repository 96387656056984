import React, { useEffect, useState } from "react";
import { Grid, Slider } from "@material-ui/core";
import useStyles from "./useStyles";
import NrInput from "../nrInput";

const RangeSlider = (props) => {
    const {
        absoluteMin,
        absoluteMax,
        defaultMin,
        defaultMax,
        marks,
        questionIndex,
        handleRangeSliderChange,
        ariaLabel,
        disabled,
    } = props;

    const classes = useStyles();

    const [min, setMin] = useState(defaultMin);
    const [max, setMax] = useState(defaultMax);
    const [inputMin, setInputMin] = useState(defaultMin);
    const [inputMax, setInputMax] = useState(defaultMax);

    useEffect(() => {
        setInputMin(min);
    }, [min]);

    useEffect(() => {
        setInputMax(max);
    }, [max]);

    const handleMinInputBlurOrEnter = () => {
        const nrInputMin = Number(inputMin);

        if (nrInputMin > max || nrInputMin < absoluteMin) {
            setInputMin(min);
            return;
        }

        setMin(nrInputMin);
        handleRangeSliderChange(questionIndex, nrInputMin, max);
    };

    const handleMaxInputBlurOrEnter = () => {
        const nrInputMax = Number(inputMax);

        if (nrInputMax < min || nrInputMax > absoluteMax) {
            setInputMax(max);
            return;
        }

        setMax(nrInputMax);
        handleRangeSliderChange(questionIndex, min, nrInputMax);
    };

    const inputAttributes = {
        step: 1,
        min: absoluteMin,
        max: absoluteMax,
        type: "number",
        title: ariaLabel + " minimum",
    };

    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} className={classes.inputItemLeft}>
                <NrInput
                    disabled={disabled}
                    value={inputMin}
                    onChange={(e) => setInputMin(e.target.value)}
                    onBlur={handleMinInputBlurOrEnter}
                    inputAttributes={inputAttributes}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleMinInputBlurOrEnter();
                        }
                    }}
                />
            </Grid>
            <Grid item xs={8} className={classes.sliderItem}>
                <Slider
                    disabled={disabled}
                    value={[min, max]}
                    step={5}
                    min={absoluteMin}
                    max={absoluteMax}
                    marks={marks}
                    valueLabelDisplay="auto"
                    getAriaLabel={(index) =>
                        index === 0 ? ariaLabel + " minimum" : ariaLabel + " maximum"
                    }
                    onChange={(e, newValue) => {
                        setMin(newValue[0]);
                        setMax(newValue[1]);
                    }}
                    onChangeCommitted={(e, newValue) =>
                        handleRangeSliderChange(questionIndex, newValue[0], newValue[1])
                    }
                />
            </Grid>
            <Grid item xs={2} className={classes.inputItemRight}>
                <NrInput
                    disabled={disabled}
                    value={inputMax}
                    onChange={(e) => setInputMax(e.target.value)}
                    onBlur={handleMaxInputBlurOrEnter}
                    inputAttributes={inputAttributes}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleMaxInputBlurOrEnter();
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default RangeSlider;
