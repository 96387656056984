import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import Merge from "./merge";
import Overview from "./overview";
import Review from "./review";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useDialog from "../../../shared/usedialog";
import EditQuestionnaire from "../../valuation/assetmodelquestionnaire/edit";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-models", {
        page: 0,
        pageSize: defaultPageSize,
        name: null,
        manufacturerId: null,
        assetCategory: { id: null, name: null },
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.name,
                searchParams.manufacturerId,
                searchParams.assetCategory.id,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [searchParams]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);
    const {
        open: handleMerge,
        close: handleCloseMerge,
        visible: mergeToggled,
        args: mergeValues,
    } = useDialog(invoke);
    const {
        open: handleEditQuestionnaire,
        close: handleCloseEditQuestionnaire,
        visible: editQuestionnaireToggled,
        args: editQuestionnaireArgs,
    } = useDialog(invoke);
    const {
        open: handleReview,
        close: handleCloseReview,
        visible: reviewToggled,
        args: reviewId,
    } = useDialog(invoke);

    function handleSearch(name, manufacturerId, assetCategory) {
        setSearchParams({ ...searchParams, name, manufacturerId, assetCategory, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="asset-models-view"
                path="/library/asset-models"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            loading={loading}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onMerge={handleMerge}
                            onEditQuestionnaire={handleEditQuestionnaire}
                            onReview={handleReview}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />
            <Add open={addToggled} onClose={handleCloseAdd} searchParams={searchParams} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
            <Delete id={deleteId} open={deleteToggled} onClose={handleCloseDelete} />
            <EditQuestionnaire
                assetModelId={editQuestionnaireArgs && editQuestionnaireArgs.assetModelId}
                manufacturerName={editQuestionnaireArgs && editQuestionnaireArgs.manufacturerName}
                assetModelName={editQuestionnaireArgs && editQuestionnaireArgs.assetModelName}
                assetCategoryNames={
                    editQuestionnaireArgs && editQuestionnaireArgs.assetCategoryNames
                }
                assetCategoryId={editQuestionnaireArgs && editQuestionnaireArgs.assetCategoryId}
                open={editQuestionnaireToggled}
                onClose={handleCloseEditQuestionnaire}
            />
            <Merge
                id={mergeValues && mergeValues.id}
                version={mergeValues && mergeValues.version}
                name={mergeValues && mergeValues.name}
                open={mergeToggled}
                onClose={handleCloseMerge}
            />
            <Review
                id={reviewId}
                open={reviewToggled}
                onClose={handleCloseReview}
                onMerge={handleMerge}
            />
        </React.Fragment>
    );
};
