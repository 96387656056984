import React from "react";
import useStyles from "./useStyles";

const Status = ({ text, color }) => {
    const classes = useStyles();

    if (text == null) {
        return null;
    }

    return <div className={`${classes.status} ${color}`}>{text}</div>;
};

export default Status;
