import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { KeyboardDateTimePicker, DateTimePicker } from "@material-ui/pickers";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import LocalizationContext from "./localization/localizationcontext";
import { formatDate } from "./formattedDate";

const WtcDateTimePicker = ({
    name,
    onChange,
    errors,
    noMargin,
    disabled,
    value,
    disallowKeyboard,
    ...props
}) => {
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (date) => {
        if (isValidDate(date)) {
            var utcDate = zonedTimeToUtc(date, selectedTimezone);
            onChange({ target: { name: name, type: "datepicker", value: utcDate } });
        }
    };

    const isValidDate = (dateString) => {
        const d = new Date(dateString);
        return d instanceof Date && !isNaN(d);
    };

    const error = errors && name in errors;
    const helperText = errors && errors[name];

    const currentTime = formatDate(
        new Date(),
        selectedUiCulture,
        selectedTimezone,
        false,
        true,
        false,
        true
    );
    const useAmPm = currentTime.includes("AM") || currentTime.includes("PM");

    var dateTimePickerProps = {
        ...props,
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        autoOk: true,
        format: "dd-MM-yyyy HH:mm",
        error,
        helperText,
        name,
        margin: noMargin ? "none" : "normal",
        variant: "inline",
        inputVariant: "outlined",
        InputLabelProps: { shrink: true },
        fullWidth: true,
        onChange: handleChange,
        disabled: disabled,
        ampm: useAmPm,
        value: isValidDate(value) ? utcToZonedTime(value, selectedTimezone) : null,
    };

    return disallowKeyboard ? (
        <DateTimePicker {...dateTimePickerProps} />
    ) : (
        <KeyboardDateTimePicker {...dateTimePickerProps} />
    );
};

WtcDateTimePicker.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    noMargin: PropTypes.bool,
    disabled: PropTypes.bool,
    disallowKeyboard: PropTypes.bool,
};

export default WtcDateTimePicker;
