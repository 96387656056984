import React, { Fragment, useContext, useState } from "react";
import { getAllForAssetContext as getAssetModels } from "./../../library/assetmodels/dataservice";
import { getAllForAssetContext as getManufacturers } from "./../../library/manufacturers/dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import useAPI from "../../../shared/useapi";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import { filter, getChildren, getParents, getPath } from "../../library/assetcategories/dataservice";
import DrillDownInput from "../../../shared/drilldowninput";
import { getWhereUserHasOperation as getCompanies } from "../../security/companies/dataservice";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const { data: allManufacturers } = useAPI(() => getManufacturers(null, null, null), true, [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    const { data: allAssetModels } = useAPI(
        () => getAssetModels(null, searchValues.manufacturerId, null, null),
        true,
        [searchValues.manufacturerId, companyScope.id, assetCollectionScope.id]
    );


    const { data: companies } = useAPI(() => getCompanies(["use-as-financier"]), true);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.assetModelId, values.manufacturerId, values.assetCategory, values.financierCompanyIds, values.order);
    }

    function handleModelSelection(event) {
        const selectedAsset = allAssetModels.items.filter(
            (item) => item.id === event.target.value
        )[0];

        const manufacturerId = selectedAsset
            ? selectedAsset.manufacturerId
            : searchValues.manufacturerId;

        handleValuesChanged({
            ...searchValues,
            assetModelId: event.target.value,
            manufacturerId: manufacturerId,
        });
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    autoFocus
                    labelResourceKey="Asset.assets.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={searchValues.searchText ? searchValues.searchText : ""}
                    AdornmentIcon={<SearchIcon />}
                    clearable
                    className="e2e_pagedListGenericSearchField"
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                />,
                <DrillDownInput
                    name="assetCategory"
                    itemName={getResource(
                        "Library.assetModels.editForm.assetCategoryItemName",
                        "category"
                    )}
                    label={getResource("Library.assetModels.editForm.assetCategoryField", "Category")}
                    getParents={getParents}
                    getChildren={getChildren}
                    getPath={getPath}
                    filter={filter}
                    value={searchValues.assetCategory}
                    setValue={(e) => {
                        handleValuesChanged({
                            ...searchValues,
                            assetCategory: e.target.value,
                        })
                    }}
                />,
                <AutoCompleteStatic
                    label={getResource("Asset.assets.search.manufacturerField", "Manufacturer")}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            manufacturerId: e.target.value,
                            assetModelId: "",
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource("Asset.assets.search.modelLabel", "Model")}
                    value={searchValues.assetModelId}
                    options={allAssetModels && allAssetModels.items}
                    getOptionLabel={(option) => (
                        <Fragment>
                            {option.manufacturerName} - {option.name}
                        </Fragment>
                    )}
                    onSelection={(event) => handleModelSelection(event)}
                />,
                ((companies && companies.length) ? <AutoCompleteStatic
                    label={getResource(
                        "Asset.assets.search.financierCompanyLabel",
                        "Financier"
                    )}
                    value={searchValues.financierCompanyIds}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            financierCompanyIds: e.target.value
                        })}
                    options={companies}
                    multiple
                /> : null)
            ]}
            sortField={
                < AutoCompleteStatic
                    disableClearable
                    label={getResource("Asset.assets.search.sortLabel", "Sort by")}
                    value={searchValues.order || "Default"}
                    options={
                        [
                            "Default",
                            "NameAscending",
                            "NameDescending",
                            "ManufacturerAscending",
                            "ManufacturerDescending",
                            "ModelAscending",
                            "ModelDescending",
                            "RegistrationAscending",
                            "RegistrationDescending",
                            "ConstructionDateAscending",
                            "ConstructionDateDescending",
                            "InternalIdAscending",
                            "InternalIdDescending",
                            "SerialAscending",
                            "SerialDescending",
                            "AssetIdAscending",
                            "AssetIdDescending"
                        ].map(x => ({ id: x }))
                    }
                    getOptionLabel={(option) => (
                        getResource(`Asset.assets.search.orderBy.${option.id}`, option.id)
                    )}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            order: e.target.value,
                        })}
                />
            }
        />
    );
});

export default Search;
