import React from "react";
import PropTypes from "prop-types";
import useStyles from "./useStyles";
import clsx from "clsx";
import Header from "./header";
import NameAndIcon from "./nameAndIcon";

const Item = (props) => {
    const { item, parentOfItem, openItem, closeItem, currentlyVisibleItemId } = props;

    const classes = useStyles();

    return (
        <li
            key={item.id}
            className={clsx(
                classes.item,
                "item",
                item.open && "--is-open",
                item.parentId === currentlyVisibleItemId && "--is-visible"
            )}
            onClick={(e) => {
                e.stopPropagation();
                openItem(e, item);
            }}
        >
            <NameAndIcon name={item.name} />

            {item.open ? (
                <div
                    className={clsx(classes.ulWrapper, "ulWrapper")}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ul className="childItems">
                        <Header
                            {...props}
                            id={item.id}
                            title={item.name}
                            isSelectable={
                                typeof item.isSelectable === "undefined" || item.isSelectable
                            }
                            handleBack={(e) => closeItem(e, item, parentOfItem)}
                        />

                        {item.children &&
                            item.children.map((childItem) => (
                                <Item
                                    {...props}
                                    item={childItem}
                                    parentOfItem={item}
                                    key={childItem.id}
                                />
                            ))}
                    </ul>
                </div>
            ) : null}
        </li>
    );
};

Item.propTypes = {
    item: PropTypes.object.isRequired,
    parentOfItem: PropTypes.object.isRequired,
    openItem: PropTypes.func.isRequired,
    closeItem: PropTypes.func.isRequired,
    currentlyVisibleItemId: PropTypes.number,
};

export default Item;
