import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, id, version, assetName, open, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(id, version)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={() => onClose(false)}
            headerText={getResource(
                "Marketplace.advertisement.delete.header",
                "Delete advertisement?"
            )}
            buttonText={getResource("Marketplace.advertisement.delete.okButton", "Delete")}
        >
            {getResource(
                "Marketplace.advertisement.delete.deleteMessage",
                "The advertisement for {assetName} will be deleted",
                { assetName }
            )}
        </Confirmation>
    );
});
