import React from "react";
import { Section } from "../shell";
import Appraisal from "./appraisal";
import withResources from "../../shared/textresources/withresources";
import { AppraisalIcon } from "../../shared/customIcons";

export default withResources(({ getResource }) => {
    const appraisalChildren = [
        {
            title: getResource("Valuation.appraisalsTitle", "Appraisals"),
            href: "/appraisal/appraisal",
            render: Appraisal,
            requiredOperationAtLeastOneOf: ["appraisals-view-draft", "appraisals-view-published"],
        },
    ];

    return (
        <Section
            key="appraisal"
            title={getResource("Appraisal.title", "Appraisal")}
            requiresAuthentication
            icon={AppraisalIcon}
            href="/appraisal"
            children={appraisalChildren}
        />
    );
});
