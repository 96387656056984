import interceptedFetch from "../interceptedFetch";

async function get() {
    return await interceptedFetch("/api/culture");
}

async function getUI() {
    return await interceptedFetch("/api/culture/UI");
}

export { get, getUI };
