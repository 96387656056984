import React, { Fragment } from "react";
import ImageGallery from "react-image-gallery";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import useAuthorization from "../../../shared/authentication/useauthorization";
import Widget from "../../../shared/widget";
import Add from "./add";
import useDialog from "../../../shared/usedialog";
import useStyles from "../../../shared/mediaWidget/useStyles";

const ComponentMediaWidget = withResources(({ getResource, component, library, title }) => {
    const classes = useStyles();

    const { data: media, loading, invoke } = useAPI(
        () => getAll(component.id, library, null),
        true
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);

    const images =
        media &&
        media.map((media) => ({
            original: `/api/assets/component/${component.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetImage&v=${media.fileId}`,
            thumbnail: `/api/assets/component/${component.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetThumb&v=${media.fileId}`,
            fullscreen: `/api/assets/component/${component.id}/media/${library}/${media.id}/view?imageSize=MediaLibraryWidgetFullScreen&v=${media.fileId}`,
            description: media.description,
        }));

    const renderThumbnail = (item) => {
        return (
            <div
                className="image-gallery-thumbnail-inner"
                style={{
                    background: `url('${item.thumbnail}') 50% 50%`,
                }}
            >
                {item.thumbnailLabel && (
                    <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>
                )}
            </div>
        );
    };

    return (
        <Fragment>
            <Widget
                headerTitle={title}
                headerLink={`/assets/component-media/${component.id}/${library.toLowerCase()}`}
                headerLinkTitle={getResource(
                    "Asset.component.media.widget.chevronAlt",
                    `Manage media items`
                )}
                renderContent={() => {
                    return (
                        <ImageGallery
                            lazyLoad
                            items={images}
                            showPlayButton={false}
                            additionalClass={classes.imageGallery}
                            renderThumbInner={renderThumbnail}
                        />
                    );
                }}
                loading={loading}
                hasContent={images !== null && images.length > 0}
                noDataText={getResource(
                    "Asset.component.media.widget.noDataText",
                    "There are no media items for this component yet"
                )}
                addButtonText={getResource("Asset.component.media.widget.noDataButtonText", "Add")}
                onAddButtonClick={handleAdd}
                addButtonRequiredOperation={{
                    name: `component-media-create-${library.toLowerCase()}`,
                    companyId: component.companyId,
                    assetCollectionId: component.assetCollectionIds,
                }}
            />
            <Add
                componentId={component.id}
                library={library}
                open={addToggled}
                onClose={handleCloseAdd}
            />
        </Fragment>
    );
});

export default ({ component, library, title }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: `component-media-view-${library.toLowerCase()}`,
        companyId: component.companyId,
        assetCollectionId: component.assetCollectionIds,
    });

    if (isLoading || !isAuthorized) {
        return null;
    }

    return <ComponentMediaWidget component={component} library={library} title={title} />;
};
