import interceptedFetch from "../../../shared/interceptedFetch";

async function get(referenceType, referenceId) {
    const url = `/api/assets/${referenceType}/${referenceId}/expected`;

    return await interceptedFetch(url);
}

async function getCascading(referenceType, referenceId) {
    const url = `/api/assets/${referenceType}/${referenceId}/expected/cascading`;

    return await interceptedFetch(url);
}

export { get, getCascading };
