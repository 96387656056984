import React, { Component } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";

class ModalMessage extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            size: "tiny",
            positive: undefined,
            negative: undefined,
            header: "",
            content: "",
            positiveButton: {
                content: "OK",
            },
            negativeButton: {
                content: "Cancel",
            },
        };
    }

    show(size, header, content, positive, positiveContent) {
        this.setState({
            open: true,
            size: size,
            header: header,
            content: content,
            positive: positive,
            positiveButton: { content: positiveContent || "OK" },
            negativeButton: { content: "Cancel" },
        });
    }

    showCustomButtons(size, header, content, buttons, positive, negative) {
        this.setState({
            open: true,
            size: size,
            header: header,
            content: content,
            positive: positive,
            negative: negative,
            positiveButton: buttons[0],
            negativeButton: buttons[1],
        });
    }

    showWarning(size, header, content, close) {
        this.setState({
            open: true,
            size: size,
            header: header,
            content: content,
            negative: close,
            positiveButton: null,
            negativeButton: { content: "Close" },
        });
    }

    positive() {
        this.close();
        this.state.positive();
    }

    negative() {
        this.close();

        if (typeof this.state.negative !== "undefined") this.state.negative();
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        const { open, size, header, content, positiveButton, negativeButton } = this.state;

        return (
            <div>
                <Dialog size={size} open={open} onClose={this.close.bind(this)}>
                    <DialogTitle>{header}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{content}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {positiveButton && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.positive.bind(this)}
                            >
                                {positiveButton.content}
                            </Button>
                        )}
                        {negativeButton && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={this.negative.bind(this)}
                            >
                                {negativeButton.content}
                            </Button>
                        )}
                        <br />
                        <br />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ModalMessage;
