import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Overview from "./overview";
import Delete from "./delete";
import { getAll } from "./dataservice";
import { get as getAsset } from "../asset/dataservice";

import useDialog from "../../../shared/usedialog";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";

const MediaLibrary = (props) => {
    const assetId = props.match.params["assetId"];
    const library = props.match.params["library"];

    const { defaultDebounceInterval } = useContext(ConfigurationContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-media", {
        searchText: null,
    });

    const { data: asset } = useAPI(() => getAsset(assetId), true, [assetId]);

    const { invoke, data } = useAPI(() => getAll(assetId, library, searchParams.searchText), true, [
        searchParams,
        assetId,
        library,
    ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);

    function onSearch(searchText) {
        setSearchParams({ ...searchParams, searchText });
    }

    return (
        asset && (
            <React.Fragment>
                <Overview
                    data={data}
                    asset={asset}
                    searchParams={searchParams}
                    onSearch={onSearch}
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onMove={invoke}
                    defaultDebounceInterval={defaultDebounceInterval}
                    {...props}
                />

                <Add
                    assetId={assetId}
                    library={library}
                    open={addToggled}
                    onClose={handleCloseAdd}
                />
                <Edit
                    assetId={assetId}
                    library={library}
                    id={editId}
                    open={editToggled}
                    onClose={handleCloseEdit}
                />
                <Delete
                    assetId={assetId}
                    library={library}
                    id={deleteArgs && deleteArgs.id}
                    isLinkedToAdvertisement={deleteArgs && deleteArgs.isLinkedToAdvertisement}
                    open={deleteToggled}
                    onClose={handleCloseDelete}
                />
            </React.Fragment>
        )
    );
};

export default () => {
    return (
        <PrivateRoute
            requiredOperation={(match) => `asset-media-view-${match.params.library.toLowerCase()}`}
            path="/assets/asset-media/:assetId(\d+)/:library(\w+)"
            render={(props) => <MediaLibrary {...props} />}
        />
    );
};
