import React from "react";
import useAPI from "../../../shared/useapi";
import { getIsReferenced, del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, onDelete, onClose, open, itemId, version }) => {
    if (!open) {
        return null;
    }

    const { data: isReferenced } = useAPI(() => getIsReferenced(itemId), true, [itemId]);

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(itemId, version)) {
            onDelete();
            onClose();
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleDelete}
            handleClose={onClose}
            headerText={getResource("Library.assetCategories.delete.header", "Delete category?")}
            buttonText={getResource("Library.assetCategories.delete.okButton", "Delete")}
            buttonHidden={isReferenced}
        >
            {isReferenced && (
                <React.Fragment>
                    <ResourcedText
                        resourceKey="Library.assetCategories.delete.inUseMessage"
                        defaultValue="Category (or one of its children) already in use; first delete all items referencing this category"
                    />
                </React.Fragment>
            )}
            {!isReferenced && (
                <React.Fragment>
                    <ResourcedText
                        resourceKey="Library.assetCategories.delete.deleteMessage"
                        defaultValue="The category and all its child categories will be deleted"
                    />
                </React.Fragment>
            )}
        </Confirmation>
    );
});
