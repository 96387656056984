import React from "react";
import useAPI from "../../../shared/useapi";
import { deactivate } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, onClose, open, companyId, companyName }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(deactivate);

    const handleActivate = async () => {
        if (await invoke(companyId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleActivate}
            handleClose={onClose}
            headerText={getResource("Security.companies.archive.header", "Archive company?")}
            buttonText={getResource("Security.companies.archive.okButton", "Archive")}
        >
            {getResource(
                "Security.companies.archive.message",
                "Company '{companyName}' will be archived. Are you sure?",
                { companyName: companyName }
            )}
        </Confirmation>
    );
});
