import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(name, missingLanguages, hasValuationCorrection, page, pageSize) {
    const params = {
        page,
        pageSize,
        name,
        missingLanguages,
        hasValuationCorrection,
    };

    return await interceptedFetch(
        `/api/assets/assetCollection?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function add(values) {
    return await interceptedFetch(`/api/assets/assetCollection`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId, version) {
    return await interceptedFetch(`/api/assets/assetCollection/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({ version })),
    });
}

async function get(id) {
    const url = `/api/assets/assetCollection/${id}`;

    return await interceptedFetch(url);
}

async function getValuationCorrection(id) {
    const url = `/api/assets/assetCollection/${id}/valuationCorrection`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/assets/assetCollection/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/assets/assetCollection/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getWhereUserHasOperation(operations) {
    const url = `/api/assets/assetCollection/my/assignedOperation/${operations.join("|")}`;

    return await interceptedFetch(url);
}

async function getByCompanyWhereUserHasOperation(companyId, operation) {
    const url = `/api/assets/assetCollection/${companyId}/assignedOperation/${operation}`;

    return await interceptedFetch(url);
}

async function updateValuationCorrection(id, values) {
    return await interceptedFetch(`/api/assets/assetCollection/${id}/valuationCorrection`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getRoles() {
    const url = `/api/security/assetCollection/roles`;

    return await interceptedFetch(url);
}

async function updateRoles(values) {
    return await interceptedFetch(`/api/security/assetCollection/roles`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export {
    getAll,
    add,
    del,
    get,
    getValuationCorrection,
    getIsReferenced,
    update,
    getWhereUserHasOperation,
    getByCompanyWhereUserHasOperation,
    updateValuationCorrection,
    getRoles,
    updateRoles,
};
