import React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import withResources from "./textresources/withresources";

export default withResources(
    ({
        getResource,
        loading,
        handleAction,
        handleClose,
        headerText,
        buttonText,
        cancelButtonText,
        children,
        open,
        cancelHidden,
        buttonHidden,
        actionDisabled,
        classes,
    }) => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={classes}
            >
                <DialogTitle id="alert-dialog-title">{headerText}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    {!cancelHidden && (
                        <Button onClick={handleClose} autoFocus id="e2e_confirmation_cancel">
                            {cancelButtonText
                                ? cancelButtonText
                                : getResource("Shell.confirmation.cancel.buttonText", "Cancel")}
                        </Button>
                    )}
                    {!buttonHidden && (
                        <Button
                            disabled={actionDisabled || loading}
                            onClick={handleAction}
                            color="primary"
                            variant="contained"
                            id="e2e_confirmation_action"
                        >
                            {buttonText}
                            {loading && <CircularProgress />}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
);
