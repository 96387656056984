import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    grid: {
        minHeight: 55,
    },

    questionItem: {
        display: "flex",
        flexFlow: "row nowrap",
    },

    visibilityIcon: {
        display: "flex",
        alignItems: "flex-start",
        marginLeft: "-9px",
        padding: 9,

        "&.--invisible": {
            opacity: 0.7,
        },
    },

    label: {
        display: "inline-block",
        padding: "9px 9px 9px 0",

        "&.--invisible": {
            opacity: 0.7,
        },
    },

    correctionScore: {
        marginLeft: 5,
        fontWeight: "bold",
    },

    sliderItem: {
        paddingLeft: 10,
        paddingRight: 20,
    },

    inputItem: {
        textAlign: "right",
    },
}));

export default useStyles;
