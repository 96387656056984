import React, { useState, useEffect, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import useAPI from "../../../shared/useapi";
import { updateRoles, getRoles as getCompanyRoles } from "./dataservice";
import { getAll as getAllRoles } from "../roles/dataservice";
import { getAll as getAllUsers } from "../users/dataservice";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import AddRowWithAutocompleteStatic from "../../../shared/addRowWithAutocompleteStatic";
import FormTable from "../../../shared/formTable";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(updateRoles);

    const { data } = useAPI(() => getCompanyRoles(), true);
    const { data: allRoles } = useAPI(() => getAllRoles(null, null, 2, null, null), true);
    const { data: allUsers } = useAPI(() => getAllUsers(null, null, true, null, null), true);

    const [roleAssignments, setRoleAssignments] = useState();

    useEffect(() => {
        data && setRoleAssignments(data);
    }, [data]);

    const onSubmit = async () => {
        var data = { roleAssignments };
        if (await invoke(data)) {
            onClose();
        }
    };

    const onValidate = () => {
        return {};
    };

    const handleRolesUpdate = (i, roles) => {
        var copy = [
            ...roleAssignments.slice(0, i),
            {
                ...roleAssignments[i],
                roles: roles,
            },
            ...roleAssignments.slice(i + 1),
        ];
        setRoleAssignments(copy);
    };

    const addRoleAssignment = (userId) => {
        if (userId) {
            const user = activeUsers.find((x) => x.id === userId);
            const copy = roleAssignments.concat({
                userId: userId,
                userFirstName: user.firstName,
                userLastName: user.lastName,
                userIsActive: user.isActive,
                roles: [],
            });
            setRoleAssignments(copy);
        }
    };

    const activeUsers =
        allUsers && allUsers.items && allUsers.items.filter((user) => user.isActive);
    const isLoaded = roleAssignments && allRoles && allUsers;
    const [isDirty, setIsDirty] = React.useState(false);

    return (
        <FormDrawer
            title={getResource("Security.companies.roles.title", "Company role assignments")}
            onClose={onClose}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ roleAssignments }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.companies.roles.okButton", "Save")}
                    hideOkButton={allUsers.length === 0}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={() => (
                        <Fragment>
                            {activeUsers.length === 0 && (
                                <Alert severity="warning">
                                    {getResource(
                                        "Security.companies.roles.noUsersUnderCompany",
                                        "There are no active users attached to this company or its parent companies."
                                    )}
                                </Alert>
                            )}

                            {roleAssignments && (
                                <FormTable>
                                    {roleAssignments.map((roleAssignment, i) => (
                                        <tr
                                            key={roleAssignment.userId}
                                            style={{
                                                opacity: roleAssignment.userIsActive ? "1" : "0.7",
                                            }}
                                        >
                                            <th scope="row" style={{ width: 200 }}>
                                                {roleAssignment.userFirstName}{" "}
                                                {roleAssignment.userLastName}
                                                {!roleAssignment.userIsActive &&
                                                    " (" +
                                                        getResource(
                                                            "Security.companies.roles.inactiveText",
                                                            "inactive"
                                                        ) +
                                                        ")"}
                                            </th>
                                            <td>
                                                <AutoCompleteStatic
                                                    options={allRoles && allRoles.items}
                                                    value={roleAssignment.roles}
                                                    multiple
                                                    isMedium
                                                    noMargin
                                                    noOptionsText={getResource(
                                                        "Security.companies.roles.noRolesAvailable",
                                                        "No roles available"
                                                    )}
                                                    fullWidth
                                                    onSelection={(e) =>
                                                        handleRolesUpdate(i, e.target.value)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </FormTable>
                            )}

                            <AddRowWithAutocompleteStatic
                                disabled={roleAssignments.length === activeUsers.length}
                                renderAutocompleteStatic={() => (
                                    <AutoCompleteStatic
                                        fullWidth
                                        isMedium
                                        label={getResource(
                                            "Security.companies.roles.addRoleAssignmentField",
                                            "Add role assignment"
                                        )}
                                        value=""
                                        onSelection={(e) => addRoleAssignment(e.target.value)}
                                        options={activeUsers.filter(
                                            (user) =>
                                                !roleAssignments.some((x) => x.userId === user.id)
                                        )}
                                    />
                                )}
                            />
                        </Fragment>
                    )}
                />
            )}
        </FormDrawer>
    );
});
