import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(quickScanId, page, pageSize) {
    const params = {
        quickScanId,
        page,
        pageSize,
    };

    const url = `/api/valuation/financialReport?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(financialReportId) {
    return await interceptedFetch(`/api/valuation/financialReport/${financialReportId}`);
}

async function purchase(values) {
    return await interceptedFetch("/api/valuation/financialReport/purchase", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(financialReportId) {
    return await interceptedFetch(`/api/valuation/financialReport/${financialReportId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, purchase, del };
