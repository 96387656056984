import React, { Fragment } from "react";
import withResources from "../textresources/withresources";

const AddNewItem = withResources(({ getResource, addNewItemLabel, label }) => (
    <strong>
        +{" "}
        {addNewItemLabel ? (
            addNewItemLabel
        ) : (
            <Fragment>
                {getResource("AutocompleteStatic.addNewItemText", "Add new {name}", {
                    name: label,
                })}
            </Fragment>
        )}
    </strong>
));

export default AddNewItem;
