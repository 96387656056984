import React, { useContext, Fragment, useEffect } from "react";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Overview from "./overview";
import Delete from "./delete";
import { getAll, getReasons, getStatuses } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";

export default ({ assetId, componentId, name, ...props }) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-work-orders", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        reason: null,
        status: null,
        assetModelId: null,
        manufacturerId: null,
    });

    const { data: reasons } = useAPI(() => getReasons(), true);
    const { data: statuses } = useAPI(() => getStatuses(), true);
    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.reason,
                searchParams.status,
                searchParams.page + 1,
                searchParams.pageSize,
                assetId,
                componentId,
                searchParams.assetModelId,
                searchParams.manufacturerId
            ),
        true,
        [
            searchParams.searchText,
            searchParams.reason,
            searchParams.status,
            searchParams.page,
            searchParams.pageSize,
            searchParams.assetModelId,
            searchParams.manufacturerId,
            companyScope.id,
            assetCollectionScope.id,
        ]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);

    function handleSearch(searchText, reason, status, assetModelId, manufacturerId) {
        setSearchParams({
            ...searchParams,
            searchText,
            reason,
            status,
            assetModelId,
            manufacturerId,
            page: 0,
        });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operation, row) {
        if (!companyScope.id && !row) {
            return operation;
        }

        return {
            name: operation,
            companyId: companyScope.id || (row ? row.companyId : null),
            assetCollectionId: assetCollectionScope.id || (row ? row.assetCollectionIds : null),
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <Fragment>
            <Overview
                data={data}
                searchParams={searchParams}
                availablePageSizes={availablePageSizes}
                loading={loading}
                getRequiredOperation={getRequiredOperation}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onSearch={handleSearch}
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                defaultDebounceInterval={defaultDebounceInterval}
                reasons={reasons}
                statuses={statuses}
                assetId={assetId}
                componentId={componentId}
                name={name}
                {...props}
            />

            <Add
                open={addToggled}
                onClose={handleCloseAdd}
                reasons={reasons}
                statuses={statuses}
                companyIdPreselected={companyScope.id}
                assetIdPreselected={assetId}
                componentIdPreselected={componentId}
                entityName={name}
            />

            <Edit
                open={editToggled}
                onClose={handleCloseEdit}
                reasons={reasons}
                statuses={statuses}
                id={editId}
            />

            <Delete
                id={deleteArgs && deleteArgs.id}
                summary={deleteArgs && deleteArgs.summary}
                open={deleteToggled}
                onClose={handleCloseDelete}
            />
        </Fragment>
    );
};
