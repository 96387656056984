import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, id, summary, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Planning.scheduledEvent.delete.header", "Delete event?")}
            buttonText={getResource("Planning.scheduledEvent.delete.okButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Planning.scheduledEvent.delete.deleteMessage"
                defaultValue="Are you sure you want to delete '{summary}'?"
                tokens={{ summary }}
            />
        </Confirmation>
    );
});
