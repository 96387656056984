import React, { Fragment, useContext } from "react";
import ContentHolder from "../../../shared/contentHolder";
import { Button, LinearProgress, Paper, Typography } from "@material-ui/core";
import { Assessment as FinancialReportsIcon, Receipt as InvoiceIcon } from "@material-ui/icons";
import useAPI from "../../../shared/useapi";
import { makeStyles } from "@material-ui/styles";
import Header from "../../../shared/header";
import { getQuickScan } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import DownloadReport from "./downloadreport";
import FormattedCurrency from "../../../shared/formattedCurrency";
import ActionMenu from "../../../shared/actionmenu";
import useDialog from "../../../shared/usedialog";
import Invoice from "../../administration/invoices/forReference";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";
import NotFound from "../../../shared/notFound";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(1),
    },

    paper: {
        padding: theme.spacing(2),

        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(3),
        },
    },

    disclaimer: {
        marginTop: 0,
    },

    property: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },

    valueWritten: {
        fontWeight: "normal",
        textTransform: "lowercase",
        fontSize: "0.9em",
    },

    questionsAnswers: {
        display: "block",

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",
            margin: 0,
        },

        "& dt": {
            fontWeight: "normal",
        },

        "& dd": {
            marginBottom: theme.spacing(1),
        },
    },

    valuesHeading: {
        marginBottom: theme.spacing(1),
    },
}));

const Property = ({ titleResourceKey, titleDefaultValue, titleTokens, value }) => {
    const classes = useStyles();

    if (!value) {
        return null;
    }

    return (
        <div className={classes.property}>
            <dt>
                <ResourcedText
                    resourceKey={titleResourceKey}
                    defaultValue={titleDefaultValue}
                    tokens={titleTokens}
                />
            </dt>
            <dd>{value}</dd>
        </div>
    );
};

const QuickScanReport = withResources(
    ({
        history,
        getResource,
        match: {
            params: { quickScanId },
        },
    }) => {
        const classes = useStyles();

        const { data: quickScan, loading } = useAPI(() => getQuickScan(quickScanId), true, [
            quickScanId,
        ]);

        const {
            open: handleDownloadReport,
            close: handleCloseDownloadReport,
            visible: downloadReportToggled,
            args: downloadReportId,
        } = useDialog();
        const {
            open: handleInvoice,
            close: handleCloseInvoice,
            visible: invoiceToggled,
            args: invoiceId,
        } = useDialog();

        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        var assetName =
            quickScan &&
            (quickScan.assetName || `${quickScan.manufacturerName} / ${quickScan.assetModelName}`);

        return (
            <Fragment>
                {loading && <LinearProgress />}

                {!loading && !quickScan && <NotFound element="quick scan" />}

                {quickScan && (
                    <ContentHolder>
                        <Header
                            className={classes.header}
                            title={getResource(
                                "Valuation.quickScanReport.header.subtitle",
                                "Quick scan report for {assetName}",
                                { assetName }
                            )}
                            breadcrumb={quickScan.categories}
                            renderActions={() => {
                                return [
                                    <Button
                                        key="downloadReportButton"
                                        className={classes.button}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleDownloadReport(quickScanId)}
                                    >
                                        {getResource(
                                            "Valuation.quickScanReport.header.downloadReportButton",
                                            "Download"
                                        )}
                                    </Button>,
                                    Boolean(quickScan.assetId) && (
                                        <ActionMenu
                                            key="actionMenu"
                                            actions={[
                                                {
                                                    text: getResource(
                                                        "Valuation.quickScanReport.financialReportsButton",
                                                        "Financial reports"
                                                    ),
                                                    icon: <FinancialReportsIcon />,
                                                    itemId: quickScanId,
                                                    requiredOperation: {
                                                        name: "financial-reports-view",
                                                        companyId:
                                                            quickScan.authentication.companyId,
                                                        assetCollectionId:
                                                            quickScan.authentication
                                                                .assetCollectionIds,
                                                    },
                                                    onClick: () =>
                                                        history.push(
                                                            `/valuation/financial-report/${quickScanId}`
                                                        ),
                                                },
                                                {
                                                    text: getResource(
                                                        "Valuation.quickScanReport.invoiceButton",
                                                        "Create invoice"
                                                    ),
                                                    icon: <InvoiceIcon />,
                                                    itemId: quickScanId,
                                                    requiredOperation: {
                                                        name: "invoice-create",
                                                        companyId:
                                                            quickScan.authentication.companyId,
                                                        assetCollectionId:
                                                            quickScan.authentication
                                                                .assetCollectionIds,
                                                    },
                                                    onClick: () => handleInvoice(quickScanId),
                                                },
                                            ]}
                                        />
                                    ),
                                ];
                            }}
                        />

                        <Paper className={classes.paper}>
                            <p className={classes.disclaimer}>
                                {getResource(
                                    "Valuation.quickScanReport.disclaimer",
                                    "Disclaimer text"
                                )}
                            </p>

                            <dl>
                                <Property
                                    titleResourceKey="Valuation.quickScanReport.descriptionHeader"
                                    titleDefaultValue="Description"
                                    value={quickScan.assetDescription}
                                />

                                <Property
                                    titleResourceKey="Valuation.quickScanReport.referenceHeader"
                                    titleDefaultValue="Reference"
                                    value={quickScan.reference}
                                />

                                <Property
                                    titleResourceKey="Valuation.quickScanReport.purchasedOnHeader"
                                    titleDefaultValue="Purchased on"
                                    value={formatDate(
                                        quickScan.purchasedOn,
                                        selectedUiCulture,
                                        selectedTimezone
                                    )}
                                />

                                <Property
                                    titleResourceKey="Valuation.quickScanReport.purchasedByHeader"
                                    titleDefaultValue="Purchased by"
                                    value={quickScan.purchasedBy}
                                />

                                <Property
                                    titleResourceKey="Valuation.quickScanReport.manufacturerModelHeader"
                                    titleDefaultValue="Manufacturer  / Model"
                                    value={`${quickScan.manufacturerName} / ${quickScan.assetModelName}`}
                                />

                                {quickScan.assetName && (
                                    <Property
                                        titleResourceKey="Valuation.quickScanReport.nameHeader"
                                        titleDefaultValue="Name"
                                        value={quickScan.assetName}
                                    />
                                )}

                                {quickScan.constructionDate && <Property
                                    titleResourceKey="Valuation.quickScanReport.constructionDateHeader"
                                    titleDefaultValue="Construction date"
                                    value={formatDate(
                                        quickScan.constructionDate,
                                        selectedUiCulture,
                                        selectedTimezone
                                    )}
                                />}

                                {quickScan.answeredQuestions.length > 0 && (
                                    <Property
                                        titleResourceKey="Valuation.quickScanReport.questionsHeader"
                                        titleDefaultValue="Questions"
                                        value={
                                            <dl className={classes.questionsAnswers}>
                                                {quickScan.answeredQuestions.map((question) => (
                                                    <Fragment key={question.question}>
                                                        <dt>{question.question}</dt>
                                                        <dd>{question.answer}</dd>
                                                    </Fragment>
                                                ))}
                                            </dl>
                                        }
                                    />
                                )}
                            </dl>

                            <Typography
                                className={classes.valuesHeading}
                                variant="h4"
                                component="h2"
                            >
                                <ResourcedText
                                    resourceKey="Valuation.quickScanReport.valuesHeader"
                                    defaultValue="Values"
                                />
                            </Typography>

                            <dl>
                                <Property
                                    titleResourceKey="Valuation.quickScanReport.fairMarketValueHeader"
                                    titleDefaultValue="QUICKSCAN ™ fair market"
                                    value={
                                        <Fragment>
                                            <FormattedCurrency
                                                value={quickScan.fairMarketValue}
                                                currency={quickScan.currencyShortName}
                                                decimals={0}
                                            />
                                            &nbsp;
                                            <span className={classes.valueWritten}>
                                                ({quickScan.fairMarketValueWritten}{" "}
                                                {quickScan.currencyName})
                                            </span>
                                        </Fragment>
                                    }
                                />

                                <Property
                                    titleResourceKey="Valuation.quickScanReport.liquidationValueHeader"
                                    titleDefaultValue="QUICKSCAN ™ liquidation"
                                    value={
                                        <Fragment>
                                            <FormattedCurrency
                                                value={quickScan.liquidationValue}
                                                currency={quickScan.currencyShortName}
                                                decimals={0}
                                            />
                                            &nbsp;
                                            <span className={classes.valueWritten}>
                                                ({quickScan.liquidationValueWritten}{" "}
                                                {quickScan.currencyName})
                                            </span>
                                        </Fragment>
                                    }
                                />

                                {quickScan.futureFairMarketValue != null && (
                                    <Property
                                        titleResourceKey="Valuation.quickScanReport.futureFairMarketValueHeader"
                                        titleDefaultValue="QUICKSCAN ™ fair market value after {futureMonths} months"
                                        titleTokens={{ futureMonths: quickScan.futureMonths }}
                                        value={
                                            <Fragment>
                                                <FormattedCurrency
                                                    value={quickScan.futureFairMarketValue}
                                                    currency={quickScan.currencyShortName}
                                                    decimals={0}
                                                />
                                                &nbsp;
                                                <span className={classes.valueWritten}>
                                                    ({quickScan.futureFairMarketValueWritten}{" "}
                                                    {quickScan.currencyName})
                                                </span>
                                            </Fragment>
                                        }
                                    />
                                )}

                                {quickScan.futureLiquidationValue != null && (
                                    <Property
                                        titleResourceKey="Valuation.quickScanReport.futureLiquidationValueHeader"
                                        titleDefaultValue="QUICKSCAN ™ liquidation value after {futureMonths} months"
                                        titleTokens={{ futureMonths: quickScan.futureMonths }}
                                        value={
                                            <Fragment>
                                                <FormattedCurrency
                                                    value={quickScan.futureLiquidationValue}
                                                    currency={quickScan.currencyShortName}
                                                    decimals={0}
                                                />
                                                &nbsp;
                                                <span className={classes.valueWritten}>
                                                    ({quickScan.futureLiquidationValueWritten}{" "}
                                                    {quickScan.currencyName})
                                                </span>
                                            </Fragment>
                                        }
                                    />
                                )}
                            </dl>
                        </Paper>

                        <DownloadReport
                            open={downloadReportToggled}
                            quickScanId={downloadReportId}
                            onClose={handleCloseDownloadReport}
                        />

                        <Invoice
                            open={invoiceToggled}
                            onClose={handleCloseInvoice}
                            referenceType={"QuickScan"}
                            referenceId={invoiceId}
                        />
                    </ContentHolder>
                )}
            </Fragment>
        );
    }
);

export default QuickScanReport;
