import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(name, page, pageSize) {
    const params = {
        page,
        pageSize,
        name,
    };

    const url = `/api/library/manufacturer?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAllForAssetContext(searchText, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
    };

    const url = `/api/library/manufacturer/forAssetContext?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/library/manufacturer/${id}`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/library/manufacturer/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/library/manufacturer", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/library/manufacturer/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function approve(id, values) {
    return await interceptedFetch(`/api/library/manufacturer/${id}/approve`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function mergeAndDelete(sourceId, values) {
    return await interceptedFetch(`/api/library/manufacturer/${sourceId}/merge`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/library/manufacturer/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function nameExists(name) {
    const url = `/api/library/manufacturer/${name}/exists`;

    return await interceptedFetch(url);
}

export {
    getAll,
    getAllForAssetContext,
    get,
    getIsReferenced,
    add,
    update,
    approve,
    mergeAndDelete,
    del,
    nameExists,
};
