import React from "react";
import PropTypes from "prop-types";
import withResources from "./withresources";

const ResourcedText = withResources(({ getResource, resourceKey, defaultValue, tokens }) => {
    const localizedText = getResource(resourceKey, defaultValue, tokens);

    return <span className="resourcedText" dangerouslySetInnerHTML={{ __html: localizedText }} />;
});

ResourcedText.propTypes = {
    resourceKey: PropTypes.string,
    defaultValue: PropTypes.string,
    tokens: PropTypes.object,
};

export default ResourcedText;
