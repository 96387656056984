import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, type, published, assetId, page, pageSize) {
    const params = {
        page: page,
        pageSize: pageSize,
        searchText: searchText,
        type: type,
        published: published,
        assetId: assetId,
    };

    const url = `/api/marketplace/ad?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/marketplace/ad/${id}`;

    return await interceptedFetch(url);
}

async function getPublished(searchText, page, pageSize) {
    const params = {
        searchText,
        page,
        pageSize,
    };

    const url = `/api/marketplace/ad/published?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function create(values) {
    return await interceptedFetch(`/api/marketplace/ad`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/marketplace/ad/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(id, version) {
    return await interceptedFetch(`/api/marketplace/ad/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ version: version }),
    });
}

async function getTypes() {
    const url = `/api/marketplace/ad/types`;

    return await interceptedFetch(url);
}

async function getStatuses() {
    const url = `/api/marketplace/ad/statuses`;

    return await interceptedFetch(url);
}

async function getPriceTypes() {
    const url = `/api/marketplace/ad/priceTypes`;

    return await interceptedFetch(url);
}

async function getRentLeasePeriods() {
    const url = `/api/marketplace/ad/rentLeasePeriods`;

    return await interceptedFetch(url);
}

async function getWantedTypes() {
    const url = `/api/marketplace/ad/wantedTypes`;

    return await interceptedFetch(url);
}

export {
    getAll,
    get,
    getPublished,
    update,
    del,
    getStatuses,
    getPriceTypes,
    getRentLeasePeriods,
    getWantedTypes,
    getTypes,
    create,
};
