import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(workOrderId, page, pageSize) {
    const params = {
        workOrderId,
        page,
        pageSize,
    };

    const url = `/api/maintenance/workOrderTask/?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(workOrderId, taskId) {
    const url = `/api/maintenance/workOrderTask/${workOrderId}/${taskId}`;

    return await interceptedFetch(url);
}

async function processJobSheet(workOrderId, taskId, values) {
    return await interceptedFetch(
        `/api/maintenance/workOrderTask/${workOrderId}/${taskId}/jobsheet`,
        {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(Object.assign(values)),
        }
    );
}

async function add(values) {
    return await interceptedFetch("/api/maintenance/workOrderTask", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/maintenance/workOrderTask/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(workOrderId, taskId) {
    return await interceptedFetch(`/api/maintenance/workOrderTask/${workOrderId}/${taskId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function reopen(id) {
    return await interceptedFetch(`/api/maintenance/workOrderTask/${id}/reopen`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, processJobSheet, add, update, del, reopen };
