import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-end",
    },

    totalBlock: {
        display: "inline-flex",
        justifyContent: "space-between",
        minWidth: 205,
        padding: theme.spacing(3, 4, 6, 0),

        "& > div": {
            paddingBottom: theme.spacing(2),

            "&:first-child": {
                paddingRight: theme.spacing(4),
            },

            "&:last-child": {
                textAlign: "right",
            },
        },

        "& .totalLabel, & .totalAmount": {
            fontSize: 18,
            fontWeight: 500,
        },

        "& .totalEstimate": {
            fontSize: 13,
            color: theme.palette.text.secondary,
        },
    },
}));

export default useStyles;
