import React from "react";
import Typography from "@material-ui/core/Typography";
import { Add as AddIcon } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import ActionMenu from "../../actionmenu";
import useStyles from "./useStyles";

const Header = (props) => {
    const {
        parentId,
        title,
        isActive,
        handleClickAddButton,
        addButtonRequiredOperation,
        addLabel,
        getActions,
    } = props;
    const classes = useStyles();

    return (
        <li className={clsx(classes.header)} onClick={(e) => e.stopPropagation()}>
            <Typography
                className={clsx(classes.titleAndPreTitle, isActive === false && "--inactive")}
                variant="h4"
                component="h2"
            >
                <span className={classes.titleWrapper}>
                    <span className={classes.titleText} title={title}>
                        {title ? title : "\u00A0"}
                    </span>
                    {isActive === false && (
                        <span className={classes.archivedText}>&nbsp;(archived)</span>
                    )}
                </span>
            </Typography>

            {getActions && <ActionMenu actions={getActions()} />}
            {handleClickAddButton && (
                <ActionMenu
                    actions={[
                        {
                            text: addLabel,
                            icon: <AddIcon />,
                            requiredOperation: addButtonRequiredOperation,
                            onClick: () => handleClickAddButton(parentId),
                        },
                    ]}
                />
            )}
        </li>
    );
};

Header.propTypes = {
    parentId: PropTypes.number.isRequired,
    title: PropTypes.string,
    isActive: PropTypes.bool,
    handleClickAddButton: PropTypes.func,
    addButtonRequiredOperation: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
    ]),
    addLabel: PropTypes.string.isRequired,
    getActions: PropTypes.func,
};

export default Header;
