import React, { Fragment, useState } from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
    LinearProgress,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useAPI from "../../../shared/useapi";

import { approve, get } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import { getAll as getAllRoles } from "./../roles/dataservice";
import {
    getActiveChildren,
    getAll as getAllCompanies,
    getParents,
    getPath,
} from "./../companies/dataservice";
import Reject from "./reject";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import DrillDownInput from "../../../shared/drilldowninput";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";

import useDialog from "../../../shared/usedialog";

import { makeStyles } from "@material-ui/styles";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import { config } from "config";

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        position: "fixed",
        bottom: 0,
        width: "calc(100% - 64px)",
        padding: theme.spacing(6, 0, 2),
        zIndex: 2,
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",

        [theme.breakpoints.up("md")]: {
            width: "calc(700px - 64px)",
        },
    },

    footer: {
        display: "flex",
        justifyContent: "space-between",

        ".labelledOutline + &": {
            marginTop: 0,
        },

        "& .MuiButton-root": {
            marginTop: "0 !important",
        },
    },
}));

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const [isApproving, setIsApproving] = useState(false);
    const classes = useStyles();

    const { data } = useAPI(() => get(id), true, [id]);
    const { data: allRoles } = useAPI(() => getAllRoles(null, null, 2, null, null), true);
    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    const { open: handleReject, close: handleCloseReject, visible: rejectToggled } = useDialog(() =>
        onClose(true)
    );

    const { loading, invoke } = useAPI((values) => approve(id, values));

    const onSubmit = async (values) => {
        let saveValues = {
            ...values.values,

            companyId:
                values.values.companyMethod === "suggestion"
                    ? values.values.suggestedCompany
                    : values.values.companyMethod === "search"
                    ? values.values.searchedCompany.id
                    : null,

            correspondenceCountry: values.values.correspondenceVisible
                ? values.values.correspondenceCountry
                : null,
            correspondenceAddress: values.values.correspondenceVisible
                ? values.values.correspondenceAddress
                : null,
            correspondenceState: values.values.correspondenceVisible
                ? values.values.correspondenceState
                : null,
            correspondencePostalCode: values.values.correspondenceVisible
                ? values.values.correspondencePostalCode
                : null,
            correspondenceCity: values.values.correspondenceVisible
                ? values.values.correspondenceCity
                : null,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.userFirstName) {
            errors.userFirstName = getResource(
                "Security.applications.process.enterFirstName",
                "Please enter a firstname"
            );
        }

        if (!values.userLastName) {
            errors.userLastName = getResource(
                "Security.applications.process.enterLastName",
                "Please enter a surname"
            );
        }

        if (!values.userEmail) {
            errors.userEmail = getResource(
                "Security.applications.process.enterEmail",
                "Please enter a personal e-mail"
            );
        }

        if (values.userEmail && !values.userEmail.match(config.emailRegex)) {
            errors.userEmail = getResource(
                "Security.applications.process.enterValidEmail",
                "The provided personal e-mail isn't a valid e-mail address"
            );
        }

        if (!values.companyMethod) {
            errors.companyMethod = getResource(
                "Security.applications.process.selectCompanyMethod",
                "Please select a method to select a company"
            );
        }

        if (values.companyMethod === "suggestion" && !values.suggestedCompany) {
            errors.suggestedCompany = getResource(
                "Security.applications.process.selectSuggestedCompany",
                "Please select a suggested company"
            );
        }

        if (values.companyMethod === "search" && !values.searchedCompany.id) {
            errors.searchedCompany = getResource(
                "Security.applications.process.selectSearchedCompany",
                "Please search a company to use"
            );
        }

        if (values.companyMethod === "new") {
            if (!values.companyName) {
                errors.companyName = getResource(
                    "Security.applications.process.newCompany.enterName",
                    "Please enter a company name"
                );
            }

            if (!values.companyEmail) {
                errors.companyEmail = getResource(
                    "Security.applications.process.newCompany.enterEmail",
                    "Please enter a company e-mail"
                );
            }

            if (!!values.suggestedCompany && !values.searchedCompany.id) {
                errors.company = getResource(
                    "Security.applications.process.newCompany.selectCompanyOrCreateNew",
                    "Please select a company or create a new one"
                );
            }

            if (values.companyEmail && !values.companyEmail.match(config.emailRegex)) {
                errors.companyEmail = getResource(
                    "Security.applications.process.newCompany.enterCompanyEmail",
                    "The provided company e-mail isn't a valid e-mail address"
                );
            }

            if (!values.companyCountry) {
                errors.companyCountry = getResource(
                    "Security.applications.process.newCompany.selectCountry",
                    "Please select a country"
                );
            }

            if (!values.companyAddress) {
                errors.companyAddress = getResource(
                    "Security.applications.process.newCompany.enterAddress",
                    "Please enter a address"
                );
            }

            if (!values.companyState) {
                errors.companyState = getResource(
                    "Security.applications.process.newCompany.enterState",
                    "Please enter a state"
                );
            }

            if (!values.companyPostalCode) {
                errors.companyPostalCode = getResource(
                    "Security.applications.process.newCompany.enterPostalCode",
                    "Please enter a postal code"
                );
            }

            if (!values.companyCity) {
                errors.companyCity = getResource(
                    "Security.applications.process.newCompany.enterCity",
                    "Please enter a city"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceCountry) {
                errors.correspondenceCountry = getResource(
                    "Security.applications.process.newCompany.correspondence.selectCountry",
                    "Please select a country"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceAddress) {
                errors.correspondenceAddress = getResource(
                    "Security.applications.process.newCompany.correspondence.enterAddress",
                    "Please enter a address"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceState) {
                errors.correspondenceState = getResource(
                    "Security.applications.process.newCompany.correspondence.enterState",
                    "Please enter a state"
                );
            }

            if (values.correspondenceVisible && !values.correspondencePostalCode) {
                errors.correspondencePostalCode = getResource(
                    "Security.applications.process.newCompany.correspondence.enterPostalCode",
                    "Please enter a postal code"
                );
            }

            if (values.correspondenceVisible && !values.correspondenceCity) {
                errors.correspondenceCity = getResource(
                    "Security.applications.process.newCompany.correspondence.enterCity",
                    "Please enter a city"
                );
            }
        }

        if (!values.roles || values.roles.length === 0) {
            errors.roles = getResource(
                "Security.applications.process.selectRoles",
                "Please select one or more roles"
            );
        }

        return errors;
    };

    const isLoaded = data && allRoles && allCountries;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.applications.process.title", "Process application")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : data.isApproved != null ? (
                <Fragment>
                    {getResource(
                        "Security.applications.process.applicationAlreadyProcessed",
                        "You cannot edit an application that is already {status}",
                        {
                            status: data.isApproved
                                ? getResource(
                                      "Security.applications.process.approvedStatus",
                                      "approved"
                                  )
                                : getResource(
                                      "Security.applications.process.rejectedStatus",
                                      "rejected"
                                  ),
                        }
                    )}
                </Fragment>
            ) : (
                <LocalizedForm
                    initialValues={{
                        ...data,
                        searchedCompany: { id: null, name: null },
                        companyMethod: "",
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    hideOkButton
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <Fragment>
                                <strong>
                                    <ResourcedText
                                        resourceKey="Security.applications.process.personalTitle"
                                        defaultValue="Personal"
                                    />
                                </strong>

                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="userFirstName"
                                    labelResourceKey="Security.applications.process.firstNameField"
                                    labelDefaultValue="First name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.userFirstName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userLastName"
                                    labelResourceKey="Security.applications.process.surNameField"
                                    labelDefaultValue="Surname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 60 }}
                                    value={values.userLastName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userEmail"
                                    labelResourceKey="Security.applications.process.emailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.userEmail}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                {data.userEmailExists && (
                                    <Alert severity="warning">
                                        {getResource(
                                            "Security.applications.process.userEmailExists",
                                            "The provided email-address {emailAddress} already exists.",
                                            { emailAddress: data.userEmail }
                                        )}
                                    </Alert>
                                )}

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userPhoneNumber"
                                    labelResourceKey="Security.applications.process.phoneNumberField"
                                    labelDefaultValue="Phone number"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.userPhoneNumber || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.userPhoneNumber;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <LabelledOutline label="Company">
                                    <dl className={classes.dl}>
                                        <dt>
                                            <ResourcedText
                                                resourceKey="Security.applications.process.company.nameField"
                                                defaultValue="Name"
                                            />
                                        </dt>
                                        <dd>{values.companyName}</dd>
                                        <dt>
                                            <ResourcedText
                                                resourceKey="Security.applications.process.company.emailField"
                                                defaultValue="Email"
                                            />
                                        </dt>
                                        <dd>{values.companyEmail}</dd>
                                        {values.companyPhoneNumber && (
                                            <Fragment>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Security.applications.process.company.phoneNumberField"
                                                        defaultValue="Phone number"
                                                    />
                                                </dt>
                                                <dd>{values.companyPhoneNumber}</dd>
                                            </Fragment>
                                        )}
                                        {values.companyChamberOfCommerce && (
                                            <Fragment>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Security.applications.process.company.chamberOfCommerceField"
                                                        defaultValue="Chamber of commerce"
                                                    />
                                                </dt>
                                                <dd>{values.companyChamberOfCommerce}</dd>
                                            </Fragment>
                                        )}
                                        {values.companyVatNumber && (
                                            <Fragment>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Security.applications.process.company.vatNumberField"
                                                        defaultValue="VAT Number"
                                                    />
                                                </dt>
                                                <dd>{values.companyVatNumber}</dd>
                                            </Fragment>
                                        )}
                                        <dt>
                                            <ResourcedText
                                                resourceKey="Security.applications.process.company.addressField"
                                                defaultValue="Address"
                                            />
                                        </dt>
                                        <dd>
                                            {values.companyAddress}
                                            <br />
                                            {values.companyPostalCode} {values.companyCity}
                                            <br />
                                            {values.companyState} -{" "}
                                            {
                                                allCountries.find(
                                                    (x) => x.code === values.companyCountry
                                                ).name
                                            }
                                        </dd>
                                        {values.correspondenceVisible &&
                                            values.correspondenceCountry && (
                                                <Fragment>
                                                    <dt>
                                                        <ResourcedText
                                                            resourceKey="Security.applications.process.company.correspondenceAddressField"
                                                            defaultValue="Correspondence address"
                                                        />
                                                    </dt>
                                                    <dd>
                                                        {values.correspondenceAddress}
                                                        <br />
                                                        {values.correspondencePostalCode}{" "}
                                                        {values.correspondenceCity}
                                                        <br />
                                                        {values.correspondenceState} -{" "}
                                                        {
                                                            allCountries.find(
                                                                (x) =>
                                                                    x.code ===
                                                                    values.correspondenceCountry
                                                            ).name
                                                        }
                                                    </dd>
                                                </Fragment>
                                            )}
                                    </dl>

                                    {isApproving && (
                                        <Fragment>
                                            <RadioGroup
                                                value={values.companyMethod}
                                                onChange={(e) => handleChange(e)}
                                                row
                                            >
                                                {values.suggestions && (
                                                    <FormControlLabel
                                                        name="companyMethod"
                                                        value="suggestion"
                                                        control={<Radio color="primary" />}
                                                        label={getResource(
                                                            "Security.applications.process.company.useSuggestedCompany",
                                                            "Use a suggested company"
                                                        )}
                                                        labelPlacement="end"
                                                    />
                                                )}
                                                <FormControlLabel
                                                    name="companyMethod"
                                                    value="search"
                                                    control={<Radio color="primary" />}
                                                    label={getResource(
                                                        "Security.applications.process.company.searchForCompany",
                                                        "Search for a company"
                                                    )}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    name="companyMethod"
                                                    value="new"
                                                    control={<Radio color="primary" />}
                                                    label={getResource(
                                                        "Security.applications.process.company.createNewCompany",
                                                        "Create a new company"
                                                    )}
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                            {"companyMethod" in errors && (
                                                <FormHelperText error component="legend">
                                                    {errors["companyMethod"]}
                                                </FormHelperText>
                                            )}
                                            {values.companyMethod === "suggestion" &&
                                                values.suggestions && (
                                                    <AutoCompleteStatic
                                                        name="suggestedCompany"
                                                        errors={errors}
                                                        label={getResource(
                                                            "Security.applications.process.suggestedCompanyField",
                                                            "Suggested company"
                                                        )}
                                                        fullWidth
                                                        isMedium
                                                        value={values.suggestedCompany}
                                                        onSelection={(e) => handleChange(e)}
                                                        options={values.suggestions}
                                                        required
                                                    />
                                                )}

                                            {values.companyMethod === "search" && (
                                                <DrillDownInput
                                                    name="searchedCompany"
                                                    errors={errors}
                                                    itemName={getResource(
                                                        "Security.applications.process.companyItemName",
                                                        "company"
                                                    )}
                                                    label={getResource(
                                                        "Security.applications.process.searchedCompanyField",
                                                        "Company"
                                                    )}
                                                    isMedium
                                                    fullWidth
                                                    getParents={getParents}
                                                    getChildren={getActiveChildren}
                                                    getPath={getPath}
                                                    filter={(
                                                        searchText,
                                                        noDescendantOf,
                                                        page,
                                                        pageSize
                                                    ) =>
                                                        getAllCompanies(
                                                            searchText,
                                                            noDescendantOf,
                                                            null,
                                                            page,
                                                            pageSize
                                                        )
                                                    }
                                                    value={values.searchedCompany}
                                                    disableClearable
                                                    setValue={(e) => handleChange(e)}
                                                    required
                                                />
                                            )}

                                            {values.companyMethod === "new" && (
                                                <Fragment>
                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyName"
                                                        labelResourceKey="Security.applications.process.newCompany.nameField"
                                                        labelDefaultValue="Name"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 75 }}
                                                        value={values.companyName}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyEmail"
                                                        label="E-mail"
                                                        labelResourceKey="Security.applications.process.newCompany.emailField"
                                                        labelDefaultValue="E-mail"
                                                        type="email"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={values.companyEmail}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyPhoneNumber"
                                                        labelResourceKey="Security.applications.process.newCompany.phoneNumberField"
                                                        labelDefaultValue="Phone number"
                                                        type="tel"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{
                                                            maxLength: 20,
                                                            pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                                        }}
                                                        value={values.companyPhoneNumber || ""}
                                                        onChange={(e) => {
                                                            if (!e.target.validity.valid) {
                                                                e.target.value =
                                                                    values.companyPhoneNumber;
                                                            }
                                                            handleChange(e);
                                                        }}
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyChamberOfCommerce"
                                                        labelResourceKey="Security.applications.process.newCompany.chamberOfCommerceField"
                                                        labelDefaultValue="Chamber of commerce"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={
                                                            values.companyChamberOfCommerce || ""
                                                        }
                                                        onChange={(e) => handleChange(e)}
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyVatNumber"
                                                        labelResourceKey="Security.applications.process.newCompany.companyVatNumberField"
                                                        labelDefaultValue="VAT Number"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={values.companyVatNumber || ""}
                                                        onChange={(e) => handleChange(e)}
                                                    />

                                                    <strong>Company address</strong>

                                                    <AutoCompleteStatic
                                                        errors={errors}
                                                        name="companyCountry"
                                                        label={getResource(
                                                            "Security.applications.process.newCompany.countryField",
                                                            "Country"
                                                        )}
                                                        fullWidth
                                                        isMedium
                                                        value={values.companyCountry}
                                                        onSelection={(e) => handleChange(e)}
                                                        options={allCountries.map((c) => ({
                                                            id: c.code,
                                                            name: c.name,
                                                        }))}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyAddress"
                                                        labelResourceKey="Security.applications.process.newCompany.addressField"
                                                        labelDefaultValue="Address"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 100 }}
                                                        value={values.companyAddress}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyPostalCode"
                                                        labelResourceKey="Security.applications.process.newCompany.postalCodeField"
                                                        labelDefaultValue="Postal code"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={values.companyPostalCode}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyState"
                                                        labelResourceKey="Security.applications.process.newCompany.stateField"
                                                        labelDefaultValue="State"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 100 }}
                                                        value={values.companyState}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <ResourcedTextField
                                                        errors={errors}
                                                        margin="normal"
                                                        name="companyCity"
                                                        labelResourceKey="Security.applications.process.newCompany.cityField"
                                                        labelDefaultValue="City"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputLabelProps={{ shrink: true }}
                                                        inputProps={{ maxLength: 100 }}
                                                        value={values.companyCity}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={Boolean(
                                                                    values.correspondenceVisible
                                                                )}
                                                                onChange={(e) => handleChange(e)}
                                                                name="correspondenceVisible"
                                                            />
                                                        }
                                                        label={getResource(
                                                            "Security.applications.process.newCompany.differentCorrespondenceAddressField",
                                                            "Different correspondence address"
                                                        )}
                                                        variant="outlined"
                                                    />

                                                    {values.correspondenceVisible && (
                                                        <Fragment>
                                                            <AutoCompleteStatic
                                                                errors={errors}
                                                                name="correspondenceCountry"
                                                                label={getResource(
                                                                    "Security.applications.process.correspondence.countryField",
                                                                    "Country"
                                                                )}
                                                                fullWidth
                                                                isMedium
                                                                value={values.correspondenceCountry}
                                                                onSelection={(e) => handleChange(e)}
                                                                options={allCountries.map((c) => ({
                                                                    id: c.code,
                                                                    name: c.name,
                                                                }))}
                                                                required
                                                            />

                                                            <ResourcedTextField
                                                                errors={errors}
                                                                margin="normal"
                                                                name="correspondenceAddress"
                                                                labelResourceKey="Security.applications.process.correspondence.addressField"
                                                                labelDefaultValue="Address"
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{ maxLength: 100 }}
                                                                value={
                                                                    values.correspondenceAddress ||
                                                                    ""
                                                                }
                                                                onChange={(e) => handleChange(e)}
                                                                required
                                                            />

                                                            <ResourcedTextField
                                                                errors={errors}
                                                                margin="normal"
                                                                name="correspondencePostalCode"
                                                                labelResourceKey="Security.applications.process.correspondence.postalCodeField"
                                                                labelDefaultValue="Postal code"
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{ maxLength: 50 }}
                                                                value={
                                                                    values.correspondencePostalCode ||
                                                                    ""
                                                                }
                                                                onChange={(e) => handleChange(e)}
                                                                required
                                                            />

                                                            <ResourcedTextField
                                                                errors={errors}
                                                                margin="normal"
                                                                name="correspondenceState"
                                                                labelResourceKey="Security.applications.process.correspondence.stateField"
                                                                labelDefaultValue="State"
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{ maxLength: 100 }}
                                                                value={values.correspondenceState}
                                                                onChange={(e) => handleChange(e)}
                                                                required
                                                            />

                                                            <ResourcedTextField
                                                                errors={errors}
                                                                margin="normal"
                                                                name="correspondenceCity"
                                                                labelResourceKey="Security.applications.process.correspondence.cityField"
                                                                labelDefaultValue="City"
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{ maxLength: 100 }}
                                                                value={
                                                                    values.correspondenceCity || ""
                                                                }
                                                                onChange={(e) => handleChange(e)}
                                                                required
                                                            />
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                </LabelledOutline>

                                {isApproving && (
                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="roles"
                                        label={getResource(
                                            "Security.applications.process.rolesField",
                                            "Roles for this company"
                                        )}
                                        value={values.roles}
                                        fullWidth
                                        isMedium
                                        multiple
                                        options={allRoles.items}
                                        onSelection={(e) => handleChange(e)}
                                        required
                                    />
                                )}

                                <div className={classes.footerWrapper}>
                                    <div className={classes.footer}>
                                        {values.isApproved == null && !isApproving && (
                                            <AuthorizedButton
                                                onClick={() => {
                                                    setIsApproving(true);
                                                }}
                                                color="primary"
                                                variant="contained"
                                                requiredOperation="applications-approve"
                                                buttonText={getResource(
                                                    "Security.applications.process.approveButton",
                                                    "Approve"
                                                )}
                                            />
                                        )}
                                        {values.isApproved == null && !isApproving && (
                                            <AuthorizedButton
                                                onClick={handleReject}
                                                color="secondary"
                                                variant="contained"
                                                requiredOperation="applications-approve"
                                                buttonText={getResource(
                                                    "Security.applications.process.rejectButton",
                                                    "Reject"
                                                )}
                                            />
                                        )}
                                        {isApproving && (
                                            <Button
                                                disabled={loading}
                                                type="submit"
                                                color="primary"
                                                fullWidth
                                                variant="contained"
                                            >
                                                <ResourcedText
                                                    resourceKey="Security.applications.process.saveButton"
                                                    defaultValue="Save"
                                                />
                                                {loading && <CircularProgress />}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                />
            )}
            <Reject open={rejectToggled} id={id} onClose={handleCloseReject} />
        </FormDrawer>
    );
});
