import React, { useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import ConfigurationContext from "../../../../shared/configuration/configurationcontext";
import AuthenticationContext from "../../../../shared/authentication/authenticationcontext";

export default ({ value, onEditorChange }) => {
    const {
        marketplace: { publicCssFile },
    } = useContext(ConfigurationContext);

    const { authorizationService } = useContext(AuthenticationContext);
    var extraPlugins = "";
    var extraToolbars = "";
    if (authorizationService.isAuthorized("content-pages-html")) {
        extraPlugins += " code";
        extraToolbars += " | code";
    }

    return (
        <Editor
            tinymceScriptSrc="/js/tinymce/tinymce.min.js"
            value={value}
            init={{
                content_css: publicCssFile,
                images_upload_handler: function (blobInfo, success, failure, progress) {
                    var xhr, formData;

                    xhr = new XMLHttpRequest();
                    xhr.withCredentials = false;
                    xhr.open("POST", "/api/content/contentpage/image/upload");

                    xhr.upload.onprogress = function (e) {
                        progress((e.loaded / e.total) * 100);
                    };

                    xhr.onload = function () {
                        var json;

                        if (xhr.status < 200 || xhr.status >= 300) {
                            failure("HTTP Error: " + xhr.status);
                            return;
                        }

                        json = JSON.parse(xhr.responseText);

                        if (!json || typeof json.location != "string") {
                            failure("Invalid JSON: " + xhr.responseText);
                            return;
                        }

                        success(json.location);
                    };

                    xhr.onerror = function () {
                        failure(
                            "Image upload failed due to a XHR Transport error. Code: " + xhr.status
                        );
                    };

                    formData = new FormData();
                    formData.append("file", blobInfo.blob(), blobInfo.filename());

                    xhr.send(formData);
                },
                theme_url: "/js/tinymce/themes/silver/theme.min.js",
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                height: 400,
                menubar: false,
                block_formats: "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4",
                plugins: `autolink lists link image anchor fullscreen media table paste template ${extraPlugins}`,
                toolbar: `undo redo | formatselect | bold italic underline | template table image | link unlink | bullist numlist | removeformat | fullscreen | media ${extraToolbars}`,
                templates: [
                    {
                        title: "Block quote",
                        description: "Block quote",
                        content:
                            "<blockquote><q>This is a quote</q><footer>John Doe</footer></blockquote>",
                    },
                    {
                        title: "Cookiebar declaration",
                        description: "Show declaration for the cookiebar",
                        content: "{$Cookiebot_CookieDeclaration}",
                    },
                ],
            }}
            onEditorChange={onEditorChange}
        />
    );
};
