import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Process from "./process";
import Overview from "./overview";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";

import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-applications", {
        page: 0,
        pageSize: defaultPageSize,
        isApproved: "",
    });

    const { loading, invoke, data } = useAPI(
        () => getAll(searchParams.isApproved, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams]
    );

    const {
        open: handleProcess,
        close: handleCloseProcess,
        visible: processToggled,
        args: processId,
    } = useDialog(invoke);

    function handleSearch(isApproved) {
        setSearchParams({ ...searchParams, isApproved, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    return (
        <div>
            <PrivateRoute
                requiredOperation="applications-view"
                path="/security/applications"
                render={(props) => (
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onProcess={handleProcess}
                        defaultDebounceInterval={defaultDebounceInterval}
                        {...props}
                    />
                )}
            />

            <Process id={processId} open={processToggled} onClose={handleCloseProcess} />
        </div>
    );
};
