import React, { useRef } from "react";
import queryString from "query-string";
import { Input } from "@material-ui/core";

import { importAssets } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import withResources from "../../../shared/textresources/withresources";
import useAPI from "../../../shared/useapi";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    validationErrors: {
        marginTop: 20
    },
}));

export default withResources(({ getResource, open, onClose }) => {
    const { invoke, data, setData, loading } = useAPI(importAssets);
    const classes = useStyles();

    const fileRef = useRef();

    if (!open) {
        return null;
    }
    
    const validationErrors = data?.errors;

    // Close when we successfully imported
    if(validationErrors?.length === 0){
        onClose(true);
        setData(null);
    }

    const onSubmit = async (values) => {
        fileRef.current.firstChild.value = "";
        await invoke(values.values.file);
    };

    const onValidate = (values, cultures, defaultCulture) => {
        var errors = {};

        return errors;
    };

    return (
        <FormDrawer
            title={getResource("Assets.asset.import.title", "Import assets from excel")}
            onClose={() => onClose(false)}
        >
            <LocalizedForm
                initialValues={{ basicInformation: true }}
                okButtonText={getResource("Assets.asset.import.okButton", "Import")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                loading={loading}
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture
                ) => {
                    return (
                        <React.Fragment>
                            <Input
                                inputProps={{ accept: ".xlsx" }}
                                type="file"
                                name="file"
                                onChange={handleChange}
                                ref={fileRef}
                            />

                            { validationErrors?.length &&
                            <div className={classes.validationErrors}>
                                <Alert severity="error">
                                    {getResource(
                                        "Assets.asset.import.error",
                                        "Could not import the file. See the errors shown below for the reasons why."
                                    )}
                                </Alert>
                                <Table size="small">
                                    <TableHead>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Assets.asset.import.error.row"
                                                defaultValue="Row"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ResourcedText
                                                resourceKey="Assets.asset.import.error.reason"
                                                defaultValue="Reason"
                                            />
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {validationErrors.map((validationError) => (
                                            <TableRow>
                                                <TableCell component="td">
                                                    {validationError.line}
                                                </TableCell>
                                                <TableCell component="td">
                                                    {validationError.error}
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </div>}
                        </React.Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
