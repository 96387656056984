import React from "react";
import { LinearProgress, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import useAPI from "../../../shared/useapi";
import FormattedDate from "../../../shared/formattedDate";
import { getMovements } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import MovementTypes from "./movementtypes";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    accordion: {
        margin: "0 0 !important",

        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
        },

        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "12px 0",
        },
    },

    accordionSummary: {
        backgroundColor: theme.palette.primary.light,

        "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
        },
    },

    dl: {
        marginBottom: 0,
        width: "100%",

        "& dt, & dd": {
            display: "inline-block",
            verticalAlign: "top",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            "-webkit-hyphens": "auto",
            "-moz-hyphens": "auto",
            hyphens: "auto",
        },

        "& dt": {
            width: 180,
            maxWidth: "50%",
            paddingRight: 10,
        },

        "& dd": {
            width: "calc(100% - 180px)",
            minWidth: "50%",
            fontStyle: "italic",
            margin: 0,
        },
    },
}));

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return null;
    }

    const classes = useStyles();

    const { loading, data } = useAPI(() => getMovements(id), true, [id]);

    const getMovementSummary = (movement) => {
        switch (movement.movementType) {
            case MovementTypes.assign_asset_collection:
                return (
                    <ResourcedText
                        resourceKey="Assets.asset.movements.assignToAssetCollection"
                        defaultValue="Added to collection <u>{newAssetCollectionName}</u>"
                        tokens={movement}
                    />
                );
            case MovementTypes.move_asset_collection:
                return (
                    <ResourcedText
                        resourceKey="Assets.asset.movements.moveToAssetCollection"
                        defaultValue="Moved from collection <u>{previousAssetCollectionName}</u> to <u>{newAssetCollectionName}</u>"
                        tokens={movement}
                    />
                );
            case MovementTypes.remove_asset_collection:
                return (
                    <ResourcedText
                        resourceKey="Assets.asset.movements.removeFromAssetCollection"
                        defaultValue="Removed from collection <u>{previousAssetCollectionName}</u>"
                        tokens={movement}
                    />
                );
            case MovementTypes.move_company:
                return (
                    <ResourcedText
                        resourceKey="Assets.asset.movements.moveToCompany"
                        defaultValue="Moved from company <strong>{previousCompanyName}</strong> to <strong>{newCompanyName}</strong>"
                        tokens={movement}
                    />
                );
            case MovementTypes.move_company_asset_collection:
                return (
                    <ResourcedText
                        resourceKey="Assets.asset.movements.moveToCompanyAssetCollection"
                        defaultValue="Moved from company <strong>{previousCompanyName}</strong> to <strong>{newCompanyName}</strong> <u>({newAssetCollectionName})</u>"
                        tokens={movement}
                    />
                );
            default:
                return "";
        }
    };

    const getMovementScope = (movement) => {
        if (
            movement.movementType === MovementTypes.assign_asset_collection ||
            movement.movementType === MovementTypes.move_asset_collection ||
            movement.movementType === MovementTypes.remove_asset_collection
        ) {
            return getResource("Assets.asset.movements.assetCollectionScope", "Collection");
        }
        if (
            movement.movementType === MovementTypes.move_company ||
            movement.movementType === MovementTypes.move_company_asset_collection
        ) {
            return getResource("Assets.asset.movements.companyScope", "Company");
        }
        return "";
    };

    var isLoaded = !loading;
    return (
        <FormDrawer
            title={getResource("Assets.asset.movements.title", "History of movements")}
            onClose={() => onClose(false)}
            isDirty={false}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <React.Fragment>
                    {(!data || data.length === 0) && (
                        <ResourcedText
                            resourceKey="Assets.asset.movements.noMovements"
                            defaultValue="This asset has not been moved to another collection or company."
                        />
                    )}

                    {data && data.length > 0 && (
                        <React.Fragment>
                            <ResourcedText
                                resourceKey="Assets.asset.movements.text"
                                defaultValue="All movements of an asset between collections and companies are recorded and can be reviewed below."
                            />
                            {data.map((movement, index) => {
                                return (
                                    <Accordion key={index} className={classes.accordion}>
                                        <AccordionSummary
                                            className={classes.accordionSummary}
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`${movement.id}-content`}
                                            id={`${movement.id}-header`}
                                        >
                                            {getMovementSummary(movement)}{" "}
                                            <FormattedDate date={movement.movementDate} />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <dl className={classes.dl}>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Assets.asset.movements.movementDate"
                                                        defaultValue="Moved on"
                                                    />
                                                </dt>
                                                <dd>
                                                    <FormattedDate
                                                        includeTime
                                                        long
                                                        date={movement.movementDate}
                                                    />
                                                </dd>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Assets.asset.movements.scope"
                                                        defaultValue="Scope"
                                                    />
                                                </dt>
                                                <dd>{getMovementScope(movement)}</dd>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Assets.asset.movements.movement"
                                                        defaultValue="Movement"
                                                    />
                                                </dt>
                                                <dd>{getMovementSummary(movement)}</dd>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Assets.asset.movements.movedBy"
                                                        defaultValue="Moved by"
                                                    />
                                                </dt>
                                                <dd>{movement.movedBy}</dd>
                                            </dl>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </FormDrawer>
    );
});
