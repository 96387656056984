import { useCallback, useRef } from "react";

const useRefCallback = () => {
    const ref = useRef(null);
    const setRef = useCallback((node) => {
        if (!node) return;

        /** setTimeout is used to make sure cultureSelectorGroup and form are present in the DOM **/
        setTimeout(() => {
            const drawerPaperRoot = node.querySelector(".MuiPaper-root");
            const cultureSelectorGroup = node.querySelector(
                ".MuiPaper-root .culture-selector-group"
            );
            const form = node.querySelector(".MuiPaper-root form");

            if (!drawerPaperRoot || !cultureSelectorGroup || !form) return;

            const selectorHeight = cultureSelectorGroup.offsetHeight;
            const selectorWidth = cultureSelectorGroup.offsetWidth;
            const selectorTopPos = cultureSelectorGroup.offsetTop;

            const handleScroll = () => {
                const makeSticky = drawerPaperRoot.scrollTop > selectorTopPos;
                if (makeSticky) {
                    cultureSelectorGroup.classList.add("--is-sticky");
                    cultureSelectorGroup.style.width = selectorWidth + "px";
                    form.style.paddingTop = selectorHeight + "px";
                } else {
                    cultureSelectorGroup.classList.remove("--is-sticky");
                    cultureSelectorGroup.style.removeProperty("width");
                    form.style.removeProperty("padding-top");
                }
            };

            handleScroll();
            drawerPaperRoot.addEventListener("scroll", handleScroll, { passive: true });
            ref.current = node;
        }, 1000);
    }, []);

    return [setRef];
};

export default useRefCallback;
