import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import AutoCompleteStatic from "../../../../shared/autocompleteStatic";

const useStyles = makeStyles((theme) => ({
    futureIndication: {
        height: 125,
    },
}));

const FutureValues = withResources(({ getResource, quickScan, onChange }) => {
    const availableMonths = [
        { id: 12, name: `12 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 24, name: `24 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 36, name: `36 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 48, name: `48 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 60, name: `60 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 72, name: `72 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
        { id: 84, name: `84 ${getResource("Valuation.quickScan.futureValues.months", "months")}` },
    ];

    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.futureIndication}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={Boolean(quickScan.purchaseFutureValues)}
                            onChange={(e) => onChange(e)}
                            name="purchaseFutureValues"
                        />
                    }
                    label={getResource(
                        "Valuation.quickScan.futureValues.purchaseFutureValues",
                        "Purchase future value indication"
                    )}
                    variant="outlined"
                />
                {quickScan.purchaseFutureValues && (
                    <AutoCompleteStatic
                        name="futureMonths"
                        label={getResource(
                            "Valuation.quickScan.futureValues.futureMonths",
                            "Months in the future"
                        )}
                        fullWidth
                        isMedium
                        value={quickScan.futureMonths}
                        onSelection={(e) => onChange(e)}
                        options={availableMonths}
                        disableClearable
                        required
                    />
                )}
            </div>
        </React.Fragment>
    );
});

export default FutureValues;
