import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    taskFooter: {
        padding: theme.spacing(2, 3),

        [theme.breakpoints.up(800)]: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
    },

    taskFooterComments: {
        paddingBottom: theme.spacing(2),
        width: "100%",

        [theme.breakpoints.up(800)]: {
            paddingRight: theme.spacing(2),
            width: "calc(100% - 375px)",
        },
    },

    taskFooterSignOff: {
        paddingBottom: theme.spacing(2),

        [theme.breakpoints.up(800)]: {
            width: 375,
            paddingLeft: theme.spacing(2),
        },

        "& dl": {
            display: "block",

            "& dt": {
                marginBottom: 0,
            },

            "& dt, & dd": {
                width: "auto",
            },

            [theme.breakpoints.up(450)]: {
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",

                "& > div:first-child": {
                    paddingRight: theme.spacing(1),
                },
            },
        },
    },

    heading: {
        marginBottom: theme.spacing(2),
    },
}));

export default useStyles;
