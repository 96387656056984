import React, { useContext } from "react";
import { LinearProgress } from "@material-ui/core";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import ScheduledEventEditor, {
    validate as validateScheduledEvent,
    correctTimesOnSubmit,
} from "../components/scheduledEventEditor";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";

export default withResources((props) => {
    const { getResource, open, onClose, start, end, allDay, referenceId, referenceType } = props;

    if (!open) {
        return null;
    }

    const { selectedTimezone } = useContext(LocalizationContext);

    const { loading, invoke } = useAPI((values) => add(values));

    const onSubmit = async (values) => {
        var copyValues = {
            scheduledEvent: correctTimesOnSubmit(values.values.scheduledEvent, selectedTimezone),
        };
        if (await invoke(copyValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        var errors = {};

        validateScheduledEvent(values.scheduledEvent, "scheduledEvent", errors, getResource, false);

        return errors;
    };

    var isLoaded = true;

    const [isDirty, setIsDirty] = React.useState(false);

    return (
        <FormDrawer
            title={getResource("Planning.scheduledEvent.add.title", "Create event")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        scheduledEvent: {
                            start: start,
                            end: end,
                            allDay: allDay,
                            exactLocationKnown: false,
                            referenceType: referenceType,
                            referenceId: referenceId,
                        },
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Planning.scheduledEvent.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture,
                        resetValues
                    ) => (
                        <ScheduledEventEditor
                            onScheduledEventChanged={(event) =>
                                handleChange({ target: { name: "scheduledEvent", value: event } })
                            }
                            scheduledEvent={values.scheduledEvent}
                            errors={errors["scheduledEvent"]}
                        />
                    )}
                />
            )}
        </FormDrawer>
    );
});
