import React, { useState, useContext } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { getByReference } from "./dataservice";

import ReferenceContext from "../../../shared/reference/referenceContext";
import withResources from "../../../shared/textresources/withresources";
import Create from "./create";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Download from "./download";

const DownloadOrCreateDialog = ({ onDownload, onCreate, onClose, referenceType }) => {
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="download-or-create-invoice-dialog-title"
            open={true}
            size="small"
        >
            <DialogTitle id="download-or-create-invoice-dialog-title">
                <ResourcedText
                    resourceKey="Administration.invoice.downloadOrCreateDialog.title"
                    defaultValue="An invoice was already created for this {referenceType}"
                    tokens={{ referenceType }}
                />
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <ResourcedText
                        resourceKey="Administration.invoice.downloadOrCreateDialog.content"
                        defaultValue="Do you want to download the existing invoice or create a new invoice?"
                    />
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={onDownload}>
                    <ResourcedText
                        resourceKey="Administration.invoice.downloadOrCreateDialog.downloadExistingButton"
                        defaultValue="Download"
                    />
                </Button>
                <Button onClick={onCreate} color="secondary">
                    <ResourcedText
                        resourceKey="Administration.invoice.downloadOrCreateDialog.createNewButton"
                        defaultValue="Create New"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CreateWrapper = ({ getResource, referenceId, referenceType, onClose, onCreate }) => {
    const { getReferenceEntity, getEntityName } = useContext(ReferenceContext);

    const { data: reference } = useAPI(() => getReferenceEntity(referenceType, referenceId), true, [
        referenceId,
        referenceType,
    ]);

    const handleClose = (saved, invoiceId) => {
        if (saved) {
            onCreate(invoiceId);
        } else {
            onClose();
        }
    };

    if (!reference) {
        return null;
    }

    return (
        <Create
            open={true}
            title={getResource(
                `Administration.invoice.create.title.${referenceType}`,
                "Create Invoice",
                reference
            )}
            referenceId={referenceId}
            referenceType={referenceType}
            referenceText={getEntityName(referenceType, reference)}
            companyId={reference.companyId}
            description={getResource(
                `Administration.invoice.create.description.${referenceType}`,
                null,
                reference
            )}
            onClose={handleClose}
        />
    );
};

export default withResources(({ getResource, open, onClose, referenceType, referenceId }) => {
    if (!open) {
        return null;
    }

    const { data: existingInvoices, loading } = useAPI(
        () => getByReference(referenceType, referenceId),
        true
    );

    const [createVisible, setCreateVisible] = useState(false);
    const [invoiceInfo, setInvoiceInfo] = useState(null);

    const handleDownload = () => {
        const mostRecentInvoice = existingInvoices.reduce((a, b) =>
            a.created > b.created ? a : b
        );

        setInvoiceInfo({ id: mostRecentInvoice.id, directDownload: true });
    };

    if (loading) {
        return null;
    }

    if (invoiceInfo) {
        return (
            <Download
                invoiceId={invoiceInfo.id}
                directDownload={invoiceInfo.directDownload}
                open={true}
                onClose={onClose}
            />
        );
    }

    if (existingInvoices.length > 0 && !createVisible) {
        return (
            <DownloadOrCreateDialog
                referenceType={referenceType}
                onCreate={() => setCreateVisible(true)}
                onDownload={handleDownload}
                onClose={onClose}
            />
        );
    }

    return (
        <CreateWrapper
            onClose={onClose}
            onCreate={(createdInvoiceId) =>
                setInvoiceInfo({ id: createdInvoiceId, directDownload: false })
            }
            referenceType={referenceType}
            referenceId={referenceId}
            getResource={getResource}
        />
    );
});
