function getLaborTotal(hours, hourlyRate, taskHourlyRate) {
    if (hours == null ||
        (hourlyRate || taskHourlyRate) == null
    ) {
        return null;
    }
    return hours * (hourlyRate ? hourlyRate : taskHourlyRate);
}

function getMaterialTotal(quantity, pricePerUnit) {
    return quantity * pricePerUnit;
}

function getTaskLaborTotalHours(task) {
    return task.labor.reduce((total, row) => total + row.hours, 0);
}

function getTaskLaborTotal(task) {
    const laborTotals = task.labor.map((labor) =>
        getLaborTotal(labor.hours, labor.hourlyRate, task.hourlyRate)
    );
    return laborTotals.reduce((a, b) => a + b, 0);
}

function getTaskMaterialTotal(task) {
    const materialTotals = task.materials.map((material) =>
        getMaterialTotal(material.quantity, material.pricePerUnit)
    );
    return materialTotals.reduce((a, b) => a + b, 0);
}

function getTaskTotal(task) {
    return getTaskLaborTotal(task) + getTaskMaterialTotal(task);
}

function getWorkOrderTotalHours(workOrder) {
    return workOrder.tasks.reduce((total, task) => total + getTaskLaborTotalHours(task), 0);
}

function getWorkOrderTotal(workOrder) {
    const taskTotals = workOrder.tasks.map((task) => getTaskTotal(task));
    return taskTotals.reduce((a, b) => a + b, 0);
}

function getWorkOrderEstimationTotal(workOrder) {
    const taskEstimationTotals = workOrder.tasks.map(
        (task) => task.laborEstimated + task.materialsEstimated
    );
    return taskEstimationTotals.reduce((a, b) => a + b, 0);
}

export {
    getLaborTotal,
    getMaterialTotal,
    getTaskLaborTotalHours,
    getTaskLaborTotal,
    getTaskMaterialTotal,
    getTaskTotal,
    getWorkOrderTotalHours,
    getWorkOrderTotal,
    getWorkOrderEstimationTotal,
};
