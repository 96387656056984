import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "none",
        backgroundColor: theme.palette.primary.darkest,
    },

    mobileMenuOpen: {
        position: "fixed",
        top: 95,
        left: 5,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: "white !important",

        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },

    logo: {
        display: "block",
        maxHeight: 28,
        maxWidth: "calc(100vw - 205px)",
    },

    flexGrow: {
        flexGrow: 1,
    },

    logoutButton: {
        marginLeft: theme.spacing(1),
    },

    logoutIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
