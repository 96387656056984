import React, { useContext, Fragment } from "react";
import { Grid, Checkbox, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import _ from "underscore";
import { Slot } from "../../../shared/slotmachine/index";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import Header from "../../../shared/header";
import Search from "../../../shared/search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import withResources from "../../../shared/textresources/withresources";
import PagedListContent from "../component/pagedListContent";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import clsx from "clsx";
import {
    Backspace as RemoveIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    DateRange as TimelineIcon,
    List as EventsIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    checkboxCell: {
        width: 52,
        cursor: "auto",

        "& + td, & + th": {
            paddingLeft: 8,
        },
    },
}));

const Overview = withResources(
    ({
        getResource,
        asset,
        data,
        history,
        availablePageSizes,
        loading,
        searchParams,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onInstall,
        onEdit,
        onRemove,
        onRemoveSelected,
        defaultDebounceInterval,
        selectedComponents,
        onSelectComponent,
        onSelectAllComponents,
        onDeselectAllComponents,
    }) => {
        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

        data = data || {};
        const classes = useStyles();
        const items = data.items;
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);
        const { companyScope } = useContext(WorkingScopeContext);

        var assetName =
            asset &&
            ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                `${asset.manufacturerName} / ${asset.assetModel.name}`);

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Asset.assetComponents.overview.header.title",
                        "Components of {assetName}",
                        { assetName }
                    )}
                    breadcrumb={asset.assetCategories}
                    renderActions={() => {
                        if (selectedComponents.length > 0) {
                            return (
                                <ActionMenu
                                    buttonLabel={getResource(
                                        "Asset.assetComponents.bulkMenuLabel",
                                        "Actions"
                                    )}
                                    actions={[
                                        {
                                            text: getResource(
                                                "Asset.assetComponents.overview.removeButton",
                                                "Remove"
                                            ),
                                            icon: <RemoveIcon />,
                                            requiredOperation: {
                                                name: "components-remove",
                                                companyId: companyScope.id,
                                            },
                                            onClick: () => onRemoveSelected(selectedComponents),
                                        },
                                    ]}
                                />
                            );
                        }

                        return (
                            <Fragment>
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onInstall}
                                    buttonText={getResource(
                                        "Asset.assetComponents.overview.new",
                                        "Add"
                                    )}
                                    requiredOperation={{
                                        name: "components-install",
                                        companyId: asset.companyId,
                                        assetCollectionId: asset.assetCollectionIds,
                                    }}
                                />

                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Asset.assetComponents.viewTimelineButton",
                                                "View timeline"
                                            ),
                                            icon: <TimelineIcon />,
                                            requiredOperation: {
                                                name: "scheduled-events-view",
                                                companyId: asset.companyId,
                                                assetCollectionId: asset.assetCollectionIds,
                                            },
                                            onClick: () =>
                                                history.push(`/planning/timeline/${asset.id}`),
                                        },
                                        {
                                            text: getResource(
                                                "Asset.assetComponents.viewEventsButton",
                                                "View events"
                                            ),
                                            icon: <EventsIcon />,
                                            requiredOperation: {
                                                name: "scheduled-events-view",
                                                companyId: asset.companyId,
                                                assetCollectionId: asset.assetCollectionIds,
                                            },
                                            onClick: () =>
                                                history.push(`/planning/events/assets/${asset.id}`),
                                        },
                                    ]}
                                />
                            </Fragment>
                        );
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <PagedList
                            loading={loading}
                            itemCount={data.total}
                            noResultsText={getResource(
                                "Asset.assetComponents.overview.noResults",
                                "No components installed"
                            )}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            availablePageSizes={availablePageSizes}
                            page={searchParams.page}
                            pageSize={searchParams.pageSize}
                            renderSearch={() => (
                                <Search
                                    onSearch={onSearchDebounced}
                                    searchParams={searchParams}
                                    labelResourceKey={
                                        "Asset.assetComponent.overview.search.searchField"
                                    }
                                    labelDefaultValue={"Keyword search"}
                                />
                            )}
                        >
                            <PagedListContent
                                items={items}
                                handleRowClick={(row) =>
                                    history.push(`/assets/components/${row.id}`)
                                }
                                isAssetComponentList
                                getRequiredOperationForRowClick={(row) => {
                                    return {
                                        name: "components-view",
                                        companyId: row.companyId,
                                        assetCollectionId: row.assetCollectionIds,
                                    };
                                }}
                                renderSelectAllComponents={() => (
                                    <TableCell
                                        className={clsx(classes.checkboxCell, "checkboxCell")}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Checkbox
                                            checked={
                                                items && selectedComponents
                                                    ? items.every((row) => selectedComponents.includes(row.id))
                                                    : false
                                            }
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? onSelectAllComponents()
                                                    : onDeselectAllComponents()
                                            }
                                            name="SelectAll"
                                            size="small"
                                        />
                                    </TableCell>
                                )}
                                renderSelectComponent={(row) => (
                                    <TableCell
                                        className={classes.checkboxCell}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Checkbox
                                            checked={selectedComponents.filter((x) => x === row.id).length > 0}
                                            onChange={(e) => onSelectComponent(e)}
                                            name={row.id.toString()}
                                            size="small"
                                        />
                                    </TableCell>
                                )}
                                renderActionMenu={(row) => (
                                    <ActionMenu
                                        actions={[
                                            {
                                                text: getResource(
                                                    "Asset.assetComponents.overview.viewButton",
                                                    "View"
                                                ),
                                                icon: <ViewIcon />,
                                                itemId: row.id,
                                                requiredOperation: {
                                                    name: "components-view",
                                                    companyId: row.companyId,
                                                    assetCollectionId: row.assetCollectionIds,
                                                },
                                                onClick: () =>
                                                    history.push(`/assets/components/${row.id}`),
                                            },
                                            {
                                                showOnlyWhen: !row.isSealed,
                                                text: getResource(
                                                    "Asset.assetComponents.overview.editButton",
                                                    "Edit"
                                                ),
                                                icon: <EditIcon />,
                                                itemId: row.id,
                                                requiredOperation: {
                                                    name: "components-update",
                                                    companyId: row.companyId,
                                                    assetCollectionId: row.assetCollectionIds,
                                                },
                                                onClick: () => onEdit(row.id),
                                            },
                                            {
                                                text: getResource(
                                                    "Asset.assetComponents.overview.removeButton",
                                                    "Remove"
                                                ),
                                                icon: <RemoveIcon />,
                                                itemId: row.id,
                                                requiredOperation: {
                                                    name: "components-remove",
                                                    companyId: row.companyId,
                                                    assetCollectionId: row.assetCollectionIds,
                                                },
                                                onClick: () => onRemove(row.id),
                                            },
                                        ]}
                                    />
                                )}
                            />
                        </PagedList>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Slot name="asset-summary" list={true} asset={asset} />
                    </Grid>
                </Grid>
            </ContentHolder>
        );
    }
);

export default Overview;
