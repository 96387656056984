import React, { useContext } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";

import useAuthorization from "../../../shared/authentication/useauthorization";
import withResources from "../../../shared/textresources/withresources";

const AddEventOrWorkOrder = withResources(
    ({
        getResource,
        open,
        assetCollectionIds,
        companyId,
        onClose,
        onCreateWorkOrder,
        onCreateScheduledEvent,
    }) => {
        if (!open) {
            return null;
        }

        const { isAuthorized: isAuthorizedToCreateWorkOrders } = useAuthorization({
            name: "work-orders-create",
            companyId: companyId,
            assetCollectionId: assetCollectionIds,
        });

        const { isAuthorized: isAuthorizedToCreateScheduledEvents } = useAuthorization({
            name: "scheduled-events-create",
            companyId: companyId,
            assetCollectionId: assetCollectionIds,
        });
        if (isAuthorizedToCreateWorkOrders == null || isAuthorizedToCreateScheduledEvents == null) {
            return null;
        }

        if (isAuthorizedToCreateWorkOrders && !isAuthorizedToCreateScheduledEvents) {
            onCreateWorkOrder();
            return null;
        } else if (!isAuthorizedToCreateWorkOrders && isAuthorizedToCreateScheduledEvents) {
            onCreateScheduledEvent();
            return null;
        }

        return (
            <Dialog
                onClose={() => onClose()}
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogActions>
                    <Button
                        onClick={() => {
                            onClose();
                            onCreateWorkOrder();
                        }}
                    >
                        {getResource(
                            "Planning.calendar.actions.createWorkOrder",
                            "Create work order"
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            onClose();
                            onCreateScheduledEvent();
                        }}
                    >
                        {getResource(
                            "Planning.calendar.actions.createScheduledEvent",
                            "Create scheduled event"
                        )}
                    </Button>

                </DialogActions>
            </Dialog>
        );
    }
);

export default AddEventOrWorkOrder;
