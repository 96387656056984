import React, { useContext, useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import AuthorizationContext from "../../../shared/authentication/authenticationcontext";
import { getAllForAssetContext as getAssetModels } from "../../library/assetmodels/dataservice";
import { getAllForAssetContext as getManufacturers } from "../../library/manufacturers/dataservice";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import useAPI from "../../../shared/useapi";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);
    const { authorizationService } = useContext(AuthorizationContext);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const { data: allManufacturers } = useAPI(() => getManufacturers(null, null, null), true, [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    const { data: allAssetModels } = useAPI(
        () => getAssetModels(null, searchValues.manufacturerId, null, null),
        true,
        [searchValues.manufacturerId, companyScope.id, assetCollectionScope.id]
    );

    const statusOperationsMapping = {
        Draft: "asset-valuations-create",
        Released: "asset-valuations-view-released",
        Published: "asset-valuations-view-published",
    };

    const filterStatuses = Object.keys(statusOperationsMapping)
        .filter((status) => {
            var operation = statusOperationsMapping[status];
            return authorizationService.isAuthorized(operation);
        })
        .map((x) => ({ id: x, name: getResource(`Valuation.status.${x}`, x) }));

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.manufacturerId, values.assetModelId, values.statuses);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    autoFocus
                    labelResourceKey="Valuation.assetValuation.search.searchField"
                    labelDefaultValue="Search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={searchValues.searchText ? searchValues.searchText : ""}
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                    onChange={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            searchText: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Valuation.modelValuation.search.manufacturerField",
                        "Manufacturer"
                    )}
                    value={searchValues.manufacturerId}
                    options={allManufacturers && allManufacturers.items}
                    disabled={Boolean(searchValues.assetModelId)}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            manufacturerId: e.target.value,
                        })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource("Valuation.assetValuation.search.modelLabel", "Model")}
                    value={searchValues.assetModelId}
                    options={allAssetModels && allAssetModels.items}
                    getOptionLabel={(option) => (
                        <>
                            {option.manufacturerName} - {option.name}
                        </>
                    )}
                    onSelection={(e) => {
                        const selectedAsset = allAssetModels.items.filter(
                            (item) => item.id === e.target.value
                        )[0];
                        const manufacturerId = selectedAsset
                            ? selectedAsset.manufacturerId
                            : searchValues.manufacturerId;

                        handleValuesChanged({
                            ...searchValues,
                            assetModelId: e.target.value,
                            manufacturerId: manufacturerId,
                        });
                    }}
                />,
                <AutoCompleteStatic
                    label={getResource(
                        "Valuation.assetValuation.search.statusesField",
                        "Valuation status"
                    )}
                    multiple
                    value={searchValues.statuses}
                    options={filterStatuses}
                    onSelection={(e) =>
                        handleValuesChanged({
                            ...searchValues,
                            statuses: e.target.value,
                        })
                    }
                />,
            ]}
        />
    );
});

export default Search;
