import React, { Component } from "react";
import { Paper, AppBar, Box, Tabs, Tab, Typography } from "@material-ui/core";
import {
    Menu as MenuIcon,
    SettingsApplications as SettingsIcon,
    Tune as TuneIcon,
    AddCircleOutline as ConvertIcon,
    Build as ExecuteIcon,
    Info as InfoIcon,
} from "@material-ui/icons";

// data and services
import Data from "./data.json";
import DataTable from "./ui/datatable.js";
import DataMutations from "./helpers/datamutations.js";
import ValuationService from "./helpers/valuationService.js";
import CalculationService from "./helpers/calculationService.js";
import DataValidator from "./helpers/datavalidator.js";
import MathHelper from "./helpers/mathHelper.js";

// UI components
import ChooseCategoryModal from "./ui/ChooseCategoryModal.js";
import MessageModal from "./ui/MessageModal.js";
import Notification from "./ui/Notification.js";
import Highcharts from "./ui/Highcharts.js";
import ActionButtons from "./ui/ActionButtons.js";
import ValuationInfo from "./ui/ValuationInfo.js";
import Convert from "./ui/Convert.js";
import Execute from "./ui/Execute.js";
import Settings from "./ui/Settings.js";
import AdjustBetweenPoints from "./ui/AdjustBetweenPoints.js";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        //Always render component, otherwise something goes wrong with getting its data
        <div
            style={{
                visibility: value === index ? "visible" : "hidden",
                height: value === index ? "auto" : "0",
            }}
        >
            <Typography
                component="div"
                role="tabpanel"
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                <Box p={3}>{children}</Box>
            </Typography>
        </div>
    );
}

function TabProps(index) {
    return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
    };
}

class Graph extends Component {
    constructor(props) {
        super(props);

        const DM = new DataMutations();
        const MH = new MathHelper();

        this.state = {
            activeTab: 0,
            errors: [],
            isTouchDevice: false,
            data: {
                currentObjectId: undefined,
                FullMonthNames: Data.FullMonthNames,
                datamutations: DM,
                math: MH,
                valuationInfo: {
                    curveRatio: null,
                    liquidationValueDifferenceAsFixed: null,
                    liquidationValueDifferenceAsPercentage: null,
                    fairMarketValue: null,
                    remainingValueAfterTechLife: null,
                    technicalLife: null,
                    startBuildYear: null,
                    endBuildYear: null,
                    valutaId: null,
                    comment: undefined,
                },
                settings: {
                    numberOfAdjustments: Data.NumberOfAdjustments,
                    numberOfAdjustmentsEnabled: Data.NumberOfAdjustmentsEnabled,
                    liquidationValuePercentage: Data.LiquidationValuePercentage,
                    liquidationValuePercentageEnabled: Data.LiquidationValuePercentageEnabled,
                },
                adjustBetweenPoints: {
                    startPoint: Data.AdjustBetweenPointsStartPoint,
                    endPoint: Data.AdjustBetweenPointsEndPoint,
                    curveRatio: Data.CurveRatio,
                    percent: Data.FairMarketValuePercentage,
                    fixed: Data.FairMarketValueFixed,
                },
                execute: {
                    month: Data.ExecuteMonth,
                    year: Data.ExecuteYear,
                    fairMarketValue: null,
                    liquidationValue: null,
                },
                chart: {
                    zoomType: "xy",
                    animation: false,
                    plotBorderWidth: 1,
                    height: 450,
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: "",
                },
                yAxis: {
                    title: {
                        text: "",
                    },
                    tickAmount: 10,
                },
                legend: {
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",
                },
                plotOptions: {
                    scatter: {
                        animation: false,
                        marker: {
                            radius: 3,
                            symbol: "circle",
                        },
                    },
                    series: {
                        cursor: "pointer",
                        dragPrecisionY: 1,
                        stickyTracking: false,
                    },
                },
                tooltip: {
                    backgroundColor: "none",
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    padding: 5,
                    yDecimals: 2,
                    pointFormat: "{point.buildYear}: <b>{point.y}</b><br/>",
                    positioner: function () {
                        return { x: 50, y: 0 };
                    },
                },
                seriesFetchCounter: 0,
                series: [
                    {
                        type: "scatter",
                        name: "Fair market value",
                        color: "rgb(255, 0, 0)",
                        draggableY: true,
                        dragMinY: 0,
                        data: [],
                    },
                    {
                        type: "scatter",
                        name: "Liquidation value",
                        color: "rgb(0, 255, 0)",
                        draggableY: true,
                        data: [],
                    },
                    {
                        type: "scatter",
                        name: "Average purchase price",
                        color: "rgb(0, 0, 255)",
                        draggableY: false,
                        daggeableX: false,
                        data: [],
                    },
                ],
            },
        };
    }

    showErrors(error) {
        this.refs.notification.showError(
            "Failed to fetch graph data",
            "An error occurred while fetching the graph data. Please try again later."
        );
    }

    componentDidMount() {
        // get input vars and save them to the state
        var stateData = this.state.data;

        const VS = new ValuationService();
        const DV = new DataValidator();
        const valuation = this.props.valuation;

        VS.isUserValuationHistoryViewer(
            (data) => {
                if (data) {
                    // get historical data ( = historical purchase price(s))
                    VS.getHistoricalPurchasePrices(valuation.id, (data) => {
                        let app = data.historicalPurchasePrices.map((item, index) => {
                            let arr = [];
                            let toHalfYearPeriod = item.period / 6; // we calculate the precise period here instead of in SQL, we do not want rounded values.

                            arr.push(toHalfYearPeriod);
                            arr.push(item.averagePurchasePrice);
                            arr.push(item.buildYear);
                            arr.push(item.purchaseYear);

                            return arr;
                        });

                        stateData = this.state.data;
                        stateData.series[2].data = app;
                        stateData.seriesFetchCounter++;

                        this.setState({ data: stateData });
                    });
                } else {
                    stateData = this.state.data;
                    stateData.series[2].data = [];
                    stateData.seriesFetchCounter++;

                    this.setState({ data: stateData });
                }
            },
            (err) => {
                this.showErrors({ Method: "isUserValuationHistoryViewer", Error: err });
            }
        );

        stateData.valuationInfo = {
            curveRatio: valuation.curveRatio,
            liquidationValueDifferenceAsFixed: valuation.liquidationValueDifferenceAsFixed,
            liquidationValueDifferenceAsPercentage:
                valuation.liquidationValueDifferenceAsPercentage,
            fairMarketValue: valuation.fairMarketValue,
            remainingValueAfterTechLife: valuation.remainingValueAfterTechnicalLife,
            technicalLife: valuation.technicalLife,
            startBuildYear: valuation.startBuildYear,
            endBuildYear: valuation.endBuildYear,
            valutaId: valuation.currencyId,
            comment: valuation.comment,
        };

        stateData.title.text = valuation.asset
            ? valuation.asset.name
            : valuation.manufacturerName + " / " + valuation.assetModel.name;
        stateData.currentObjectId = valuation.id;
        stateData.currentAssetModelId = valuation.assetModel && valuation.assetModel.id;
        stateData.currentAssetId = valuation.asset && valuation.asset.id;
        this.setState({ data: stateData });

        // now check if we have to generate the periods
        if (
            typeof valuation.periodicValuationValues === "undefined" ||
            (typeof valuation.periodicValuationValues !== "undefined" &&
                valuation.periodicValuationValues.length === 0)
        ) {
            let curveRatio = DV.isFloatAndGreaterThanZero(stateData.valuationInfo.curveRatio)
                ? parseFloat(stateData.valuationInfo.curveRatio)
                : null;
            let liquidationValueFixed = DV.isNumberAndGreaterThanZero(
                stateData.valuationInfo.liquidationValueDifferenceAsFixed
            )
                ? Number(stateData.valuationInfo.liquidationValueDifferenceAsFixed)
                : null;
            let liquidationValuePercentage = DV.isNumberAndGreaterThanOrEqualToZero(
                stateData.valuationInfo.liquidationValueDifferenceAsPercentage
            )
                ? Number(stateData.valuationInfo.liquidationValueDifferenceAsPercentage)
                : null;

            let args = {
                data: {
                    CurveRatio: curveRatio,
                    FairMarketValue: valuation.fairMarketValue,
                    RemainingValueAfterTechnicalLife: valuation.remainingValueAfterTechnicalLife,
                    LiquidationValueDifferenceAsFixed: liquidationValueFixed,
                    LiquidationValueDifferenceAsPercentage: liquidationValuePercentage,
                    TechnicalLife: valuation.technicalLife,
                },
            };

            const CS = new CalculationService();
            CS.generatePeriods(
                args,
                (data) => {
                    stateData = this.state.data;
                    stateData.series[0].data = data.fairMarketValues;
                    stateData.series[1].data = data.liquidationValues;
                    stateData.seriesFetchCounter++;

                    this.setState({ data: stateData });
                },
                (err) => {
                    this.showErrors({ Method: "isUserValuationHistoryViewer", Error: err });
                }
            );
        } else {
            // we already have the periods, so render the chart
            stateData = this.state.data;

            stateData.series[0].data = valuation.periodicValuationValues.map((item, index) => {
                return item.fairMarketValue;
            });

            stateData.series[1].data = valuation.periodicValuationValues.map((item, index) => {
                return item.liquidationValue;
            });

            stateData.seriesFetchCounter++;

            this.setState({ data: stateData });
        }

        let that = this;
        // detect touch
        window.addEventListener("touchstart", function () {
            // the user touched the screen
            let stateData = that.state.data;

            if (!stateData.isTouchDevice) {
                stateData.isTouchDevice = true;
                that.setState({ data: stateData });
            }
        });
    }

    updateDataTable() {
        this.props.onGetDirty();
        // force componentWillReceiveProps in DataTable.js
        let newData = this.state.data;
        let chart = this.refs.highcharts.refs.highcharts.getChart();

        // sync the state data with the actual chart data
        for (let i = 0; i <= 1; i++) {
            newData.series[i].data = chart.series[i].data.map((item, index) => {
                return item.y;
            });
        }

        this.setState({ data: newData });
    }

    handleChange(data, name) {
        this.props.onGetDirty();
        let newData = this.state.data;

        if (name.indexOf("/") !== -1) {
            let path = name.split("/");
            newData[path[0]][path[1]] = data.type === "checkbox" ? data.checked : data.value;
        } else {
            newData[name] = data.type === "checkbox" ? data.checked : data.value;
        }

        this.setState({ data: newData });
    }

    handleValue(e, data) {
        this.props.onGetDirty();

        let newData = this.state.data;
        const conversionValue = this.state.data.ConvertToValue.replace(",", ".");
        let fairMarketValue = newData.series[0].data.map((item) => {
            return Math.round(Number(item) * Number(conversionValue));
        });

        let liquidationValue = newData.series[1].data.map((item) => {
            return Math.round(Number(item) * Number(conversionValue));
        });

        newData.series[0].data = fairMarketValue;
        newData.series[1].data = liquidationValue;
        this.setState({ data: newData });

        // update the points in the chart (y only)
        this.refs.highcharts.redrawChart(newData);
    }

    handleTabItemClick = (event, newActiveTab) => {
        const state = this.state;
        state.activeTab = newActiveTab;

        this.setState(state);
    };

    render() {
        const chartPanes = [
            {
                tab: <Tab icon={<MenuIcon />} key="menu" label="Menu" {...TabProps(0)} />,
                pane: (
                    <TabPanel key="0" value={this.state.activeTab} index={0}>
                        <ActionButtons
                            config={this.props.config}
                            notificationRef={this.refs.notification}
                            messageModelRef={this.refs.messageModel}
                            chooseCategoryModalRef={this.refs.chooseCategoryModal}
                            data={this.state.data}
                            highchartsRef={this.refs.highcharts}
                            openQuestionnaire={this.props.openQuestionnaire}
                            reloadValuation={this.props.reloadValuation}
                            getResource={this.props.getResource}
                        />
                    </TabPanel>
                ),
            },
            {
                tab: (
                    <Tab icon={<SettingsIcon />} key="settings" label="Settings" {...TabProps(1)} />
                ),
                pane: (
                    <TabPanel key="1" value={this.state.activeTab} index={1}>
                        <Settings
                            settings={this.state.data.settings}
                            onHandleChange={this.handleChange.bind(this)}
                        />
                    </TabPanel>
                ),
            },
            {
                tab: (
                    <Tab
                        icon={<TuneIcon />}
                        key="adjustbetweenpoints"
                        label="Adjust between points"
                        {...TabProps(2)}
                    />
                ),
                pane: (
                    <TabPanel key="2" value={this.state.activeTab} index={2}>
                        <AdjustBetweenPoints
                            highchartsRef={this.refs.highcharts}
                            adjustBetweenPointsData={this.state.data.adjustBetweenPoints}
                            onHandleChange={this.handleChange.bind(this)}
                        />
                    </TabPanel>
                ),
            },
            {
                tab: <Tab icon={<ConvertIcon />} key="convert" label="Convert" {...TabProps(3)} />,
                pane: (
                    <TabPanel key="3" value={this.state.activeTab} index={3}>
                        <Convert
                            convertToValue={this.state.data.ConvertToValue}
                            onHandleValue={this.handleValue.bind(this)}
                            onHandleChange={this.handleChange.bind(this)}
                        />
                    </TabPanel>
                ),
            },
            {
                tab: <Tab icon={<ExecuteIcon />} key="execute" label="Execute" {...TabProps(4)} />,
                pane: (
                    <TabPanel key="4" value={this.state.activeTab} index={4}>
                        <Execute
                            valuationData={this.state.data.valuationInfo}
                            highchartsRef={this.refs.highcharts}
                        />
                    </TabPanel>
                ),
            },
            {
                tab: (
                    <Tab
                        icon={<InfoIcon />}
                        key="valuationinfo"
                        label="Valuation info"
                        {...TabProps(5)}
                    />
                ),
                pane: (
                    <TabPanel key="5" value={this.state.activeTab} index={5}>
                        <ValuationInfo
                            config={this.props.config}
                            data={this.state.data}
                            notificationRef={this.refs.notification}
                            valuationData={this.state.data.valuationInfo}
                            onHandleChange={this.handleChange.bind(this)}
                        />
                    </TabPanel>
                ),
            },
        ];

        return (
            <div className="App">
                <ChooseCategoryModal ref="chooseCategoryModal" {...this.props} />

                <MessageModal ref="messageModel" />

                <Paper>
                    <AppBar position="static" color="secondary">
                        <Tabs value={this.state.activeTab} onChange={this.handleTabItemClick}>
                            {chartPanes.map((tabs) => tabs.tab)}
                        </Tabs>
                    </AppBar>
                    {chartPanes.map((tabs) => tabs.pane)}
                </Paper>
                <Notification ref="notification" />
                <Paper style={{ marginTop: 10, padding: 10 }}>
                    <Highcharts
                        ref="highcharts"
                        config={this.state.data}
                        onUpdateDataTable={this.updateDataTable.bind(this)}
                    />
                    <DataTable data={this.state.data} highchartsRef={this.refs.highcharts} />
                </Paper>
            </div>
        );
    }
}

export default Graph;
