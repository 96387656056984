import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(assetCategoryId, page, pageSize) {
    const params = {
        page,
        pageSize,
        assetCategoryId,
    };
    const url = `/api/valuation/questionnaire/bycategory?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/valuation/questionnaire/${id}`;

    return await interceptedFetch(url);
}

async function getQuestionsForAssetCategory(assetCategoryId) {
    const url = `/api/valuation/questionnaire/asset-category=${assetCategoryId}/questions`;

    return await interceptedFetch(url);
}

async function getQuestionsForAssetModel(assetModelId) {
    const url = `/api/valuation/questionnaire/asset-model=${assetModelId}/questions`;

    return await interceptedFetch(url);
}

async function getQuestionsForAsset(assetId) {
    const url = `/api/valuation/questionnaire/asset=${assetId}/questions`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/valuation/questionnaire", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/valuation/questionnaire/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(selectedId) {
    return await interceptedFetch(`/api/valuation/questionnaire/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export {
    getAll,
    get,
    getQuestionsForAssetCategory,
    getQuestionsForAssetModel,
    getQuestionsForAsset,
    add,
    update,
    del,
};
