import React from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import Configuration from "./cachereset";

export default () => {
    return (
        <PrivateRoute
            requiredOperation="marketplace-cache-reset"
            path="/marketplace/cache-reset"
            render={(props) => <Configuration {...props} />}
        />
    );
};
