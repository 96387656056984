import React, { useContext, useEffect } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";
import Overview from "./overview";
import { getAll } from "./dataservice";

import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-advertisements", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        type: null,
        published: null,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.type,
                searchParams.published,
                null,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.type,
            searchParams.published,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);

    function handleSearch(searchText, type, published) {
        setSearchParams({ ...searchParams, searchText, type, published, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="advertisements-view"
                path="/marketplace/advertisements"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
            <Delete
                id={deleteArgs && deleteArgs.id}
                assetName={deleteArgs && deleteArgs.assetName}
                version={deleteArgs && deleteArgs.version}
                open={deleteToggled}
                onClose={handleCloseDelete}
            />
        </React.Fragment>
    );
};
