import React from "react";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, name, factType, id, onClose }) => {
    if (!open) {
        return false;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Asset.facts.delete.header", "Delete {factType}?", {
                factType,
            })}
            buttonText={getResource("Asset.facts.delete.okButton", "Delete")}
        >
            {getResource(
                "Asset.facts.delete.deleteMessage",
                "The {factType} for {name} will be deleted. This operation cannot be undone.",
                { factType, name }
            )}
        </Confirmation>
    );
});
