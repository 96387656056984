import React, { useState, Fragment } from "react";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import {
    filter,
    getChildren,
    getParents,
    getPath,
} from "./../../library/assetcategories/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import QuestionsEditor from "./questionsEditor";
import CreditScore from "./creditScore";
import DrillDownInput from "../../../shared/drilldowninput";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(add);

    const [questions, setQuestions] = useState([]);
    const [isDirty, setIsDirty] = useState(false);

    const onSubmit = async (values) => {
        const saveValues = {
            ...values.values,
            assetCategories: values.values.assetCategories.map((item) => item.id),
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.assetCategories || values.assetCategories.length === 0) {
            errors.assetCategories = getResource(
                "Valuation.questionnaire.add.selectAssetCategory",
                "Please select an category"
            );
        }

        if (!values.questions || values.questions.length === 0) {
            errors.questions = getResource(
                "Valuation.questionnaire.add.enterQuestion",
                "Please fill in at least 1 question"
            );
        }

        return errors;
    };

    return (
        <FormDrawer
            title={getResource(
                "Valuation.questionnaire.add.title",
                "Create category questionnaire"
            )}
            onClose={() => onClose(false)}
            size="medium"
            isDirty={isDirty}
        >
            <LocalizedForm
                initialValues={{ assetCategories: [], questions: [] }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource("Valuation.questionnaire.add.okButton", "Create")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                renderForm={(culture, values, handleChange, errors) => {
                    return (
                        <Fragment>
                            <DrillDownInput
                                errors={errors}
                                autoFocus
                                name="assetCategories"
                                multiple
                                itemName={getResource(
                                    "Valuation.questionnaire.add.assetCategoryItemName",
                                    "category"
                                )}
                                label={getResource(
                                    "Valuation.questionnaire.add.assetCategoryField",
                                    "Category"
                                )}
                                isMedium
                                fullWidth
                                getParents={getParents}
                                getChildren={getChildren}
                                getPath={getPath}
                                filter={filter}
                                value={values.assetCategories}
                                disableClearable
                                setValue={(e) => handleChange(e)}
                                className="e2e_assetCategory"
                                required
                            />

                            <CreditScore questions={questions} />

                            <QuestionsEditor
                                error={"questions" in errors}
                                helperText={errors["questions"]}
                                questions={questions}
                                onQuestionsChanged={(newQuestions) => {
                                    setQuestions(newQuestions);
                                    handleChange({
                                        target: {
                                            name: "questions",
                                            value: newQuestions,
                                        },
                                    });
                                }}
                            />
                        </Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
