import React from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./components/editForm";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        validate(values, errors, getResource);

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.assetModels.edit.title", "Edit model")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        ...data,
                        assetCategoryId: data.assetCategory.id,
                        suggestManufacturer: false,
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Library.assetModels.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => (
                        <EditForm errors={errors} values={values} handleChange={handleChange} />
                    )}
                />
            )}
        </FormDrawer>
    );
});
