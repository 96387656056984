import React from "react";

import { getAll as getAssetModels } from "../../../library/assetmodels/dataservice";
import {
    getParents,
    getActiveChildren,
    getPath,
    getAll,
} from "../../../security/companies/dataservice";

import withResources from "../../../../shared/textresources/withresources";
import DatePicker from "../../../../shared/datepicker";
import AutoComplete from "../../../../shared/autocomplete";
import DrillDownInput from "../../../../shared/drilldowninput";

const Init = withResources(({ getResource, quickScan, onChange }) => {
    return (
        <React.Fragment>
            <div>
                <DrillDownInput
                    name="purchaseForCompany"
                    itemName={getResource(
                        "Valuation.quickScan.assetModelPurchase.companiesField",
                        "Company"
                    )}
                    label={getResource(
                        "Valuation.quickScan.assetModelPurchase.companiesField",
                        "Purchase for company"
                    )}
                    isMedium
                    fullWidth
                    getParents={getParents}
                    getChildren={getActiveChildren}
                    getPath={getPath}
                    filter={(searchText, noDescendantOf, page, pageSize) =>
                        getAll(searchText, noDescendantOf, true, page, pageSize)
                    }
                    value={quickScan.purchaseForCompany}
                    disableClearable
                    setValue={(e) => onChange(e)}
                    required
                />

                <AutoComplete
                    name="assetModel"
                    label={getResource(
                        "Valuation.quickScan.assetModelPurchase.assetModelField",
                        "Model"
                    )}
                    value={quickScan.assetModel}
                    onGetData={(name, page, pageSize) =>
                        getAssetModels(name, null, null, page, pageSize)
                    }
                    onSelection={(e) => onChange(e)}
                    getTextField={(field) =>
                        field.manufacturerName
                            ? `${field.manufacturerName} ${field.name}`
                            : field.name
                    }
                    renderOption={(option) => (
                        <div>
                            {option.manufacturerName} {option.name}
                        </div>
                    )}
                    disableClearable
                    required
                />
                <DatePicker
                    name="constructionDate"
                    label={getResource(
                        "Valuation.quickScan.assetModelPurchase.constructionDate",
                        "Construction date"
                    )}
                    value={quickScan.constructionDate}
                    onChange={(e) => onChange(e)}
                    disableFuture
                    required
                />
            </div>
        </React.Fragment>
    );
});

export default Init;
