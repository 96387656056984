import React, { useState } from "react";
import SnackbarContext from "./snackbarContext";

export default ({ children }) => {
    const [snackInfo, setSnackInfo] = useState(null);

    return (
        <SnackbarContext.Provider
            value={{
                snackInfo,
                setSnackInfo,
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};
