import React, { useContext } from "react";
import nlLocale from "date-fns/locale/nl";
import enLocale from "date-fns/locale/en-GB";

import LocalizationContext from "./localizationcontext";
import useAPI from "../useapi";
import { get, getUI } from "./dataservice";

import AuthenticationContext from "../authentication/authenticationcontext";

const localeMap = {
    "nl-NL": nlLocale,
    "en-GB": enLocale,
};

export default ({ children }) => {
    const { data: cultures } = useAPI(get, true);
    const { data: uiCultures } = useAPI(getUI, true);

    const defaultCulture = cultures ? cultures.filter((l) => l.default)[0].code : null;
    const defaultUiCulture = uiCultures ? uiCultures.filter((l) => l.default)[0].code : null;

    const { isAuthenticated, loggedInUser, refresh } = useContext(AuthenticationContext);

    return (
        <LocalizationContext.Provider
            value={{
                selectedTimezone: isAuthenticated ? loggedInUser.timezone : "Europe/Amsterdam",

                cultures,
                selectedCulture: isAuthenticated ? loggedInUser.contentCulture : defaultCulture,

                uiCultures,
                selectedUiCulture: isAuthenticated
                    ? loggedInUser.applicationCulture
                    : defaultUiCulture,

                refreshCultures: async () => await refresh(),

                defaultCulture,

                dateFnsLocale:
                    localeMap[isAuthenticated ? loggedInUser.applicationCulture : defaultUiCulture],
            }}
        >
            {children}
        </LocalizationContext.Provider>
    );
};
