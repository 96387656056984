import React, { Fragment, useContext } from "react";
import useAuthorization from "../../../../shared/authentication/useauthorization";
import useAPI from "../../../../shared/useapi";
import { getAll, getFactActions, getFactTypes } from "../dataservice";
import useDialog from "../../../../shared/usedialog";
import Add from "../add";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import FactsWidget from "./factsWidget";
import { config as factConfig } from "../config";

const Widget = ({ component }) => {
    const { loading, invoke, data } = useAPI(
        () => getAll(null, component.id, null, null, 1, 5, true),
        true
    );

    const { data: factTypes } = useAPI(() => getFactTypes(), true);
    const { data: factActions } = useAPI(() => getFactActions(), true);
    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const componentName =
        component &&
        component.name &&
        (component.name[selectedCulture] || component.name[defaultCulture]);

    return (
        <Fragment>
            <FactsWidget
                headerLink={`/assets/component-facts/${component.id}`}
                loading={loading}
                data={data}
                handleAdd={handleAdd}
                entity={component}
            />

            <Add
                open={addToggled}
                onClose={handleCloseAdd}
                componentId={component.id}
                name={componentName}
                factTypes={factTypes}
                factActions={factActions}
                assetTypeId={component.installedAssetTypeId}
            />
        </Fragment>
    );
};

export default ({ component }) => {
    const { isLoading, isAuthorized } = useAuthorization(
        null,
        factConfig.operationTypes.map((type) => ({
            name: `facts-view-${type}`,
            companyId: component.companyId,
            assetCollectionId: component.assetCollectionIds,
        }))
    );

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <Widget component={component} />;
};
