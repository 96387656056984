import React, { useContext } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import RoutedIndex from "../workOrder/routedIndex";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import { get as getComponent } from "../../assets/component/dataservice";

const ComponentWorkOrderList = (props) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    var componentId = props.match.params["componentId"];
    const { data: component } = useAPI(() => getComponent(componentId), true, [componentId]);

    var componentName =
        component &&
        component.name &&
        (component.name[selectedCulture] || component.name[defaultCulture]);

    return (
        <RoutedIndex
            name={componentName}
            assetId={null}
            componentId={props.match.params["componentId"]}
            {...props}
        />
    );
};

export default () => {
    return (
        <PrivateRoute
            requiredOperation={"work-orders-view"}
            path="/maintenance/component-work-orders/:componentId(\d+)"
            render={(props) => <ComponentWorkOrderList {...props} />}
        />
    );
};
