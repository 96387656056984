import { useContext } from "react";
import LocalizationContext from "./localization/localizationcontext";

export default ({ value, currency, decimals }) => {
    const { selectedUiCulture } = useContext(LocalizationContext);

    if (value == null || !currency) {
        return null;
    }

    return formatCurrency(value, currency, decimals, selectedUiCulture);
};

export const formatCurrency = (value, currency, decimals, selectedUiCulture) => {
    const formatter = new Intl.NumberFormat(selectedUiCulture, {
        style: "currency",
        currency: currency,
        maximumFractionDigits: decimals || 0,
        minimumFractionDigits: decimals || 0,
    });

    return formatter.format(value);
};
