import React from "react";
import Paper from "@material-ui/core/Paper";
import useStyles from "./useStyles";
import withResources from "../../../../shared/textresources/withresources";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";

const Comments = withResources((props) => {
    const { getResource, task, handleChange, disabled, errors } = props;

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <ResourcedTextField
                errors={errors}
                name="jobSheetComments"
                variant="outlined"
                labelResourceKey="Maintenance.workOrder.comments.comment"
                labelDefaultValue="Comments"
                margin="dense"
                multiline
                fullWidth
                rows={5}
                rowsMax={5}
                InputLabelProps={{ shrink: true }}
                value={task.jobSheetComments}
                placeholder={
                    disabled
                        ? ""
                        : getResource(
                              "Maintenance.workOrder.comments.comment.placeholder",
                              "Type your comment"
                          )
                }
                onChange={handleChange}
                disabled={disabled}
            />
        </Paper>
    );
});

export default Comments;
