import React, { useContext } from "react";

import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    CheckCircleOutline as NormalIcon,
    Delete as DeleteIcon,
    Drafts as ReadIcon,
    HighlightOff as SpamIcon,
    Mail as UnreadIcon,
    Visibility as ViewIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import _ from "underscore";
import clsx from "clsx";
import { formatDate } from "../../../shared/formattedDate";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";

const useStyles = makeStyles((theme) => ({
    headingRow: {
        "& th": {
            verticalAlign: "middle",

            "&.checkboxCell": {
                paddingTop: 4,
            },
        },
    },

    readRow: {
        cursor: "pointer",
    },

    unreadRow: {
        cursor: "pointer",

        "& td": {
            fontWeight: "bold",
        },
    },

    checkboxCell: {
        paddingLeft: 14,
        width: 52,
        cursor: "auto",

        "& + td, & + th": {
            paddingLeft: 0,
        },
    },

    iconCell: {
        position: "relative",
        width: 40,

        "& svg": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    },
}));

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onView,
        selectedMessages,
        onSelectMessage,
        onDeselectAllMessages,
        onSelectAllMessages,
        onMarkAsRead,
        onMarkAsUnread,
        onMarkAsSpam,
        onMarkAsNormal,
        onDelete,
        defaultDebounceInterval,
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

        data = data || {};
        const items = data.items;
        const classes = useStyles();

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <React.Fragment>
                <WorkingContext
                    requiredOperation="messages-view"
                    hideAssetCollections
                    allowNoCompany
                />
                <ContentHolder>
                    <Header
                        title={
                            searchParams.spam
                                ? getResource("Marketplace.messages.header.spamTitle", "Spam")
                                : getResource("Marketplace.messages.header.inboxTitle", "Inbox")
                        }
                        renderActions={() => {
                            if (selectedMessages.length === 0) {
                                return null;
                            }

                            return (
                                <ActionMenu
                                    buttonLabel={getResource(
                                        "Marketplace.messages.header.bulkMenuLabel",
                                        "Actions"
                                    )}
                                    actions={[
                                        {
                                            text: getResource(
                                                "Marketplace.messages.header.markSelectedAsReadButton",
                                                "Mark selected as read"
                                            ),
                                            icon: <ReadIcon />,
                                            onClick: () => onMarkAsRead(selectedMessages),
                                        },
                                        {
                                            text: getResource(
                                                "Marketplace.messages.header.markSelectedAsUnreadButton",
                                                "Mark selected as unread"
                                            ),
                                            icon: <UnreadIcon />,
                                            onClick: () => onMarkAsUnread(selectedMessages),
                                        },
                                        {
                                            text: getResource(
                                                "Marketplace.messages.header.markSelectedAsSpamButton",
                                                "Move selected to spam"
                                            ),
                                            icon: <SpamIcon />,
                                            onClick: () => onMarkAsSpam(selectedMessages),
                                            hide: searchParams.spam,
                                        },
                                        {
                                            text: getResource(
                                                "Marketplace.messages.header.markSelectedAsNormalButton",
                                                "Move selected to inbox"
                                            ),
                                            icon: <NormalIcon />,
                                            onClick: () => onMarkAsNormal(selectedMessages),
                                            hide: !searchParams.spam,
                                        },
                                        {
                                            text: getResource(
                                                "Marketplace.messages.deleteSelectedButton",
                                                "Delete selected"
                                            ),
                                            icon: <DeleteIcon />,
                                            onClick: () => onDelete(selectedMessages),
                                        },
                                    ]}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={
                            searchParams.spam
                                ? getResource(
                                      "Marketplace.messages.noSpamResults",
                                      "No spam available"
                                  )
                                : getResource(
                                      "Marketplace.messages.noResults",
                                      "No messages available"
                                  )
                        }
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow className={classes.headingRow}>
                                    <TableCell
                                        className={clsx(classes.checkboxCell, "checkboxCell")}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Checkbox
                                            checked={
                                                items && selectedMessages
                                                    ? items.every((row) =>
                                                          selectedMessages.includes(row.id)
                                                      )
                                                    : false
                                            }
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? onSelectAllMessages()
                                                    : onDeselectAllMessages()
                                            }
                                            name="SelectAll"
                                            size="small"
                                            className={classes.checkbox}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.messages.overview.fromHeader"
                                            defaultValue="From"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.messages.overview.subjectHeader"
                                            defaultValue="Subject"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.messages.overview.dateHeader"
                                            defaultValue="Date"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Marketplace.messages.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <TableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            onClick={() => onView(row.id)}
                                            className={
                                                row.read ? classes.readRow : classes.unreadRow
                                            }
                                        >
                                            <TableCell
                                                className={classes.checkboxCell}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Checkbox
                                                    checked={
                                                        selectedMessages.filter((x) => x === row.id)
                                                            .length > 0
                                                    }
                                                    onChange={(e) => onSelectMessage(e)}
                                                    name={row.id.toString()}
                                                    size="small"
                                                    className={classes.checkbox}
                                                />
                                            </TableCell>
                                            <TableCell align="center" className={classes.iconCell}>
                                                {row.read ? <ReadIcon /> : <UnreadIcon />}
                                            </TableCell>
                                            <TableCell>
                                                {row.firstName} {row.lastName}
                                            </TableCell>
                                            <TableCell>{row.subject}</TableCell>
                                            <TableCell>
                                                {formatDate(
                                                    row.created,
                                                    selectedUiCulture,
                                                    selectedTimezone,
                                                    false,
                                                    true
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.overview.viewButton",
                                                                "View"
                                                            ),
                                                            icon: <ViewIcon />,
                                                            itemId: row.id,
                                                            onClick: () => onView(row.id),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.overview.markAsReadButton",
                                                                "Mark as read"
                                                            ),
                                                            icon: <ReadIcon />,
                                                            itemId: row.id,
                                                            onClick: () => onMarkAsRead([row.id]),
                                                            hide: row.read,
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.overview.markAsUnreadButton",
                                                                "Mark as unread"
                                                            ),
                                                            icon: <UnreadIcon />,
                                                            itemId: row.id,
                                                            onClick: () => onMarkAsUnread([row.id]),
                                                            hide: !row.read,
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.overview.markAsSpamButton",
                                                                "Move to spam"
                                                            ),
                                                            icon: <SpamIcon />,
                                                            itemId: row.id,
                                                            onClick: () => onMarkAsSpam([row.id]),
                                                            hide: row.spam,
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.overview.markAsNormalButton",
                                                                "Move to inbox"
                                                            ),
                                                            icon: <NormalIcon />,
                                                            itemId: row.id,
                                                            onClick: () => onMarkAsNormal([row.id]),
                                                            hide: !row.spam,
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Marketplace.messages.deleteButton",
                                                                "Delete"
                                                            ),
                                                            icon: <DeleteIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "messages-delete",
                                                            onClick: () => onDelete([row.id]),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
