import React, { Fragment, useContext, useEffect, useRef, useCallback } from "react";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import ReferenceContext from "../../../shared/reference/referenceContext";
import PrivateRoute from "../../../shared/authentication/privateroute";
import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import Overview from "./overview";
import Add from "../scheduledEvent/add";
import Edit from "../scheduledEvent/edit";
import useAPI from "../../../shared/useapi";
import useDialog from "../../../shared/usedialog";
import { getResources, getEvents } from "./dataservice";

const Timeline = withResources(({ getResource, match }) => {
    const timelineRef = useRef(null);

    const { companyScope, setCompanyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const { getReferenceEntity } = useContext(ReferenceContext);
    const { selectedTimezone } = useContext(LocalizationContext);

    const assetId = match.params["assetId"] && parseInt(match.params["assetId"]);

    const { data: resources, loading: resourcesLoading } = useAPI(
        () => {
            return companyScope.id ? getResources(assetId) : null;
        },
        true,
        [companyScope.id, assetCollectionScope.id, assetId]
    );

    const reloadEvents = () => {
        const calendarApi = timelineRef.current.getApi();
        calendarApi.refetchEvents();
    };

    const loadEvents = useCallback(
        async (info, successCallback, failureCallback) => {
            const response = await getEvents(assetId, info.startStr, info.endStr);
            const data = await response.json();

            const events =
                data &&
                data.map((event) => {
                    var zonedStart = utcToZonedTime(event.start, selectedTimezone);
                    var zonedEnd = event.end && utcToZonedTime(event.end, selectedTimezone);

                    if (event.isPredicted) {
                        //Turn back timezone changes for predicted events
                        zonedStart = zonedTimeToUtc(zonedStart, selectedTimezone);
                        zonedEnd = zonedEnd && zonedTimeToUtc(zonedEnd, selectedTimezone);
                    }

                    let title = event.title;
                    if (
                        event.allDay &&
                        (zonedStart.getHours() !== 0 || zonedStart.getMinutes() !== 0) &&
                        event.display === "block" &&
                        !event.isPredicted
                    ) {
                        title = getResource(
                            "Planning.calendar.event.titleAllDayOtherTimezone",
                            `{title} (starts at {hour}:{minutes})`,
                            {
                                title,
                                hour: zonedStart.getHours(),
                                minutes: `0${zonedStart.getMinutes()}`.slice(-2),
                            }
                        );
                    }

                    return {
                        ...event,
                        title,
                        start: zonedStart,
                        end: zonedEnd,
                        editable: Boolean(event.id) && event.allowEdit,
                        allowEdit: event.allowEdit,
                        resource: event.resourceId, // resourceId isn't available via extendedProps
                    };
                });

            successCallback(events);
        },
        [assetId, selectedTimezone, getResource]
    );

    const { data: assetReference } = useAPI(
        () => getReferenceEntity("Assets", assetId),
        Boolean(assetId),
        [assetId]
    );

    const {
        open: handleAdd,
        close: handleCloseAdd,
        visible: addToggled,
        args: addArgs,
    } = useDialog(reloadEvents);

    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(reloadEvents);

    useEffect(() => {
        if (assetReference && assetReference.companyId !== companyScope.id) {
            setCompanyScope({ id: assetReference.companyId, name: assetReference.companyName });
        }
    });

    return (
        <Fragment>
            <Overview
                timelineRef={timelineRef}
                resources={resources}
                loadEvents={loadEvents}
                loading={resourcesLoading}
                onAdd={handleAdd}
                onEdit={handleEdit}
                assetId={assetId}
                assetReference={assetReference}
                onReload={reloadEvents}
            />

            <Add
                open={addToggled}
                onClose={handleCloseAdd}
                start={addArgs && addArgs.start}
                end={addArgs && addArgs.end}
                allDay={addArgs && addArgs.allDay}
                referenceId={addArgs && addArgs.referenceId}
                referenceType={addArgs && addArgs.referenceType}
            />

            <Edit open={editToggled} onClose={handleCloseEdit} id={editId} />
        </Fragment>
    );
});

export default () => {
    return (
        <PrivateRoute
            requiredOperation={"scheduled-events-view"}
            path="/planning/timeline/:assetId?"
            render={(props) => <Timeline {...props} />}
        />
    );
};
