import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAllforAsset(assetId, page, pageSize) {
    const params = {
        assetId,
        page,
        pageSize,
    };

    const url = `/api/valuation/quickScan/assetQuickScans?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAll(page, pageSize) {
    const params = {
        page,
        pageSize,
    };

    const url = `/api/valuation/quickscan/allAssetQuickScans?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function purchase(quickScan) {
    return await interceptedFetch("/api/valuation/quickScan/purchaseAssetQuickScan", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(quickScan)),
    });
}

async function purchaseCustom(quickScan) {
    return await interceptedFetch("/api/valuation/quickScan/purchaseCustomAssetQuickScan", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(quickScan)),
    });
}

async function getBatchInformation(futureMonths) {
    const params = {
        futureMonths,
    };

    const url = `/api/valuation/quickScan/eligibleAssetsInBatch?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function purchaseBatch(batchQuickScan) {
    return await interceptedFetch("/api/valuation/quickScan/purchaseAssetBatch", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(batchQuickScan)),
    });
}

async function calculate(quickScan) {
    return await interceptedFetch("/api/valuation/quickscan/calculateCustomAssetQuickScan", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(quickScan)),
    });
}

async function finalize(parameters) {
    return await interceptedFetch("/api/valuation/quickscan/finalizeCustomAssetQuickScan", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(parameters)),
    });
}

export {
    getAllforAsset,
    getAll,
    purchase,
    purchaseCustom,
    calculate,
    finalize,
    getBatchInformation,
    purchaseBatch,
};
