import React, { useContext, useEffect } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import DownloadDraft from "./downloaddraft";
import Publish from "./publish";
import Overview from "./overview";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-appraisals", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        statuses: [],
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.statuses,
                null,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.statuses,
            searchParams.page + 1,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleDownloadDraft,
        close: handleCloseDownloadDraft,
        visible: downloadDraftToggled,
        args: downloadDraftId,
    } = useDialog(invoke);
    const {
        open: handlePublish,
        close: handleClosePublish,
        visible: publishToggled,
        args: publishAppraisal,
    } = useDialog(invoke);

    function handleSearch(searchText, statuses) {
        setSearchParams({ ...searchParams, searchText, statuses, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <div>
            <PrivateRoute
                requiredOperationAtLeastOneOf={[
                    "appraisals-view-draft",
                    "appraisals-view-published",
                ]}
                path="/appraisal/appraisal"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onDownloadDraft={handleDownloadDraft}
                            onPublish={handlePublish}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
            <Delete
                id={deleteArgs && deleteArgs.id}
                open={deleteToggled}
                onClose={handleCloseDelete}
                assetName={deleteArgs && deleteArgs.assetName}
            />
            <DownloadDraft
                appraisalId={downloadDraftId}
                open={downloadDraftToggled}
                onClose={handleCloseDownloadDraft}
            />
            <Publish
                appraisal={publishAppraisal}
                open={publishToggled}
                onClose={handleClosePublish}
            />
        </div>
    );
};
