import React from "react";
import useAPI from "../../../shared/useapi";
import { archive } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, onClose, id, name, library }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(archive);

    const handleActivate = async () => {
        if (await invoke(library, id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleActivate}
            handleClose={onClose}
            headerText={getResource("Document.documents.archive.header", "Archive document?")}
            buttonText={getResource("Document.documents.archive.okButton", "Archive")}
        >
            {getResource(
                "Document.documents.archive.archiveMessage",
                "Are you sure you want to archive '{name}'? Please note that all actions linked to this document will be marked as resolved.",
                { name }
            )}
        </Confirmation>
    );
});
