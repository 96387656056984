import React from "react";
import useAuthorization from "./useauthorization";

import { TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    showPointer: {
        cursor: "pointer",
    },
}));

const AuthorizedTableRow = ({
    requiredOperation,
    requiredOperationAtLeastOneOf,
    handleClick,
    children,
    ...other
}) => {
    const classes = useStyles();

    const tableProps = { ...other };

    const { isAuthorized } = useAuthorization(requiredOperation, requiredOperationAtLeastOneOf);

    if (isAuthorized) {
        tableProps.className = classes.row;
        tableProps.onClick = handleClick;
    }
    return (
        <TableRow {...tableProps} className={handleClick ? classes.showPointer : ""}>
            {children}
        </TableRow>
    );
};

export default AuthorizedTableRow;
