import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import React, { Fragment } from "react";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import AssetCellContent from "../../../shared/assetCellContent";
import FormattedCurrency from "../../../shared/formattedCurrency";
import withresources from "../../../shared/textresources/withresources";
import { makeStyles } from "@material-ui/styles";
import Status from "../../../shared/status/index";

const useStyles = makeStyles((theme) => ({
    headingRow: {
        "& th": {
            verticalAlign: "middle",

            "&.checkboxCell": {
                paddingTop: 4,
            },
        },
    },
}));

const PagedListContent = withresources((props) => {
    const {
        getResource,
        items,
        handleRowClick,
        getRequiredOperationForRowClick,
        renderActionMenu,
        renderSelectButton,
        renderSelectAllAssets,
        renderSelectAsset,
        renderExtraHeaders,
        renderExtraColumns,
        showFinancials,
        showThumbnails,
        sortedColumnHeader,
    } = props;

    const classes = useStyles();

    return (
        <Table size="small">
            <TableHead>
                <TableRow className={classes.headingRow}>
                    {renderSelectAllAssets && renderSelectAllAssets()}

                    {showThumbnails && <TableCell
                        style={{ width: 75 }} />}

                    {sortedColumnHeader && (
                        <TableCell>
                            <ResourcedText
                                resourceKey={`Asset.assets.overview.${sortedColumnHeader}Header`}
                                defaultValue={sortedColumnHeader}
                            />
                        </TableCell>)}

                    <TableCell>
                        <ResourcedText
                            resourceKey="Asset.assets.overview.nameHeader"
                            defaultValue="Name"
                        />
                    </TableCell>

                    {showFinancials && (
                        <Fragment>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.quickScanReferenceHeader"
                                    defaultValue="Quick scan reference"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.fairMarketValueHeader"
                                    defaultValue="Fair market value"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.liquidationValueHeader"
                                    defaultValue="Liquidation value"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.loanAmountHeader"
                                    defaultValue="Loan amount"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.financePeriodHeader"
                                    defaultValue="Finance period"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.interestHeader"
                                    defaultValue="Interest"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.remainingInterestHeader"
                                    defaultValue="Remaining interest"
                                />
                            </TableCell>
                            <TableCell>
                                <ResourcedText
                                    resourceKey="Asset.assets.overview.remainingDebtHeader"
                                    defaultValue="Remaining debt"
                                />
                            </TableCell>
                        </Fragment>
                    )}
                    {renderExtraHeaders && renderExtraHeaders()}
                    <TableCell component="th" scope="col">
                        <ResourcedText
                            resourceKey="Asset.assets.overview.sealedHeader"
                            defaultValue="Sealed"
                        />
                    </TableCell>
                    <TableCell align="right">
                        <ResourcedText
                            resourceKey="Asset.assets.overview.actionsHeader"
                            defaultValue="Actions"
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items &&
                    items.map((row) => (
                        <AuthorizedTableRow
                            id={row.id}
                            key={row.id}
                            hover
                            handleClick={() => handleRowClick(row)}
                            requiredOperation={getRequiredOperationForRowClick(row)}
                        >
                            {renderSelectAsset && renderSelectAsset(row)}

                            {showThumbnails && <TableCell>
                                {(row.assetMediaId ? <img
                                    src={`/api/assets/asset/${row.id}/media/generic/${row.assetMediaId}/view?imageSize=OverviewThumbnail&v=${row.assetMediaFileId}`}
                                    alt=""
                                    draggable="false"
                                /> : <img
                                        src={`/images/notavailable?imageSize=OverviewThumbnail`}
                                    alt=""
                                    draggable="false"
                                />)}
                            </TableCell>}

                            {sortedColumnHeader &&
                                <TableCell>
                                    {row.sortedField}
                                </TableCell>
                            }

                            <TableCell>
                                <AssetCellContent
                                    assetName={row.name}
                                    manufacturerName={row.manufacturerName}
                                    assetModelName={row.assetModelName}
                                    constructionYear={row.constructionYear}
                                    serial={row.serial}
                                    internalId={row.internalId}
                                    assetId={row.id}
                                />
                            </TableCell>

                            {showFinancials && row.financials && (
                                <Fragment>
                                    <TableCell component="th" scope="row">
                                        {row.financials.quickScanReference}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <FormattedCurrency
                                            value={row.financials.quickScanFairMarketValue}
                                            currency={row.financials.quickScanCurrencyShortName}
                                            decimals={0}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <FormattedCurrency
                                            value={row.financials.quickScanLiquidationValue}
                                            currency={row.financials.quickScanCurrencyShortName}
                                            decimals={0}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <FormattedCurrency
                                            value={row.financials.loanInitialAmount}
                                            currency={row.financials.loanCurrencyShortName}
                                            decimals={0}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.financials.loanPeriods &&
                                            getResource(
                                                "Asset.assets.overview.monthsLabel",
                                                "{month} months",
                                                { month: row.financials.loanPeriods }
                                            )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.financials.loanInterestRate && (
                                            <span>{row.financials.loanInterestRate} %</span>
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <FormattedCurrency
                                            value={row.financials.loanRemainingInterest}
                                            currency={row.financials.loanCurrencyShortName}
                                            decimals={0}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <FormattedCurrency
                                            value={row.financials.loanRemainingDebt}
                                            currency={row.financials.loanCurrencyShortName}
                                            decimals={0}
                                        />
                                    </TableCell>
                                </Fragment>
                            )}

                            {showFinancials && !row.financials && (
                                <Fragment>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        &nbsp;
                                    </TableCell>
                                </Fragment>
                            )}

                            {renderExtraColumns && renderExtraColumns(row)}
                            <TableCell component="td">
                                {row.isSealed ? (
                                    <Status text="Sealed" color="red" />
                                ) : (
                                    <Status text="Not sealed" color="green" />
                                )}
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{ width: renderSelectButton ? 136 : "auto" }}
                            >
                                {renderActionMenu && renderActionMenu(row)}
                                {renderSelectButton && renderSelectButton(row.id)}
                            </TableCell>
                        </AuthorizedTableRow>
                    ))}
            </TableBody>
        </Table>
    );
});

export default PagedListContent;
