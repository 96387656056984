import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, assetModelId, manufacturerId, assetCategoryId, financierCompanyIds, companyId, includeFinancials, order, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        assetModelId,
        manufacturerId,
        assetCategoryId,
        financierCompanyIds,
        companyId,
        includeFinancials,
        order,
    };

    const url = `/api/assets/asset?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getTotalValue(searchText, assetModelId, manufacturerId) {
    const params = {
        searchText,
        assetModelId,
        manufacturerId,
    };

    const url = `/api/assets/asset/totalValue?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/assets/asset/${id}`;

    return await interceptedFetch(url);
}

async function getMovements(id) {
    const url = `/api/assets/asset/${id}/movements`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch(`/api/assets/asset`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/assets/asset/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function duplicate(id, values) {
    return await interceptedFetch(`/api/assets/asset/${id}/duplicate`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(id) {
    return await interceptedFetch(`/api/assets/asset/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
    });
}

async function seal(id) {
    return await interceptedFetch(`/api/assets/asset/${id}/seal`, {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
    });
}

async function unseal(id) {
    return await interceptedFetch(`/api/assets/asset/${id}/unseal`, {
        method: "POST",
        headers: {
            Accept: "application/json",
        },
    });
}

async function assignAssetCollection(values) {
    return await interceptedFetch(`/api/assets/asset/assetCollection/assign`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function moveAssetCollection(values) {
    return await interceptedFetch(`/api/assets/asset/assetCollection/move`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function moveAllAssetCollection(values) {
    return await interceptedFetch(`/api/assets/asset/assetCollection/moveall`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function removeAssetCollection(values) {
    return await interceptedFetch(`/api/assets/asset/assetCollection/remove`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function moveCompany(id, values) {
    return await interceptedFetch(`/api/assets/asset/${id}/company/move`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function getActions() {
    const url = `/api/assets/asset/actions`;

    return await interceptedFetch(url);
}

async function importAssets(file) {
    const url = `/api/assets/asset/excelImport`;
    const formData = new FormData();

    formData.append('File', file);

    return await interceptedFetch(url, {
        method: "POST",
        body: formData,
    });
}

export {
    getAll,
    getTotalValue,
    get,
    getMovements,
    add,
    update,
    seal,
    unseal,
    del,
    assignAssetCollection,
    moveAssetCollection,
    moveAllAssetCollection,
    removeAssetCollection,
    moveCompany,
    getActions,
    duplicate,
    importAssets
};
