import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    root: {
        "& a[href]": {
            color: "#fff"
        }
    }
}));

export default useStyles;
