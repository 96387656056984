import React from "react";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import Search from "./search";
import ActionMenu from "../../../shared/actionmenu";
import PagedList from "../../../shared/pagedList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import _ from "underscore";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import Path from "../../../shared/path";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useOverviewStyles = makeStyles((theme) => ({
    table: {
        tableLayout: "fixed",
    },
    chipHeaderCell: {
        width: "calc(100vw - 155px)",

        [theme.breakpoints.up("lg")]: {
            width: "92%",
        },
    },
    chip: {
        maxWidth: "100%",
    },
}));

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        history,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        const classes = useOverviewStyles();

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Valuation.questionnaire.header.title",
                        "Category questionnaires"
                    )}
                    renderActions={() => (
                        <AuthorizedButton
                            color="primary"
                            variant="contained"
                            onClick={onAdd}
                            buttonText={getResource(
                                "Valuation.questionnaire.newQuestionnaireButton",
                                "Create"
                            )}
                        />
                    )}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Valuation.questionnaire.noResults",
                        "No category questionnaires available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.chipHeaderCell}>
                                    <ResourcedText
                                        resourceKey="Valuation.questionnaire.overview.assetCategoriesHeader"
                                        defaultValue="Categories"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Valuation.questionnaire.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() => onEdit(row.id)}
                                        requiredOperation="questionnaires-update"
                                    >
                                        <TableCell component="td" scope="row">
                                            {Object.entries(row.assetCategories).map(
                                                ([key, value], i) => (
                                                    <Chip
                                                        label={<Path path={value} keepOnOneLine />}
                                                        key={i}
                                                        className={classes.chip}
                                                    />
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Valuation.questionnaire.editQuestionnaireButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "questionnaires-update",
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                    {
                                                        text: getResource(
                                                            "Valuation.questionnaire.deleteQuestionnaireButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "questionnaires-delete",
                                                        onClick: () => onDelete(row.id),
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
