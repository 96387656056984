import { makeStyles } from "@material-ui/styles";
import variables from "./variables";

const useStyles = makeStyles((theme) => ({
    overview: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        flexDirection: "column",

        [theme.breakpoints.up(variables.firstBp)]: {
            flexDirection: "row",
        },
    },

    widgetsContainer: {
        position: "relative",
        minWidth: 0,

        [theme.breakpoints.up(variables.firstBp)]: {
            flex: "1 1 auto",

            "& > *": {
                marginLeft: theme.spacing(3),

                "&.widget--fullwidth": {
                    width: `calc(100% - ${theme.spacing(3)}px)`,
                },
            },
        },

        [theme.breakpoints.up(variables.secondBp)]: {
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",

            "& > *:not(.widget--fullwidth)": {
                flex: "0 1 auto",
                width: `calc(100% / 2 - ${theme.spacing(3)}px)`,
            },
        },

        [theme.breakpoints.up(variables.thirdBp)]: {
            "& > *:not(.widget--fullwidth)": {
                width: `calc(100% / 3 - ${theme.spacing(3)}px)`,
            },
        },
    },

    sidebarContainer: {
        [theme.breakpoints.up(variables.firstBp)]: {
            flex: "0 0 auto",
            minWidth: 210,
            width: "23%",
            maxWidth: 280,
        },
    },
}));

export default useStyles;
