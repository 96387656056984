export default {
    root: {},
    notchedOutline: {
        borderColor: "rgba(0,0,0,0.15)",

        "& > legend": {
            fontSize: 12,
        },
    },
};
