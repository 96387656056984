import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(3)}px 72px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },

    totalsRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1.5),
        fontSize: 16,
        fontWeight: 500,

        "&:last-child": {
            marginBottom: 0,
            fontSize: 19,
        },
    },
}));

export default useStyles;
