import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    status: {
        display: "inline-block",
        marginBottom: 8,
        padding: "5px 10px",
        border: "1px solid",
        borderRadius: 5,
        fontSize: 10,
        fontWeight: "500",
        textTransform: "uppercase",
        whiteSpace: "nowrap",

        "td > &, th > &": {
            marginBottom: 0,
        },

        "&.red": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },

        "&.orange": {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
        },

        "&.green": {
            borderColor: theme.palette.completed.main,
            color: theme.palette.completed.main,
        },
    },
}));

export default useStyles;
