import React from "react";
import PropTypes from "prop-types";
import useStyles from "./useStyles";
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as UncheckedIcon } from "@material-ui/icons";
import clsx from "clsx";
import { FormHelperText } from "@material-ui/core";

const ImageSelect = (props) => {
    const {
        errors,
        name,
        label,
        value,
        images,
        getImageSource,
        onSelection,
        noImagesText,
        required,
    } = props;

    const classes = useStyles();
    const error = errors && name in errors;
    const hasImages = images && images.length > 0;
    const helperText = errors && errors[name];

    const saveValue = (e, id, wasChecked) => {
        let newValue;
        if (wasChecked) {
            newValue = value.filter((imageId) => imageId !== id);
        } else {
            newValue = value ? [...value] : [];
            newValue.push(id);
        }

        onSelection({ target: { name: name, value: newValue } });
    };

    return (
        <div className={classes.root}>
            <div
                className={clsx(
                    classes.imageSelectElement,
                    error && "--error",
                    !hasImages && "--no-images"
                )}
            >
                <label
                    className={clsx(
                        classes.imageSelectLabel,
                        "imageSelectLabel",
                        error && "--error"
                    )}
                >
                    {label}
                    {required && (
                        <span aria-hidden="true" className="MuiInputLabel-asterisk">
                            {" "}
                            *
                        </span>
                    )}
                </label>

                {hasImages ? (
                    images.map((image) => {
                        const id = image.id;
                        const isChecked = value && value.includes(id);

                        return (
                            <label className={classes.imageLabel} htmlFor={`media${id}`} key={id}>
                                <input
                                    type="checkbox"
                                    id={`media${id}`}
                                    name="media"
                                    className={classes.checkbox}
                                    checked={Boolean(isChecked)}
                                    onChange={(e) => saveValue(e, id, isChecked)}
                                />

                                <img
                                    className={clsx(classes.image, isChecked && "--is-checked")}
                                    src={getImageSource(image)}
                                    alt={image.description || id}
                                />

                                <div
                                    className={clsx(
                                        classes.checkboxIconWrapper,
                                        isChecked && "--is-checked"
                                    )}
                                >
                                    {isChecked ? (
                                        <CheckedIcon size="small" />
                                    ) : (
                                        <UncheckedIcon size="small" />
                                    )}
                                </div>
                            </label>
                        );
                    })
                ) : (
                    <div className="noImagesText">{noImagesText}</div>
                )}
            </div>

            {helperText && error && (
                <FormHelperText
                    className={classes.autoCompleteHelperText}
                    variant="outlined"
                    error={error}
                >
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};

ImageSelect.propTypes = {
    errors: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    images: PropTypes.array,
    getImageSource: PropTypes.func,
    onSelection: PropTypes.func,
    noImagesText: PropTypes.string,
};

export default ImageSelect;
