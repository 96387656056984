import React, { useEffect, useState } from "react";
import AuthenticationContext from "./authenticationcontext";
import AuthorizationService from "./authorizationservice";
import { get, logOut } from "./dataservice";

export default ({ children }) => {
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(true);

    async function load() {
        const response = await get();

        if (response.ok && response.status !== 204) {
            setUser(await response.json());
        }

        setLoading(false);
    }

    async function onLogOut() {
        const response = await logOut();

        if (response.ok) {
            setUser(null);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const isAuthenticated = Boolean(user);

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                loggedInUser: user,
                authorizationService: new AuthorizationService(
                    (user && user.assignedOperations) || [],
                    user && user.isAdmin
                ),
                refresh: async () => await load(),
                logOut: () => onLogOut(),
            }}
        >
            {!loading && children}
        </AuthenticationContext.Provider>
    );
};
