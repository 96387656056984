import { makeStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    drillDownInputContainer: {
        position: "relative",
        display: "inline-flex",
        verticalAlign: "top",
        maxWidth: "100%",
        width: 300,

        "&.--medium": {
            margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        },

        "&.--fullwidth": {
            width: "100%",
        },
    },
    drillDownInput: {
        padding: "2px 3px 0 11px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        minHeight: 40,
        border: "1px solid rgba(0,0,0,0.15)",
        boxShadow: "none",
        transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "var(--background-color)",

        "&:hover": {
            borderColor: theme.palette.black,
            cursor: "pointer",
        },

        "&.--medium": {
            minHeight: 56,
            paddingTop: 9,
            paddingBottom: 7,
        },

        "&.--error": {
            borderColor: theme.palette.error.main,
        },

        "& .path": {
            fontSize: 13,
            color: "#263238",
        },

        "&.--disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            borderColor: "rgba(0, 0, 0, 0.28)",
        },
    },

    label: {
        position: "absolute",
        top: 0,
        left: 9,
        padding: "0 4px",
        fontSize: 12,
        lineHeight: "11px",
        transform: "translateY(-6px)",

        "&.--error": {
            color: theme.palette.error.main,
        },

        "&::before": {
            content: "''",
            position: "absolute",
            top: "calc(50% - 1px)",
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "var(--background-color)",
            zIndex: -1,
        },
    },

    chipsWrapper: {
        position: "relative",
        overflow: "hidden",
        flexGrow: 1,
    },

    chip: {
        margin: "3px 6px 3px 0",

        "&:hover": {
            cursor: "pointer",
        },

        "&.--show-as-text": {
            backgroundColor: "transparent",

            "& .MuiChip-label": {
                paddingLeft: 2,
                paddingRight: 8,

                "& p": {
                    fontSize: 16,
                },
            },

            "& .MuiChip-deleteIcon": {
                width: 16,
                height: 16,
            },
        },
    },

    clearIconButton: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: 4,
        marginRight: -2,
        opacity: 0,

        ".drillDownInput:hover &, &.Mui-focusVisible": {
            opacity: 1,
        },

        "& svg": {
            fontSize: 13,
        },
    },

    popperToggle: {
        marginLeft: "auto",

        /** To be able to set focus on the button with JS from outside of the React component **/
        "&:focus:not(.Mui-focusVisible)": {
            backgroundColor: colors.grey[300],
        },
    },

    error: {
        marginTop: 0,
    },

    pageBlocker: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
    },

    popper: {
        border: "1px solid rgba(27,31,35,.15)",
        boxShadow: "0 3px 12px rgba(27,31,35,.15)",
        borderRadius: 3,
        zIndex: 10001,
        fontSize: 13,
        marginTop: 5,
        color: "#586069",
        backgroundColor: "white",
    },

    searchContainer: {
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        padding: "20px 20px 4px",
        borderRadius: "3px 3px 0 0",
        color: "white",
        overflow: "hidden",
    },

    closeButton: {
        position: "absolute",
        top: 3,
        right: 3,
        color: "white",

        "& svg": {
            fontSize: 14,
        },
    },

    /**
     * Drill Down ul
     **/
    ulWrapper: {
        display: "inline-block",
        width: "100%",

        "&.--root": {
            position: "relative",
            overflowX: "hidden",
            overflowY: "auto",
        },

        "& .childItems": {
            margin: 0,
            padding: "0",
            listStyle: "none",
            height: "100%",
            maxHeight: "100%",
            transition: "0.2s all ease-out",
        },

        "& .ulWrapper": {
            position: "absolute",
            top: 0,
            bottom: 0,
            marginLeft: "calc(100% - 20px)",
            cursor: "auto",
        },
    },

    item: {
        display: "none",

        "&.--is-visible, &.--is-open": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 40,
            margin: 0,
            padding: "5px 10px 5px 20px",
            cursor: "pointer",
        },

        "&:hover": {
            transition: `0.1s background-color ${theme.transitions.easing.easeOut}`,
            backgroundColor: theme.palette.background.default,
        },

        "& > .name": {
            flexGrow: 1,
            lineHeight: 1.2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 2,
        },

        "& > .ulWrapper": {
            display: "none",
        },

        "&.--is-open.--is-visible": {
            transition: "none !important",
            backgroundColor: `${theme.palette.primary.main} !important`,

            "& > .name": {
                color: theme.palette.white,
            },

            "& > button": {
                color: theme.palette.white,
            },
        },

        "&.--is-open": {
            "& > .ulWrapper": {
                display: "block",
            },
        },
    },
}));

export default useStyles;
