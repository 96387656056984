import interceptedFetch from "../../../shared/interceptedFetch";

async function link(values, referenceType) {
    return await interceptedFetch(`/api/administration/qrcodes/${referenceType}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

export { link };
