import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, invoice, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(invoice.id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource(
                "Administration.invoice.delete.confirmationHeader",
                "Delete invoice?"
            )}
            buttonText={getResource("Administration.invoice.delete.confirmationButton", "Delete")}
        >
            <ResourcedText
                resourceKey="Administration.invoice.delete.deleteMessage"
                defaultValue="Are you sure you want to delete '{invoiceNumber}' for '{invoiceDescription}'?"
                tokens={{ invoiceNumber: invoice.number, invoiceDescription: invoice.description }}
            />
        </Confirmation>
    );
});
