import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
} from "@material-ui/core";
import { Check, Clear, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import withResources from "../../../../shared/textresources/withresources";
import ResourcedText from "../../../../shared/textresources/resourcedtext";

const useStyles = makeStyles((theme) => ({
    accordionsWrapper: {
        margin: theme.spacing(2) + "px 0",
    },

    accordionDetails: {
        padding: 0,
    },
}));

const FutureValues = withResources(({ getResource, invalidAssets, validAssets }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h4">
                {getResource(
                    "Valuation.quickScan.invalidAssets.header",
                    "Can't create a quick scan for all assets"
                )}
            </Typography>
            <Typography variant="body1">
                {getResource(
                    "Valuation.quickScan.invalidAssets.content",
                    "{invalidAssetCount} assets will not be included in the quick scan batch. Do you wish to proceed with the purchase of {validAssetCount} quick scans?",
                    { invalidAssetCount: invalidAssets.length, validAssetCount: validAssets.length }
                )}
            </Typography>

            <div className={classes.accordionsWrapper}>
                <Accordion TransitionProps={{ timeout: 0 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="invalidAsset-content"
                        id="invalidAsset-header"
                    >
                        {getResource(
                            "Valuation.quickScan.invalidAssets.panelHeader",
                            "{invalidAssetCount} invalid assets",
                            { invalidAssetCount: invalidAssets.length }
                        )}
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.invalidAssets.name"
                                                defaultValue="Name"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.invalidAssets.serial"
                                                defaultValue="Serial"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.invalidAssets.ConstructionDate"
                                                defaultValue="Construction date"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.invalidAssets.QuickScan"
                                                defaultValue="Previous Quick Scan"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.invalidAssets.Valuation"
                                                defaultValue="Valuation"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invalidAssets.map((row) => (
                                        <TableRow id={row.id} hover key={row.id}>
                                            <TableCell>
                                                {row.assetName
                                                    ? row.assetName
                                                    : row.manufacturerName +
                                                      " " +
                                                      row.assetModelName}
                                            </TableCell>
                                            <TableCell>{row.serial}</TableCell>
                                            <TableCell>
                                                {row.constructionDateAvailable ? (
                                                    <Check color="secondary" />
                                                ) : (
                                                    <Clear color="error" />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.quickScanAvailable ? (
                                                    <Check color="secondary" />
                                                ) : (
                                                    <Clear color="error" />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.valuationAvailable ? (
                                                    <Check color="secondary" />
                                                ) : (
                                                    <Clear color="error" />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ timeout: 0 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="invalidAsset-content"
                        id="invalidAsset-header"
                    >
                        {getResource(
                            "Valuation.quickScan.validAssets.panelHeader",
                            "{validAssetCount} valid assets",
                            { validAssetCount: validAssets.length }
                        )}
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.validAssets.name"
                                                defaultValue="Name"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="col">
                                            <ResourcedText
                                                resourceKey="Valuation.quickScan.validAssets.serial"
                                                defaultValue="Serial"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {validAssets.map((row) => (
                                        <TableRow id={row.id} hover key={row.id}>
                                            <TableCell>
                                                {row.assetName
                                                    ? row.assetName
                                                    : row.manufacturerName +
                                                      " " +
                                                      row.assetModelName}
                                            </TableCell>
                                            <TableCell>{row.serial}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </div>
        </React.Fragment>
    );
});

export default FutureValues;
