import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(
    searchText,
    reason,
    status,
    page,
    pageSize,
    assetId,
    componentId,
    assetModelId,
    manufacturerId
) {
    const params = {
        page,
        pageSize,
        searchText,
        reason,
        status,
        assetId,
        componentId,
        assetModelId,
        manufacturerId,
    };

    const url = `/api/maintenance/workorder?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/maintenance/workorder/${id}`;

    return await interceptedFetch(url);
}

async function getReport(id) {
    const url = `/api/maintenance/workorder/${id}/report`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/maintenance/workorder", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/maintenance/workorder/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function getStatuses() {
    const url = `/api/maintenance/workorder/statuses`;

    return await interceptedFetch(url);
}

async function getReasons() {
    const url = `/api/maintenance/workorder/reasons`;

    return await interceptedFetch(url);
}

async function del(selectedId) {
    return await interceptedFetch(`/api/maintenance/workorder/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, getReport, add, update, getStatuses, getReasons, del };
