import React from "react";
import { Section } from "../shell";
import WorkOrder from "./workOrder";
import AssetWorkOrder from "./assetWorkOrder";
import ComponentWorkOrder from "./componentWorkOrder";
import WorkOrderTask from "./workOrderTask";
import WorkOrderTaskJobSheet from "./workOrderTaskJobSheet";
import WorkOrderReport from "./workOrderReport";
import Build from "@material-ui/icons/Build";
import withResources from "../../shared/textresources/withresources";

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Maintenance.workOrders", "Work orders"),
            href: "/maintenance/work-orders",
            render: WorkOrder,
            requiredOperation: "work-orders-view",
        },
        {
            title: getResource("Maintenance.assetWorkOrders", "Asset work orders"),
            href: "/maintenance/asset-work-orders",
            render: AssetWorkOrder,
            requiredOperation: "work-orders-view",
            hideInMenu: true,
        },
        {
            title: getResource("Maintenance.componentWorkOrders", "Component work orders"),
            href: "/maintenance/component-work-orders",
            render: ComponentWorkOrder,
            requiredOperation: "work-orders-view",
            hideInMenu: true,
        },
        {
            title: getResource("Maintenance.workOrderReport", "Work order report"),
            href: "/maintenance/work-orders/:workOrderId/report",
            render: WorkOrderReport,
            requiredOperation: "work-orders-view-report",
            hideInMenu: true,
        },
        {
            title: getResource("Maintenance.workOrderTasks", "Work orders tasks"),
            href: "/maintenance/work-orders/:workOrderId/tasks",
            render: WorkOrderTask,
            requiredOperation: "work-orders-task-view",
            hideInMenu: true,
        },
        {
            title: getResource("Maintenance.workOrderTaskJobSheet", "Work order task job sheet"),
            href: "/maintenance/work-orders/:workOrderId/tasks/:taskId",
            render: WorkOrderTaskJobSheet,
            requiredOperation: "work-orders-task-update",
            hideInMenu: true,
        },
    ];

    return [
        <Section
            key="Maintenance"
            title={getResource("Maintenance.title", "Maintenance")}
            icon={Build}
            href="/maintenance"
            children={children}
        />,
    ];
});
