import { makeStyles } from "@material-ui/styles";
import { colors } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "--fc-page-bg-color": "#fafafa",

        /** Generic **/
        "& button": {
            transition: "background 250ms cubic-bezier(0.4, 0, 0.2, 1)",

            "&:focus:not(:focus-visible)": {
                boxShadow: "none !important",
            },
        },

        /** Toolbar **/
        "& .fc-toolbar.fc-header-toolbar": {
            [theme.breakpoints.down(700)]: {
                flexDirection: "column",

                "& .fc-toolbar-chunk": {
                    "&:nth-child(1)": {
                        order: 2,
                        marginTop: theme.spacing(0.5),
                    },

                    "&:nth-child(2)": {
                        order: 1,
                        marginTop: theme.spacing(0.5),
                    },

                    "&:nth-child(3)": {
                        order: 3,
                        marginTop: theme.spacing(1),
                    },
                },
            },
        },

        "& .fc-toolbar-chunk:first-child": {
            "& .fc-button": {
                color: "inherit",
                backgroundColor: "transparent",
                border: "none",

                "&:not([disabled]):hover": {
                    backgroundColor: "rgba(38, 50, 56, 0.04)",
                },
            },
        },

        "& .fc-toolbar-title": {
            fontSize: 20,
            fontWeight: 500,
        },

        "& .fc-toolbar-chunk:last-child": {
            "& .fc-button": {
                fontSize: 13,
                color: colors.grey[600],
                borderColor: "rgba(0, 0, 0, 0.12)",
                transition: "background-color 0.4s",
                textTransform: "uppercase",
                backgroundColor: theme.palette.white,

                "&:hover": {
                    backgroundColor: "rgba(38, 50, 56, 0.05)",
                },

                "&.fc-button-active": {
                    color: "inherit",
                    fontWeight: 500,
                    backgroundColor: "rgba(0, 0, 0, 0.12)",

                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.15)",
                    },
                },
            },
        },

        "& .fc-view-harness": {
            /** table head **/
            "& thead table": {
                "& th": {
                    fontSize: 12,
                    fontWeight: "inherit",
                    padding: theme.spacing(0.5, 0),
                },
            },

            /** table body **/
            "& tbody table": {
                backgroundColor: theme.palette.white,
            },
        },

        /** List view **/
        "& .fc-list-day-cushion": {
            backgroundColor: "transparent",
        },

        "& .fc-list-event": {
            backgroundColor: theme.palette.white,

            "&:hover": {
                cursor: "pointer",
            },
        },

        /** Event: **/
        "& .fc-event-title": {
            top: 1,
            fontWeight: 500,
        },

        "& .fc-list-event-title": {
            fontWeight: 500,
        },
    },

    timeLineCalendar: {
        "& [data-resource-id='Storage0']": {
            backgroundColor: "#eee",
        },

        "& .fc-view-harness": {
            "& tbody table": {
                backgroundColor: "inherit",
            },
        },

        "& .fc-datagrid-cell-main": {
            textDecoration: "underline",
            cursor: "pointer",
            transition: "color 0.3s",

            "&:hover": {
                color: theme.palette.primary.main,
            },
        },

        "& .fc-event-editable .fc-event-main-frame": {
            cursor: "pointer",
        },

        "& .fc-event-main-frame": {
            position: "relative",

            "& .custom-icon": {
                marginTop: 1,
                marginLeft: 4,

                "& svg": {
                    width: 14,
                    height: 14,
                },
            },
        },

        "& th": {
            "& .fc-datagrid-cell-main": {
                textDecoration: "none",
                cursor: "default",

                "&:hover": {
                    color: theme.palette.black,
                },
            },
        },

        "& .fc-timeline-bg": {
            "& .fc-event-main-frame": {
                height: "100%",
            },
        },
    },
}));

export default useStyles;
