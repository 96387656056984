import React from "react";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import _ from "underscore";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import withResources from "../../../shared/textresources/withresources";
import PagedListContent from "./pagedListContent";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="advertisements-view" hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource(
                            "Marketplace.advertisements.header.title",
                            "Advertisements"
                        )}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="advertisements-create"
                                    buttonText={getResource(
                                        "Marketplace.advertisements.newAdvertisementButton",
                                        "Create advertisement"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Marketplace.advertisements.noResults",
                            "No advertisements available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <PagedListContent
                            items={items}
                            handleRowClick={(row) => onEdit(row.id)}
                            requiredOperation="advertisements-update"
                            getRequiredOperationForRowClick={() => "advertisements-update"}
                            renderActionMenu={(row, assetName) => (
                                <ActionMenu
                                    actions={[
                                        {
                                            text: getResource(
                                                "Marketplace.advertisements.editAdvertisementButton",
                                                "Edit"
                                            ),
                                            icon: <EditIcon />,
                                            itemId: row.id,
                                            requiredOperation: "advertisements-edit",
                                            onClick: () => onEdit(row.id),
                                        },
                                        {
                                            text: getResource(
                                                "Marketplace.advertisements.deleteAdvertisementButton",
                                                "Delete"
                                            ),
                                            icon: <DeleteIcon />,
                                            itemId: row.id,
                                            requiredOperation: "advertisements-delete",
                                            onClick: () =>
                                                onDelete({
                                                    id: row.id,
                                                    assetName: assetName,
                                                    version: row.version,
                                                }),
                                            hide: row.type === "Auction",
                                        },
                                    ]}
                                />
                            )}
                        />
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
