import React, { useState, useEffect } from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    FormControl,
    Slider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";

import useAPI from "../../../shared/useapi";
import { getAll, update } from "./dataservice";
import { getAll as getCountries } from "./../../library/countries/dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

export default withResources(({ getResource, assetModelId, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading: dataLoading, data } = useAPI(() => getAll(assetModelId), true, [assetModelId]);

    const { data: countries } = useAPI(() => getCountries(), true);

    const [countryCorrections, setCountryCorrections] = useState();

    useEffect(() => {
        data && setCountryCorrections(data);
    }, [data]);

    const { loading, invoke } = useAPI((values) => update(assetModelId, values));

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        return errors;
    };

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    var isLoaded = !dataLoading && countries;
    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Valuation.countryCorrection.title", "Country corrections")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Valuation.countryCorrection.okButton", "Save")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange) => {
                        return (
                            <React.Fragment>
                                <Table size="small">
                                    <TableBody>
                                        {countryCorrections.map((countryCorrection, i) => (
                                            <TableRow hover key={countryCorrection.country}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: "25%" }}
                                                >
                                                    {
                                                        countries.find(
                                                            (x) =>
                                                                x.code === countryCorrection.country
                                                        ).name
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: "25%" }}
                                                >
                                                    <Slider
                                                        value={countryCorrection.percentage}
                                                        steps={1}
                                                        min={-100}
                                                        max={200}
                                                        onChange={(e, newValue) => {
                                                            var copy = [
                                                                ...countryCorrections.slice(0, i),
                                                                {
                                                                    ...countryCorrections[i],
                                                                    percentage: newValue,
                                                                },
                                                                ...countryCorrections.slice(i + 1),
                                                            ];
                                                            setCountryCorrections(copy);
                                                            handleChange({
                                                                target: {
                                                                    name: "countryCorrections",
                                                                    value: copy,
                                                                },
                                                            });
                                                        }}
                                                        valueLabelDisplay="on"
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{ width: "40%" }}
                                                >
                                                    <ResourcedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                        labelResourceKey="Valuation.countryCorrection.noteField"
                                                        labelDefaultValue="Note"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={countryCorrection.note}
                                                        onChange={(e) => {
                                                            var copy = [
                                                                ...countryCorrections.slice(0, i),
                                                                {
                                                                    ...countryCorrections[i],
                                                                    note: e.target.value,
                                                                },
                                                                ...countryCorrections.slice(i + 1),
                                                            ];
                                                            setCountryCorrections(copy);
                                                            handleChange({
                                                                target: {
                                                                    name: "countryCorrections",
                                                                    value: copy,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: "10%" }}>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            var copy = countryCorrections.filter(
                                                                (item) =>
                                                                    item.country !==
                                                                    countryCorrection.country
                                                            );
                                                            setCountryCorrections(copy);
                                                            handleChange({
                                                                target: {
                                                                    name: "countryCorrections",
                                                                    value: copy,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {countries.length !== countryCorrections.length && (
                                            <TableRow hover>
                                                <TableCell component="th" scope="row" colSpan="4">
                                                    <FormControl fullWidth>
                                                        <AutoCompleteStatic
                                                            label={getResource(
                                                                "Valuation.countryCorrection.add.addCountryCorrectionField",
                                                                "Add country correction"
                                                            )}
                                                            fullWidth
                                                            isMedium
                                                            onSelection={(e) => {
                                                                if (e.target.value) {
                                                                    var copy = countryCorrections.concat(
                                                                        {
                                                                            country: e.target.value,
                                                                            percentage: 0,
                                                                            note: "",
                                                                        }
                                                                    );
                                                                    setCountryCorrections(copy);
                                                                    handleChange({
                                                                        target: {
                                                                            name:
                                                                                "countryCorrections",
                                                                            value: copy,
                                                                        },
                                                                    });
                                                                }
                                                            }}
                                                            value=""
                                                            options={countries
                                                                .filter(
                                                                    (country) =>
                                                                        !countryCorrections.some(
                                                                            (x) =>
                                                                                x.country ===
                                                                                country.code
                                                                        )
                                                                )
                                                                .map((c) => ({
                                                                    id: c.code,
                                                                    name: c.name,
                                                                }))}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
