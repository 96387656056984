import React from "react";
import useAPI from "../../../shared/useapi";
import { reject } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(reject);

    const handleReject = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    const handleClose = () => {
        onClose(false);
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleReject}
            handleClose={handleClose}
            headerText={getResource("Security.applications.reject.header", "Reject application?")}
            buttonText={getResource("Security.applications.reject.okButton", "Reject")}
        >
            <ResourcedText
                resourceKey="Security.applications.reject.message"
                defaultValue="You will reject this application, this action cannot be undone. Are you sure?"
            />
        </Confirmation>
    );
});
