import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import Widget from "../widget";
import useStyles from "./useStyles";

const TableWidget = ({
    requiredOperation,
    headerTitle,
    headerLink,
    headerLinkTitle,
    onHeaderClick,
    content,
    headers,
    loading,
    hasContent,
    clickProperty,
    data,
    onRowClick,
    icon,
    noDataText,
    addButtonText,
    addButtonUrl,
    onAddButtonClick,
    addButtonRequiredOperation,
    caption,
    leftCellWidth = "auto",
    rightCellWidth = 90,
    rightCellTextAlign = "left",
}) => {
    const classes = useStyles({ leftCellWidth, rightCellWidth, rightCellTextAlign });
    const history = useHistory();

    return (
        <Widget
            requiredOperation={requiredOperation}
            headerTitle={headerTitle}
            headerLink={headerLink}
            onHeaderClick={onHeaderClick}
            headerLinkTitle={headerLinkTitle}
            noContentSpacing={true}
            loading={loading}
            hasContent={hasContent}
            icon={icon}
            noDataText={noDataText}
            addButtonText={addButtonText}
            addButtonUrl={addButtonUrl}
            onAddButtonClick={onAddButtonClick}
            addButtonRequiredOperation={addButtonRequiredOperation}
            renderContent={() => (
                <Fragment>
                    <Table className={classes.table}>
                        <TableBody>
                            {headers && (
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableCell key={header} component="th" scope="col">
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )}
                            {data &&
                                data.map((row, index) => {
                                    let rowProps = {};
                                    if (clickProperty) {
                                        rowProps.className = classes.clickableRow;
                                        if (onRowClick) {
                                            rowProps.onClick = () => onRowClick(row[clickProperty]);
                                        } else {
                                            rowProps.onClick = () =>
                                                history.push(row[clickProperty]);
                                        }
                                    }

                                    return (
                                        <TableRow key={index} {...rowProps}>
                                            {Object.keys(row).map((key, cellIndex) => {
                                                if (key === clickProperty) {
                                                    return null;
                                                }

                                                return (
                                                    <TableCell
                                                        key={key}
                                                        component="td"
                                                        className={clsx(
                                                            classes.cell,
                                                            index % 2 === 0 ? "odd" : "even"
                                                        )}
                                                        title={
                                                            cellIndex === 0 ? row[key] : undefined
                                                        }
                                                    >
                                                        {row[key]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    {caption && (
                        <Typography className={classes.caption} variant="caption">
                            {caption}
                        </Typography>
                    )}
                    {content && (
                        <Typography className={classes.content} component="p" variant="body2">
                            {content}
                        </Typography>
                    )}
                </Fragment>
            )}
        />
    );
};

TableWidget.propTypes = {
    requiredOperation: PropTypes.string,
    headerTitle: PropTypes.string,
    headerLink: PropTypes.string,
    headerLinkTitle: PropTypes.string,
    onHeaderClick: PropTypes.func,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    headers: PropTypes.array,
    loading: PropTypes.bool,
    hasContent: PropTypes.bool,
    clickProperty: PropTypes.string,
    data: PropTypes.array,
    onRowClick: PropTypes.func,
    icon: PropTypes.object,
    noDataText: PropTypes.string,
    addButtonText: PropTypes.string,
    addButtonUrl: PropTypes.string,
    onAddButtonClick: PropTypes.func,
    addButtonRequiredOperation: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.array,
        PropTypes.string,
        PropTypes.object,
    ]),
    caption: PropTypes.string,
};

export default TableWidget;
