import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";
import ResourcedTextField from "../../../../../shared/textresources/resourcedtextfield";

class Convert extends Component {
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <ResourcedTextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        labelResourceKey="Valuation.edit.convert.valueField"
                        labelDefaultValue="Value"
                        onChange={(e) => this.props.onHandleChange(e.target, "ConvertToValue")}
                        value={this.props.convertToValue}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" variant="contained" onClick={this.props.onHandleValue}>
                        <ResourcedText
                            resourceKey="Valuation.edit.convertButton"
                            defaultValue="Convert"
                        />
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default Convert;
