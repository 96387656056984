import React from "react";
import { LinearProgress, Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import useAPI from "../../../shared/useapi";
import { getValuationCorrection, updateValuationCorrection } from "./dataservice";

import LocalizedForm from "../../../shared/localization/localizedform";
import LabelledOutline from "../../../shared/labelledoutline";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";

const marksPositiveNegativePercentage = [
    { value: -100, label: "-100%" },
    { value: -75, label: "-75%" },
    { value: -50, label: "-50%" },
    { value: -25, label: "-25%" },
    { value: 0, label: "0%" },
    { value: 25, label: "25%" },
    { value: 50, label: "50%" },
    { value: 75, label: "75%" },
    { value: 100, label: "100%" },
];

const useStyles = makeStyles((theme) => ({
    percentage: {
        "& > div > div": {
            margin: "0 10px",
        },
    },
    percentageSlider: {
        marginTop: 30,
    },
}));

export default withResources(({ getResource, id, open, onClose }) => {
    if (!open) {
        return false;
    }

    const classes = useStyles();

    const { data } = useAPI(() => getValuationCorrection(id), true, [id]);

    const { loading, invoke } = useAPI((values) => updateValuationCorrection(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource(
                "Assets.assetCollection.correction.title",
                "Edit collection - valuation correction"
            )}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.assetCollection.correction.okButton", "Save")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <LabelledOutline
                                    label={getResource(
                                        "Assets.assetCollection.correction.percentageField",
                                        "Percentage"
                                    )}
                                    className={classes.percentage}
                                >
                                    <Slider
                                        className={classes.percentageSlider}
                                        value={values.percentage}
                                        steps={1}
                                        min={-100}
                                        max={100}
                                        marks={marksPositiveNegativePercentage}
                                        onChange={(e, newValue) => {
                                            handleChange({
                                                target: { name: "percentage", value: newValue },
                                            });
                                        }}
                                        valueLabelDisplay="on"
                                    />
                                </LabelledOutline>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="note"
                                    labelResourceKey="Assets.assetCollection.correction.noteField"
                                    labelDefaultValue="Note"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 255 }}
                                    value={values.note}
                                    onChange={(e) => handleChange(e)}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
