import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Route, Switch } from "react-router";
import { Slot, Fill } from "../../../shared/slotmachine/index";
import ErrorContext from "../../../shared/errorcontext";
import ErrorMessage from "../../../shared/errormessage";
import useAPI from "../../../shared/useapi";
import TopBar from "./topbar";
import NavBar from "./navbar";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import clsx from "clsx";

const TopLevelRoute = ({ href, render }) => {
    return (
        <Fill name="top-level-route">
            <Route path={href}>{render()}</Route>
        </Fill>
    );
};

export { TopLevelRoute };

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },

    topBar: {
        zIndex: 2,
        position: "relative",
    },

    container: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",

        "&.--has-mobile-nav-bar": {
            [theme.breakpoints.down("md")]: {
                paddingLeft: 20,

                "&::after": {
                    content: "''",
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: 20,
                    backgroundColor: "white",
                    borderRight: `1px solid ${theme.palette.grey[300]}`,
                },
            },
        },
    },

    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: "0 0 auto",
    },

    content: {
        overflowY: "auto",
        overflowX: "hidden",
        flex: "1 1 auto",
    },
}));

const Dashboard = ({ route, children }) => {
    const [errors, setErrors] = useState([]);

    const handleError = (error) => {
        if (typeof error === "string") {
            setErrors([error, ...errors]);
        } else {
            Object.keys(error).forEach((field) => {
                setErrors([`${field}: ${error[field]}`, ...errors]);
            });
        }
    };

    useAPI.onError = handleError;

    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const { isAuthenticated } = useContext(AuthenticationContext);

    const handleNavBarMobileOpen = () => {
        setOpenNavBarMobile(true);
    };

    const handleNavBarMobileClose = () => {
        setOpenNavBarMobile(false);
    };

    return (
        <div className={classes.root}>
            <ErrorContext.Provider value={handleError}>
                {errors.map((e) => (
                    <ErrorMessage
                        key={e}
                        message={e}
                        onClose={() => setErrors(errors.filter((error) => error !== e))}
                    />
                ))}
                <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
                <div className={clsx(classes.container, isAuthenticated && "--has-mobile-nav-bar")}>
                    <Slot
                        name="top-level-route"
                        list
                        renderInner={(children) => <Switch>{children}</Switch>}
                    />

                    <TopLevelRoute
                        href="/"
                        render={() => {
                            return (
                                <React.Fragment>
                                    <NavBar
                                        className={classes.navBar}
                                        onMobileClose={handleNavBarMobileClose}
                                        openMobile={openNavBarMobile}
                                    />
                                    <main id="main" className={classes.content}>{children}</main>
                                </React.Fragment>
                            );
                        }}
                    />
                </div>
            </ErrorContext.Provider>
        </div>
    );
};

Dashboard.propTypes = {
    route: PropTypes.object,
};

export default Dashboard;
