import React from "react";
import { Section } from "../shell";
import AssetModelQuickScan from "./assetmodelquickscan";
import AssetQuickScan from "./assetquickscan";
import Report from "./quickscan/report";
import withResources from "../../shared/textresources/withresources";
import { QuickScanIcon } from "../../shared/customIcons";

import { ReferenceExtension } from "../../shared/reference/referenceExtension";
import { getQuickScan } from "./quickscan/dataservice";

export default withResources(({ getResource }) => {
    const quickScanChildren = [
        {
            title: getResource("Valuation.assetQuickScansTitle", "Asset quick scans"),
            href: "/quick-scan/asset",
            render: AssetQuickScan,
            requiredOperation: "asset-quick-scans-view",
        },
        {
            title: getResource("Valuation.assetModelQuickScansTitle", "Model quick scans"),
            href: "/quick-scan/asset-model",
            render: AssetModelQuickScan,
            requiredOperation: "asset-model-quick-scans-view",
        },
        {
            href: "/quick-scan/report/:quickScanId",
            render: Report,
            requiredOperationAtLeastOneOf: [
                "asset-quick-scans-view",
                "asset-model-quick-scans-view",
            ],
            hideInMenu: true,
        }
    ];

    return [
        <Section
            key="quickScan"
            title={getResource("QuickScan.title", "Quick scans")}
            requiresAuthentication
            icon={QuickScanIcon}
            href="/quick-scan"
            children={quickScanChildren}
        />,

        <ReferenceExtension
            key="QuickScan-reference"
            type="QuickScan"
            fetchMethod={getQuickScan}
            nameMethod={(quickScan) => quickScan && quickScan.reference}
            breadcrumbMethod={(quickScan) => null}
        />,
    ];
});
