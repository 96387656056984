import React from "react";
import { Slot } from "../../../shared/slotmachine";

export default ({ reference }) => {
    if (!reference) {
        return null;
    }

    return <Slot name="component-summary" list={true} component={reference} />;
};
