import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    root: {},
    listItem: {
        "&:hover": {
            backgroundColor: theme.palette.background.default,
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.darkest,
    },
    arrowForwardIcon: {
        color: theme.palette.icon,
    },
}));

const NotificationList = ({ notifications, className, icons, onClick, ...rest }) => {
    const classes = useStyles();

    return (
        <List {...rest} className={clsx(classes.root, className)} disablePadding>
            {notifications.map((notification, i) => (
                <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider={i < notifications.length - 1}
                    key={i}
                    onClick={onClick}
                    to={notification.link}
                >
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            {React.createElement(icons.get(notification.type))}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={notification.title}
                        secondary={notification.text}
                        primaryTypographyProps={{ variant: "body1" }}
                    />
                    <ArrowForwardIcon className={classes.arrowForwardIcon} />
                </ListItem>
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    icons: PropTypes.any,
};

export default NotificationList;
