import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { deactivate } from "./dataservice";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(
    ({ getResource, onClose, open, userId, userFirstName, userLastName, userIsSystemAdmin }) => {
        if (!open) {
            return null;
        }

        const { loggedInUser } = useContext(AuthenticationContext);

        const { loading, invoke } = useAPI(deactivate);

        const handleDeactivate = async () => {
            if (await invoke(userId)) {
                onClose(true);
            }
        };

        return (
            <Confirmation
                open={open}
                loading={loading}
                handleAction={handleDeactivate}
                handleClose={onClose}
                headerText={getResource("Security.users.deActivate.header", "Deactivate user?")}
                buttonText={getResource("Security.users.deActivate.okButton", "Deactivate")}
                buttonHidden={loggedInUser.id === userId || userIsSystemAdmin}
            >
                {loggedInUser.id === userId ? (
                    <ResourcedText
                        resourceKey="Security.users.deActivate.cannotDeactivateOwnAccountMessage"
                        defaultValue="You cannot deactivate your own account"
                    />
                ) : userIsSystemAdmin ? (
                    <ResourcedText
                        resourceKey="Security.users.deActivate.cannotDeactivateSysadminMessage"
                        defaultValue="A system administrator cannot be deactivated"
                    />
                ) : (
                    <React.Fragment>
                        {getResource(
                            "Security.users.deActivate.confirmationMessage",
                            "User '{userFirstName} {userLastName}' will be deactivated.",
                            { userFirstName, userLastName }
                        )}
                    </React.Fragment>
                )}
            </Confirmation>
        );
    }
);
