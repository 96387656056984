import React from "react";
import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Titled } from "react-titled";
import { ThemeProvider } from "@material-ui/styles";

import Dashboard, { TopLevelRoute } from "./components/dashboard";
import Home from "./components/home";
import { Slot, Fill } from "../../shared/slotmachine/index";
import { NavLink } from "./components/navbar";
import { TopNavLink } from "./components/topbar";
import theme from "./theme";
import ConfigurationProvider from "../../shared/configuration/configurationprovider";
import WorkingScopeProvider from "./components/workingScope/workingscopeprovider";
import PrivateRoute from "../../shared/authentication/privateroute";
import withResources from "../../shared/textresources/withresources";
import getUserConfirmation from "../../shared/getUserConfirmation";

const Section = ({ href, render, children, icon, title, open, hideInMenu }) => {
    var allOperations =
        children &&
        children
            .filter((c) => c.requiredOperation)
            .map((c) => c.requiredOperation)
            .concat(
                children
                    .filter((c) => c.requiredOperationAtLeastOneOf)
                    .flatMap((c) => c.requiredOperationAtLeastOneOf)
            );

    return [
        <Fill key="main-route" name="main-route">
            <PrivateRoute
                key={href}
                path={href}
                component={render}
                requiredOperationAtLeastOneOf={allOperations}
            >
                <Titled title={(pageTitle) => (title ? `${pageTitle} - ${title}` : `${pageTitle}`)}>
                    {children &&
                        children
                            .filter((c) => !c.linkOnly)
                            .map((c) => {
                                if (!c.requiredOperation && !c.requiredOperationAtLeastOneOf) {
                                    return (
                                        <Route key={c.path || c.href} path={c.path || c.href} component={c.render} />
                                    );
                                } else {
                                    return (
                                        <PrivateRoute
                                            key={c.path || c.href}
                                            path={c.path || c.href}
                                            component={c.render}
                                            requiredOperation={c.requiredOperation}
                                            requiredOperationAtLeastOneOf={
                                                c.requiredOperationAtLeastOneOf
                                            }
                                        />
                                    );
                                }
                            })}
                </Titled>
            </PrivateRoute>
        </Fill>,
        !hideInMenu && (
            <NavLink
                key="nav-link"
                href={href}
                icon={icon}
                title={title}
                open={open}
                pages={children}
            />
        ),
    ];
};

const ApplicationTitle = withResources(({ getResource, children }) => {
    var applicationTitle = getResource("Shell.applicationTitle", "WTC");

    return <Titled title={() => applicationTitle}>{children}</Titled>;
});

export { Section, ApplicationTitle, TopNavLink, TopLevelRoute, NavLink };

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

export default withResources(({ getResource }) => {
    return (
        <WorkingScopeProvider>
            <ThemeProvider theme={theme}>
                <BrowserRouter
                    basename={baseUrl}
                    getUserConfirmation={(message, callback) =>
                        getUserConfirmation(message, callback, getResource)
                    }
                >
                    <ConfigurationProvider>
                        <Dashboard>
                            <PrivateRoute exact path="/" component={Home} />

                            <Slot name="main-route" list />
                        </Dashboard>
                    </ConfigurationProvider>
                </BrowserRouter>
            </ThemeProvider>
        </WorkingScopeProvider>
    );
});
