import React, { useContext } from "react";
import queryString from "query-string";
import { Alert } from "@material-ui/lab";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import CheckboxPanel from "./components/checkboxPanel";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";

export default withResources(({ getResource, open, onClose }) => {
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    if (!open) {
        return null;
    }

    const onSubmit = async (values) => {
        const url = `/api/assets/asset/excelExport?${queryString.stringify(
            {
                ...values.values,
                companyId: companyScope.id,
                assetCollectionId: assetCollectionScope.id,
            },
            {
                skipNull: true,
                skipEmptyString: true,
            }
        )}`;

        window.open(url, "_blank");
        onClose();
    };

    const onValidate = (values, cultures, defaultCulture) => {
        var errors = {};

        return errors;
    };

    return (
        <FormDrawer
            title={getResource("Assets.asset.export.title", "Export assets to excel")}
            onClose={() => onClose(false)}
        >
            <LocalizedForm
                initialValues={{ basicInformation: true }}
                okButtonText={getResource("Assets.asset.export.okButton", "Export")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture
                ) => {
                    return (
                        <React.Fragment>
                            <CheckboxPanel
                                values={values}
                                partTitle="Basic information"
                                partName="basicInformation"
                                onChange={handleChange}
                                checkboxes={[
                                    { title: "Company", variable: "company" },
                                    { title: "Asset type", variable: "assetType" },
                                    { title: "Category", variable: "category" },
                                    { title: "Manufacturer", variable: "manufacturer" },
                                    { title: "Collection", variable: "collection" },
                                    { title: "Model", variable: "model" },
                                    { title: "Name", variable: "name" },
                                    { title: "Description", variable: "description" },
                                    { title: "Country", variable: "country" },
                                    { title: "Location", variable: "location" },
                                    { title: "Position URL", variable: "positionUrl" },
                                    { title: "Construction date", variable: "constructionDate" },
                                    { title: "Internal Id", variable: "internalId" },
                                    { title: "Serial", variable: "serial" },
                                    { title: "Registration", variable: "registration" },
                                    { title: "Emission impact", variable: "emissionImpact" },
                                    { title: "Expected usage", variable: "expectedUsage" },
                                    { title: "Current usage", variable: "currentUsage" },
                                    { title: "Attributes", variable: "attributes" },
                                ]}
                            />
                            <CheckboxPanel
                                values={values}
                                partTitle="Financial information"
                                partName="financialInformation"
                                onChange={handleChange}
                                checkboxes={[
                                    { title: "Currency", variable: "financialCurrency" },
                                    { title: "Purchase date", variable: "financialPurchaseDate" },
                                    { title: "Purchase price", variable: "financialPurchasePrice" },
                                    {
                                        title: "Remaining value",
                                        variable: "financialRemainingValue",
                                    },
                                    {
                                        title: "Depreciation period",
                                        variable: "financialDeprecationPeriod",
                                    },
                                    {
                                        title: "Liquidation cost",
                                        variable: "financialLiquidationCost",
                                    },
                                    { title: "Note", variable: "financialNote" },
                                ]}
                            />
                            <CheckboxPanel
                                values={values}
                                partTitle="Loan information"
                                partName="loanInformation"
                                onChange={handleChange}
                                checkboxes={[
                                    { title: "Start date", variable: "loanStartDate" },
                                    { title: "Initial amount", variable: "loanInitialAmount" },
                                    { title: "Remaining amount", variable: "loanRemainingAmount" },
                                    { title: "Interest rate", variable: "loanInterestRate" },
                                    { title: "Loan period", variable: "loanPeriod" },
                                    { title: "Payment method", variable: "loanPaymentMethod" },
                                    { title: "Type", variable: "loanType" },
                                ]}
                            />
                            <CheckboxPanel
                                values={values}
                                partTitle="Quick scan values"
                                partName="quickScanInformation"
                                onChange={handleChange}
                                checkboxes={[
                                    { title: "Reference", variable: "quickScanReference" },
                                    { title: "Currency", variable: "quickScanCurrency" },
                                    {
                                        title: "Fair market value",
                                        variable: "quickScanFairMarketValue",
                                    },
                                    {
                                        title: "Liquidation value",
                                        variable: "quickScanLiquidationValue",
                                    },
                                ]}
                            />

                            <Alert severity="info">
                                {getResource(
                                    "Assets.asset.export.infoText",
                                    "Results may vary per user, dependent on the permissions of the user"
                                )}
                            </Alert>
                        </React.Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
