import { colors } from "@material-ui/core";

export default {
    root: {
        color: colors.grey[600],

        "&$selected": {
            color: "inherit",
        },
    },

    sizeSmall: {
        height: 30,
        padding: "0 8px",
        fontSize: 10,
    },
};
