import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    contentSection: {
        maxWidth: "65%",

        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
        },
    },

    content: {
        padding: theme.spacing(2, 4),

        "& h1, & h2, & h3, & h4": {
            fontWeight: 500,
        },
    },
}));

export default useStyles;
