import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, status, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        status,
    };

    const url = `/api/marketplace/auction?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/marketplace/auction/${id}`;

    return await interceptedFetch(url);
}

async function create(values) {
    return await interceptedFetch(`/api/marketplace/auction`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/marketplace/auction/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(id, version) {
    return await interceptedFetch(`/api/marketplace/ad/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ version: version }),
    });
}

async function publish(id) {
    return await interceptedFetch(`/api/marketplace/auction/${id}/publish`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function unPublish(id, values) {
    return await interceptedFetch(`/api/marketplace/auction/${id}/unPublish`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

async function archive(id, values) {
    return await interceptedFetch(`/api/marketplace/auction/${id}/archive`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function getStatuses() {
    const url = `/api/marketplace/auction/statuses`;

    return await interceptedFetch(url);
}

async function getBuyoutTypes() {
    const url = `/api/marketplace/auction/buyoutTypes`;

    return await interceptedFetch(url);
}

export {
    getAll,
    get,
    create,
    update,
    del,
    publish,
    unPublish,
    archive,
    getStatuses,
    getBuyoutTypes,
};
