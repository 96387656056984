import { colors } from "@material-ui/core";

export default {
    root: {
        backgroundColor: colors.blueGrey[50],
        color: colors.blueGrey[900],

        "td > &, th > &": {
            margin: "3px 6px 3px 0",
        },
    },
    deletable: {
        "&:focus": {
            backgroundColor: colors.blueGrey[50],

            "& .MuiChip-deleteIcon": {
                color: colors.blueGrey[400],
            },
        },
    },
};
