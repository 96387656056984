import interceptedFetch from "../../../shared/interceptedFetch";

async function get(token) {
    const url = `/api/security/user/reset/${token}`;

    return await interceptedFetch(url);
}

async function forgotPassword(email) {
    return await interceptedFetch("/api/security/user/forgot", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
        }),
    });
}

async function resetPassword(token, password) {
    return await interceptedFetch(`/api/security/user/reset/${token}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            password: password,
        }),
    });
}

export { get, forgotPassword, resetPassword };
