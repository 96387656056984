import React from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import _ from "underscore";

import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ActionMenu from "../../../shared/actionmenu";
import Search from "../../../shared/search";
import PagedList from "../../../shared/pagedList";
import ResourcedText from "../../../shared/textresources/resourcedtext";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <ContentHolder>
                <Header
                    title={getResource("Content.contentPages.header.title", "Content pages")}
                    renderActions={() => {
                        return (
                            <AuthorizedButton
                                color="primary"
                                variant="contained"
                                onClick={onAdd}
                                buttonText={getResource("Content.contentPages.newButton", "Create")}
                                requiredOperation="content-pages-create"
                            />
                        );
                    }}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Content.contentPages.noResults",
                        "No pages available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Content.contentPages.overview.titleHeader"
                                        defaultValue="Title"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Content.contentPages.overview.urlSlugHeader"
                                        defaultValue="URL Slug"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Content.contentPages.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() => onEdit(row.id)}
                                        requiredOperation={"content-pages-update"}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.urlSlug}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Content.contentPages.editButton",
                                                            "Edit"
                                                        ),
                                                        icon: <EditIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "content-pages-update",
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                    {
                                                        showOnlyWhen: !row.isSystemPage,
                                                        text: getResource(
                                                            "Content.contentPages.deleteButton",
                                                            "Delete"
                                                        ),
                                                        icon: <DeleteIcon />,
                                                        itemId: row.id,
                                                        requiredOperation: "content-pages-delete",
                                                        onClick: () =>
                                                            onDelete({
                                                                id: row.id,
                                                                title: row.title,
                                                            }),
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
