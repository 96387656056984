import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update, getCategoryValues } from "./dataservice";
import { getAll as getAssetModels } from "../../library/assetmodels/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoComplete from "../../../shared/autocomplete";
import withResources from "../../../shared/textresources/withresources";
import EditForm, { validate } from "./editform";

export default withResources((props) => {
    const {
        getResource,
        open,
        assetModelId,
        assetModelName,
        manufacturerName,
        assetCategoryId,
        assetCategoryNames,
        onClose,
    } = props;
    if (!open) {
        return null;
    }

    const [selectedAssetModel, setSelectedAssetModel] = useState({
        id: assetModelId,
        manufacturerName,
        name: assetModelName,
    });

    const assetModelIsEditable = assetModelId == null;

    const { data, loading: dataLoading } = useAPI(
        () => get(selectedAssetModel.id),
        Boolean(selectedAssetModel.id),
        [selectedAssetModel]
    );

    const { loading, invoke } = useAPI((values) => update(selectedAssetModel.id, values));

    const { data: categoryQuestionnaire, loading: categoryQuestionnaireLoading } = useAPI(
        () => getCategoryValues(assetCategoryId || selectedAssetModel.assetCategoryId),
        Boolean(assetCategoryId || selectedAssetModel.assetCategoryId),
        [assetCategoryId, selectedAssetModel]
    );

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            onClose(true);
        }
    };

    const categoryNames = assetCategoryNames || selectedAssetModel.assetCategoryNames;
    const categoryPath = categoryNames && categoryNames.join(" > ");

    const isCreate = selectedAssetModel.id == null || (data == null && !dataLoading);

    const [isDirty, setIsDirty] = useState(false);
    return (
        <React.Fragment>
            <FormDrawer
                title={
                    isCreate
                        ? getResource(
                              "Valuation.assetmodelquestionnaire.create.title",
                              "Create model questionnaire"
                          )
                        : getResource(
                              "Valuation.assetmodelquestionnaire.edit.title",
                              "Edit model questionnaire"
                          )
                }
                size="medium"
                onClose={() => onClose(false)}
                isDirty={isDirty}
            >
                <AutoComplete
                    autoFocus
                    label={getResource(
                        "Valuation.assetmodelquestionnaire.edit.assetModelField",
                        "Model"
                    )}
                    value={selectedAssetModel}
                    onGetData={(name, page, pageSize) =>
                        getAssetModels(name, null, null, page, pageSize)
                    }
                    onSelection={(_, selectedItem) => {
                        setSelectedAssetModel(selectedItem || {});
                    }}
                    getTextField={(option) => option.manufacturerName + " " + option.name}
                    renderOption={(option) => (
                        <div>
                            {option.manufacturerName} {option.name}
                        </div>
                    )}
                    disabled={!assetModelIsEditable}
                    disableClearable
                    required
                    name="assetModel"
                />
                {dataLoading || categoryQuestionnaireLoading || selectedAssetModel.id == null ? (
                    (dataLoading || categoryQuestionnaireLoading) && <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={data || { action: "Inherit", questions: [] }}
                        onIsDirty={setIsDirty}
                        loading={loading}
                        okButtonText={
                            isCreate
                                ? getResource(
                                      "Valuation.assetmodelquestionnaire.create.okButton",
                                      "Create"
                                  )
                                : getResource(
                                      "Valuation.assetmodelquestionnaire.edit.okButton",
                                      "Update"
                                  )
                        }
                        onSubmit={onSubmit}
                        onValidate={(values) => validate(values, getResource)}
                        renderForm={(culture, values, handleChange, errors) => (
                            <EditForm
                                categoryQuestionnaire={categoryQuestionnaire}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                categoryPath={categoryPath}
                            />
                        )}
                    />
                )}
            </FormDrawer>
        </React.Fragment>
    );
});
