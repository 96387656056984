import React, { Fragment } from "react";
import ResourcedTextField from "../../../../../shared/textresources/resourcedtextfield";
import { FormControlLabel, Switch } from "@material-ui/core";
import MoneyInput from "../../../../../shared/moneyInput";
import AutoCompleteStatic from "../../../../../shared/autocompleteStatic";
import FormattedCurrency from "../../../../../shared/formattedCurrency";
import withResources from "../../../../../shared/textresources/withresources";
import useStyles from "./useStyles";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import ResourcedText from "../../../../../shared/textresources/resourcedtext";

const TaskFields = withResources((props) => {
    const { getResource, task, errors, handleChange, currencyCode, splitted, users } = props;

    const classes = useStyles();

    const {
        costEstimationHours: hours,
        costEstimationHourlyRate: hourlyRate,
        costEstimationCostsOfPartsAndMaterials: partsAndMaterials,
    } = task;
    const totalCosts = hours * hourlyRate + partsAndMaterials;
    const hasTotalCosts = Boolean(totalCosts);

    return (
        <Fragment>
            {!task.isDefaultTask && (
                <ResourcedTextField
                    errors={errors}
                    margin="normal"
                    name="summary"
                    labelResourceKey="Maintenance.workOrder.tasks.summaryField"
                    labelDefaultValue="Summary"
                    type="text"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 255 }}
                    value={task.summary || ""}
                    onChange={(e) => handleChange(e)}
                    required
                />
            )}

            {!task.isDefaultTask && (
                <ResourcedTextField
                    errors={errors}
                    margin="normal"
                    name="description"
                    labelResourceKey="Maintenance.workOrder.tasks.descriptionField"
                    labelDefaultValue="Description"
                    type="text"
                    variant="outlined"
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    value={task.description || ""}
                    onChange={(e) => handleChange(e)}
                />
            )}

            <AutoCompleteStatic
                name="assignedToUserId"
                value={task.assignedToUserId}
                label={getResource("Maintenance.workOrder.tasks.assignedToField", "Assigned to")}
                options={users}
                onSelection={(e) => handleChange(e)}
                fullWidth
                isMedium
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                name="internalReference"
                labelResourceKey="Maintenance.workOrder.tasks.internalReferenceField"
                labelDefaultValue="Internal reference"
                type="text"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
                value={task.internalReference || ""}
                onChange={(e) => handleChange(e)}
            />

            <MoneyInput
                errors={errors}
                margin="normal"
                name="hourlyRate"
                labelResourceKey="Maintenance.workOrder.tasks.hourlyRate"
                labelDefaultValue="Hourly rate"
                value={task.hourlyRate}
                fullWidth
                currencyCode={currencyCode}
                useDecimals
                onChange={(e) => handleChange(e)}
            />

            <FormHelperText className={classes.helperText}>
                <ResourcedText
                    resourceKey="Maintenance.workOrder.tasks.hourlyRateHelperText"
                    defaultValue="You can specify the hourly rate used to calculate the total cost of this task."
                />
            </FormHelperText>

            <div className={classes.switchWrapper}>
                <FormControlLabel
                    label={getResource(
                        "Maintenance.workOrder.tasks.allowRateOverrideField",
                        "Allow override on job sheet"
                    )}
                    variant="outlined"
                    control={
                        <Switch
                            checked={Boolean(task.allowRateOverride)}
                            onChange={(e) => handleChange(e)}
                            name="allowRateOverride"
                        />
                    }
                />

                <FormHelperText className={classes.helperText}>
                    <ResourcedText
                        resourceKey="Maintenance.workOrder.tasks.allowRateOverrideHelperText"
                        defaultValue="By default a user cannot override the hourly rate when filling out the job sheet."
                    />
                </FormHelperText>
            </div>

            <div className={classes.switchWrapper}>
                <FormControlLabel
                    label={getResource(
                        "Maintenance.workOrder.tasks.provideCostEstimationField",
                        "Provide cost estimation"
                    )}
                    variant="outlined"
                    control={
                        <Switch
                            checked={Boolean(task.provideCostEstimation)}
                            onChange={(e) => handleChange(e)}
                            name="provideCostEstimation"
                        />
                    }
                />

                <FormHelperText className={classes.helperText}>
                    <ResourcedText
                        resourceKey="Maintenance.workOrder.tasks.costEstimationHelperText"
                        defaultValue="You can provide a cost estimation to be able to compare the real cost once the task is finished."
                    />
                </FormHelperText>
            </div>

            {task.provideCostEstimation && (
                <div>
                    <ResourcedTextField
                        errors={errors}
                        margin="normal"
                        variant="outlined"
                        name="costEstimationHours"
                        labelResourceKey="Maintenance.workOrder.tasks.costEstimationHoursField"
                        labelDefaultValue="Hours"
                        type="number"
                        fullWidth
                        value={task.costEstimationHours || ""}
                        inputProps={{ step: 0.05, min: 0 }}
                        onChange={(e) => handleChange(e)}
                    />

                    <MoneyInput
                        errors={errors}
                        name="costEstimationHourlyRate"
                        labelResourceKey="Marketplace.workOrder.tasks.costEstimationHourlyRateField"
                        labelDefaultValue="Hourly rate"
                        fullWidth
                        value={task.costEstimationHourlyRate}
                        currencyCode={currencyCode}
                        useDecimals
                        onChange={(e) => handleChange(e)}
                    />

                    <MoneyInput
                        errors={errors}
                        name="costEstimationCostsOfPartsAndMaterials"
                        labelResourceKey="Marketplace.workOrder.tasks.costEstimationCostsOfPartsAndMaterialsField"
                        labelDefaultValue="Cost of parts and materials"
                        fullWidth
                        value={task.costEstimationCostsOfPartsAndMaterials}
                        currencyCode={currencyCode}
                        useDecimals
                        onChange={(e) => handleChange(e)}
                    />

                    <div
                        className={clsx(
                            classes.totalWrapper,
                            hasTotalCosts && "--has-total-costs",
                            !splitted && "--has-margin-bottom"
                        )}
                    >
                        {getResource("Maintenance.workOrder.tasks.totalField", "Total")}
                        :&nbsp;
                        {hasTotalCosts && (
                            <FormattedCurrency
                                currency={currencyCode}
                                value={totalCosts}
                                decimals={2}
                            />
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
});

export default TaskFields;

export function validateTask(task, getResource) {
    let errors = null;

    const setError = (field, message) => {
        if (!errors) {
            errors = {};
        }
        errors[field] = message;
    };

    if (!task.isDefaultTask && !task.summary) {
        setError(
            "summary",
            getResource(
                "Maintenance.workOrder.tasks.summaryRequired",
                "Please fill in the summary of this task"
            )
        );
    }

    return errors;
}
