import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    loaderWrapper: {
        visibility: "hidden",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        "&.--position-absolute": {
            position: "absolute",
        },

        "&.--visible": {
            visibility: "visible",
            /** Short delay to not show loader when data loads fast */
            animation: `0.1s $show-loader-wrapper linear forwards 0.2s`,

            "& .loader": {
                animation: `0s $show-loader linear forwards 0.2s`,
            },
        },
    },

    "@keyframes show-loader-wrapper": {
        "0%": {
            backgroundColor: "transparent",
        },
        "100%": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
    },

    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%)",
        visibility: "hidden",
    },

    "@keyframes show-loader": {
        "0%": {
            visibility: "hidden",
        },
        "100%": {
            visibility: "visible",
        },
    },
}));

export default useStyles;
