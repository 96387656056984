/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Button, Link } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import { get, resetPassword } from "./dataservice";
import useAPI from "../../../shared/useapi";
import useForm from "../../../shared/forms/useform";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import { config } from "config";

import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(3),
    },
    unknownToken: {
        marginTop: theme.spacing(2),
    },
    fields: {
        margin: theme.spacing(-1),
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
}));

export default withResources(({ getResource }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const formId = useRef("id_" + Math.random().toString(36).substr(2, 9));

    const token = new URLSearchParams(location.search).get("token");

    const { loading: dataLoading, data } = useAPI(() => get(token), true, [token]);

    const { loading, invoke } = useAPI(resetPassword);

    const onSubmit = async ({ values }) => {
        if (await invoke(token, values.password)) {
            history.replace("/login");
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = getResource(
                "Security.resetPassword.enterPassword",
                "Please enter a password"
            );
        }

        if (values.password && !values.password.match(config.passwordRegex)) {
            errors.password = getResource(
                "Security.resetPassword.enterValidPassword",
                "The password doesn't match the requirements"
            );
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = getResource(
                "Security.resetPassword.enterPasswordConfirmation",
                "Please enter a password confirmation"
            );
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = getResource(
                "Security.resetPassword.passwordConfirmationIsNotTheSame",
                "The password isn't the same as the password confirmation"
            );
        }

        return errors;
    };

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: {},
        onSubmit: onSubmit,
        formId: formId.current,
        validate: onValidate,
    });

    return (
        <React.Fragment>
            {!dataLoading && !data && (
                <Alert severity="error" className={classes.unknownToken}>
                    <ResourcedText
                        resourceKey="Security.resetPassword.unknownTokenText"
                        defaultValue="The reset password link is no longer valid; use the forgot password link on the login page to try again."
                    />
                    <Link color="primary" component={RouterLink} to="/login" underline="always">
                        <ResourcedText
                            resourceKey="Security.resetPassword.unknownTokenLink"
                            defaultValue="Back to login page"
                        />
                    </Link>
                </Alert>
            )}
            {data && (
                <div className={classes.form} id={formId.current}>
                    <div className={classes.fields}>
                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="password"
                            labelResourceKey="Security.resetPassword.passwordField"
                            labelDefaultValue="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            value={values.password}
                            onChange={(e) => handleChange(e)}
                        />

                        <Alert severity="info">
                            <ResourcedText
                                resourceKey="Security.resetPassword.passwordHelpText"
                                defaultValue="Your password needs to be at least 6 long and needs to have at least 1 capital, 1 digit, and 1 non-word character in it."
                            />
                        </Alert>

                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="confirmPassword"
                            labelResourceKey="Security.resetPassword.passwordConfirmField"
                            labelDefaultValue="Password confirmation"
                            type="password"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            value={values.confirmPassword}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <Button
                        className={classes.submitButton}
                        disabled={loading}
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        <ResourcedText
                            resourceKey="Security.resetPassword.resetPasswordButton"
                            defaultValue="Reset"
                        />
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
});
