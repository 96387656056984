import getItemInTree from "./getItemInTree";

const closeSiblings = (itemTree, itemId, item = null) => {
    const parentId = item ? item.parentId : getItemInTree(itemTree, itemId).parentId;
    const parentItem = getItemInTree(itemTree, parentId);

    parentItem.children.forEach((item) => {
        if (item.id === itemId) {
            return;
        }

        if (item.open) {
            delete item.open;
            delete item.children;
        }
    });
};

export default closeSiblings;
