import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Overview from "./overview";
import { get } from "./dataservice";
import { get as getAsset } from "../../assets/asset/dataservice";
import { get as getComponent } from "../../assets/component/dataservice";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import useAPI from "../../../shared/useapi";
import useIsFirstRun from "../../../shared/useIsFirstRun";
import useDialog from "../../../shared/usedialog";
import Edit from "../scheduledEvent/edit";
import { Fragment } from "react";
import PredictedEventActions from "../timeline/components/predictedEventActions";
import AddWorkOrder from "../../maintenance/workOrder/add";
import { getReasons, getStatuses } from "../../maintenance/workOrder/dataservice";
import {
    getAll as getAllUsageUnits
} from "features/system/usageunits/dataservice";

export default withResources((props) => {
    const getResource = props.getResource;
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    var assetId = props.match.params.referenceType == "assets" ? props.match.params.referenceId : null;
    var componentId = props.match.params.referenceType == "components" ? props.match.params.referenceId : null;
    var assetCollectionId = !assetId && !componentId ? assetCollectionScope.id : null;
    var companyId = !assetId && !componentId && !assetCollectionId ? companyScope.id : null;

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(null);

    const { data: asset } = useAPI(() => getAsset(assetId), assetId);
    const { data: component } = useAPI(() => getComponent(componentId), componentId);
    const firstRun = useIsFirstRun();

    const history = useHistory();
    const { data: reasons } = useAPI(() => getReasons(), true);
    const { data: statuses } = useAPI(() => getStatuses(), true);
    const { data: units } = useAPI(() => getAllUsageUnits(), true);

    const [items, setItems] = useState([]);

    const loadItems = async (reset) => {
        if (!assetCollectionId && !assetId && !companyId && !componentId) {
            return;
        }
        var response = await get(assetCollectionId, assetId, companyId, componentId, reset ? 1 : page, 30);
        var result = await response.json();

        setItems((items) => {
            return items.concat(result.items);
        });
        setTotal(state => {
            if (state > 0 && result.total == 0) { return state; }
            return result.total;
        });
        setPage(x => x + 1);
    }

    const reset = () => {
        setItems([]);
        setTotal(null);
        setPage(1);
        loadItems(true);
    }

    useEffect(() => {
        !firstRun && reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetCollectionScope.id, companyScope.id, assetId, componentId]);


    var header = null;
    if (companyId) {
        header = getResource("Planning.events.header.title.company", "Events for assets of {company}", { company: companyScope.name });
    }
    else if (assetCollectionId) {
        header = getResource("Planning.events.header.title.assetCollection", "Events for assets of {company} / {assetCollection}", { company: companyScope.name, assetCollection: assetCollectionScope.name });
    }
    else if (assetId && asset) {
        var assetName = ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);
        header = getResource("Planning.events.header.title.asset", "Events for {assetName} and its components", { assetName });
    }
    else if (componentId && component) {
        var componentName = component.name[selectedCulture] || component.name[defaultCulture];
        header = getResource("Planning.events.header.title.component", "Events for {componentName}", { componentName });
    }

    const [selectedPredictedEvent, setSelectedPredictedEvent] = useState(null);

    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(reset);

    const {
        open: handleAdd,
        close: handleCloseAdd,
        visible: addToggled,
        args: addArgs,
    } = useDialog();


    const onResolved = (assignedActionId) => {
        setItems(items => {
            var copyItems = [...items];
            var actionsItems = copyItems.filter(x => x.assignedActionId == assignedActionId);
            actionsItems.forEach(x => {
                x.priority = 4;
                x.status = "Resolved";
            });
            return copyItems;
        });

    };

    const onReopened = (assignedActionId) => {
        setItems(items => {
            var copyItems = [...items];
            var actionsItems = copyItems.filter(x => x.assignedActionId == assignedActionId);
            actionsItems.forEach(x => {
                x.priority = x.priorityWhenReopened;
                x.status = "Open";
            });
            return copyItems;
        });
    };


    return (
        <Fragment>
            <Overview
                showWorkingContext={!assetId && !componentId}
                loadMore={loadItems}
                reset={reset}
                total={total}
                shouldSelectCompany={!assetCollectionId && !assetId && !companyId && !componentId}
                items={items}
                header={header}
                onEdit={handleEdit}
                onAddWorkOrder={handleAdd}
                onResolved={onResolved}
                onReopened={onReopened}
                units={units}
                {...props}
            />

            <Edit open={editToggled} onClose={handleCloseEdit} id={editId} />

            {selectedPredictedEvent && (
                <PredictedEventActions
                    predictedEvent={selectedPredictedEvent}
                    resourceName={selectedPredictedEvent.componentName || selectedPredictedEvent.assetName}
                    companyId={selectedPredictedEvent.companyId}
                    onClose={(reload) => {
                        setSelectedPredictedEvent(null);
                        if (reload) {
                            reset();
                        }
                    }}
                    onOpenWorkOrder={(workOrderId) => {
                        history.push(`/maintenance/work-orders/${workOrderId}/tasks`);
                    }}
                    onCreateWorkOrder={() => {
                        let assetId = null;
                        let componentId = null;

                        if (selectedPredictedEvent.resourceType === "Assets") {
                            assetId = selectedPredictedEvent.resourceIdentifier;
                        } else if (
                            selectedPredictedEvent.resourceType === "Component"
                        ) {
                            componentId =
                                selectedPredictedEvent.resourceIdentifier;
                        }

                        handleAdd({
                            assetId,
                            componentId,
                            entityName: selectedPredictedEvent.resourceName,
                            assignedActionId:
                                selectedPredictedEvent.assignedActionId,
                        });
                    }}
                />
            )}

            <AddWorkOrder
                open={addToggled}
                onClose={(reload) => {
                    handleCloseAdd();
                    if (reload) {
                        reset();
                    }
                }}
                reasons={reasons}
                statuses={statuses}
                assetIdPreselected={addArgs && addArgs.assetId}
                componentIdPreselected={addArgs && addArgs.componentId}
                entityName={addArgs && addArgs.entityName}
                assignedActionId={addArgs && addArgs.assignedActionId}
            />

        </Fragment>
    );
});
