import React, { useContext } from "react";
import OriginalFullCalendar from "@fullcalendar/react";
import useStyles from "./useStyles";
import ConfigurationContext from "../configuration/configurationcontext";

const FullCalendar = ({ calendarRef, isTimeLineCalendar, ...props }) => {
    const classes = useStyles();
    const { fullCalendarLicenseKey } = useContext(ConfigurationContext);

    return (
        <div className={`${classes.root} ${isTimeLineCalendar ? classes.timeLineCalendar : ""}`}>
            <OriginalFullCalendar
                eventTimeFormat={{ hour: "2-digit", minute: "2-digit" }}
                navLinks={true}
                schedulerLicenseKey={fullCalendarLicenseKey}
                ref={calendarRef}
                {...props}
            />
        </div>
    );
};

export default FullCalendar;
