import React from "react";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Check as ActivateIcon,
    Block as DeactivateIcon,
    Edit as EditIcon,
    EnhancedEncryption as SecurityIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import _ from "underscore";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

const useOverviewStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(1),
    },
}));

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        getRequiredOperation,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onEditRoles,
        onAdd,
        onEdit,
        onActivate,
        onDeactivate,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const classes = useOverviewStyles();

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="users-view" hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource("Security.users.header.title", "Users")}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation={getRequiredOperation("users-create")}
                                    buttonText={getResource(
                                        "Security.users.newUserButton",
                                        "Create"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Security.users.noResults",
                            "No users available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.users.overview.firstNameHeader"
                                            defaultValue="Firstname"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.users.overview.surNameHeader"
                                            defaultValue="Surname"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.users.overview.emailHeader"
                                            defaultValue="E-mail"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Security.users.overview.companiesHeader"
                                            defaultValue="Companies"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Security.users.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => onEdit(row.id)}
                                            requiredOperation={getRequiredOperation("users-update")}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.firstName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.lastName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.companies.map(function (company) {
                                                    return (
                                                        <Chip
                                                            key={company}
                                                            className={classes.chip}
                                                            label={company}
                                                        />
                                                    );
                                                })}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Security.users.editUserButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "users-update"
                                                            ),
                                                            onClick: () => onEdit(row.id),
                                                        },
                                                        {
                                                            text: row.isActive
                                                                ? getResource(
                                                                      "Security.users.deActivateUserButton",
                                                                      "Deactivate"
                                                                  )
                                                                : getResource(
                                                                      "Security.users.activateUserButton",
                                                                      "Activate"
                                                                  ),
                                                            icon: row.isActive ? (
                                                                <DeactivateIcon />
                                                            ) : (
                                                                <ActivateIcon />
                                                            ),
                                                            itemId: row.id,
                                                            requiredOperation: getRequiredOperation(
                                                                "users-update"
                                                            ),
                                                            onClick: () =>
                                                                row.isActive
                                                                    ? onDeactivate({
                                                                          userId: row.id,
                                                                          userFirstName:
                                                                              row.firstName,
                                                                          userLastName:
                                                                              row.lastName,
                                                                          isSystemAdmin:
                                                                              row.isSystemAdmin,
                                                                      })
                                                                    : onActivate({
                                                                          userId: row.id,
                                                                          userFirstName:
                                                                              row.firstName,
                                                                          userLastName:
                                                                              row.lastName,
                                                                          isSystemAdmin:
                                                                              row.isSystemAdmin,
                                                                      }),
                                                        },
                                                        {
                                                            text: getResource(
                                                                "Security.users.roleAssignmentsButton",
                                                                "Role assignments"
                                                            ),
                                                            icon: <SecurityIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "user-roles-assign",
                                                            onClick: () => onEditRoles(row.id),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
