import React, { Fragment, useContext } from "react";
import {
    LinearProgress,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import useAPI from "../../../shared/useapi";
import { get } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { makeStyles } from "@material-ui/styles";
import { formatDate } from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    ownerHistoryDl: {
        marginTop: theme.spacing(1),

        "& > *:last-of-type": {
            marginBottom: 0,
        },
    },

    ownerHistory: {
        margin: `${theme.spacing(3)}px 0`,

        "& h4": {
            margin: `${theme.spacing(1)}px 0`,
        },
    },
}));

export default withResources(({ getResource, id, onUpdate }) => {
    const classes = useStyles();

    const { data } = useAPI(() => get(id), true, [id]);

    const { data: allCountries } = useAPI(() => getAllCountries(), true);
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    var isLoaded = data && allCountries;
    return (
        <Fragment>
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <Fragment>
                    {data && data.current && (
                        <dl className={classes.dl}>
                            <dt>
                                <ResourcedText
                                    resourceKey="Assets.asset.ownership.currentOwner.NameField"
                                    defaultValue="Name"
                                />
                            </dt>
                            <dd>{data.current.name}</dd>
                            <dt>
                                <ResourcedText
                                    resourceKey="Assets.asset.ownership.currentOwner.addressField"
                                    defaultValue="Address"
                                />
                            </dt>
                            <dd>
                                {data.current.address}
                                <br />
                                {data.current.postalCode} {data.current.city}
                                <br />
                                {data.current.state} -{" "}
                                {allCountries.find((x) => x.code === data.current.country).name}
                            </dd>
                            {data.current.chamberOfCommerce && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.currentOwner.chamberOfCommerceField"
                                            defaultValue="Chamber of commerce"
                                        />
                                    </dt>
                                    <dd>{data.current.chamberOfCommerce}</dd>
                                </Fragment>
                            )}
                            {data.current.vatNumber && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.currentOwner.vatNumberField"
                                            defaultValue="VAT Number"
                                        />
                                    </dt>
                                    <dd>{data.current.vatNumber}</dd>
                                </Fragment>
                            )}
                            {data.current.email && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.currentOwner.emailField"
                                            defaultValue="Email"
                                        />
                                    </dt>
                                    <dd>{data.current.email}</dd>
                                </Fragment>
                            )}
                            {data.current.phoneNumber1 && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.currentOwner.phoneNumber1Field"
                                            defaultValue="Phone number 1"
                                        />
                                    </dt>
                                    <dd>{data.current.phoneNumber1}</dd>
                                </Fragment>
                            )}
                            {data.current.phoneNumber2 && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.ownership.currentOwner.phoneNumber2Field"
                                            defaultValue="Phone number 2"
                                        />
                                    </dt>
                                    <dd>{data.current.phoneNumber2}</dd>
                                </Fragment>
                            )}
                        </dl>
                    )}
                    {data && !data.current && (
                        <p>
                            <ResourcedText
                                resourceKey="Assets.asset.ownership.noOwnerHistory"
                                defaultValue="The owner history of this asset is unknown."
                            />
                        </p>
                    )}

                    <AuthorizedButton
                        onClick={onUpdate}
                        color="primary"
                        variant="contained"
                        requiredOperation="assets-update-ownership"
                        buttonText={getResource("Assets.asset.ownership.okButton", "Change owner")}
                    />

                    {data && data.history && data.history.length > 1 && (
                        <div className={classes.ownerHistory}>
                            <Typography variant="h4">
                                <ResourcedText
                                    resourceKey="Assets.asset.ownership.previousOwners.header"
                                    defaultValue="Previous owner(s)"
                                />
                            </Typography>
                            {data.history.map((owner, index) => {
                                var date = index === 0 ? null : data.history[index - 1].createdOn;

                                return owner.isCurrent ? null : (
                                    <Accordion key={index} defaultExpanded={index === 0}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`${owner.companyName}-content`}
                                            id={`${owner.companyName}-header`}
                                        >
                                            <strong>{owner.companyName}</strong> &nbsp;
                                            {getResource(
                                                "Assets.asset.ownership.ownerHistory.until",
                                                "until {date}",
                                                {
                                                    date: formatDate(
                                                        date,
                                                        selectedUiCulture,
                                                        selectedTimezone
                                                    ),
                                                }
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <dl className={classes.ownerHistoryDl}>
                                                <dt>
                                                    <ResourcedText
                                                        resourceKey="Assets.asset.ownership.previousOwners.addressField"
                                                        defaultValue="Address"
                                                    />
                                                </dt>
                                                <dd>
                                                    {owner.address}
                                                    <br />
                                                    {owner.postalCode} {owner.city}
                                                    <br />
                                                    {owner.state} -{" "}
                                                    {
                                                        allCountries.find(
                                                            (x) => x.code === owner.country
                                                        ).name
                                                    }
                                                </dd>
                                                {owner.chamberOfCommerce && (
                                                    <Fragment>
                                                        <dt>
                                                            <ResourcedText
                                                                resourceKey="Assets.asset.ownership.previousOwners.chamberOfCommerceField"
                                                                defaultValue="Chamber of commerce"
                                                            />
                                                        </dt>
                                                        <dd>{owner.chamberOfCommerce}</dd>
                                                    </Fragment>
                                                )}
                                                {owner.vatNumber && (
                                                    <Fragment>
                                                        <dt>
                                                            <ResourcedText
                                                                resourceKey="Assets.asset.ownership.previousOwners.vatNumberField"
                                                                defaultValue="VAT Number"
                                                            />
                                                        </dt>
                                                        <dd>{owner.vatNumber}</dd>
                                                    </Fragment>
                                                )}
                                                {owner.email && (
                                                    <Fragment>
                                                        <dt>
                                                            <ResourcedText
                                                                resourceKey="Assets.asset.ownership.previousOwners.emailField"
                                                                defaultValue="Email"
                                                            />
                                                        </dt>
                                                        <dd>{owner.email}</dd>
                                                    </Fragment>
                                                )}
                                                {owner.phoneNumber1 && (
                                                    <Fragment>
                                                        <dt>
                                                            <ResourcedText
                                                                resourceKey="Assets.asset.ownership.previousOwners.phoneNumber1Field"
                                                                defaultValue="Phone number 1"
                                                            />
                                                        </dt>
                                                        <dd>{owner.phoneNumber1}</dd>
                                                    </Fragment>
                                                )}
                                                {owner.phoneNumber2 && (
                                                    <Fragment>
                                                        <dt>
                                                            <ResourcedText
                                                                resourceKey="Assets.asset.ownership.previousOwners.phoneNumber2Field"
                                                                defaultValue="Phone number 2"
                                                            />
                                                        </dt>
                                                        <dd>{owner.phoneNumber2}</dd>
                                                    </Fragment>
                                                )}
                                            </dl>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
});
