import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(searchText, assetModelId, manufacturerId, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        assetModelId,
        manufacturerId,
    };
    const url = `/api/valuation/questionnaire/byAsset?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(assetId) {
    const url = `/api/assets/asset/${assetId}/questionnaire`;

    return await interceptedFetch(url);
}

async function getAssetModelValues(assetModelId) {
    const url = `/api/valuation/questionnaire/asset-model=${assetModelId}/questions`;

    return await interceptedFetch(url);
}

async function update(assetId, values) {
    const url = `/api/assets/asset/${assetId}/questionnaire`;

    return await interceptedFetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(assetId) {
    return await interceptedFetch(`/api/assets/asset/${assetId}/questionnaire`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, update, getAssetModelValues, del };
