import React, { useContext, useEffect, useState } from "react";
import {
    DialogTitle,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    DialogContentText,
} from "@material-ui/core";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import SelectLanguageDialog from "../../../shared/selectLanguageDialog";
import { reportAvailable } from "./dataservice";

function DownloadReportDialog({ languages, quickScanId, onSelectLanguage, onClose }) {
    const [isReportAvailable, setIsReportAvailable] = useState(null);

    useEffect(() => {
        async function checkIsReportAvailable() {
            var response = await reportAvailable(quickScanId);

            setIsReportAvailable(response.status === 200);
        }

        checkIsReportAvailable();
    });

    if (isReportAvailable === null) {
        return null;
    }

    if (!isReportAvailable) {
        return <ReportNotYetAvailableDialog onClose={onClose} />;
    }

    return (
        <SelectLanguageDialog
            open={true}
            languages={languages}
            onSelectLanguage={onSelectLanguage}
            onClose={onClose}
        />
    );
}

function ReportNotYetAvailableDialog({ onClose }) {
    return (
        <Dialog onClose={onClose} aria-labelledby="download-report-dialog-title" open={true}>
            <DialogTitle id="download-report-dialog-title">
                <ResourcedText
                    resourceKey="Valuation.quickScanReport.notYetAvailableTitle"
                    defaultValue="QuickScan report not yet available"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <ResourcedText
                        resourceKey="Valuation.quickScanReport.notYetAvailableText"
                        defaultValue="The QuickScan report is still being generated and will by ready any time soon. Please try again later."
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    <ResourcedText
                        resourceKey="Valuation.quickScanReport.notYetAvailable.closeButton"
                        defaultValue="Close"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function openReport(quickScanId, language) {
    window.open(
        `/api/valuation/quickscan/${quickScanId}/report?culture=${language.code}`,
        "_blank"
    );
}

export default ({ quickScanId, open, onClose }) => {
    if (!open) {
        return null;
    }

    const config = useContext(ConfigurationContext);

    return (
        <DownloadReportDialog
            languages={config.supportedQuickScanLanguages}
            onSelectLanguage={(language) => openReport(quickScanId, language, onClose)}
            quickScanId={quickScanId}
            onClose={onClose}
        />
    );
};
