import React, { useEffect, useRef, Fragment } from "react";
import useAPI from "../../../../shared/useapi";
import { getAll as getAllCountries } from "../../../library/countries/dataservice";
import { getAll as getCompanies, get as getCompany } from "../../../security/companies/dataservice";

import withResources from "../../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";

export default withResources(({ getResource, values, handleChange, errors, isDefaultCulture }) => {
    const { data: allCountries } = useAPI(getAllCountries, true);
    const { data: companies } = useAPI(() => getCompanies(null, null, true, null, null), true);
    const prevCustomerIdRef = useRef();

    useEffect(() => {
        async function copyContactInformation() {
            const response = await getCompany(values.companyId);
            const company = await response.json();

            handleChange([
                { target: { name: "name", value: company.name } },
                { target: { name: "country", value: company.country } },
                { target: { name: "address", value: company.address } },
                { target: { name: "postalCode", value: company.postalCode } },
                { target: { name: "city", value: company.city } },
                { target: { name: "state", value: company.state } },
                { target: { name: "email", value: company.email } },
                { target: { name: "phoneNumber", value: company.phoneNumber1 } },
                { target: { name: "whatsAppNumber", value: company.whatsAppNumber } },
            ]);
        }

        if (prevCustomerIdRef.current !== values.companyId) {
            copyContactInformation();
            prevCustomerIdRef.current = values.companyId;
        }
    }, [values.companyId, handleChange]);

    return (
        <Fragment>
            <AutoCompleteStatic
                errors={errors}
                name="companyId"
                label={getResource(
                    "Marketplace.advertisement.contactInformation.copyContactField",
                    "Copy contact information from"
                )}
                fullWidth
                isMedium
                onSelection={handleChange}
                options={companies && companies.items}
                disabled={!isDefaultCulture}
                disableClearable
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="name"
                labelResourceKey="Marketplace.advertisement.contactInformation.nameField"
                labelDefaultValue="Name"
                type="text"
                fullWidth
                value={values.name}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 75 }}
                onChange={handleChange}
                disabled={!isDefaultCulture}
                required
            />

            <AutoCompleteStatic
                errors={errors}
                name="country"
                label={getResource(
                    "Marketplace.advertisement.contactInformation.countryField",
                    "Country"
                )}
                fullWidth
                isMedium
                value={values.country}
                onSelection={handleChange}
                options={allCountries && allCountries.map((c) => ({ id: c.code, name: c.name }))}
                disabled={!isDefaultCulture}
                required
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="address"
                labelResourceKey="Marketplace.advertisement.contactInformation.addressField"
                labelDefaultValue="Address"
                type="text"
                fullWidth
                value={values.address || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
                onChange={handleChange}
                disabled={!isDefaultCulture}
                required
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="postalCode"
                labelResourceKey="Marketplace.advertisement.contactInformation.postalCodeField"
                labelDefaultValue="Postal code"
                type="text"
                fullWidth
                value={values.postalCode || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                onChange={handleChange}
                disabled={!isDefaultCulture}
                required
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="city"
                labelResourceKey="Marketplace.advertisement.contactInformation.cityField"
                labelDefaultValue="City"
                type="text"
                fullWidth
                value={values.city || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
                onChange={handleChange}
                disabled={!isDefaultCulture}
                required
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="state"
                labelResourceKey="Marketplace.advertisement.contactInformation.stateField"
                labelDefaultValue="State"
                type="text"
                fullWidth
                value={values.state || ""}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
                onChange={handleChange}
                disabled={!isDefaultCulture}
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="email"
                labelResourceKey="Marketplace.advertisement.contactInformation.emailField"
                labelDefaultValue="Email"
                type="text"
                fullWidth
                value={values.email}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleChange(e)}
                disabled={!isDefaultCulture}
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="phoneNumber"
                labelResourceKey="Marketplace.advertisement.contactInformation.phoneNumberField"
                labelDefaultValue="Phone number"
                type="tel"
                fullWidth
                value={values.phoneNumber}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    maxLength: 20,
                    pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                }}
                onChange={(e) => {
                    if (!e.target.validity.valid) {
                        e.target.value = values.phoneNumber || "";
                    }
                    handleChange(e);
                }}
                disabled={!isDefaultCulture}
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="whatsAppNumber"
                labelResourceKey="Marketplace.advertisement.contactInformation.whatsAppNumberField"
                labelDefaultValue="WhatsApp number"
                helperText={getResource(
                    "Marketplace.advertisement.contactInformation.whatsAppNumberHelperText",
                    "in international format like 316123456789"
                )}
                type="text"
                fullWidth
                value={values.whatsAppNumber}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    maxLength: 20,
                    pattern: "^[0-9.\\-()]{0,20}$",
                }}
                onChange={(e) => {
                    if (!e.target.validity.valid) {
                        e.target.value = values.whatsAppNumber || "";
                    }
                    handleChange(e);
                }}
                disabled={!isDefaultCulture}
            />

            <ResourcedTextField
                errors={errors}
                margin="normal"
                variant="outlined"
                name="website"
                labelResourceKey="Marketplace.advertisement.contactInformation.websiteField"
                labelDefaultValue="Website"
                type="text"
                fullWidth
                value={values.website}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 2048 }}
                onChange={(e) => handleChange(e)}
                disabled={!isDefaultCulture}
            />
        </Fragment>
    );
});

export function validate(values, getResource, errors) {
    if (!values.name) {
        errors.name = getResource(
            "Marketplace.advertisement.add.nameRequired",
            "Please enter a name"
        );
    }

    if (!values.country) {
        errors.country = getResource(
            "Marketplace.advertisement.add.countryIdRequired",
            "Please select a country"
        );
    }

    if (!values.address) {
        errors.address = getResource(
            "Marketplace.advertisement.add.addressRequired",
            "Please enter a address"
        );
    }

    if (!values.postalCode) {
        errors.postalCode = getResource(
            "Marketplace.advertisement.add.postalCodeRequired",
            "Please enter a postal code"
        );
    }

    if (!values.city) {
        errors.city = getResource(
            "Marketplace.advertisement.add.cityRequired",
            "Please enter a city"
        );
    }

    if (values.website && !values.website.match("^(http:\\/\\/|https:\\/\\/){1}.*$")) {
        errors.website = getResource(
            "Marketplace.advertisement.add.websiteInvalid",
            "Provided URL is invalid, it should start with http:// or https://"
        );
    }

    return errors;
}
