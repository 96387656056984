import React, { useContext, useState, useEffect } from "react";
import { Route, Switch } from "react-router";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useLocalStorage from "../../../shared/uselocalstorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";

import { getAll } from "./dataservice";

import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import invalidatedBy from "../../../shared/invalidation/invalidatedby";

import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import TotalValue from "./totalvalue";
import FinancialDetails from "../assetFinancialDetail/edit";
import Ownership from "../assetOwnership";
import Details from "../assetDetail";
import AssetQuickScanBatch from "../../valuation/assetquickscan/batch";
import AssignAssetCollection from "./assignAssetCollection";
import MoveAssetCollection from "./moveAssetCollection";
import MoveAllAssetCollection from "./moveAllAssetCollection";
import RemoveAssetCollection from "./removeAssetCollection";
import MoveCompany from "./moveCompany";
import Movements from "./movements";
import Export from "./export";
import Seal from "./seal";
import Unseal from "./unseal";
import Import from "./import";

import useDialog from "../../../shared/usedialog";

const List = invalidatedBy("asset-list", (_) => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { authorizationService } = useContext(AuthenticationContext);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [showFinancialsRaw, setShowFinancials] = useLocalStorage("show-financials", false);
    const [showThumbnails, setShowThumbnails] = useLocalStorage("show-thumbnails", false);

    const showFinancialsOperation = {
        name: "assets-view-financial",
        companyId: companyScope.id,
        assetCollectionId: assetCollectionScope.id,
    };

    const showFinancials = showFinancialsRaw && authorizationService.isAuthorized(showFinancialsOperation);

    const [searchParams, setSearchParams] = useSessionStorage("search-asset", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        assetModelId: null,
        manufacturerId: null,
        assetCategory: null,
        financierCompanyIds: [],
        order: "Default"
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.assetModelId,
                searchParams.manufacturerId,
                searchParams.assetCategory ? searchParams.assetCategory.id : null,
                searchParams.financierCompanyIds,
                null,
                showFinancials,
                searchParams.order,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.assetModelId,
            searchParams.manufacturerId,
            searchParams.assetCategory,
            searchParams.financierCompanyIds,
            searchParams.page,
            searchParams.pageSize,
            searchParams.order,
            companyScope.id,
            assetCollectionScope.id,
            showFinancials
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleSeal,
        close: handleCloseSeal,
        visible: sealToggled,
        args: sealId,
    } = useDialog(invoke);
    const {
        open: handleUnseal,
        close: handleCloseUnseal,
        visible: unsealToggled,
        args: unsealId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);
    const {
        open: handleTotalValue,
        close: handleCloseTotalValue,
        visible: totalValueToggled,
    } = useDialog(invoke);
    const {
        open: handleFinancialDetails,
        close: handleCloseFinancialDetails,
        visible: financialDetailsToggled,
        args: financialDetailsId,
    } = useDialog(invoke);
    const {
        open: handleOwnership,
        close: handleCloseOwnership,
        visible: ownershipToggled,
        args: ownershipId,
    } = useDialog(invoke);
    const { open: handleBatch, close: handleCloseBatch, visible: batchToggled } = useDialog();
    const {
        open: handleAssignAssetCollection,
        close: handleCloseAssignAssetCollection,
        visible: assignAssetCollectionToggled,
    } = useDialog(invoke);
    const {
        open: handleMoveAssetCollection,
        close: handleCloseMoveAssetCollection,
        visible: moveAssetCollectionToggled,
    } = useDialog(invoke);
    const {
        open: handleMoveAllAssetCollection,
        close: handleCloseMoveAllAssetCollection,
        visible: moveAllAssetCollectionToggled,
    } = useDialog(invoke);
    const {
        open: handleRemoveAssetCollection,
        close: handleCloseRemoveAssetCollection,
        visible: removeAssetCollectionToggled,
    } = useDialog(invoke);
    const {
        open: handleMoveCompany,
        close: handleCloseMoveCompany,
        visible: moveCompanyToggled,
        args: moveCompanyId,
    } = useDialog(invoke);
    const {
        open: handleMovements,
        close: handleCloseMovements,
        visible: movementsToggled,
        args: movementsId,
    } = useDialog();
    const { open: handleExport, close: handleCloseExport, visible: exportToggled } = useDialog();
    const { open: handleImport, close: handleCloseImport, visible: importToggled } = useDialog(invoke);

    const handleSearch = (searchText, assetModelId, manufacturerId, assetCategory, financierCompanyIds, order) => {
        setSearchParams({ ...searchParams, searchText, assetModelId, manufacturerId, assetCategory, financierCompanyIds, order, page: 0 });
        setSelectedAssets([]);
    };

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const handleSelectAsset = (e) => {
        var assetId = Number(e.target.name);
        var selected = e.target.checked;

        var newSelections = [...selectedAssets];
        newSelections = handleAssetSelect(newSelections, assetId, selected);
        setSelectedAssets(newSelections);
    };

    const handleAssetSelect = (selections, assetId, itemSelected) => {
        if (!itemSelected) {
            selections = selections.filter((id) => id !== assetId);
        } else if (selections.filter((id) => id === assetId).length === 0) {
            selections.push(assetId);
        }
        return selections;
    };

    const handleSelectAllAssets = () => {
        if (!data || !data.items) {
            return;
        }
        var newSelections = [...selectedAssets];
        for (var assetIndex in data.items) {
            newSelections = handleAssetSelect(newSelections, data.items[assetIndex].id, true);
        }
        setSelectedAssets(newSelections);
    };

    const handleDeselectAllAssets = () => {
        var newSelections = [...selectedAssets];
        for (var assetIndex in data.items) {
            newSelections = handleAssetSelect(newSelections, data.items[assetIndex].id, false);
        }
        setSelectedAssets(newSelections);
    };

    useEffect(() => setSelectedAssets([]), [companyScope.id, assetCollectionScope.id]);
    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <PrivateRoute
            requiredOperation={"assets-view"}
            path="/assets/assets"
            exact
            render={(props) => (
                <React.Fragment>
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        selectedAssets={selectedAssets}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onSeal={handleSeal}
                        onUnseal={handleUnseal}
                        onDelete={handleDelete}
                        onTotalValue={handleTotalValue}
                        onFinancialDetails={handleFinancialDetails}
                        onOwnership={handleOwnership}
                        onPurchaseAssetQuickScanBatch={handleBatch}
                        onSelectAsset={handleSelectAsset}
                        onSelectAllAssets={handleSelectAllAssets}
                        onDeselectAllAssets={handleDeselectAllAssets}
                        onAssignAssetCollection={handleAssignAssetCollection}
                        onMoveAssetCollection={handleMoveAssetCollection}
                        onMoveAllAssetCollection={handleMoveAllAssetCollection}
                        onRemoveAssetCollection={handleRemoveAssetCollection}
                        onMoveCompany={handleMoveCompany}
                        onMovements={handleMovements}
                        onExport={handleExport}
                        onImport={handleImport}
                        defaultDebounceInterval={defaultDebounceInterval}
                        showFinancials={showFinancials}
                        setShowFinancials={setShowFinancials}
                        showThumbnails={showThumbnails}
                        setShowThumbnails={setShowThumbnails}
                        {...props}
                    />

                    <Add open={addToggled} onClose={handleCloseAdd} />
                    <Edit open={editToggled} id={editId} onClose={handleCloseEdit} />
                    <Seal open={sealToggled} id={sealId} onClose={handleCloseSeal} />
                    <Unseal open={unsealToggled} id={unsealId} onClose={handleCloseUnseal} />
                    <Delete open={deleteToggled} id={deleteId} onClose={handleCloseDelete} />
                    <TotalValue
                        open={totalValueToggled}
                        searchParams={searchParams}
                        onClose={handleCloseTotalValue}
                    />
                    <FinancialDetails
                        open={financialDetailsToggled}
                        id={financialDetailsId}
                        onClose={handleCloseFinancialDetails}
                    />
                    <Ownership
                        open={ownershipToggled}
                        id={ownershipId}
                        onClose={handleCloseOwnership}
                    />
                    <AssetQuickScanBatch
                        open={batchToggled}
                        companyId={companyScope.id}
                        assetCollectionId={assetCollectionScope.id}
                        onClose={handleCloseBatch}
                    />
                    <AssignAssetCollection
                        open={assignAssetCollectionToggled}
                        onClose={handleCloseAssignAssetCollection}
                        selectedAssets={selectedAssets}
                    />
                    <MoveAssetCollection
                        open={moveAssetCollectionToggled}
                        onClose={handleCloseMoveAssetCollection}
                        selectedAssets={selectedAssets}
                    />
                    <MoveAllAssetCollection
                        open={moveAllAssetCollectionToggled}
                        onClose={handleCloseMoveAllAssetCollection}
                    />
                    <RemoveAssetCollection
                        open={removeAssetCollectionToggled}
                        onClose={handleCloseRemoveAssetCollection}
                        selectedAssets={selectedAssets}
                    />
                    <MoveCompany
                        open={moveCompanyToggled}
                        id={moveCompanyId}
                        onClose={handleCloseMoveCompany}
                    />
                    <Movements
                        open={movementsToggled}
                        id={movementsId}
                        onClose={handleCloseMovements}
                    />
                    <Export open={exportToggled} onClose={handleCloseExport} />
                    <Import open={importToggled} onClose={handleCloseImport} />
                </React.Fragment>
            )}
        />
    );
});

export default () => {
    return (
        <Switch>
            <Route path="/assets/assets/:id(\d+)" render={(props) => <Details {...props} />} />
            <Route path="/assets/assets" render={(props) => <List {...props} />} />
        </Switch>
    );
};
