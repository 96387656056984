import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import TableWidget from "../../../../shared/tableWidget";
import FormattedCurrency from "../../../../shared/formattedCurrency";

export default withResources(({ getResource, loading, values, currencyShortName }) => {
    if (!values) {
        return null;
    }

    const tableHeaders = [
        getResource("Valuation.financialReports.report.netOutstandingTable.header.year", "Year"),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.remainingLoan",
            "Net outstanding"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.liquidationValue",
            "Liquidation value"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.liquidationCost",
            "Liquidation cost"
        ),
        getResource(
            "Valuation.financialReports.report.netOutstandingTable.header.coverage",
            "Coverage"
        ),
    ];

    const tableData =
        values &&
        values.map((document) => ({
            year: document.year,
            remainingLoan: (
                <FormattedCurrency
                    value={document.remainingLoan}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            liquidationValue: (
                <FormattedCurrency
                    value={document.liquidationValue}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            liquidationCost: (
                <FormattedCurrency
                    value={document.liquidationCost}
                    currency={currencyShortName}
                    decimals={0}
                />
            ),
            coverage: document.coverage,
        }));

    return (
        <TableWidget
            headerTitle={getResource(
                "Valuation.financialReports.report.netOutstandingTable.title",
                "Net outstanding"
            )}
            headers={tableHeaders}
            data={tableData}
            loading={loading}
            hasContent={tableData !== null && tableData.length > 0}
            caption={getResource(
                "Valuation.financialReports.report.netOutstandingTable.caption",
                "The above table starts on the start date of the loan"
            )}
        />
    );
});
