import React, { Fragment, useRef } from "react";
import { FormHelperText, IconButton, Tooltip, Typography, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import AddButton from "shared/addButton";
import FormTable from "shared/formTable";
import ResourcedTextField from "shared/textresources/resourcedtextfield";
import WorkhoursTextField from "shared/workhoursTextField";
import withResources from "shared/textresources/withresources";
import FormattedCurrency from "shared/formattedCurrency";
import useStyles from "./useStyles";
import MoneyInput from "shared/moneyInput";
import { getLaborTotal, getMaterialTotal } from "../../workOrderTask/helpers";

const JobSheetRows = withResources((props) => {
    const {
        getResource,
        variant,
        title,
        rowsData,
        emptyRow,
        currency,
        handleChange,
        errors,
        disabled,
        descriptionPlaceHolder,
        getHourlyRatePlaceholder,
    } = props;
    const classes = useStyles();
    const addButtonRef = useRef(null);
    const fieldToFocusOnAfterAddingRef = useRef(null);
    const errorObject = errors && errors[variant];
    const errorText = errorObject && errorObject.message;

    while (rowsData.length < 4) {
        rowsData.push({ ...emptyRow });
    }

    function handleDurationChange(newValue, index) {
        const hours =
        {
            target: {
                name: 'hours',
                value: newValue.value
            }
        };
        const hoursAsText =
        {
            target: {
                name: 'hoursAsText',
                value: newValue.valueText
            }
        };

        onJobSheetRowsChange(hours, index);
        onJobSheetRowsChange(hoursAsText, index);
    }

    const onJobSheetRowsChange = (e, index) => {
        const copy = [...rowsData];
        const name = e.target.name;
        const value = e.target.value;

        copy[index][name] = value;

        handleChange({
            target: {
                name: variant,
                value: copy,
            },
        });
    };

    const getTotal = () => {
        let total = 0;
        rowsData.forEach((row) => {
            total +=
                variant === "labor"
                    ? getLaborTotal(row.hours, row.hourlyRate, row.taskHourlyRate)
                    : getMaterialTotal(row.quantity, row.pricePerUnit);
        });
        return total;
    };

    const getHourlyRateValue = (row) => {
        const isCompleted = disabled;
        const isFilledIn = row.description;

        if (isCompleted && isFilledIn) {
            return typeof row.hourlyRate === "number" ? row.hourlyRate : row.taskHourlyRate;
        } else {
            return row.hourlyRate;
        }
    };

    return (
        <Paper className={classes.paper}>
            <Typography variant="h4">{title}</Typography>

            <Fragment>
                <div className={classes.tableWrapper}>
                    <FormTable id={variant}>
                        {rowsData &&
                            rowsData.map((row, index) => {
                                const rowHasError =
                                    errorObject && errorObject.invalidRows.includes(index);
                                const rowTotal = variant === "labor"
                                    ? getLaborTotal(
                                        row.hours,
                                        row.hourlyRate,
                                        row.taskHourlyRate
                                    )
                                    : getMaterialTotal(
                                        row.quantity,
                                        row.pricePerUnit
                                    )
                                return (
                                    <Fragment key={index}>
                                        <tr
                                            className={clsx(
                                                classes.row,
                                                rowHasError && "--has-error"
                                            )}
                                        >
                                            <td>
                                                <ResourcedTextField
                                                    margin="dense"
                                                    name="description"
                                                    labelResourceKey="Maintenance.workOrder.jobSheetRow.description"
                                                    labelDefaultValue="Description"
                                                    type="text"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ maxLength: 255 }}
                                                    fullWidth
                                                    value={row.description}
                                                    onChange={(e) => onJobSheetRowsChange(e, index)}
                                                    placeholder={descriptionPlaceHolder}
                                                    inputRef={
                                                        index === rowsData.length - 1
                                                            ? fieldToFocusOnAfterAddingRef
                                                            : null
                                                    }
                                                    disabled={disabled}
                                                />
                                            </td>

                                            {variant === "labor" && (
                                                <React.Fragment>
                                                    <td>
                                                        <WorkhoursTextField
                                                            margin="dense"
                                                            name="hours"
                                                            errors={errors && errors[index]}
                                                            labelResourceKey="Maintenance.workOrder.jobSheetRow.hoursWorked"
                                                            labelDefaultValue="Hours worked"
                                                            variant="outlined"
                                                            InputLabelProps={{ shrink: true }}
                                                            inputProps={{ maxLength: 255 }}
                                                            fullWidth
                                                            value={{
                                                                value: row.hours,
                                                                valueText: row.hoursAsText,
                                                            }}
                                                            onChange={(newValue) => handleDurationChange(newValue, index)}
                                                            disabled={disabled}
                                                            required={false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <MoneyInput
                                                            name="hourlyRate"
                                                            size="small"
                                                            labelResourceKey="Maintenance.workOrder.jobSheetRow.hourlyRate"
                                                            labelDefaultValue="Hourly rate"
                                                            margin="dense"
                                                            value={getHourlyRateValue(row)}
                                                            fullWidth
                                                            currencyCode={currency}
                                                            useDecimals
                                                            onChange={(e) =>
                                                                onJobSheetRowsChange(e, index)
                                                            }
                                                            placeholder={
                                                                getHourlyRatePlaceholder &&
                                                                getHourlyRatePlaceholder(row)
                                                            }
                                                            disabled={
                                                                disabled || !row.allowRateOverride
                                                            }
                                                        />
                                                    </td>
                                                </React.Fragment>
                                            )}

                                            {variant === "materials" && (
                                                <React.Fragment>
                                                    <td>
                                                        <ResourcedTextField
                                                            margin="dense"
                                                            size="small"
                                                            name="quantity"
                                                            labelResourceKey="Maintenance.workOrder.jobSheetRow.quantity"
                                                            labelDefaultValue="Quantity"
                                                            type="number"
                                                            variant="outlined"
                                                            InputLabelProps={{ shrink: true }}
                                                            inputProps={{ maxLength: 255 }}
                                                            fullWidth
                                                            value={row.quantity}
                                                            onChange={(e) =>
                                                                onJobSheetRowsChange(e, index)
                                                            }
                                                            disabled={disabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        <MoneyInput
                                                            name="pricePerUnit"
                                                            size="small"
                                                            labelResourceKey="Maintenance.workOrder.jobSheetRow.pricePerUnit"
                                                            labelDefaultValue="Unit price"
                                                            margin="dense"
                                                            value={row.pricePerUnit}
                                                            fullWidth
                                                            currencyCode={currency}
                                                            useDecimals
                                                            onChange={(e) =>
                                                                onJobSheetRowsChange(e, index)
                                                            }
                                                            disabled={disabled}
                                                        />
                                                    </td>
                                                </React.Fragment>
                                            )}

                                            <td>
                                                <div className={classes.amount}>
                                                    <div className={classes.amountLabel}>
                                                        Amount:
                                                    </div>
                                                    <div className={classes.amountTotal}>
                                                        {rowTotal == null ? "-" : <FormattedCurrency
                                                            currency={currency}
                                                            decimals={2}
                                                            value={rowTotal}
                                                            disabled={disabled}
                                                        />}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <Tooltip
                                                    title={
                                                        disabled
                                                            ? ""
                                                            : getResource(
                                                                "Maintenance.workOrder.jobSheetRow.clearRow",
                                                                "Clear row"
                                                            )
                                                    }
                                                    placement="top"
                                                    arrow
                                                >
                                                    <div>
                                                        <IconButton
                                                            aria-label="clear"
                                                            onClick={() => {
                                                                const newRows = [...rowsData];
                                                                newRows[index] = { ...emptyRow };
                                                                handleChange({
                                                                    target: {
                                                                        name: variant,
                                                                        value: newRows,
                                                                    },
                                                                });
                                                            }}
                                                            disabled={disabled}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        {rowHasError && (
                                            <tr className={classes.errorRow}>
                                                <td colSpan="5">
                                                    <FormHelperText
                                                        className={classes.error}
                                                        variant="outlined"
                                                        error
                                                    >
                                                        {errorText}
                                                    </FormHelperText>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                );
                            })}
                    </FormTable>
                </div>
                <AddButton
                    buttonRef={addButtonRef}
                    buttonText={getResource("Maintenance.workOrder.jobSheetRow.add", "Add row")}
                    handleClick={() => {
                        const newRows = [...rowsData];
                        newRows.push({ ...emptyRow });
                        handleChange({
                            target: {
                                name: variant,
                                value: newRows,
                            },
                        });
                        setTimeout(() => fieldToFocusOnAfterAddingRef.current.focus(), 100);
                    }}
                    disabled={disabled}
                />
                <div className={classes.totalCount}>
                    <span>{getResource("Maintenance.workOrder.jobSheetRow.total", "Total:")}</span>
                    <FormattedCurrency value={getTotal()} currency={currency} decimals={2} />
                </div>
            </Fragment>
        </Paper>
    );
});

export default JobSheetRows;
