import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(searchText, isActive, includeParentCompanies, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
        isActive,
        includeParentCompanies,
    };

    const url = `/api/security/user?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getByCompany(companyId) {
    const params = {
        companyId,
    };

    const url = `/api/security/user/byCompany?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(id) {
    const url = `/api/security/user/${id}`;

    return await interceptedFetch(url);
}

async function exists(email) {
    const url = `/api/security/user/exists?email=${email}`;

    return await interceptedFetch(url);
}

async function getRoles(id) {
    const url = `/api/security/user/${id}/roles`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/security/user", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/security/user/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function updateRoles(id, values) {
    return await interceptedFetch(`/api/security/user/${id}/roles`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function deactivate(id) {
    const url = `/api/security/user/${id}/deactivate`;

    return await interceptedFetch(url, {
        method: "POST",
    });
}

async function activate(id) {
    const url = `/api/security/user/${id}/activate`;

    return await interceptedFetch(url, {
        method: "POST",
    });
}

export { getAll, getByCompany, get, getRoles, add, update, updateRoles, deactivate, activate, exists };
