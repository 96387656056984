import interceptedFetch from "../../../shared/interceptedFetch";
import queryString from "query-string";

async function getAll(searchText, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
    };
    const url = `/api/valuation/questionnaire/byassetmodel?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function get(assetModelId) {
    const url = `/api/library/assetmodel/${assetModelId}/questionnaire`;

    return await interceptedFetch(url);
}

async function getCategoryValues(assetCategoryId) {
    const url = `/api/valuation/questionnaire/asset-category=${assetCategoryId}`;

    return await interceptedFetch(url);
}

async function update(assetModelId, values) {
    const url = `/api/library/assetmodel/${assetModelId}/questionnaire`;

    return await interceptedFetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function del(assetModelId) {
    return await interceptedFetch(`/api/library/assetmodel/${assetModelId}/questionnaire`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getAll, get, update, getCategoryValues, del };
