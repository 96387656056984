import palette from "../palette";
import typography from "../typography";

export default {
    root: {
        ...typography.body1,
        borderBottom: `1px solid ${palette.divider}`,
    },

    head: {
        fontWeight: "inherit",
        lineHeight: "inherit",
        fontSize: typography.body2.fontSize,
        verticalAlign: "bottom",
    },

    sizeSmall: {
        paddingRight: 0,
        paddingLeft: 16,
    },
};
