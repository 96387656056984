import React, { Fragment, useRef, useState } from "react";
import withResources from "../textresources/withresources";
import AddButton from "../addButton";
import DocumentItem from "./documentItem";
import PropTypes from "prop-types";

const DocumentEditor = withResources((props) => {
    const { getResource, name, values, onChange, errors, disabled, uploadUrl, culture } = props;
    const addButtonRef = useRef(null);
    const multiLingual = Boolean(culture);
    const [expandedTaskIndexes, setExpandedTaskIndexes] = useState([]);

    const handleChange = (values) => {
        onChange({
            target: {
                name: name,
                value: values,
            },
        });
    };

    const handleAccordionToggle = (isExpanded, indexOfTask) => {
        if (isExpanded) {
            const newExpandedIndexes = [...expandedTaskIndexes].filter((i) => i !== indexOfTask);
            setExpandedTaskIndexes(newExpandedIndexes);
        } else {
            setExpandedTaskIndexes([...expandedTaskIndexes, indexOfTask]);
        }
    };

    const handleFileNameChange = (e, index) => {
        const copy = [...values];
        copy[index].fileName = e.target.value;
        handleChange(copy);
    };

    const handleNameChange = (e, index) => {
        const copy = [...values];

        if (multiLingual) {
            copy[index].name[culture] = e.target.value;
            handleChange(copy);
        } else {
            copy[index].name = e.target.value;
            handleChange(copy);
        }
    };

    const handleDelete = (index) => {
        const copy = [...values.slice(0, index), ...values.slice(index + 1)];
        handleChange(copy);
    };

    const handleFileDelete = (index) => {
        const copy = [...values];
        copy[index].fileId = null;
        copy[index].fileName = null;
        handleChange(copy);
    };

    const handleFileUpload = (file, index) => {
        let fileId = "";
        let extension = "";
        let fileNameWithoutExtension = "";
        let validFileName = "";

        if (file) {
            const indexOfExtension = file.name.lastIndexOf(".");
            extension = file.name.substring(indexOfExtension);
            fileNameWithoutExtension = file.name.substring(0, indexOfExtension);
            validFileName = fileNameWithoutExtension.replace(/[\\/:"*?<>|]+/);
            fileId = file.id;
        }

        const copy = [...values];
        copy[index].fileId = fileId;
        copy[index].fileName = validFileName;
        copy[index].extension = extension;
        handleChange(copy);
    };

    return (
        <Fragment>
            {values &&
                values.map((document, i) => {
                    const isExpanded = expandedTaskIndexes.includes(i);
                    return (
                        <div key={i}>
                            <DocumentItem
                                index={i}
                                isExpanded={isExpanded}
                                document={document}
                                multiLingual={multiLingual}
                                culture={culture}
                                disabled={disabled}
                                errors={errors}
                                uploadUrl={uploadUrl}
                                handleDelete={handleDelete}
                                handleAccordionToggle={handleAccordionToggle}
                                handleFileUpload={handleFileUpload}
                                handleNameChange={handleNameChange}
                                handleFileNameChange={handleFileNameChange}
                                handleFileDelete={handleFileDelete}
                            />
                        </div>
                    );
                })}

            <AddButton
                disabled={disabled}
                buttonRef={addButtonRef}
                buttonText={getResource("Documents.documentEditor.addLinkText", "Add document")}
                handleClick={() => {
                    const copy = values.concat([
                        {
                            fileId: "",
                            fileName: "",
                            name: multiLingual ? { [culture]: "" } : "",
                        },
                    ]);
                    handleChange(copy);
                    setExpandedTaskIndexes([copy.length - 1]);
                }}
            />
        </Fragment>
    );
});

DocumentEditor.propTypes = {
    name: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    errors: PropTypes.array,
    disabled: PropTypes.bool,
    uploadUrl: PropTypes.string,
    culture: PropTypes.string,
};

export default DocumentEditor;

export function validateDocuments(values, name, errors, getResource, culture) {
    const setDocumentError = (i, field, message) => {
        if (!errors[name]) {
            errors[name] = [];
        }
        if (!errors[name][i]) {
            errors[name][i] = {};
        }
        errors[name][i][field] = message;
    };

    values[name].forEach((document, i) => {
        if (!document.fileId) {
            setDocumentError(
                i,
                "fileId",
                getResource("Documents.documentEditor.fileIdRequired", "Please upload a file")
            );
        }

        if (!document.fileName) {
            setDocumentError(
                i,
                "fileName",
                getResource("Documents.documentEditor.fileNameRequired", "File name is required")
            );
        }

        if (culture ? !document.name[culture] : !document.name) {
            setDocumentError(
                i,
                "name",
                getResource("Documents.documentEditor.nameRequired", "Name is required")
            );
        }
    });
}
