import React, { useState, useEffect } from "react";
import useAPI from "../../../shared/useapi";
import { getInitialParameters, startValuation, getAvailableBuildYears } from "./dataservice";
import useCurrencies from "../../library/currencies/usecurrencies";
import LocalizedForm from "../../../shared/localization/localizedform";
import { LinearProgress } from "@material-ui/core";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import MoneyInput from "../../../shared/moneyInput";

const StartValuation = withResources(
    ({
        getResource,
        assetModelId,
        assetId,
        technicalLifeNeeded,
        valuationRequestId,
        onValuationStarted,
        open,
        onClose,
    }) => {
        if (!open) {
            return null;
        }

        const { data: parameters } = useAPI(
            () => getInitialParameters(assetModelId, assetId),
            true,
            [assetModelId, assetId]
        );
        const { data: buildYears } = useAPI(getAvailableBuildYears, true, []);
        const { currencies, getCode } = useCurrencies();
        const [initialValues, setInitialValues] = useState();

        const { invoke: invokeStartValuation, data: newValuationId } = useAPI(
            (values) => startValuation(values),
            false
        );

        useEffect(() => {
            if (newValuationId) {
                onValuationStarted(newValuationId, assetModelId, assetId);
            }
        }, [newValuationId, assetModelId, assetId, onValuationStarted]);

        const onValidate = (values) => {
            const errors = {};

            if (!values.fairMarketValue) {
                errors.fairMarketValue = getResource(
                    "Valuation.valuation.start.enterCurveRation",
                    "Please enter a fair market value"
                );
            }

            if (!values.remainingValueAfterTechnicalLife) {
                errors.remainingValueAfterTechnicalLife = getResource(
                    "Valuation.valuation.start.enterCurveRation",
                    "Please enter a remaining value after technical life"
                );
            }

            if (
                values.liquidationValueDifferenceAsFixed &&
                values.liquidationValueDifferenceAsPercentage
            ) {
                errors.liquidationValueDifferenceAsFixed = getResource(
                    "Valuation.valuation.start.onlyLiquidationValueMessage",
                    "Only one liquidation value is allowed"
                );
                errors.liquidationValueDifferenceAsPercentage = getResource(
                    "Valuation.valuation.start.onlyLiquidationValueMessage",
                    "Only one liquidation value is allowed"
                );
            }

            if (
                !values.liquidationValueDifferenceAsFixed &&
                !values.liquidationValueDifferenceAsPercentage
            ) {
                errors.liquidationValueDifferenceAsFixed = getResource(
                    "Valuation.valuation.start.oneLiquidationValueRequiredMessage",
                    "One liquidation value is required"
                );
                errors.liquidationValueDifferenceAsPercentage = getResource(
                    "Valuation.valuation.start.oneLiquidationValueRequiredMessage",
                    "One liquidation value is required"
                );
            }

            if (
                values.liquidationValueDifferenceAsPercentage &&
                values.liquidationValueDifferenceAsPercentage <= 0
            ) {
                errors.liquidationValueDifferenceAsPercentage = getResource(
                    "Valuation.valuation.start.positiveLiquidationValuePercentage",
                    "Please enter a positive liquidation value difference value"
                );
            }

            if (!values.curveRatio) {
                errors.curveRatio = getResource(
                    "Valuation.valuation.start.enterCurveRation",
                    "Please enter a curve ratio"
                );
            } else if (values.curveRatio <= 0) {
                errors.curveRatio = getResource(
                    "Valuation.valuation.start.positiveCurveRation",
                    "Please enter a positive curve ratio value"
                );
            }

            if (!values.technicalLife) {
                errors.technicalLife = getResource(
                    "Valuation.valuation.start.enterTechnicalLife",
                    "Please enter a technical life value"
                );
            } else if (values.technicalLife <= 0) {
                errors.technicalLife = getResource(
                    "Valuation.valuation.start.positiveTechnicalLife",
                    "Please enter a positive technical life value"
                );
            }

            return errors;
        };

        const onSubmit = async (values) => {
            await invokeStartValuation(values);
        };

        var isLoading = !initialValues || !buildYears || !currencies;

        const [isDirty, setIsDirty] = useState(false);

        useEffect(() => {
            if (parameters) {
                var values = { ...parameters };

                if (technicalLifeNeeded) {
                    values.technicalLife = technicalLifeNeeded;
                }

                if (valuationRequestId) {
                    values.valuationRequestId = valuationRequestId;
                }

                setInitialValues(values);
            }
        }, [parameters, technicalLifeNeeded, valuationRequestId]);

        return (
            <FormDrawer
                title={getResource("Valuation.valuation.start.title", "Start valuation")}
                onClose={() => onClose(true)}
                isDirty={isDirty}
            >
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={initialValues}
                        onIsDirty={setIsDirty}
                        loading={isLoading}
                        okButtonText={getResource("Valuation.valuation.start.okButton", "Start")}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        renderForm={(_, values, handleChange, errors) => {
                            return (
                                <React.Fragment>
                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="currencyId"
                                        label={getResource(
                                            "Valuation.valuation.start.currencyField",
                                            "Currency"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.currencyId}
                                        onSelection={(e) => handleChange(e, false)}
                                        options={currencies}
                                        disableClearable
                                        required
                                    />

                                    <MoneyInput
                                        errors={errors}
                                        autoFocus
                                        name="fairMarketValue"
                                        labelResourceKey="Valuation.valuation.start.fairMarketValue"
                                        labelDefaultValue="Fair market value (New value)"
                                        fullWidth
                                        value={values.fairMarketValue}
                                        currencyCode={getCode(values.currencyId)}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <MoneyInput
                                        errors={errors}
                                        autoFocus
                                        name="remainingValueAfterTechnicalLife"
                                        labelResourceKey="Valuation.valuation.start.valueAfterTechnicalLifeField"
                                        labelDefaultValue="Remaining value after technical life"
                                        fullWidth
                                        value={values.remainingValueAfterTechnicalLife}
                                        currencyCode={getCode(values.currencyId)}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        variant="outlined"
                                        name="technicalLife"
                                        labelResourceKey="Valuation.valuation.start.technicalLifeField"
                                        labelDefaultValue="Technical life"
                                        type="number"
                                        fullWidth
                                        value={values.technicalLife || ""}
                                        inputProps={{ step: 1, min: 1 }}
                                        onChange={(e) => handleChange(e, false)}
                                        required
                                    />

                                    {values.startBuildYear && (
                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="startBuildYear"
                                            label={getResource(
                                                "Valuation.valuation.start.startBuildYearField",
                                                "Start build year"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.startBuildYear}
                                            onSelection={(e) => handleChange(e, false)}
                                            options={buildYears.map((year) => ({
                                                id: year,
                                                name: year,
                                            }))}
                                            disableClearable
                                            required
                                        />
                                    )}

                                    {values.endBuildYear && (
                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="endBuildYear"
                                            label={getResource(
                                                "Valuation.valuation.start.endBuildYearField",
                                                "End build year"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.endBuildYear || ""}
                                            onSelection={(e) => handleChange(e, false)}
                                            options={buildYears.map((year) => ({
                                                id: year,
                                                name: year,
                                            }))}
                                            disableClearable
                                            required
                                        />
                                    )}

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        variant="outlined"
                                        name="curveRatio"
                                        labelResourceKey="Valuation.valuation.start.curveRatioField"
                                        labelDefaultValue="Curve ratio"
                                        type="number"
                                        fullWidth
                                        value={values.curveRatio || ""}
                                        inputProps={{ step: 0.01, min: 0 }}
                                        onChange={(e) => handleChange(e, false)}
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        variant="outlined"
                                        name="liquidationValueDifferenceAsPercentage"
                                        labelResourceKey="Valuation.valuation.start.liquidationValueDifferenceField"
                                        labelDefaultValue="Liquidation value difference (%)"
                                        type="number"
                                        fullWidth
                                        value={values.liquidationValueDifferenceAsPercentage || ""}
                                        inputProps={{ step: 1 }}
                                        onChange={(e) => {
                                            e.target.value = e.target.value.replace(/\D/g, "");
                                            handleChange(e, false);
                                        }}
                                    />

                                    <MoneyInput
                                        errors={errors}
                                        autoFocus
                                        name="liquidationValueDifferenceAsFixed"
                                        labelResourceKey="Valuation.valuation.start.liquidationValueFixedField"
                                        labelDefaultValue="Liquidation value fixed amount"
                                        fullWidth
                                        value={values.liquidationValueDifferenceAsFixed}
                                        currencyCode={getCode(values.currencyId)}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </React.Fragment>
                            );
                        }}
                    />
                )}
            </FormDrawer>
        );
    }
);

export default StartValuation;
