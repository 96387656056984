import React, { useContext } from "react";
import { Alert } from "@material-ui/lab";
import { LinearProgress, FormControlLabel, Switch, FormHelperText } from "@material-ui/core";
import clsx from "clsx";
import { addHours } from "date-fns";

import useCurrencies from "../../library/currencies/usecurrencies";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import { getWhereUserHasOperation as getCompanies } from "../../security/companies/dataservice";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import ScheduledEventEditor, {
    validate as validateScheduledEvent,
    correctTimesOnSubmit,
} from "../../planning/components/scheduledEventEditor";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import PagedListInputForAssets from "../../../shared/pagedListInput/pagedListInputForAssets";
import PagedListInputForComponents from "../../../shared/pagedListInput/pagedListInputForComponents";
import DocumentEditor, { validateDocuments } from "../../../shared/documentEditor";
import MediaEditor, { validateMedia } from "../../../shared/mediaEditor";
import LabelledOutline from "../../../shared/labelledoutline";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useStyles from "./useStyles";

export default withResources(
    ({ getResource, open, onClose, entityName, id, reasons, statuses }) => {
        if (!open) {
            return null;
        }

        const { selectedTimezone } = useContext(LocalizationContext);

        const classes = useStyles();

        const { loading, invoke } = useAPI((values) => update(id, values));

        const { data, loading: dataLoading } = useAPI(() => get(id), true, [id]);

        const { currencies } = useCurrencies();

        const { data: companies } = useAPI(() => getCompanies(["work-orders-update"]), true);

        const onSubmit = async (values) => {
            var copyValues = {
                ...values.values,
                assetId: values.values.asset ? values.values.asset.id : null,
                componentId: values.values.component ? values.values.component.id : null,
                scheduledEvent: values.values.schedule
                    ? correctTimesOnSubmit(
                          {
                              ...values.values.scheduledEvent,
                              summary: values.values.summary,
                              description: values.values.description,
                          },
                          selectedTimezone
                      )
                    : null,
            };

            if (await invoke(copyValues)) {
                onClose(true);
            }
        };

        const showAssetSelector = true;
        const showComponentSelector = true;

        const onValidate = (values) => {
            var errors = {};

            if (!values.summary) {
                errors.summary = getResource(
                    "Maintenance.workOrder.edit.summaryRequired",
                    "Summary is required"
                );
            }

            if (!values.reason) {
                errors.reason = getResource(
                    "Maintenance.workOrder.edit.reasonRequired",
                    "Reason is required"
                );
            }

            if (!values.status) {
                errors.status = getResource(
                    "Maintenance.workOrder.edit.statusRequired",
                    "Status is required"
                );
            }

            if (!values.currencyId) {
                errors.currencyId = getResource(
                    "Maintenance.workOrder.add.currencyIdRequired",
                    "Currency is required"
                );
            }

            if (!values.company && !values.asset && !values.componentId) {
                const error = getResource(
                    "Maintenance.workOrder.edit.companyAssetOrComponentRequired",
                    "Company, asset or component is required"
                );
                errors.companyId = error;
                errors.asset = error;
                errors.component = error;
            }

            if ((values.company || values.asset) && values.schedule === true) {
                validateScheduledEvent(
                    values.scheduledEvent,
                    "scheduledEvent",
                    errors,
                    getResource,
                    true
                );
            }
            validateDocuments(values, "documents", errors, getResource);
            validateMedia(values, "media", errors, getResource);

            return errors;
        };

        var isLoaded = !dataLoading && companies;

        const [isDirty, setIsDirty] = React.useState(false);

        return (
            <FormDrawer
                title={
                    entityName
                        ? getResource(
                              "Maintenance.workOrder.edit.titleWithEntity",
                              "Edit work order for {entityName}",
                              { entityName }
                          )
                        : getResource("Maintenance.workOrder.edit.title", "Edit work order")
                }
                onClose={() => onClose(false)}
                isDirty={isDirty}
            >
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={{
                            ...data,
                            scheduledEvent: data.scheduledEvent
                                ? data.scheduledEvent
                                : {
                                      allDay: false,
                                      start: new Date(),
                                      end: addHours(new Date(), 1),
                                      exactLocationKnown: false,
                                  },
                            componentAssetId: data.componentInstalledAssetId,
                        }}
                        onIsDirty={setIsDirty}
                        loading={loading}
                        okButtonText={getResource("Maintenance.workOrder.edit.okButton", "Update")}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        renderForm={(culture, values, handleChange, errors) => {
                            return (
                                <React.Fragment>
                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="summary"
                                        labelResourceKey="Maintenance.workOrder.edit.summaryField"
                                        labelDefaultValue="Summary"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 255 }}
                                        value={values.summary || ""}
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />

                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="reason"
                                        label={getResource(
                                            "Maintenance.workOrder.edit.reasonField",
                                            "Reason"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.reason}
                                        onSelection={(e) => handleChange(e)}
                                        options={reasons}
                                        disableClearable
                                        required
                                    />

                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="status"
                                        label={getResource(
                                            "Maintenance.workOrder.edit.statusField",
                                            "Status"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.status}
                                        onSelection={(e) => handleChange(e)}
                                        options={statuses}
                                        disableClearable
                                        required
                                    />

                                    <ResourcedTextField
                                        errors={errors}
                                        margin="normal"
                                        name="description"
                                        labelResourceKey="Maintenance.workOrder.edit.descriptionField"
                                        labelDefaultValue="Description"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 500 }}
                                        value={values.description || ""}
                                        onChange={(e) => handleChange(e)}
                                    />

                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="companyId"
                                        label={getResource(
                                            "Maintenance.workOrder.edit.companyIdField",
                                            "Company"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.companyId}
                                        onSelection={(e, asset) =>
                                            handleChange([
                                                e,
                                                {
                                                    target: {
                                                        name: "asset",
                                                        value: null,
                                                    },
                                                },
                                                {
                                                    target: {
                                                        name: "component",
                                                        value: null,
                                                    },
                                                },
                                                {
                                                    target: {
                                                        name: "componentAssetId",
                                                        value: null,
                                                    },
                                                },
                                            ])
                                        }
                                        options={companies}
                                        disableClearable
                                    />

                                    {showAssetSelector && (
                                        <PagedListInputForAssets
                                            label={getResource(
                                                "Maintenance.workOrder.edit.assetField",
                                                "Asset"
                                            )}
                                            errors={errors}
                                            name="asset"
                                            value={values.asset}
                                            onSelection={(e, asset) =>
                                                handleChange([
                                                    e,
                                                    {
                                                        target: {
                                                            name: "companyId",
                                                            value: asset?.companyId || values.companyId,
                                                        },
                                                    },
                                                ])
                                            }
                                            fullWidth
                                            isMedium
                                            dialogTitle={getResource(
                                                "Maintenance.workOrder.edit.dialogTitle",
                                                "Select an asset"
                                            )}
                                            helperText={getResource(
                                                "Maintenance.workOrder.edit.selectAssetText",
                                                "Select the asset for this work order"
                                            )}
                                        />
                                    )}

                                    {showComponentSelector && (
                                        <PagedListInputForComponents
                                            assetId={values.asset ? values.asset.id : null}
                                            errors={errors}
                                            name="component"
                                            fullWidth
                                            isMedium
                                            value={values.component}
                                            onSelection={(e, component) =>
                                                handleChange([
                                                    e,
                                                    {
                                                        target: {
                                                            name: "componentAssetId",
                                                            value:
                                                                (component &&
                                                                    component.installedAssetId) ||
                                                                null,
                                                        },
                                                    },
                                                    {
                                                        target: {
                                                            name: "companyId",
                                                            value: component?.companyId || values.companyId,
                                                        },
                                                    },
                                                ])
                                            }
                                            label={getResource(
                                                "Maintenance.workOrder.edit.componentIdField",
                                                "Component"
                                            )}
                                            dialogTitle={getResource(
                                                "Maintenance.workOrder.edit.component.dialogTitle",
                                                "Select component for this work order"
                                            )}
                                            helperText={getResource(
                                                "Maintenance.workOrder.edit.selectComponentText",
                                                "Select the component for this work order"
                                            )}
                                        />
                                    )}

                                    {values.asset &&
                                        values.asset.id &&
                                        values.componentAssetId &&
                                        values.asset.id !== values.componentAssetId && (
                                            <Alert severity="warning">
                                                {getResource(
                                                    "Maintenance.workOrder.add.component.installedOnDifferentAssetWarning",
                                                    "The selected component is currently installed on a different asset."
                                                )}
                                            </Alert>
                                        )}

                                    <AutoCompleteStatic
                                        errors={errors}
                                        name="currencyId"
                                        label={getResource(
                                            "Maintenance.workOrder.edit.currencyField",
                                            "Currency"
                                        )}
                                        fullWidth
                                        isMedium
                                        value={values.currencyId}
                                        onSelection={(e) => handleChange(e)}
                                        options={currencies}
                                        disableClearable
                                        required
                                    />

                                    {values.currencyId !== data.currencyId && (
                                        <Alert severity="warning">
                                            {getResource(
                                                "Maintenance.workOrder.edit.currencyWarningText",
                                                "Warning: changing the currency might affect cost already recorded for this work order!"
                                            )}
                                        </Alert>
                                    )}

                                    <LabelledOutline
                                        label={getResource(
                                            "Maintenance.workOrder.edit.documentsHeader",
                                            "Documents"
                                        )}
                                    >
                                        <DocumentEditor
                                            errors={errors["documents"]}
                                            values={values.documents}
                                            name="documents"
                                            onChange={(e) => handleChange(e)}
                                            uploadUrl={
                                                "/api/documents/libraries/workorder-documents-generic/upload"
                                            }
                                        />
                                    </LabelledOutline>

                                    <LabelledOutline
                                        label={getResource(
                                            "Maintenance.workOrder.edit.mediaHeader",
                                            "Media"
                                        )}
                                    >
                                        <MediaEditor
                                            errors={errors["media"]}
                                            values={values.media}
                                            name="media"
                                            onChange={(e) => handleChange(e)}
                                            uploadUrl={"api/maintenance/workOrderMedia/upload"}
                                            getDownloadUrl={(image) =>
                                                `/api/maintenance/workOrderMedia/${id}/media/generic/${image.id}/view?imageSize=MediaLibraryWidgetImage&v=${image.fileId}`
                                            }
                                        />
                                    </LabelledOutline>

                                    <LabelledOutline
                                        label={getResource(
                                            "Maintenance.workOrder.edit.tasksHeader",
                                            "Tasks"
                                        )}
                                    >
                                        <Alert severity="info">
                                            {getResource(
                                                "Maintenance.workOrder.edit.tasksInfoText",
                                                "Tasks can be managed on the task list."
                                            )}
                                        </Alert>
                                    </LabelledOutline>

                                    {(values.assetId || values.componentId) && (
                                        <div
                                            className={clsx(
                                                classes.scheduledEventSwitchWrapper,
                                                !values.schedule && "--more-margin-bottom"
                                            )}
                                        >
                                            <FormControlLabel
                                                label={getResource(
                                                    "Planning.scheduledEvent.scheduleField",
                                                    "Schedule event"
                                                )}
                                                variant="outlined"
                                                control={
                                                    <Switch
                                                        name="schedule"
                                                        checked={Boolean(values.schedule)}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                }
                                            />

                                            <FormHelperText className={classes.scheduleHelperText}>
                                                <ResourcedText
                                                    resourceKey="Planning.scheduledEvent.editorHelperText"
                                                    defaultValue="You can schedule an event to include the work in the calendar."
                                                />
                                            </FormHelperText>
                                        </div>
                                    )}

                                    {(values.assetId || values.componentId) && values.schedule && (
                                        <LabelledOutline
                                            label={getResource(
                                                "Planning.scheduledEvent.eventLabel",
                                                "Event"
                                            )}
                                            className={classes.scheduledEventOutline}
                                        >
                                            <ScheduledEventEditor
                                                hideSummaryAndDescription
                                                onScheduledEventChanged={(newScheduledEvent) => {
                                                    handleChange({
                                                        target: {
                                                            name: "scheduledEvent",
                                                            value: newScheduledEvent,
                                                        },
                                                    });
                                                }}
                                                scheduledEvent={values.scheduledEvent}
                                                errors={errors["scheduledEvent"]}
                                            />
                                        </LabelledOutline>
                                    )}
                                </React.Fragment>
                            );
                        }}
                    />
                )}
            </FormDrawer>
        );
    }
);
