import React, { useRef, useState } from "react";
import { Badge, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationsPopover from "./notificationsPopover";
import useAPI from "../../../../../shared/useapi";
import { get } from "./dataservice";

const useStyles = makeStyles((theme) => ({
    notificationsButton: {
        marginLeft: theme.spacing(1),
    },
    notificationsBadge: {
        backgroundColor: "red",
    },
}));

const icons = new Map();

export function registerIcon(type, icon) {
    icons.set(type, icon);
}

const Notifications = () => {
    const classes = useStyles();
    const { data: notifications } = useAPI(get, true);

    const [open, setOpen] = useState(false);

    const notificationsRef = useRef(null);

    return (
        <React.Fragment>
            <IconButton
                className={classes.notificationsButton}
                color="inherit"
                onClick={() => setOpen(true)}
                ref={notificationsRef}
            >
                <Badge
                    badgeContent={(notifications || []).length}
                    classes={{ badge: classes.notificationsBadge }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <NotificationsPopover
                anchorEl={notificationsRef.current}
                notifications={notifications || []}
                onClose={() => setOpen(false)}
                open={open}
                icons={icons}
            />
        </React.Fragment>
    );
};

export default Notifications;
