import React from "react";
import ConfigContext from "./configurationcontext";
import useAPI from "../useapi";
import { get } from "./dataservice";

export default ({ children }) => {
    const { data: config } = useAPI(get, true);

    return <ConfigContext.Provider value={config}>{config && children}</ConfigContext.Provider>;
};
