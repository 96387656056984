import React, { Fragment, useState, useContext } from "react";
import LocalizationContext from "../../../../shared/localization/localizationcontext";
import withResources from "../../../../shared/textresources/withresources";
import ContentHolder from "../../../../shared/contentHolder";
import Graph from "./Graph";
import { getDetails } from "../dataservice";
import { get as getAssetModel } from "../../../library/assetmodels/dataservice";
import { get as getAsset } from "../../../assets/asset/dataservice";
import { PreventNavigationDialog } from "../../../../shared/getUserConfirmation";
import EditModelQuestionnaire from "../../assetmodelquestionnaire/edit";
import EditAssetQuestionnaire from "../../assetquestionnaire/edit";
import useAPI from "../../../../shared/useapi";
import useDialog from "../../../../shared/usedialog";

export default withResources(({ getResource, valuationId }) => {
    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    const { data: valuation, invoke: loadValuation, loading: valuationLoading } = useAPI(
        () => getDetails(valuationId),
        true
    );
    const { data: assetModel } = useAPI(() => getAssetModel(valuation.assetModel.id), false, [
        valuation && valuation.assetModel,
    ]);
    const { data: asset } = useAPI(() => getAsset(valuation.asset.id), false, [
        valuation && valuation.asset,
    ]);

    const {
        open: handleEditQuestionnaire,
        close: handleCloseEditQuestionnaire,
        visible: editQuestionnaireToggled,
    } = useDialog();
    const [isDirty, setIsDirty] = useState(false);

    return (
        <Fragment>
            <PreventNavigationDialog
                when={isDirty}
                header={getResource("Valuation.editor.discardConfirmation.title", "Are you sure?")}
                message={getResource(
                    "Valuation.editor.discardConfirmation.message",
                    "Any information entered will be discarded"
                )}
                discardButtonText={getResource(
                    "Valuation.editor.discardConfirmation.buttonText",
                    "Discard"
                )}
            />
            <ContentHolder>
                {valuation && !valuationLoading && (
                    <Graph
                        valuation={valuation}
                        onGetDirty={() => setIsDirty(true)}
                        openQuestionnaire={handleEditQuestionnaire}
                        reloadValuation={loadValuation}
                        getResource={getResource}
                    />
                )}
            </ContentHolder>

            {assetModel && (
                <EditModelQuestionnaire
                    assetModelId={assetModel.id}
                    manufacturerName={assetModel.manufacturerName}
                    assetModelName={assetModel.name}
                    assetCategoryNames={assetModel.assetCategoryNames}
                    assetCategoryId={assetModel.assetCategory.id}
                    open={editQuestionnaireToggled}
                    onClose={handleCloseEditQuestionnaire}
                />
            )}

            {asset && (
                <EditAssetQuestionnaire
                    assetId={asset.id}
                    assetName={asset.name[selectedCulture] || asset.name[defaultCulture]}
                    assetModelId={asset.assetModel.id}
                    assetModelName={asset.assetModel.name}
                    manufacturerName={asset.manufacturerName}
                    open={editQuestionnaireToggled}
                    onClose={handleCloseEditQuestionnaire}
                />
            )}
        </Fragment>
    );
});
