import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { utcToZonedTime } from "date-fns-tz";
import _ from "underscore";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import WorkingContext from "../../security/workingContext";
import Header from "../../../shared/header";
import Search from "./search";
import PagedList from "../../../shared/pagedList";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import AssetCellContent from "../../../shared/assetCellContent";
import FormattedDate from "../../../shared/formattedDate";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        defaultDebounceInterval,
        getRequiredOperation,
    }) => {
        data = data || {};
        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        const { selectedTimezone } = useContext(LocalizationContext);

        return (
            <React.Fragment>
                <WorkingContext requiredOperation="auctions-view" hideAssetCollections />
                <ContentHolder>
                    <Header
                        title={getResource("Marketplace.auctions.overview.title", "Auctions")}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="auctions-create"
                                    buttonText={getResource(
                                        "Marketplace.auctions.newButton",
                                        "Create auction"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Marketplace.auctions.overview.noResults",
                            "No auctions available"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.auctions.overview.assetHeader"
                                            defaultValue="Asset"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.auctions.overview.startHeader"
                                            defaultValue="Start"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.auctions.overview.statusHeader"
                                            defaultValue="Status"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Marketplace.auctions.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.items &&
                                    data.items.map((row) => (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            key={row.id}
                                            hover
                                            handleClick={() => onEdit(row.id)}
                                            requiredOperation={getRequiredOperation(
                                                "auctions-edit",
                                                row
                                            )}
                                        >
                                            <TableCell component="td">
                                                <AssetCellContent
                                                    assetName={row.assetName}
                                                    manufacturerName={row.manufacturerName}
                                                    assetModelName={row.assetModelName}
                                                    constructionYear={row.constructionYear}
                                                    serial={row.serial}
                                                    internalId={row.internalId}
                                                    assetId={row.id}
                                                />
                                            </TableCell>
                                            <TableCell component="td">
                                                <FormattedDate
                                                    date={utcToZonedTime(
                                                        row.auctionStart,
                                                        selectedTimezone
                                                    )}
                                                    includeTime
                                                />
                                            </TableCell>
                                            <TableCell component="td">{row.status}</TableCell>
                                            <TableCell align="right">
                                                <ActionMenu
                                                    actions={[
                                                        {
                                                            text: getResource(
                                                                "Marketplace.auctions.editButton",
                                                                "Edit"
                                                            ),
                                                            icon: <EditIcon />,
                                                            itemId: row.id,
                                                            requiredOperation: "auctions-edit",
                                                            onClick: () => onEdit(row.id),
                                                            hide: row.status !== "Draft",
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
