import React, { useEffect, useState, useContext } from "react";
import { getAll, getChildren, getParents } from "./dataservice";
import PrivateRoute from "../../../shared/authentication/privateroute";
import Add from "./add";
import Edit from "./edit";
import Move from "./move";
import Delete from "./delete";
import Roles from "./roles";
import UnArchive from "./unarchive";
import Archive from "./archive";
import DrillDown from "../../../shared/drilldown";
import handleToggle from "../../../shared/drilldown/utilities/handleToggle";
import handleUpdateChildren from "../../../shared/drilldown/utilities/handleUpdateChildren";
import handleSearchPress from "../../../shared/drilldown/utilities/search/handleSearchPress";
import handleLoad from "../../../shared/drilldown/utilities/handleLoad";
import handleSearchOptionClick from "../../../shared/drilldown/utilities/search/handleSearchOptionClick";
import Search from "../../../shared/drilldown/search";
import Loader from "../../../shared/drilldown/loader";
import getNewTreeFromItemId from "../../../shared/drilldown/utilities/getNewTreeFromItemId";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
    OpenWith as MoveIcon,
    EnhancedEncryption as SecurityIcon,
    Archive as ArchiveIcon,
} from "@material-ui/icons";
import withResources from "../../../shared/textresources/withresources";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";

export default withResources(({ getResource, history }) => {
    const { setCompanyScope } = useContext(WorkingScopeContext);
    const [itemTree, setItemTree] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [autoCompleteResults, setAutoCompleteResults] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        await handleLoad(setItemTree, setIsLoading, getChildren);
    };

    const handleUpdateChildItems = async (parentId, itemId = null) => {
        setIsLoading(true);
        await handleUpdateChildren(parentId, itemTree, setItemTree, getChildren, itemId);
        setIsLoading(false);
    };

    const handleToggleItem = async (clickedItem) => {
        setIsLoading(true);
        await handleToggle(clickedItem, itemTree, setItemTree, getChildren);
        setIsLoading(false);
    };

    const {
        open: handleAdd,
        close: handleCloseAdd,
        visible: addToggled,
        args: addParentId,
    } = useDialog((parentId) => handleUpdateChildItems(parentId));
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editArgs,
    } = useDialog(({ parentId, id }) => handleUpdateChildItems(parentId, id));
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(({ parentId }) => handleUpdateChildItems(parentId));
    const {
        open: handleMove,
        close: handleCloseMove,
        visible: moveToggled,
        args: moveArgs,
    } = useDialog();
    const {
        open: handleEditRoles,
        close: handleCloseEditRoles,
        visible: editRolesToggled,
    } = useDialog();
    const {
        open: handleArchive,
        close: handleCloseArchive,
        visible: archiveToggled,
        args: archiveArgs,
    } = useDialog(({ parentId }) => handleUpdateChildItems(parentId));
    const {
        open: handleUnArchive,
        close: handleCloseUnArchive,
        visible: unArchiveToggled,
        args: unArchiveArgs,
    } = useDialog(({ parentId }) => handleUpdateChildItems(parentId));

    const handleSearchKeyPress = async (searchText) => {
        await handleSearchPress(
            searchText,
            setAutoCompleteResults,
            (searchText, noDescendantOf, page, pageSize) =>
                getAll(searchText, noDescendantOf, null, page, pageSize)
        );
    };

    const handleSearchOptionChange = async (itemId) => {
        if (!itemId) {
            await loadData();
            return;
        }

        setIsLoading(true);
        await handleSearchOptionClick(itemId, setItemTree, getParents, getChildren);
        setIsLoading(false);
    };

    const getActions = (availableWidth, item, itemId, parentId, version) => {
        return [
            {
                text: getResource("Security.companies.overview.addButton", "Create"),
                icon: <AddIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "companies-create",
                    companyId: itemId,
                },
                onClick: () => handleAdd(itemId),
            },
            {
                text: getResource("Security.companies.overview.editButton", "Edit"),
                icon: <EditIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "companies-update",
                    companyId: itemId,
                },
                onClick: () => handleEdit({ parentId: parentId, id: itemId }),
            },
            {
                text: getResource("Security.companies.overview.moveButton", "Move"),
                icon: <MoveIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "companies-update",
                    companyId: itemId,
                },
                onClick: () => handleMove({ parentId: parentId, id: itemId }),
            },
            {
                text: getResource("Security.companies.overview.deleteButton", "Delete"),
                icon: <DeleteIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "companies-delete",
                    companyId: itemId,
                },
                onClick: () => handleDelete({ parentId: parentId, id: itemId }),
            },
            {
                text: item.isActive
                    ? getResource("Security.companies.overview.archiveButton", "Archive")
                    : getResource("Security.companies.overview.unArchiveButton", "Un-archive"),
                icon: <ArchiveIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "companies-update",
                    companyId: itemId,
                },
                onClick: () =>
                    item.isActive
                        ? handleArchive({
                              companyId: itemId,
                              companyName: item.name,
                              parentId: parentId,
                          })
                        : handleUnArchive({
                              companyId: itemId,
                              companyName: item.name,
                              parentId: parentId,
                          }),
            },
            {
                text: getResource(
                    "Security.companies.overview.roleAssignmentsButton",
                    "Role assignments"
                ),
                icon: <SecurityIcon />,
                itemId: itemId,
                requiredOperation: {
                    name: "user-roles-assign",
                    companyId: itemId,
                },
                onClick: () => {
                    setCompanyScope({ id: itemId, name: item.name });
                    handleEditRoles(itemId);
                },
            },
        ];
    };

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="companies-view"
                path="/security/companies"
                render={(props) => (
                    <ContentHolder>
                        <Header
                            title={getResource("Security.companies.header.title", "Companies")}
                        />

                        <Search
                            itemName="company"
                            autoCompleteResults={autoCompleteResults}
                            handleSearchKeyPress={handleSearchKeyPress}
                            handleSearchOptionChange={handleSearchOptionChange}
                        />

                        <DrillDown
                            getActions={getActions}
                            itemTree={itemTree}
                            handleToggleItem={handleToggleItem}
                            handleClickAddButton={(itemId) => handleAdd(itemId)}
                            addButtonRequiredOperation={"companies-create"}
                            addLabel={getResource(
                                "Security.companies.overview.addRootButton",
                                "Create"
                            )}
                        />

                        <Loader isLoading={isLoading} />
                    </ContentHolder>
                )}
            />

            <Add open={addToggled} parentId={addParentId} onClose={handleCloseAdd} />
            <Edit open={editToggled} id={editArgs && editArgs.id} onClose={handleCloseEdit} />
            <Delete
                open={deleteToggled}
                id={deleteArgs && deleteArgs.id}
                onClose={handleCloseDelete}
            />
            <Move
                open={moveToggled}
                id={moveArgs && moveArgs.id}
                onClose={handleCloseMove}
                onMove={async (newParentId) => {
                    const newItemTree = await getNewTreeFromItemId(
                        newParentId,
                        getParents,
                        getChildren
                    );
                    setItemTree(newItemTree);
                }}
            />
            <Roles open={editRolesToggled} onClose={handleCloseEditRoles} />
            <UnArchive
                open={unArchiveToggled}
                onClose={handleCloseUnArchive}
                companyId={unArchiveArgs && unArchiveArgs.companyId}
                companyName={unArchiveArgs && unArchiveArgs.companyName}
            />
            <Archive
                open={archiveToggled}
                onClose={handleCloseArchive}
                companyId={archiveArgs && archiveArgs.companyId}
                companyName={archiveArgs && archiveArgs.companyName}
            />
        </React.Fragment>
    );
});
