import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    search: {
        marginTop: 3,
        marginBottom: theme.spacing(2),
        width: 320,
        maxWidth: "100%",

        "& .MuiInputBase-root": {
            paddingRight: 35,
        },

        "&.--is-white": {
            "& .MuiFormLabel-root, .MuiInputBase-root, .MuiAutocomplete-clearIndicator": {
                color: "white",
                transition: "none",
            },

            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid white !important",
            },

            '& [class*="PrivateNotchedOutline-legend"]': {
                transition: "none",
            },
        },
    },

    startAdornment: {
        margin: "2px 2px 0 6px",
    },

    searchOption: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        minHeight: 40,
    },

    autocompletePopper: {
        zIndex: 99999,
    },

    autocompleteOption: {
        "&:not(:last-child)": {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },

        '&[data-focus="true"]': {
            backgroundColor: theme.palette.divider,
        },
    },
}));

export default useStyles;
