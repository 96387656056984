/* eslint-disable react/no-multi-comp */
import React, { useContext } from "react";
import { matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";

import NavigationListItem from "./navigationlistitem";
import { __RouterContext } from "react-router";
import AuthenticationContext from "../../../../shared/authentication/authenticationcontext";

const useRouter = () => useContext(__RouterContext);

const NavigationList = ({ pages, ...rest }) => {
    const { authorizationService } = useContext(AuthenticationContext);

    return (
        <List>
            {pages
                .filter(
                    (p) =>
                        (!p.requiredOperation && !p.requiredOperationAtLeastOneOf) ||
                        (p.requiredOperation &&
                            authorizationService.isAuthorized(p.requiredOperation)) ||
                        (p.requiredOperationAtLeastOneOf &&
                            p.requiredOperationAtLeastOneOf.some((x) =>
                                authorizationService.isAuthorized(x)
                            ))
                )
                .reduce((items, page) => reduceChildRoutes({ items, page, ...rest }), [])}
        </List>
    );
};

NavigationList.propTypes = {
    depth: PropTypes.number,
    pages: PropTypes.array,
};

const reduceChildRoutes = ({ router, items, page, depth }) => {
    if (page.children) {
        const open =
            page.open ||
            matchPath(router.location.pathname, {
                path: page.href,
                exact: false,
            });

        items.push(
            <NavigationListItem
                depth={depth}
                icon={page.icon}
                key={page.title}
                label={page.label}
                open={Boolean(open)}
                title={page.title}
            >
                <NavigationList depth={depth + 1} pages={page.children} router={router} />
            </NavigationListItem>
        );
    } else if (!page.hideInMenu) {
        items.push(
            <NavigationListItem
                depth={depth}
                href={page.href}
                icon={page.icon}
                key={page.title}
                label={page.label}
                title={page.title}
            />
        );
    }

    return items;
};

const Navigation = ({
    title,
    href,
    icon,
    open,
    pages,
    className,
    component: Component,
    ...rest
}) => {
    const router = useRouter();
    const { authorizationService } = useContext(AuthenticationContext);

    var requiredOperations = pages
        .filter((p) => p.requiredOperation)
        .map((p) => ({ requiredOperation: p.requiredOperation, hideInMenu: p.hideInMenu }))
        .concat(
            pages
                .filter((p) => p.requiredOperationAtLeastOneOf)
                .flatMap((p) =>
                    p.requiredOperationAtLeastOneOf.map((r) => ({
                        requiredOperation: r,
                        hideInMenu: p.hideInMenu,
                    }))
                )
        );

    const isAllowed =
        !requiredOperations ||
        requiredOperations.some(
            (x) => authorizationService.isAuthorized(x.requiredOperation) && !x.hideInMenu
        );

    return !isAllowed ? null : (
        <Component {...rest} className={className}>
            <NavigationList
                depth={0}
                pages={[
                    {
                        title: title,
                        children: pages,
                        href: href,
                        icon: icon,
                        open: open,
                    },
                ]}
                router={router}
            />
        </Component>
    );
};

Navigation.propTypes = {
    className: PropTypes.string,
    component: PropTypes.any,
    pages: PropTypes.array.isRequired,
    title: PropTypes.string,
};

Navigation.defaultProps = {
    component: "nav",
};

export default Navigation;
