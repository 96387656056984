import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { SubdirectoryArrowRight as UpdateDependencies } from "@material-ui/icons";

import _ from "underscore";

import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Search from "../../../shared/search";
import PagedList from "../../../shared/pagedList";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onEdit,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        return (
            <ContentHolder>
                <Header title={getResource("Security.operations.header.title", "Operations")} />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Security.operations.noResults",
                        "No operations available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                    renderSearch={() => (
                        <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                    )}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.operations.overview.nameHeader"
                                        defaultValue="Name"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Security.operations.overview.identifierHeader"
                                        defaultValue="Identifier"
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Security.operations.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => (
                                    <AuthorizedTableRow
                                        id={row.id}
                                        hover
                                        key={row.id}
                                        handleClick={() => onEdit(row.id)}
                                        requiredOperation="operations-update-dependencies"
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.identifier}
                                        </TableCell>
                                        <TableCell align="right">
                                            <ActionMenu
                                                actions={[
                                                    {
                                                        text: getResource(
                                                            "Security.operations.updateDependenciesButton",
                                                            "Update dependencies"
                                                        ),
                                                        icon: <UpdateDependencies />,
                                                        itemId: row.id,
                                                        requiredOperation: "operations-update",
                                                        onClick: () => onEdit(row.id),
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </AuthorizedTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
