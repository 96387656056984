import React, { useContext, Fragment } from "react";
import { LinearProgress, InputAdornment } from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { get, getActions, update } from "./dataservice";
import { getCascading as getCascadingUsageRecords } from "../../assets/usages/dataservice";
import { getCascading as getCascadingExpectedUsages } from "../../assets/expectedUsages/dataservice";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import LabelledOutline from "../../../shared/labelledoutline";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import DocumentContext from "./documentcontext";
import Upload from "../../../shared/upload";
import condionallyAddReferenceId from "./condionallyAddReferenceId";
import TranslatableTextField from "../../../shared/translatableTextField";
import ActionsEditor, { validateActions } from "../../../shared/actionsEditor";
import { getAll as getAllUsageUnits } from "../../system/usageunits/dataservice";
import { config } from "config";

export default withResources(({ getResource, open, onClose, id, library, referenceId }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id, library), true);
    const { loading, invoke } = useAPI((values) => update(id, library, values));
    const { data: allActions } = useAPI(getActions, true);
    const { data: units } = useAPI(() => getAllUsageUnits(), true);
    const { data: usageRecords } = useAPI(() => {
        if (library.startsWith("asset")) {
            return getCascadingUsageRecords("assets", referenceId);
        } else if (library.startsWith("component")) {
            return getCascadingUsageRecords("components", referenceId);
        } else {
            return null;
        }
    }, true);
    const { data: expectedUsages } = useAPI(() => {
        if (library.startsWith("asset")) {
            return getCascadingExpectedUsages("assets", referenceId);
        } else if (library.startsWith("component")) {
            return getCascadingExpectedUsages("components", referenceId);
        } else {
            return null;
        }
    }, true);

    const { getSupportedDocumentTypes, isSystemLibrary } = useContext(DocumentContext);
    const documentTypes = getSupportedDocumentTypes(library);
    const systemLibrary = isSystemLibrary(library);
    const { allowedDocumentExtensions } = useContext(ConfigurationContext);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!systemLibrary) {
            if (!values.name[defaultCulture]) {
                errors.name = getResource(
                    "Document.document.edit.enterName",
                    "Please enter a name for the default language"
                );
            }

            if (!values.fileName) {
                errors.fileName = getResource(
                    "Document.document.edit.enterFileName",
                    "Please enter a file name"
                );
            } else if (!values.fileName.match(config.fileNameRegEx)) {
                errors.fileName = getResource(
                    "Document.document.edit.enterValidFileName",
                    "Please enter a valid file name"
                );
            }

            if (!values.type) {
                errors.type = getResource(
                    "Document.document.edit.enterType",
                    "Please enter a type"
                );
            }
        }

        if (!values.fileId) {
            errors.fileId = getResource(
                "Document.document.edit.uploadFile",
                "Please upload a file"
            );
        }

        validateActions(values, "actions", errors, getResource, units);

        return errors;
    };

    const isLoaded = data && allActions && units;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Document.document.edit.title", "Edit document")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Document.document.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector={!systemLibrary}
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        function handleFileUpload(file) {
                            let fileId = "",
                                extension = "",
                                fileNameWithoutExtension = "",
                                validFileName = "";

                            if (file) {
                                const indexOfExtension = file.name.lastIndexOf(".");
                                extension = file.name.substring(indexOfExtension);
                                fileNameWithoutExtension = file.name.substring(0, indexOfExtension);
                                validFileName = fileNameWithoutExtension.replace(/[\\/:"*?<>|]+/);
                                fileId = file.id;
                            }

                            updateValues([
                                {
                                    name: "fileId",
                                    value: fileId,
                                    isLocalizable: false,
                                },
                                {
                                    name: "name",
                                    value: fileNameWithoutExtension,
                                    isLocalizable: true,
                                },
                                {
                                    name: "fileName",
                                    value: validFileName,
                                    isLocalizable: false,
                                },
                                {
                                    name: "extension",
                                    value: extension,
                                    isLocalizable: false,
                                },
                            ]);
                        }

                        return (
                            <React.Fragment>
                                <Upload
                                    fileId={values.fileId}
                                    fileName={values.fileName}
                                    errors={errors}
                                    url={condionallyAddReferenceId(
                                        `/api/documents/libraries/${library}/upload`,
                                        referenceId
                                    )}
                                    onFileUpload={handleFileUpload}
                                    disabled={!systemLibrary && !isDefaultCulture}
                                    allowedExtensions={allowedDocumentExtensions}
                                />

                                {!systemLibrary && (
                                    <Fragment>
                                        <TranslatableTextField
                                            errors={errors}
                                            margin="normal"
                                            name="name"
                                            labelResourceKey="Document.document.edit.nameField"
                                            labelDefaultValue="Name"
                                            fullWidth
                                            inputProps={{ maxLength: 255 }}
                                            value={values.name}
                                            culture={culture}
                                            handleChange={handleChange}
                                            required
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="fileName"
                                            labelResourceKey="Document.document.edit.fileNameField"
                                            labelDefaultValue="File name"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 255 }}
                                            value={values.fileName}
                                            onChange={(e) => handleChange(e)}
                                            disabled={!isDefaultCulture}
                                            InputProps={{
                                                endAdornment: values.extension && (
                                                    <InputAdornment position="end">
                                                        {values.extension}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                        />

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="type"
                                            label={getResource(
                                                "Document.document.edit.typeField",
                                                "Type"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.type}
                                            onSelection={(e) => handleChange(e)}
                                            options={
                                                documentTypes
                                                    ? documentTypes.map((t) => ({
                                                        id: t,
                                                        name: t,
                                                    }))
                                                    : null
                                            }
                                            disabled={!isDefaultCulture}
                                            required
                                            disableClearable
                                        />
                                    </Fragment>
                                )}

                                {(library.startsWith("asset") ||
                                    library.startsWith("component")) && (
                                        <LabelledOutline
                                            label={getResource(
                                                "Document.document.edit.actionsHeader",
                                                "When is the document due for review, renewal, etc"
                                            )}
                                        >
                                            <ActionsEditor
                                                errors={errors["actions"]}
                                                values={values.actions}
                                                name="actions"
                                                onChange={(e) => handleChange(e)}
                                                disabled={!isDefaultCulture}
                                                actions={allActions}
                                                linkedToComponent={library.startsWith("component")}
                                                expectedUsages={expectedUsages}
                                                usageRecord={usageRecords}
                                                isInstalled={false}
                                                hasConstructionDate={false}
                                                units={units}
                                            />
                                        </LabelledOutline>
                                    )}
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
