import React from "react";

import { Button } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default ({ state }) => {
    return (
        <div>
            <h1>
                <ResourcedText
                    resourceKey="Security.unauthorized.title"
                    defaultValue="You do not have access to this page."
                />
            </h1>
            <Button
                color="primary"
                variant="contained"
                component={(props) => <RouterLink state={state} {...props} />}
                to="/login"
            >
                <ResourcedText
                    resourceKey="Security.unauthorized.loginWithDifferentAccountButton"
                    defaultValue="Login with a different account"
                />
            </Button>
        </div>
    );
};
