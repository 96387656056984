import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    LinearProgress,
    TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";

import useForm from "../../../shared/forms/useform";
import Header from "../../../shared/header";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import { config } from "config";

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: "100%",
        margin: "0 auto",
        padding: theme.spacing(3, 3, 6, 3),
    },
    content: {
        overflowY: "auto",
        flex: "1 1 auto",
        padding: 20,
    },
    card: {
        marginTop: theme.spacing(3),
    },
    optional: {
        fontSize: ".9em",
        color: "#999",
        paddingLeft: "5px",
    },
}));

export default withResources(({ getResource }) => {
    const classes = useStyles();
    const formId = useRef("id_" + Math.random().toString(36).substr(2, 9));

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            setApplicationSent(true);
        }
    };

    const [applicationSent, setApplicationSent] = useState(false);

    const { values, handleChange, handleSubmit, errors } = useForm({
        initialValues: {
            userPhoneNumber: "",
            companyPhoneNumber: "",
            correspondenceVisible: false,
        },
        onSubmit: (values) => onSubmit(values),
        formId: formId.current,
        validate: (values) => onValidate(values),
    });

    const onValidate = (values) => {
        const errors = {};

        if (!values.userFirstName) {
            errors.userFirstName = getResource(
                "Security.applications.apply.enterFirstName",
                "Please enter a firstname"
            );
        }

        if (!values.userLastName) {
            errors.userLastName = getResource(
                "Security.applications.apply.enterLastName",
                "Please enter a surname"
            );
        }

        if (!values.userEmail) {
            errors.userEmail = getResource(
                "Security.applications.apply.enterEmail",
                "Please enter a personal e-mail"
            );
        }

        if (values.userEmail && !values.userEmail.match(config.emailRegex)) {
            errors.userEmail = getResource(
                "Security.applications.apply.enterValidEmail",
                "The provided personal e-mail isn't a valid e-mail address"
            );
        }

        if (!values.userPassword) {
            errors.userPassword = getResource(
                "Security.applications.apply.enterPassword",
                "Please enter a password"
            );
        }

        if (values.userPassword && !values.userPassword.match(config.passwordRegex)) {
            errors.userPassword = getResource(
                "Security.applications.apply.enterValidPassword",
                "The password doesn't match the requirements"
            );
        }

        if (!values.userConfirmPassword) {
            errors.userConfirmPassword = getResource(
                "Security.applications.apply.enterPasswordConfirmation",
                "Please enter a password confirmation"
            );
        }

        if (values.userPassword !== values.userConfirmPassword) {
            errors.userConfirmPassword = getResource(
                "Security.applications.apply.passwordConfirmationIsNotTheSame",
                "The password isn't the same as the password confirmation"
            );
        }

        if (!values.companyName) {
            errors.companyName = getResource(
                "Security.applications.apply.enterCompanyName",
                "Please enter a company name"
            );
        }

        if (!values.companyEmail) {
            errors.companyEmail = getResource(
                "Security.applications.apply.enterCompanyEmail",
                "Please enter a company e-mail"
            );
        }

        if (values.companyEmail && !values.companyEmail.match(config.emailRegex)) {
            errors.companyEmail = getResource(
                "Security.applications.apply.enterValidCompanyEmail",
                "The provided company e-mail isn't a valid e-mail address"
            );
        }

        if (!values.companyCountry) {
            errors.companyCountry = getResource(
                "Security.applications.apply.selectCompanyCountry",
                "Please select a company country"
            );
        }

        if (!values.companyAddress) {
            errors.companyAddress = getResource(
                "Security.applications.apply.enterCompanyAddress",
                "Please enter a company address"
            );
        }

        if (!values.companyPostalCode) {
            errors.companyPostalCode = getResource(
                "Security.applications.apply.enterCompanyPostalCode",
                "Please enter a company postal code"
            );
        }

        if (!values.companyCity) {
            errors.companyCity = getResource(
                "Security.applications.apply.enterCompanyCity",
                "Please enter a company city"
            );
        }

        if (values.correspondenceVisible && !values.correspondenceCountry) {
            errors.correspondenceCountry = getResource(
                "Security.applications.apply.selectCorrespondenceCountry",
                "Please select a correspondence country"
            );
        }

        if (values.correspondenceVisible && !values.correspondenceAddress) {
            errors.correspondenceAddress = getResource(
                "Security.applications.apply.selectCorrespondenceAddress",
                "Please enter a correspondence address"
            );
        }

        if (values.correspondenceVisible && !values.correspondencePostalCode) {
            errors.correspondencePostalCode = getResource(
                "Security.applications.apply.selectCorrespondencePostalCode",
                "Please enter a correspondence postal code"
            );
        }

        if (values.correspondenceVisible && !values.correspondenceCity) {
            errors.correspondenceCity = getResource(
                "Security.applications.apply.selectCorrespondenceCity",
                "Please enter a correspondence city"
            );
        }

        if (!values.condition1Approved) {
            errors.condition1Approved = getResource(
                "Security.applications.apply.approveCondition1",
                "You need to approve condition 1"
            );
        }

        if (!values.condition2Approved) {
            errors.condition2Approved = getResource(
                "Security.applications.apply.approveCondition2",
                "You need to approve condition 2"
            );
        }

        if (!values.condition3Approved) {
            errors.condition3Approved = getResource(
                "Security.applications.apply.approveCondition3",
                "You need to approve condition 3"
            );
        }

        return errors;
    };

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    return (
        <main className={classes.content}>
            <div className={classes.root}>
                <Header
                    title={getResource(
                        "Security.applications.header.apply.title",
                        "Apply for an account to access the system"
                    )}
                />
                {applicationSent ? (
                    <Card className={classes.card}>
                        <CardHeader
                            title={getResource(
                                "Security.applications.applicationSent.title",
                                "Thank you"
                            )}
                        />
                        <CardContent>
                            <ResourcedText
                                resourceKey="Security.applications.applicationSent.message"
                                defaultValue="We have received your application."
                            />
                        </CardContent>
                    </Card>
                ) : allCountries ? (
                    <form id={formId.current} onSubmit={handleSubmit}>
                        <Card className={classes.card}>
                            <CardHeader
                                title={getResource(
                                    "Security.applications.newApplication.title",
                                    "Personal details"
                                )}
                            />
                            <CardContent>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="userFirstName"
                                    labelResourceKey="Security.applications.newApplication.firstNameField"
                                    labelDefaultValue="First name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.userFirstName}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userLastName"
                                    labelResourceKey="Security.applications.newApplication.surNameField"
                                    labelDefaultValue="Surname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 60 }}
                                    value={values.userLastName}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userEmail"
                                    labelResourceKey="Security.applications.newApplication.emailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.userEmail}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userPassword"
                                    labelResourceKey="Security.applications.newApplication.passwordField"
                                    labelDefaultValue="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.userPassword}
                                    onChange={(e) => handleChange(e)}
                                />

                                <Alert severity="info">
                                    <ResourcedText
                                        resourceKey="Security.applications.newApplication.passwordHelpText"
                                        defaultValue="Your password needs to be at least 6 long and needs to have at least 1 capital, 1 digit, and 1 non-word character in it."
                                    />
                                </Alert>

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="userConfirmPassword"
                                    labelResourceKey="Security.applications.newApplication.passwordConfirmField"
                                    labelDefaultValue="Password confirmation"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.userConfirmPassword}
                                    onChange={(e) => handleChange(e)}
                                />

                                <TextField
                                    margin="normal"
                                    name="userPhoneNumber"
                                    label={
                                        <LabelWithOptional
                                            label={getResource(
                                                "Security.applications.newApplication.phoneNumberField",
                                                "Phone number"
                                            )}
                                        />
                                    }
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.userPhoneNumber || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.userPhoneNumber;
                                        }
                                        handleChange(e);
                                    }}
                                />
                            </CardContent>
                        </Card>

                        <Card className={classes.card}>
                            <CardHeader
                                title={getResource(
                                    "Security.applications.newApplication.companyTitle",
                                    "Company"
                                )}
                            />
                            <CardContent>
                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="companyName"
                                    labelResourceKey="Security.applications.newApplication.companyNameField"
                                    labelDefaultValue="Name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 75 }}
                                    value={values.companyName}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="companyEmail"
                                    labelResourceKey="Security.applications.newApplication.companyEmailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.companyEmail}
                                    onChange={(e) => handleChange(e)}
                                />

                                <TextField
                                    margin="normal"
                                    name="companyPhoneNumber"
                                    label={
                                        <LabelWithOptional
                                            label={getResource(
                                                "Security.applications.newApplication.companyPhoneNumberField",
                                                "Phone number"
                                            )}
                                        />
                                    }
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.companyPhoneNumber || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.companyPhoneNumber;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <TextField
                                    margin="normal"
                                    name="companyChamberOfCommerce"
                                    label={
                                        <LabelWithOptional
                                            label={getResource(
                                                "Security.applications.newApplication.companyChamberOfCommerceField",
                                                "Chamber of commerce"
                                            )}
                                        />
                                    }
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.companyChamberOfCommerce || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                                <TextField
                                    margin="normal"
                                    name="companyVatNumber"
                                    label={
                                        <LabelWithOptional
                                            label={getResource(
                                                "Security.applications.newApplication.companVATField",
                                                "VAT Number"
                                            )}
                                        />
                                    }
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.companyVatNumber || ""}
                                    onChange={(e) => handleChange(e)}
                                />
                            </CardContent>
                        </Card>

                        <Card className={classes.card}>
                            <CardHeader
                                title={getResource(
                                    "Security.applications.newApplication.companyAddressTitle",
                                    "Company address"
                                )}
                            />
                            <CardContent>
                                <AutoCompleteStatic
                                    errors={errors}
                                    name="companyCountry"
                                    label={getResource(
                                        "Security.applications.newApplication.companyCountryField",
                                        "Country"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.companyCountry}
                                    onSelection={(e) => handleChange(e)}
                                    options={allCountries.map((c) => ({
                                        id: c.code,
                                        name: c.name,
                                    }))}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="companyAddress"
                                    labelResourceKey="Security.applications.newApplication.companyAddressField"
                                    labelDefaultValue="Address"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.companyAddress}
                                    onChange={(e) => handleChange(e)}
                                />

                                <TextField
                                    margin="normal"
                                    name="companyState"
                                    label={
                                        <LabelWithOptional
                                            label={getResource(
                                                "Security.applications.newApplication.companyState",
                                                "State"
                                            )}
                                        />
                                    }
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.companyState || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="companyPostalCode"
                                    labelResourceKey="Security.applications.newApplication.companyPostalCodeField"
                                    labelDefaultValue="Postal code"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.companyPostalCode}
                                    onChange={(e) => handleChange(e)}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="companyCity"
                                    labelResourceKey="Security.applications.newApplication.companyCityField"
                                    labelDefaultValue="City"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.companyCity}
                                    onChange={(e) => handleChange(e)}
                                />
                            </CardContent>
                        </Card>

                        <Card className={classes.card}>
                            <CardHeader
                                title={getResource(
                                    "Security.applications.newApplication.correspondanceAddressTitle",
                                    "Correspondence address"
                                )}
                            />
                            <CardContent>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(values.correspondenceVisible)}
                                            onChange={(e) => handleChange(e)}
                                            name="correspondenceVisible"
                                        />
                                    }
                                    label={getResource(
                                        "Security.applications.newApplication.differentCorrespondenceAddressField",
                                        "Different correspondence address"
                                    )}
                                    variant="outlined"
                                />
                                {values.correspondenceVisible && (
                                    <React.Fragment>
                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="correspondenceCountry"
                                            label={getResource(
                                                "Security.applications.newApplication.correspondenceCountryField",
                                                "Country"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.correspondenceCountry}
                                            onSelection={(e) => handleChange(e)}
                                            options={allCountries.map((c) => ({
                                                id: c.code,
                                                name: c.name,
                                            }))}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="correspondenceAddress"
                                            labelResourceKey="Security.applications.newApplication.correspondenceAddressField"
                                            labelDefaultValue="Address"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.correspondenceAddress}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <TextField
                                            margin="normal"
                                            name="correspondenceState"
                                            label={
                                                <LabelWithOptional
                                                    label={getResource(
                                                        "Security.applications.newApplication.correspondenceStateField",
                                                        "State"
                                                    )}
                                                />
                                            }
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.correspondenceState || ""}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="correspondencePostalCode"
                                            labelResourceKey="Security.applications.newApplication.correspondencePostalCodeField"
                                            labelDefaultValue="Postal code"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 50 }}
                                            value={values.correspondencePostalCode}
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <ResourcedTextField
                                            errors={errors}
                                            margin="normal"
                                            name="correspondenceCity"
                                            labelResourceKey="Security.applications.newApplication.correspondenceCityField"
                                            labelDefaultValue="City"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 100 }}
                                            value={values.correspondenceCity}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </React.Fragment>
                                )}
                            </CardContent>
                        </Card>

                        <Card className={classes.card}>
                            <CardHeader
                                title={getResource(
                                    "Security.applications.newApplication.conditionsTitle",
                                    "Conditions"
                                )}
                            />
                            <CardContent>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                autoFocus={"condition1Approved" in errors}
                                                checked={Boolean(values.condition1Approved)}
                                                onChange={(e) => handleChange(e)}
                                                name="condition1Approved"
                                            />
                                        }
                                        label={<span dangerouslySetInnerHTML={{
                                            __html: getResource(
                                                "Security.applications.newApplication.conditions1Field",
                                                "Condition 1"
                                            )
                                        }} />}
                                        variant="outlined"
                                    />
                                    {"condition1Approved" in errors ? (
                                        <FormHelperText className="Mui-error">
                                            {errors["condition1Approved"]}
                                        </FormHelperText>
                                    ) : null}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                autoFocus={"condition2Approved" in errors}
                                                checked={Boolean(values.condition2Approved)}
                                                onChange={(e) => handleChange(e)}
                                                name="condition2Approved"
                                            />
                                        }
                                        label={<span dangerouslySetInnerHTML={{
                                            __html: getResource(
                                                "Security.applications.newApplication.conditions2Field",
                                                "Condition 2"
                                            )
                                        }} />}
                                        variant="outlined"
                                    />
                                    {"condition2Approved" in errors ? (
                                        <FormHelperText className="Mui-error">
                                            {errors["condition2Approved"]}
                                        </FormHelperText>
                                    ) : null}

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                autoFocus={"condition3Approved" in errors}
                                                checked={Boolean(values.condition3Approved)}
                                                onChange={(e) => handleChange(e)}
                                                name="condition3Approved"
                                            />
                                        }
                                        label={<span dangerouslySetInnerHTML={{
                                            __html: getResource(
                                                "Security.applications.newApplication.conditions3Field",
                                                "Condition 3"
                                            )
                                        }} />}
                                        variant="outlined"
                                    />
                                    {"condition3Approved" in errors ? (
                                        <FormHelperText className="Mui-error">
                                            {errors["condition3Approved"]}
                                        </FormHelperText>
                                    ) : null}
                                </FormGroup>
                            </CardContent>
                        </Card>
                        <div className={classes.card}>
                            <Button
                                color="secondary"
                                variant="contained"
                                component={RouterLink}
                                to="/login"
                            >
                                <ResourcedText
                                    resourceKey="Security.applications.newApplication.cancelButton"
                                    defaultValue="Cancel"
                                />
                            </Button>
                            <Button
                                disabled={loading}
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                <ResourcedText
                                    resourceKey="Security.applications.newApplication.applyButton"
                                    defaultValue="Apply"
                                />
                            </Button>
                        </div>
                    </form>
                ) : (
                    <LinearProgress />
                )}
            </div>
        </main>
    );
});

const LabelWithOptional = withResources(({ getResource, label }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <span>{label}</span>
            <span className={classes.optional}>
                {" "}
                ({getResource("Shell.optionalLabel", "optional")})
            </span>
        </React.Fragment>
    );
});
