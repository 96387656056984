//https://www.codetinkerer.com/2019/01/14/cancel-async-requests-pattern.html
//Altered it to be used in a generic way with multiple url's
const cancelFetchOnReentry = (wrappedFunc) => {
    let aborts = [];

    return (url, ...args) => {
        let abortUrl = url;

        //Abort request on url without querystring so strip this from the url
        if (abortUrl.indexOf("?") > 0) {
            abortUrl = url.substring(0, url.indexOf("?"));
        }

        //If it's a new url than add it to the array
        if (aborts.findIndex((x) => x.url === abortUrl) <= 0) {
            if (!abortUrl.indexOf('api/settings/dictionary/')) { // these URL is allowed to happen multiple times
                aborts.push({ url: abortUrl, controller: new AbortController() });
            }
        }

        var currentAbort = aborts.find((x) => x.url === abortUrl);
        if (currentAbort) {
            currentAbort.controller.abort();
            currentAbort.controller = new AbortController();
        }

        const injectedFetch = (input, init) =>
            fetch(input, {
                ...init,
                signal: currentAbort && currentAbort.controller.signal,
            });

        try {
            return wrappedFunc(injectedFetch)(url, ...args);
        } catch (ex) {
            if (ex.name === "AbortError") {
                return; // Request has been canceled, so do nothing
            }

            throw ex;
        }
    };
};

export default cancelFetchOnReentry;
