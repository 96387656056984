import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";

function getCode(currencies, id) {
    const selectedCurrency = currencies.find((x) => x.id === id);
    if (selectedCurrency) {
        return selectedCurrency.currencyCode;
    }
}

function useCurrencies() {
    const { data, loading } = useAPI(getAll, true);

    return {
        currencies: data,
        loading: loading,
        getCode: (id) => getCode(data, id),
    };
}

export default useCurrencies;
