import React, { Fragment } from "react";
import { LinearProgress, FormControlLabel } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import DrillDownInput from "../../../shared/drilldowninput";
import LabelledOutline from "../../../shared/labelledoutline";

import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import { getParents, getActiveChildren, getPath, getAll } from "./../companies/dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Timezone from "./timezone";
import Switch from "@material-ui/core/Switch";
import { config } from "config";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            companies: values.values.companies.map((item) => item.id),
            country: values.values.addressVisible ? values.values.country : null,
            address: values.values.addressVisible ? values.values.address : null,
            postalCode: values.values.addressVisible ? values.values.postalCode : null,
            state: values.values.addressVisible ? values.values.state : null,
            city: values.values.addressVisible ? values.values.city : null,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = getResource(
                "Security.users.edit.enterFirstName",
                "Please enter a firstname"
            );
        }

        if (!values.lastName) {
            errors.lastName = getResource(
                "Security.users.edit.enterSurname",
                "Please enter a surname"
            );
        }

        if (!values.email) {
            errors.email = getResource("Security.users.edit.enterEmail", "Please enter a e-mail");
        }

        if (values.email && !values.email.match(config.emailRegex)) {
            errors.email = getResource(
                "Security.users.edit.enterValidEmail",
                "The provided e-mail isn't a valid e-mail address"
            );
        }

        if (values.password && !values.password.match(config.passwordRegex)) {
            errors.password = getResource(
                "Security.users.edit.enterValidPassword",
                "The password doesn't match the requirements"
            );
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = getResource(
                "Security.users.edit.enterSamePasswordConfirmation",
                "The password isn't the same as the password confirmation"
            );
        }

        if (values.addressVisible && !values.country) {
            errors.country = getResource(
                "Security.users.edit.selectCountry",
                "Please select a country"
            );
        }

        if (values.addressVisible && !values.address) {
            errors.address = getResource(
                "Security.users.edit.enterAddress",
                "Please enter a address"
            );
        }

        if (values.addressVisible && !values.postalCode) {
            errors.postalCode = getResource(
                "Security.users.edit.enterPostalCode",
                "Please enter a postal code"
            );
        }

        if (values.addressVisible && !values.city) {
            errors.city = getResource("Security.users.edit.enterCity", "Please enter a city");
        }

        if (!values.companies || values.companies.length === 0) {
            errors.companies = getResource(
                "Security.users.edit.selectCompanies",
                "Please select one or more companies"
            );
        }

        if (!values.timezone) {
            errors.timezone = getResource(
                "Security.users.edit.selectTimezone",
                "Please select a timezone"
            );
        }

        return errors;
    };

    var isLoaded = data && allCountries;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.users.edit.title", "Edit user")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.users.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="firstName"
                                    labelResourceKey="Security.users.edit.firstNameField"
                                    labelDefaultValue="First name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.firstName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="lastName"
                                    labelResourceKey="Security.users.edit.surnameField"
                                    labelDefaultValue="Surname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 60 }}
                                    value={values.lastName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="email"
                                    labelResourceKey="Security.users.edit.emailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.email || ""}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="password"
                                    labelResourceKey="Security.users.edit.passwordField"
                                    labelDefaultValue="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.password || ""}
                                    onChange={(e) => handleChange(e)}
                                    helperText={
                                        <Fragment>
                                            <ResourcedText
                                                resourceKey="Security.users.edit.emptyPasswordInstructionMessage"
                                                defaultValue="If you keep the password fields empty, the current password is being used."
                                            />
                                            <ResourcedText
                                                resourceKey="Security.users.edit.passwordInstructionMessage"
                                                defaultValue="Your password needs to be at least 6 long and needs to have at least 1 capital, 1 digit, and 1 non-word character in it."
                                            />
                                        </Fragment>
                                    }
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="confirmPassword"
                                    labelResourceKey="Security.users.edit.passwordConfirmationField"
                                    labelDefaultValue="Password confirmation"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.confirmPassword || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                                <DrillDownInput
                                    errors={errors}
                                    name="companies"
                                    itemName={getResource(
                                        "Security.users.edit.companiesField",
                                        "Companies"
                                    )}
                                    label={getResource(
                                        "Security.users.edit.companiesField",
                                        "Companies"
                                    )}
                                    isMedium
                                    fullWidth
                                    getParents={getParents}
                                    getChildren={getActiveChildren}
                                    getPath={getPath}
                                    filter={(searchText, noDescendantOf, page, pageSize) =>
                                        getAll(searchText, noDescendantOf, true, page, pageSize)
                                    }
                                    value={values.companies}
                                    disableClearable
                                    setValue={(e) => {
                                        handleChange(e);
                                    }}
                                    multiple
                                    required
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Security.users.edit.personalAddressLabel",
                                        "Personal address"
                                    )}
                                >
                                    <FormControlLabel
                                        label={getResource(
                                            "Security.users.edit.personalAddressSwitchLabel",
                                            "Personal address"
                                        )}
                                        control={
                                            <Switch
                                                checked={Boolean(values.addressVisible)}
                                                onChange={(e) => handleChange(e)}
                                                name="addressVisible"
                                            />
                                        }
                                    />

                                    {values.addressVisible && (
                                        <Fragment>
                                            <AutoCompleteStatic
                                                errors={errors}
                                                name="country"
                                                label={getResource(
                                                    "Security.users.edit.countryField",
                                                    "Country"
                                                )}
                                                fullWidth
                                                isMedium
                                                value={values.country}
                                                onSelection={(e) => handleChange(e)}
                                                options={allCountries.map((c) => ({
                                                    id: c.code,
                                                    name: c.name,
                                                }))}
                                                disableClearable
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="address"
                                                labelResourceKey="Security.users.edit.addressField"
                                                labelDefaultValue="Address"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.address}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="state"
                                                labelResourceKey="Security.users.edit.stateField"
                                                labelDefaultValue="State"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.state || ""}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="postalCode"
                                                labelResourceKey="Security.users.edit.postalCodeField"
                                                labelDefaultValue="Postal code"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                value={values.postalCode}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="city"
                                                labelResourceKey="Security.users.edit.cityField"
                                                labelDefaultValue="City"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.city}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </Fragment>
                                    )}
                                </LabelledOutline>

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber1"
                                    labelResourceKey="Security.users.edit.phoneNumber1Field"
                                    labelDefaultValue="Phone number 1"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber1 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber1;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber2"
                                    labelResourceKey="Security.users.edit.phoneNumber2Field"
                                    labelDefaultValue="Phone number 2"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber2 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber2;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <Timezone
                                    name="timezone"
                                    value={values.timezone}
                                    onChange={(e) => handleChange(e)}
                                    errors={errors}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
