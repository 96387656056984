import React, { useContext } from "react";
import PropTypes from "prop-types";
import PagedListInput from "./index";
import Search from "../../features/assets/asset/search";
import withResources from "../textresources/withresources";
import PagedListContent from "../../features/assets/asset/pagedListContent";
import _ from "underscore";
import useSessionStorage from "../useSessionStorage";
import useAPI from "../useapi";
import { getAll } from "../../features/assets/asset/dataservice";
import ConfigurationContext from "../configuration/configurationcontext";

const PagedListInputForAssets = withResources((props) => {
    const { getResource, required, companyId } = props;

    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-asset-paged-list-input", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: "",
        assetModelId: null,
        manufacturerId: null,
        assetCategory: null,
        financierCompanyIds: [],
        order: "Default"
    });

    const { loading, invoke, data: filteredAssets } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.assetModelId,
                searchParams.manufacturerId,
                searchParams.assetCategory ? searchParams.assetCategory.id : null,
                searchParams.financierCompanyIds,
                companyId,
                false,
                searchParams.order,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        false,
        [searchParams, companyId]
    );

    const data = filteredAssets || {};
    const items = data.items;

    const onSearch = (searchText, assetModelId, manufacturerId, assetCategory, financierCompanyIds, order) => {
        setSearchParams({ ...searchParams, searchText, assetModelId, manufacturerId, assetCategory, financierCompanyIds, order, page: 0 });
    };

    const onPageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const onPageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

    return (
        <PagedListInput
            loading={loading}
            itemCount={data.total}
            noResultsText={getResource("Asset.assets.noResults", "No assets available")}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            invoke={invoke}
            availablePageSizes={availablePageSizes}
            page={searchParams.page}
            pageSize={searchParams.pageSize}
            items={items}
            PagedListContent={PagedListContent}
            renderSearch={() => <Search onSearch={onSearchDebounced} searchParams={searchParams} />}
            getCustomName={(row) => {
                if (row.name) {
                    return row.name;
                }
                return `${row.manufacturerName} | ${row.assetModelName}`;
            }}
            {...props}
            required={required}
        />
    );
});

PagedListInputForAssets.propTypes = {
    getResources: PropTypes.string,
};

export default PagedListInputForAssets;
