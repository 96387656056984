import React, { useEffect, useState, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import {
    filter,
    getChildren,
    getParents,
    getPath,
} from "./../../library/assetcategories/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import QuestionsEditor from "./questionsEditor";
import CreditScore from "./creditScore";
import DrillDownInput from "../../../shared/drilldowninput";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const [questions, setQuestions] = useState([]);

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            assetCategories: values.values.assetCategories.map((item) => item.id),
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    useEffect(() => {
        data && setQuestions(data.questions);
    }, [data]);

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.assetCategories || values.assetCategories.length === 0) {
            errors.assetCategories = getResource(
                "Valuation.questionnaire.edit.selectAssetCategory",
                "Please select an category"
            );
        }

        if (!values.questions || values.questions.length === 0) {
            errors.questions = getResource(
                "Valuation.questionnaire.edit.enterQuestion",
                "Please fill in at least 1 question"
            );
        }

        return errors;
    };

    var isLoaded = data;

    const [isDirty, setIsDirty] = useState(false);
    return (
        <React.Fragment>
            <FormDrawer
                title={getResource(
                    "Valuation.questionnaire.edit.title",
                    "Edit category questionnaire"
                )}
                onClose={() => onClose(false)}
                isDirty={isDirty}
                size="medium"
            >
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <LocalizedForm
                        initialValues={data}
                        onIsDirty={setIsDirty}
                        loading={loading}
                        okButtonText={getResource(
                            "Valuation.questionnaire.edit.okButton",
                            "Update"
                        )}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        renderForm={(culture, values, handleChange, errors) => {
                            return (
                                <Fragment>
                                    <DrillDownInput
                                        errors={errors}
                                        autoFocus
                                        name="assetCategories"
                                        multiple
                                        itemName={getResource(
                                            "Valuation.questionnaire.add.assetCategoryItemName",
                                            "category"
                                        )}
                                        label={getResource(
                                            "Valuation.questionnaire.add.assetCategoryField",
                                            "Category"
                                        )}
                                        isMedium
                                        fullWidth
                                        getParents={getParents}
                                        getChildren={getChildren}
                                        getPath={getPath}
                                        filter={filter}
                                        value={values.assetCategories}
                                        disableClearable
                                        setValue={(e) => handleChange(e)}
                                        className="e2e_assetCategory"
                                    />

                                    <CreditScore questions={questions} />

                                    <QuestionsEditor
                                        error={"questions" in errors}
                                        helperText={errors["questions"]}
                                        questions={questions}
                                        onQuestionsChanged={(newQuestions) => {
                                            setQuestions(newQuestions);
                                            handleChange({
                                                target: { name: "questions", value: newQuestions },
                                            });
                                        }}
                                    />
                                </Fragment>
                            );
                        }}
                    />
                )}
            </FormDrawer>
        </React.Fragment>
    );
});
