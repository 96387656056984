import { useContext, useEffect } from "react";
import ReferenceContext from "./referenceContext";

export const ReferenceExtension = ({ type, fetchMethod, nameMethod, breadcrumbMethod }) => {
    const { registerReference } = useContext(ReferenceContext);

    useEffect(() => {
        registerReference(type, fetchMethod, nameMethod, breadcrumbMethod);
    }, [type, fetchMethod, nameMethod, breadcrumbMethod, registerReference]);

    return null;
};
