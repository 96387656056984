import React, { useEffect } from "react";
import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import useAPI from "../../../shared/useapi";
import { purchase } from "./dataservice";
import { getQuickScan } from "../quickscan/dataservice";
import { get as getFinancialDetails } from "../../assets/assetFinancialDetail/dataservice";

import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, onClose, history, quickScanId }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke, data: financialReportId } = useAPI(purchase);

    const handleCreate = async () => {
        await invoke({ quickScanId });
    };

    useEffect(() => {
        if (financialReportId == null) return;

        history.push(`/valuation/financial-report/${quickScanId}/${financialReportId}`);
    }, [history, quickScanId, financialReportId]);

    const { data: quickScan } = useAPI(() => getQuickScan(quickScanId), true, [quickScanId]);
    const { data: financialDetails } = useAPI(
        () => getFinancialDetails(quickScan.assetId),
        quickScan != null,
        [quickScan && quickScan.assetId]
    );

    const isLoaded = quickScan && financialDetails;

    return (
        <FormDrawer
            title={getResource(
                "Valuation.financialReport.purchase.title",
                "Confirm financial report purchase"
            )}
            onClose={onClose}
            isDirty={false}
        >
            <React.Fragment>
                {!isLoaded ? (
                    <LinearProgress />
                ) : (
                    <React.Fragment>
                        {!financialDetails.isExistingRecord && (
                            <Alert severity="warning">
                                {getResource(
                                    "Valuation.financialReport.purchase.noExistingFinancialDetail",
                                    "Financial details for this asset have not filled; please retry your purchase after filling the financial details"
                                )}
                            </Alert>
                        )}
                        {financialDetails.isExistingRecord &&
                            financialDetails.currencyId !== quickScan.currencyId && (
                                <Alert severity="warning">
                                    {getResource(
                                        "Valuation.financialReport.purchase.notMatchingCurrencies",
                                        "Financial details and quick scan do not use the same currency; please review both and make the appropriate changes before purchasing a financial report"
                                    )}
                                </Alert>
                            )}

                        {financialDetails.isExistingRecord &&
                            financialDetails.currencyId === quickScan.currencyId && (
                                <React.Fragment>
                                    <p>
                                        {getResource(
                                            "Valuation.financialReport.purchase.text",
                                            "By confirming the purchase you will be billed for this financial report."
                                        )}
                                    </p>
                                    <Button
                                        disabled={loading}
                                        onClick={handleCreate}
                                        color="primary"
                                        fullWidth
                                        variant="contained"
                                    >
                                        {getResource(
                                            "Valuation.financialReport.purchase.purchaseButtonText",
                                            "Purchase"
                                        )}
                                        {loading && <CircularProgress />}
                                    </Button>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                )}
            </React.Fragment>
        </FormDrawer>
    );
});
