import React, { Fragment, useState } from "react";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import DatePicker from "../../../shared/datepicker";
import MediaEditor from "../../../shared/mediaEditor";

export default withResources(({ getResource, open, onClose, assetId, library }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI((values) => add(assetId, library, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.fileId) {
            errors.media = [
                { fileId: getResource("Assets.media.add.uploadFile", "Please upload a file") },
            ];
        }

        if (!values.date) {
            errors.date = getResource("Assets.media.add.enterDate", "Please enter a date");
        }

        return errors;
    };

    const [isDirty, setIsDirty] = useState(false);
    return (
        <FormDrawer
            title={getResource("Assets.media.add.title", "Add media")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            <LocalizedForm
                initialValues={{ description: {}, fileId: null, date: new Date() }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource("Assets.media.add.okButton", "Create")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                showCultureSelector
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture
                ) => {
                    const handleMediaEditorChange = (e) => {
                        if (!e) return;
                        const value = e.target.value[0];
                        updateValues([
                            {
                                name: "fileId",
                                value: value.fileId,
                            },
                            {
                                name: "description",
                                value: value.description,
                            },
                        ]);
                    };

                    return (
                        <Fragment>
                            <MediaEditor
                                name="media"
                                errors={errors.media}
                                uploadUrl={`/api/assets/asset/${assetId}/media/${library}/upload`}
                                culture={culture}
                                disabled={!isDefaultCulture}
                                onChange={handleMediaEditorChange}
                                singleMediaItem
                                values={[
                                    {
                                        description: { ...values.description },
                                        fileId: values.fileId,
                                    },
                                ]}
                            />

                            <DatePicker
                                name="date"
                                errors={errors}
                                label={getResource("Assets.media.add.date", "Date")}
                                value={values.date}
                                onChange={(e) => handleChange(e)}
                                disableFuture
                                disabled={!isDefaultCulture}
                            />
                        </Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
