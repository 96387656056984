import React from "react";
import { Typography } from "@material-ui/core";
import withResources from "../../../../shared/textresources/withresources";
import useStyles from "./useStyles";
import useAuthorization from "../../../../shared/authentication/useauthorization";

const WidgetItem = withResources((props) => {
    const { factType, title, authorAndDate, requiredOperation, actions = [] } = props;

    const classes = useStyles();

    const { isAuthorized } = useAuthorization(requiredOperation);

    let actionNotification;
    if (actions.length > 0) {
        if (actions.some((e) => e.urgency === "overdue")) {
            actionNotification = <span className={`${classes.actionNotification} overdue`}></span>;
        } else if (actions.some((e) => e.urgency === "impending")) {
            actionNotification = (
                <span className={`${classes.actionNotification} impending`}></span>
            );
        }
    }

    if (isAuthorized) {
        return (
            <div className={classes.fact}>
                {actionNotification}
                <div className={classes.content}>
                    <div className={classes.titleAndLabel}>
                        <div className={classes.titleWrapper}>
                            <Typography variant="h6" component="h2" className={classes.title}>
                                {title}
                            </Typography>
                        </div>
                        <div className={classes.factLabel}>{factType}</div>
                    </div>
                    <Typography variant="body2" className={classes.subTitle}>
                        {authorAndDate}
                    </Typography>
                </div>
            </div>
        );
    } else {
        return null;
    }
});

export default WidgetItem;
