import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import Confirmation from "../../../../shared/confirmation";
import ResourcedText from "../../../../shared/textresources/resourcedtext";

export default withResources(({ getResource, open, type, onAction, onClose, isEdit }) => {
    return (
        <Confirmation
            headerText={getResource(
                "Marketplace.advertisement.duplicateWarningDialog.title",
                "Duplicate advertisement warning"
            )}
            cancelButtonText={getResource(
                "Marketplace.advertisement.duplicateWarningDialog.cancelButton",
                "Cancel"
            )}
            buttonText={
                isEdit
                    ? getResource(
                          "Marketplace.advertisement.duplicateWarningDialog.updateButton",
                          "Update"
                      )
                    : getResource(
                          "Marketplace.advertisement.duplicateWarningDialog.createButton",
                          "Create"
                      )
            }
            handleAction={onAction}
            handleClose={onClose}
            open={open}
        >
            {!isEdit && (
                <ResourcedText
                    resourceKey="Marketplace.advertisement.add.duplicateWarningDialog.Label"
                    defaultValue="A {type} advertisement already exists. Are you sure you want to create another?"
                    tokens={{
                        type: getResource(
                            `Marketplace.advertisement.add.duplicateWarningDialog.type-${type}`,
                            type
                        ),
                    }}
                />
            )}

            {isEdit && (
                <ResourcedText
                    resourceKey="Marketplace.advertisement.edit.duplicateWarningDialog.Label"
                    defaultValue="A {type} advertisement already exists. Are you sure you want to change the type?"
                    tokens={{
                        type: getResource(
                            `Marketplace.advertisement.edit.duplicateWarningDialog.type-${type}`,
                            type
                        ),
                    }}
                />
            )}
        </Confirmation>
    );
});
