import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    AccordionRoot: {
        border: 0,

        "&:last-child": {
            marginBottom: "0 !important",
        },
    },

    AccordionSummary: {
        display: "flex",
        flexFlow: "row nowrap",
        padding: theme.spacing(1, 4, 1, 2),
        borderTop: `2px solid ${theme.palette.white}`,
        transition: "none",

        "& svg": {
            padding: 0,
        },

        "& dl": {
            display: "flex",
            flexFlow: "row nowrap",
            flexGrow: 1,
            width: 1 /** Somehow this works well with flexbox and ellipsis content **/,

            [theme.breakpoints.down(800)]: {
                flexWrap: "wrap",
            },

            "& div": {
                width: 165,
                minWidth: 0,
                flexShrink: 0,
                paddingLeft: theme.spacing(2),

                [theme.breakpoints.down(800)]: {
                    width: "100%",
                    marginBottom: theme.spacing(1),
                },

                "& dt, & dd": {
                    padding: 0,
                    margin: 0,
                    width: "auto",
                    maxWidth: "none",
                },

                "&:not(.--is-expanded)": {
                    "& dt, & dd": {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },

                "&.description": {
                    alignSelf: "center",
                    marginRight: "auto",
                    width: 1 /** Somehow this works well with flexbox and ellipsis content **/,
                    flexGrow: 1,
                    flexShrink: 1,

                    [theme.breakpoints.down(800)]: {
                        width: "100%",
                    },

                    "& dt": {
                        textTransform: "none",
                        fontSize: 14,
                        lineHeight: 1,
                        color: "inherit",
                        fontWeight: 500,
                    },

                    "& dd": {
                        fontWeight: "normal",
                        color: theme.palette.text.darkGray,
                    },
                },

                "&:nth-last-child(1), &:nth-last-child(2)": {
                    width: 95,

                    [theme.breakpoints.down(800)]: {
                        width: "100%",
                    },
                },
            },
        },
    },

    AccordionSummaryExpanded: {
        backgroundColor: theme.palette.panelGray,

        "& dt, & dd": {
            color: `${theme.palette.white} !important`,
        },
    },

    AccordionDetails: {
        display: "flex",
        flexFlow: "column nowrap",
        padding: 0,
    },

    icon: {
        position: "relative",
        alignSelf: "center",
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "50%",
        backgroundColor: theme.palette.panelGray,
        color: theme.palette.white,

        "&.expanded": {
            backgroundColor: theme.palette.white,
            color: theme.palette.panelGray,
        },
    },
}));

export default useStyles;
