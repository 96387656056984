import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { getAllforAsset } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import TableWidget from "../../../shared/tableWidget";
import useAuthorization from "../../../shared/authentication/useauthorization";
import useDialog from "../../../shared/usedialog";
import Purchase from "./purchase";
import { QuickScanIcon } from "../../../shared/customIcons";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";

const Widget = withResources(({ getResource, asset, history }) => {
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    const { data: assetQuickScans, loading, invoke } = useAPI(
        () => getAllforAsset(asset.id, 1, 4),
        true
    );
    const {
        open: handlePurchase,
        close: handleClosePurchase,
        visible: purchaseToggled,
    } = useDialog(invoke);

    const data =
        assetQuickScans &&
        assetQuickScans.items.map((quickScan) => ({
            __url: `/quick-scan/report/${quickScan.id}`,
            reference: quickScan.reference,
            purchasedDate: formatDate(quickScan.purchasedDate, selectedUiCulture, selectedTimezone),
        }));

    return (
        <React.Fragment>
            <TableWidget
                headerTitle={getResource("Valuation.assetQuickScan.widget.header", "Quick scans")}
                headerLink={`/quick-scan/asset/${asset.id}`}
                headerLinkTitle={getResource(
                    "Valuation.assetQuickScan.widget.chevronAlt",
                    `See all quick scans`
                )}
                clickProperty="__url"
                data={data}
                loading={loading}
                hasContent={data !== null && data.length > 0}
                icon={<QuickScanIcon />}
                noDataText={getResource(
                    "Valuation.assetQuickScan.widget.noDataText",
                    `There are no quick scans for this asset yet`
                )}
                addButtonText={getResource(
                    "Valuation.assetQuickScan.widget.noDataButtonText",
                    `Purchase`
                )}
                onAddButtonClick={handlePurchase}
                addButtonRequiredOperation={{
                    name: "asset-quick-scans-purchase",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                rightCellTextAlign={"right"}
            />

            <Purchase
                open={purchaseToggled}
                assetId={asset.id}
                onClose={handleClosePurchase}
                history={history}
            />
        </React.Fragment>
    );
});

export default ({ asset, history }) => {
    const { isLoading, isAuthorized } = useAuthorization({
        name: "asset-quick-scans-view",
        companyId: asset.companyId,
        assetCollectionId: asset.assetCollectionIds,
    });

    if (isLoading) {
        return null;
    }

    if (!isAuthorized) {
        return null;
    }

    return <Widget asset={asset} history={history} />;
};
