import React, { Fragment } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import useAPI from "../../../../shared/useapi";
import { getAll as getAllCountries } from "../../../library/countries/dataservice";
import { getReferenceStatuses } from "../../scheduledEvent/dataservice";

import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import withResources from "../../../../shared/textresources/withresources";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import DateTimePicker from "../../../../shared/datetimepicker";
import DatePicker from "../../../../shared/datepicker";
import FormHelperText from "@material-ui/core/FormHelperText";
import ResourcedText from "../../../../shared/textresources/resourcedtext";
import useStyles from "./useStyles";

const ScheduledEventEditor = withResources((props) => {
    const {
        getResource,
        onScheduledEventChanged,
        errors,
        scheduledEvent,
        hideSummaryAndDescription,
        disableLinkedEdit,
    } = props;
    const { data: allCountries } = useAPI(getAllCountries, true);
    const { data: referenceStatuses } = useAPI(getReferenceStatuses, true);
    const classes = useStyles();

    const handleChange = (e) => {
        if (!Array.isArray(e)) {
            e = [e];
        }

        const copy = { ...scheduledEvent };
        e.forEach((item) => {
            const value = item.target.value || item.target.checked;
            copy[item.target.name] = value;
        });

        onScheduledEventChanged(copy);
    };

    const setStartDate = (e) => {
        const copy = { ...scheduledEvent };
        copy[e.target.name] = e.target.value;

        const selection = new Date(e.target.value);
        const startDate = new Date(scheduledEvent.start);
        const endDate = new Date(scheduledEvent.end);

        const timeDiff = endDate.getTime() - startDate.getTime();
        copy["end"] = new Date(selection.getTime() + timeDiff + 1);

        onScheduledEventChanged(copy);
    };

    var endDateToShow = scheduledEvent.end;
    if (scheduledEvent.allDay) {
        endDateToShow = new Date(scheduledEvent.end);
        endDateToShow.setDate(endDateToShow.getDate() - 1);
    }

    const isLoaded = allCountries && referenceStatuses;

    return (
        isLoaded && (
            <Fragment>
                {!hideSummaryAndDescription && (
                    <ResourcedTextField
                        errors={errors}
                        margin="normal"
                        name="summary"
                        labelResourceKey="Planning.scheduledEvent.summaryField"
                        labelDefaultValue="Summary"
                        type="text"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 255 }}
                        value={scheduledEvent.summary || ""}
                        onChange={(e) => handleChange(e)}
                        disabled={disableLinkedEdit && scheduledEvent.parentType}
                        required
                    />
                )}

                {!hideSummaryAndDescription && (
                    <ResourcedTextField
                        errors={errors}
                        margin="normal"
                        name="description"
                        labelResourceKey="Planning.scheduledEvent.descriptionField"
                        labelDefaultValue="Description"
                        type="text"
                        variant="outlined"
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 500 }}
                        value={scheduledEvent.description || ""}
                        onChange={(e) => handleChange(e)}
                        disabled={disableLinkedEdit && scheduledEvent.parentType}
                    />
                )}
                {!hideSummaryAndDescription && disableLinkedEdit && scheduledEvent.parentType && (
                    <Alert severity="info">
                        {getResource(
                            `Planning.scheduledEvent.onlyEditOnParent.${scheduledEvent.parentType}`,
                            "You can only edit the summary and description through its parent."
                        )}
                    </Alert>
                )}

                <div className={classes.locationSwitchWrapper}>
                    <FormControlLabel
                        label={getResource("Planning.scheduledEvent.allDayField", "All day")}
                        variant="outlined"
                        control={
                            <Switch
                                checked={Boolean(scheduledEvent.allDay)}
                                onChange={(e) => {
                                    var diffInDays = e.target.checked ? 1 : -1;
                                    var newEndDate = new Date(scheduledEvent.end);

                                    newEndDate.setDate(newEndDate.getDate() + diffInDays);

                                    handleChange([
                                        e,
                                        {
                                            target: {
                                                name: "end",
                                                value: newEndDate,
                                            },
                                        },
                                    ]);
                                }}
                                name="allDay"
                            />
                        }
                    />

                    <FormHelperText className={classes.locationKnownHelperText}>
                        <ResourcedText
                            resourceKey="Planning.scheduledEvent.allDayHelperText"
                            defaultValue="Is the event a total day or only part of the day?"
                        />
                    </FormHelperText>
                </div>

                {!scheduledEvent.allDay && (
                    <Fragment>
                        <DateTimePicker
                            errors={errors}
                            name="start"
                            label={getResource("Planning.scheduledEvent.startLabel", "Start")}
                            value={scheduledEvent.start}
                            onChange={setStartDate}
                            disallowKeyboard
                            required
                        />

                        <DateTimePicker
                            errors={errors}
                            name="end"
                            label={getResource("Planning.scheduledEvent.endLabel", "End")}
                            value={scheduledEvent.end}
                            onChange={handleChange}
                            minDate={scheduledEvent.start}
                            disallowKeyboard
                            required
                        />
                    </Fragment>
                )}

                {scheduledEvent.allDay && (
                    <Fragment>
                        <DatePicker
                            errors={errors}
                            name="start"
                            label={getResource("Planning.scheduledEvent.startLabel", "Start")}
                            value={scheduledEvent.start}
                            onChange={setStartDate}
                            useTime={true}
                            disallowKeyboard
                            required
                        />

                        <DatePicker
                            errors={errors}
                            name="end"
                            label={getResource("Planning.scheduledEvent.endLabel", "End")}
                            value={endDateToShow}
                            onChange={(e) => {
                                var value = e.target.value;

                                value.setDate(value.getDate() + 1);

                                e.target.value = value;
                                handleChange(e);
                            }}
                            minDate={scheduledEvent.start}
                            useTime={true}
                            disallowKeyboard
                            required
                        />
                    </Fragment>
                )}

                <AutoCompleteStatic
                    errors={errors}
                    name="referenceStatus"
                    label={getResource("Planning.scheduledEvent.referenceStatusField", "Status")}
                    fullWidth
                    isMedium
                    value={scheduledEvent.referenceStatus}
                    onSelection={(e) => handleChange(e)}
                    options={referenceStatuses}
                    required
                />

                <div className={classes.locationSwitchWrapper}>
                    <FormControlLabel
                        label={getResource(
                            "Planning.scheduledEvent.exactLocationKnownField",
                            "Exact location known"
                        )}
                        variant="outlined"
                        control={
                            <Switch
                                checked={Boolean(scheduledEvent.exactLocationKnown)}
                                onChange={(e) => handleChange(e)}
                                name="exactLocationKnown"
                            />
                        }
                    />

                    <FormHelperText className={classes.locationKnownHelperText}>
                        <ResourcedText
                            resourceKey="Planning.scheduledEvent.locationKnownHelperText"
                            defaultValue="Where will the work be executed?"
                        />
                    </FormHelperText>
                </div>

                {scheduledEvent.exactLocationKnown && (
                    <Fragment>
                        <AutoCompleteStatic
                            errors={errors}
                            name="country"
                            label={getResource("Planning.scheduledEvent.countryField", "Country")}
                            fullWidth
                            isMedium
                            value={scheduledEvent.country}
                            onSelection={(e) => handleChange(e)}
                            options={allCountries.map((c) => ({ id: c.code, name: c.name }))}
                            disableClearable
                            required
                        />

                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="address"
                            labelResourceKey="Planning.scheduledEvent.addressField"
                            labelDefaultValue="Address"
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            value={scheduledEvent.address || ""}
                            onChange={(e) => handleChange(e)}
                            required
                        />

                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="postalCode"
                            labelResourceKey="Planning.scheduledEvent.postalCodeField"
                            labelDefaultValue="Postal code"
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            value={scheduledEvent.postalCode || ""}
                            onChange={(e) => handleChange(e)}
                        />

                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="city"
                            labelResourceKey="Planning.scheduledEvent.cityField"
                            labelDefaultValue="City"
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            value={scheduledEvent.city || ""}
                            onChange={(e) => handleChange(e)}
                            required
                        />

                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="state"
                            labelResourceKey="Planning.scheduledEvent.stateField"
                            labelDefaultValue="State"
                            type="text"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            value={scheduledEvent.state || ""}
                            onChange={(e) => handleChange(e)}
                        />
                    </Fragment>
                )}

                {!scheduledEvent.exactLocationKnown && (
                    <Fragment>
                        <ResourcedTextField
                            errors={errors}
                            margin="normal"
                            name="approximateLocation"
                            labelResourceKey="Planning.scheduledEvent.approximateLocationField"
                            labelDefaultValue="Approximate Location"
                            type="text"
                            variant="outlined"
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            value={scheduledEvent.approximateLocation || ""}
                            onChange={(e) => handleChange(e)}
                        />
                    </Fragment>
                )}
            </Fragment>
        )
    );
});

export default ScheduledEventEditor;

export function validate(scheduledEvent, name, errors, getResource, hideSummaryAndDescription) {
    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    };

    const setError = (field, message) => {
        if (!errors[name]) {
            errors[name] = {};
        }
        errors[name][field] = message;
    };

    if (!hideSummaryAndDescription && !scheduledEvent.summary) {
        setError(
            "summary",
            getResource("Planning.scheduledEvent.summaryRequired", "Please provide a summary")
        );
    }

    if (!scheduledEvent.start || !isValidDate(new Date(scheduledEvent.start))) {
        setError(
            "start",
            getResource("Planning.scheduledEvent.startRequired", "Please provide a start date/time")
        );
    }

    if (!scheduledEvent.end || !isValidDate(new Date(scheduledEvent.end))) {
        setError(
            "end",
            getResource("Planning.scheduledEvent.endRequired", "Please provide an end date/time")
        );
    }

    if (
        scheduledEvent.start &&
        scheduledEvent.end &&
        new Date(scheduledEvent.end) <= new Date(scheduledEvent.start)
    ) {
        setError(
            "end",
            getResource(
                "Planning.scheduledEvent.endAfterStart",
                "The end date needs to be after the start date"
            )
        );
    }

    if (!scheduledEvent.referenceStatus) {
        setError(
            "referenceStatus",
            getResource(
                "Planning.scheduledEvent.referenceStatusRequired",
                "Please provide a status"
            )
        );
    }

    if (scheduledEvent.exactLocationKnown) {
        if (!scheduledEvent.country) {
            setError(
                "country",
                getResource("Planning.scheduledEvent.countryRequired", "Please provide a country")
            );
        }

        if (!scheduledEvent.address) {
            setError(
                "address",
                getResource("Planning.scheduledEvent.addressRequired", "Please provide an address")
            );
        }

        if (!scheduledEvent.city) {
            setError(
                "city",
                getResource("Planning.scheduledEvent.cityRequired", "Please provide a city")
            );
        }
    }
}

export function correctTimesOnSubmit(scheduledEvent, timezone) {
    const stripTime = (zonedDate) => {
        var date = utcToZonedTime(zonedDate, timezone);
        date = new Date(date.setHours(0, 0, 0, 0));
        return zonedTimeToUtc(date, timezone);
    };

    if (scheduledEvent.allDay) {
        scheduledEvent.start = stripTime(scheduledEvent.start);
        scheduledEvent.end = stripTime(scheduledEvent.end);
    }

    return scheduledEvent;
}
