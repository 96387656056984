import React, { useContext } from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import { formatDate } from "../../../shared/formattedDate";

export default withResources(({ getResource, open, onClose, id, reportDate }) => {
    const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);

    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(id)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleDelete}
            handleClose={onClose}
            headerText={getResource(
                "Valuation.financialReport.delete.confirmationHeader",
                "Delete financial report?"
            )}
            buttonText={getResource(
                "Valuation.financialReport.delete.confirmationButton",
                "Delete"
            )}
        >
            {getResource(
                "Valuation.financialReport.delete.deleteMessage",
                "Financial report of {reportDate} will be deleted.",
                { reportDate: formatDate(reportDate, selectedUiCulture, selectedTimezone) }
            )}
        </Confirmation>
    );
});
