import React, { useContext } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Check as PublishedIcon,
    Clear as UnpublishedIcon,
} from "@material-ui/icons";

import _ from "underscore";

import LocalizationContext from "../../../shared/localization/localizationcontext";
import ActionMenu from "../../../shared/actionmenu";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Search from "../../marketplace/advertisements/search";
import PagedList from "../../../shared/pagedList";
import AuthorizedButton from "../../../shared/authentication/authorizedbutton";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";

const Overview = withResources(
    ({
        getResource,
        asset,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onSearch,
        onAdd,
        onEdit,
        onDelete,
        defaultDebounceInterval,
    }) => {
        data = data || {};
        const items = data.items;

        const onSearchDebounced = _.debounce(onSearch, defaultDebounceInterval);

        const { selectedCulture, defaultCulture } = useContext(LocalizationContext);
        var assetName =
            (asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`;

        return (
            <React.Fragment>
                <ContentHolder>
                    <Header
                        title={getResource(
                            "Marketplace.assetAdvertisements.header.title",
                            "Advertisements for {assetName}",
                            { assetName }
                        )}
                        renderActions={() => {
                            return (
                                <AuthorizedButton
                                    color="primary"
                                    variant="contained"
                                    onClick={onAdd}
                                    requiredOperation="advertisements-create"
                                    buttonText={getResource(
                                        "Marketplace.assetAdvertisements.newAdvertisementButton",
                                        "Create"
                                    )}
                                />
                            );
                        }}
                    />
                    <PagedList
                        loading={loading}
                        itemCount={data.total}
                        noResultsText={getResource(
                            "Marketplace.assetAdvertisements.noResults",
                            "No advertisements available for this asset"
                        )}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        availablePageSizes={availablePageSizes}
                        page={searchParams.page}
                        pageSize={searchParams.pageSize}
                        renderSearch={() => (
                            <Search onSearch={onSearchDebounced} searchParams={searchParams} />
                        )}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <ResourcedText
                                            resourceKey="Marketplace.advertisements.overview.publishedHeader"
                                            defaultValue="Published"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.advertisements.overview.typeHeader"
                                            defaultValue="Type"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ResourcedText
                                            resourceKey="Marketplace.advertisements.overview.titleHeader"
                                            defaultValue="Title"
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ResourcedText
                                            resourceKey="Marketplace.advertisements.overview.actionsHeader"
                                            defaultValue="Actions"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items &&
                                    items.map((row) => {
                                        const assetName =
                                            row.assetName ||
                                            `${row.manufacturerName} ${row.assetModelName}`;

                                        return (
                                            <AuthorizedTableRow
                                                id={row.id}
                                                hover
                                                key={row.id}
                                                handleClick={() => onEdit(row.id)}
                                                requiredOperation="advertisements-update"
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {row.isPublished ? (
                                                        <PublishedIcon color="secondary" />
                                                    ) : (
                                                        <UnpublishedIcon color="error" />
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {getResource(
                                                        `Marketplace.advertisement.type.${row.type.toLowerCase()}`,
                                                        row.type
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ActionMenu
                                                        actions={[
                                                            {
                                                                text: getResource(
                                                                    "Marketplace.assetAdvertisements.editAdvertisementButton",
                                                                    "Edit"
                                                                ),
                                                                icon: <EditIcon />,
                                                                itemId: row.id,
                                                                requiredOperation:
                                                                    "advertisements-edit",
                                                                onClick: () => onEdit(row.id),
                                                            },
                                                            {
                                                                text: getResource(
                                                                    "Marketplace.assetAdvertisements.deleteAdvertisementButton",
                                                                    "Delete"
                                                                ),
                                                                icon: <DeleteIcon />,
                                                                itemId: row.id,
                                                                requiredOperation:
                                                                    "advertisements-delete",
                                                                onClick: () =>
                                                                    onDelete({
                                                                        id: row.id,
                                                                        assetName: assetName,
                                                                        version: row.version,
                                                                    }),
                                                            },
                                                        ]}
                                                    />
                                                </TableCell>
                                            </AuthorizedTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </PagedList>
                </ContentHolder>
            </React.Fragment>
        );
    }
);

export default Overview;
