import React from "react";
import Security from "@material-ui/icons/Security";

import { Section } from "../shell";

import Applications from "./applications";
import Users from "./users";
import Operations from "./operations";
import Roles from "./roles";
import Permissions from "./permissions";
import Companies from "./companies";
import Profile from "./profile";
import withResources from "../../shared/textresources/withresources";

export default withResources(({ getResource }) => {
    const securityChildren = [
        {
            title: getResource("Security.applicationsTitle", "Applications"),
            href: "/security/applications",
            render: Applications,
            requiredOperation: "applications-view",
        },
        {
            title: getResource("Security.usersTitle", "Users"),
            href: "/security/users",
            render: Users,
            requiredOperation: "users-view",
        },
        {
            title: getResource("Security.operationsTitle", "Operations"),
            href: "/security/operations",
            render: Operations,
            requiredOperation: "operations-view",
        },
        {
            title: getResource("Security.rolesTitle", "Roles"),
            href: "/security/roles",
            render: Roles,
            requiredOperation: "roles-view",
        },
        {
            title: getResource("Security.permissionsTitle", "Permissions"),
            href: "/security/permissions",
            render: Permissions,
            requiredOperation: "user-roles-assign",
        },
        {
            title: getResource("Security.companiesTitle", "Companies"),
            href: "/security/companies",
            render: Companies,
            requiredOperation: "companies-view",
        },
    ];

    const allUsersChildren = [
        {
            title: getResource("Security.profileTitle", "Profile"),
            href: "/profile",
            render: Profile,
            hideInMenu: true,
        },
    ];

    return [
        <Section
            key="Security"
            title={getResource("Security.title", "Security")}
            icon={Security}
            href="/security"
            children={securityChildren}
        />,
        <Section
            key="AllUsers"
            title={getResource("AllUsers.title", "")}
            href=""
            children={allUsersChildren}
            hideInMenu
        />,
    ];
});
