import React, { useState, Fragment } from "react";
import withResources from "../../../shared/textresources/withresources";
import LocalizedForm from "../../../shared/localization/localizedform";
import useStyles from "./useStyles";
import JobSheetRows from "./jobSheetRows";
import Comments from "./comments";
import SignOff from "./signOff";
import Totals from "./totals";
import { Slot } from "../../../shared/slotmachine";
import WorkOrderHeader from "../shared/workOrderHeader";
import ContentHolder from "../../../shared/contentHolder";
import Confirmation from "../../../shared/confirmation";
import WorkOrderDetail from "../workOrder/detail";
import WorkOrderTaskDetail from "../workOrderTask/detail";
import useAPI from "../../../shared/useapi";
import { processJobSheet } from "../workOrderTask/dataservice";
import IntroBlock from "../shared/introBlock";

const Detail = withResources(({ getResource, workOrder, task, onSave, history }) => {
    const classes = useStyles();

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const emptyLaborRow = {
        description: "",
        hours: null,
        hourlyRate: null,
        taskHourlyRate: task.hourlyRate,
        allowRateOverride: task.allowRateOverride,
    };

    const emptyMaterialsRow = {
        description: "",
        quantity: null,
        pricePerUnit: null,
    };

    const { loading, invoke } = useAPI((values) => processJobSheet(workOrder.id, task.id, values));

    const getInvalidMaterialRows = (rows) => {
        const invalidRows = [];

        rows.forEach((row, index) => {
            const rowCopy = { ...row };
            delete rowCopy.hourlyRate;
            delete rowCopy.taskHourlyRate;
            delete rowCopy.allowRateOverride;

            const rowValuesArray = Object.values(rowCopy);
            const allEmpty = rowValuesArray.every((value) => !value);
            const allFilled = rowValuesArray.every((value) => Boolean(value));

            if (!allEmpty && !allFilled) {
                invalidRows.push(index);
            }
        });

        return invalidRows;
    };

    const getInvalidLaborRows = (rows) => {
        const invalidRows = [];

        rows.forEach((row, index) => {
            const rowCopy = { ...row };
            delete rowCopy.hourlyRate;
            delete rowCopy.taskHourlyRate;
            delete rowCopy.allowRateOverride;

            const rowValuesArray = Object.values(rowCopy);
            if ((row.hourlyRate || row.hours) && !row.description) {
                invalidRows.push(index);
            }
        });

        return invalidRows;
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        const invalidLaborRows = getInvalidLaborRows(values.labor);
        if (invalidLaborRows.length > 0) {
            errors.labor = {
                message: getResource(
                    "Maintenance.workOrder.jobSheet.invalidJobSheetLaborRow",
                    "Description is required when hours and/or rate is filled"
                ),
                invalidRows: invalidLaborRows,
            };
        }

        const invalidMaterialsRows = getInvalidMaterialRows(values.materials);
        if (invalidMaterialsRows.length > 0) {
            errors.materials = {
                message: getResource(
                    "Maintenance.workOrder.jobSheet.invalidJobSheetMaterialRow",
                    "This row is not filled in completely (or left completely empty)"
                ),
                invalidRows: invalidMaterialsRows,
            };
        }

        return errors;
    };

    const onSubmit = async (values) => {
        var copy = {
            ...values.values,
            labor: values.values.labor.filter((labor) => labor.description),
            materials: values.values.materials.filter(
                (material) => material.description && material.quantity && material.pricePerUnit
            ),
        };

        if (await invoke(copy)) {
            if (copy.completed) {
                history.push(`/maintenance/work-orders/${workOrder.id}/tasks`);
            } else {
                setConfirmationOpen(true);
                onSave();
            }
        }
    };

    const getDescriptionPlaceholder = (type) => {
        if (type === "labor") {
            return task.status === "Completed"
                ? ""
                : getResource(
                      "Maintenance.workOrder.jobSheetRow.laborDescription.placeholder",
                      "Your work description"
                  );
        } else if (type === "materials") {
            return task.status === "Completed"
                ? ""
                : getResource(
                      "Maintenance.workOrder.jobSheetRow.materialDescription.placeholder",
                      "What material did you use?"
                  );
        }
    };

    const getHourlyRatePlaceholder = (row) => {
        return task.status === "Completed"
            ? ""
            : typeof row.taskHourlyRate === "number"
            ? row.taskHourlyRate.toString()
            : "";
    };

    return (
        <ContentHolder>
            <WorkOrderHeader
                status={task.status}
                title={getResource(
                    "Maintenance.workOrder.jobSheet.title",
                    "Job sheet for task {name}",
                    { name: task.isDefaultTask ? workOrder.summary : task.summary }
                )}
            />

            <IntroBlock summary={workOrder.summary} description={workOrder.description} />

            <div className={classes.container}>
                <div className={classes.mainArea}>
                    <WorkOrderDetail workOrder={workOrder} />

                    <WorkOrderTaskDetail task={task} />

                    <LocalizedForm
                        initialValues={task}
                        loading={loading}
                        onSubmit={onSubmit}
                        onValidate={onValidate}
                        hideOkButton
                        renderForm={(_, values, handleChange, errors) => (
                            <Fragment>
                                <JobSheetRows
                                    currency={task.currencyShortName}
                                    variant="labor"
                                    title={getResource(
                                        "Maintenance.workOrder.jobSheet.laborTitle",
                                        "Labor"
                                    )}
                                    errors={errors}
                                    rowsData={values.labor}
                                    emptyRow={emptyLaborRow}
                                    handleChange={handleChange}
                                    disabled={task.status === "Completed"}
                                    descriptionPlaceHolder={getDescriptionPlaceholder("labor")}
                                    getHourlyRatePlaceholder={getHourlyRatePlaceholder}
                                />

                                <JobSheetRows
                                    currency={task.currencyShortName}
                                    variant="materials"
                                    title={getResource(
                                        "Maintenance.workOrder.jobSheet.materialsTitle",
                                        "Materials"
                                    )}
                                    errors={errors}
                                    rowsData={values.materials}
                                    emptyRow={emptyMaterialsRow}
                                    handleChange={handleChange}
                                    disabled={task.status === "Completed"}
                                    descriptionPlaceHolder={getDescriptionPlaceholder("materials")}
                                />

                                <div className={classes.mainAreaDouble}>
                                    <Comments
                                        task={values}
                                        handleChange={handleChange}
                                        disabled={task.status === "Completed"}
                                        errors={errors}
                                    />

                                    <Totals currency={task.currencyShortName} task={values} />
                                </div>

                                <div className={classes.mainAreaSignOff}>
                                    <SignOff
                                        task={values}
                                        handleChange={handleChange}
                                        disabled={task.status === "Completed"}
                                        errors={errors}
                                    />
                                </div>
                            </Fragment>
                        )}
                    />
                </div>

                <div className={classes.sideBar}>
                    <Slot name="work-order-media" workOrder={workOrder} />
                    <Slot name="work-order-documents" workOrder={workOrder} />
                </div>
            </div>

            <Confirmation
                open={confirmationOpen}
                cancelHidden
                handleAction={() => setConfirmationOpen(false)}
                headerText={getResource(
                    "Maintenance.workOrder.jobSheet.confirmation.header",
                    "Job sheet saved"
                )}
                buttonText={getResource(
                    "Maintenance.workOrder.jobSheet.confirmation.buttonText",
                    "Ok"
                )}
                loading={loading}
            >
                {getResource(
                    "Maintenance.workOrder.jobSheet.confirmation.content",
                    "All changes made to the job sheet are saved successful"
                )}
            </Confirmation>
        </ContentHolder>
    );
});

export default Detail;
