import React from "react";
import { LinearProgress } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";

import useAPI from "../../../shared/useapi";
import { getAll, get, update } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";

export default withResources(({ getResource, open, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(id), true, [id]);
    const { data: operations } = useAPI(() => getAll(null, true, null, null), true);

    const { loading, invoke } = useAPI((values) => update(id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        return errors;
    };

    var isLoaded = data && operations;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.operations.edit.title", "Edit operation")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.operations.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(culture, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Security.operations.edit.nameField"
                                    labelDefaultValue="Name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.name}
                                    disabled
                                />

                                <ResourcedTextField
                                    margin="normal"
                                    name="identifier"
                                    labelResourceKey="Security.operations.edit.identifierField"
                                    labelDefaultValue="Identifier"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={values.identifier}
                                    disabled
                                />

                                <AutoCompleteStatic
                                    autoFocus
                                    errors={errors}
                                    name="secondaryOperations"
                                    label={getResource(
                                        "Security.operations.edit.secondaryOperationsField",
                                        "Secondary permissions"
                                    )}
                                    value={values.secondaryOperations}
                                    multiple={true}
                                    options={operations.items}
                                    onSelection={(e) => handleChange(e)}
                                    fullWidth
                                    isMedium
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
