import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll() {
    return await interceptedFetch("/api/library/assetcategory/all");
}

async function filter(name, noDescendantOf, page, pageSize, onlyWithTrends) {
    const params = {
        page,
        pageSize,
        name,
        noDescendantOf,
        onlyWithTrends,
    };

    return await interceptedFetch(
        `/api/library/assetcategory?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function getChildren(parentId, onlyWithTrends) {
    const params = { parentId, onlyWithTrends };

    return await interceptedFetch(
        `/api/library/assetcategory/children?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function getParents(id, onlyWithTrends) {
    const params = { id, onlyWithTrends };
    return await interceptedFetch(
        `/api/library/assetcategory/parents?${queryString.stringify(params, {
            skipNull: true,
            skipEmptyString: true,
        })}`
    );
}

async function add(values) {
    return await interceptedFetch("/api/library/assetcategory", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function del(selectedId, version) {
    return await interceptedFetch(`/api/library/assetcategory/${selectedId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign({ version })),
    });
}

async function get(id) {
    const url = `/api/library/assetcategory/${id}`;

    return await interceptedFetch(url);
}

async function getIsReferenced(id) {
    const url = `/api/library/assetcategory/${id}/isReferenced`;

    return await interceptedFetch(url);
}

async function update(id, values) {
    return await interceptedFetch(`/api/library/assetcategory/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function move(id, newParentId, version) {
    return await interceptedFetch(`/api/library/assetcategory/${id}/move`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            newParentId,
            version,
        }),
    });
}

async function mergeAndDelete(sourceId, values) {
    return await interceptedFetch(`/api/library/assetcategory/${sourceId}/merge`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values)),
    });
}

async function getPath(id) {
    const url = `/api/library/assetcategory/${id}/path`;

    return await interceptedFetch(url);
}

export {
    getAll,
    filter,
    getChildren,
    getParents,
    add,
    del,
    get,
    getIsReferenced,
    update,
    move,
    mergeAndDelete,
    getPath,
};
