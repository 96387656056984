import React, { useContext, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Overview from "./overview";
import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import Reopen from "./reopen";
import { getAll } from "./dataservice";
import { get as getWorkOrder } from "../workOrder/dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useDialog from "../../../shared/usedialog";
import NotFound from "../../../shared/notFound";

const TaskOverview = (props) => {
    var workOrderId = props.match.params["workOrderId"];

    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-work-order-task", {
        page: 0,
        pageSize: defaultPageSize,
    });

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    const { data: workOrder, loading: workOrderLoading } = useAPI(
        () => getWorkOrder(workOrderId),
        true,
        [workOrderId]
    );

    const { loading, invoke, data } = useAPI(
        () => getAll(workOrderId, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams, workOrderId]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleReopen,
        close: handleCloseReopen,
        visible: reopenToggled,
        args: reopenArgs,
    } = useDialog(invoke);

    function getRequiredOperation(operation) {
        if (!workOrder) {
            return operation;
        }

        return {
            name: operation,
            companyId: workOrder.companyId,
            assetCollectionId: workOrder.assetCollectionIds,
        };
    }

    return (
        <Fragment>
            {workOrderLoading && !workOrder && <LinearProgress />}
            {!workOrderLoading && !workOrder && <NotFound element="work order" />}
            {workOrder && (
                <Fragment>
                    <Overview
                        data={data}
                        workOrder={workOrder}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onReopen={handleReopen}
                        onDelete={handleDelete}
                        defaultDebounceInterval={defaultDebounceInterval}
                        getRequiredOperation={getRequiredOperation}
                        {...props}
                    />

                    <Add
                        open={addToggled}
                        onClose={handleCloseAdd}
                        workOrderId={workOrderId}
                        companyId={workOrder.companyId}
                        currencyCode={workOrder.currencyShortName}
                    />

                    <Edit
                        open={editToggled}
                        onClose={handleCloseEdit}
                        id={editId}
                        workOrderId={workOrderId}
                        companyId={workOrder.companyId}
                        currencyCode={workOrder.currencyShortName}
                    />

                    <Delete
                        workOrderId={workOrderId}
                        taskId={deleteArgs && deleteArgs.id}
                        summary={deleteArgs && deleteArgs.summary}
                        open={deleteToggled}
                        onClose={handleCloseDelete}
                    />

                    <Reopen
                        taskId={reopenArgs && reopenArgs.id}
                        summary={reopenArgs && reopenArgs.summary}
                        open={reopenToggled}
                        onClose={handleCloseReopen}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default () => {
    return (
        <PrivateRoute
            exact
            requiredOperation={"work-orders-task-view"}
            path="/maintenance/work-orders/:workOrderId(\d+)/tasks"
            render={(props) => <TaskOverview {...props} />}
        />
    );
};
