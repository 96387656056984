import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import {
    Close as CloseIcon,
    ArrowForward as StartIcon,
    Launch as ContinueIcon,
} from "@material-ui/icons";

import ActionMenu from "../../../shared/actionmenu";
import PagedList from "../../../shared/pagedList";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import AuthorizedTableRow from "../../../shared/authentication/authorizedtablerow";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import FormattedDate from "../../../shared/formattedDate";

const Overview = withResources(
    ({
        getResource,
        data,
        searchParams,
        availablePageSizes,
        loading,
        onPageChange,
        onPageSizeChange,
        onStart,
        onClose,
        onEdit,
    }) => {
        data = data || {};
        const items = data.items;

        return (
            <ContentHolder>
                <Header
                    title={getResource(
                        "Valuation.valuationRequests.header.title",
                        "Valuation requests"
                    )}
                />
                <PagedList
                    loading={loading}
                    itemCount={data.total}
                    noResultsText={getResource(
                        "Valuation.valuationRequests.noResults",
                        "No valuation requests available"
                    )}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    availablePageSizes={availablePageSizes}
                    page={searchParams.page}
                    pageSize={searchParams.pageSize}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.valuationRequests.overview.dateRequestedHeader"
                                        defaultValue="Date requested"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.valuationRequests.overview.assetAssetModelHeader"
                                        defaultValue="Asset / Model"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.valuationRequests.overview.requestedByHeader"
                                        defaultValue="Requested by"
                                    />
                                </TableCell>
                                <TableCell>
                                    <ResourcedText
                                        resourceKey="Valuation.valuationRequests.overview.reasonHeader"
                                        defaultValue="Reason(s)"
                                    />
                                </TableCell>

                                <TableCell align="right">
                                    <ResourcedText
                                        resourceKey="Valuation.valuationRequests.overview.actionsHeader"
                                        defaultValue="Actions"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items &&
                                items.map((row) => {
                                    var actions = [
                                        {
                                            text: getResource(
                                                "Valuation.valuationRequests.overview.startAssetValuationButton",
                                                "Start asset valuation"
                                            ),
                                            icon: <StartIcon />,
                                            itemId: row.id,
                                            requiredOperation: "asset-valuations-create",
                                            onClick: () =>
                                                onStart({
                                                    assetId: row.assetId,
                                                    technicalLifeNeeded: row.technicalLifeNeeded,
                                                    valuationRequestId: row.id,
                                                }),
                                            hide: !Boolean(row.assetId),
                                        },
                                        {
                                            text: getResource(
                                                "Valuation.valuationRequests.overview.startAssetModelValuationButton",
                                                "Start model valuation"
                                            ),
                                            icon: <StartIcon />,
                                            itemId: row.id,
                                            requiredOperation: "model-valuations-create",
                                            onClick: () =>
                                                onStart({
                                                    assetModelId: row.assetModelId,
                                                    technicalLifeNeeded: row.technicalLifeNeeded,
                                                    valuationRequestId: row.id,
                                                }),
                                        },
                                        {
                                            text: getResource(
                                                "Valuation.valuationRequests.overview.continueButton",
                                                "Continue"
                                            ),
                                            icon: <ContinueIcon />,
                                            itemId: row.id,
                                            requiredOperation: "model-valuations-create",
                                            onClick: () =>
                                                onEdit(
                                                    row.draftValuationId,
                                                    row.assetModelId,
                                                    row.assetId
                                                ),
                                            hide: !Boolean(row.draftValuationId),
                                        },
                                        {
                                            text: getResource(
                                                "Valuation.valuationRequests.overview.closeButton",
                                                "Close"
                                            ),
                                            icon: <CloseIcon />,
                                            itemId: row.id,
                                            requiredOperation: "valuation-requests-close",
                                            onClick: () => onClose(row.id),
                                        },
                                    ];

                                    return (
                                        <AuthorizedTableRow
                                            id={row.id}
                                            hover
                                            key={row.id}
                                            handleClick={() => {} /*TODO*/}
                                            requiredOperation="model-valuations-create"
                                        >
                                            <TableCell component="th" scope="row">
                                                <FormattedDate date={row.dateRequested} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.assetName ||
                                                    `${row.manufacturerName} / ${row.assetModelName}`}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.requestedBy}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.reasons.length === 1 && (
                                                    <span>{row.reasons[0]}</span>
                                                )}
                                                {row.reasons.length > 1 && (
                                                    <ul>
                                                        {row.reasons.map((x, i) => (
                                                            <li key={i}>{x}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionMenu actions={actions} />
                                            </TableCell>
                                        </AuthorizedTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </PagedList>
            </ContentHolder>
        );
    }
);

export default Overview;
