import React, { useContext } from "react";

import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";

import Overview from "./overview";
import Add from "./add";
import Edit from "./edit";
import Delete from "./delete";
import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-makrteplace-page", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
    });

    const { loading, invoke, data } = useAPI(
        () => getAll(searchParams.searchText, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);

    const handleSearch = (searchText) => {
        setSearchParams({ ...searchParams, searchText, page: 0 });
    };

    const handlePageChange = (page) => {
        setSearchParams({ ...searchParams, page });
    };

    const handlePageSizeChange = (pageSize) => {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    };

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="marketplace-pages-view"
                path="/marketplace/marketplacePages"
                render={(props) => (
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        defaultDebounceInterval={defaultDebounceInterval}
                        {...props}
                    />
                )}
            />
            <Add open={addToggled} onClose={handleCloseAdd} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
            <Delete
                id={deleteArgs && deleteArgs.id}
                open={deleteToggled}
                onClose={handleCloseDelete}
                title={deleteArgs && deleteArgs.title}
            />
        </React.Fragment>
    );
};
