import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import FormattedCurrency from "../../../shared/formattedCurrency";
import Widget from "../../../shared/widget";
import ShowMoreText from "react-show-more-text";
import useDialog from "../../../shared/usedialog";
import FinancialDetails from "./edit";
import {
    EuroSymbol,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import FormattedDate from "../../../shared/formattedDate";

const useStyles = makeStyles((theme) => ({
    dl: {
        "& dt": {
            marginBottom: 0,
        },

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",

            "&:not(.--summary-has-toggle):last-of-type": {
                marginBottom: 0,
            },
        },
    },

    noteText: {
        letterSpacing: 0,
    },

    noteTextToggle: {
        display: "inline-flex",
        alignItems: "center",
        fontWeight: "normal",
    },
}));

const FinancialDetailsWidget = withResources(
    ({ getResource, loading, asset, onEditFinancialDetails, data, hideActions }) => {
        const classes = useStyles();

        if (!data) {
            return null;
        }

        const hasContent =
            data.purchaseDate ||
            data.purchasePrice ||
            data.remainingValue ||
            data.depreciationPeriod ||
            data.liquidationCost ||
            data.note;

        return (
            <Widget
                requiredOperation={{
                    name: "assets-view-financial",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                headerTitle={getResource(
                    "Assets.asset.financialDetails.financialDetailsWidget.headerTitle",
                    "Financial information"
                )}
                loading={loading}
                hasContent={hasContent}
                icon={<EuroSymbol />}
                noDataText={getResource(
                    "Assets.asset.financialDetails.financialDetailsWidget.noDataText",
                    "No financial information available"
                )}
                addButtonText={
                    hasContent
                        ? getResource(
                              "Assets.asset.financialDetails.financialDetailsWidget.editButtonText",
                              "Edit"
                          )
                        : getResource(
                              "Assets.asset.financialDetails.financialDetailsWidget.noDataButtonText",
                              "Add"
                          )
                }
                onAddButtonClick={!hideActions ? () => onEditFinancialDetails(asset.id) : null}
                addButtonRequiredOperation={{
                    name: "assets-update-financial",
                    companyId: asset.companyId,
                    assetCollectionId: asset.assetCollectionIds,
                }}
                renderContent={() => {
                    return (
                        <dl className={classes.dl}>
                            {data.purchaseDate && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.purchaseDateField"
                                            defaultValue="Purchase date"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedDate
                                            long
                                            includeWeekDay
                                            date={data.purchaseDate}
                                        />
                                    </dd>
                                </Fragment>
                            )}
                            {data.purchasePrice && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.purchasePriceField"
                                            defaultValue="Purchase price"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={data.purchasePrice}
                                            currency={data.currencyShortName}
                                            decimals={0}
                                        />
                                    </dd>
                                </Fragment>
                            )}
                            {data.remainingValue && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.remainingValueField"
                                            defaultValue="Remaining value"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={data.remainingValue}
                                            currency={data.currencyShortName}
                                            decimals={0}
                                        />
                                    </dd>
                                </Fragment>
                            )}
                            {data.depreciationPeriod && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.depreciationPeriodField"
                                            defaultValue="Deprecation period"
                                        />
                                    </dt>
                                    <dd>
                                        {getResource(
                                            "Assets.asset.financialDetails.financialDetailsWidget.depreciationPeriodValue",
                                            "{months} months",
                                            { months: data.depreciationPeriod }
                                        )}
                                    </dd>
                                </Fragment>
                            )}
                            {data.liquidationCost && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.liquidationCostField"
                                            defaultValue="Liquidation cost"
                                        />
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={data.liquidationCost}
                                            currency={data.currencyShortName}
                                            decimals={0}
                                        />
                                    </dd>
                                </Fragment>
                            )}
                            {data.note && (
                                <Fragment>
                                    <dt>
                                        <ResourcedText
                                            resourceKey="Assets.asset.financialDetails.financialDetailsWidget.noteField"
                                            defaultValue="Note"
                                        />
                                    </dt>
                                    <dd>
                                        <div className={classes.noteText}>
                                            <ShowMoreText
                                                lines={3}
                                                more={
                                                    <Fragment>
                                                        {getResource(
                                                            "Assets.asset.financialDetails.financialDetailsWidget.showMore",
                                                            "more"
                                                        )}
                                                        <ExpandMoreIcon fontSize="small" />
                                                    </Fragment>
                                                }
                                                less={
                                                    <Fragment>
                                                        {getResource(
                                                            "Assets.asset.financialDetails.financialDetailsWidget.showLess",
                                                            "less"
                                                        )}
                                                        <ExpandLessIcon fontSize="small" />
                                                    </Fragment>
                                                }
                                                expanded={false}
                                                anchorClass={classes.noteTextToggle}
                                            >
                                                {data.note}
                                            </ShowMoreText>
                                        </div>
                                    </dd>
                                </Fragment>
                            )}
                        </dl>
                    );
                }}
            />
        );
    }
);

export default ({ onRefresh, ...props }) => {
    const {
        open: handleFinancialDetails,
        close: handleCloseFinancialDetails,
        visible: financialDetailsToggled,
        args: financialDetailsId,
    } = useDialog(onRefresh);

    return (
        <Fragment>
            <FinancialDetailsWidget onEditFinancialDetails={handleFinancialDetails} {...props} />
            <FinancialDetails
                open={financialDetailsToggled}
                id={financialDetailsId}
                onClose={handleCloseFinancialDetails}
            />
        </Fragment>
    );
};
