import React, { Fragment, useContext, useState } from "react";
import { FormControlLabel, LinearProgress, Switch, Tooltip } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import FullCalendar from "../../../shared/fullCalendar";
import allLocales from "@fullcalendar/core/locales-all";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { addDays, addHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useHistory } from "react-router-dom";

import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import ReferenceContext from "../../../shared/reference/referenceContext";
import WorkingContext from "../../security/workingContext";
import withResources from "../../../shared/textresources/withresources";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import Confirmation from "../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import PredictedEventActions from "./components/predictedEventActions";
import BackgroundEventActions from "./components/backgroundEventActions";
import AddWorkOrder from "../../maintenance/workOrder/add";
import useDialog from "../../../shared/usedialog";
import useStyles from "./useStyles";
import useLocalStorage from "../../../shared/uselocalstorage";
import ActionMenu from "../../../shared/actionmenu";

import useAPI from "../../../shared/useapi";
import { move } from "../scheduledEvent/dataservice";
import { getReasons, getStatuses } from "../../maintenance/workOrder/dataservice";
import clsx from "clsx";

const Overview = withResources(
    ({
        getResource,
        timelineRef,
        resources,
        loadEvents,
        loading,
        reference,
        referenceType,
        onAdd,
        onEdit,
        assetId,
        assetReference,
        onReload,
    }) => {
        const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);
        const { getEntityName, getEntityBreadcrumb } = useContext(ReferenceContext);

        const [moveParameters, setMoveParameters] = useState({ open: false });
        const [eventsLoading, setEventsLoading] = useState(false);
        const [selectedPredictedEvent, setSelectedPredictedEvent] = useState(null);
        const [currentView, setCurrentView] = useState("resourceTimelineMonth");
        const [backgroundEventDialog, setBackgroundEventDialog] = useState(null);
        const [showAllResources, setShowAllResources] = useLocalStorage(
            "timeline-showAllResources",
            true
        );

        const classes = useStyles();

        const history = useHistory();

        const { invoke: invokeMove, loading: moveLoading } = useAPI((id, start, end, allDay) =>
            move(id, start, end, allDay)
        );
        const { data: reasons } = useAPI(() => getReasons(), true);
        const { data: statuses } = useAPI(() => getStatuses(), true);

        const {
            open: handleAdd,
            close: handleCloseAdd,
            visible: addToggled,
            args: addArgs,
        } = useDialog();

        let title;
        if (assetId) {
            title = getResource(
                "Planning.timeline.header.assetTitle",
                "Timeline for components of {name}",
                {
                    name: getEntityName("Assets", assetReference),
                }
            );
        } else if (assetCollectionScope.id) {
            title = getResource(
                "Planning.timeline.header.collectionTitle",
                "Timeline for assets & components of {name}",
                {
                    name: `${companyScope.name} / ${assetCollectionScope.name}`,
                }
            );
        } else {
            title = getResource(
                "Planning.timeline.header.companyTitle",
                "Timeline for assets & components of {name}",
                {
                    name: `${companyScope.name}`,
                }
            );
        }

        const renderTimelineContent = (args) => {
            const title = args.event.title || "&nbsp;";
            const tooltip = args.event.extendedProps.tooltip || args.event.title;
            const addCustomIcon =
                args.event.extendedProps.isPredicted && args.event.display === "block";

            return (
                <Tooltip title={tooltip} arrow classes={{ tooltip: classes.tooltip }}>
                    <div className="fc-event-main-frame">
                        {addCustomIcon && (
                            <div className="custom-icon">
                                <svg
                                    className="MuiSvgIcon-root"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    tabIndex="-1"
                                >
                                    <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
                                </svg>
                            </div>
                        )}
                        <div className="fc-event-title-container">
                            <div
                                className="fc-event-title fc-sticky"
                                dangerouslySetInnerHTML={{ __html: title }}
                            ></div>
                        </div>
                    </div>
                </Tooltip>
            );
        };

        const showWorkingContext = !assetId;

        return (
            <Fragment>
                {showWorkingContext && <WorkingContext requiredOperation="scheduled-events-view" />}
                <div>
                    <LinearProgress
                        className={clsx(classes.loader, (loading || eventsLoading) && "--visible")}
                        color="secondary"
                    />
                </div>
                <ContentHolder>
                    {!loading && !companyScope.id && (
                        <div className={classes.noContent}>
                            <BusinessIcon className={classes.icon} />
                            {getResource(
                                "Planning.timeline.selectCompany",
                                "Select a company to view the timeline"
                            )}
                        </div>
                    )}
                    {!loading && companyScope.id && (
                        <Fragment>
                            <Header
                                title={title}
                                breadcrumb={getEntityBreadcrumb(referenceType, reference)}
                                renderActions={() => {
                                    return (
                                        <ActionMenu
                                            actions={[
                                                {
                                                    preventClose: true,
                                                    text: (
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={showAllResources}
                                                                    onChange={(e) =>
                                                                        setShowAllResources(
                                                                            e.target.checked
                                                                        )
                                                                    }
                                                                    name="showAllResources"
                                                                />
                                                            }
                                                            label={getResource(
                                                                "Planning.timeline.showAllResources",
                                                                "Show all assets/components"
                                                            )}
                                                            labelPlacement="start"
                                                            variant="outlined"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                marginLeft: 0,
                                                            }}
                                                        />
                                                    ),
                                                    icon: null, // <FilterList />,
                                                },
                                            ]}
                                        />
                                    );
                                }}
                            />
                            <FullCalendar
                                calendarRef={timelineRef}
                                plugins={[resourceTimelinePlugin, interactionPlugin]}
                                initialView="resourceTimelineMonth"
                                events={loadEvents}
                                nowIndicator
                                resources={resources}
                                resourceOrder={"title"}
                                resourcesInitiallyExpanded={!showWorkingContext}
                                filterResourcesWithEvents={!showAllResources}
                                stickyHeaderDates
                                height={
                                    showWorkingContext
                                        ? "calc(100vh - 200px - 95px)"
                                        : "calc(100vh - 200px)"
                                }
                                selectable={resources && resources.some((x) => x.allowCreate)}
                                locales={allLocales}
                                locale={selectedUiCulture}
                                eventClick={(args) => {
                                    if (args.event.extendedProps.allowEdit) {
                                        if (parseInt(args.event.id)) {
                                            onEdit(args.event.id);
                                        } else {
                                            setSelectedPredictedEvent(args.event);
                                        }
                                    }
                                }}
                                isTimeLineCalendar
                                slotMinWidth={
                                    currentView === "resourceTimelineMonth" ||
                                    currentView === "resourceTimelineYear"
                                        ? 65
                                        : null
                                }
                                eventResourceEditable={false}
                                eventClassNames={classes.event}
                                select={(args) => {
                                    //https://github.com/fullcalendar/fullcalendar/issues/4590
                                    const calendarApi = timelineRef.current.getApi();
                                    var events = calendarApi.getEvents();

                                    for (let i = 0; i < events.length; i++) {
                                        if (
                                            events[i].display === "background" &&
                                            events[i].start <= args.start &&
                                            events[i].end >= args.end &&
                                            events[i].getResources().some(r => r.id === args.resource.id)
                                        ) {
                                            setBackgroundEventDialog({
                                                event: events[i],
                                                start: zonedTimeToUtc(args.start, selectedTimezone),
                                                end: zonedTimeToUtc(args.end, selectedTimezone),
                                                allDay: args.allDay,
                                                referenceId: args.resource.extendedProps.resourceId,
                                                referenceType:
                                                    args.resource.extendedProps.resourceType,
                                                allowCreate:
                                                    args.resource.extendedProps.allowCreate,
                                            });
                                            return;
                                        }
                                    }

                                    if (args.resource.extendedProps.allowCreate) {
                                        onAdd({
                                            start: zonedTimeToUtc(args.start, selectedTimezone),
                                            end: zonedTimeToUtc(args.end, selectedTimezone),
                                            allDay: args.allDay,
                                            referenceId: args.resource.extendedProps.resourceId,
                                            referenceType: args.resource.extendedProps.resourceType,
                                        });
                                    }
                                }}
                                eventDrop={async (args) => {
                                    const event = {
                                        id: args.event.id,
                                        start: args.event.start,
                                        end: args.event.end
                                            ? args.event.end
                                            : args.event.allDay
                                            ? addDays(args.event.start, 1)
                                            : addHours(args.event.start, 1),
                                        allDay: args.event.allDay,
                                        title: args.event.title,
                                    };

                                    setMoveParameters({
                                        open: true,
                                        event: event,
                                        revert: args.revert,
                                        type: "move",
                                    });
                                }}
                                eventResize={(args) => {
                                    setMoveParameters({
                                        open: true,
                                        event: args.event,
                                        revert: args.revert,
                                        type: "resize",
                                    });
                                }}
                                headerToolbar={{
                                    left: "prev today next",
                                    center: "title",
                                    right:
                                        "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear",
                                }}
                                resourceLabelDidMount={({ el, resource }) => {
                                    var nameElement = el.querySelector(".fc-datagrid-cell-main");
                                    nameElement.onclick = function () {
                                        if (resource.extendedProps.resourceType !== "Storage") {
                                            history.push(
                                                `/assets/${resource.extendedProps.resourceType}/${resource.extendedProps.resourceId}`
                                            );
                                        }
                                    };
                                }}
                                eventContent={renderTimelineContent}
                                eventDidMount={({ el, event }) => {
                                    if (event.extendedProps.allowEdit) {
                                        el.classList.add("fc-event-editable");
                                    }
                                }}
                                datesSet={({ view }) => {
                                    setCurrentView(view.type);
                                }}
                                loading={(isLoading) => setEventsLoading(isLoading)}
                            />
                            <div className={classes.timeZone}>
                                <ResourcedText
                                    resourceKey="Planning.scheduledEvent.timezone"
                                    defaultValue="All events are displayed using timezone <span>{timezone}</span>"
                                    tokens={{ timezone: selectedTimezone }}
                                />
                            </div>
                        </Fragment>
                    )}
                    {moveParameters && (
                        <Confirmation
                            open={moveParameters.open}
                            loading={moveLoading}
                            handleAction={async () => {
                                const event = moveParameters.event;
                                const result = await invokeMove(
                                    event.id,
                                    zonedTimeToUtc(event.start, selectedTimezone),
                                    zonedTimeToUtc(event.end, selectedTimezone),
                                    event.allDay
                                );
                                if (!result) {
                                    moveParameters.revert();
                                }
                                setMoveParameters({ ...moveParameters, open: false });
                            }}
                            handleClose={() => {
                                moveParameters.revert();
                                setMoveParameters({ ...moveParameters, open: false });
                            }}
                            headerText={
                                moveParameters.type === "resize"
                                    ? getResource(
                                          "Planning.scheduledEvent.resize.header",
                                          "Change event?"
                                      )
                                    : getResource(
                                          "Planning.scheduledEvent.move.header",
                                          "Move event?"
                                      )
                            }
                            buttonText={
                                moveParameters.type === "resize"
                                    ? getResource(
                                          "Planning.scheduledEvent.resize.okButton",
                                          "Change"
                                      )
                                    : getResource("Planning.scheduledEvent.move.okButton", "Move")
                            }
                        >
                            {moveParameters.type === "resize" ? (
                                <ResourcedText
                                    resourceKey="Planning.scheduledEvent.resize.text"
                                    defaultValue="Are you sure you want to change the end time for '{summary}'?"
                                    tokens={{
                                        summary:
                                            moveParameters &&
                                            moveParameters.event &&
                                            moveParameters.event.title,
                                    }}
                                />
                            ) : (
                                <ResourcedText
                                    resourceKey="Planning.scheduledEvent.move.text"
                                    defaultValue="Are you sure you want to move '{summary}'?"
                                    tokens={{
                                        summary:
                                            moveParameters &&
                                            moveParameters.event &&
                                            moveParameters.event.title,
                                    }}
                                />
                            )}
                        </Confirmation>
                    )}
                    {backgroundEventDialog && (
                        <BackgroundEventActions
                            event={backgroundEventDialog.event}
                            allowCreate={backgroundEventDialog.allowCreate}
                            onClose={() => setBackgroundEventDialog(null)}
                            onGoToDate={(date) => {
                                const calendarApi = timelineRef.current.getApi();
                                calendarApi.gotoDate(date);

                                // A bit of a hack, but without timeout getdate is not yet finished
                                // and the wrong date gets displayed.
                                setTimeout(() => calendarApi.scrollToTime(
                                    date.getTime() - calendarApi.view.currentStart.getTime()
                                ), 500 );
                            }}
                            onAdd={() => {
                                onAdd({
                                    start: backgroundEventDialog.start,
                                    end: backgroundEventDialog.end,
                                    allDay: backgroundEventDialog.allDay,
                                    referenceId: backgroundEventDialog.referenceId,
                                    referenceType: backgroundEventDialog.referenceType,
                                });
                            }}
                        />
                    )}
                </ContentHolder>
                {selectedPredictedEvent && (
                    <PredictedEventActions
                        predictedEvent={selectedPredictedEvent.extendedProps}
                        resourceName={selectedPredictedEvent.getResources()[0].title}
                        companyId={(assetReference && assetReference.companyId) || companyScope.id}
                        onClose={(reload) => {
                            setSelectedPredictedEvent(null);
                            if (reload) {
                                onReload();
                            }
                        }}
                        onOpenWorkOrder={(workOrderId) => {
                            history.push(`/maintenance/work-orders/${workOrderId}/tasks`);
                        }}
                        onCreateWorkOrder={() => {
                            let assetId = null;
                            let componentId = null;

                            if (selectedPredictedEvent.extendedProps.resourceType === "Assets") {
                                assetId = selectedPredictedEvent.extendedProps.resourceIdentifier;
                            } else if (
                                selectedPredictedEvent.extendedProps.resourceType === "Component"
                            ) {
                                componentId =
                                    selectedPredictedEvent.extendedProps.resourceIdentifier;
                            }

                            handleAdd({
                                assetId,
                                componentId,
                                entityName: selectedPredictedEvent.extendedProps.resourceName,
                                assignedActionId:
                                    selectedPredictedEvent.extendedProps.assignedActionId,
                            });
                        }}
                    />
                )}

                <AddWorkOrder
                    open={addToggled}
                    onClose={(reload) => {
                        handleCloseAdd();
                        if (reload) {
                            onReload();
                        }
                    }}
                    reasons={reasons}
                    statuses={statuses}
                    assetIdPreselected={addArgs && addArgs.assetId}
                    componentIdPreselected={addArgs && addArgs.componentId}
                    entityName={addArgs && addArgs.entityName}
                    assignedActionId={addArgs && addArgs.assignedActionId}
                />
            </Fragment>
        );
    }
);

export default Overview;
