import React, { useContext } from "react";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Overview from "./overview";
import Delete from "./delete";
import Merge from "./merge";
import Review from "./review";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );

    const [searchParams, setSearchParams] = useSessionStorage("search-manufacturers", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
    });

    const { loading, invoke, data } = useAPI(
        () => getAll(searchParams.searchText, searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams]
    );

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteId,
    } = useDialog(invoke);
    const {
        open: handleMerge,
        close: handleCloseMerge,
        visible: mergeToggled,
        args: mergeValues,
    } = useDialog(invoke);
    const {
        open: handleReview,
        close: handleCloseReview,
        visible: reviewToggled,
        args: reviewId,
    } = useDialog(invoke);

    function handleSearch(searchText) {
        setSearchParams({ ...searchParams, searchText, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="manufacturers-view"
                path="/library/manufacturers"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onMerge={handleMerge}
                            onReview={handleReview}
                            defaultDebounceInterval={defaultDebounceInterval}
                            {...props}
                        />
                    );
                }}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
            <Delete id={deleteId} open={deleteToggled} onClose={handleCloseDelete} />
            <Merge
                id={mergeValues && mergeValues.id}
                version={mergeValues && mergeValues.version}
                name={mergeValues && mergeValues.name}
                open={mergeToggled}
                onClose={handleCloseMerge}
            />
            <Review
                id={reviewId}
                open={reviewToggled}
                onClose={handleCloseReview}
                onMerge={handleMerge}
            />
        </React.Fragment>
    );
};
