import React from "react";
import useStyles from "./useStyles";
import clsx from "clsx";

const PageListSearch = ({ searchFields, sortField }) => {
    const classes = useStyles();

    return (searchFields && searchFields.length > 0 || sortField) ? (
        <form className={classes.root} onSubmit={(e) => e.preventDefault()}>
            {searchFields.map((field, i) => (
                <div className={classes.filterPart} key={i}>
                    {field}
                </div>
            ))}
            {sortField &&
                <div className={clsx(classes.filterPart, classes.sortPart)}>
                    {sortField}
                </div>
            }
        </form>
    ) : null;
};

export default PageListSearch;
