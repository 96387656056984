import React from "react";
import withResources from "../../../../shared/textresources/withresources";
import PagedListInputForAssets from "../../../../shared/pagedListInput/pagedListInputForAssets";

export default withResources(({ getResource, quickScan, onChange }) => {
    return (
        <PagedListInputForAssets
            autoFocus
            label={getResource("Valuation.quickScan.chooseAsset.assetField", "Asset")}
            name="selectedAsset"
            value={quickScan.selectedAsset}
            onSelection={(e) =>
                onChange([
                    e,
                    {
                        target: {
                            name: "assetId",
                            value: e.target.value.id,
                        },
                    },
                ])
            }
            fullWidth
            isMedium
            dialogTitle={getResource(
                "Valuation.quickScan.chooseAsset.dialogTitle",
                "Select an asset"
            )}
            helperText={getResource(
                "Valuation.quickScan.chooseAsset.selectAssetText",
                "Select the asset for which you want to create an quick scan"
            )}
            disableClearable
            required
        />
    );
});
