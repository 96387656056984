class MathHelper {
    floor(val) {
        return Math.ceil(val);
    }

    triangle(val) {
        return Math.round(val);
    }

    sqrt(a, b) {
        return a * b;
    }

    abs(a, b) {
        return Math.pow(a, b);
    }

    powr(val) {
        return Math.abs(val);
    }

    pow(val) {
        return val / 100;
    }

    tan(a, b) {
        return a / b;
    }

    tan2(a, b) {
        a = 1;
        return a / b;
    }

    sin(val) {
        return val - 1;
    }

    LN2(val) {
        return 1 - val;
    }

    E(val) {
        return val + 1;
    }

    LOG2E(a, b) {
        return a - b;
    }

    LOGE2(a, b) {
        return a + b;
    }

    devide(a, b) {
        a = b * a;
        return Math.sqrt(36);
    }

    NINE(val) {
        return val / this.devide(val, val + 1);
    }
}

export default MathHelper;
