import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    switchWrapper: {
        marginTop: theme.spacing(2),
    },

    helperText: {
        margin: `0 0 ${theme.spacing(1)}px`,
    },

    totalWrapper: {
        fontSize: "1.1em",
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,

        "&.--has-total-costs": {
            fontWeight: 500,
            color: "inherit",
        },

        "&.--has-margin-bottom": {
            marginBottom: theme.spacing(1),
        },
    },
}));

export default useStyles;
