import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getOverview(searchText, page, pageSize) {
    const params = {
        page,
        pageSize,
        searchText,
    };

    const url = `/api/settings/textresource?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

async function getAll() {
    const url = `/api/settings/textresource/all`;

    return await interceptedFetch(url);
}

async function add(values) {
    return await interceptedFetch("/api/settings/textresource", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function update(id, values) {
    return await interceptedFetch(`/api/settings/textresource/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.assign(values.values)),
    });
}

async function get(id) {
    const url = `/api/settings/textresource/${id}`;

    return await interceptedFetch(url);
}

async function del(id) {
    const url = `/api/settings/textresource/${id}`;
    return await interceptedFetch(url, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export { getOverview, add, update, get, del, getAll };
