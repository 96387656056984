import React, { useEffect } from "react";

function isJSON(response) {
    const contentType = response.headers.get("content-type");
    return contentType && contentType.indexOf("application/json") !== -1;
}

async function handleResponse(response) {
    if (!response.ok && !response.skipErrorHandling) {
        // Unauthorized requests are handled in interceptedFetch.js
        if (response.status >= 400 && response.status < 500) {
            if (isJSON(response)) {
                const errorMessage = await response.json();
                return {
                    error: true,
                    data: errorMessage,
                };
            } else {
                return {
                    error: true,
                    data: await response.text(),
                };
            }
        }

        throw Error(await response.text());
    }

    return {
        error: false,
        data: isJSON(response) ? await response.json() : null,
    };
}

function useAPI(call, immediate = false, options) {
    const [loading, setLoading] = React.useState(immediate);
    const [data, setData] = React.useState(null);
    const [hasError, setError] = React.useState(false);
    const [deferFetchingData, setDeferFetchingData] = React.useState(!immediate);

    // Global error hook that can be used by setting useAPI.onError.
    const onError = useAPI.onError;

    const fetchData = async (...args) => {
        setLoading(true);

        var aborted = false;

        try {
            const response = await call(...args);
            if (response == null) {
                setData(null);
                return true;
            } else {
                const fetchedData = await handleResponse(response);

                if (fetchedData.error) {
                    onError && onError(fetchedData.data);
                    setError(true);
                } else {
                    setData(fetchedData.data);
                }

                return !fetchedData.error;
            }
        } catch (e) {
            if (e.name !== "AbortError") {
                if (onError) {
                    onError("Oops, something went wrong on the server..");
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
                setError(true);
            } else {
                aborted = true;
            }
        } finally {
            if (!aborted) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (deferFetchingData) {
            setDeferFetchingData(false);
        } else {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, options || []);

    return {
        loading,
        invoke: fetchData,
        data,
        hasError,
        setData
    };
}

export default useAPI;
