import React, { useState } from "react";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import withResources from "../../../shared/textresources/withresources";
import PageListSearch from "../../../shared/pagelistsearch";
import SearchIcon from "@material-ui/icons/Search";
import { Switch, Grid, Typography } from "@material-ui/core";

const Search = withResources(({ getResource, onSearch, searchParams }) => {
    const [searchValues, setSearchValues] = useState(searchParams);

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.searchText, values.status, values.spam);
    }

    const statuses = [
        { id: null, name: "" },
        { id: "unread", name: getResource("Marketplace.messages.status.unread", "Unread") },
        { id: "read", name: getResource("Marketplace.messages.status.read", "Read") },
    ];

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Marketplace.messages.search.searchField"
                    labelDefaultValue="Keyword search"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.searchText || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, searchText: e.target.value })
                    }
                    AdornmentIcon={<SearchIcon />}
                    className="e2e_pagedListGenericSearchField"
                    clearable
                />,
                <AutoCompleteStatic
                    label={getResource("Marketplace.messages.search.statusField", "Status")}
                    value={searchValues.status}
                    options={statuses}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, status: e.target.value })
                    }
                />,
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                            {getResource("Marketplace.messages.search.inboxLabel", "Inbox")}
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={searchValues.spam}
                                onChange={(e) =>
                                    handleValuesChanged({ ...searchValues, spam: e.target.checked })
                                }
                                name="spam"
                            />
                        </Grid>
                        <Grid item>
                            {getResource("Marketplace.messages.search.spamLabel", "Spam")}
                        </Grid>
                    </Grid>
                </Typography>,
            ]}
        />
    );
});

export default Search;
