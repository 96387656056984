import React, { useContext, useEffect } from "react";

import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import Add from "./add";
import Edit from "./edit";
import Roles from "./roles";
import Activate from "./activate";
import Deactivate from "./deactivate";
import Overview from "./overview";
import { getAll } from "./dataservice";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useDialog from "../../../shared/usedialog";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-users", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        isActive: "true",
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.isActive,
                false,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.isActive,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);
    const {
        open: handleEditRoles,
        close: handleCloseEditRoles,
        visible: editRolesToggled,
        args: editRolesId,
    } = useDialog(invoke);
    const {
        open: handleActivate,
        close: handleCloseActivate,
        visible: activateToggled,
        args: activateArgs,
    } = useDialog(invoke);
    const {
        open: handleDeactivate,
        close: handleCloseDeactivate,
        visible: deactivateToggled,
        args: deactivateArgs,
    } = useDialog(invoke);

    function handleSearch(searchText, isActive) {
        setSearchParams({ ...searchParams, searchText, isActive, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operation) {
        if (!companyScope.id) {
            return operation;
        }

        return {
            name: operation,
            companyId: companyScope.id,
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <React.Fragment>
            <PrivateRoute
                requiredOperation="users-view"
                path="/security/users"
                render={(props) => (
                    <Overview
                        data={data}
                        searchParams={searchParams}
                        availablePageSizes={availablePageSizes}
                        loading={loading}
                        getRequiredOperation={getRequiredOperation}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSearch={handleSearch}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        onEditRoles={handleEditRoles}
                        onActivate={handleActivate}
                        onDeactivate={handleDeactivate}
                        defaultDebounceInterval={defaultDebounceInterval}
                        {...props}
                    />
                )}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />

            <Edit open={editToggled} id={editId} onClose={handleCloseEdit} />

            <Roles open={editRolesToggled} id={editRolesId} onClose={handleCloseEditRoles} />

            <Activate
                open={activateToggled}
                onClose={handleCloseActivate}
                userId={activateArgs && activateArgs.userId}
                userFirstName={activateArgs && activateArgs.userFirstName}
                userLastName={activateArgs && activateArgs.userLastName}
                userIsSystemAdmin={activateArgs && activateArgs.isSystemAdmin}
            />

            <Deactivate
                open={deactivateToggled}
                onClose={handleCloseDeactivate}
                userId={deactivateArgs && deactivateArgs.userId}
                userFirstName={deactivateArgs && deactivateArgs.userFirstName}
                userLastName={deactivateArgs && deactivateArgs.userLastName}
                userIsSystemAdmin={deactivateArgs && deactivateArgs.isSystemAdmin}
            />
        </React.Fragment>
    );
};
