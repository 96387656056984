import React, { useState, useContext } from "react";
import AutoCompleteStatic from "../../../../shared/autocompleteStatic";
import AuthorizationContext from "../../../../shared/authentication/authenticationcontext";
import ResourcedTextField from "../../../../shared/textresources/resourcedtextfield";
import withResources from "../../../../shared/textresources/withresources";
import PageListSearch from "../../../../shared/pagelistsearch";

const Search = withResources(({ getResource, onSearch }) => {
    const { authorizationService } = useContext(AuthorizationContext);

    const [searchValues, setSearchValues] = useState({
        statuses: [],
        createdBy: "",
    });

    const statusOperationsMapping = {
        Draft: "model-valuations-create",
        Released: "model-valuations-view-released",
        Published: "model-valuations-view-published",
        Archived: "model-valuations-view-published",
    };

    const filterStatuses = Object.keys(statusOperationsMapping)
        .filter((status) => {
            var operation = statusOperationsMapping[status];
            return authorizationService.isAuthorized(operation);
        })
        .map((x) => ({ id: x, name: getResource(`Valuation.status.${x}`, x) }));

    function handleValuesChanged(values) {
        setSearchValues(values);
        onSearch(values.createdBy, values.statuses);
    }

    return (
        <PageListSearch
            searchFields={[
                <ResourcedTextField
                    labelResourceKey="Valuation.overview.createdByField"
                    labelDefaultValue="Created by"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoFocus
                    value={searchValues.createdBy || ""}
                    onChange={(e) =>
                        handleValuesChanged({ ...searchValues, createdBy: e.target.value })
                    }
                />,
                <AutoCompleteStatic
                    label={getResource("Valuation.overview.statusesField", "Statuses")}
                    multiple
                    value={searchValues.statuses}
                    options={filterStatuses}
                    onSelection={(e) =>
                        handleValuesChanged({ ...searchValues, statuses: e.target.value })
                    }
                />,
            ]}
        />
    );
});

export default Search;
