import React from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { add, getPath } from "./dataservice";
import { getAll } from "../assettypes/dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, parentId, onClose }) => {
    if (!open) {
        return null;
    }

    const { data: path } = useAPI(() => getPath(parentId), true, [parentId]);

    const { loading, invoke } = useAPI(add);

    const { data: assetTypes } = useAPI(() => getAll(null, null, null, null), true);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, cultures, defaultCulture) => {
        const errors = {};

        if (!values.name[defaultCulture]) {
            errors.name = getResource(
                "Library.assetCategories.add.enterName",
                "Please enter a name for the default language"
            );
        }

        if (!values.defaultAssetTypeId) {
            errors.defaultAssetTypeId = getResource(
                "Library.assetCategories.add.selectAssetType",
                "Please select an type"
            );
        }

        return errors;
    };

    var isLoaded = assetTypes && path;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.assetCategories.add.title", "Create category")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{ name: "", parentId: parentId }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Library.assetCategories.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    name="parent"
                                    labelResourceKey="Library.assetCategories.add.parentField"
                                    labelDefaultValue="Parent category path"
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={path.parentNames.join(" > ")}
                                />

                                <TranslatableTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="name"
                                    labelResourceKey="Library.assetCategories.add.nameField"
                                    labelDefaultValue="Name"
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                    value={values.name}
                                    culture={culture}
                                    handleChange={handleChange}
                                    required
                                />

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="defaultAssetTypeId"
                                    label={getResource(
                                        "Library.assetCategories.add.defaultAssetTypeField",
                                        "Default type"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.defaultAssetTypeId}
                                    onSelection={(e) => handleChange(e)}
                                    options={assetTypes.items}
                                    disableClearable={true}
                                    disabled={!isDefaultCulture}
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
