import React, { Fragment, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import DatePicker from "../../../shared/datepicker";
import MediaEditor from "../../../shared/mediaEditor";

export default withResources(({ getResource, open, componentId, library, id, onClose }) => {
    if (!open) {
        return null;
    }

    const { data } = useAPI(() => get(componentId, library, id), true, [id]);

    const { loading, invoke } = useAPI((values) => update(componentId, library, id, values));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.fileId) {
            errors.media = [
                {
                    fileId: getResource(
                        "Assets.component.media.edit.uploadFile",
                        "Please upload a file"
                    ),
                },
            ];
        }

        if (!values.date) {
            errors.date = getResource(
                "Assets.component.media.edit.enterDate",
                "Please enter a date"
            );
        }

        return errors;
    };

    const isLoaded = data;
    const [isDirty, setIsDirty] = useState(false);

    return (
        <FormDrawer
            title={getResource("Assets.component.media.edit.title", "Edit media")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Assets.component.media.edit.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    showCultureSelector
                    renderForm={(
                        culture,
                        values,
                        handleChange,
                        errors,
                        updateValues,
                        isDefaultCulture
                    ) => {
                        const handleMediaEditorChange = (e) => {
                            if (!e) return;
                            const value = e.target.value[0];
                            updateValues([
                                {
                                    name: "fileId",
                                    value: value.fileId,
                                },
                                {
                                    name: "description",
                                    value: value.description,
                                },
                            ]);
                        };

                        return (
                            <Fragment>
                                <MediaEditor
                                    name="media"
                                    errors={errors.media}
                                    uploadUrl={`/api/assets/component/${componentId}/media/${library}/upload`}
                                    getDownloadUrl={(image) =>
                                        `/api/assets/component/${componentId}/media/${library}/${image.id}/view?imageSize=MediaLibrary&v=${image.fileId}`
                                    }
                                    culture={culture}
                                    disabled={!isDefaultCulture}
                                    onChange={handleMediaEditorChange}
                                    singleMediaItem
                                    values={[
                                        {
                                            description: { ...values.description },
                                            fileId: values.fileId,
                                            id: values.id,
                                        },
                                    ]}
                                />

                                <DatePicker
                                    errors={errors}
                                    name="date"
                                    label={getResource("Assets.component.media.edit.date", "Date")}
                                    value={values.date}
                                    onChange={(e) => handleChange(e)}
                                    disableFuture
                                    disabled={!isDefaultCulture}
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
