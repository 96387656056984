import React, { useState, useContext, useEffect, Fragment } from "react";
import { Button, LinearProgress, MobileStepper } from "@material-ui/core";
import {
    ShoppingCart as PurchaseButton,
    NavigateBefore as PreviousButton,
    NavigateNext as NextButton,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useSessionStorage from "../../../shared/useSessionStorage";
import useAPI from "../../../shared/useapi";
import { get as getAsset } from "../../assets/asset/dataservice";
import { getQuestionsForAsset as getQuestions } from "../questionnaire/dataservice";
import { getPublishedForAsset as getValuation } from "../valuation/dataservice";
import { purchase, finalize } from "./dataservice";
import { isValuationOutdated } from "../quickscan/dataservice";
import LocalizationContext from "../../../shared/localization/localizationcontext";

import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AnswerQuestionnaire from "../quickscan/components/questionnaire";
import FutureValues from "../quickscan/components/futureValues";
import CustomizeValues from "../quickscan/components/customizeValues";
import ChooseUser from "./components/chooseUser";
import ChooseAsset from "./components/chooseAsset";
import RequestValuationDialog from "../quickscan/components/requestValuationDialog";
import {
    CloseConfirmation,
    ContinueConfirmation,
    revalidateQuestions,
} from "./components/continueAbandoned";

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: theme.spacing(2),

        "& button": {
            marginRight: theme.spacing(1),
        },
    },
    error: {
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    mobileStepper: {
        paddingRight: 0,
        paddingLeft: 0,
        backgroundColor: "inherit",

        "& .MuiMobileStepper-progress": {
            width: "100%",
        },
    },
    infoText: {
        marginBottom: theme.spacing(2),
    },
}));

export default withResources(({ getResource, open, assetId, onClose, history, isCustom }) => {
    if (!open) {
        return null;
    }

    const { selectedCulture, defaultCulture } = useContext(LocalizationContext);

    const classes = useStyles();

    const [quickScan, setQuickScan] = useState({
        selectedAsset: null,
        assetId: assetId,
        assetLoaded: false,
        constructionDate: null,
        purchaseFutureValues: false,
        futureMonths: null,
        answers: {},
    });
    const [isDirty, setIsDirty] = useState(false);
    const [showRequestValuationDialog, setShowRequestValuationDialog] = useState(false);
    const [finalizeParameters, setFinalizeParameters] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [showContinue, setShowContinue] = useState(Boolean(assetId));
    const [validateQuestions, setValidateQuestions] = useState(false);

    const [previousQuickScans, setPreviousQuickScans] = useSessionStorage(
        "previous-asset-quick-scan",
        {},
        true
    );
    const [previousCustomQuickScans, setPreviousCustomQuickScans] = useSessionStorage(
        "previous-custom-asset-quick-scan",
        {},
        true
    );

    const storeQuickScan = () => {
        var list = isCustom ? previousCustomQuickScans : previousQuickScans;
        list[quickScan.assetId] = quickScan;

        isCustom ? setPreviousCustomQuickScans(list) : setPreviousQuickScans(list);
    };

    const getPreviousQuickScan = () => {
        var list = isCustom ? previousCustomQuickScans : previousQuickScans;
        return list[quickScan.assetId];
    };

    const checkToContinue = () => {
        if (getPreviousQuickScan()) {
            setShowContinue(true);
        }

        return true;
    };

    const { loading: assetLoading, data: asset } = useAPI(
        () => getAsset(quickScan.assetId),
        Boolean(assetId),
        [quickScan.assetId]
    );
    const { loading: valuationLoading, data: valuation } = useAPI(
        () => getValuation(asset.id),
        asset != null,
        [asset]
    );
    const { loading: questionsLoading, data: questions } = useAPI(
        () => getQuestions(asset.id),
        asset != null,
        [asset]
    );
    const { loading: isOutdatedLoading, data: isOutdated, invoke: checkIsOutdated } = useAPI(
        () => isValuationOutdated(valuation.id, asset.constructionDate, quickScan.futureMonths),
        false
    );
    const { loading: savingPurchase, invoke: invokePurchase, data: quickScanId } = useAPI(() =>
        purchase(quickScan)
    );
    const { loading: savingFinalize, invoke: invokeFinalize } = useAPI(() =>
        finalize(finalizeParameters)
    );

    var assetName =
        asset &&
        ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
            `${asset.manufacturerName} / ${asset.assetModel.name}`);
    var loading =
        assetLoading ||
        valuationLoading ||
        questionsLoading ||
        (isOutdated == null && valuation != null);

    const goToNextStep = async () => {
        if (!getCurrentStep().beforeNextAction || (await getCurrentStep().beforeNextAction())) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleChange = (events) => {
        if (!Array.isArray(events)) {
            events = [events];
        }

        var copy = { ...quickScan };

        events.forEach((event) => {
            const name = event.target.name;
            const value =
                event.target.type === "checkbox" ? event.target.checked : event.target.value;

            copy = {
                ...copy,
                [name]: value,
            };
        });

        setIsDirty(true);
        setQuickScan(copy);
    };

    const calculateAge = (date) => {
        if (date == null) {
            return null;
        }
        const constructionDate = new Date(date);
        const difference = Date.now() - constructionDate.getTime();
        const differenceDate = new Date(difference);

        return Math.abs(differenceDate.getUTCFullYear() - 1970);
    };

    const getSteps = () => {
        var futureValues = {
            infoText: getResource(
                "Valuation.quickScan.futureValues.purchase.infoText",
                "In addition to purchasing a current value you can also purchase a future value. This future value will be included in the quick scan report."
            ),
            render: () => <FutureValues quickScan={quickScan} onChange={handleChange} />,
            beforeNextAction: async () => {
                if (isCustom) {
                    return true;
                }
                if (!valuation) {
                    setShowRequestValuationDialog(true);
                    return false;
                }
                var response = await isValuationOutdated(
                    valuation.id,
                    quickScan.constructionDate,
                    quickScan.futureMonths
                );
                var result = await response.json();
                if (result) {
                    setShowRequestValuationDialog(true);
                    return false;
                }
                return true;
            },
            isStepComplete: () => !quickScan.purchaseFutureValues || quickScan.futureMonths,
        };

        var chooseUser = {
            infoText: getResource(
                "Valuation.quickScan.chooseUser.infoText",
                "Select the user on whose behalf you are making this quick scan."
            ),
            render: () => (
                <ChooseUser
                    quickScan={quickScan}
                    companyId={asset.companyId}
                    onChange={(userId) => {
                        var copy = {
                            ...quickScan,
                            userId,
                        };

                        setQuickScan(copy);
                    }}
                />
            ),
            isStepComplete: () => quickScan.userId != null,
        };

        var chooseAsset = {
            infoText: getResource(
                "Valuation.quickScan.chooseAsset.infoText",
                "Select the asset for which you want to do the quick scan."
            ),
            render: () => <ChooseAsset quickScan={quickScan} onChange={handleChange} />,
            isStepComplete: () => quickScan.assetId != null,
            beforeNextAction: () => checkToContinue(),
        };

        var customizeValues = {
            infoText: getResource(
                "Valuation.quickScan.customizeValues.infoText",
                "You can adjust the pre-calculated fair market values before saving the quick scan."
            ),
            render: () => (
                <CustomizeValues
                    parameters={finalizeParameters}
                    quickScan={quickScan}
                    filteredQuestions={filteredQuestions}
                    onChange={(parameters) => {
                        setFinalizeParameters(parameters);
                    }}
                />
            ),
            handleSubmit: async () => {
                if (await invokeFinalize()) {
                    history.push(`/quick-scan/report/${finalizeParameters.quickScanId}`);
                }
            },
            isStepComplete: () => {
                if (quickScan.futureMonths) {
                    return (
                        finalizeParameters.fairMarketValue &&
                        finalizeParameters.liquidationValue &&
                        finalizeParameters.futureFairMarketValue &&
                        finalizeParameters.futureLiquidationValue
                    );
                }
                return finalizeParameters.fairMarketValue && finalizeParameters.liquidationValue;
            },
        };

        const age = calculateAge(quickScan.constructionDate);
        const filteredQuestions =
            questions &&
            questions.filter(
                (question) =>
                    !question.dependentOnAge ||
                    age == null ||
                    (question.minimalAge <= age && question.maximumAge >= age)
            );

        var questionSteps = !filteredQuestions
            ? []
            : filteredQuestions.map((question) => ({
                  infoText: getResource(
                      "Valuation.quickScan.questions.infoText",
                      "Answer all questions as accurately as possible. The answers supplied will affect the quickscan values."
                  ),
                  render: () => (
                      <AnswerQuestionnaire
                          quickScan={quickScan}
                          question={question}
                          onChange={(answerId) => {
                              var answersCopy = {
                                  ...quickScan.answers,
                                  [question.id]: answerId,
                              };
                              handleChange({ target: { name: "answers", value: answersCopy } });
                          }}
                          onComplete={goToNextStep}
                      />
                  ),
                  isStepComplete: () => {
                      if (question.answers.length === 0) {
                          return true;
                      }
                      return quickScan.answers && quickScan.answers[question.id];
                  },
              }));

        var confirmPurchase = {
            infoText: getResource(
                "Valuation.quickScan.confirmPurchase.text",
                "All questions have been answered. By confirming the purchase you will be billed for this quick scan."
            ),
            render: () => <Fragment></Fragment>, // This step doesn't need to render anything, infoText is enough
            handleSubmit: async () => {
                await invokePurchase();
            },
        };

        var steps = [futureValues, ...questionSteps];

        if (!isCustom) {
            steps = [...steps, confirmPurchase];
        } else {
            steps = [chooseUser, ...steps, customizeValues];
        }

        if (!Boolean(assetId)) {
            steps = [chooseAsset, ...steps];
        }

        return steps;
    };

    const getCurrentStep = () => {
        return getSteps()[currentStep];
    };

    const inLastStep = () => currentStep === getSteps().length - 1;

    const getCurrentStepControl = () => {
        return getCurrentStep().render();
    };

    const handleSubmit = async () => {
        var handleSubmitCurrentStep = getCurrentStep().handleSubmit;
        handleSubmitCurrentStep && (await handleSubmitCurrentStep());
    };

    const handleCancel = () => onClose(false);

    const saving = savingFinalize || savingPurchase;

    const isCurrentStepComplete = () => {
        return !getCurrentStep().isStepComplete || getCurrentStep().isStepComplete();
    };

    const getBlockingControl = () => {
        if (quickScan.assetId && !asset) {
            return (
                <Alert severity="error" className={classes.error}>
                    {getResource(
                        "Valuation.assetQuickScanPurchase.assetNotFound",
                        "This asset can't be found"
                    )}
                </Alert>
            );
        } else if (quickScan.assetId && !asset.constructionDate && !isCustom) {
            return (
                <Alert severity="error" className={classes.error}>
                    {getResource(
                        "Valuation.assetQuickScanPurchase.noConstructionDate",
                        "This asset hasn't got a construction date"
                    )}
                </Alert>
            );
        }
    };

    if (!loading) {
        if (!quickScan.assetLoaded && asset) {
            const copy = {
                ...quickScan,
                assetLoaded: true,
                assetId: asset.id,
                constructionDate: asset.constructionDate,
            };
            setQuickScan(copy);
        }
    }

    if (showContinue && !getPreviousQuickScan()) {
        setShowContinue(false);
    }

    useEffect(() => {
        if (quickScanId == null) return;

        history.push(`/quick-scan/report/${quickScanId}`);
    }, [quickScanId, history]);

    useEffect(() => {
        if (valuation && asset && isOutdated == null && !isOutdatedLoading) {
            checkIsOutdated();
        }
    }, [valuation, asset, isOutdated, isOutdatedLoading, checkIsOutdated]);

    useEffect(() => {
        if (isOutdated) {
            setShowRequestValuationDialog(true);
        }
    }, [isOutdated]);

    useEffect(() => {
        if (validateQuestions) {
            var copy = revalidateQuestions(quickScan, questions);
            setQuickScan(copy);
            setValidateQuestions(false);
        }
    }, [validateQuestions, quickScan, questions]);

    return (
        <Fragment>
            {asset && quickScan && getBlockingControl() == null && (
                <RequestValuationDialog
                    open={!isCustom && showRequestValuationDialog}
                    assetId={asset.id}
                    assetModelId={asset.assetModel.id}
                    constructionDate={quickScan.constructionDate}
                    futureMonths={quickScan.futureMonths}
                    onValuationRequested={handleCancel}
                    onCancel={() =>
                        isOutdated ? handleCancel() : setShowRequestValuationDialog(false)
                    }
                >
                    <ResourcedText
                        resourceKey="Valuation.quickScan.requestValuationDialogAsset.text"
                        defaultValue="The valuation information for this asset is missing or outdated. Would you like to submit a request to update this information?<br><br>NB please allow us 24 hours before you try again."
                    />
                </RequestValuationDialog>
            )}

            <FormDrawer
                title={
                    assetName
                        ? getResource(
                              "Valuation.purchaseAssetQuickScan.titleFor",
                              "Purchase quick scan for {assetName}",
                              { assetName }
                          )
                        : getResource(
                              "Valuation.purchaseAssetQuickScan.title",
                              "Purchase quick scan"
                          )
                }
                onClose={handleCancel}
                isDirty={isDirty}
                onCloseWhenDirty={() => setConfirmationVisible(true)}
            >
                {loading ? (
                    <LinearProgress />
                ) : getBlockingControl() != null ? (
                    getBlockingControl()
                ) : (
                    <div>
                        <MobileStepper
                            variant="progress"
                            steps={getSteps().length}
                            position="static"
                            activeStep={currentStep}
                            className={classes.mobileStepper}
                        />
                        <div className={classes.infoText}>{getCurrentStep().infoText}</div>

                        {getCurrentStepControl()}

                        <div className={classes.content}>
                            {currentStep !== 0 && (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={goToPreviousStep}
                                    startIcon={<PreviousButton />}
                                >
                                    {getResource("Valuation.quickScan.previousButton", "Previous")}
                                </Button>
                            )}
                            {!inLastStep() && (
                                <Button
                                    disabled={!isCurrentStepComplete()}
                                    color="primary"
                                    variant="contained"
                                    onClick={goToNextStep}
                                    endIcon={<NextButton />}
                                >
                                    {getResource("Valuation.quickScan.nextButton", "Next")}
                                </Button>
                            )}
                            {inLastStep() && (
                                <Button
                                    disabled={saving || !isCurrentStepComplete()}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    endIcon={!isCustom && <PurchaseButton />}
                                >
                                    {isCustom
                                        ? getResource(
                                              "Valuation.assetQuickScanPurchase.saveQuickScanButton",
                                              "Save quick scan"
                                          )
                                        : getResource(
                                              "Valuation.assetQuickScanPurchase.confirmPurchaseButton",
                                              "Confirm purchase"
                                          )}
                                </Button>
                            )}
                        </div>
                    </div>
                )}

                <CloseConfirmation
                    open={confirmationVisible}
                    onCancel={() => setConfirmationVisible(false)}
                    onDiscard={handleCancel}
                    onContinueLater={() => {
                        storeQuickScan();
                        handleCancel();
                    }}
                />

                <ContinueConfirmation
                    open={showContinue}
                    onNew={() => setShowContinue(false)}
                    onContinue={() => {
                        var previous = getPreviousQuickScan();
                        previous["selectedAsset"] = quickScan["selectedAsset"];

                        setQuickScan(previous);
                        setValidateQuestions(true);
                        setShowContinue(false);
                    }}
                />
            </FormDrawer>
        </Fragment>
    );
});
