import React from "react";
import { LinearProgress } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";

import useAPI from "../../../shared/useapi";
import { getChildren, getParents, getPath, move, filter } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import DrillDownInput from "../../../shared/drilldowninput";

export default withResources(({ getResource, open, id, onMove, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI((values) => move(id, values.values.assetCategory.id));

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onMove(values.values.assetCategory.id);
            onClose();
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.assetCategory) {
            errors.assetCategory = getResource(
                "Library.assetCategories.move.selectDestinationCategory",
                "Please select a parent category to move to"
            );
        }

        return errors;
    };

    const { data: path } = useAPI(() => getPath(id), true, [id]);

    var isLoaded = path;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Library.assetCategories.move.title", "Move category")}
            onClose={onClose}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{}}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Library.assetCategories.move.okButton", "Move")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <ResourcedTextField
                                    name="parent"
                                    labelResourceKey="Library.assetCategories.move.currentItemField"
                                    labelDefaultValue="Category to move"
                                    margin="normal"
                                    variant="filled"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={path.parentNames.join(" > ")}
                                />

                                <DrillDownInput
                                    errors={errors}
                                    name="assetCategory"
                                    itemName={getResource(
                                        "Library.assetCategories.move.assetCategoryItemName",
                                        "category"
                                    )}
                                    label={getResource(
                                        "Library.assetCategories.move.destinationField",
                                        "New parent category"
                                    )}
                                    isMedium
                                    fullWidth
                                    getParents={getParents}
                                    getChildren={getChildren}
                                    getPath={getPath}
                                    filter={filter}
                                    value={values.assetCategory}
                                    setValue={(e) => handleChange(e)}
                                    disableClearable
                                    required
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
