import React, { useContext } from "react";
import {
    LinearProgress,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Typography,
} from "@material-ui/core";

import useAPI from "../../../shared/useapi";
import { get, update } from "./dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";

import withResources from "../../../shared/textresources/withresources";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import LabelledOutline from "../../../shared/labelledoutline";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import Timezone from "../users/timezone";

export default withResources(({ getResource, history }) => {
    const { cultures, uiCultures, refreshCultures } = useContext(LocalizationContext);
    const { refresh: refreshUserInformation } = useContext(AuthenticationContext);

    const { data } = useAPI(() => get(), true);

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    const { loading, invoke } = useAPI(update);

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            country: values.values.addressVisible ? values.values.country : null,
            address: values.values.addressVisible ? values.values.address : null,
            postalCode: values.values.addressVisible ? values.values.postalCode : null,
            state: values.values.addressVisible ? values.values.state : null,
            city: values.values.addressVisible ? values.values.city : null,
        };

        if (await invoke(saveValues)) {
            refreshUserInformation();
            await refreshCultures();

            history.push("/");
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = getResource(
                "Security.users.profile.enterFirstName",
                "Please enter a firstname"
            );
        }

        if (!values.lastName) {
            errors.lastName = getResource(
                "Security.users.profile.enterSurname",
                "Please enter a surname"
            );
        }

        if (values.addressVisible && !values.country) {
            errors.country = getResource(
                "Security.users.profile.selectCountry",
                "Please select a country"
            );
        }

        if (values.addressVisible && !values.address) {
            errors.address = getResource(
                "Security.users.profile.enterAddress",
                "Please enter a address"
            );
        }

        if (values.addressVisible && !values.postalCode) {
            errors.postalCode = getResource(
                "Security.users.profile.enterPostalCode",
                "Please enter a postal code"
            );
        }

        if (values.addressVisible && !values.city) {
            errors.city = getResource("Security.users.profile.enterCity", "Please enter a city");
        }

        if (!values.applicationCulture) {
            errors.applicationCulture = getResource(
                "Security.users.profile.selectApplicationCulture",
                "Please select an application language"
            );
        }

        if (!values.contentCulture) {
            errors.contentCulture = getResource(
                "Security.users.profile.selectContentCulture",
                "Please select a content language"
            );
        }

        if (!values.timezone) {
            errors.timezone = getResource(
                "Security.users.profile.selectTimezone",
                "Please select a timezone"
            );
        }

        return errors;
    };

    var isLoaded = data && cultures && allCountries;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.users.profile.title", "Profile & Settings")}
            onClose={() => history.push("/")}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={data}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.users.profile.okButton", "Update")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <React.Fragment>
                                <Typography variant="h5">
                                    {getResource("Security.users.profile.profileHeader", "Profile")}
                                </Typography>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="firstName"
                                    labelResourceKey="Security.users.profile.firstNameField"
                                    labelDefaultValue="First name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.firstName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="lastName"
                                    labelResourceKey="Security.users.profile.surnameField"
                                    labelDefaultValue="Surname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 60 }}
                                    value={values.lastName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Security.users.profile.addressLabel",
                                        "Address"
                                    )}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(values.addressVisible)}
                                                onChange={(e) => handleChange(e)}
                                                name="addressVisible"
                                            />
                                        }
                                        label={getResource(
                                            "Security.users.profile.personalAddressField",
                                            "Personal address"
                                        )}
                                        variant="outlined"
                                    />

                                    {values.addressVisible && (
                                        <React.Fragment>
                                            <AutoCompleteStatic
                                                errors={errors}
                                                name="country"
                                                label={getResource(
                                                    "Security.users.profile.countryField",
                                                    "Country"
                                                )}
                                                fullWidth
                                                isMedium
                                                value={values.country}
                                                onSelection={(e) => handleChange(e)}
                                                options={allCountries.map((c) => ({
                                                    id: c.code,
                                                    name: c.name,
                                                }))}
                                                disableClearable
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="address"
                                                labelResourceKey="Security.users.profile.addressField"
                                                labelDefaultValue="Address"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.address}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="postalCode"
                                                labelResourceKey="Security.users.profile.postalCodeField"
                                                labelDefaultValue="Postal code"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                value={values.postalCode}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="city"
                                                labelResourceKey="Security.users.profile.cityField"
                                                labelDefaultValue="City"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.city}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="state"
                                                labelResourceKey="Security.users.profile.stateField"
                                                labelDefaultValue="State"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.state || ""}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </React.Fragment>
                                    )}
                                </LabelledOutline>

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber1"
                                    labelResourceKey="Security.users.profile.phoneNumber1Field"
                                    labelDefaultValue="Phone number 1"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber1 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber1;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber2"
                                    labelResourceKey="Security.users.profile.phoneNumber2Field"
                                    labelDefaultValue="Phone number 2"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber2 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber2;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <Typography variant="h5">
                                    {getResource(
                                        "Security.users.profile.settingsHeader",
                                        "Settings"
                                    )}
                                </Typography>

                                <AutoCompleteStatic
                                    name="applicationCulture"
                                    label={getResource(
                                        "Security.users.profile.applicationCultureField",
                                        "Preferred application language"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.applicationCulture}
                                    onSelection={(e) => handleChange(e)}
                                    options={uiCultures.map((culture) => ({
                                        id: culture.code,
                                        name: culture.description,
                                    }))}
                                    disableClearable
                                    required
                                />

                                <FormHelperText>
                                    <ResourcedText
                                        resourceKey="Security.users.profile.applicationCultureNote"
                                        defaultValue="This is the language the application uses in menus, forms, buttons, etc."
                                    />
                                </FormHelperText>
                                <br />

                                <AutoCompleteStatic
                                    name="contentCulture"
                                    label={getResource(
                                        "Security.users.profile.contentCultureField",
                                        "Preferred content language"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.contentCulture}
                                    onSelection={(e) => handleChange(e)}
                                    options={cultures.map((culture) => ({
                                        id: culture.code,
                                        name: culture.description,
                                    }))}
                                    disableClearable
                                    required
                                />

                                <FormHelperText>
                                    <ResourcedText
                                        resourceKey="Security.users.profile.contentCultureNote"
                                        defaultValue="This is the language the application uses to display information, reports, etc."
                                    />
                                </FormHelperText>
                                <br />

                                <Timezone
                                    name="timezone"
                                    value={values.timezone}
                                    onChange={(e) => handleChange(e)}
                                    errors={errors}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
