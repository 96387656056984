import React from "react";
import useAPI from "../../../shared/useapi";
import { activate } from "./dataservice";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";

export default withResources(
    ({ getResource, open, onClose, userId, userFirstName, userLastName, userIsSystemAdmin }) => {
        if (!open) {
            return null;
        }

        const { loading, invoke } = useAPI(activate);

        const handleActivate = async () => {
            if (await invoke(userId)) {
                onClose(true);
            }
        };

        return (
            <Confirmation
                open={open}
                loading={loading}
                handleAction={handleActivate}
                handleClose={onClose}
                headerText={getResource("Security.users.activate.header", "Activate user?")}
                buttonText={getResource("Security.users.activate.okButton", "Activate")}
                buttonHidden={userIsSystemAdmin}
            >
                {userIsSystemAdmin && (
                    <ResourcedText
                        resourceKey="Security.users.activate.cannotBeActivatedMessage"
                        defaultValue="A system administrator cannot be activated"
                    />
                )}
                {!userIsSystemAdmin && (
                    <React.Fragment>
                        {getResource(
                            "Security.users.activate.activateMessage",
                            "User '{userFirstName} {userLastName}' will be activated.",
                            { userFirstName, userLastName }
                        )}
                    </React.Fragment>
                )}
            </Confirmation>
        );
    }
);
