import { colors, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "6px 0",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },

    sortPart: {
        marginLeft: "auto"
    },

    filterPart: {
        flexGrow: 1,
        flexBasis: 150,
        maxWidth: "250px !important",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),

        /** Only target first child to prevent that the Popper of AutoCompleteStatic is also targeted: */
        "& > *:first-child": {
            width: "100% !important",
            minWidth: "auto !important",
        },

        "& .drillDownInput": {
            "--background-color": `${colors.grey[50]} !important`,
        },

        "& .autoCompleteSelect": {
            "--background-color": `${colors.grey[50]} !important`,

            "& .autoCompleteChip:not(.--show-as-text)": {
                border: `1px solid ${colors.grey[300]}`,

                "& .MuiChip-deleteIcon": {
                    color: colors.grey[400],

                    "&:hover": {
                        color: colors.grey[500],
                    },
                },

                "&:focus": {
                    "& .MuiChip-deleteIcon": {
                        color: colors.grey[500],
                    },
                },
            },
        },
    },
}));

export default useStyles;
