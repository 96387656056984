import React from "react";
import Confirmation from "../../../shared/confirmation";
import useAPI from "../../../shared/useapi";
import { del } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, onClose, quickScanId, reference }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(del);

    const handleDelete = async () => {
        if (await invoke(quickScanId)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open={open}
            loading={loading}
            handleAction={handleDelete}
            handleClose={onClose}
            headerText={getResource(
                "Valuation.quickScan.delete.confirmationHeader",
                "Delete quick scan?"
            )}
            buttonText={getResource("Valuation.quickScan.delete.confirmationButton", "Delete")}
        >
            {getResource(
                "Valuation.quickScan.delete.deleteMessage",
                "Quick scan {reference} will be deleted.",
                { reference }
            )}
        </Confirmation>
    );
});
