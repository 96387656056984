import cancelFetchOnReentry from "./cancelFetchOnReentry";

let interceptors = {
    pre: [],
    post: [],
};

const interceptedFetch = cancelFetchOnReentry((fetch) => (...args) => {
    interceptors.pre.forEach((interceptor) => {
        args = interceptor(args);
    });

    return fetch(...args).then((x) => {
        interceptors.post.forEach((interceptor) => {
            interceptor(x);
        });
        return x;
    });
});

interceptedFetch.addPostInterceptor = (interceptor) => {
    interceptors.post.push(interceptor);
};

interceptedFetch.addPreInterceptor = (interceptor) => {
    interceptors.pre.push(interceptor);
};

export default interceptedFetch;
