import React, { Fragment, useContext, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import FullCalendar from "../../../shared/fullCalendar";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { addHours, addDays } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import ReferenceContext from "../../../shared/reference/referenceContext";
import LocalizationContext from "../../../shared/localization/localizationcontext";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";
import ContentHolder from "../../../shared/contentHolder";
import Header from "../../../shared/header";
import withResources from "../../../shared/textresources/withresources";
import Confirmation from "../../../shared/confirmation";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import useStyles from "./useStyles";

import useAPI from "../../../shared/useapi";
import { move } from "../scheduledEvent/dataservice";

const Overview = withResources(
    ({
        getResource,
        loadEvents,
        loading,
        reference,
        referenceType,
        onAdd,
        onEditScheduledEvent,
        onEditWorkOrder,
        calendarRef,
    }) => {
        const { selectedUiCulture, selectedTimezone } = useContext(LocalizationContext);
        const { getEntityName, getEntityBreadcrumb } = useContext(ReferenceContext);
        const { authorizationService } = useContext(AuthenticationContext);

        const [moveParameters, setMoveParameters] = useState({ open: false });

        const classes = useStyles();

        const { invoke: invokeMove, loading: moveLoading } = useAPI((id, start, end, allDay) =>
            move(id, start, end, allDay)
        );

        const renderCalendarContent = (args) => {
            const time = args.timeText;
            const title = args.event.title;
            const location = args.event.extendedProps.location;

            if (args.view.type === "dayGridMonth") {
                return (
                    <Fragment>
                        <div className="fc-event-month-content-wrapper">
                            <div className="fc-event-month-top-content">
                                {time && <div className="fc-event-time">{time}</div>}
                                <div className="fc-event-title" title={title}>
                                    {title}
                                </div>
                            </div>
                            {location && (
                                <div className="fc-event-location" title={location}>
                                    {location}
                                </div>
                            )}
                        </div>
                    </Fragment>
                );
            } else if (args.view.type === "timeGridWeek" || args.view.type === "timeGridDay") {
                return (
                    <div className="fc-event-main-frame">
                        <div className="fc-event-title-container">
                            <div
                                className="fc-event-title fc-sticky"
                                title={`${title}${location ? ` - ${location}` : ""}`}
                            >
                                {title}
                                {location && <div className="fc-event-location">{location}</div>}
                            </div>
                        </div>
                    </div>
                );
            } else if (args.view.type === "listMonth") {
                return (
                    <Fragment>
                        {title}
                        {location && <div className="fc-list-event-location">{location}</div>}
                    </Fragment>
                );
            }
        };

        const allowUpdateScheduledEvents = authorizationService.isAuthorized({
            name: "scheduled-events-update",
            companyId: reference && reference.companyId,
            assetCollectionId: reference && reference.assetCollectionIds,
        });

        const allowUpdateWorkOrders = authorizationService.isAuthorized({
            name: "work-orders-update",
            companyId: reference && reference.companyId,
            assetCollectionId: reference && reference.assetCollectionIds,
        });

        const allowCreateScheduledEvents = authorizationService.isAuthorized({
            name: "scheduled-events-create",
            companyId: reference && reference.companyId,
            assetCollectionId: reference && reference.assetCollectionIds,
        });

        const allowCreateWorkOrders = authorizationService.isAuthorized({
            name: "work-orders-create",
            companyId: reference && reference.companyId,
            assetCollectionId: reference && reference.assetCollectionIds,
        });

        const isLoading = loading || !reference;

        return (
            <ContentHolder>
                <Header
                    title={getResource("Planning.calendar.header.title", "Calendar for {name}", {
                        name: getEntityName(referenceType, reference),
                    })}
                    breadcrumb={getEntityBreadcrumb(referenceType, reference)}
                />

                {isLoading && (
                    <div className={classes.loader}>
                        <CircularProgress size={100} />
                    </div>
                )}

                {!isLoading && (
                    <Fragment>
                        <FullCalendar
                            calendarRef={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={loadEvents}
                            stickyHeaderDates
                            height="calc(100vh - 200px)"
                            locales={allLocales}
                            locale={selectedUiCulture}
                            eventClick={(args) => {
                                if (allowUpdateWorkOrders && args.event.extendedProps.workOrderId) {
                                    onEditWorkOrder(args.event.extendedProps.workOrderId);
                                }else if (allowUpdateScheduledEvents) {
                                    onEditScheduledEvent(args.event.id);
                                }
                            }}
                            selectable={allowCreateScheduledEvents || allowCreateWorkOrders}
                            editable={allowUpdateScheduledEvents}
                            eventClassNames={classes.event}
                            eventContent={renderCalendarContent}
                            select={(args) => {
                                onAdd({
                                    start: zonedTimeToUtc(args.start, selectedTimezone),
                                    end: zonedTimeToUtc(args.end, selectedTimezone),
                                    allDay: args.allDay,
                                });
                            }}
                            eventDrop={async (args) => {
                                var event = {
                                    id: args.event.id,
                                    start: args.event.start,
                                    end: args.event.end
                                        ? args.event.end
                                        : args.event.allDay
                                        ? addDays(args.event.start, 1)
                                        : addHours(args.event.start, 1),
                                    allDay: args.event.allDay,
                                    title: args.event.title,
                                };

                                setMoveParameters({
                                    open: true,
                                    event: event,
                                    revert: args.revert,
                                    type: "move",
                                });
                            }}
                            eventResize={(args) => {
                                setMoveParameters({
                                    open: true,
                                    event: args.event,
                                    revert: args.revert,
                                    type: "resize",
                                });
                            }}
                            headerToolbar={{
                                left: "prev today next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                            }}
                        />
                        <div className={classes.timeZone}>
                            <ResourcedText
                                resourceKey="Planning.scheduledEvent.timezone"
                                defaultValue="All events are displayed using timezone <span>{timezone}</span>"
                                tokens={{ timezone: selectedTimezone }}
                            />
                        </div>
                    </Fragment>
                )}

                {moveParameters && (
                    <Confirmation
                        open={moveParameters.open}
                        loading={moveLoading}
                        handleAction={async () => {
                            const event = moveParameters.event;
                            const result = await invokeMove(
                                event.id,
                                zonedTimeToUtc(event.start, selectedTimezone),
                                zonedTimeToUtc(event.end, selectedTimezone),
                                event.allDay
                            );
                            if (!result) {
                                moveParameters.revert();
                            }
                            setMoveParameters({ ...moveParameters, open: false });
                        }}
                        handleClose={() => {
                            moveParameters.revert();
                            setMoveParameters({ ...moveParameters, open: false });
                        }}
                        headerText={
                            moveParameters.type === "resize"
                                ? getResource(
                                      "Planning.scheduledEvent.resize.header",
                                      "Change event?"
                                  )
                                : getResource("Planning.scheduledEvent.move.header", "Move event?")
                        }
                        buttonText={
                            moveParameters.type === "resize"
                                ? getResource("Planning.scheduledEvent.resize.okButton", "Change")
                                : getResource("Planning.scheduledEvent.move.okButton", "Move")
                        }
                    >
                        {moveParameters.type === "resize" ? (
                            <ResourcedText
                                resourceKey="Planning.scheduledEvent.resize.text"
                                defaultValue="Are you sure you want to change the end time for '{summary}'?"
                                tokens={{
                                    summary:
                                        moveParameters &&
                                        moveParameters.event &&
                                        moveParameters.event.title,
                                }}
                            />
                        ) : (
                            <ResourcedText
                                resourceKey="Planning.scheduledEvent.move.text"
                                defaultValue="Are you sure you want to move '{summary}'?"
                                tokens={{
                                    summary:
                                        moveParameters &&
                                        moveParameters.event &&
                                        moveParameters.event.title,
                                }}
                            />
                        )}
                    </Confirmation>
                )}
            </ContentHolder>
        );
    }
);

export default Overview;
