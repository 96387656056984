import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function getAll(assetCategoryId, page, pageSize) {
    const params = {
        page,
        pageSize,
        assetCategoryId,
    };
    const url = `/api/valuation/trend?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

export { getAll };
