import React from "react";
import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import withResources from "../../../shared/textresources/withresources";
import ResourceTextField from "../../../shared/textresources/resourcedtextfield";
import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import TranslatableTextField from "../../../shared/translatableTextField";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        if (await invoke(values)) {
            onClose(true);
        }
    };

    const onValidate = (values, _, defaultCulture) => {
        const errors = {};

        if (!values.key) {
            errors.key = getResource("System.textResources.add.enterKey", "Please enter a key");
        }

        if (!values.text[defaultCulture]) {
            errors.text = getResource(
                "System.textResources.add.enterText",
                "Please enter a text for the default language"
            );
        }

        return errors;
    };

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("System.textResources.add.title", "Create text resource")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            <LocalizedForm
                initialValues={{ text: {} }}
                onIsDirty={setIsDirty}
                loading={loading}
                okButtonText={getResource("System.textResources.add.okButtonText", "Create")}
                onSubmit={onSubmit}
                onValidate={onValidate}
                useUILanguages={true}
                showCultureSelector
                renderForm={(
                    culture,
                    values,
                    handleChange,
                    errors,
                    updateValues,
                    isDefaultCulture
                ) => {
                    return (
                        <React.Fragment>
                            <ResourceTextField
                                errors={errors}
                                autoFocus
                                margin="normal"
                                name="key"
                                labelResourceKey="System.textResources.add.keyField"
                                labelDefaultValue="Key"
                                type="text"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 255 }}
                                value={values.key}
                                onChange={(e) => handleChange(e)}
                                disabled={!isDefaultCulture}
                                required
                            />

                            <TranslatableTextField
                                errors={errors}
                                margin="normal"
                                name="text"
                                labelResourceKey="System.textResources.add.textField"
                                labelDefaultValue="Text"
                                fullWidth
                                inputProps={{ maxLength: 1000 }}
                                value={values.text}
                                culture={culture}
                                handleChange={handleChange}
                                required
                            />
                        </React.Fragment>
                    );
                }}
            />
        </FormDrawer>
    );
});
