import React, { useContext } from "react";
import useAPI from "../../../shared/useapi";
import { removeMultiple } from "../component/dataservice";
import LocalizationContext from "../../../shared/localization/localizationcontext";

import Confirmation from "./../../../shared/confirmation";
import withResources from "../../../shared/textresources/withresources";

export default withResources(({ getResource, open, ids, onClose }) => {
    if (!open) {
        return null;
    }

    const { loading, invoke } = useAPI(removeMultiple);

    function handleClose() {
        onClose(false);
    }

    const handleDelete = async () => {
        if (await invoke(ids)) {
            onClose(true);
        }
    };

    return (
        <Confirmation
            open
            loading={loading}
            handleAction={handleDelete}
            handleClose={handleClose}
            headerText={getResource("Asset.assetComponents.removeMultiple.header", "Remove {count} components?", { count: ids.length })}
            buttonText={getResource("Asset.assetComponents.remove.okButton", "Remove")}
        >
            {getResource(
                "Asset.assetComponents.removeMultiple.message",
                "Are you sure you want to remove {count} components? After removal the components can be found in the global components list. If the readings other components are linked to removed component, they will be unlinked.",
                { count: ids.length }
            )}
        </Confirmation>
    );
});
