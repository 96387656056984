import React, { Fragment } from "react";
import Widget from "../../../shared/widget";
import withResources from "../../../shared/textresources/withresources";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    dl: {
        "& dt": {
            marginBottom: 0,
        },

        "& dt, & dd": {
            width: "100%",
            maxWidth: "none",
        },
    },
}));

const ComponentPartyWidget = withResources(({ getResource, party }) => {
    const classes = useStyles();

    return (
        <Widget
            headerTitle={getResource(
                `Assets.component.partyTypes.${party.partyType}`,
                party.partyType
            )}
            hasContent={true}
            renderContent={() => {
                return (
                    <dl className={classes.dl}>
                        <dt>
                            <ResourcedText
                                resourceKey="Assets.component.partyWidget.nameField"
                                defaultValue="Name"
                            />
                        </dt>
                        <dd>
                            {party.name}
                            <div>{party.address}</div>
                            <div>
                                {party.postalCode} {party.city}
                            </div>
                            <div>
                                {party.state} {party.countryName}
                            </div>
                        </dd>
                        {party.website && (
                            <Fragment>
                                <dt>
                                    <ResourcedText
                                        resourceKey="Assets.component.partyWidget.websiteField"
                                        defaultValue="Website"
                                    />
                                </dt>
                                <dd>{party.website}</dd>
                            </Fragment>
                        )}
                        {party.email && (
                            <Fragment>
                                <dt>
                                    <ResourcedText
                                        resourceKey="Assets.component.partyWidget.emailField"
                                        defaultValue="Email"
                                    />
                                </dt>
                                <dd>{party.email}</dd>
                            </Fragment>
                        )}
                    </dl>
                );
            }}
        />
    );
});

export default ({ component }) => {
    return (
        <Fragment>
            {component.parties &&
                component.parties.map((party) => <ComponentPartyWidget party={party} />)}
        </Fragment>
    );
};
