import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "relative",
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(8)}px`,

        "& dl": {
            display: "flex",
            flexFlow: "column nowrap",
            marginTop: theme.spacing(2),
        },

        "& dt": {
            marginBottom: 0,
        },

        "& dl, & dt, & dd": {
            width: "auto",
        },

        "& button": {
            position: "absolute",
            right: theme.spacing(2),
            bottom: theme.spacing(2),
        },
    },
}));

export default useStyles;
