import React, { useEffect, useState, Fragment } from "react";
import QuestionsEditor from "../questionnaire/questionsEditor";
import CreditScore from "../questionnaire/creditScore";
import ResourcedText from "../../../shared/textresources/resourcedtext";
import withResources from "../../../shared/textresources/withresources";
import Confirmation from "../../../shared/confirmation";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    intro: {
        margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },

    inheritedQuestions: {
        "& .questionsEditorAccordion": {
            marginBottom: "0 !important",
        },
    },
}));

export default withResources(
    ({ values, categoryQuestionnaire, handleChange, errors, getResource, categoryPath }) => {
        const [copyDialogVisible, showCopyDialog] = useState(false);
        const classes = useStyles();

        // If the user chooses Replace and no questions have filled in and there is a category
        // questionnaire available...
        useEffect(() => {
            if (
                values.action === "Replace" &&
                categoryQuestionnaire != null &&
                categoryQuestionnaire.questions != null &&
                categoryQuestionnaire.questions.length > 0 &&
                (!values.questions || values.questions.length === 0)
            ) {
                showCopyDialog(true);
            }
        }, [values.action, values.questions, categoryQuestionnaire]);

        const handleCopy = async () => {
            handleChange({
                target: {
                    name: "questions",
                    value: categoryQuestionnaire.questions.map((q) => ({ ...q, id: null })),
                },
            });

            showCopyDialog(false);
        };

        var allQuestions = [];
        if (values.action === "Extend" || values.action === "Replace") {
            allQuestions = allQuestions.concat(values.questions);
        }
        if (
            categoryQuestionnaire != null &&
            categoryQuestionnaire.questions != null &&
            categoryQuestionnaire.questions.length > 0 &&
            (values.action === "Inherit" || values.action === "Extend")
        ) {
            allQuestions = allQuestions.concat(categoryQuestionnaire.questions);
        }
        return (
            <Fragment>
                <div className={classes.intro}>
                    <ResourcedText
                        resourceKey="Valuation.assetModelQuestionnaire.edit.description"
                        defaultValue="You can choose to inherit or extend the questions defined for the <strong>{categoryPath}</strong> category or replace them with a custom set of questions."
                        tokens={{ categoryPath }}
                    />
                </div>

                <ToggleButtonGroup
                    value={values.action || "Inherit"}
                    exclusive
                    size="small"
                    onChange={(e, value) => {
                        if (value !== null) {
                            handleChange({
                                target: {
                                    name: "action",
                                    value: value,
                                },
                            });
                        }
                    }}
                >
                    <ToggleButton value="Inherit" className="e2e_inheritButton">
                        {getResource(
                            "Valuation.assetModelQuestionnaire.edit.action.inherit",
                            "Inherit"
                        )}
                    </ToggleButton>
                    <ToggleButton value="Extend" className="e2e_extendButton">
                        {getResource(
                            "Valuation.assetModelQuestionnaire.edit.action.extend",
                            "Extend"
                        )}
                    </ToggleButton>
                    <ToggleButton value="Replace" className="e2e_replaceButton">
                        {getResource(
                            "Valuation.assetModelQuestionnaire.edit.action.replace",
                            "Replace"
                        )}
                    </ToggleButton>
                </ToggleButtonGroup>

                {categoryQuestionnaire != null &&
                    categoryQuestionnaire.questions != null &&
                    categoryQuestionnaire.questions.length > 0 && (
                        <FormHelperText>
                            {values.action === "Inherit" && (
                                <ResourcedText
                                    resourceKey="Valuation.assetModelQuestionnaire.edit.inheritQuestionsDescription"
                                    defaultValue="Only the questions defined for this category will be used"
                                />
                            )}

                            {values.action === "Replace" && (
                                <ResourcedText
                                    resourceKey="Valuation.assetModelQuestionnaire.edit.replaceQuestionsDescription"
                                    defaultValue="The questions below will replace the questions defined for this category."
                                />
                            )}

                            {values.action === "Extend" && (
                                <ResourcedText
                                    resourceKey="Valuation.assetModelQuestionnaire.edit.extendQuestionsDescription"
                                    defaultValue="The questions below will be in addition to the questions already defined for this category."
                                />
                            )}
                        </FormHelperText>
                    )}

                {(!categoryQuestionnaire ||
                    !categoryQuestionnaire.questions ||
                    categoryQuestionnaire.questions.length === 0) && (
                    <FormHelperText>
                        {values.action === "Inherit" && (
                            <ResourcedText
                                resourceKey="Valuation.assetModelQuestionnaire.edit.inheritNothingDescription"
                                defaultValue="This category currently does not have any questions defined. Any questions added to the category will be automatically inherited"
                            />
                        )}

                        {values.action === "Extend" && (
                            <ResourcedText
                                resourceKey="Valuation.assetModelQuestionnaire.edit.extendNothingDescription"
                                defaultValue="This category currently does not have any questions defined. Any questions added to the category will be automatically inherited."
                            />
                        )}
                    </FormHelperText>
                )}

                <CreditScore questions={allQuestions} />

                {categoryQuestionnaire != null &&
                    categoryQuestionnaire.questions != null &&
                    categoryQuestionnaire.questions.length > 0 &&
                    (values.action === "Inherit" || values.action === "Extend") && (
                        <div className={classes.inheritedQuestions}>
                            <QuestionsEditor
                                disabled
                                label={getResource(
                                    "Valuation.assetModelQuestionnaire.inheritedQuestions.label",
                                    "Inherited questions from category"
                                )}
                                questions={categoryQuestionnaire.questions}
                                isExpandable
                            />
                        </div>
                    )}

                {values.action !== "Inherit" && (
                    <QuestionsEditor
                        error={"questions" in errors}
                        helperText={errors["questions"]}
                        questions={values.questions}
                        onQuestionsChanged={(newQuestions) => {
                            handleChange({ target: { name: "questions", value: newQuestions } });
                        }}
                    />
                )}

                {copyDialogVisible && (
                    <Confirmation
                        open={copyDialogVisible}
                        handleAction={handleCopy}
                        handleClose={() => showCopyDialog(false)}
                        headerText={getResource(
                            "Valuation.assetModelQuestionnaire.copyValuesDialog.headerText",
                            "Copy category questions?"
                        )}
                        buttonText={getResource(
                            "Valuation.assetModelQuestionnaire.copyValuesDialog.copyButton",
                            "Copy"
                        )}
                        cancelButtonText={getResource(
                            "Valuation.assetModelQuestionnaire.copyValuesDialog.dontCopyButton",
                            "Don't copy"
                        )}
                    >
                        <ResourcedText
                            resourceKey="Valuation.assetModelQuestionnaire.copyValuesDialog.Label"
                            defaultValue="Would you like to copy the questions defined for the <strong>{categoryPath}</strong> category? (after copying you can modify the questions without affecting the category questions)"
                            tokens={{ categoryPath }}
                        />
                    </Confirmation>
                )}
            </Fragment>
        );
    }
);

export function validate(values, getResource) {
    const errors = {};

    if (values.action !== "Inherit" && (!values.questions || values.questions.length === 0)) {
        errors.questions = getResource(
            "Valuation.questionnaire.edit.enterQuestion",
            "Please fill in at least 1 question"
        );
    }

    return errors;
}
