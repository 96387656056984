export default {
    root: {
        lineHeight: 1.2,

        "& .resourcedText + .resourcedText": {
            display: "block",
            marginTop: 6,
        },
    },
};
