import React, { useContext, useState, useEffect } from "react";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";
import AllAssetsList from "./allAssetsList";
import Delete from "../quickscan/delete";
import useDialog from "../../../shared/usedialog";
import Invoice from "../../administration/invoices/forReference";
import Purchase from "./purchase";

export default ({ history }) => {
    const { defaultPageSize, availablePageSizes } = useContext(ConfigurationContext);
    const { companyScope, assetCollectionScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useState({
        page: 0,
        pageSize: defaultPageSize,
    });

    const { data, invoke, loading } = useAPI(
        () => getAll(searchParams.page + 1, searchParams.pageSize),
        true,
        [searchParams.page, searchParams.pageSize, companyScope.id, assetCollectionScope.id]
    );

    const {
        open: handlePurchase,
        close: handleClosePurchase,
        visible: purchaseToggled,
    } = useDialog(invoke);
    const {
        open: handlePurchaseCustom,
        close: handleClosePurchaseCustom,
        visible: purchaseCustomToggled,
    } = useDialog(invoke);
    const {
        open: handleDelete,
        close: handleCloseDelete,
        visible: deleteToggled,
        args: deleteArgs,
    } = useDialog(invoke);
    const {
        open: handleInvoice,
        close: handleCloseInvoice,
        visible: invoiceToggled,
        args: invoiceId,
    } = useDialog();

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operationName) {
        if (!companyScope.id && !assetCollectionScope.id) {
            return operationName;
        }

        return {
            name: operationName,
            companyId: companyScope.id,
            assetCollectionId: assetCollectionScope.id,
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [
        companyScope.id,
        assetCollectionScope.id,
    ]);

    return (
        <React.Fragment>
            <AllAssetsList
                history={history}
                data={data}
                page={searchParams.page}
                pageSize={searchParams.pageSize}
                availablePageSizes={availablePageSizes}
                loading={loading}
                getRequiredOperation={getRequiredOperation}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onPurchase={handlePurchase}
                onPurchaseCustom={handlePurchaseCustom}
                onDelete={handleDelete}
                onInvoice={handleInvoice}
            />

            <Purchase open={purchaseToggled} onClose={handleClosePurchase} history={history} />
            <Purchase
                open={purchaseCustomToggled}
                onClose={handleClosePurchaseCustom}
                isCustom
                history={history}
            />

            <Delete
                open={deleteToggled}
                onClose={handleCloseDelete}
                quickScanId={deleteArgs && deleteArgs.id}
                reference={deleteArgs && deleteArgs.reference}
            />

            <Invoice
                open={invoiceToggled}
                onClose={handleCloseInvoice}
                referenceType={"QuickScan"}
                referenceId={invoiceId}
            />
        </React.Fragment>
    );
};
