import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import PropTypes from "prop-types";
import useStyles from "./useStyles";

const Loader = React.memo(({ isLoading, positionAbsolute }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.loaderWrapper,
                isLoading && "--visible",
                positionAbsolute && "--position-absolute"
            )}
        >
            <div className={clsx(classes.loader, "loader")}>
                <CircularProgress />
            </div>
        </div>
    );
});

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    positionAbsolute: PropTypes.bool,
};

export default Loader;
