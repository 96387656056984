import React from "react";
import { Section } from "../shell";
import AssetCollection from "./assetCollection";
import Asset from "./asset";
import AssetMedia from "./assetMedia";
import ComponentUsage from "./componentUsage";
import AssetUsage from "./assetUsage";
import AssetComponents from "./assetComponents";
import AssetAdvertisements from "./assetAdvertisements";

import Component from "./component";
import ComponentMedia from "./componentMedia";

import BarChart from "@material-ui/icons/BarChart";
import withResources from "../../shared/textresources/withresources";

import { LibraryExtension } from "../document";
import { ReferenceExtension } from "../../shared/reference/referenceExtension";
import AssetDocumentHeader from "./assetDocuments/header";
import AssetDocumentSummary from "./assetDocuments/summary";

import ComponentDocumentHeader from "./componentDocuments/header";
import ComponentDocumentSummary from "./componentDocuments/summary";

import AssetFacts from "./fact/assetIndex";
import ComponentFacts from "./fact/componentIndex";

import { get as getAsset } from "./asset/dataservice";
import { get as getComponent } from "./component/dataservice";

export default withResources(({ getResource }) => {
    const children = [
        {
            title: getResource("Assets.assetsTitle", "Assets"),
            href: "/assets/assets",
            render: Asset,
            requiredOperation: "assets-view",
        },
        {
            title: getResource("Assets.assetCollectionsTitle", "Collections"),
            href: "/assets/asset-collections",
            render: AssetCollection,
            requiredOperation: "asset-collections-view",
        },
        {
            title: getResource("Assets.componentsTitle", "Components"),
            href: "/assets/components",
            render: Component,
            requiredOperation: "components-view",
        },
        {
            //No operation because the operation are set per library
            title: getResource("Assets.assetsMediaTitle", "Assets media"),
            href: "/assets/asset-media",
            render: AssetMedia,
            hideInMenu: true,
        },
        {
            //No operation because the operation are set per library
            title: getResource("Assets.componentMediaTitle", "Component media"),
            href: "/assets/component-media",
            render: ComponentMedia,
            hideInMenu: true,
        },
        {
            title: getResource("Assets.componentUsageTitle", "Component usage"),
            href: "/assets/component-usage",
            render: ComponentUsage,
            hideInMenu: true,
        },
        {
            title: getResource("Assets.assetsComponentsTitle", "Assets components"),
            href: "/assets/asset-components",
            render: AssetComponents,
            requiredOperation: "assets-view-components",
            hideInMenu: true,
        },
        {
            title: getResource("Assets.assetsUsageTitle", "Assets usage"),
            href: "/assets/asset-usage",
            render: AssetUsage,
            hideInMenu: true,
        },
        {
            title: getResource("Assets.assetFactTitle", "Asset facts"),
            href: "/assets/asset-facts",
            render: AssetFacts,
            hideInMenu: true,
        },
        {
            title: getResource("Assets.componentFactTitle", "Component facts"),
            href: "/assets/component-facts",
            render: ComponentFacts,
            hideInMenu: true,
        },
        {
            title: getResource("Assets.assetAdvertisementsTitle", "Asset advertisements"),
            href: "/assets/asset-advertisement",
            render: AssetAdvertisements,
            requiredOperation: "advertisements-view",
            hideInMenu: true,
        },
    ];

    return [
        <Section
            key="Assets"
            title={getResource("Assets.title", "Asset")}
            icon={BarChart}
            href="/assets"
            children={children}
        />,

        <ReferenceExtension
            key="Assets-reference"
            type="Assets"
            fetchMethod={getAsset}
            nameMethod={(asset, defaultCulture, selectedCulture) =>
                asset &&
                ((asset.name && (asset.name[selectedCulture] || asset.name[defaultCulture])) ||
                    `${asset.manufacturerName} / ${asset.assetModel.name}`)
            }
            breadcrumbMethod={(asset) => (asset ? asset.assetCategories : null)}
        />,
        <ReferenceExtension
            key="Components-reference"
            type="Components"
            fetchMethod={getComponent}
            nameMethod={(component, defaultCulture, selectedCulture) =>
                component &&
                component.name &&
                (component.name[selectedCulture] || component.name[defaultCulture])
            }
            breadcrumbMethod={(component) => null}
        />,

        <LibraryExtension
            key="asset-documents-generic"
            library="asset-documents-generic"
            referenceType="Assets"
            renderHeader={() => <AssetDocumentHeader />}
            renderSummary={() => <AssetDocumentSummary />}
        />,
        <LibraryExtension
            key="asset-documents-financial"
            library="asset-documents-financial"
            referenceType="Assets"
            renderHeader={() => <AssetDocumentHeader />}
            renderSummary={() => <AssetDocumentSummary />}
        />,
        <LibraryExtension
            key="asset-documents-ownership"
            library="asset-documents-ownership"
            referenceType="Assets"
            renderHeader={() => <AssetDocumentHeader />}
            renderSummary={() => <AssetDocumentSummary />}
        />,
        <LibraryExtension
            key="component-documents"
            library="component-documents"
            referenceType="Components"
            renderHeader={() => <ComponentDocumentHeader />}
            renderSummary={() => <ComponentDocumentSummary />}
        />,
    ];
});
