import React, { Fragment, useContext } from "react";
import { LinearProgress, FormControlLabel } from "@material-ui/core";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import DrillDownInput from "../../../shared/drilldowninput";
import LabelledOutline from "../../../shared/labelledoutline";

import useAPI from "../../../shared/useapi";
import { add } from "./dataservice";
import { getParents, getActiveChildren, getPath, getAll } from "../companies/dataservice";
import { getAll as getAllCountries } from "./../../library/countries/dataservice";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import withResources from "../../../shared/textresources/withresources";
import ResourcedTextField from "../../../shared/textresources/resourcedtextfield";
import Timezone from "./timezone";
import Switch from "@material-ui/core/Switch";
import { config } from "config";

export default withResources(({ getResource, open, onClose }) => {
    if (!open) {
        return null;
    }

    const { companyScope } = useContext(WorkingScopeContext);

    const { loading, invoke } = useAPI(add);

    const onSubmit = async (values) => {
        var saveValues = {
            ...values.values,
            companies: values.values.companies.map((item) => item.id),
            country: values.values.addressVisible ? values.values.country : null,
            address: values.values.addressVisible ? values.values.address : null,
            postalCode: values.values.addressVisible ? values.values.postalCode : null,
            state: values.values.addressVisible ? values.values.state : null,
            city: values.values.addressVisible ? values.values.city : null,
        };

        if (await invoke(saveValues)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = getResource(
                "Security.users.add.enterFirstName",
                "Please enter a firstname"
            );
        }

        if (!values.lastName) {
            errors.lastName = getResource(
                "Security.users.add.enterSurname",
                "Please enter a surname"
            );
        }

        if (!values.email) {
            errors.email = getResource("Security.users.add.enterEmail", "Please enter a e-mail");
        }

        if (values.email && !values.email.match(config.emailRegex)) {
            errors.email = getResource(
                "Security.users.add.enterValidEmail",
                "The provided e-mail isn't a valid e-mail address"
            );
        }

        if (!values.password) {
            errors.password = getResource(
                "Security.users.add.enterPassword",
                "Please enter a password"
            );
        }

        if (values.password && !values.password.match(config.passwordRegex)) {
            errors.password = getResource(
                "Security.users.add.enterValidPassword",
                "The password doesn't match the requirements"
            );
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = getResource(
                "Security.users.add.enterPasswordConfirmation",
                "Please enter a password confirmation"
            );
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = getResource(
                "Security.users.add.enterSamePasswordConfirmation",
                "The password isn't the same as the password confirmation"
            );
        }

        if (values.addressVisible && !values.country) {
            errors.country = getResource(
                "Security.users.add.selectCountry",
                "Please select a country"
            );
        }

        if (values.addressVisible && !values.address) {
            errors.address = getResource(
                "Security.users.add.enterAddress",
                "Please enter a address"
            );
        }

        if (values.addressVisible && !values.postalCode) {
            errors.postalCode = getResource(
                "Security.users.add.enterPostalCode",
                "Please enter a postal code"
            );
        }

        if (values.addressVisible && !values.city) {
            errors.city = getResource("Security.users.add.enterCity", "Please enter a city");
        }

        if (!values.companies || values.companies.length === 0) {
            errors.companies = getResource(
                "Security.users.add.selectCompanies",
                "Please select one or more companies"
            );
        }

        if (!values.timezone) {
            errors.timezone = getResource(
                "Security.users.add.selectTimezone",
                "Please select a timezone"
            );
        }

        return errors;
    };

    const { data: allCountries } = useAPI(() => getAllCountries(), true);

    var isLoaded = allCountries;

    const [isDirty, setIsDirty] = React.useState(false);
    return (
        <FormDrawer
            title={getResource("Security.users.add.title", "Create user")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{
                        name: "",
                        phoneNumber1: "",
                        password: "",
                        confirmPassword: "",
                        email: "",
                        phoneNumber2: "",
                        addressVisible: false,
                        companies: companyScope.id ? [companyScope] : [],
                    }}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.users.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <Fragment>
                                <ResourcedTextField
                                    errors={errors}
                                    autoFocus
                                    margin="normal"
                                    name="firstName"
                                    labelResourceKey="Security.users.add.firstNameField"
                                    labelDefaultValue="First name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.firstName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="lastName"
                                    labelResourceKey="Security.users.add.surnameField"
                                    labelDefaultValue="Surname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 60 }}
                                    value={values.lastName}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="email"
                                    labelResourceKey="Security.users.add.emailField"
                                    labelDefaultValue="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    value={values.email}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="password"
                                    labelResourceKey="Security.users.add.passwordField"
                                    labelDefaultValue="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.password}
                                    onChange={(e) => handleChange(e)}
                                    helperText={getResource(
                                        "Security.users.add.passwordRequirement",
                                        "Your password needs to be at least 6 long and needs to have at least 1 capital, 1 digit, and 1 non-word character in it."
                                    )}
                                    required
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="confirmPassword"
                                    labelResourceKey="Security.users.add.passwordConfirmationField"
                                    labelDefaultValue="Password confirmation"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 100 }}
                                    value={values.confirmPassword}
                                    onChange={(e) => handleChange(e)}
                                    required
                                />

                                <DrillDownInput
                                    errors={errors}
                                    name="companies"
                                    itemName={getResource(
                                        "Security.users.add.companiesField",
                                        "Companies"
                                    )}
                                    label={getResource(
                                        "Security.users.add.companiesField",
                                        "Companies"
                                    )}
                                    isMedium
                                    fullWidth
                                    getParents={getParents}
                                    getChildren={getActiveChildren}
                                    getPath={getPath}
                                    filter={(searchText, noDescendantOf, page, pageSize) =>
                                        getAll(searchText, noDescendantOf, true, page, pageSize)
                                    }
                                    value={values.companies}
                                    disableClearable
                                    setValue={(e) => {
                                        handleChange(e);
                                    }}
                                    multiple
                                    required
                                />

                                <LabelledOutline
                                    label={getResource(
                                        "Security.users.add.personalAddressLabel",
                                        "Personal address"
                                    )}
                                >
                                    <FormControlLabel
                                        label={getResource(
                                            "Security.users.add.personalAddressSwitchLabel",
                                            "Personal address"
                                        )}
                                        control={
                                            <Switch
                                                checked={Boolean(values.addressVisible)}
                                                onChange={(e) => handleChange(e)}
                                                name="addressVisible"
                                            />
                                        }
                                    />

                                    {values.addressVisible && (
                                        <Fragment>
                                            <AutoCompleteStatic
                                                errors={errors}
                                                name="country"
                                                label={getResource(
                                                    "Security.users.add.countryField",
                                                    "Country"
                                                )}
                                                fullWidth
                                                isMedium
                                                value={values.country}
                                                onSelection={(e) => handleChange(e)}
                                                options={allCountries.map((c) => ({
                                                    id: c.code,
                                                    name: c.name,
                                                }))}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="address"
                                                labelResourceKey="Security.users.add.addressField"
                                                labelDefaultValue="Address"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.address}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="state"
                                                labelResourceKey="Security.users.add.stateField"
                                                labelDefaultValue="State"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.state || ""}
                                                onChange={(e) => handleChange(e)}
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="postalCode"
                                                labelResourceKey="Security.users.add.postalCodeField"
                                                labelDefaultValue="Postal code"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 50 }}
                                                value={values.postalCode}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />

                                            <ResourcedTextField
                                                errors={errors}
                                                margin="normal"
                                                name="city"
                                                labelResourceKey="Security.users.add.cityField"
                                                labelDefaultValue="City"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 100 }}
                                                value={values.city}
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </Fragment>
                                    )}
                                </LabelledOutline>

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber1"
                                    labelResourceKey="Security.users.add.phoneNumber1Field"
                                    labelDefaultValue="Phone number 1"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber1 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber1;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <ResourcedTextField
                                    errors={errors}
                                    margin="normal"
                                    name="phoneNumber2"
                                    labelResourceKey="Security.users.add.phoneNumber2Field"
                                    labelDefaultValue="Phone number 2"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        maxLength: 20,
                                        pattern: "^[+]{0,1}[0-9.\\-()]{0,20}$",
                                    }}
                                    value={values.phoneNumber2 || ""}
                                    onChange={(e) => {
                                        if (!e.target.validity.valid) {
                                            e.target.value = values.phoneNumber2;
                                        }
                                        handleChange(e);
                                    }}
                                />

                                <Timezone
                                    name="timezone"
                                    value={values.timezone}
                                    onChange={(e) => handleChange(e)}
                                    errors={errors}
                                    required
                                />
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
