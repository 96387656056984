import React from "react";
import { LinearProgress, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import useAPI from "../../../shared/useapi";
import { getTotalValue } from "./dataservice";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import withResources from "../../../shared/textresources/withresources";
import FormattedCurrency from "../../../shared/formattedCurrency";

const useStyles = makeStyles((theme) => ({
    groupTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },

    dl: {
        marginBottom: theme.spacing(4),
        maxWidth: "550px",

        "& dt, & dd": {
            "&:not(.--summary-has-toggle):last-of-type": {
                marginBottom: 0,
            },
        },
    },

    assetCount: {
        fontSize: "0.9em",
        fontWeight: "normal",
    },
}));

export default withResources(({ getResource, searchParams, open, onClose }) => {
    if (!open) {
        return null;
    }

    const classes = useStyles();

    const { data } = useAPI(
        () =>
            getTotalValue(
                searchParams.searchText,
                searchParams.assetModelId,
                searchParams.manufacturerId
            ),
        true
    );

    const isLoaded = data;

    return (
        <FormDrawer
            title={getResource("Assets.asset.totalValue.title", "Total value of {count} assets", {
                count:
                    data &&
                    data.reduce((counter, currencyTotal) => counter + currencyTotal.assetCount, 0),
            })}
            onClose={onClose}
            isDirty={false}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <React.Fragment>
                    <p>
                        {getResource(
                            "Assets.asset.totalValue.introduction",
                            "The following totals represent the sum of values for all of the assets included in the (filtered) asset list for which Quick scan and/or financial information is available"
                        )}
                    </p>
                    {data.length > 1 && (
                        <p>
                            {getResource(
                                "Assets.asset.totalValue.multipleCurrencies",
                                "The (filtered) asset list includes assets in multiple currencies, therefore the totals are presented for each currency individually"
                            )}
                        </p>
                    )}
                    {data.length === 0 && (
                        <p>
                            <Alert severity="warning">
                                {getResource(
                                    "Assets.asset.totalValue.noCurrencies",
                                    "There are no assets in the (filtered) asset list with a Quick scan and/or financial information"
                                )}
                            </Alert>
                        </p>
                    )}
                    {data.map((currencyTotal) => {
                        return (
                            <React.Fragment key={currencyTotal.currencyName}>
                                <Typography variant="h5" className={classes.groupTitle}>
                                    {getResource(
                                        "Assets.asset.totalValue.currencyTotal.title",
                                        "Totals for {assetCount} assets that have information listed in {currencyName}",
                                        {
                                            assetCount: currencyTotal.assetCount,
                                            currencyName: currencyTotal.currencyName,
                                        }
                                    )}
                                </Typography>
                                <dl className={classes.dl}>
                                    <dt>
                                        {getResource(
                                            "Assets.asset.totalValue.currencyTotal.fairMarketValue",
                                            "Total fair market value"
                                        )}
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={currencyTotal.totalFairMarketValue}
                                            currency={currencyTotal.currencyShortName}
                                            decimals={0}
                                        />
                                        <div className={classes.assetCount}>
                                            {getResource(
                                                "Assets.asset.totalValue.assetsIncluded",
                                                "{assetCount} asset included",
                                                { assetCount: currencyTotal.quickScanCount }
                                            )}
                                        </div>
                                    </dd>

                                    <dt>
                                        {getResource(
                                            "Assets.asset.totalValue.currencyTotal.totalLiquidationValue",
                                            "Total liquidation value"
                                        )}
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={currencyTotal.totalLiquidationValue}
                                            currency={currencyTotal.currencyShortName}
                                            decimals={0}
                                        />
                                        <div className={classes.assetCount}>
                                            {getResource(
                                                "Assets.asset.totalValue.assetsIncluded",
                                                "{assetCount} asset included",
                                                { assetCount: currencyTotal.quickScanCount }
                                            )}
                                        </div>
                                    </dd>

                                    <dt>
                                        {getResource(
                                            "Assets.asset.totalValue.currencyTotal.totalRemainingDebt",
                                            "Total remaining debt"
                                        )}
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={currencyTotal.totalRemainingDebt}
                                            currency={currencyTotal.currencyShortName}
                                            decimals={0}
                                        />
                                        <div className={classes.assetCount}>
                                            {getResource(
                                                "Assets.asset.totalValue.assetsIncluded",
                                                "{assetCount} asset included",
                                                { assetCount: currencyTotal.loanCount }
                                            )}
                                        </div>
                                    </dd>

                                    <dt>
                                        {getResource(
                                            "Assets.asset.totalValue.currencyTotal.totalHiddenReserve",
                                            "Total hidden reserve"
                                        )}
                                    </dt>
                                    <dd>
                                        <FormattedCurrency
                                            value={currencyTotal.totalHiddenReserve}
                                            currency={currencyTotal.currencyShortName}
                                            decimals={0}
                                        />
                                        <div className={classes.assetCount}>
                                            {getResource(
                                                "Assets.asset.totalValue.assetsIncluded",
                                                "{assetCount} asset included",
                                                { assetCount: currencyTotal.hiddenReserveCount }
                                            )}
                                        </div>
                                    </dd>
                                </dl>
                            </React.Fragment>
                        );
                    })}

                    <Button onClick={onClose} color="primary" fullWidth variant="contained">
                        {getResource("Assets.asset.totalValue.closeButton", "Ok")}
                    </Button>
                </React.Fragment>
            )}
        </FormDrawer>
    );
});
