import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import withResources from "../../../../../shared/textresources/withresources";
import {
    ExpandLess as CollapseIcon,
    ExpandMore as ExpandIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons";
import useStyles from "./useStyles";
import { IconButton } from "@material-ui/core";

const TaskSummary = withResources((props) => {
    const { getResource, summary, showDelete, isExpanded, index, handleDeleteTask } = props;

    const classes = useStyles();

    return (
        <Fragment>
            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}

            <Typography className={classes.taskName}>
                {summary ||
                    getResource("Maintenance.workOrder.tasks.task", "Task {number}", {
                        number: index + 1,
                    })}
            </Typography>

            {isExpanded && showDelete && (
                <IconButton
                    aria-label={getResource("Maintenance.workOrder.tasks.deleteTaskLabel", "Task")}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTask(index);
                    }}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            )}
        </Fragment>
    );
});

export default TaskSummary;
