import React, { Fragment, useEffect, useContext, useState } from "react";
import { LinearProgress, FormHelperText } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import LocalizedForm from "../../../shared/localization/localizedform";
import FormDrawer from "../../../shared/formDrawer/formDrawer";
import AutoCompleteStatic from "../../../shared/autocompleteStatic";
import PermissionScope from "./permissionScope";

import useAPI from "../../../shared/useapi";
import { add, getPossiblePermissions } from "./dataservice";
import { getScopes } from "../roles/dataservice";
import { getWhereUserHasOperation as getCompanies } from "../companies/dataservice";
import { getByCompanyWhereUserHasOperation as getAssetCollections } from "../../assets/assetCollection/dataservice";
import { getByCompany as getUsers, getAll as getAllUsers } from "../users/dataservice";
import withResources from "../../../shared/textresources/withresources";
import AuthenticationContext from "../../../shared/authentication/authenticationcontext";

export default withResources(({ getResource, open, parentId, onClose }) => {
    if (!open) {
        return null;
    }

    const { authorizationService } = useContext(AuthenticationContext);

    const [scopes, setScopes] = useState([]);
    const [scopeText, setScopeText] = useState(null);
    const [introText, setIntroText] = useState(null);
    const [companyText, setCompanyText] = useState(null);
    const [userText, setUserText] = useState(null);
    const [roleText, setRoleText] = useState(null);

    const { loading, invoke } = useAPI(add);

    const { data: allScopes } = useAPI(() => getScopes(), true);
    const { data: companies, invoke: loadCompanies } = useAPI(
        () => getCompanies(["user-roles-assign"]),
        false
    );
    const { data: assetCollections, invoke: loadAssetCollections } = useAPI(
        (companyId) => getAssetCollections(companyId, "user-roles-assign"),
        false
    );
    const { data: users, invoke: loadUsers } = useAPI(
        (loadAll, companyId) =>
            loadAll ? getAllUsers(null, true, null, null, null) : getUsers(companyId),
        false
    );
    const { data: roles, invoke: loadRoles } = useAPI(
        (scope) => getPossiblePermissions(scope),
        false
    );

    useEffect(() => {
        if (allScopes) {
            //global permission
            if (
                authorizationService.isAuthorized({
                    name: "user-roles-assign",
                    companyId: null,
                    assetCollectionId: null,
                })
            ) {
                setScopes(allScopes);
                setScopeText(
                    getResource(
                        "Security.permission.add.scopeText.global",
                        "Would you like to assign a role to a user for an entire company, a single collection or for the entire system?"
                    )
                );
            }
            //company permission
            else if (authorizationService.hasAuthorizationOnCompanyLevel("user-roles-assign")) {
                setScopes(
                    allScopes.filter(
                        (s) =>
                            s.id === PermissionScope.company ||
                            s.id === PermissionScope.assetCollection
                    )
                );
                setScopeText(
                    getResource(
                        "Security.permission.add.scopeText.company",
                        "Would you like to assign a role to a user for an entire company or a single collection?"
                    )
                );
            }
            //asset collection permission
            else if (
                authorizationService.hasAuthorizationOnAssetCollectionLevel("user-roles-assign")
            ) {
                setScopes(allScopes.filter((s) => s.id === PermissionScope.assetCollection));
                setScopeText(null);
            }
        }
    }, [allScopes, authorizationService, getResource]);

    const onSubmit = async (values) => {
        if (await invoke(values.values)) {
            onClose(true);
        }
    };

    const onValidate = (values) => {
        const errors = {};

        if (!values.users || values.users.length === 0) {
            errors.users = getResource(
                "Security.permission.add.enterUsers",
                "Please select ore or more users"
            );
        }
        if (!values.roles || values.roles.length === 0) {
            errors.roles = getResource(
                "Security.permission.add.enterRoles",
                "Please select one or more roles"
            );
        }
        if (values.scope !== PermissionScope.global) {
            if (!values.companyId) {
                errors.companyId = getResource(
                    "Security.permission.add.enterCompanyId",
                    "Please select a company"
                );
            }
            if (values.scope !== PermissionScope.company) {
                if (!values.assetCollectionId) {
                    errors.assetCollectionId = getResource(
                        "Security.permission.add.enterAssetCollectionId",
                        "Please select an asset collection"
                    );
                }
            }
        }

        return errors;
    };

    const resetIdFields = (scopeId, values, events) => {
        if (scopeId !== PermissionScope.assetCollection && values.assetCollectionId) {
            events.push({
                target: { name: "assetCollectionId", value: null },
            });
        }
        if (scopeId === PermissionScope.global && values.companyId) {
            events.push({
                target: { name: "companyId", value: null },
            });
        }
        if (scopeId !== PermissionScope.global && values.userId) {
            events.push({
                target: { name: "userId", value: null },
            });
        }
    };

    const setTexts = (scope) => {
        if (scope === PermissionScope.global) {
            setIntroText(
                getResource(
                    "Security.permission.add.introText.global",
                    "The assigned roles will apply to (all assets in) the entire system."
                )
            );
            setCompanyText(null);
            setUserText(
                getResource(
                    "Security.permission.add.userText.global",
                    "Select the user you want to assign a global role to."
                )
            );
            setRoleText(null);
        } else if (scope === PermissionScope.company) {
            setIntroText(
                getResource(
                    "Security.permission.add.introText.company",
                    "The assigned roles will apply to (all assets in) the selected company."
                )
            );
            setCompanyText(
                getResource(
                    "Security.permission.add.companyText.company",
                    "Select the company you want to give access to."
                )
            );
            setUserText(
                getResource(
                    "Security.permission.add.userText.company",
                    "Select the user(s) you want to assign a role to."
                )
            );
            setRoleText(
                getResource(
                    "Security.permission.add.roleText.company",
                    "The assigned roles will apply to (all assets in) the selected company"
                )
            );
        } else if (scope === PermissionScope.assetCollection) {
            setIntroText(
                getResource(
                    "Security.permission.add.introText.assetCollection",
                    "The assigned roles will apply to (all assets in) the selected collection."
                )
            );
            setCompanyText(
                getResource(
                    "Security.permission.add.companyText.assetCollection",
                    "First select a company and then select the collection you want to give access to."
                )
            );
            setUserText(
                getResource(
                    "Security.permission.add.userText.assetCollection",
                    "Select the user(s) you want to assign a role to."
                )
            );
            setRoleText(
                getResource(
                    "Security.permission.add.roleText.assetCollection",
                    "The assigned roles will apply to (all assets in) the selected collection."
                )
            );
        }
    };

    const setScope = (e, handleChange, values) => {
        var events = [e];
        resetIdFields(e.target.value, values, events);
        setTexts(e.target.value);

        loadRoles(e.target.value);

        if (e.target.value === PermissionScope.global) {
            //Load all users
            loadUsers(true, null);
        } else {
            loadCompanies();

            if (e.target.value === PermissionScope.assetCollection && values.companyId) {
                loadAssetCollections(values.companyId);
            }
        }
        handleChange(events);
    };

    const setCompany = (e, handleChange, values) => {
        if (e.target.value) {
            if (values.scope === PermissionScope.assetCollection) {
                loadAssetCollections(e.target.value);
            }
            loadUsers(false, e.target.value);
        }

        handleChange(e);
    };

    var isLoaded = scopes.length > 0;

    const [isDirty, setIsDirty] = useState(false);
    return (
        <FormDrawer
            title={getResource("Security.permission.add.title", "Add role assignment")}
            onClose={() => onClose(false)}
            isDirty={isDirty}
        >
            {!isLoaded ? (
                <LinearProgress />
            ) : (
                <LocalizedForm
                    initialValues={{}}
                    onIsDirty={setIsDirty}
                    loading={loading}
                    okButtonText={getResource("Security.permission.add.okButton", "Create")}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    renderForm={(_, values, handleChange, errors) => {
                        return (
                            <Fragment>
                                {scopeText && <Alert severity="info">{scopeText}</Alert>}

                                <AutoCompleteStatic
                                    errors={errors}
                                    name="scope"
                                    label={getResource(
                                        "Security.permission.add.scopeField",
                                        "Scope"
                                    )}
                                    fullWidth
                                    isMedium
                                    value={values.scope}
                                    onSelection={(e) => setScope(e, handleChange, values)}
                                    options={scopes}
                                    disableClearable
                                    required
                                />

                                {values.scope && (
                                    <Fragment>
                                        {introText && (
                                            <Alert
                                                severity={
                                                    values.scope === PermissionScope.global
                                                        ? "warning"
                                                        : "info"
                                                }
                                            >
                                                {introText}
                                            </Alert>
                                        )}

                                        {(values.scope === PermissionScope.company ||
                                            values.scope === PermissionScope.assetCollection) && (
                                            <Fragment>
                                                {companyText && (
                                                    <FormHelperText>{companyText}</FormHelperText>
                                                )}

                                                <AutoCompleteStatic
                                                    errors={errors}
                                                    name="companyId"
                                                    label={getResource(
                                                        "Security.permission.add.companyIdField",
                                                        "Company"
                                                    )}
                                                    fullWidth
                                                    isMedium
                                                    value={values.companyId}
                                                    onSelection={(e) =>
                                                        setCompany(e, handleChange, values)
                                                    }
                                                    options={companies}
                                                    disableClearable
                                                    required
                                                />
                                            </Fragment>
                                        )}

                                        {values.scope === PermissionScope.assetCollection && (
                                            <AutoCompleteStatic
                                                errors={errors}
                                                name="assetCollectionId"
                                                label={getResource(
                                                    "Security.permission.add.assetCollectionIdField",
                                                    "Collection"
                                                )}
                                                fullWidth
                                                isMedium
                                                value={values.assetCollectionId}
                                                onSelection={(e) => handleChange(e)}
                                                options={assetCollections}
                                                disableClearable
                                                required
                                            />
                                        )}

                                        {userText && <FormHelperText>{userText}</FormHelperText>}

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="users"
                                            label={getResource(
                                                "Security.permission.add.usersField",
                                                "User"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.users}
                                            onSelection={(e) => handleChange(e)}
                                            options={(users && users.items) || users}
                                            disableClearable
                                            multiple
                                            required
                                        />

                                        {roleText && <FormHelperText>{roleText}</FormHelperText>}

                                        <AutoCompleteStatic
                                            errors={errors}
                                            name="roles"
                                            label={getResource(
                                                "Security.permission.add.rolesField",
                                                "Role"
                                            )}
                                            fullWidth
                                            isMedium
                                            value={values.roles}
                                            onSelection={(e) => handleChange(e)}
                                            options={roles}
                                            disableClearable
                                            multiple
                                            required
                                        />
                                    </Fragment>
                                )}
                            </Fragment>
                        );
                    }}
                />
            )}
        </FormDrawer>
    );
});
