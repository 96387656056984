import React, { useContext, Fragment, useEffect } from "react";
import PrivateRoute from "../../../shared/authentication/privateroute";
import useSessionStorage from "../../../shared/useSessionStorage";
import ConfigurationContext from "../../../shared/configuration/configurationcontext";
import WorkingScopeContext from "../../shell/components/workingScope/workingscopecontext";
import useAPI from "../../../shared/useapi";
import { getAll } from "./dataservice";

import useDialog from "../../../shared/usedialog";
import Overview from "./overview";
import Add from "./add";
import Edit from "./edit";

export default () => {
    const { defaultPageSize, availablePageSizes, defaultDebounceInterval } = useContext(
        ConfigurationContext
    );
    const { companyScope } = useContext(WorkingScopeContext);

    const [searchParams, setSearchParams] = useSessionStorage("search-auctions", {
        page: 0,
        pageSize: defaultPageSize,
        searchText: null,
        status: null,
    });

    const { loading, invoke, data } = useAPI(
        () =>
            getAll(
                searchParams.searchText,
                searchParams.status,
                searchParams.page + 1,
                searchParams.pageSize
            ),
        true,
        [
            searchParams.searchText,
            searchParams.status,
            searchParams.page,
            searchParams.pageSize,
            companyScope.id
        ]);

    const { open: handleAdd, close: handleCloseAdd, visible: addToggled } = useDialog(invoke);
    const {
        open: handleEdit,
        close: handleCloseEdit,
        visible: editToggled,
        args: editId,
    } = useDialog(invoke);

    function handleSearch(searchText, status) {
        setSearchParams({ ...searchParams, searchText, status, page: 0 });
    }

    function handlePageChange(page) {
        setSearchParams({ ...searchParams, page });
    }

    function handlePageSizeChange(pageSize) {
        setSearchParams({ ...searchParams, pageSize, page: 0 });
    }

    function getRequiredOperation(operation, row) {
        if (!companyScope.id && !row) {
            return operation;
        }

        return {
            name: operation,
            companyId: companyScope.id || (row ? row.companyId : null),
            assetCollectionId: null,
        };
    }

    useEffect(() => setSearchParams({ ...searchParams, page: 0 }), [companyScope.id]);

    return (
        <Fragment>
            <PrivateRoute
                requiredOperation="auctions-view"
                path="/marketplace/auctions"
                render={(props) => {
                    return (
                        <Overview
                            data={data}
                            searchParams={searchParams}
                            availablePageSizes={availablePageSizes}
                            loading={loading}
                            onSearch={handleSearch}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            defaultDebounceInterval={defaultDebounceInterval}
                            getRequiredOperation={getRequiredOperation}
                            {...props}
                        />
                    );
                }}
            />

            <Add open={addToggled} onClose={handleCloseAdd} />
            <Edit id={editId} open={editToggled} onClose={handleCloseEdit} />
        </Fragment>
    );
};
