import queryString from "query-string";
import interceptedFetch from "../../../shared/interceptedFetch";

async function get(assetCollectionId, assetId, companyId, componentId, page, pageSize) {
    const params = {
        assetCollectionId, assetId, companyId, componentId, page, pageSize
    };

    const url = `/api/planning/openaction/?${queryString.stringify(params, {
        skipNull: true,
        skipEmptyString: true,
    })}`;

    return await interceptedFetch(url);
}

export { get };
