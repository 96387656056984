import React from "react";
import { Section } from "../shell";
import ModelValuation from "./modelvaluation";
import AssetValuation from "./assetvaluation";
import Questions from "./questions";
import Questionnaire from "./questionnaire";
import AssetModelQuestionnaire from "./assetmodelquestionnaire";
import AssetQuestionnaire from "./assetquestionnaire";
import FinancialReports from "./financialreport";
import ValuationRequest from "./valuationrequest";
import withResources from "../../shared/textresources/withresources";
import { ValuationIcon } from "../../shared/customIcons";

import { registerIcon } from "../shell/components/topbar/components/notifications";

registerIcon("openValuationRequests", ValuationIcon);

export default withResources(({ getResource }) => {
    const valuationChildren = [
        {
            title: getResource("Valuation.modelValuationTitle", "Model valuation"),
            href: "/valuation/model-valuation",
            render: ModelValuation,
            requiredOperation: "model-valuations-create",
        },
        {
            title: getResource("Valuation.assetValuationTitle", "Asset valuation"),
            href: "/valuation/asset-valuation",
            render: AssetValuation,
            requiredOperation: "asset-valuations-create",
        },
        {
            title: getResource("Valuation.questionsTitle", "Questions"),
            href: "/valuation/questions",
            render: Questions,
            requiredOperation: "questions-view",
        },
        {
            title: getResource("Valuation.questionnairesTitle", "Category questionnaires"),
            href: "/valuation/questionnaire",
            render: Questionnaire,
            requiredOperation: "questionnaires-view",
        },
        {
            title: getResource("Valuation.assetModelQuestionnairesTitle", "Model questionnaires"),
            href: "/valuation/asset-model-questionnaire",
            render: AssetModelQuestionnaire,
            requiredOperation: "questionnaires-view",
        },
        {
            title: getResource("Valuation.assetQuestionnairesTitle", "Asset questionnaires"),
            href: "/valuation/asset-questionnaire",
            render: AssetQuestionnaire,
            requiredOperation: "questionnaires-view",
        },
        {
            href: "/valuation/financial-report/:quickScanId",
            render: FinancialReports,
            requiredOperation: "financial-reports-view",
            hideInMenu: true,
        },
        {
            title: getResource("Valuation.valuationRequestsTitle", "Valuation requests"),
            href: "/valuation/valuation-requests",
            render: ValuationRequest,
            requiredOperation: "model-valuations-create",
        },
    ];

    return (
        <Section
            key="valuation"
            title={getResource("Valuation.title", "Valuation")}
            requiresAuthentication
            icon={ValuationIcon}
            href="/valuation"
            children={valuationChildren}
        />
    );
});
