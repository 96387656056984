import React, { useEffect, useState } from "react";
import { FormHelperText, IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import { Close as FileDelete, Error as ErrorIcon } from "@material-ui/icons";
import DropZone from "../dropzone";
import withResources from "../textresources/withresources";
import clsx from "clsx";
import useStyles from "./useStyles";
import PropTypes from "prop-types";

const Upload = withResources((props) => {
    const {
        getResource,
        url,
        errors,
        fileId,
        fileName,
        allowedExtensions,
        disabled,
        onFileUpload,
        onFileDelete,
        showDelete = true,
    } = props;

    const [progress, setProgress] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const classes = useStyles();

    const error = (errors && "fileId" in errors) || Boolean(errorMessage);
    const helperText = (errors && errors["fileId"]) || errorMessage;

    useEffect(() => {
        setCurrentFile({ id: fileId, name: fileName });

        if (fileId) {
            setProgress({ completed: 100, state: "done" });
        } else {
            setProgress(null);
        }
    }, [fileId, fileName]);

    function upload(file) {
        setErrorMessage(null);
        setCurrentFile({ id: null, name: file.name });
        setProgress({ state: "starting", ...progress });

        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                setProgress({ completed: percentage, state: "busy", ...progress });
            }
        });

        req.upload.addEventListener("load", (event) => {
            setProgress({ completed: 100, state: "done", ...progress });
        });

        req.upload.addEventListener("error", (event) => {
            setProgress({ state: "error", ...progress });
        });

        req.onreadystatechange = function () {
            if (req.readyState !== XMLHttpRequest.DONE) {
                return;
            }

            if (req.status === 200) {
                const result = JSON.parse(req.responseText);
                setCurrentFile({ id: result.id, name: file.name });
                setProgress({ completed: 100, state: "done", ...progress });
                onFileUpload({ id: result.id, name: file.name }, file);
            } else {
                setProgress({ state: "error", message: req.responseText, ...progress });
            }
        };

        const formData = new FormData();
        formData.append("file", file, file.name);

        req.open("POST", url);
        req.send(formData);
    }

    function handleDelete() {
        setCurrentFile(null);
        setProgress(null);
        onFileUpload(null);

        if (onFileDelete) {
            onFileDelete();
        }
    }

    function handleFileRejections(files) {
        if (files.length > 1) {
            setErrorMessage(
                getResource(
                    "Shell.upload.uploadMultipleDisabled",
                    "You cannot upload multiple documents"
                )
            );
        } else {
            setErrorMessage(
                getResource(
                    "Shell.upload.invalidFileExtension",
                    "Invalid file extension, only one of the following extensions is supported: {allowedExtensions}",
                    { allowedExtensions: allowedExtensions.join(", ") }
                )
            );
        }
    }

    return (
        <div className={`${classes.root} uploadComponent`}>
            {(!progress || (progress && progress.state === "error")) && (
                <DropZone
                    error={error}
                    disabled={disabled}
                    acceptedFileExtensions={allowedExtensions}
                    onFileAccepted={(file) => upload(file)}
                    onFileRejected={(files) => handleFileRejections(files)}
                />
            )}

            {progress && progress.state === "busy" && (
                <LinearProgress
                    variant="determinate"
                    value={progress.completed || 0}
                    color="secondary"
                />
            )}

            {progress && progress.state === "error" && (
                <Tooltip title={progress.message}>
                    <ErrorIcon color="error" />
                </Tooltip>
            )}

            {progress && progress.state === "done" && (
                <div
                    className={clsx(
                        classes.fileUploaded,
                        "fileUploaded",
                        showDelete && "--has-delete"
                    )}
                >
                    <div className={classes.fileName} title={currentFile.name}>
                        {currentFile.name ||
                            getResource("Shell.upload.fileLabelWhenNoName", "Uploaded file")}
                    </div>
                    {showDelete && (
                        <IconButton
                            className={classes.deleteButton}
                            onClick={handleDelete}
                            disabled={disabled}
                            size="small"
                        >
                            <FileDelete />
                        </IconButton>
                    )}
                </div>
            )}

            {error && (
                <FormHelperText variant="outlined" error={error}>
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
});

Upload.propTypes = {
    url: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    fileId: PropTypes.string,
    fileName: PropTypes.string,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    allowedExtensions: PropTypes.array,
    onFileUpload: PropTypes.func,
    onFileDelete: PropTypes.func,
    showDelete: PropTypes.bool,
};

export default Upload;
